import ImageOne from "../components/ImageOne";
import ImageTwo from "../components/ImageTwo";
import ImageThree from "../components/ImageThree";
import TextBox from "../components/TextBox";
import TextBoxOne from '../components/TextBoxOne';

const Home = () => {
    return(
        <>
            <ImageOne />
            <TextBoxOne />
            <ImageTwo />
            <TextBox />
            <ImageThree />
            <TextBox />
        </>
    );
};

export default Home;