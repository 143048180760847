//import Logo from '../app/assets/logo/logo.png'
import ADMS from '../app/assets/img/ADMS.svg';
import Logo from '../app/assets/logo/biggerEmblem.svg';
import { Container, Row, Col } from 'reactstrap';
import { Parallax } from "react-parallax";
import '../App.css';

const ImageOne = () => (   
    <Parallax 
        bgImageStyle={{ maxHeight: '100vh', minWidth: '100vw' }}
        bgImageAlt='Home Screen Background' 
        bgImage={ADMS}
        strength={300}
        blur={0}>
        <Container>
            <Row>
                <Col xs='12' lg='8'>
                    <div>
                        <header id='title'>Asociacíon de Damas</header>
                    </div>
                    <div id='subTitle'>
                        <span>Mensajeras del Señor</span>
                    </div>
                </Col>
                <Col>
                    <div className='content'>
                        <img src={Logo} alt='logo' id='logo' />
                    </div>
                </Col>
            </Row>
        </Container>
    </Parallax>
);

export default ImageOne;