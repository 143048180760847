import { Card, CardImg, CardBody, CardText, CardTitle, CardLink } from 'reactstrap';
import Convention from '../app/assets/img/ADMS2024.jpeg';
import CampMain from '../app/assets/img/camp/CampMain.png'
import World from '../app/assets/img/world.png';
import Cart from '../app/assets/img/marketCart.svg';
import { motion } from 'framer-motion';
import { Row, Col } from 'reactstrap';

const Directory = () => {
    return(
         <>
            <h1 className='my-5'>Directorio</h1>
            <Row className='row-flex'>
                <Col lg='8' xl='9'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: -300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                            <Card>
                                <CardBody >
                                    <CardTitle tag='h2'>
                                        Convencion
                                    </CardTitle>
                                    <CardLink href='/directory/convention'>
                                        <motion.div
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.9 }}>
                                            <CardImg 
                                                src={Convention} 
                                                alt='Convention Pic' 
                                                className='directory-img' />
                                        </motion.div>
                                    </CardLink>
                                    <CardText tag='h4' className='mt-3'>
                                        ADMS - 2023
                                    </CardText>
                                </CardBody>
                            </Card>
                    </motion.div>        
                </Col>
                <Col className='directoryText'>
                    <motion.div 
                            className='temp'
                            initial={{ opacity: 0, x: 300 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 2, type: 'tween' }}
                            viewport={{ once: true }}>
                        <span>
                            <h3 style={{ marginTop: '5.5rem' }}>Alabalo!</h3>
                            <p style={{ marginTop: '-2rem' }}>
                               Nuestra convenciones anuales son unos servicios donde
                               la gloria de Dios se dejar sentir.  Donde El Señor habla a
                               nuestras vidas y confraternizamos con otras damas de otros 
                               estados, regiones, y paises.  Este año llevaremos acabo la 
                               convencion 2025 bajo el tema:
                            </p>
                            <p style={{ marginTop: '-5rem', fontSize: '1.1rem' }}>
                                <strong><em>Firmes, Constantes e Inmovibles!</em></strong>
                            </p>
                            <h6 style={{ marginTop: '-3.5rem' }}>
                                <em>Romanos 8:35</em>
                            </h6>
                            <p style={{ marginTop: '-2rem' }}>
                                Se llevara acabo los dias 16 y 17 de Mayo en la iglesia:
        
                                <h5 style={{textAlign:'center', paddingTop: '1rem'}}>La Segunda Sinagoga</h5>
                                <address style={{textAlign:'center'}}>
                                    44-46 Van Derveer Street
                                    Amterdam, NY 12010
                                </address>
                            </p>
                        </span>         
                    </motion.div>
                </Col>
            </Row>
            <Row className='row-flex'>
                <Col className='directoryText'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: -300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                        <span>
                            <h3 style={{ marginTop: '7rem' }}>Escapemos a los montes!</h3>
                            <p style={{ marginTop: '-2rem' }}>
                                Nuesto campamento anual es un evento donde confraternizamos
                                con damas do otros estados y paises un poco mas de cerca. Con 
                                timepo de oracion y ayuno, horas de diversion, confraternizar 
                                durante desayuno, almuerzo y cena, sera un fin de semana relajante
                                y lleno de enthiasmo!    
                            </p>
                            <p style={{ textAlign: 'center', marginTop: '-4rem'}}>
                                    Registrese Ya!
                            </p>
                        </span>
                    </motion.div>
                </Col>
                <Col lg='8' xl='9'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: 300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag='h2'>
                                        Campamento
                                    </CardTitle>
                                        <CardLink href='/directory/camp'>
                                            <motion.div
                                                whileHover={{ scale: 1.02 }}
                                                whileTap={{ scale: 0.9 }}
                                            >
                                                <CardImg 
                                                    src={CampMain} 
                                                    alt='Villa Roma Resort Front' 
                                                    className='directory-img' />
                                            </motion.div>
                                        </CardLink>
                                        <CardText tag='h4' className='mt-3'>
                                            Villa Roma Resort
                                        </CardText>
                                </CardBody>
                            </Card>
                    </motion.div>
                </Col>
            </Row>
            <Row className='row-flex'>
                <Col lg='8' xl='9'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: -300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag='h2'>
                                        ADMS Internacional
                                    </CardTitle>
                                        <CardLink href='/directory/regions'>
                                            <motion.div
                                                whileHover={{ scale: 1.02 }}
                                                whileTap={{ scale: 0.9 }}>
                                                <CardImg 
                                                    src={World} 
                                                    alt='World Map' 
                                                    className='directory-img'/>
                                            </motion.div>
                                        </CardLink>
                                        <CardText tag='h4' className='mt-3'>
                                            Regiones
                                        </CardText>
                                </CardBody>
                            </Card>
                    </motion.div>
                </Col>
                <Col className='directoryText'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: 300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                        <span className='directoryText'>
                            <h3 style={{ marginTop: '7rem' }}>El Evangelio por todo el mundo!</h3>
                            <p style={{ marginTop: '-1rem', marginBottom: '-2rem'}}>
                                Jesucristo dijo:
                            </p>
                                <cite>
                                        Id por todo el mundo y predicad el evangelio
                                        a toda criatura.
                                </cite>
                            <p style={{ marginTop: '-1rem' }}>  
                                Veamos nuestra representacion en el mundo y el trabajo que 
                                estamos haciendo todo estos años de servicio con las mensajeras
                                del Señor!   
                            </p>
                        </span>
                    </motion.div>
                </Col>
            </Row>
            <Row className='row-flex'>
                <Col className='directoryText'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: -300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                        <span className='directoryText'>
                            <h3 style={{ marginTop: '7rem' }}>Compre con nosotros!</h3>
                            <p>
                                Le falta su ensignia? Su escudo, lazo o pin? Quisiera tener
                                varios para asegurarse de tenerlo? Registrece con nuestra 
                                pagina para obtener su cuenta con ADMS para poder comprar
                                todo lo que neceiste con su uniforme y mas...
                            </p>
                        </span>
                    </motion.div>
                </Col>
                <Col lg='8' xl='9'>
                    <motion.div 
                        className='temp'
                        initial={{ opacity: 0, x: 300 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ delay: 1, duration: 2, type: 'tween' }}
                        viewport={{ once: true }}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag='h2'>
                                        Mercadería
                                    </CardTitle>
                                    <CardLink href='/directory/market'>
                                        <motion.div
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.9 }}>
                                            <CardImg 
                                                src={Cart} 
                                                alt='Shopping Cart' 
                                                className='directory-img' />
                                        </motion.div>
                                    </CardLink>
                                    <CardText tag='h4' className='mt-3'>
                                        Online Store
                                    </CardText>
                                </CardBody>
                            </Card>
                    </motion.div>
                </Col>
            </Row>
         </>
    );
};

export default Directory;