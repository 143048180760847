import { Row, Col } from 'reactstrap';

const Market = () => {
    return(
        <Row id='marketBG'>
            <Col>
                <h1 style={{margin: '30rem 15rem 10rem'}}>This is the ADMS Market Page</h1>
                <div>
                    <p style={{margin: '13rem 15rem'}}>
                        Irure cillum dolor irure minim. Do aliqua 
                        consectetur laboris est et ex nisi quis sunt 
                        commodo cillum. Occaecat esse esse ut occaecat 
                        aute ullamco nulla ea non laboris cillum ea. Eu 
                        mollit ullamco adipisicing commodo quis pariatur 
                        laborum eu laborum. Eiusmod aliquip dolore nisi 
                        elit nisi aliquip amet aliqua esse adipisicing. 
                        Duis id nulla reprehenderit ullamco amet eu veniam 
                        ex voluptate quis ad duis elit enim.Irure cillum dolor 
                        irure minim. Do aliqua et ex nisi quis sunt
                        consectetur laboris est et ex nisi quis sunt 
                        commodo cillum. Occaecat esse esse ut occaecat 
                        aute ullamco nulla ea non laboris cillum ea. Eu 
                        mollit ullamco adipisicing commodo quis pariatur 
                        laborum eu laborum. Eiusmod aliquip dolore nisi 
                        elit nisi aliquip amet aliqua esse adipisicing. 
                        Duis id nulla reprehenderit ullamco amet eu veniam 
                        ex voluptate quis ad duis elit enim.
                    </p>
                </div>
            </Col>
        </Row>
    );
};

export default Market;