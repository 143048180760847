import { useTransition, useSpring, useChain, config, animated, useSpringRef } from '@react-spring/web';
import styles from '../styles.module.css';
import { useState } from 'react';

const BoxSpread = ({ item }) => {
  const [open, set] = useState(false);

  const springApi = useSpringRef();
  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { size: '20%', background: "#FFDB58" },
    to: {
      size: open ? '100vw' : '20%',
      background: open ? '#000000' : '#FFDB58',
    }
  });

  const transApi = useSpringRef()
  const transition = useTransition(open ? item.campImages : [], {
    ref: transApi,
    trail: 400 / item.campImages.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springApi, transApi] : [transApi, springApi], [
    0,
    open ? 0.1 : 0.6,
  ])

  return (
    <div className={styles.boxSpreadWrapper}>
      <animated.div
        style={{ ...rest, width: size }}
        className={styles.boxSpreadContainer}
        onClick={() => set(open => !open)}>
          <span 
              className={styles.boxSpreadTitle}
              style={{ 
              color: open ? '#FFFFFF' : '#0A5C36',
              marginLeft: open ? '2rem' : '-3.9rem'
          }}
          >{item.campTitle}</span>
          {transition((style, item) => (
            <animated.div
              style={{ ...style }}> 
                <img 
                    src={item.image} 
                    alt={item.title} 
                    className={styles.boxSpreadItem} />
            </animated.div>
            ))}
        </animated.div>
    </div>
  );
};

export default BoxSpread;
