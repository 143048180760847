import { Container, Row, Col } from 'reactstrap';
import MyMap from '../components/MyMap';

const Regions = () => {

    return(
        <Container>
            <Row>
                <Col>
                    <div style={{marginTop: '2rem', textAlign: 'center'}}>
                        <h2>ADMS Internacional</h2>
                    </div>
                    <div style={{ border: '12px double darkblue' }}>
                        <MyMap />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Regions;