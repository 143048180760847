export const CaribbeanData = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-73.037231, 21.139442],
                [-73.123611, 21.008888],
                [-73.141113, 20.98333],
                [-73.147781, 20.975555],
                [-73.151672, 20.972221],
                [-73.162231, 20.968887],
                [-73.310837, 20.936108],
                [-73.452789, 20.924721],
                [-73.466949, 20.924999],
                [-73.524734, 20.933887],
                [-73.556671, 20.940277],
                [-73.588623, 20.946663],
                [-73.59584, 20.94722],
                [-73.601959, 20.946388],
                [-73.612503, 20.943054],
                [-73.662781, 20.915276],
                [-73.66806, 20.917774],
                [-73.680557, 20.926941],
                [-73.683899, 20.930553],
                [-73.685837, 20.935555],
                [-73.702225, 20.999722],
                [-73.655563, 21.081944],
                [-73.640564, 21.101665],
                [-73.536392, 21.183331],
                [-73.531677, 21.185833],
                [-73.527237, 21.186943],
                [-73.422226, 21.194164],
                [-73.349731, 21.172497],
                [-73.341675, 21.166111],
                [-73.337234, 21.163055],
                [-73.306671, 21.149166],
                [-73.261124, 21.134163],
                [-73.255844, 21.13583],
                [-73.154724, 21.175552],
                [-73.138062, 21.187496],
                [-73.086395, 21.266666],
                [-73.083618, 21.271385],
                [-73.075287, 21.292221],
                [-73.070282, 21.316666],
                [-73.06723, 21.319164],
                [-73.053345, 21.326385],
                [-73.031403, 21.332497],
                [-73.024445, 21.332497],
                [-73.017792, 21.331387],
                [-73.008896, 21.325832],
                [-73.006119, 21.321663],
                [-73.002502, 21.311665],
                [-73.003067, 21.304722],
                [-73.021393, 21.174999],
                [-73.023346, 21.169441],
                [-73.034454, 21.143887],
                [-73.037231, 21.139442]
              ]
            ],
            [
              [
                [-72.924728, 21.483608],
                [-72.929459, 21.48111],
                [-72.936401, 21.480831],
                [-72.950836, 21.481941],
                [-72.956955, 21.48111],
                [-73.011124, 21.458054],
                [-73.019455, 21.452499],
                [-73.023346, 21.449165],
                [-73.038071, 21.434998],
                [-73.047501, 21.444164],
                [-73.067505, 21.501389],
                [-73.067505, 21.507774],
                [-73.065002, 21.512497],
                [-73.056396, 21.518055],
                [-73.019455, 21.530552],
                [-72.978058, 21.540554],
                [-72.922501, 21.513611],
                [-72.918335, 21.510555],
                [-72.911392, 21.50333],
                [-72.910843, 21.498333],
                [-72.91362, 21.49361],
                [-72.916946, 21.489719],
                [-72.924728, 21.483608]
              ]
            ],
            [
              [
                [-72.829178, 22.382221],
                [-72.762222, 22.351665],
                [-72.74057, 22.336941],
                [-72.738617, 22.331944],
                [-72.737503, 22.326385],
                [-72.74028, 22.321663],
                [-72.75029, 22.309998],
                [-72.768341, 22.291943],
                [-72.781403, 22.284164],
                [-72.787506, 22.283054],
                [-72.810837, 22.309719],
                [-72.814178, 22.313332],
                [-72.860291, 22.347775],
                [-72.874725, 22.355831],
                [-72.885559, 22.360275],
                [-72.899734, 22.362499],
                [-72.988892, 22.366386],
                [-73.011124, 22.360275],
                [-73.020569, 22.355553],
                [-73.028625, 22.349163],
                [-73.042511, 22.341942],
                [-73.053894, 22.339165],
                [-73.060562, 22.339165],
                [-73.08168, 22.340553],
                [-73.156113, 22.359722],
                [-73.161957, 22.361664],
                [-73.164459, 22.365833],
                [-73.164734, 22.370831],
                [-73.16362, 22.376942],
                [-73.140015, 22.428333],
                [-73.08168, 22.443607],
                [-73.067505, 22.441944],
                [-73.023056, 22.432499],
                [-72.829178, 22.382221]
              ]
            ],
            [
              [
                [-73.610291, 22.57972],
                [-73.616394, 22.578609],
                [-73.623062, 22.579998],
                [-73.62056, 22.59972],
                [-73.604446, 22.613888],
                [-73.598343, 22.614719],
                [-73.594177, 22.611664],
                [-73.593338, 22.60611],
                [-73.59584, 22.601387],
                [-73.604446, 22.588333],
                [-73.610291, 22.57972]
              ]
            ],
            [
              [
                [-73.462784, 22.601944],
                [-73.465286, 22.597221],
                [-73.471115, 22.5975],
                [-73.551392, 22.612221],
                [-73.547501, 22.615276],
                [-73.538345, 22.618889],
                [-73.532227, 22.619999],
                [-73.51889, 22.620277],
                [-73.497787, 22.618889],
                [-73.484726, 22.616665],
                [-73.472229, 22.613609],
                [-73.464447, 22.606941],
                [-73.462784, 22.601944]
              ]
            ],
            [
              [
                [-74.377792, 22.538887],
                [-74.382233, 22.536663],
                [-74.386124, 22.537498],
                [-74.388062, 22.548611],
                [-74.387787, 22.555553],
                [-74.385284, 22.567776],
                [-74.379456, 22.576385],
                [-74.376114, 22.580276],
                [-74.284454, 22.665554],
                [-74.280014, 22.662498],
                [-74.275558, 22.653332],
                [-74.277512, 22.647778],
                [-74.316116, 22.599998],
                [-74.370285, 22.546108],
                [-74.377792, 22.538887]
              ]
            ],
            [
              [
                [-73.847229, 22.72361],
                [-73.836945, 22.551666],
                [-73.858902, 22.490276],
                [-73.867508, 22.469444],
                [-73.977234, 22.345554],
                [-73.994736, 22.334442],
                [-74.013062, 22.324444],
                [-74.104172, 22.295277],
                [-74.151672, 22.25222],
                [-74.155014, 22.247498],
                [-74.161667, 22.239719],
                [-74.180557, 22.223053],
                [-74.183899, 22.220554],
                [-74.276398, 22.170555],
                [-74.281403, 22.173054],
                [-74.276672, 22.216942],
                [-74.178345, 22.295277],
                [-74.039734, 22.396664],
                [-73.921402, 22.465553],
                [-73.910843, 22.468887],
                [-73.886948, 22.488052],
                [-73.867783, 22.512218],
                [-73.863892, 22.523052],
                [-73.863892, 22.529442],
                [-73.869736, 22.544167],
                [-73.878616, 22.562775],
                [-73.885559, 22.570274],
                [-73.892502, 22.577499],
                [-73.899445, 22.584721],
                [-73.921402, 22.599442],
                [-73.927505, 22.59861],
                [-73.938339, 22.594997],
                [-73.96167, 22.590275],
                [-73.974731, 22.589165],
                [-73.981949, 22.589722],
                [-73.993622, 22.59333],
                [-74.014725, 22.602219],
                [-74.035278, 22.618053],
                [-74.037781, 22.622498],
                [-73.983902, 22.671944],
                [-73.971954, 22.681664],
                [-73.950012, 22.694721],
                [-73.924728, 22.708885],
                [-73.857788, 22.729721],
                [-73.851959, 22.731388],
                [-73.849167, 22.728611],
                [-73.847229, 22.72361]
              ]
            ],
            [
              [
                [-74.073624, 22.66333],
                [-74.088898, 22.657497],
                [-74.15834, 22.671944],
                [-74.164169, 22.673611],
                [-74.204178, 22.686386],
                [-74.272507, 22.714443],
                [-74.343613, 22.803333],
                [-74.347504, 22.819443],
                [-74.346954, 22.826385],
                [-74.343903, 22.834999],
                [-74.305283, 22.843052],
                [-74.299454, 22.84111],
                [-74.218903, 22.808331],
                [-74.022781, 22.718887],
                [-74.017792, 22.716389],
                [-74.024445, 22.708611],
                [-74.073624, 22.66333]
              ]
            ],
            [
              [
                [-73.728897, 23.070553],
                [-73.777786, 23.066387],
                [-73.784729, 23.067497],
                [-73.811111, 23.084999],
                [-73.815292, 23.088055],
                [-73.813614, 23.096943],
                [-73.78334, 23.101944],
                [-73.770569, 23.103054],
                [-73.696396, 23.102776],
                [-73.688614, 23.102219],
                [-73.682785, 23.100555],
                [-73.672791, 23.095833],
                [-73.668335, 23.092777],
                [-73.665009, 23.088886],
                [-73.728897, 23.070553]
              ]
            ],
            [
              [
                [-75.551117, 23.431389],
                [-75.528625, 23.416943],
                [-75.525284, 23.41333],
                [-75.523056, 23.408333],
                [-75.525009, 23.404442],
                [-75.531677, 23.404163],
                [-75.541122, 23.409443],
                [-75.547226, 23.41111],
                [-75.589737, 23.421944],
                [-75.616394, 23.419998],
                [-75.622223, 23.421665],
                [-75.640839, 23.434998],
                [-75.644455, 23.438889],
                [-75.64917, 23.448055],
                [-75.651123, 23.453053],
                [-75.650848, 23.45583],
                [-75.638062, 23.456944],
                [-75.623901, 23.455555],
                [-75.565567, 23.439163],
                [-75.560287, 23.436943],
                [-75.551117, 23.431389]
              ]
            ],
            [
              [
                [-75.021393, 23.106388],
                [-75.016403, 23.104164],
                [-75.008896, 23.103611],
                [-74.967514, 23.104721],
                [-74.96167, 23.102776],
                [-74.925293, 23.086941],
                [-74.850281, 23.004166],
                [-74.847504, 23],
                [-74.841125, 22.964996],
                [-74.83223, 22.901108],
                [-74.827789, 22.865555],
                [-74.830566, 22.860832],
                [-74.835281, 22.85833],
                [-74.840561, 22.856667],
                [-74.847504, 22.856388],
                [-74.853348, 22.858055],
                [-74.900558, 22.948608],
                [-74.910004, 22.96722],
                [-74.925842, 23.006107],
                [-74.935837, 23.023888],
                [-74.963058, 23.060276],
                [-74.969177, 23.068054],
                [-74.972229, 23.07111],
                [-74.976669, 23.074165],
                [-74.986679, 23.078888],
                [-74.993057, 23.079998],
                [-75.035568, 23.084442],
                [-75.050568, 23.085552],
                [-75.065002, 23.085552],
                [-75.084167, 23.088886],
                [-75.092117, 23.096497],
                [-75.10112, 23.100832],
                [-75.123787, 23.113667],
                [-75.129288, 23.116831],
                [-75.168617, 23.142832],
                [-75.163284, 23.154163],
                [-75.161285, 23.156498],
                [-75.156944, 23.159496],
                [-75.13195, 23.150166],
                [-75.127609, 23.14583],
                [-75.126122, 23.143164],
                [-75.123947, 23.137333],
                [-75.126282, 23.135332],
                [-75.133781, 23.136166],
                [-75.142944, 23.140497],
                [-75.144791, 23.140665],
                [-75.143616, 23.137665],
                [-75.141617, 23.135498],
                [-75.126617, 23.123831],
                [-75.123947, 23.121998],
                [-75.090454, 23.106333],
                [-75.082779, 23.104664],
                [-75.073059, 23.109444],
                [-75.068344, 23.111942],
                [-75.085846, 23.137997],
                [-75.107513, 23.164719],
                [-75.108063, 23.270275],
                [-75.121124, 23.331108],
                [-75.126678, 23.346107],
                [-75.131393, 23.355274],
                [-75.136673, 23.363888],
                [-75.179459, 23.398052],
                [-75.257233, 23.489719],
                [-75.303345, 23.630276],
                [-75.30751, 23.667774],
                [-75.303894, 23.664997],
                [-75.286392, 23.646664],
                [-75.274734, 23.629997],
                [-75.109451, 23.348331],
                [-75.100006, 23.329998],
                [-75.098068, 23.324997],
                [-75.084732, 23.271111],
                [-75.082504, 23.259998],
                [-75.08223, 23.253609],
                [-75.084167, 23.235275],
                [-75.085556, 23.228886],
                [-75.085556, 23.209721],
                [-75.078903, 23.176109],
                [-75.072784, 23.154999],
                [-75.068893, 23.145275],
                [-75.066116, 23.140831],
                [-75.055008, 23.130554],
                [-75.030014, 23.112499],
                [-75.021393, 23.106388]
              ]
            ],
            [
              [
                [-75.771957, 23.499722],
                [-75.805557, 23.49472],
                [-75.805008, 23.500275],
                [-75.909729, 23.568054],
                [-75.934448, 23.580276],
                [-75.944458, 23.584999],
                [-75.955841, 23.589165],
                [-75.991119, 23.599442],
                [-76.032501, 23.646942],
                [-76.033066, 23.651943],
                [-76.028625, 23.672497],
                [-76.020279, 23.675278],
                [-75.996124, 23.674164],
                [-75.918625, 23.626389],
                [-75.771957, 23.499722]
              ]
            ],
            [
              [
                [-74.781952, 23.683887],
                [-74.791672, 23.664719],
                [-74.811401, 23.644997],
                [-74.819733, 23.639442],
                [-74.843063, 23.651943],
                [-74.882233, 23.661942],
                [-74.911392, 23.666943],
                [-74.918335, 23.666664],
                [-74.931396, 23.658607],
                [-74.936951, 23.656944],
                [-74.939453, 23.66111],
                [-74.945282, 23.676109],
                [-74.948624, 23.685276],
                [-74.935013, 23.693333],
                [-74.930283, 23.695831],
                [-74.85112, 23.716663],
                [-74.838348, 23.717777],
                [-74.801956, 23.71833],
                [-74.796951, 23.717499],
                [-74.778061, 23.694721],
                [-74.781952, 23.683887]
              ]
            ],
            [
              [
                [-76.363892, 24.07222],
                [-76.321671, 23.972775],
                [-76.327789, 23.973053],
                [-76.331955, 23.976109],
                [-76.335007, 23.980274],
                [-76.337784, 23.984722],
                [-76.396118, 24.079998],
                [-76.398621, 24.084164],
                [-76.400009, 24.089722],
                [-76.401398, 24.101665],
                [-76.398346, 24.113331],
                [-76.392227, 24.114166],
                [-76.386124, 24.112499],
                [-76.377228, 24.100277],
                [-76.363892, 24.07222]
              ]
            ],
            [
              [
                [-74.49028, 23.954441],
                [-74.496948, 23.954166],
                [-74.527786, 23.956665],
                [-74.541397, 23.969166],
                [-74.537231, 23.98],
                [-74.523621, 24.032219],
                [-74.523056, 24.039165],
                [-74.523621, 24.051666],
                [-74.530289, 24.076111],
                [-74.526947, 24.10083],
                [-74.52417, 24.105553],
                [-74.511124, 24.121109],
                [-74.506393, 24.123608],
                [-74.4664, 24.141109],
                [-74.460846, 24.142776],
                [-74.458344, 24.138611],
                [-74.425568, 24.080555],
                [-74.423615, 24.075554],
                [-74.435287, 24.041943],
                [-74.438065, 24.03722],
                [-74.486954, 23.958332],
                [-74.49028, 23.954441]
              ]
            ],
            [
              [
                [-77.946671, 24.149998],
                [-77.952225, 24.148052],
                [-77.958344, 24.148331],
                [-77.966949, 24.154163],
                [-77.970566, 24.157776],
                [-77.973892, 24.181389],
                [-77.973618, 24.188053],
                [-77.97168, 24.193607],
                [-77.851669, 24.252499],
                [-77.845566, 24.253887],
                [-77.838623, 24.254166],
                [-77.806122, 24.254719],
                [-77.800293, 24.25333],
                [-77.799179, 24.248886],
                [-77.800003, 24.242775],
                [-77.808899, 24.231388],
                [-77.885834, 24.175552],
                [-77.946671, 24.149998]
              ]
            ],
            [
              [
                [-77.936676, 24.22583],
                [-77.941681, 24.223331],
                [-77.995834, 24.225555],
                [-78.017227, 24.241108],
                [-78.01973, 24.245277],
                [-78.019455, 24.250832],
                [-78.015015, 24.25333],
                [-77.938614, 24.27972],
                [-77.931122, 24.279442],
                [-77.927505, 24.277222],
                [-77.924728, 24.273052],
                [-77.92334, 24.267498],
                [-77.923065, 24.261108],
                [-77.936676, 24.22583]
              ]
            ],
            [
              [
                [-76.468903, 24.209999],
                [-76.474731, 24.208885],
                [-76.503616, 24.217777],
                [-76.544724, 24.276386],
                [-76.546677, 24.281387],
                [-76.547226, 24.287498],
                [-76.545288, 24.290276],
                [-76.542511, 24.291386],
                [-76.536392, 24.291111],
                [-76.531403, 24.288887],
                [-76.523056, 24.282219],
                [-76.4664, 24.22361],
                [-76.464447, 24.218609],
                [-76.464737, 24.213055],
                [-76.468903, 24.209999]
              ]
            ],
            [
              [
                [-77.735565, 24.02861],
                [-77.728058, 24.028332],
                [-77.721954, 24.029163],
                [-77.716675, 24.031109],
                [-77.707779, 24.040276],
                [-77.702225, 24.049164],
                [-77.666672, 24.120552],
                [-77.63945, 24.193054],
                [-77.638336, 24.199165],
                [-77.634171, 24.209442],
                [-77.630005, 24.212776],
                [-77.619446, 24.216389],
                [-77.611954, 24.21611],
                [-77.606674, 24.213886],
                [-77.600281, 24.20583],
                [-77.582504, 24.18222],
                [-77.57695, 24.173885],
                [-77.551392, 24.129166],
                [-77.547501, 24.119442],
                [-77.546112, 24.113888],
                [-77.516953, 23.927776],
                [-77.516678, 23.921665],
                [-77.51973, 23.861111],
                [-77.524445, 23.82972],
                [-77.526123, 23.824165],
                [-77.55278, 23.753609],
                [-77.555847, 23.749722],
                [-77.566956, 23.739166],
                [-77.571671, 23.736664],
                [-77.58223, 23.733055],
                [-77.605286, 23.727219],
                [-77.612793, 23.727497],
                [-77.773056, 23.742775],
                [-77.774445, 23.746944],
                [-77.748611, 23.778889],
                [-77.744736, 23.782219],
                [-77.738892, 23.780552],
                [-77.681816, 23.760284],
                [-77.669647, 23.766369],
                [-77.668625, 23.779442],
                [-77.588898, 23.822777],
                [-77.573624, 23.836666],
                [-77.572784, 23.842777],
                [-77.575562, 23.847221],
                [-77.58168, 23.848888],
                [-77.588898, 23.849163],
                [-77.595001, 23.848053],
                [-77.605835, 23.844444],
                [-77.64418, 23.817776],
                [-77.696671, 23.776665],
                [-77.703613, 23.776108],
                [-77.72084, 23.781944],
                [-77.730286, 23.786942],
                [-77.808334, 23.878609],
                [-77.876678, 24.073055],
                [-77.852509, 24.160275],
                [-77.7164, 24.269444],
                [-77.701126, 24.284721],
                [-77.697235, 24.288055],
                [-77.68251, 24.294998],
                [-77.676392, 24.296108],
                [-77.670288, 24.294441],
                [-77.663071, 24.28722],
                [-77.634171, 24.25222],
                [-77.632782, 24.248055],
                [-77.636673, 24.230274],
                [-77.640015, 24.226109],
                [-77.652786, 24.22472],
                [-77.662781, 24.220276],
                [-77.670837, 24.213886],
                [-77.733337, 24.160831],
                [-77.755005, 24.138885],
                [-77.757233, 24.134163],
                [-77.741669, 24.030277],
                [-77.735565, 24.02861]
              ]
            ],
            [
              [
                [-77.686951, 24.337219],
                [-77.796677, 24.308052],
                [-77.801682, 24.310555],
                [-77.804733, 24.31472],
                [-77.806671, 24.319721],
                [-77.804169, 24.324165],
                [-77.793335, 24.332775],
                [-77.77417, 24.345833],
                [-77.771118, 24.347775],
                [-77.760284, 24.354164],
                [-77.744171, 24.359722],
                [-77.726959, 24.364441],
                [-77.703339, 24.36972],
                [-77.695847, 24.369164],
                [-77.692505, 24.365555],
                [-77.68251, 24.347221],
                [-77.683899, 24.34111],
                [-77.686951, 24.337219]
              ]
            ],
            [
              [
                [-75.314728, 24.213886],
                [-75.289734, 24.149719],
                [-75.290009, 24.144165],
                [-75.296951, 24.143887],
                [-75.333618, 24.152222],
                [-75.341949, 24.158333],
                [-75.34639, 24.16],
                [-75.428619, 24.150833],
                [-75.438339, 24.145832],
                [-75.462509, 24.122498],
                [-75.503342, 24.129444],
                [-75.507782, 24.1325],
                [-75.515015, 24.139721],
                [-75.525848, 24.155552],
                [-75.516678, 24.160553],
                [-75.472778, 24.181664],
                [-75.463058, 24.288055],
                [-75.467514, 24.290833],
                [-75.47084, 24.294441],
                [-75.506393, 24.344166],
                [-75.518066, 24.360554],
                [-75.530014, 24.38361],
                [-75.580231, 24.467276],
                [-75.583733, 24.476276],
                [-75.585068, 24.482611],
                [-75.587395, 24.488441],
                [-75.594902, 24.505775],
                [-75.596565, 24.508442],
                [-75.633057, 24.552277],
                [-75.637398, 24.55661],
                [-75.691116, 24.621944],
                [-75.695557, 24.624996],
                [-75.723068, 24.642498],
                [-75.737503, 24.643887],
                [-75.744446, 24.645832],
                [-75.75473, 24.650276],
                [-75.759171, 24.653332],
                [-75.760834, 24.658333],
                [-75.758347, 24.663055],
                [-75.739456, 24.695],
                [-75.734726, 24.697498],
                [-75.728622, 24.698608],
                [-75.715012, 24.696388],
                [-75.710007, 24.694164],
                [-75.684174, 24.68222],
                [-75.637512, 24.654999],
                [-75.633057, 24.651943],
                [-75.626678, 24.643887],
                [-75.623611, 24.63472],
                [-75.600677, 24.576777],
                [-75.601341, 24.568443],
                [-75.596512, 24.552942],
                [-75.593506, 24.54361],
                [-75.523895, 24.429996],
                [-75.476669, 24.369442],
                [-75.472778, 24.365833],
                [-75.451401, 24.350555],
                [-75.441116, 24.346107],
                [-75.431671, 24.340553],
                [-75.405563, 24.322498],
                [-75.398346, 24.315277],
                [-75.395004, 24.311665],
                [-75.319458, 24.223053],
                [-75.314728, 24.213886]
              ]
            ],
            [
              [
                [-77.436401, 25.008053],
                [-77.465836, 24.986664],
                [-77.473343, 24.986942],
                [-77.541397, 24.991943],
                [-77.546677, 24.994999],
                [-77.557785, 25.011944],
                [-77.561401, 25.021111],
                [-77.561401, 25.027496],
                [-77.55307, 25.033333],
                [-77.518341, 25.056389],
                [-77.505569, 25.062775],
                [-77.455566, 25.075554],
                [-77.449448, 25.076942],
                [-77.435837, 25.077499],
                [-77.358902, 25.07972],
                [-77.330002, 25.076942],
                [-77.270004, 25.060555],
                [-77.260559, 25.054443],
                [-77.258621, 25.049442],
                [-77.258057, 25.043331],
                [-77.262222, 25.039997],
                [-77.341125, 25.016941],
                [-77.431122, 25.009998],
                [-77.436401, 25.008053]
              ]
            ],
            [
              [
                [-78.191956, 25.203609],
                [-78.044724, 25.180275],
                [-78.038895, 25.178608],
                [-78.028336, 25.173885],
                [-77.990845, 25.152496],
                [-77.986389, 25.149719],
                [-77.982788, 25.146111],
                [-77.945557, 24.991665],
                [-77.886398, 24.85611],
                [-77.881668, 24.846943],
                [-77.873337, 24.834164],
                [-77.842789, 24.794441],
                [-77.829178, 24.779442],
                [-77.814728, 24.764999],
                [-77.806671, 24.758331],
                [-77.773621, 24.732777],
                [-77.743347, 24.619999],
                [-77.72168, 24.528889],
                [-77.716675, 24.506664],
                [-77.7164, 24.500275],
                [-77.717224, 24.494164],
                [-77.72084, 24.48333],
                [-77.725281, 24.473331],
                [-77.738342, 24.456387],
                [-77.743057, 24.453888],
                [-77.760559, 24.449997],
                [-77.804733, 24.443607],
                [-77.853348, 24.425552],
                [-77.857788, 24.423054],
                [-77.861389, 24.419167],
                [-77.863892, 24.414444],
                [-77.866669, 24.402775],
                [-77.865845, 24.390274],
                [-77.861954, 24.380554],
                [-77.864182, 24.375832],
                [-77.871399, 24.36861],
                [-78.011398, 24.277222],
                [-78.021393, 24.272778],
                [-78.034454, 24.271385],
                [-78.040283, 24.273052],
                [-78.044724, 24.275833],
                [-78.047501, 24.280277],
                [-78.050842, 24.290554],
                [-78.063065, 24.333332],
                [-78.068619, 24.354721],
                [-78.070847, 24.359722],
                [-78.079178, 24.372498],
                [-78.086395, 24.379719],
                [-78.095291, 24.385555],
                [-78.214172, 24.459164],
                [-78.280838, 24.489441],
                [-78.311111, 24.503887],
                [-78.320847, 24.508888],
                [-78.343613, 24.522221],
                [-78.360291, 24.534721],
                [-78.390839, 24.562775],
                [-78.440567, 24.613609],
                [-78.439728, 24.61972],
                [-78.325562, 24.708885],
                [-78.320847, 24.711388],
                [-78.314728, 24.711109],
                [-78.309006, 24.70672],
                [-78.299454, 24.697498],
                [-78.292511, 24.683609],
                [-78.290558, 24.678608],
                [-78.288071, 24.6675],
                [-78.287781, 24.65583],
                [-78.291397, 24.644997],
                [-78.304733, 24.621944],
                [-78.328339, 24.602219],
                [-78.332504, 24.59222],
                [-78.33139, 24.586388],
                [-78.327515, 24.583054],
                [-78.321671, 24.581387],
                [-78.248611, 24.561943],
                [-78.235565, 24.559998],
                [-78.229446, 24.561108],
                [-78.224167, 24.563053],
                [-78.220291, 24.566387],
                [-78.19278, 24.5975],
                [-78.193069, 24.610554],
                [-78.194458, 24.616108],
                [-78.256668, 24.722775],
                [-78.266678, 24.721107],
                [-78.261948, 24.704998],
                [-78.260284, 24.679996],
                [-78.261948, 24.674721],
                [-78.265015, 24.670555],
                [-78.271957, 24.670277],
                [-78.275558, 24.673885],
                [-78.305847, 24.721107],
                [-78.295013, 24.76083],
                [-78.291397, 24.771664],
                [-78.288895, 24.776386],
                [-78.275284, 24.791664],
                [-78.23584, 24.834721],
                [-78.210556, 24.85722],
                [-78.205002, 24.858887],
                [-78.199448, 24.867775],
                [-78.177231, 24.917221],
                [-78.156677, 25.023052],
                [-78.155563, 25.03611],
                [-78.161118, 25.057777],
                [-78.165283, 25.067497],
                [-78.188065, 25.114441],
                [-78.21167, 25.173885],
                [-78.214447, 25.184998],
                [-78.213623, 25.197498],
                [-78.211945, 25.202774],
                [-78.205002, 25.201942],
                [-78.191956, 25.203609]
              ]
            ],
            [
              [
                [-76.53334, 25.398331],
                [-76.410278, 25.340275],
                [-76.349701, 25.343281],
                [-76.328781, 25.319574],
                [-76.323204, 25.297728],
                [-76.313065, 25.28722],
                [-76.132233, 25.146111],
                [-76.121674, 25.135277],
                [-76.118896, 25.131107],
                [-76.113068, 25.116386],
                [-76.110565, 25.105],
                [-76.109177, 25.080276],
                [-76.110001, 25.066666],
                [-76.13945, 24.846107],
                [-76.168335, 24.689999],
                [-76.216125, 24.715275],
                [-76.320282, 24.79361],
                [-76.327515, 24.800831],
                [-76.335846, 24.81361],
                [-76.337784, 24.818607],
                [-76.338058, 24.824997],
                [-76.211121, 24.865833],
                [-76.145004, 25.009163],
                [-76.14389, 25.015274],
                [-76.143616, 25.06472],
                [-76.145004, 25.077221],
                [-76.151947, 25.104443],
                [-76.163345, 25.127777],
                [-76.171677, 25.140831],
                [-76.175003, 25.144444],
                [-76.1875, 25.153889],
                [-76.208069, 25.16333],
                [-76.24556, 25.172222],
                [-76.254456, 25.178055],
                [-76.314453, 25.253609],
                [-76.325562, 25.270554],
                [-76.333069, 25.284164],
                [-76.336945, 25.293888],
                [-76.339737, 25.298332],
                [-76.342514, 25.302498],
                [-76.355835, 25.318333],
                [-76.366394, 25.321663],
                [-76.378342, 25.324997],
                [-76.398621, 25.328053],
                [-76.506958, 25.35083],
                [-76.513062, 25.352497],
                [-76.522781, 25.357777],
                [-76.531403, 25.363609],
                [-76.539459, 25.370277],
                [-76.54306, 25.373886],
                [-76.552231, 25.386108],
                [-76.559448, 25.39333],
                [-76.575562, 25.406666],
                [-76.587784, 25.416111],
                [-76.59639, 25.422222],
                [-76.606949, 25.426666],
                [-76.618896, 25.429996],
                [-76.632507, 25.43222],
                [-76.715836, 25.441666],
                [-76.735291, 25.559166],
                [-76.678345, 25.544441],
                [-76.680283, 25.489441],
                [-76.53334, 25.398331]
              ]
            ],
            [
              [
                [-77.821945, 25.702221],
                [-77.828339, 25.701111],
                [-77.838348, 25.712498],
                [-77.888062, 25.774441],
                [-77.890015, 25.779442],
                [-77.888062, 25.785],
                [-77.875, 25.786388],
                [-77.864456, 25.781944],
                [-77.8564, 25.775276],
                [-77.819458, 25.719997],
                [-77.816956, 25.715832],
                [-77.815567, 25.710278],
                [-77.818069, 25.705555],
                [-77.821945, 25.702221]
              ]
            ],
            [
              [
                [-77.563339, 26.268887],
                [-77.568619, 26.26722],
                [-77.581116, 26.318607],
                [-77.58168, 26.324718],
                [-77.575012, 26.335552],
                [-77.57251, 26.336109],
                [-77.566391, 26.335831],
                [-77.537231, 26.306942],
                [-77.536957, 26.300831],
                [-77.539459, 26.296108],
                [-77.559174, 26.272221],
                [-77.563339, 26.268887]
              ]
            ],
            [
              [
                [-77.917236, 26.745277],
                [-77.908615, 26.689442],
                [-77.938065, 26.642498],
                [-77.946945, 26.648331],
                [-77.957504, 26.652775],
                [-77.963623, 26.654442],
                [-78.013336, 26.658607],
                [-78.021118, 26.658886],
                [-78.237503, 26.635277],
                [-78.243622, 26.634163],
                [-78.298889, 26.623886],
                [-78.366119, 26.610832],
                [-78.546402, 26.556664],
                [-78.602783, 26.530552],
                [-78.678345, 26.496109],
                [-78.689178, 26.492496],
                [-78.708618, 26.489719],
                [-78.723068, 26.489719],
                [-78.730011, 26.490555],
                [-78.753342, 26.497498],
                [-78.758896, 26.499722],
                [-78.772232, 26.50861],
                [-78.819168, 26.5425],
                [-78.827225, 26.549164],
                [-78.968613, 26.67083],
                [-78.978897, 26.695274],
                [-78.874451, 26.641388],
                [-78.840012, 26.616943],
                [-78.820282, 26.600277],
                [-78.797791, 26.585831],
                [-78.718903, 26.583611],
                [-78.704178, 26.583885],
                [-78.698059, 26.584999],
                [-78.681671, 26.590553],
                [-78.672226, 26.595554],
                [-78.656952, 26.609444],
                [-78.653625, 26.613609],
                [-78.602783, 26.683887],
                [-78.599731, 26.695274],
                [-78.600281, 26.701664],
                [-78.51889, 26.734165],
                [-78.376114, 26.693054],
                [-78.363892, 26.68972],
                [-78.349457, 26.68861],
                [-78.327225, 26.688332],
                [-78.310837, 26.693886],
                [-78.246124, 26.710552],
                [-78.198624, 26.708611],
                [-78.127228, 26.702499],
                [-78.120285, 26.701385],
                [-78.1064, 26.702221],
                [-77.983612, 26.72472],
                [-77.966675, 26.729443],
                [-77.961945, 26.731941],
                [-77.957779, 26.735275],
                [-77.950562, 26.742496],
                [-77.944458, 26.751389],
                [-77.917236, 26.745277]
              ]
            ],
            [
              [
                [-77.735001, 26.914444],
                [-77.696945, 26.90583],
                [-77.661957, 26.903053],
                [-77.635559, 26.90583],
                [-77.606674, 26.910831],
                [-77.602509, 26.914165],
                [-77.597229, 26.914444],
                [-77.591125, 26.913055],
                [-77.573624, 26.907497],
                [-77.546677, 26.896664],
                [-77.536957, 26.891388],
                [-77.508057, 26.868889],
                [-77.500839, 26.862862],
                [-77.472778, 26.836388],
                [-77.420837, 26.785831],
                [-77.303619, 26.668053],
                [-77.301682, 26.663055],
                [-77.277786, 26.623333],
                [-77.275009, 26.620552],
                [-77.269455, 26.618332],
                [-77.226395, 26.607498],
                [-77.156403, 26.579998],
                [-77.089737, 26.548885],
                [-77.076401, 26.539997],
                [-77.051392, 26.520832],
                [-77.047791, 26.51722],
                [-77.042236, 26.508888],
                [-77.039734, 26.497776],
                [-77.027786, 26.359722],
                [-77.050003, 26.306389],
                [-77.053619, 26.309998],
                [-77.059723, 26.310276],
                [-77.073059, 26.308887],
                [-77.078613, 26.306942],
                [-77.109726, 26.286388],
                [-77.143341, 26.261944],
                [-77.151398, 26.255276],
                [-77.164459, 26.239441],
                [-77.168335, 26.228611],
                [-77.173889, 26.197777],
                [-77.188614, 26.074718],
                [-77.189728, 26.027222],
                [-77.1875, 25.956944],
                [-77.188339, 25.929443],
                [-77.193069, 25.898052],
                [-77.194458, 25.891941],
                [-77.200562, 25.884163],
                [-77.204727, 25.880833],
                [-77.218063, 25.879166],
                [-77.2314, 25.881386],
                [-77.236954, 25.88361],
                [-77.24556, 25.889442],
                [-77.268616, 25.916664],
                [-77.279175, 25.934444],
                [-77.289169, 25.958885],
                [-77.296112, 25.973053],
                [-77.302505, 25.980831],
                [-77.306946, 25.983887],
                [-77.318069, 25.987778],
                [-77.398056, 26.026386],
                [-77.307785, 26.076111],
                [-77.222778, 26.143608],
                [-77.218903, 26.146942],
                [-77.22612, 26.247776],
                [-77.232513, 26.406109],
                [-77.231949, 26.415833],
                [-77.225845, 26.441666],
                [-77.221115, 26.444164],
                [-77.208069, 26.445553],
                [-77.200012, 26.452221],
                [-77.154449, 26.520275],
                [-77.148056, 26.536388],
                [-77.147781, 26.543331],
                [-77.148895, 26.548885],
                [-77.151947, 26.553055],
                [-77.156403, 26.55611],
                [-77.181671, 26.568333],
                [-77.202515, 26.577499],
                [-77.214737, 26.58083],
                [-77.227783, 26.583332],
                [-77.273056, 26.584721],
                [-77.292786, 26.588333],
                [-77.330002, 26.5975],
                [-77.340561, 26.602219],
                [-77.345001, 26.605274],
                [-77.346115, 26.610832],
                [-77.349731, 26.640553],
                [-77.490845, 26.817219],
                [-77.525558, 26.856388],
                [-77.530014, 26.859444],
                [-77.539734, 26.864719],
                [-77.545837, 26.866386],
                [-77.553345, 26.866665],
                [-77.560562, 26.866386],
                [-77.59584, 26.857498],
                [-77.631958, 26.85833],
                [-77.654175, 26.859997],
                [-77.660843, 26.861111],
                [-77.672501, 26.864998],
                [-77.723892, 26.882221],
                [-77.789734, 26.900833],
                [-77.801956, 26.904163],
                [-77.834732, 26.910275],
                [-77.893616, 26.904442],
                [-77.906677, 26.903053],
                [-77.950287, 26.897778],
                [-77.916946, 26.915833],
                [-77.906113, 26.919441],
                [-77.840836, 26.928333],
                [-77.82695, 26.929165],
                [-77.735001, 26.914444]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Bahamas",
          "density": "29.4",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 13
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-64.660843, 18.383888],
                [-64.667236, 18.383888],
                [-64.699173, 18.391388],
                [-64.700287, 18.395554],
                [-64.697235, 18.400276],
                [-64.668335, 18.428333],
                [-64.652512, 18.441109],
                [-64.640289, 18.445],
                [-64.611679, 18.452499],
                [-64.575012, 18.458054],
                [-64.56778, 18.457222],
                [-64.562225, 18.455276],
                [-64.558334, 18.451942],
                [-64.556946, 18.446941],
                [-64.556946, 18.440552],
                [-64.561951, 18.425831],
                [-64.563614, 18.421387],
                [-64.567505, 18.418331],
                [-64.57695, 18.413887],
                [-64.660843, 18.383888]
              ]
            ],
            [
              [
                [-64.422501, 18.43861],
                [-64.436401, 18.431389],
                [-64.43779, 18.434998],
                [-64.438614, 18.440552],
                [-64.438339, 18.453053],
                [-64.415558, 18.503609],
                [-64.412231, 18.506107],
                [-64.325562, 18.509998],
                [-64.322235, 18.506107],
                [-64.323624, 18.501663],
                [-64.326675, 18.496944],
                [-64.330566, 18.494164],
                [-64.422501, 18.43861]
              ]
            ],
            [
              [
                [-64.311401, 18.746109],
                [-64.305008, 18.738609],
                [-64.292511, 18.729164],
                [-64.278336, 18.721107],
                [-64.271957, 18.713333],
                [-64.26973, 18.708885],
                [-64.26918, 18.703331],
                [-64.270004, 18.696388],
                [-64.275284, 18.695],
                [-64.278061, 18.691944],
                [-64.283066, 18.693333],
                [-64.295288, 18.703331],
                [-64.302505, 18.710552],
                [-64.315842, 18.719166],
                [-64.335007, 18.72361],
                [-64.367233, 18.72361],
                [-64.388062, 18.725555],
                [-64.400284, 18.729164],
                [-64.408066, 18.735832],
                [-64.407227, 18.741943],
                [-64.401398, 18.741108],
                [-64.396118, 18.742496],
                [-64.384171, 18.743889],
                [-64.357224, 18.742496],
                [-64.351395, 18.743053],
                [-64.329727, 18.748608],
                [-64.316956, 18.748333],
                [-64.311401, 18.746109]
              ]
            ]
          ]
        },
        "properties": {
          "name": "British Virgin Islands",
          "density": "210",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 215
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-78.327789, 20.513611],
                [-78.333344, 20.511665],
                [-78.343903, 20.515553],
                [-78.382782, 20.535],
                [-78.391403, 20.540833],
                [-78.460556, 20.602219],
                [-78.458344, 20.605553],
                [-78.453064, 20.608055],
                [-78.446945, 20.609444],
                [-78.440567, 20.609722],
                [-78.34584, 20.569443],
                [-78.341675, 20.566387],
                [-78.334732, 20.559166],
                [-78.332504, 20.554443],
                [-78.326126, 20.533333],
                [-78.323624, 20.522221],
                [-78.323898, 20.516941],
                [-78.327789, 20.513611]
              ]
            ],
            [
              [
                [-78.381958, 20.637775],
                [-78.39418, 20.636108],
                [-78.400848, 20.637218],
                [-78.406113, 20.639442],
                [-78.412231, 20.647221],
                [-78.414459, 20.652222],
                [-78.412781, 20.664444],
                [-78.410278, 20.669167],
                [-78.402786, 20.675831],
                [-78.396667, 20.676941],
                [-78.38945, 20.676666],
                [-78.387512, 20.673054],
                [-78.377792, 20.644997],
                [-78.377228, 20.640274],
                [-78.381958, 20.637775]
              ]
            ],
            [
              [
                [-78.441681, 20.710552],
                [-78.513336, 20.689999],
                [-78.540283, 20.699997],
                [-78.547501, 20.703609],
                [-78.54306, 20.706108],
                [-78.458618, 20.731667],
                [-78.436676, 20.724163],
                [-78.43251, 20.721107],
                [-78.433334, 20.716389],
                [-78.437225, 20.713055],
                [-78.441681, 20.710552]
              ]
            ],
            [
              [
                [-78.767502, 20.706108],
                [-78.773346, 20.706108],
                [-78.778625, 20.708332],
                [-78.815567, 20.736111],
                [-78.822784, 20.743332],
                [-78.825562, 20.747498],
                [-78.821945, 20.756943],
                [-78.816116, 20.758053],
                [-78.810287, 20.756664],
                [-78.794449, 20.750275],
                [-78.772232, 20.736385],
                [-78.763626, 20.730553],
                [-78.753067, 20.719997],
                [-78.753891, 20.713886],
                [-78.762512, 20.707775],
                [-78.767502, 20.706108]
              ]
            ],
            [
              [
                [-75.487228, 20.728886],
                [-75.493896, 20.728611],
                [-75.571121, 20.755833],
                [-75.578903, 20.762497],
                [-75.579453, 20.774998],
                [-75.578064, 20.781109],
                [-75.575836, 20.783054],
                [-75.532501, 20.794441],
                [-75.526398, 20.795277],
                [-75.51973, 20.794441],
                [-75.515015, 20.791943],
                [-75.511124, 20.78833],
                [-75.487228, 20.749722],
                [-75.484726, 20.745277],
                [-75.484451, 20.740555],
                [-75.487228, 20.728886]
              ]
            ],
            [
              [
                [-78.847504, 20.771664],
                [-78.852509, 20.771111],
                [-78.88028, 20.776386],
                [-78.912231, 20.783054],
                [-78.935837, 20.789444],
                [-78.955292, 20.798611],
                [-78.959457, 20.801388],
                [-78.963058, 20.804996],
                [-78.965836, 20.809998],
                [-78.948624, 20.832775],
                [-78.944168, 20.835278],
                [-78.9375, 20.835831],
                [-78.931671, 20.834164],
                [-78.926392, 20.831944],
                [-78.869446, 20.806389],
                [-78.86084, 20.800552],
                [-78.849731, 20.783607],
                [-78.847778, 20.778053],
                [-78.847504, 20.771664]
              ]
            ],
            [
              [
                [-78.977783, 20.839443],
                [-78.983063, 20.837498],
                [-79.051117, 20.871666],
                [-79.073334, 20.885555],
                [-79.077515, 20.893887],
                [-79.068344, 20.899166],
                [-79.045013, 20.895554],
                [-78.969727, 20.874165],
                [-78.967789, 20.869164],
                [-78.967224, 20.863052],
                [-78.974731, 20.84333],
                [-78.977783, 20.839443]
              ]
            ],
            [
              [
                [-79.084457, 20.898331],
                [-79.088348, 20.894997],
                [-79.098618, 20.898052],
                [-79.103622, 20.900276],
                [-79.118057, 20.907776],
                [-79.218063, 20.990555],
                [-79.225571, 21.000553],
                [-79.206116, 21.009441],
                [-79.194458, 21.011944],
                [-79.1875, 21.012218],
                [-79.176682, 21.00861],
                [-79.152237, 20.989719],
                [-79.107513, 20.948887],
                [-79.08139, 20.924999],
                [-79.077515, 20.921387],
                [-79.075012, 20.917221],
                [-79.075012, 20.913055],
                [-79.084457, 20.898331]
              ]
            ],
            [
              [
                [-79.406403, 21.104721],
                [-79.412231, 21.103611],
                [-79.44278, 21.109997],
                [-79.446671, 21.113609],
                [-79.451126, 21.122776],
                [-79.440002, 21.12722],
                [-79.41806, 21.126389],
                [-79.402512, 21.121109],
                [-79.398895, 21.117496],
                [-79.39917, 21.111942],
                [-79.402237, 21.108055],
                [-79.406403, 21.104721]
              ]
            ],
            [
              [
                [-79.302231, 21.064442],
                [-79.314728, 21.063053],
                [-79.327225, 21.065552],
                [-79.342514, 21.07222],
                [-79.35112, 21.078053],
                [-79.381668, 21.112778],
                [-79.384171, 21.116943],
                [-79.384171, 21.11972],
                [-79.375839, 21.125832],
                [-79.363892, 21.128052],
                [-79.3564, 21.127777],
                [-79.350571, 21.12611],
                [-79.3414, 21.121109],
                [-79.337784, 21.117496],
                [-79.303619, 21.080555],
                [-79.2939, 21.069164],
                [-79.296951, 21.066387],
                [-79.302231, 21.064442]
              ]
            ],
            [
              [
                [-81.530838, 21.600277],
                [-81.5439, 21.599163],
                [-81.561676, 21.60083],
                [-81.561111, 21.605831],
                [-81.556946, 21.622776],
                [-81.55307, 21.626389],
                [-81.391678, 21.710278],
                [-81.386124, 21.712219],
                [-81.38028, 21.713333],
                [-81.373062, 21.713333],
                [-81.366959, 21.711941],
                [-81.366394, 21.708332],
                [-81.373337, 21.700832],
                [-81.376953, 21.697498],
                [-81.414459, 21.66333],
                [-81.421951, 21.656666],
                [-81.435287, 21.648609],
                [-81.509735, 21.608055],
                [-81.524734, 21.601665],
                [-81.530838, 21.600277]
              ]
            ],
            [
              [
                [-82.545288, 21.57111],
                [-82.598618, 21.538055],
                [-82.636124, 21.518055],
                [-82.700287, 21.488609],
                [-82.710556, 21.484444],
                [-82.743347, 21.474163],
                [-82.865845, 21.437775],
                [-82.897232, 21.432777],
                [-82.95668, 21.448055],
                [-82.996124, 21.453331],
                [-83.066391, 21.460278],
                [-83.072235, 21.461666],
                [-83.076675, 21.464443],
                [-83.145279, 21.523888],
                [-83.148895, 21.527222],
                [-83.151947, 21.531387],
                [-83.164459, 21.554443],
                [-83.191681, 21.616108],
                [-83.193069, 21.621387],
                [-83.19223, 21.627777],
                [-83.188614, 21.62833],
                [-83.115845, 21.578609],
                [-83.112793, 21.574444],
                [-83.113068, 21.568886],
                [-83.11084, 21.564163],
                [-83.107224, 21.560555],
                [-83.084167, 21.548054],
                [-83.073898, 21.54361],
                [-83.061401, 21.541386],
                [-83.021393, 21.5425],
                [-82.955292, 21.562222],
                [-82.947784, 21.568886],
                [-82.937225, 21.579998],
                [-82.934723, 21.584721],
                [-82.934174, 21.590832],
                [-82.939178, 21.599998],
                [-82.948059, 21.605553],
                [-83.013626, 21.677219],
                [-83.075562, 21.764164],
                [-83.089447, 21.785553],
                [-83.088348, 21.805553],
                [-83.08667, 21.811108],
                [-83.079178, 21.831665],
                [-83.072235, 21.841942],
                [-82.974457, 21.942776],
                [-82.86528, 21.932499],
                [-82.826675, 21.927776],
                [-82.820847, 21.926388],
                [-82.715836, 21.890274],
                [-82.699722, 21.833611],
                [-82.666397, 21.800831],
                [-82.614731, 21.76833],
                [-82.606674, 21.761387],
                [-82.598068, 21.748886],
                [-82.593063, 21.739998],
                [-82.54306, 21.589722],
                [-82.5439, 21.576664],
                [-82.545288, 21.57111]
              ]
            ],
            [
              [
                [-77.915054, 22.094696],
                [-77.878708, 22.071795],
                [-77.858345, 22.07543],
                [-77.852165, 22.088881],
                [-77.799454, 22.101604],
                [-77.732567, 22.076521],
                [-77.65918, 22.071388],
                [-77.653336, 22.069721],
                [-77.648895, 22.066666],
                [-77.634735, 22.052219],
                [-77.621124, 22.03083],
                [-77.621399, 22.025555],
                [-77.638336, 21.953053],
                [-77.673065, 21.946388],
                [-77.693619, 21.922497],
                [-77.705566, 21.908607],
                [-77.764725, 21.956665],
                [-77.800568, 21.965832],
                [-77.885834, 22.00333],
                [-77.932785, 22.0425],
                [-77.936401, 22.046108],
                [-77.941956, 22.054722],
                [-77.944458, 22.06583],
                [-77.947235, 22.098331],
                [-77.937225, 22.102776],
                [-77.915054, 22.094696]
              ]
            ],
            [
              [
                [-77.84639, 22.106388],
                [-77.864731, 22.095554],
                [-77.895279, 22.0975],
                [-77.961395, 22.125832],
                [-77.998611, 22.146111],
                [-78.040558, 22.183052],
                [-78.044174, 22.186665],
                [-78.046112, 22.189442],
                [-78.045288, 22.194164],
                [-77.997513, 22.285275],
                [-77.863068, 22.220276],
                [-77.859451, 22.218052],
                [-77.829727, 22.158886],
                [-77.828339, 22.153332],
                [-77.829453, 22.146942],
                [-77.842514, 22.109722],
                [-77.84639, 22.106388]
              ]
            ],
            [
              [
                [-77.777237, 22.195],
                [-77.771667, 22.168053],
                [-77.776947, 22.170277],
                [-77.80751, 22.193607],
                [-77.813339, 22.202221],
                [-77.842514, 22.253887],
                [-77.850006, 22.29472],
                [-77.836121, 22.289997],
                [-77.777237, 22.195]
              ]
            ],
            [
              [
                [-78.118622, 22.413887],
                [-78.099457, 22.397221],
                [-78.092224, 22.389999],
                [-78.08667, 22.381386],
                [-78.022507, 22.276665],
                [-78.019455, 22.261944],
                [-78.057236, 22.26722],
                [-78.156952, 22.301666],
                [-78.162231, 22.303886],
                [-78.186111, 22.317219],
                [-78.190292, 22.320274],
                [-78.193069, 22.324444],
                [-78.174454, 22.334721],
                [-78.174454, 22.340275],
                [-78.185562, 22.35722],
                [-78.191956, 22.364998],
                [-78.26445, 22.408054],
                [-78.277237, 22.406666],
                [-78.281677, 22.403889],
                [-78.288895, 22.396664],
                [-78.294724, 22.398331],
                [-78.311401, 22.403889],
                [-78.310562, 22.41],
                [-78.306671, 22.41333],
                [-78.274734, 22.424442],
                [-78.208618, 22.436943],
                [-78.20195, 22.437222],
                [-78.165558, 22.433609],
                [-78.159454, 22.431942],
                [-78.118622, 22.413887]
              ]
            ],
            [
              [
                [-78.3414, 22.533886],
                [-78.278061, 22.449997],
                [-78.276123, 22.445],
                [-78.276398, 22.43972],
                [-78.280289, 22.436386],
                [-78.29306, 22.434719],
                [-78.298889, 22.436386],
                [-78.405838, 22.428886],
                [-78.410278, 22.426388],
                [-78.425293, 22.412498],
                [-78.396957, 22.441666],
                [-78.394455, 22.446388],
                [-78.39418, 22.451942],
                [-78.395569, 22.457497],
                [-78.400009, 22.460552],
                [-78.419174, 22.463886],
                [-78.464737, 22.458332],
                [-78.474167, 22.453053],
                [-78.481949, 22.446388],
                [-78.486954, 22.445274],
                [-78.493896, 22.445],
                [-78.536957, 22.448055],
                [-78.653061, 22.486942],
                [-78.690567, 22.5],
                [-78.694168, 22.503609],
                [-78.696121, 22.50861],
                [-78.696671, 22.514721],
                [-78.694458, 22.531109],
                [-78.692505, 22.535275],
                [-78.677505, 22.555553],
                [-78.673065, 22.558052],
                [-78.645569, 22.553608],
                [-78.638901, 22.552498],
                [-78.603348, 22.545555],
                [-78.597504, 22.544167],
                [-78.583344, 22.52972],
                [-78.571671, 22.526386],
                [-78.551392, 22.523609],
                [-78.536667, 22.523052],
                [-78.459457, 22.527496],
                [-78.448624, 22.53833],
                [-78.428345, 22.553886],
                [-78.423065, 22.555832],
                [-78.404175, 22.558609],
                [-78.397507, 22.558887],
                [-78.390839, 22.558052],
                [-78.349731, 22.539719],
                [-78.3414, 22.533886]
              ]
            ],
            [
              [
                [-78.976395, 22.637497],
                [-78.981674, 22.637218],
                [-79.025848, 22.651108],
                [-79.054459, 22.665276],
                [-78.976669, 22.669441],
                [-78.968903, 22.669167],
                [-78.958618, 22.664444],
                [-78.955292, 22.660831],
                [-78.952515, 22.656666],
                [-78.954178, 22.651108],
                [-78.962784, 22.645275],
                [-78.976395, 22.637497]
              ]
            ],
            [
              [
                [-79.323059, 22.614719],
                [-79.329727, 22.614166],
                [-79.37001, 22.638332],
                [-79.468903, 22.689999],
                [-79.473892, 22.692219],
                [-79.517792, 22.714165],
                [-79.627502, 22.777222],
                [-79.631119, 22.78083],
                [-79.632233, 22.786388],
                [-79.632507, 22.799999],
                [-79.602234, 22.812775],
                [-79.587784, 22.813053],
                [-79.58139, 22.812222],
                [-79.576126, 22.809998],
                [-79.568069, 22.803608],
                [-79.564453, 22.799999],
                [-79.54306, 22.77861],
                [-79.5289, 22.7575],
                [-79.521667, 22.750275],
                [-79.495285, 22.732777],
                [-79.490845, 22.73],
                [-79.430847, 22.701664],
                [-79.380005, 22.678608],
                [-79.376114, 22.675831],
                [-79.372223, 22.672222],
                [-79.340836, 22.639442],
                [-79.325287, 22.61972],
                [-79.323059, 22.614719]
              ]
            ],
            [
              [
                [-79.891953, 22.928333],
                [-79.936951, 22.920555],
                [-79.94278, 22.92083],
                [-79.946671, 22.924442],
                [-79.955002, 22.936943],
                [-79.958618, 22.947498],
                [-79.957504, 22.948887],
                [-79.953064, 22.951385],
                [-79.934723, 22.960552],
                [-79.928619, 22.961666],
                [-79.910568, 22.963886],
                [-79.883057, 22.965275],
                [-79.878342, 22.963608],
                [-79.878067, 22.957497],
                [-79.87973, 22.951942],
                [-79.891953, 22.928333]
              ]
            ],
            [
              [
                [-80.232788, 22.994999],
                [-80.229446, 22.964443],
                [-80.229736, 22.958885],
                [-80.234177, 22.956387],
                [-80.243896, 22.961388],
                [-80.287231, 22.968052],
                [-80.313614, 22.971664],
                [-80.319733, 22.971943],
                [-80.330292, 22.968052],
                [-80.343063, 22.966942],
                [-80.349457, 22.981667],
                [-80.347778, 22.983055],
                [-80.335007, 22.990276],
                [-80.278336, 23.006386],
                [-80.249451, 23.011108],
                [-80.243057, 23.010277],
                [-80.235001, 22.999722],
                [-80.232788, 22.994999]
              ]
            ],
            [
              [
                [-80.049454, 23.026108],
                [-80.053619, 23.023331],
                [-80.077225, 23.041943],
                [-80.079727, 23.046944],
                [-80.083618, 23.064163],
                [-80.076675, 23.074444],
                [-80.070847, 23.074165],
                [-80.065567, 23.071941],
                [-80.058334, 23.064999],
                [-80.050293, 23.03833],
                [-80.049179, 23.032776],
                [-80.049454, 23.026108]
              ]
            ],
            [
              [
                [-80.146393, 23.070274],
                [-80.168625, 23.056389],
                [-80.181946, 23.06361],
                [-80.223892, 23.087498],
                [-80.224167, 23.099442],
                [-80.204178, 23.119442],
                [-80.199448, 23.121944],
                [-80.150284, 23.085552],
                [-80.146393, 23.070274]
              ]
            ],
            [
              [
                [-80.927505, 23.125553],
                [-80.970001, 23.10611],
                [-80.973618, 23.107498],
                [-80.969727, 23.110832],
                [-80.909454, 23.149998],
                [-80.847229, 23.179443],
                [-80.801392, 23.15361],
                [-80.797226, 23.150555],
                [-80.797501, 23.143887],
                [-80.800568, 23.139721],
                [-80.835281, 23.130833],
                [-80.841675, 23.130554],
                [-80.927505, 23.125553]
              ]
            ],
            [
              [
                [-82.003891, 23.186386],
                [-81.996674, 23.179443],
                [-81.983337, 23.17083],
                [-81.966949, 23.164997],
                [-81.960846, 23.163609],
                [-81.934174, 23.160275],
                [-81.848892, 23.153332],
                [-81.783615, 23.151386],
                [-81.771667, 23.153889],
                [-81.70723, 23.159443],
                [-81.699722, 23.159443],
                [-81.677475, 23.152004],
                [-81.647232, 23.156944],
                [-81.626953, 23.158607],
                [-81.590286, 23.157219],
                [-81.580566, 23.155552],
                [-81.575562, 23.153332],
                [-81.571671, 23.151386],
                [-81.553345, 23.140553],
                [-81.536392, 23.123055],
                [-81.528625, 23.108887],
                [-81.527237, 23.103611],
                [-81.527237, 23.096664],
                [-81.528336, 23.090275],
                [-81.531403, 23.079441],
                [-81.500839, 23.055553],
                [-81.386948, 23.112221],
                [-81.300003, 23.141388],
                [-81.226959, 23.161663],
                [-81.255005, 23.146385],
                [-81.277786, 23.133331],
                [-81.281113, 23.129166],
                [-81.285568, 23.11972],
                [-81.176392, 23.032497],
                [-81.171402, 23.030277],
                [-81.134735, 23.023052],
                [-81.128067, 23.023609],
                [-81.122787, 23.025555],
                [-81.118896, 23.028889],
                [-81.103348, 23.049164],
                [-80.982788, 23.076664],
                [-80.744171, 23.094997],
                [-80.633057, 23.098331],
                [-80.621399, 23.091663],
                [-80.596054, 23.067219],
                [-80.590286, 23.051666],
                [-80.588348, 23.046665],
                [-80.585556, 23.0425],
                [-80.544449, 22.991108],
                [-80.539459, 22.988888],
                [-80.438614, 22.951664],
                [-80.411392, 22.941944],
                [-80.400284, 22.938332],
                [-80.278625, 22.905277],
                [-80.249451, 22.90361],
                [-80.2314, 22.907219],
                [-80.170013, 22.930275],
                [-80.041946, 22.951385],
                [-80.03334, 22.951111],
                [-80.015564, 22.938889],
                [-80.008347, 22.931664],
                [-79.998901, 22.908054],
                [-79.989182, 22.882774],
                [-79.981949, 22.875832],
                [-79.973068, 22.869999],
                [-79.854446, 22.803608],
                [-79.724731, 22.769444],
                [-79.676682, 22.758888],
                [-79.633896, 22.690552],
                [-79.602509, 22.644722],
                [-79.548065, 22.571941],
                [-79.538895, 22.559998],
                [-79.524445, 22.545555],
                [-79.509171, 22.531944],
                [-79.504181, 22.52972],
                [-79.491394, 22.52861],
                [-79.421112, 22.476109],
                [-79.390717, 22.451706],
                [-79.353622, 22.417221],
                [-79.349167, 22.414444],
                [-79.335846, 22.406944],
                [-79.261948, 22.373333],
                [-79.256119, 22.371944],
                [-79.162781, 22.371944],
                [-79.156403, 22.372498],
                [-79.085556, 22.380276],
                [-79.06723, 22.383053],
                [-79.055557, 22.386108],
                [-79.036957, 22.393055],
                [-79.016403, 22.401108],
                [-79.003342, 22.402775],
                [-78.976959, 22.401943],
                [-78.932495, 22.3918],
                [-78.925568, 22.395275],
                [-78.888626, 22.404163],
                [-78.790558, 22.394997],
                [-78.573334, 22.321941],
                [-78.410004, 22.24361],
                [-78.352509, 22.198608],
                [-78.346954, 22.190277],
                [-78.343613, 22.186665],
                [-78.339172, 22.183609],
                [-78.312225, 22.166664],
                [-78.307236, 22.164444],
                [-78.121674, 22.092499],
                [-78.110565, 22.088608],
                [-78.084015, 22.083099],
                [-78.066391, 22.082775],
                [-78.055557, 22.078888],
                [-78.015564, 22.027496],
                [-77.952515, 21.948887],
                [-77.900284, 21.888885],
                [-77.864182, 21.898888],
                [-77.756119, 21.804443],
                [-77.746948, 21.799442],
                [-77.667511, 21.780552],
                [-77.660843, 21.77972],
                [-77.655014, 21.779442],
                [-77.577515, 21.779999],
                [-77.498009, 21.778711],
                [-77.457504, 21.777222],
                [-77.453064, 21.774441],
                [-77.429459, 21.748608],
                [-77.423889, 21.739998],
                [-77.425568, 21.734444],
                [-77.430847, 21.734165],
                [-77.435013, 21.736942],
                [-77.444168, 21.75],
                [-77.447784, 21.753609],
                [-77.452225, 21.750832],
                [-77.455292, 21.746944],
                [-77.45723, 21.741386],
                [-77.45723, 21.728333],
                [-77.455002, 21.710278],
                [-77.439453, 21.671665],
                [-77.432785, 21.657497],
                [-77.428345, 21.65472],
                [-77.405014, 21.648052],
                [-77.360291, 21.635555],
                [-77.34639, 21.634163],
                [-77.341125, 21.636108],
                [-77.337234, 21.639442],
                [-77.334457, 21.649719],
                [-77.335007, 21.65583],
                [-77.336945, 21.660831],
                [-77.342224, 21.669167],
                [-77.409729, 21.757774],
                [-77.444458, 21.798885],
                [-77.448059, 21.802498],
                [-77.458893, 21.806389],
                [-77.497826, 21.816021],
                [-77.499428, 21.815121],
                [-77.512726, 21.816887],
                [-77.529564, 21.826389],
                [-77.568069, 21.847221],
                [-77.610001, 21.883053],
                [-77.611389, 21.888611],
                [-77.600845, 21.906666],
                [-77.597778, 21.910553],
                [-77.574722, 21.923332],
                [-77.570007, 21.925831],
                [-77.563904, 21.926941],
                [-77.55806, 21.925278],
                [-77.54306, 21.91861],
                [-77.529175, 21.910275],
                [-77.521393, 21.90361],
                [-77.515839, 21.882221],
                [-77.511948, 21.872219],
                [-77.507233, 21.863052],
                [-77.498901, 21.850277],
                [-77.491119, 21.843609],
                [-77.465286, 21.826111],
                [-77.229172, 21.686665],
                [-77.171677, 21.656387],
                [-77.160004, 21.653053],
                [-77.1539, 21.654163],
                [-77.140015, 21.652775],
                [-77.137558, 21.61261],
                [-77.136726, 21.609776],
                [-77.136726, 21.605608],
                [-77.137398, 21.601942],
                [-77.140396, 21.596275],
                [-77.15657, 21.577944],
                [-77.159393, 21.576443],
                [-77.164902, 21.579609],
                [-77.168724, 21.584442],
                [-77.181946, 21.576942],
                [-77.189728, 21.583611],
                [-77.215836, 21.594444],
                [-77.26445, 21.612499],
                [-77.277237, 21.613888],
                [-77.34639, 21.621109],
                [-77.360001, 21.61972],
                [-77.364456, 21.617222],
                [-77.366959, 21.612499],
                [-77.365845, 21.606941],
                [-77.265839, 21.480831],
                [-77.258896, 21.47361],
                [-77.253616, 21.471386],
                [-77.216125, 21.45583],
                [-77.210281, 21.454166],
                [-77.204727, 21.45583],
                [-77.156403, 21.479721],
                [-77.153625, 21.484444],
                [-77.151947, 21.489998],
                [-77.13195, 21.519999],
                [-77.124947, 21.543333],
                [-77.123283, 21.546164],
                [-77.106781, 21.572664],
                [-77.10495, 21.574999],
                [-77.101624, 21.576166],
                [-77.094452, 21.577497],
                [-77.091614, 21.575998],
                [-77.088951, 21.574165],
                [-77.061951, 21.584999],
                [-77.044449, 21.566944],
                [-76.988617, 21.508888],
                [-76.969727, 21.485275],
                [-76.946075, 21.452213],
                [-76.883896, 21.41972],
                [-76.882233, 21.35611],
                [-76.886948, 21.353611],
                [-76.902237, 21.340553],
                [-76.901672, 21.314999],
                [-76.900558, 21.309444],
                [-76.896118, 21.306389],
                [-76.878891, 21.301388],
                [-76.872787, 21.302498],
                [-76.83567, 21.336887],
                [-76.803894, 21.381107],
                [-76.800842, 21.384998],
                [-76.795288, 21.384163],
                [-76.664169, 21.354721],
                [-76.65834, 21.353054],
                [-76.57579, 21.286665],
                [-76.574615, 21.283667],
                [-76.573959, 21.280331],
                [-76.57412, 21.268332],
                [-76.575447, 21.260998],
                [-76.576622, 21.257666],
                [-76.579956, 21.252499],
                [-76.582779, 21.250998],
                [-76.590286, 21.250164],
                [-76.593445, 21.250666],
                [-76.584732, 21.228611],
                [-76.5914, 21.229721],
                [-76.598068, 21.229443],
                [-76.610001, 21.227219],
                [-76.613342, 21.223331],
                [-76.605286, 21.207497],
                [-76.600845, 21.20472],
                [-76.541397, 21.183052],
                [-76.535568, 21.182499],
                [-76.489456, 21.201664],
                [-76.458344, 21.21722],
                [-76.45639, 21.222775],
                [-76.467224, 21.226665],
                [-76.486954, 21.229721],
                [-76.49968, 21.242222],
                [-76.510674, 21.244888],
                [-76.515182, 21.248888],
                [-76.516342, 21.251886],
                [-76.515511, 21.259722],
                [-76.514343, 21.262886],
                [-76.507843, 21.26922],
                [-76.505508, 21.271221],
                [-76.502182, 21.272221],
                [-76.49057, 21.283054],
                [-76.364456, 21.274441],
                [-76.314316, 21.248463],
                [-76.307785, 21.243053],
                [-76.265839, 21.217499],
                [-76.260834, 21.214996],
                [-76.242508, 21.210552],
                [-76.235001, 21.210278],
                [-76.215286, 21.207222],
                [-76.170013, 21.186108],
                [-76.156403, 21.177776],
                [-76.131958, 21.159164],
                [-76.091675, 21.111385],
                [-76.021667, 21.084164],
                [-75.968338, 21.090832],
                [-75.886673, 21.105],
                [-75.816956, 21.132774],
                [-75.811111, 21.1325],
                [-75.725571, 21.126389],
                [-75.70723, 21.121944],
                [-75.618896, 21.071941],
                [-75.614731, 21.068886],
                [-75.605835, 21.056664],
                [-75.584167, 21.016109],
                [-75.579727, 21.006943],
                [-75.633896, 20.845833],
                [-75.738068, 20.831108],
                [-75.767227, 20.826385],
                [-75.778061, 20.773331],
                [-75.780289, 20.749165],
                [-75.780014, 20.743053],
                [-75.767502, 20.716942],
                [-75.736679, 20.696941],
                [-75.72084, 20.690552],
                [-75.714172, 20.68972],
                [-75.551392, 20.683609],
                [-75.449448, 20.688889],
                [-75.446671, 20.693607],
                [-75.445007, 20.698887],
                [-75.443619, 20.709164],
                [-75.447784, 20.712219],
                [-75.458618, 20.716389],
                [-75.463898, 20.714443],
                [-75.470566, 20.714165],
                [-75.473068, 20.718609],
                [-75.473618, 20.72472],
                [-75.47084, 20.729443],
                [-75.4664, 20.731941],
                [-75.461121, 20.733608],
                [-75.435287, 20.734165],
                [-75.395569, 20.73333],
                [-75.236115, 20.723053],
                [-74.985001, 20.693333],
                [-74.955566, 20.685276],
                [-74.746674, 20.593052],
                [-74.720184, 20.553848],
                [-74.675568, 20.53611],
                [-74.638062, 20.512218],
                [-74.601959, 20.483608],
                [-74.584167, 20.46611],
                [-74.580841, 20.462219],
                [-74.579727, 20.456665],
                [-74.58168, 20.451385],
                [-74.582779, 20.445274],
                [-74.569458, 20.423611],
                [-74.543625, 20.394444],
                [-74.501114, 20.351665],
                [-74.496399, 20.349163],
                [-74.384171, 20.290276],
                [-74.303345, 20.293888],
                [-74.297226, 20.294998],
                [-74.292511, 20.297222],
                [-74.289459, 20.301388],
                [-74.288345, 20.30611],
                [-74.285004, 20.309998],
                [-74.267227, 20.312775],
                [-74.235291, 20.315552],
                [-74.228622, 20.31583],
                [-74.222778, 20.314163],
                [-74.148056, 20.259441],
                [-74.141403, 20.25222],
                [-74.139725, 20.247219],
                [-74.131958, 20.221107],
                [-74.130844, 20.209164],
                [-74.131668, 20.199718],
                [-74.132507, 20.193607],
                [-74.133308, 20.191307],
                [-74.147507, 20.172497],
                [-74.241119, 20.080555],
                [-74.253616, 20.071941],
                [-74.266678, 20.06361],
                [-74.281403, 20.056942],
                [-74.287231, 20.05611],
                [-74.293625, 20.05722],
                [-74.298615, 20.059719],
                [-74.30278, 20.062496],
                [-74.309448, 20.07],
                [-74.312225, 20.074444],
                [-74.316391, 20.077221],
                [-74.321945, 20.079166],
                [-74.36557, 20.078609],
                [-74.378616, 20.078053],
                [-74.486954, 20.064999],
                [-74.603348, 20.052498],
                [-74.679733, 20.049721],
                [-74.684723, 20.050831],
                [-74.703903, 20.051941],
                [-74.717789, 20.052219],
                [-74.762222, 20.048054],
                [-74.800293, 20.038887],
                [-74.816956, 20.034443],
                [-74.83139, 20.026943],
                [-74.960846, 19.956387],
                [-74.964737, 19.945553],
                [-74.972778, 19.93222],
                [-74.979446, 19.924442],
                [-74.983337, 19.921108],
                [-74.987793, 19.91861],
                [-75.015289, 19.903889],
                [-75.03334, 19.900833],
                [-75.085281, 19.89304],
                [-75.085556, 19.917221],
                [-75.087234, 19.965553],
                [-75.13974, 19.962872],
                [-75.101395, 19.985832],
                [-75.094177, 19.992775],
                [-75.077515, 20.010555],
                [-75.078903, 20.016109],
                [-75.092789, 20.056389],
                [-75.164169, 20.014442],
                [-75.172501, 20.00861],
                [-75.175842, 20.004719],
                [-75.178345, 20],
                [-75.178619, 19.993053],
                [-75.157272, 19.964163],
                [-75.15918, 19.960693],
                [-75.15918, 19.963055],
                [-75.190567, 19.963333],
                [-75.195847, 19.958611],
                [-75.226959, 19.924442],
                [-75.223724, 19.901554],
                [-75.305283, 19.880833],
                [-75.35556, 19.875553],
                [-75.369446, 19.875553],
                [-75.380188, 19.876717],
                [-75.51889, 19.876942],
                [-75.526123, 19.87722],
                [-75.540009, 19.878887],
                [-75.592789, 19.889442],
                [-75.609451, 19.895275],
                [-75.660004, 19.918053],
                [-75.67334, 19.926388],
                [-75.686111, 19.935555],
                [-75.699173, 19.943886],
                [-75.713348, 19.951664],
                [-75.718613, 19.953888],
                [-75.725006, 19.954998],
                [-75.905289, 19.968052],
                [-75.911392, 19.966942],
                [-75.944733, 19.958332],
                [-76.11084, 19.978611],
                [-76.134735, 19.984722],
                [-76.215012, 19.990555],
                [-76.248901, 19.990833],
                [-76.421951, 19.96722],
                [-76.455292, 19.959999],
                [-76.468903, 19.952499],
                [-76.484726, 19.946941],
                [-76.496674, 19.944721],
                [-76.566681, 19.938053],
                [-76.573898, 19.93861],
                [-76.625839, 19.944721],
                [-76.796677, 19.929165],
                [-76.906403, 19.904442],
                [-76.930557, 19.89333],
                [-76.987793, 19.880276],
                [-77.005005, 19.876389],
                [-77.011398, 19.87722],
                [-77.033859, 19.890774],
                [-77.075012, 19.881107],
                [-77.126678, 19.886944],
                [-77.198624, 19.908333],
                [-77.293335, 19.905277],
                [-77.305557, 19.903332],
                [-77.310562, 19.901386],
                [-77.333344, 19.888611],
                [-77.340286, 19.881386],
                [-77.337509, 19.879166],
                [-77.316956, 19.879719],
                [-77.311111, 19.878052],
                [-77.310562, 19.874443],
                [-77.315292, 19.871944],
                [-77.350281, 19.857777],
                [-77.35556, 19.855831],
                [-77.648895, 19.82333],
                [-77.681122, 19.821941],
                [-77.722504, 19.832775],
                [-77.729172, 19.839996],
                [-77.738342, 19.852219],
                [-77.742508, 19.867496],
                [-77.742233, 19.874443],
                [-77.710007, 19.915554],
                [-77.598618, 20.044998],
                [-77.594727, 20.048332],
                [-77.396667, 20.200275],
                [-77.379181, 20.211388],
                [-77.369446, 20.215832],
                [-77.363892, 20.217499],
                [-77.340286, 20.221943],
                [-77.329727, 20.225555],
                [-77.304459, 20.235275],
                [-77.2789, 20.245277],
                [-77.193619, 20.285831],
                [-77.175568, 20.296108],
                [-77.171677, 20.299442],
                [-77.168335, 20.303333],
                [-77.115845, 20.364998],
                [-77.113342, 20.36972],
                [-77.083893, 20.448055],
                [-77.078613, 20.464443],
                [-77.078339, 20.469997],
                [-77.195282, 20.632221],
                [-77.198624, 20.63583],
                [-77.236389, 20.663055],
                [-77.314178, 20.708885],
                [-77.323624, 20.713886],
                [-77.336639, 20.71669],
                [-77.357224, 20.71833],
                [-77.37001, 20.716942],
                [-77.461395, 20.685276],
                [-77.530014, 20.68222],
                [-77.68251, 20.68972],
                [-77.742508, 20.693886],
                [-77.76889, 20.696388],
                [-77.775284, 20.697498],
                [-77.786392, 20.701385],
                [-77.83226, 20.719009],
                [-77.855011, 20.728611],
                [-77.86084, 20.730274],
                [-77.874725, 20.73],
                [-77.900558, 20.727776],
                [-77.907791, 20.720554],
                [-77.910843, 20.716389],
                [-77.918335, 20.702221],
                [-77.925293, 20.695],
                [-77.929733, 20.692497],
                [-77.935837, 20.691387],
                [-78.045837, 20.698055],
                [-78.050293, 20.699718],
                [-78.072235, 20.713886],
                [-78.16362, 20.792774],
                [-78.212234, 20.840832],
                [-78.273346, 20.90361],
                [-78.336395, 20.948887],
                [-78.490005, 21.028053],
                [-78.49501, 21.030277],
                [-78.497787, 21.034443],
                [-78.508621, 21.167221],
                [-78.537231, 21.28833],
                [-78.565842, 21.390274],
                [-78.595001, 21.464443],
                [-78.603058, 21.484165],
                [-78.622513, 21.513611],
                [-78.633057, 21.524441],
                [-78.647247, 21.534451],
                [-78.697784, 21.604721],
                [-78.701126, 21.60833],
                [-78.74028, 21.63472],
                [-78.75029, 21.639164],
                [-78.756119, 21.640831],
                [-78.763062, 21.640553],
                [-78.848892, 21.622219],
                [-78.853622, 21.61972],
                [-78.866394, 21.611111],
                [-78.897781, 21.592499],
                [-78.911957, 21.59222],
                [-78.966019, 21.596371],
                [-79.02417, 21.583332],
                [-79.127502, 21.558052],
                [-79.132782, 21.55611],
                [-79.14418, 21.553055],
                [-79.173065, 21.546665],
                [-79.196945, 21.541943],
                [-79.209732, 21.540276],
                [-79.231949, 21.541111],
                [-79.245834, 21.542221],
                [-79.265015, 21.54583],
                [-79.462509, 21.594444],
                [-79.478622, 21.601387],
                [-79.482788, 21.604164],
                [-79.646393, 21.686108],
                [-79.668335, 21.693607],
                [-79.777786, 21.705555],
                [-79.890015, 21.746944],
                [-79.987793, 21.72361],
                [-80.047226, 21.78833],
                [-80.101089, 21.822681],
                [-80.171677, 21.839443],
                [-80.189178, 21.844166],
                [-80.199448, 21.84861],
                [-80.245285, 21.875553],
                [-80.281113, 21.897778],
                [-80.294174, 21.906387],
                [-80.366959, 21.970554],
                [-80.381393, 21.985554],
                [-80.402237, 22.0075],
                [-80.427231, 22.039165],
                [-80.43306, 22.061943],
                [-80.413895, 22.064163],
                [-80.407791, 22.065277],
                [-80.397232, 22.069164],
                [-80.392502, 22.071941],
                [-80.391113, 22.077221],
                [-80.396667, 22.104443],
                [-80.450562, 22.162498],
                [-80.455841, 22.164719],
                [-80.491959, 22.177219],
                [-80.526123, 22.179722],
                [-80.531952, 22.179722],
                [-80.535278, 22.175831],
                [-80.537506, 22.171108],
                [-80.540009, 22.160831],
                [-80.555939, 22.062828],
                [-80.591125, 22.050552],
                [-80.628067, 22.054165],
                [-80.75, 22.059998],
                [-80.757233, 22.060276],
                [-80.808899, 22.058331],
                [-80.814728, 22.056942],
                [-80.823624, 22.051666],
                [-80.839737, 22.04583],
                [-80.876953, 22.039997],
                [-80.891113, 22.039719],
                [-80.905289, 22.040554],
                [-81.009445, 22.056389],
                [-81.021118, 22.059444],
                [-81.086121, 22.083054],
                [-81.0914, 22.085278],
                [-81.104446, 22.093609],
                [-81.111679, 22.10083],
                [-81.117508, 22.109165],
                [-81.125839, 22.128609],
                [-81.134171, 22.148052],
                [-81.196396, 22.109722],
                [-81.205566, 22.094719],
                [-81.323898, 22.084164],
                [-81.345566, 22.084164],
                [-81.351669, 22.085552],
                [-81.386398, 22.115276],
                [-81.388336, 22.120277],
                [-81.388062, 22.131107],
                [-81.38501, 22.14222],
                [-81.382507, 22.146942],
                [-81.381958, 22.153053],
                [-81.390289, 22.165554],
                [-81.396667, 22.173332],
                [-81.406403, 22.178333],
                [-81.622223, 22.207222],
                [-81.647507, 22.210278],
                [-81.651123, 22.206944],
                [-81.660278, 22.194721],
                [-81.758621, 22.171944],
                [-81.765015, 22.171387],
                [-81.823059, 22.183609],
                [-81.840561, 22.195],
                [-82.006958, 22.303333],
                [-82.099457, 22.343052],
                [-82.137787, 22.366943],
                [-82.150284, 22.37611],
                [-82.156677, 22.383888],
                [-82.162231, 22.39222],
                [-82.163071, 22.398331],
                [-82.15918, 22.408607],
                [-82.14473, 22.423054],
                [-82.123611, 22.431664],
                [-82.119171, 22.432777],
                [-82.099167, 22.430553],
                [-82.05806, 22.427776],
                [-81.982788, 22.426941],
                [-81.976959, 22.426941],
                [-81.841125, 22.43111],
                [-81.795013, 22.436386],
                [-81.703613, 22.453888],
                [-81.698334, 22.45583],
                [-81.648895, 22.491386],
                [-81.643341, 22.570553],
                [-81.64473, 22.576111],
                [-81.650131, 22.577396],
                [-81.741119, 22.6325],
                [-81.755005, 22.640274],
                [-81.784729, 22.65472],
                [-81.789734, 22.656944],
                [-81.81279, 22.663609],
                [-81.83667, 22.669441],
                [-81.88501, 22.680832],
                [-81.961121, 22.675831],
                [-82.176682, 22.677776],
                [-82.400558, 22.686108],
                [-82.624725, 22.682777],
                [-82.673065, 22.688053],
                [-82.702789, 22.695553],
                [-82.714737, 22.69833],
                [-82.72139, 22.699165],
                [-82.757233, 22.701111],
                [-82.763901, 22.700554],
                [-82.775284, 22.69722],
                [-82.779724, 22.694443],
                [-82.787231, 22.687775],
                [-82.789734, 22.682777],
                [-82.791397, 22.677498],
                [-82.791397, 22.670555],
                [-82.790009, 22.664997],
                [-82.785568, 22.656666],
                [-82.794449, 22.624443],
                [-82.797501, 22.620277],
                [-82.801392, 22.616943],
                [-82.823334, 22.607384],
                [-82.849167, 22.59972],
                [-82.859726, 22.595554],
                [-83.026672, 22.512218],
                [-83.035568, 22.506943],
                [-83.073334, 22.479721],
                [-83.07695, 22.476387],
                [-83.103622, 22.438889],
                [-83.1064, 22.434719],
                [-83.108337, 22.416943],
                [-83.13501, 22.365555],
                [-83.137512, 22.360832],
                [-83.140289, 22.356667],
                [-83.14418, 22.353054],
                [-83.166672, 22.332775],
                [-83.171112, 22.329998],
                [-83.18251, 22.326664],
                [-83.189178, 22.326111],
                [-83.194458, 22.328331],
                [-83.204178, 22.333054],
                [-83.246948, 22.348888],
                [-83.253616, 22.349442],
                [-83.258347, 22.346943],
                [-83.342789, 22.235554],
                [-83.344452, 22.23],
                [-83.366119, 22.203331],
                [-83.37001, 22.199997],
                [-83.411957, 22.189163],
                [-83.476959, 22.174164],
                [-83.481949, 22.174721],
                [-83.493896, 22.177776],
                [-83.543335, 22.194721],
                [-83.589447, 22.213055],
                [-83.667236, 22.174999],
                [-83.748337, 22.17083],
                [-83.813614, 22.16861],
                [-83.847778, 22.170277],
                [-83.875565, 22.171108],
                [-83.905289, 22.171387],
                [-83.911118, 22.169998],
                [-83.926682, 22.163887],
                [-83.931396, 22.16111],
                [-83.943344, 22.150833],
                [-83.954727, 22.140553],
                [-83.965836, 22.130276],
                [-83.968903, 22.12611],
                [-84.005005, 22.060276],
                [-84.007233, 22.055553],
                [-84.007233, 22.049999],
                [-83.994171, 22.027775],
                [-83.990845, 21.949997],
                [-83.990845, 21.944443],
                [-84.002228, 21.934166],
                [-84.026123, 21.914997],
                [-84.031403, 21.913055],
                [-84.03862, 21.913055],
                [-84.0439, 21.915276],
                [-84.065842, 21.929165],
                [-84.08168, 21.935276],
                [-84.107513, 21.93972],
                [-84.122223, 21.93972],
                [-84.128342, 21.938332],
                [-84.229446, 21.909164],
                [-84.240005, 21.905277],
                [-84.271667, 21.893608],
                [-84.281403, 21.888885],
                [-84.306122, 21.876663],
                [-84.328339, 21.862778],
                [-84.382782, 21.823055],
                [-84.402237, 21.806664],
                [-84.428345, 21.786663],
                [-84.436401, 21.781387],
                [-84.45195, 21.775276],
                [-84.487503, 21.766941],
                [-84.501114, 21.765553],
                [-84.515839, 21.765831],
                [-84.526123, 21.769997],
                [-84.529724, 21.773331],
                [-84.528336, 21.778889],
                [-84.494171, 21.84333],
                [-84.475281, 21.860554],
                [-84.47084, 21.870277],
                [-84.469177, 21.889721],
                [-84.470001, 21.895832],
                [-84.474457, 21.91222],
                [-84.476669, 21.917221],
                [-84.482788, 21.925552],
                [-84.490005, 21.93222],
                [-84.49501, 21.934444],
                [-84.501953, 21.934998],
                [-84.564178, 21.933887],
                [-84.601959, 21.927776],
                [-84.613892, 21.924999],
                [-84.648895, 21.916111],
                [-84.700287, 21.894997],
                [-84.736679, 21.873608],
                [-84.74556, 21.868053],
                [-84.749176, 21.864719],
                [-84.755005, 21.856388],
                [-84.757233, 21.851665],
                [-84.765015, 21.844719],
                [-84.805008, 21.82],
                [-84.815567, 21.81583],
                [-84.861389, 21.821388],
                [-84.868057, 21.82222],
                [-84.911118, 21.830555],
                [-84.927505, 21.836109],
                [-84.936951, 21.840832],
                [-84.945282, 21.846943],
                [-84.951126, 21.855274],
                [-84.953339, 21.859997],
                [-84.952515, 21.866386],
                [-84.939178, 21.902222],
                [-84.936951, 21.906944],
                [-84.931122, 21.913887],
                [-84.925003, 21.917221],
                [-84.921402, 21.915833],
                [-84.920013, 21.911663],
                [-84.905838, 21.890274],
                [-84.897781, 21.884163],
                [-84.890839, 21.88361],
                [-84.818069, 21.907497],
                [-84.546951, 22.027496],
                [-84.53334, 22.035831],
                [-84.514175, 22.045277],
                [-84.496948, 22.046108],
                [-84.490845, 22.046108],
                [-84.378891, 22.04361],
                [-84.373062, 22.042221],
                [-84.367783, 22.039997],
                [-84.358902, 22.034443],
                [-84.348343, 22.023331],
                [-84.338058, 22.012218],
                [-84.320847, 22.079166],
                [-84.411118, 22.155277],
                [-84.440002, 22.203609],
                [-84.400558, 22.332775],
                [-84.390839, 22.351387],
                [-84.299454, 22.459164],
                [-84.200562, 22.553055],
                [-84.07695, 22.660553],
                [-84.071671, 22.662777],
                [-84.065567, 22.661388],
                [-84.023056, 22.677498],
                [-83.843338, 22.75222],
                [-83.692505, 22.801941],
                [-83.442505, 22.900276],
                [-83.229172, 22.998886],
                [-83.216949, 23.001389],
                [-83.053894, 23.01833],
                [-83, 23.015232],
                [-83.008057, 22.98333],
                [-83.008057, 22.976387],
                [-82.979736, 22.966389],
                [-82.973618, 22.966389],
                [-82.968613, 22.96833],
                [-82.944763, 22.978725],
                [-82.928894, 23.014721],
                [-82.926392, 23.019444],
                [-82.917511, 23.024719],
                [-82.900284, 23.029442],
                [-82.858337, 23.030277],
                [-82.80751, 23.031109],
                [-82.768616, 23.030552],
                [-82.748337, 23.028053],
                [-82.726669, 23.02861],
                [-82.695007, 23.032219],
                [-82.590012, 23.048611],
                [-82.556122, 23.058331],
                [-82.545563, 23.062496],
                [-82.535339, 23.07024],
                [-82.53334, 23.071941],
                [-82.496674, 23.092777],
                [-82.413345, 23.139721],
                [-82.408615, 23.14222],
                [-82.403336, 23.144444],
                [-82.331116, 23.166664],
                [-82.265564, 23.181389],
                [-82.234177, 23.186386],
                [-82.220566, 23.187496],
                [-82.20639, 23.186665],
                [-82.172501, 23.181942],
                [-82.095856, 23.184464],
                [-82.082779, 23.187222],
                [-82.045288, 23.193333],
                [-82.031403, 23.194443],
                [-82.025009, 23.193607],
                [-82.006119, 23.189999],
                [-82.003891, 23.186386]
              ]
            ],
            [
              [
                [-80.493057, 23.188053],
                [-80.487793, 23.169998],
                [-80.496948, 23.164719],
                [-80.513062, 23.176388],
                [-80.536118, 23.182499],
                [-80.541946, 23.183887],
                [-80.5625, 23.18111],
                [-80.579727, 23.176109],
                [-80.579727, 23.181664],
                [-80.577789, 23.186943],
                [-80.571671, 23.195],
                [-80.563904, 23.201664],
                [-80.558624, 23.203609],
                [-80.551956, 23.204166],
                [-80.545837, 23.202774],
                [-80.493057, 23.188053]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Cuba",
          "density": "105",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 41
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-62.171394, 16.671387],
                [-62.190002, 16.672497],
                [-62.208618, 16.683609],
                [-62.212502, 16.686943],
                [-62.229729, 16.70472],
                [-62.236671, 16.711941],
                [-62.237228, 16.717499],
                [-62.23584, 16.730553],
                [-62.20723, 16.811108],
                [-62.20195, 16.81361],
                [-62.176392, 16.808609],
                [-62.148056, 16.751663],
                [-62.146393, 16.746666],
                [-62.137505, 16.692776],
                [-62.140282, 16.688332],
                [-62.15139, 16.678055],
                [-62.155556, 16.675278],
                [-62.165558, 16.671665],
                [-62.171394, 16.671387]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Montserrat",
          "density": "43.86",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 111
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-60.954727, 13.709444],
                [-60.956116, 13.714998],
                [-60.968056, 13.728054],
                [-60.986389, 13.744444],
                [-60.995003, 13.749722],
                [-61.001396, 13.750832],
                [-61.01445, 13.75],
                [-61.026115, 13.752499],
                [-61.039169, 13.761389],
                [-61.05056, 13.770555],
                [-61.067505, 13.78861],
                [-61.072784, 13.796944],
                [-61.074448, 13.801943],
                [-61.075005, 13.808054],
                [-61.079727, 13.873333],
                [-61.079445, 13.879999],
                [-61.075005, 13.896944],
                [-61.035004, 13.969721],
                [-61.011673, 14.009722],
                [-60.945839, 14.104721],
                [-60.936394, 14.109165],
                [-60.930283, 14.109444],
                [-60.919724, 14.105555],
                [-60.91584, 14.102499],
                [-60.914452, 14.099722],
                [-60.888062, 14.013054],
                [-60.878616, 13.975832],
                [-60.878059, 13.956665],
                [-60.885284, 13.854166],
                [-60.886116, 13.848055],
                [-60.890007, 13.82361],
                [-60.900284, 13.778332],
                [-60.938339, 13.717777],
                [-60.951393, 13.709999],
                [-60.954727, 13.709444]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Saint Lucia",
          "density": "295",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 189
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-63.167778, 18.164444],
                [-63.158615, 18.175831],
                [-63.155281, 18.179443],
                [-63.141396, 18.194164],
                [-63.057503, 18.256386],
                [-63.053337, 18.259163],
                [-63.048615, 18.261387],
                [-63.022507, 18.268887],
                [-62.978889, 18.276665],
                [-62.972504, 18.276665],
                [-62.970001, 18.272221],
                [-62.969452, 18.266388],
                [-62.986671, 18.235554],
                [-62.993057, 18.227219],
                [-63.021118, 18.214443],
                [-63.030838, 18.210278],
                [-63.074173, 18.192776],
                [-63.140007, 18.168331],
                [-63.150841, 18.165554],
                [-63.15667, 18.164997],
                [-63.167778, 18.164444]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Anguilla",
          "density": "176.66",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 128
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-61.746948, 11.997499],
                [-61.752502, 11.996387],
                [-61.780151, 12],
                [-61.783615, 12.001389],
                [-61.787224, 12.004721],
                [-61.789726, 12.009165],
                [-61.724724, 12.172222],
                [-61.707504, 12.196943],
                [-61.686668, 12.218054],
                [-61.675835, 12.227777],
                [-61.658615, 12.236666],
                [-61.653061, 12.237778],
                [-61.647507, 12.237499],
                [-61.6175, 12.232777],
                [-61.611946, 12.23111],
                [-61.610001, 12.227777],
                [-61.606392, 12.224165],
                [-61.604729, 12.220554],
                [-61.599724, 12.186666],
                [-61.59639, 12.125555],
                [-61.611115, 12.079443],
                [-61.628616, 12.048054],
                [-61.631668, 12.044167],
                [-61.642502, 12.034166],
                [-61.65139, 12.029165],
                [-61.683334, 12.016109],
                [-61.717506, 12.003887],
                [-61.746948, 11.997499]
              ]
            ],
            [
              [
                [-61.580002, 12.295555],
                [-61.585556, 12.294443],
                [-61.589172, 12.298054],
                [-61.58889, 12.30361],
                [-61.585556, 12.314444],
                [-61.583336, 12.319166],
                [-61.578896, 12.323055],
                [-61.573891, 12.320833],
                [-61.572227, 12.315832],
                [-61.572502, 12.308887],
                [-61.574173, 12.30361],
                [-61.580002, 12.295555]
              ]
            ],
            [
              [
                [-61.428337, 12.453609],
                [-61.491669, 12.434999],
                [-61.49556, 12.438055],
                [-61.496948, 12.443609],
                [-61.49556, 12.469444],
                [-61.493896, 12.474998],
                [-61.490837, 12.478888],
                [-61.434448, 12.529165],
                [-61.427505, 12.528889],
                [-61.42556, 12.526943],
                [-61.420563, 12.512777],
                [-61.419449, 12.5075],
                [-61.418617, 12.494999],
                [-61.425285, 12.457499],
                [-61.428337, 12.453609]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Grenada",
          "density": "371",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 70
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-81.097229, 19.308887],
                [-81.10556, 19.302776],
                [-81.260284, 19.265274],
                [-81.266953, 19.264721],
                [-81.384171, 19.264721],
                [-81.390015, 19.264721],
                [-81.39418, 19.267498],
                [-81.397232, 19.271942],
                [-81.400558, 19.282219],
                [-81.401123, 19.28833],
                [-81.400558, 19.307777],
                [-81.39917, 19.313053],
                [-81.395004, 19.326111],
                [-81.371948, 19.309166],
                [-81.367233, 19.306942],
                [-81.35556, 19.303886],
                [-81.341675, 19.304165],
                [-81.316956, 19.306389],
                [-81.311111, 19.307777],
                [-81.301392, 19.312222],
                [-81.272507, 19.325554],
                [-81.269455, 19.329441],
                [-81.275284, 19.34333],
                [-81.254456, 19.353886],
                [-81.150009, 19.354164],
                [-81.115005, 19.353333],
                [-81.108337, 19.352497],
                [-81.099167, 19.3475],
                [-81.096115, 19.34333],
                [-81.093063, 19.332775],
                [-81.093338, 19.319164],
                [-81.095001, 19.31361],
                [-81.097229, 19.308887]
              ]
            ],
            [
              [
                [-80.097504, 19.654163],
                [-80.103897, 19.65361],
                [-80.109726, 19.655277],
                [-80.111679, 19.66222],
                [-80.110001, 19.6675],
                [-80.106949, 19.671665],
                [-80.080566, 19.695],
                [-80.075836, 19.697498],
                [-79.990845, 19.711388],
                [-79.976959, 19.711666],
                [-79.97084, 19.710278],
                [-79.966675, 19.707222],
                [-79.965286, 19.703053],
                [-79.969177, 19.699718],
                [-79.974457, 19.697777],
                [-80.097504, 19.654163]
              ]
            ],
            [
              [
                [-79.866394, 19.686943],
                [-79.878891, 19.685276],
                [-79.885559, 19.686108],
                [-79.890564, 19.689163],
                [-79.893341, 19.693333],
                [-79.889725, 19.696663],
                [-79.769455, 19.761665],
                [-79.763062, 19.762218],
                [-79.756958, 19.760555],
                [-79.741119, 19.754444],
                [-79.736115, 19.75222],
                [-79.732788, 19.748608],
                [-79.732788, 19.74472],
                [-79.736679, 19.741386],
                [-79.753067, 19.729443],
                [-79.771118, 19.718887],
                [-79.786667, 19.713333],
                [-79.866394, 19.686943]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Cayman Islands",
          "density": "287.5934",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 34
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-61.363617, 15.198055],
                [-61.37056, 15.199165],
                [-61.374168, 15.202499],
                [-61.375839, 15.207499],
                [-61.377502, 15.236387],
                [-61.377785, 15.248055],
                [-61.374451, 15.268332],
                [-61.374168, 15.274443],
                [-61.391396, 15.349722],
                [-61.392784, 15.354721],
                [-61.395004, 15.359444],
                [-61.422783, 15.4175],
                [-61.450562, 15.455555],
                [-61.462502, 15.471109],
                [-61.474724, 15.486387],
                [-61.476395, 15.491388],
                [-61.491394, 15.540554],
                [-61.482506, 15.587776],
                [-61.474724, 15.61861],
                [-61.468056, 15.628054],
                [-61.452225, 15.631943],
                [-61.433617, 15.631666],
                [-61.427223, 15.630278],
                [-61.31778, 15.581944],
                [-61.304169, 15.57361],
                [-61.267227, 15.516666],
                [-61.253334, 15.461388],
                [-61.250557, 15.329166],
                [-61.250839, 15.323055],
                [-61.254448, 15.291666],
                [-61.25528, 15.285],
                [-61.25695, 15.278889],
                [-61.265007, 15.258055],
                [-61.270004, 15.248333],
                [-61.280281, 15.237499],
                [-61.363617, 15.198055]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Dominica",
          "density": "97.39",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 47
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-63.028336, 18.015553],
                [-63.03334, 18.015553],
                [-63.072784, 18.024998],
                [-63.07917, 18.026665],
                [-63.116112, 18.042774],
                [-63.139839, 18.058601],
                [-63.109867, 18.066116],
                [-63.030273, 18.070366],
                [-63.011459, 18.067276],
                [-63.010284, 18.047775],
                [-63.012222, 18.035553],
                [-63.01667, 18.024998],
                [-63.019447, 18.020554],
                [-63.028336, 18.015553]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Saint Barthelemy",
          "density": "524",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 242
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-59.533058, 13.050554],
                [-59.543335, 13.067499],
                [-59.547501, 13.070555],
                [-59.571114, 13.075832],
                [-59.60334, 13.08],
                [-59.60778, 13.082499],
                [-59.611389, 13.085833],
                [-59.625557, 13.099722],
                [-59.628616, 13.103333],
                [-59.642227, 13.14361],
                [-59.643333, 13.149166],
                [-59.659447, 13.287777],
                [-59.65834, 13.299166],
                [-59.653061, 13.315554],
                [-59.650841, 13.320276],
                [-59.64473, 13.328054],
                [-59.641113, 13.331388],
                [-59.631111, 13.334999],
                [-59.619728, 13.337221],
                [-59.612503, 13.336943],
                [-59.59584, 13.331944],
                [-59.591118, 13.329721],
                [-59.573616, 13.299166],
                [-59.56945, 13.290277],
                [-59.566116, 13.279722],
                [-59.565285, 13.274166],
                [-59.559448, 13.259443],
                [-59.554451, 13.25111],
                [-59.551674, 13.246944],
                [-59.545006, 13.239721],
                [-59.521118, 13.214443],
                [-59.513062, 13.208611],
                [-59.479446, 13.185833],
                [-59.470001, 13.18111],
                [-59.463615, 13.180277],
                [-59.442223, 13.173054],
                [-59.4375, 13.170832],
                [-59.429169, 13.164999],
                [-59.426949, 13.153889],
                [-59.427223, 13.146944],
                [-59.430283, 13.135277],
                [-59.439445, 13.116665],
                [-59.444725, 13.108055],
                [-59.450279, 13.099443],
                [-59.456673, 13.092222],
                [-59.464447, 13.085554],
                [-59.5, 13.059444],
                [-59.510284, 13.054998],
                [-59.533058, 13.050554]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Barbados",
          "density": "656",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 11
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-69.882233, 12.41111],
                [-69.946945, 12.436666],
                [-70.056122, 12.534443],
                [-70.059448, 12.538055],
                [-70.060287, 12.544167],
                [-70.063339, 12.621666],
                [-70.063065, 12.628611],
                [-70.058899, 12.631109],
                [-70.053345, 12.629721],
                [-70.035278, 12.61972],
                [-70.031113, 12.616943],
                [-69.932236, 12.528055],
                [-69.896957, 12.480833],
                [-69.891403, 12.472221],
                [-69.885559, 12.457777],
                [-69.873901, 12.421944],
                [-69.873337, 12.415833],
                [-69.876114, 12.411665],
                [-69.882233, 12.41111]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Aruba",
          "density": "590",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 127
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-71.140289, 21.431942],
                [-71.145844, 21.430275],
                [-71.150009, 21.433331],
                [-71.150284, 21.445831],
                [-71.150558, 21.470833],
                [-71.140015, 21.509441],
                [-71.135559, 21.511108],
                [-71.131393, 21.50861],
                [-71.12973, 21.503609],
                [-71.127792, 21.448055],
                [-71.128067, 21.441387],
                [-71.131668, 21.437496],
                [-71.135834, 21.434444],
                [-71.140289, 21.431942]
              ]
            ],
            [
              [
                [-72.460556, 21.629719],
                [-72.465836, 21.628052],
                [-72.468063, 21.645275],
                [-72.45668, 21.699718],
                [-72.448624, 21.706108],
                [-72.433624, 21.713333],
                [-72.428619, 21.710831],
                [-72.427505, 21.698887],
                [-72.428619, 21.692776],
                [-72.444458, 21.649719],
                [-72.446671, 21.644444],
                [-72.45668, 21.632774],
                [-72.460556, 21.629719]
              ]
            ],
            [
              [
                [-71.468903, 21.654999],
                [-71.473618, 21.652775],
                [-71.476395, 21.66333],
                [-71.478058, 21.668331],
                [-71.480835, 21.672775],
                [-71.498337, 21.696941],
                [-71.505005, 21.704441],
                [-71.535004, 21.719444],
                [-71.53334, 21.734444],
                [-71.466675, 21.724163],
                [-71.462784, 21.720833],
                [-71.460846, 21.71611],
                [-71.45723, 21.699718],
                [-71.45639, 21.694164],
                [-71.456955, 21.687222],
                [-71.462234, 21.662777],
                [-71.464737, 21.658333],
                [-71.468903, 21.654999]
              ]
            ],
            [
              [
                [-71.657791, 21.82333],
                [-71.637787, 21.781666],
                [-71.633621, 21.772221],
                [-71.643616, 21.744164],
                [-71.653625, 21.739998],
                [-71.65889, 21.739719],
                [-71.674179, 21.75972],
                [-71.680847, 21.76722],
                [-71.713058, 21.787777],
                [-71.718903, 21.789719],
                [-71.75, 21.792221],
                [-71.757233, 21.792774],
                [-71.769455, 21.790833],
                [-71.796951, 21.783607],
                [-71.802231, 21.781944],
                [-71.811111, 21.776386],
                [-71.816391, 21.774719],
                [-71.823059, 21.774441],
                [-71.827515, 21.776386],
                [-71.841125, 21.797497],
                [-71.842789, 21.802498],
                [-71.850571, 21.839722],
                [-71.850571, 21.845833],
                [-71.847778, 21.854721],
                [-71.695007, 21.838886],
                [-71.681671, 21.836388],
                [-71.670288, 21.832775],
                [-71.665283, 21.830276],
                [-71.661118, 21.827221],
                [-71.657791, 21.82333]
              ]
            ],
            [
              [
                [-72.262222, 21.75111],
                [-72.27417, 21.741665],
                [-72.287231, 21.744164],
                [-72.300842, 21.758888],
                [-72.329727, 21.826942],
                [-72.327225, 21.855831],
                [-72.290558, 21.832222],
                [-72.247787, 21.795555],
                [-72.242233, 21.774441],
                [-72.252228, 21.762775],
                [-72.262222, 21.75111]
              ]
            ],
            [
              [
                [-71.884171, 21.847221],
                [-71.881958, 21.823608],
                [-71.889175, 21.824165],
                [-71.901672, 21.827221],
                [-72.029175, 21.904999],
                [-72.035843, 21.938053],
                [-72.03334, 21.942776],
                [-72.030014, 21.946663],
                [-72.026672, 21.949165],
                [-72.017227, 21.953888],
                [-72.005005, 21.957775],
                [-71.973068, 21.957222],
                [-71.947235, 21.953053],
                [-71.917511, 21.945831],
                [-71.912231, 21.943333],
                [-71.90889, 21.93972],
                [-71.906403, 21.935276],
                [-71.884171, 21.847221]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Turks and Caicos Islands",
          "density": "48.89",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 236
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-63.006668, 18.080555],
                [-63.011459, 18.067276],
                [-63.030273, 18.070366],
                [-63.109867, 18.066116],
                [-63.139839, 18.058601],
                [-63.14389, 18.061943],
                [-63.146118, 18.066387],
                [-63.146667, 18.071941],
                [-63.142227, 18.074165],
                [-63.045006, 18.121387],
                [-63.039169, 18.121944],
                [-63.019173, 18.117775],
                [-63.016113, 18.113888],
                [-63.006393, 18.086941],
                [-63.006668, 18.080555]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Saint Martin",
          "density": "605.23",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 241
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-61.686668, 17.024441],
                [-61.73806, 16.989719],
                [-61.82917, 16.996944],
                [-61.876114, 17.016941],
                [-61.880562, 17.019722],
                [-61.883614, 17.023609],
                [-61.885834, 17.028053],
                [-61.887222, 17.033054],
                [-61.891113, 17.094166],
                [-61.887222, 17.105274],
                [-61.884171, 17.109722],
                [-61.832779, 17.163887],
                [-61.826393, 17.167221],
                [-61.794449, 17.16333],
                [-61.784172, 17.158333],
                [-61.744171, 17.137218],
                [-61.674171, 17.093609],
                [-61.67028, 17.090275],
                [-61.668892, 17.084999],
                [-61.666389, 17.04583],
                [-61.667503, 17.040554],
                [-61.682503, 17.027496],
                [-61.686668, 17.024441]
              ]
            ],
            [
              [
                [-61.729172, 17.608608],
                [-61.731117, 17.547222],
                [-61.73278, 17.541111],
                [-61.738892, 17.540554],
                [-61.751945, 17.549442],
                [-61.815559, 17.583885],
                [-61.834724, 17.588608],
                [-61.839447, 17.586666],
                [-61.842781, 17.582775],
                [-61.847504, 17.58083],
                [-61.853058, 17.583054],
                [-61.856674, 17.592499],
                [-61.873894, 17.688889],
                [-61.875282, 17.698608],
                [-61.873062, 17.703888],
                [-61.850281, 17.722775],
                [-61.845558, 17.724998],
                [-61.839172, 17.72472],
                [-61.787224, 17.700554],
                [-61.783615, 17.69722],
                [-61.74334, 17.653053],
                [-61.74028, 17.649166],
                [-61.73806, 17.644722],
                [-61.731674, 17.624996],
                [-61.729172, 17.608608]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Antigua and Barbuda",
          "density": "214",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 1
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-61.416946, 12.590277],
                [-61.443893, 12.584444],
                [-61.448616, 12.586943],
                [-61.452225, 12.590277],
                [-61.45417, 12.593887],
                [-61.446396, 12.607222],
                [-61.443336, 12.611111],
                [-61.437782, 12.612499],
                [-61.425835, 12.612499],
                [-61.412506, 12.60861],
                [-61.406952, 12.606943],
                [-61.403061, 12.603888],
                [-61.405281, 12.599165],
                [-61.416946, 12.590277]
              ]
            ],
            [
              [
                [-61.328056, 12.687777],
                [-61.332504, 12.686666],
                [-61.336395, 12.689444],
                [-61.347778, 12.698889],
                [-61.347778, 12.701666],
                [-61.329727, 12.732498],
                [-61.326668, 12.736387],
                [-61.323616, 12.737499],
                [-61.310562, 12.734999],
                [-61.30584, 12.725832],
                [-61.305, 12.720276],
                [-61.30584, 12.714167],
                [-61.314728, 12.702221],
                [-61.320839, 12.694166],
                [-61.328056, 12.687777]
              ]
            ],
            [
              [
                [-61.216667, 12.998055],
                [-61.239449, 12.974998],
                [-61.247505, 12.980833],
                [-61.252228, 12.988609],
                [-61.228058, 13.023333],
                [-61.206116, 13.042776],
                [-61.199722, 13.044722],
                [-61.197227, 13.033609],
                [-61.213615, 13.002222],
                [-61.216667, 12.998055]
              ]
            ],
            [
              [
                [-61.173058, 13.1325],
                [-61.184448, 13.130278],
                [-61.243614, 13.156111],
                [-61.263062, 13.178333],
                [-61.278893, 13.203609],
                [-61.281395, 13.207777],
                [-61.278061, 13.250277],
                [-61.265556, 13.273054],
                [-61.208893, 13.367777],
                [-61.205284, 13.370832],
                [-61.182503, 13.382221],
                [-61.177223, 13.384165],
                [-61.14389, 13.378332],
                [-61.141113, 13.374166],
                [-61.123611, 13.33111],
                [-61.122223, 13.325554],
                [-61.120285, 13.307499],
                [-61.123337, 13.248888],
                [-61.124168, 13.242777],
                [-61.130005, 13.220276],
                [-61.148056, 13.167776],
                [-61.165001, 13.138332],
                [-61.16806, 13.134443],
                [-61.173058, 13.1325]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Saint Vincent and the Grenadines",
          "density": "266",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 213
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-64.761948, 17.774166],
                [-64.758621, 17.771664],
                [-64.753342, 17.773052],
                [-64.741394, 17.774166],
                [-64.73584, 17.772221],
                [-64.729736, 17.764442],
                [-64.721954, 17.758053],
                [-64.709167, 17.754997],
                [-64.696671, 17.745552],
                [-64.689728, 17.74472],
                [-64.687225, 17.75],
                [-64.683899, 17.753887],
                [-64.674454, 17.758053],
                [-64.663071, 17.767776],
                [-64.657791, 17.769165],
                [-64.638062, 17.765274],
                [-64.610001, 17.762218],
                [-64.590286, 17.761944],
                [-64.576675, 17.76083],
                [-64.570282, 17.759441],
                [-64.565002, 17.757221],
                [-64.560837, 17.753887],
                [-64.560287, 17.748333],
                [-64.564178, 17.745277],
                [-64.578064, 17.746387],
                [-64.590561, 17.749443],
                [-64.59584, 17.749443],
                [-64.600571, 17.747219],
                [-64.61084, 17.736111],
                [-64.618896, 17.730274],
                [-64.628067, 17.72583],
                [-64.64418, 17.721664],
                [-64.668335, 17.710831],
                [-64.691956, 17.708332],
                [-64.714172, 17.704166],
                [-64.726395, 17.705276],
                [-64.738892, 17.708054],
                [-64.744446, 17.706665],
                [-64.757782, 17.699444],
                [-64.763062, 17.698055],
                [-64.776947, 17.699165],
                [-64.78862, 17.697777],
                [-64.804459, 17.693607],
                [-64.815567, 17.691666],
                [-64.823059, 17.684719],
                [-64.828339, 17.683331],
                [-64.833618, 17.683331],
                [-64.843063, 17.688889],
                [-64.852234, 17.684444],
                [-64.858612, 17.685833],
                [-64.862503, 17.689163],
                [-64.868057, 17.68972],
                [-64.872223, 17.686943],
                [-64.877502, 17.685555],
                [-64.896118, 17.676666],
                [-64.895844, 17.679443],
                [-64.891953, 17.682499],
                [-64.888901, 17.686943],
                [-64.881958, 17.710278],
                [-64.880005, 17.723888],
                [-64.883057, 17.733887],
                [-64.890839, 17.746666],
                [-64.891678, 17.752499],
                [-64.890015, 17.758331],
                [-64.886398, 17.762218],
                [-64.882507, 17.764999],
                [-64.87973, 17.769722],
                [-64.870834, 17.774719],
                [-64.864456, 17.774441],
                [-64.861954, 17.770832],
                [-64.844452, 17.761665],
                [-64.838058, 17.760277],
                [-64.831955, 17.76083],
                [-64.82695, 17.762218],
                [-64.818619, 17.768055],
                [-64.815567, 17.771942],
                [-64.811401, 17.774719],
                [-64.796951, 17.788887],
                [-64.786957, 17.7925],
                [-64.773346, 17.789997],
                [-64.769455, 17.786942],
                [-64.766403, 17.783054],
                [-64.761948, 17.774166]
              ]
            ],
            [
              [
                [-64.665283, 18.332222],
                [-64.704453, 18.307499],
                [-64.76918, 18.31583],
                [-64.774734, 18.317776],
                [-64.788071, 18.326385],
                [-64.790283, 18.33083],
                [-64.790009, 18.336941],
                [-64.781952, 18.343052],
                [-64.744171, 18.36861],
                [-64.739182, 18.370831],
                [-64.731949, 18.371944],
                [-64.708618, 18.370552],
                [-64.668335, 18.35083],
                [-64.664459, 18.3475],
                [-64.65834, 18.339722],
                [-64.661118, 18.335278],
                [-64.665283, 18.332222]
              ]
            ],
            [
              [
                [-64.841675, 18.312496],
                [-64.878067, 18.310555],
                [-64.886398, 18.316666],
                [-64.923065, 18.336388],
                [-64.928619, 18.338608],
                [-64.979446, 18.346943],
                [-65.011948, 18.349163],
                [-65.018341, 18.350555],
                [-65.026123, 18.356941],
                [-65.026947, 18.362778],
                [-65.02417, 18.367222],
                [-64.976669, 18.377777],
                [-64.950012, 18.375275],
                [-64.9039, 18.364719],
                [-64.846954, 18.338608],
                [-64.834457, 18.328888],
                [-64.831955, 18.324444],
                [-64.833069, 18.317497],
                [-64.835846, 18.313053],
                [-64.841675, 18.312496]
              ]
            ]
          ]
        },
        "properties": {
          "name": "United States Virgin Islands",
          "density": "282.1429",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 217
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-71.532227, 17.540276],
                [-71.53862, 17.540276],
                [-71.541397, 17.544441],
                [-71.542786, 17.549442],
                [-71.54306, 17.555832],
                [-71.538345, 17.589722],
                [-71.536392, 17.594997],
                [-71.524734, 17.611942],
                [-71.522232, 17.615276],
                [-71.516403, 17.616108],
                [-71.496399, 17.606941],
                [-71.491394, 17.604443],
                [-71.466675, 17.585831],
                [-71.463058, 17.581944],
                [-71.488892, 17.559444],
                [-71.493057, 17.556389],
                [-71.511948, 17.547497],
                [-71.532227, 17.540276]
              ]
            ],
            [
              [
                [-68.574448, 18.129444],
                [-68.568893, 18.114998],
                [-68.568069, 18.109444],
                [-68.568619, 18.103886],
                [-68.573334, 18.101665],
                [-68.590012, 18.113609],
                [-68.62056, 18.121666],
                [-68.640289, 18.124996],
                [-68.652237, 18.123608],
                [-68.662231, 18.11972],
                [-68.688065, 18.111111],
                [-68.694458, 18.111111],
                [-68.701675, 18.111942],
                [-68.731949, 18.119999],
                [-68.753616, 18.12833],
                [-68.757507, 18.131386],
                [-68.779175, 18.165554],
                [-68.78389, 18.174164],
                [-68.785004, 18.179722],
                [-68.78389, 18.195],
                [-68.782227, 18.197498],
                [-68.776947, 18.199165],
                [-68.610001, 18.164719],
                [-68.597778, 18.161388],
                [-68.588898, 18.15583],
                [-68.585556, 18.151943],
                [-68.574448, 18.129444]
              ]
            ],
            [
              [
                [-70.783615, 19.846664],
                [-70.709457, 19.802219],
                [-70.63501, 19.76083],
                [-70.630005, 19.75861],
                [-70.561111, 19.753052],
                [-70.554169, 19.753052],
                [-70.522507, 19.754997],
                [-70.514175, 19.759163],
                [-70.492783, 19.773331],
                [-70.485001, 19.779442],
                [-70.474167, 19.782497],
                [-70.468338, 19.783333],
                [-70.462234, 19.784164],
                [-70.455002, 19.783607],
                [-70.450287, 19.781109],
                [-70.399445, 19.74472],
                [-70.391403, 19.738331],
                [-70.384735, 19.73111],
                [-70.353622, 19.692219],
                [-70.320847, 19.667221],
                [-70.303345, 19.654999],
                [-70.293625, 19.649998],
                [-70.288071, 19.648331],
                [-70.275284, 19.645554],
                [-70.185837, 19.629166],
                [-70.146957, 19.622776],
                [-70.125, 19.620831],
                [-70.100571, 19.623333],
                [-70.09584, 19.625832],
                [-70.087784, 19.631943],
                [-70.084457, 19.63583],
                [-70.072914, 19.655828],
                [-70.06723, 19.662498],
                [-70.055557, 19.674442],
                [-70.051117, 19.676941],
                [-70.035568, 19.682499],
                [-70.029175, 19.682499],
                [-69.966949, 19.678886],
                [-69.952515, 19.677498],
                [-69.946121, 19.676109],
                [-69.936401, 19.671108],
                [-69.898621, 19.637218],
                [-69.891403, 19.630276],
                [-69.885559, 19.622219],
                [-69.882233, 19.612221],
                [-69.881393, 19.600277],
                [-69.892227, 19.525276],
                [-69.878067, 19.445],
                [-69.876114, 19.439999],
                [-69.864731, 19.4175],
                [-69.839172, 19.374443],
                [-69.824448, 19.354443],
                [-69.81778, 19.346664],
                [-69.758347, 19.292221],
                [-69.753616, 19.289444],
                [-69.741394, 19.286388],
                [-69.728897, 19.285831],
                [-69.718613, 19.288887],
                [-69.702515, 19.29361],
                [-69.675568, 19.300831],
                [-69.537231, 19.333332],
                [-69.525848, 19.335552],
                [-69.51918, 19.335552],
                [-69.445557, 19.333332],
                [-69.319168, 19.316387],
                [-69.307236, 19.325554],
                [-69.262222, 19.355],
                [-69.238892, 19.365276],
                [-69.232513, 19.365276],
                [-69.22168, 19.362221],
                [-69.217789, 19.358887],
                [-69.15918, 19.297222],
                [-69.156677, 19.292774],
                [-69.156113, 19.28722],
                [-69.157501, 19.281109],
                [-69.160004, 19.276665],
                [-69.165558, 19.267498],
                [-69.216675, 19.188053],
                [-69.220001, 19.184166],
                [-69.230011, 19.180275],
                [-69.243057, 19.180275],
                [-69.37001, 19.196663],
                [-69.588348, 19.224163],
                [-69.609451, 19.226665],
                [-69.614731, 19.225277],
                [-69.619446, 19.222775],
                [-69.626114, 19.215275],
                [-69.628067, 19.209721],
                [-69.637222, 19.116108],
                [-69.636398, 19.110275],
                [-69.631668, 19.101665],
                [-69.623337, 19.089165],
                [-69.616959, 19.087776],
                [-69.603897, 19.088055],
                [-69.578064, 19.088886],
                [-69.531952, 19.094168],
                [-69.515564, 19.098053],
                [-69.468903, 19.10611],
                [-69.44278, 19.106941],
                [-69.429733, 19.10722],
                [-69.415283, 19.105831],
                [-69.402237, 19.103054],
                [-69.391113, 19.099163],
                [-69.383057, 19.093052],
                [-69.378891, 19.083611],
                [-69.379456, 19.071941],
                [-69.377792, 19.066944],
                [-69.37529, 19.062496],
                [-69.366394, 19.056664],
                [-69.355835, 19.052219],
                [-69.253891, 19.020832],
                [-69.19223, 19.011944],
                [-69.17878, 19.012131],
                [-68.989456, 19.018887],
                [-68.963898, 19.03083],
                [-68.926682, 19.03083],
                [-68.921112, 19.028889],
                [-68.907227, 19.020554],
                [-68.893616, 19.012218],
                [-68.773346, 18.968887],
                [-68.738892, 18.957775],
                [-68.729172, 18.952774],
                [-68.725006, 18.949718],
                [-68.696121, 18.928055],
                [-68.69223, 18.924721],
                [-68.688904, 18.921108],
                [-68.656403, 18.883053],
                [-68.590561, 18.82111],
                [-68.56723, 18.801388],
                [-68.539734, 18.77861],
                [-68.435532, 18.705156],
                [-68.357788, 18.65472],
                [-68.34639, 18.644444],
                [-68.340012, 18.636944],
                [-68.325562, 18.616665],
                [-68.322235, 18.606667],
                [-68.322235, 18.600555],
                [-68.323624, 18.594444],
                [-68.335556, 18.569164],
                [-68.449722, 18.355831],
                [-68.453064, 18.351944],
                [-68.462784, 18.348053],
                [-68.473618, 18.344997],
                [-68.479446, 18.344166],
                [-68.51889, 18.344166],
                [-68.531403, 18.346943],
                [-68.550842, 18.35722],
                [-68.569458, 18.368053],
                [-68.583618, 18.375832],
                [-68.589447, 18.377777],
                [-68.595001, 18.37833],
                [-68.6064, 18.368332],
                [-68.607788, 18.362221],
                [-68.631393, 18.256943],
                [-68.636124, 18.219444],
                [-68.637787, 18.213333],
                [-68.640289, 18.208611],
                [-68.644455, 18.205555],
                [-68.649734, 18.204166],
                [-68.694733, 18.203331],
                [-68.701401, 18.203331],
                [-68.728622, 18.207222],
                [-68.755005, 18.216942],
                [-68.756119, 18.2225],
                [-68.761673, 18.236942],
                [-68.771393, 18.26083],
                [-68.781677, 18.277775],
                [-68.820557, 18.341663],
                [-68.832504, 18.357498],
                [-68.839737, 18.364441],
                [-68.847229, 18.371387],
                [-68.863342, 18.384163],
                [-68.872787, 18.389164],
                [-68.895279, 18.396385],
                [-68.90889, 18.398331],
                [-68.914726, 18.397501],
                [-68.973068, 18.40472],
                [-69.059723, 18.390553],
                [-69.06723, 18.391388],
                [-69.086121, 18.395275],
                [-69.087883, 18.395641],
                [-69.15918, 18.408054],
                [-69.164169, 18.410553],
                [-69.19278, 18.425831],
                [-69.196945, 18.429165],
                [-69.384445, 18.426109],
                [-69.468613, 18.416111],
                [-69.516678, 18.402775],
                [-69.547226, 18.416943],
                [-69.690842, 18.455555],
                [-69.697235, 18.456944],
                [-69.746399, 18.460278],
                [-69.776039, 18.460482],
                [-69.881668, 18.469444],
                [-69.887787, 18.468609],
                [-69.903336, 18.463886],
                [-69.912506, 18.459164],
                [-69.942505, 18.439999],
                [-69.959457, 18.429165],
                [-69.967216, 18.423059],
                [-69.998901, 18.421108],
                [-70.005005, 18.420277],
                [-70.010681, 18.416895],
                [-70.059723, 18.338333],
                [-70.0625, 18.333885],
                [-70.07251, 18.32222],
                [-70.154175, 18.233055],
                [-70.164734, 18.23],
                [-70.17202, 18.23237],
                [-70.184174, 18.227776],
                [-70.229446, 18.22361],
                [-70.319458, 18.230553],
                [-70.380005, 18.236111],
                [-70.387512, 18.236664],
                [-70.400009, 18.234997],
                [-70.411667, 18.22583],
                [-70.459732, 18.204441],
                [-70.475281, 18.199718],
                [-70.486679, 18.19722],
                [-70.504181, 18.194721],
                [-70.510834, 18.194721],
                [-70.545837, 18.206108],
                [-70.556396, 18.22583],
                [-70.547226, 18.230553],
                [-70.546677, 18.235832],
                [-70.552505, 18.250275],
                [-70.555557, 18.253887],
                [-70.587029, 18.274624],
                [-70.573624, 18.309444],
                [-70.571671, 18.31472],
                [-70.568619, 18.364998],
                [-70.586121, 18.401665],
                [-70.589447, 18.405552],
                [-70.593613, 18.408607],
                [-70.611115, 18.41972],
                [-70.622513, 18.423611],
                [-70.652237, 18.431942],
                [-70.664459, 18.434998],
                [-70.690002, 18.433887],
                [-70.693893, 18.430832],
                [-70.712234, 18.413609],
                [-70.711945, 18.408886],
                [-70.707779, 18.399441],
                [-70.707779, 18.39333],
                [-70.708344, 18.386387],
                [-70.712784, 18.376389],
                [-70.722778, 18.364998],
                [-70.730011, 18.358055],
                [-70.738617, 18.352497],
                [-70.747787, 18.347775],
                [-70.753067, 18.346107],
                [-70.866959, 18.312222],
                [-70.935837, 18.253887],
                [-70.98584, 18.292774],
                [-70.990845, 18.295277],
                [-71.026123, 18.305275],
                [-71.038895, 18.307777],
                [-71.053345, 18.309166],
                [-71.065842, 18.308052],
                [-71.071121, 18.306389],
                [-71.079727, 18.301109],
                [-71.082779, 18.297222],
                [-71.099731, 18.263332],
                [-71.103058, 18.245831],
                [-71.099731, 18.235832],
                [-71.078903, 18.189163],
                [-71.074448, 18.179722],
                [-71.066391, 18.167221],
                [-71.056671, 18.156109],
                [-71.054733, 18.151108],
                [-71.055557, 18.144444],
                [-71.090012, 18.077774],
                [-71.184448, 17.934719],
                [-71.193619, 17.922497],
                [-71.20723, 17.907776],
                [-71.222504, 17.894444],
                [-71.248901, 17.879719],
                [-71.266953, 17.846664],
                [-71.396667, 17.618332],
                [-71.40834, 17.609165],
                [-71.416946, 17.603611],
                [-71.422501, 17.601944],
                [-71.426392, 17.603611],
                [-71.429733, 17.607498],
                [-71.625565, 17.836388],
                [-71.65889, 17.953053],
                [-71.663071, 17.963608],
                [-71.672226, 17.975277],
                [-71.678619, 17.982777],
                [-71.690842, 17.992222],
                [-71.732513, 18.022499],
                [-71.751953, 18.032497],
                [-71.767868, 18.038502],
                [-71.749451, 18.088608],
                [-71.748901, 18.095554],
                [-71.753891, 18.145554],
                [-71.765839, 18.180553],
                [-71.770279, 18.19611],
                [-71.76918, 18.202221],
                [-71.767227, 18.207497],
                [-71.72139, 18.284443],
                [-71.715515, 18.292532],
                [-71.694733, 18.32222],
                [-71.716675, 18.330276],
                [-71.743057, 18.340553],
                [-71.7789, 18.356667],
                [-71.810562, 18.375832],
                [-71.89917, 18.431389],
                [-71.907501, 18.437775],
                [-71.910004, 18.441944],
                [-71.910278, 18.44833],
                [-71.90834, 18.453609],
                [-71.905563, 18.458332],
                [-71.885284, 18.473053],
                [-71.88501, 18.477219],
                [-71.890564, 18.485275],
                [-71.897232, 18.492775],
                [-71.949173, 18.547222],
                [-71.996948, 18.592499],
                [-72.003067, 18.60083],
                [-71.979736, 18.619442],
                [-71.970566, 18.624165],
                [-71.92807, 18.629166],
                [-71.922226, 18.62722],
                [-71.9189, 18.623608],
                [-71.913895, 18.614719],
                [-71.910568, 18.611111],
                [-71.905014, 18.608608],
                [-71.898346, 18.608608],
                [-71.853348, 18.616665],
                [-71.83168, 18.622498],
                [-71.823624, 18.628887],
                [-71.789734, 18.674442],
                [-71.775284, 18.69611],
                [-71.766113, 18.700832],
                [-71.756119, 18.704166],
                [-71.736679, 18.705276],
                [-71.732224, 18.707497],
                [-71.72612, 18.71611],
                [-71.724167, 18.721386],
                [-71.715836, 18.749722],
                [-71.709167, 18.780277],
                [-71.708618, 18.78722],
                [-71.708618, 18.793331],
                [-71.715286, 18.874722],
                [-71.716949, 18.879719],
                [-71.719452, 18.883888],
                [-71.775848, 18.954166],
                [-71.779175, 18.957775],
                [-71.75, 19.019722],
                [-71.654449, 19.119442],
                [-71.640839, 19.134163],
                [-71.638901, 19.139721],
                [-71.63501, 19.158054],
                [-71.634171, 19.164719],
                [-71.62973, 19.200275],
                [-71.628891, 19.207222],
                [-71.629181, 19.219721],
                [-71.660278, 19.22583],
                [-71.672226, 19.228886],
                [-71.68779, 19.235554],
                [-71.696121, 19.241665],
                [-71.715561, 19.257774],
                [-71.744736, 19.285831],
                [-71.723618, 19.359997],
                [-71.698624, 19.400276],
                [-71.696671, 19.405552],
                [-71.691681, 19.485832],
                [-71.691956, 19.498333],
                [-71.704453, 19.539444],
                [-71.707779, 19.549442],
                [-71.717789, 19.566666],
                [-71.722229, 19.576111],
                [-71.726395, 19.585552],
                [-71.735001, 19.610275],
                [-71.737793, 19.62722],
                [-71.735291, 19.65472],
                [-71.733063, 19.679157],
                [-71.735001, 19.684166],
                [-71.738068, 19.688053],
                [-71.748062, 19.699165],
                [-71.754181, 19.70583],
                [-71.724731, 19.698608],
                [-71.719727, 19.697498],
                [-71.716949, 19.702221],
                [-71.717224, 19.706944],
                [-71.730835, 19.734444],
                [-71.744736, 19.760277],
                [-71.762222, 19.769444],
                [-71.774734, 19.76833],
                [-71.777237, 19.772778],
                [-71.775848, 19.778889],
                [-71.773346, 19.783333],
                [-71.770004, 19.78722],
                [-71.667786, 19.89222],
                [-71.663895, 19.895275],
                [-71.595291, 19.906387],
                [-71.583344, 19.908054],
                [-71.529449, 19.908333],
                [-71.491669, 19.905277],
                [-71.48584, 19.90472],
                [-71.480286, 19.903053],
                [-71.44751, 19.890553],
                [-71.431122, 19.884163],
                [-71.401947, 19.869442],
                [-71.366669, 19.852497],
                [-71.355835, 19.848331],
                [-71.260834, 19.823055],
                [-71.248901, 19.824718],
                [-71.23204, 19.829563],
                [-71.150284, 19.849442],
                [-71.099731, 19.869999],
                [-71.095001, 19.872498],
                [-71.092224, 19.876942],
                [-71.087784, 19.886944],
                [-71.087234, 19.893887],
                [-71.084167, 19.899166],
                [-71.063614, 19.913887],
                [-71.045288, 19.923332],
                [-71.029175, 19.928333],
                [-71.011398, 19.930832],
                [-70.998062, 19.93111],
                [-70.990845, 19.930275],
                [-70.841949, 19.90361],
                [-70.83223, 19.898609],
                [-70.828064, 19.895554],
                [-70.801117, 19.864719],
                [-70.783615, 19.846664]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Dominican Republic",
          "density": "235",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 48
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-73.584732, 18.056942],
                [-73.590561, 18.05611],
                [-73.661667, 18.056942],
                [-73.667511, 18.05722],
                [-73.672501, 18.059719],
                [-73.705002, 18.084721],
                [-73.707504, 18.09111],
                [-73.706116, 18.101387],
                [-73.702225, 18.104443],
                [-73.696945, 18.104721],
                [-73.66806, 18.104164],
                [-73.603897, 18.091389],
                [-73.598068, 18.089722],
                [-73.588348, 18.084721],
                [-73.580002, 18.078609],
                [-73.577515, 18.074444],
                [-73.576401, 18.062496],
                [-73.584732, 18.056942]
              ]
            ],
            [
              [
                [-73.739456, 18.584164],
                [-73.750839, 18.581387],
                [-73.757782, 18.581944],
                [-73.790283, 18.593887],
                [-73.795288, 18.596386],
                [-73.798615, 18.599998],
                [-73.799728, 18.605553],
                [-73.798065, 18.624722],
                [-73.78334, 18.636944],
                [-73.777512, 18.637775],
                [-73.758057, 18.634441],
                [-73.725845, 18.628609],
                [-73.715286, 18.624443],
                [-73.707779, 18.617775],
                [-73.706116, 18.612778],
                [-73.70639, 18.60722],
                [-73.708893, 18.602497],
                [-73.739456, 18.584164]
              ]
            ],
            [
              [
                [-72.815567, 18.698887],
                [-72.825836, 18.695553],
                [-72.850006, 18.726944],
                [-72.931946, 18.741386],
                [-73.064178, 18.778889],
                [-73.218063, 18.834164],
                [-73.246948, 18.856388],
                [-73.287506, 18.899998],
                [-73.294449, 18.907497],
                [-73.298615, 18.916664],
                [-73.300003, 18.927219],
                [-73.289459, 18.943054],
                [-73.285278, 18.94611],
                [-73.262512, 18.958332],
                [-73.224457, 18.968609],
                [-73.205292, 18.969997],
                [-73.198898, 18.968887],
                [-73.072784, 18.923054],
                [-72.998062, 18.893887],
                [-72.857224, 18.834999],
                [-72.848892, 18.828888],
                [-72.829727, 18.812222],
                [-72.823898, 18.804443],
                [-72.816391, 18.791386],
                [-72.814453, 18.786388],
                [-72.811111, 18.776386],
                [-72.799454, 18.734722],
                [-72.815567, 18.698887]
              ]
            ],
            [
              [
                [-72.673615, 19.916664],
                [-72.579445, 19.884432],
                [-72.540009, 19.845554],
                [-72.493057, 19.820553],
                [-72.317505, 19.762775],
                [-72.209457, 19.749443],
                [-72.20639, 19.745552],
                [-72.202225, 19.742496],
                [-72.190567, 19.738888],
                [-72.097504, 19.7225],
                [-72.05806, 19.720833],
                [-71.980835, 19.72361],
                [-71.818893, 19.711941],
                [-71.754181, 19.70583],
                [-71.748062, 19.699165],
                [-71.738068, 19.688053],
                [-71.735001, 19.684166],
                [-71.733063, 19.679157],
                [-71.735291, 19.65472],
                [-71.737793, 19.62722],
                [-71.735001, 19.610275],
                [-71.726395, 19.585552],
                [-71.722229, 19.576111],
                [-71.717789, 19.566666],
                [-71.707779, 19.549442],
                [-71.704453, 19.539444],
                [-71.691956, 19.498333],
                [-71.691681, 19.485832],
                [-71.696671, 19.405552],
                [-71.698624, 19.400276],
                [-71.723618, 19.359997],
                [-71.744736, 19.285831],
                [-71.715561, 19.257774],
                [-71.696121, 19.241665],
                [-71.68779, 19.235554],
                [-71.672226, 19.228886],
                [-71.660278, 19.22583],
                [-71.629181, 19.219721],
                [-71.628891, 19.207222],
                [-71.62973, 19.200275],
                [-71.634171, 19.164719],
                [-71.63501, 19.158054],
                [-71.638901, 19.139721],
                [-71.640839, 19.134163],
                [-71.654449, 19.119442],
                [-71.75, 19.019722],
                [-71.779175, 18.957775],
                [-71.775848, 18.954166],
                [-71.719452, 18.883888],
                [-71.716949, 18.879719],
                [-71.715286, 18.874722],
                [-71.708618, 18.793331],
                [-71.708618, 18.78722],
                [-71.709167, 18.780277],
                [-71.715836, 18.749722],
                [-71.724167, 18.721386],
                [-71.72612, 18.71611],
                [-71.732224, 18.707497],
                [-71.736679, 18.705276],
                [-71.756119, 18.704166],
                [-71.766113, 18.700832],
                [-71.775284, 18.69611],
                [-71.789734, 18.674442],
                [-71.823624, 18.628887],
                [-71.83168, 18.622498],
                [-71.853348, 18.616665],
                [-71.898346, 18.608608],
                [-71.905014, 18.608608],
                [-71.910568, 18.611111],
                [-71.913895, 18.614719],
                [-71.9189, 18.623608],
                [-71.922226, 18.62722],
                [-71.92807, 18.629166],
                [-71.970566, 18.624165],
                [-71.979736, 18.619442],
                [-72.003067, 18.60083],
                [-71.996948, 18.592499],
                [-71.949173, 18.547222],
                [-71.897232, 18.492775],
                [-71.890564, 18.485275],
                [-71.88501, 18.477219],
                [-71.885284, 18.473053],
                [-71.905563, 18.458332],
                [-71.90834, 18.453609],
                [-71.910278, 18.44833],
                [-71.910004, 18.441944],
                [-71.907501, 18.437775],
                [-71.89917, 18.431389],
                [-71.810562, 18.375832],
                [-71.7789, 18.356667],
                [-71.743057, 18.340553],
                [-71.716675, 18.330276],
                [-71.694733, 18.32222],
                [-71.715515, 18.292532],
                [-71.72139, 18.284443],
                [-71.767227, 18.207497],
                [-71.76918, 18.202221],
                [-71.770279, 18.19611],
                [-71.765839, 18.180553],
                [-71.753891, 18.145554],
                [-71.748901, 18.095554],
                [-71.749451, 18.088608],
                [-71.767868, 18.038502],
                [-71.796112, 18.047775],
                [-71.805283, 18.053333],
                [-71.815002, 18.064442],
                [-71.817505, 18.068886],
                [-71.820282, 18.085552],
                [-71.835281, 18.111664],
                [-71.9039, 18.171108],
                [-71.908066, 18.174164],
                [-71.926682, 18.184719],
                [-72.069168, 18.239719],
                [-72.075562, 18.240276],
                [-72.091949, 18.236111],
                [-72.138336, 18.228054],
                [-72.186401, 18.221386],
                [-72.228058, 18.215832],
                [-72.292786, 18.2225],
                [-72.327515, 18.226387],
                [-72.333618, 18.227776],
                [-72.35556, 18.229443],
                [-72.382233, 18.229443],
                [-72.522781, 18.209442],
                [-72.545837, 18.183052],
                [-72.659454, 18.179443],
                [-72.700012, 18.18111],
                [-72.71167, 18.179165],
                [-72.738892, 18.173611],
                [-72.756393, 18.1675],
                [-72.774734, 18.156387],
                [-72.787506, 18.148331],
                [-72.801392, 18.141109],
                [-72.8125, 18.138611],
                [-72.819168, 18.138332],
                [-72.886124, 18.141388],
                [-72.892502, 18.142498],
                [-72.983887, 18.169678],
                [-73.007233, 18.177498],
                [-73.026672, 18.180832],
                [-73.150009, 18.19833],
                [-73.266678, 18.219444],
                [-73.315567, 18.238888],
                [-73.310562, 18.240555],
                [-73.308899, 18.243053],
                [-73.311401, 18.247498],
                [-73.386398, 18.261944],
                [-73.451401, 18.256943],
                [-73.485291, 18.249722],
                [-73.786118, 18.171665],
                [-73.799728, 18.164444],
                [-73.819168, 18.148609],
                [-73.82695, 18.14222],
                [-73.833344, 18.134441],
                [-73.838348, 18.124996],
                [-73.8414, 18.113609],
                [-73.839737, 18.108608],
                [-73.834457, 18.099998],
                [-73.826126, 18.093887],
                [-73.819458, 18.086666],
                [-73.804459, 18.066944],
                [-73.79834, 18.058887],
                [-73.793335, 18.050278],
                [-73.788895, 18.041111],
                [-73.787781, 18.035553],
                [-73.789734, 18.031387],
                [-73.793335, 18.028332],
                [-73.798065, 18.025833],
                [-73.809174, 18.023052],
                [-73.881668, 18.022778],
                [-73.895844, 18.029163],
                [-73.902237, 18.036663],
                [-73.904175, 18.041386],
                [-73.908066, 18.057499],
                [-73.911667, 18.067497],
                [-73.92778, 18.092777],
                [-73.947235, 18.121666],
                [-73.953064, 18.129719],
                [-73.960556, 18.136387],
                [-74.05278, 18.211666],
                [-74.098068, 18.244999],
                [-74.188339, 18.29361],
                [-74.193893, 18.295555],
                [-74.233612, 18.306389],
                [-74.261948, 18.307777],
                [-74.275009, 18.30722],
                [-74.280289, 18.305553],
                [-74.304733, 18.286942],
                [-74.314453, 18.282776],
                [-74.320282, 18.281944],
                [-74.326126, 18.282219],
                [-74.361954, 18.289444],
                [-74.372513, 18.29361],
                [-74.446671, 18.34194],
                [-74.451126, 18.357498],
                [-74.467789, 18.450832],
                [-74.423615, 18.607777],
                [-74.421112, 18.612499],
                [-74.387222, 18.63583],
                [-74.381958, 18.637497],
                [-74.273895, 18.664719],
                [-74.268066, 18.665833],
                [-74.236679, 18.667221],
                [-74.17778, 18.667221],
                [-74.171951, 18.665554],
                [-74.14917, 18.658333],
                [-74.127228, 18.650833],
                [-74.085556, 18.62722],
                [-74.04834, 18.60611],
                [-74.033615, 18.598888],
                [-74.022232, 18.595276],
                [-73.787231, 18.532219],
                [-73.744446, 18.538887],
                [-73.734177, 18.542221],
                [-73.729446, 18.54472],
                [-73.723618, 18.553333],
                [-73.721954, 18.558609],
                [-73.718903, 18.562496],
                [-73.714737, 18.56583],
                [-73.699173, 18.570831],
                [-73.693069, 18.571941],
                [-73.607788, 18.585278],
                [-73.594727, 18.585552],
                [-73.587509, 18.584999],
                [-73.58168, 18.583332],
                [-73.577515, 18.580276],
                [-73.575012, 18.575832],
                [-73.575562, 18.570553],
                [-73.597733, 18.556389],
                [-73.602394, 18.552721],
                [-73.608727, 18.550554],
                [-73.648056, 18.547222],
                [-73.677505, 18.557777],
                [-73.693893, 18.553333],
                [-73.703613, 18.549164],
                [-73.707504, 18.546108],
                [-73.710556, 18.542221],
                [-73.708344, 18.537777],
                [-73.666397, 18.503887],
                [-73.65834, 18.497776],
                [-73.651947, 18.496666],
                [-73.604446, 18.498055],
                [-73.581345, 18.504],
                [-73.576172, 18.507332],
                [-73.569839, 18.509333],
                [-73.523895, 18.521942],
                [-73.506958, 18.525555],
                [-73.430008, 18.516666],
                [-73.404175, 18.513332],
                [-73.364456, 18.506943],
                [-73.347778, 18.501663],
                [-73.217224, 18.476944],
                [-73.069458, 18.453609],
                [-73.036438, 18.456217],
                [-72.998611, 18.464996],
                [-72.992783, 18.465832],
                [-72.973068, 18.464443],
                [-72.960556, 18.462219],
                [-72.92334, 18.453888],
                [-72.863892, 18.443886],
                [-72.739731, 18.424164],
                [-72.733612, 18.424999],
                [-72.690567, 18.452774],
                [-72.682785, 18.459164],
                [-72.676956, 18.467499],
                [-72.664459, 18.490555],
                [-72.65918, 18.514999],
                [-72.654724, 18.525276],
                [-72.639175, 18.545277],
                [-72.636124, 18.549164],
                [-72.627502, 18.554722],
                [-72.621948, 18.556389],
                [-72.594727, 18.563332],
                [-72.588898, 18.564163],
                [-72.58168, 18.56361],
                [-72.458344, 18.550552],
                [-72.422226, 18.546387],
                [-72.395279, 18.538887],
                [-72.371674, 18.525833],
                [-72.365845, 18.525555],
                [-72.350006, 18.530277],
                [-72.346954, 18.534164],
                [-72.345001, 18.539719],
                [-72.321121, 18.66333],
                [-72.321396, 18.669441],
                [-72.324722, 18.679443],
                [-72.328064, 18.683331],
                [-72.333069, 18.685555],
                [-72.382782, 18.706944],
                [-72.395844, 18.709164],
                [-72.416672, 18.710278],
                [-72.429733, 18.712498],
                [-72.435287, 18.714443],
                [-72.440292, 18.716942],
                [-72.546951, 18.781666],
                [-72.559174, 18.790833],
                [-72.564178, 18.799721],
                [-72.566116, 18.810833],
                [-72.56778, 18.81583],
                [-72.573059, 18.824444],
                [-72.613892, 18.875275],
                [-72.640289, 18.905552],
                [-72.652512, 18.915554],
                [-72.683899, 18.934998],
                [-72.725845, 18.965275],
                [-72.800293, 19.033054],
                [-72.761398, 19.161942],
                [-72.798615, 19.214996],
                [-72.801392, 19.219444],
                [-72.802231, 19.224998],
                [-72.774734, 19.281666],
                [-72.725845, 19.374443],
                [-72.723343, 19.454998],
                [-73.01445, 19.598331],
                [-73.057785, 19.613331],
                [-73.105286, 19.626389],
                [-73.111107, 19.626942],
                [-73.150284, 19.613888],
                [-73.363617, 19.623055],
                [-73.376404, 19.624165],
                [-73.405014, 19.633053],
                [-73.410278, 19.635555],
                [-73.428345, 19.647221],
                [-73.447235, 19.663609],
                [-73.454178, 19.671108],
                [-73.465286, 19.687775],
                [-73.461945, 19.719997],
                [-73.459877, 19.732946],
                [-73.41806, 19.819721],
                [-73.407501, 19.830555],
                [-73.402786, 19.833054],
                [-73.389725, 19.833611],
                [-73.357788, 19.834721],
                [-73.345001, 19.832497],
                [-73.329178, 19.837498],
                [-73.288345, 19.852497],
                [-73.273895, 19.858887],
                [-73.190842, 19.902775],
                [-73.154724, 19.922222],
                [-73.033615, 19.912498],
                [-72.985001, 19.914444],
                [-72.896957, 19.923885],
                [-72.844727, 19.933609],
                [-72.817505, 19.941387],
                [-72.79834, 19.942776],
                [-72.784454, 19.942219],
                [-72.685013, 19.920277],
                [-72.673615, 19.916664]
              ]
            ],
            [
              [
                [-72.628342, 19.986942],
                [-72.640289, 19.985275],
                [-72.690292, 19.987499],
                [-72.69751, 19.988052],
                [-72.876114, 20.024998],
                [-72.94278, 20.041943],
                [-72.952515, 20.046665],
                [-72.956116, 20.050278],
                [-72.95639, 20.056664],
                [-72.952789, 20.060555],
                [-72.925293, 20.074444],
                [-72.915558, 20.078331],
                [-72.862503, 20.087498],
                [-72.818619, 20.091942],
                [-72.811951, 20.09222],
                [-72.805557, 20.090832],
                [-72.794174, 20.087219],
                [-72.68251, 20.042221],
                [-72.658066, 20.029999],
                [-72.623062, 20.006664],
                [-72.621399, 20.001663],
                [-72.621948, 19.99472],
                [-72.624451, 19.990276],
                [-72.628342, 19.986942]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Haiti",
          "density": "425",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 78
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-77.131958, 17.878887],
                [-77.147507, 17.864166],
                [-77.150284, 17.860275],
                [-77.193893, 17.77972],
                [-77.147232, 17.761108],
                [-77.142227, 17.761387],
                [-77.138626, 17.757774],
                [-77.126953, 17.732498],
                [-77.12529, 17.727497],
                [-77.124725, 17.722775],
                [-77.130005, 17.714165],
                [-77.138336, 17.708332],
                [-77.152512, 17.701385],
                [-77.162781, 17.697777],
                [-77.168625, 17.696663],
                [-77.18251, 17.698055],
                [-77.217514, 17.713608],
                [-77.226959, 17.718887],
                [-77.238617, 17.728333],
                [-77.349167, 17.821663],
                [-77.395905, 17.851879],
                [-77.412231, 17.859722],
                [-77.450562, 17.856667],
                [-77.455841, 17.855],
                [-77.482513, 17.839722],
                [-77.487503, 17.837776],
                [-77.493347, 17.836666],
                [-77.507233, 17.836666],
                [-77.518616, 17.839996],
                [-77.566391, 17.859474],
                [-77.609726, 17.854443],
                [-77.719727, 17.847221],
                [-77.733337, 17.84861],
                [-77.737793, 17.851387],
                [-77.789459, 17.885555],
                [-77.793625, 17.888332],
                [-77.797226, 17.891941],
                [-77.820007, 17.937775],
                [-77.835007, 17.987778],
                [-77.840836, 18.002499],
                [-77.846954, 18.010555],
                [-77.85112, 18.013332],
                [-77.870834, 18.022499],
                [-77.878067, 18.022778],
                [-77.904175, 18.021111],
                [-77.936401, 18.026108],
                [-77.948624, 18.02861],
                [-77.952789, 18.031666],
                [-77.961319, 18.054749],
                [-77.963898, 18.061108],
                [-77.969177, 18.069443],
                [-77.980011, 18.086388],
                [-78.041946, 18.182777],
                [-78.044724, 18.186943],
                [-78.057236, 18.196388],
                [-78.074173, 18.201385],
                [-78.101959, 18.202499],
                [-78.128616, 18.203053],
                [-78.194458, 18.199718],
                [-78.20639, 18.19722],
                [-78.226959, 18.189999],
                [-78.232788, 18.188889],
                [-78.238617, 18.190277],
                [-78.254181, 18.194443],
                [-78.336395, 18.217777],
                [-78.3414, 18.220276],
                [-78.362228, 18.234722],
                [-78.369446, 18.241943],
                [-78.373901, 18.25111],
                [-78.373901, 18.258053],
                [-78.372787, 18.262775],
                [-78.371124, 18.266666],
                [-78.365005, 18.274719],
                [-78.342514, 18.298611],
                [-78.345566, 18.335278],
                [-78.341675, 18.35722],
                [-78.339737, 18.362499],
                [-78.336121, 18.365833],
                [-78.213898, 18.450832],
                [-78.208069, 18.451942],
                [-78.023895, 18.452499],
                [-78.015015, 18.451385],
                [-78.000015, 18.441383],
                [-77.936111, 18.496109],
                [-77.920837, 18.509163],
                [-77.882507, 18.519722],
                [-77.864456, 18.522499],
                [-77.849731, 18.521664],
                [-77.73584, 18.505016],
                [-77.700012, 18.489441],
                [-77.688614, 18.486385],
                [-77.640015, 18.479164],
                [-77.615845, 18.483055],
                [-77.611679, 18.484165],
                [-77.601959, 18.488609],
                [-77.595566, 18.488888],
                [-77.564178, 18.489441],
                [-77.558334, 18.489166],
                [-77.484177, 18.478886],
                [-77.478622, 18.477219],
                [-77.466766, 18.469219],
                [-77.460846, 18.46611],
                [-77.407791, 18.456665],
                [-77.343338, 18.451664],
                [-77.339737, 18.453609],
                [-77.323334, 18.458332],
                [-77.316956, 18.458611],
                [-77.250565, 18.452774],
                [-77.243896, 18.451664],
                [-77.181671, 18.434166],
                [-77.166122, 18.428055],
                [-77.157791, 18.422222],
                [-77.136673, 18.407219],
                [-77.067932, 18.406925],
                [-77.056122, 18.410831],
                [-77.043625, 18.412498],
                [-77.037231, 18.411388],
                [-76.945282, 18.394444],
                [-76.896118, 18.374722],
                [-76.854446, 18.344997],
                [-76.805557, 18.302776],
                [-76.790283, 18.276386],
                [-76.787506, 18.272221],
                [-76.782791, 18.26833],
                [-76.724167, 18.263332],
                [-76.718903, 18.263885],
                [-76.7034, 18.266483],
                [-76.65918, 18.235832],
                [-76.640289, 18.223053],
                [-76.551392, 18.19722],
                [-76.53334, 18.192776],
                [-76.458618, 18.180553],
                [-76.386124, 18.167774],
                [-76.380569, 18.166111],
                [-76.365005, 18.159721],
                [-76.341675, 18.149719],
                [-76.292511, 18.078053],
                [-76.2789, 18.050552],
                [-76.274445, 18.041386],
                [-76.258102, 17.995758],
                [-76.234177, 17.941387],
                [-76.221115, 17.904163],
                [-76.265289, 17.879719],
                [-76.306671, 17.865555],
                [-76.350571, 17.855553],
                [-76.53418, 17.856941],
                [-76.591125, 17.860832],
                [-76.597504, 17.861664],
                [-76.607513, 17.866386],
                [-76.613892, 17.873608],
                [-76.6577, 17.929253],
                [-76.693619, 17.937496],
                [-76.724731, 17.948887],
                [-76.727509, 17.953053],
                [-76.73584, 17.959164],
                [-76.746399, 17.963055],
                [-76.800606, 17.966686],
                [-76.811401, 17.976665],
                [-76.819168, 17.98333],
                [-76.829727, 17.987221],
                [-76.836121, 17.986942],
                [-76.847778, 17.982594],
                [-76.894455, 17.888611],
                [-76.914459, 17.851944],
                [-76.920837, 17.843887],
                [-76.924454, 17.840832],
                [-76.929169, 17.838333],
                [-76.948334, 17.829441],
                [-76.954727, 17.829166],
                [-77.00528, 17.844719],
                [-77.03418, 17.886944],
                [-77.038345, 17.889721],
                [-77.048889, 17.893887],
                [-77.054733, 17.895275],
                [-77.080292, 17.899441],
                [-77.101669, 17.895554],
                [-77.111954, 17.891941],
                [-77.131958, 17.878887]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Jamaica",
          "density": "261",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 90
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-60.86084, 14.402777],
                [-60.886948, 14.465555],
                [-60.975006, 14.471388],
                [-61.011116, 14.469999],
                [-61.03389, 14.465832],
                [-61.172501, 14.693054],
                [-61.231674, 14.809444],
                [-61.231392, 14.815554],
                [-61.226669, 14.832222],
                [-61.212784, 14.846666],
                [-61.198616, 14.858889],
                [-61.174728, 14.876944],
                [-61.165001, 14.880278],
                [-61.158615, 14.879999],
                [-61.14473, 14.877777],
                [-61.100006, 14.861387],
                [-61.094727, 14.859165],
                [-61.039726, 14.831944],
                [-61.034447, 14.829166],
                [-60.940834, 14.740833],
                [-60.942505, 14.727777],
                [-60.90667, 14.652498],
                [-60.849724, 14.594721],
                [-60.829727, 14.57],
                [-60.817223, 14.47611],
                [-60.816673, 14.470554],
                [-60.820557, 14.455832],
                [-60.82695, 14.439722],
                [-60.82917, 14.434444],
                [-60.839447, 14.415277],
                [-60.855003, 14.403055],
                [-60.86084, 14.402777]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Martinique",
          "density": "346",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 109
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-67.880569, 18.048332],
                [-67.886398, 18.047497],
                [-67.892227, 18.048054],
                [-67.930557, 18.074997],
                [-67.936951, 18.081387],
                [-67.938339, 18.090275],
                [-67.930847, 18.109722],
                [-67.926682, 18.112778],
                [-67.903336, 18.114441],
                [-67.862793, 18.114166],
                [-67.84668, 18.107498],
                [-67.840286, 18.093887],
                [-67.841675, 18.087776],
                [-67.871399, 18.052776],
                [-67.880569, 18.048332]
              ]
            ],
            [
              [
                [-65.441391, 18.090275],
                [-65.525009, 18.083054],
                [-65.545563, 18.084999],
                [-65.551392, 18.086941],
                [-65.565567, 18.094997],
                [-65.573334, 18.101387],
                [-65.578903, 18.109722],
                [-65.580292, 18.114719],
                [-65.578339, 18.11861],
                [-65.502792, 18.144165],
                [-65.486954, 18.149166],
                [-65.434448, 18.164165],
                [-65.428345, 18.164997],
                [-65.395844, 18.164444],
                [-65.301117, 18.147778],
                [-65.305283, 18.1325],
                [-65.337784, 18.116943],
                [-65.441391, 18.090275]
              ]
            ],
            [
              [
                [-65.242783, 18.302219],
                [-65.275284, 18.276943],
                [-65.279175, 18.278889],
                [-65.333618, 18.331944],
                [-65.33667, 18.335831],
                [-65.338898, 18.346664],
                [-65.335007, 18.348053],
                [-65.284729, 18.331665],
                [-65.273346, 18.327499],
                [-65.262512, 18.32333],
                [-65.248062, 18.315277],
                [-65.244171, 18.311943],
                [-65.241959, 18.307499],
                [-65.242783, 18.302219]
              ]
            ],
            [
              [
                [-66.996674, 18.504997],
                [-66.768829, 18.487677],
                [-66.695557, 18.489441],
                [-66.550568, 18.482498],
                [-66.466675, 18.474163],
                [-66.305557, 18.46722],
                [-66.208344, 18.464165],
                [-66.195, 18.463892],
                [-66.136398, 18.465553],
                [-66.102783, 18.416386],
                [-66.116959, 18.469444],
                [-66.116959, 18.474163],
                [-66.111115, 18.474998],
                [-66.031113, 18.445274],
                [-66.014175, 18.456387],
                [-66.004181, 18.460278],
                [-65.99086, 18.460089],
                [-65.904175, 18.454441],
                [-65.890564, 18.452221],
                [-65.801392, 18.430553],
                [-65.643066, 18.375275],
                [-65.626953, 18.364998],
                [-65.630005, 18.275276],
                [-65.632217, 18.26543],
                [-65.602509, 18.236664],
                [-65.603058, 18.232498],
                [-65.613342, 18.221386],
                [-65.69278, 18.193607],
                [-65.823898, 18.063889],
                [-65.83667, 18.055832],
                [-65.840286, 18.044441],
                [-65.856949, 18.018608],
                [-65.884735, 17.989441],
                [-65.888901, 17.986385],
                [-65.898056, 17.981941],
                [-65.927505, 17.970276],
                [-65.937225, 17.966663],
                [-66.204453, 17.922222],
                [-66.237793, 17.928055],
                [-66.290558, 17.943333],
                [-66.347778, 17.967499],
                [-66.375565, 17.960831],
                [-66.454178, 17.982498],
                [-66.571396, 17.965553],
                [-66.575836, 17.963333],
                [-66.587234, 17.961109],
                [-66.671112, 17.964722],
                [-66.684448, 17.966942],
                [-66.714737, 17.981388],
                [-66.789169, 17.970554],
                [-66.80751, 17.960278],
                [-66.821671, 17.953609],
                [-66.841949, 17.946941],
                [-66.927231, 17.928886],
                [-66.939728, 17.928055],
                [-66.953339, 17.930275],
                [-67.067505, 17.959721],
                [-67.096115, 17.949997],
                [-67.182236, 17.932499],
                [-67.187225, 17.932499],
                [-67.203903, 17.949444],
                [-67.205566, 17.954441],
                [-67.205566, 17.960831],
                [-67.194168, 18.035],
                [-67.175293, 18.10611],
                [-67.156403, 18.188332],
                [-67.157501, 18.214722],
                [-67.169052, 18.23761],
                [-67.170563, 18.242775],
                [-67.174454, 18.251942],
                [-67.189728, 18.278053],
                [-67.193619, 18.281109],
                [-67.199173, 18.283054],
                [-67.211121, 18.286663],
                [-67.218613, 18.28722],
                [-67.224731, 18.288887],
                [-67.229446, 18.291386],
                [-67.240845, 18.301666],
                [-67.25029, 18.313053],
                [-67.261948, 18.341389],
                [-67.266678, 18.362778],
                [-67.266113, 18.368053],
                [-67.172226, 18.484997],
                [-67.168625, 18.488888],
                [-67.154449, 18.503052],
                [-67.146393, 18.509163],
                [-67.141953, 18.511387],
                [-67.139977, 18.511629],
                [-67.101669, 18.518055],
                [-67.083344, 18.519444],
                [-67.063614, 18.519165],
                [-67.039734, 18.515831],
                [-67.026123, 18.513611],
                [-66.996674, 18.504997]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Puerto Rico",
          "density": "368",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 174
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-62.55278, 17.094166],
                [-62.585007, 17.091663],
                [-62.591393, 17.091942],
                [-62.610558, 17.095276],
                [-62.617226, 17.102497],
                [-62.621948, 17.111385],
                [-62.625282, 17.120831],
                [-62.626114, 17.126663],
                [-62.625557, 17.139164],
                [-62.623611, 17.151108],
                [-62.620285, 17.16333],
                [-62.610001, 17.191109],
                [-62.599724, 17.19722],
                [-62.582779, 17.199718],
                [-62.576118, 17.199444],
                [-62.548615, 17.189999],
                [-62.544724, 17.186665],
                [-62.542503, 17.18222],
                [-62.541389, 17.176941],
                [-62.535278, 17.144997],
                [-62.534172, 17.13361],
                [-62.535278, 17.120831],
                [-62.538612, 17.108608],
                [-62.544449, 17.09972],
                [-62.547783, 17.096107],
                [-62.55278, 17.094166]
              ]
            ],
            [
              [
                [-62.701668, 17.336941],
                [-62.622505, 17.241943],
                [-62.62056, 17.231388],
                [-62.621674, 17.224442],
                [-62.625282, 17.220833],
                [-62.653893, 17.208885],
                [-62.662224, 17.239166],
                [-62.671112, 17.257221],
                [-62.677223, 17.264999],
                [-62.692505, 17.278332],
                [-62.707504, 17.285831],
                [-62.71389, 17.28611],
                [-62.725006, 17.284164],
                [-62.73806, 17.284721],
                [-62.759445, 17.287498],
                [-62.778336, 17.292221],
                [-62.793335, 17.299999],
                [-62.835281, 17.324444],
                [-62.839172, 17.327774],
                [-62.861671, 17.366386],
                [-62.863892, 17.370831],
                [-62.856392, 17.38583],
                [-62.849449, 17.393055],
                [-62.838615, 17.401943],
                [-62.817505, 17.410831],
                [-62.812782, 17.409443],
                [-62.734726, 17.368053],
                [-62.730003, 17.365276],
                [-62.701668, 17.336941]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Saint Kitts and Nevis",
          "density": "184",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 178
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-60.923058, 10.797222],
                [-60.94445, 10.76222],
                [-60.963058, 10.739166],
                [-60.987228, 10.714722],
                [-61.013062, 10.702221],
                [-61.018059, 10.700277],
                [-61.027504, 10.689165],
                [-61.033058, 10.680555],
                [-61.035278, 10.675833],
                [-61.045837, 10.491943],
                [-61.045006, 10.486387],
                [-61.02195, 10.394722],
                [-61.020004, 10.389721],
                [-61.003334, 10.358889],
                [-60.99556, 10.346109],
                [-60.992226, 10.342499],
                [-61.000839, 10.158609],
                [-61.008057, 10.140554],
                [-61.083618, 10.102777],
                [-61.107224, 10.091944],
                [-61.152229, 10.075554],
                [-61.199722, 10.069443],
                [-61.206116, 10.068888],
                [-61.405281, 10.066944],
                [-61.526672, 10.068333],
                [-61.674446, 10.075277],
                [-61.679726, 10.076944],
                [-61.705559, 10.078888],
                [-61.803337, 10.081665],
                [-61.808891, 10.080555],
                [-61.833618, 10.071388],
                [-61.858337, 10.062222],
                [-61.88028, 10.0425],
                [-61.902779, 10.038055],
                [-61.909172, 10.037498],
                [-61.914726, 10.039165],
                [-61.91806, 10.042776],
                [-61.923058, 10.051109],
                [-61.924446, 10.055277],
                [-61.924171, 10.062222],
                [-61.922501, 10.067778],
                [-61.915283, 10.074165],
                [-61.81778, 10.127777],
                [-61.647781, 10.197222],
                [-61.511116, 10.234444],
                [-61.501396, 10.238333],
                [-61.492783, 10.243332],
                [-61.464447, 10.270277],
                [-61.458336, 10.278332],
                [-61.455002, 10.289165],
                [-61.452507, 10.299166],
                [-61.460556, 10.56361],
                [-61.465561, 10.578888],
                [-61.473618, 10.597776],
                [-61.489723, 10.630833],
                [-61.501945, 10.639721],
                [-61.543892, 10.668333],
                [-61.548615, 10.670555],
                [-61.597778, 10.684444],
                [-61.604729, 10.684721],
                [-61.619728, 10.679165],
                [-61.626114, 10.67861],
                [-61.654449, 10.678055],
                [-61.659447, 10.680277],
                [-61.662781, 10.683887],
                [-61.663895, 10.706388],
                [-61.662224, 10.709166],
                [-61.658058, 10.711666],
                [-61.605835, 10.741665],
                [-61.599724, 10.742777],
                [-61.480003, 10.750555],
                [-61.432503, 10.752777],
                [-61.426674, 10.753887],
                [-61.422501, 10.756388],
                [-61.390839, 10.775555],
                [-61.378334, 10.783888],
                [-61.2425, 10.790277],
                [-61.196396, 10.789444],
                [-61.185005, 10.791666],
                [-61.175003, 10.795555],
                [-61.170837, 10.798054],
                [-61.156395, 10.81111],
                [-61.146118, 10.814722],
                [-61.140556, 10.815832],
                [-61.079445, 10.824165],
                [-61.075562, 10.826111],
                [-61.03389, 10.83861],
                [-61.016945, 10.841944],
                [-60.928337, 10.83861],
                [-60.910835, 10.834166],
                [-60.907784, 10.830832],
                [-60.908058, 10.823889],
                [-60.910278, 10.819166],
                [-60.923058, 10.797222]
              ]
            ],
            [
              [
                [-60.63945, 11.203054],
                [-60.644173, 11.201387],
                [-60.668892, 11.198889],
                [-60.687782, 11.201944],
                [-60.736389, 11.183611],
                [-60.741112, 11.181665],
                [-60.754173, 11.174166],
                [-60.762222, 11.168333],
                [-60.797501, 11.141666],
                [-60.839172, 11.152498],
                [-60.844727, 11.154165],
                [-60.847504, 11.158333],
                [-60.848335, 11.163887],
                [-60.847229, 11.176943],
                [-60.756668, 11.242222],
                [-60.700836, 11.275833],
                [-60.657784, 11.301388],
                [-60.532501, 11.344999],
                [-60.526672, 11.346109],
                [-60.521118, 11.333611],
                [-60.520561, 11.327499],
                [-60.524727, 11.273888],
                [-60.527229, 11.262499],
                [-60.531113, 11.259165],
                [-60.534447, 11.257221],
                [-60.63945, 11.203054]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Trinidad and Tobago",
          "density": "299",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 194
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-61.24556, 15.871666],
                [-61.256668, 15.869999],
                [-61.275841, 15.870832],
                [-61.283058, 15.871944],
                [-61.305557, 15.879999],
                [-61.310005, 15.882776],
                [-61.316948, 15.889999],
                [-61.329445, 15.92861],
                [-61.329727, 15.934444],
                [-61.309174, 15.972776],
                [-61.275284, 16.009163],
                [-61.266945, 16.014999],
                [-61.261391, 16.012775],
                [-61.23806, 15.993332],
                [-61.202225, 15.952499],
                [-61.196114, 15.944721],
                [-61.186951, 15.92111],
                [-61.186394, 15.915554],
                [-61.1875, 15.91],
                [-61.19445, 15.902777],
                [-61.222778, 15.882776],
                [-61.230835, 15.877222],
                [-61.24556, 15.871666]
              ]
            ],
            [
              [
                [-61.037224, 16.311108],
                [-61.081673, 16.295555],
                [-61.08667, 16.29583],
                [-61.087784, 16.300831],
                [-61.085838, 16.304722],
                [-61.08223, 16.308331],
                [-61.059448, 16.325554],
                [-61.047226, 16.334164],
                [-61.025284, 16.346664],
                [-61.020836, 16.348888],
                [-61.015282, 16.350277],
                [-61.001945, 16.348888],
                [-60.988617, 16.346107],
                [-60.990837, 16.340832],
                [-60.998894, 16.335278],
                [-61.037224, 16.311108]
              ]
            ],
            [
              [
                [-61.346115, 16.334164],
                [-61.339447, 16.333054],
                [-61.333618, 16.333611],
                [-61.291672, 16.326664],
                [-61.279167, 16.32333],
                [-61.268616, 16.318886],
                [-61.260002, 16.312775],
                [-61.205559, 16.26722],
                [-61.225838, 16.253052],
                [-61.454727, 16.200554],
                [-61.466949, 16.200275],
                [-61.474167, 16.201385],
                [-61.498337, 16.208332],
                [-61.509171, 16.212776],
                [-61.521118, 16.222221],
                [-61.53334, 16.231667],
                [-61.54723, 16.229532],
                [-61.563892, 16.228111],
                [-61.567062, 16.226944],
                [-61.568893, 16.224277],
                [-61.581116, 16.20472],
                [-61.58139, 16.198608],
                [-61.580833, 16.193054],
                [-61.576668, 16.166111],
                [-61.575279, 16.160831],
                [-61.567505, 16.142498],
                [-61.555557, 16.076111],
                [-61.55278, 16.054443],
                [-61.559448, 16.033886],
                [-61.5625, 16.029442],
                [-61.572784, 16.01833],
                [-61.606949, 15.989721],
                [-61.648338, 15.965277],
                [-61.685005, 15.947777],
                [-61.690559, 15.946388],
                [-61.695007, 15.949165],
                [-61.736946, 16.015831],
                [-61.75695, 16.047775],
                [-61.761391, 16.056664],
                [-61.793892, 16.241943],
                [-61.796112, 16.264164],
                [-61.793617, 16.306942],
                [-61.783615, 16.333054],
                [-61.759727, 16.351109],
                [-61.74778, 16.359722],
                [-61.7425, 16.361111],
                [-61.730835, 16.362221],
                [-61.726669, 16.360275],
                [-61.655838, 16.324718],
                [-61.609726, 16.283886],
                [-61.610283, 16.27861],
                [-61.606674, 16.275276],
                [-61.581547, 16.275103],
                [-61.557926, 16.284374],
                [-61.556717, 16.284899],
                [-61.54187, 16.290249],
                [-61.498894, 16.34861],
                [-61.522507, 16.393055],
                [-61.529449, 16.417774],
                [-61.532784, 16.433331],
                [-61.53334, 16.439163],
                [-61.53167, 16.451942],
                [-61.526115, 16.460831],
                [-61.50695, 16.484997],
                [-61.470284, 16.51083],
                [-61.466118, 16.513611],
                [-61.460281, 16.514164],
                [-61.456673, 16.513054],
                [-61.436111, 16.498886],
                [-61.407784, 16.476665],
                [-61.404724, 16.472775],
                [-61.402504, 16.468052],
                [-61.393059, 16.432777],
                [-61.391396, 16.421944],
                [-61.399445, 16.399719],
                [-61.400284, 16.392776],
                [-61.398895, 16.387775],
                [-61.39473, 16.378887],
                [-61.373337, 16.351387],
                [-61.366394, 16.344166],
                [-61.35778, 16.338055],
                [-61.346115, 16.334164]
              ]
            ],
            [
              [
                [-62.811394, 17.888332],
                [-62.835556, 17.87833],
                [-62.841118, 17.879166],
                [-62.845001, 17.8825],
                [-62.870003, 17.913055],
                [-62.872505, 17.9175],
                [-62.873062, 17.923332],
                [-62.869171, 17.929722],
                [-62.863335, 17.930275],
                [-62.811668, 17.918053],
                [-62.79084, 17.912498],
                [-62.789452, 17.907497],
                [-62.79084, 17.902775],
                [-62.811394, 17.888332]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Guadeloupe",
          "density": "234",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 226
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-68.195282, 12.221109],
                [-68.19278, 12.210278],
                [-68.193069, 12.204721],
                [-68.205566, 12.113054],
                [-68.228897, 12.039721],
                [-68.235565, 12.025555],
                [-68.238892, 12.022221],
                [-68.251114, 12.020555],
                [-68.255844, 12.022778],
                [-68.259171, 12.02611],
                [-68.275284, 12.051388],
                [-68.278625, 12.061666],
                [-68.285278, 12.102221],
                [-68.279175, 12.139999],
                [-68.279175, 12.146666],
                [-68.281403, 12.157776],
                [-68.286667, 12.173054],
                [-68.28862, 12.177776],
                [-68.298615, 12.195276],
                [-68.304733, 12.203054],
                [-68.312225, 12.209444],
                [-68.328064, 12.215277],
                [-68.354736, 12.217499],
                [-68.367508, 12.216389],
                [-68.388336, 12.216944],
                [-68.39418, 12.218611],
                [-68.398895, 12.220833],
                [-68.402237, 12.224165],
                [-68.416946, 12.250555],
                [-68.416122, 12.256943],
                [-68.404449, 12.292776],
                [-68.399445, 12.301666],
                [-68.395004, 12.304165],
                [-68.384171, 12.307499],
                [-68.369736, 12.306944],
                [-68.363892, 12.305277],
                [-68.199448, 12.224165],
                [-68.195282, 12.221109]
              ]
            ],
            [
              [
                [-68.965561, 12.198889],
                [-68.911957, 12.181665],
                [-68.886124, 12.183054],
                [-68.878891, 12.182777],
                [-68.861679, 12.178888],
                [-68.85112, 12.175278],
                [-68.83667, 12.16861],
                [-68.824173, 12.160276],
                [-68.753067, 12.064999],
                [-68.746948, 12.040277],
                [-68.798889, 12.040277],
                [-68.816956, 12.043333],
                [-68.827515, 12.047222],
                [-68.83223, 12.049444],
                [-68.96167, 12.112499],
                [-68.969727, 12.118055],
                [-69.0625, 12.188332],
                [-69.134445, 12.273333],
                [-69.15834, 12.311388],
                [-69.16362, 12.366388],
                [-69.146393, 12.383888],
                [-69.097778, 12.363333],
                [-69.08139, 12.351944],
                [-69.077789, 12.34861],
                [-69.06723, 12.331665],
                [-69.058334, 12.313055],
                [-69.055557, 12.288887],
                [-69.047791, 12.269722],
                [-69.040283, 12.256388],
                [-69.031403, 12.244444],
                [-69.028061, 12.24111],
                [-68.997223, 12.21611],
                [-68.980835, 12.204721],
                [-68.965561, 12.198889]
              ]
            ],
            [
              [
                [-62.961113, 17.460831],
                [-62.971947, 17.458054],
                [-62.976669, 17.460831],
                [-62.979446, 17.464722],
                [-62.995285, 17.489719],
                [-62.997505, 17.494164],
                [-62.998062, 17.514442],
                [-62.996674, 17.520554],
                [-62.991112, 17.521942],
                [-62.98584, 17.519722],
                [-62.942505, 17.49361],
                [-62.938614, 17.490276],
                [-62.936394, 17.485832],
                [-62.940285, 17.474442],
                [-62.947784, 17.467777],
                [-62.95195, 17.464996],
                [-62.961113, 17.460831]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Netherlands Antilles",
          "density": "354.74",
          "created_at": "2015-05-19T12:50:41Z",
          "updated_at": "2015-05-19T12:50:41Z",
          "cartodb_id": 227
        }
      }
    ]
  }