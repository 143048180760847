import { Modal, ModalHeader, ModalBody, FormGroup, Label, Button, InputGroup } from 'reactstrap';
import { validateUserSignupForm } from '../../utils/validateUserSignupForm';
import { userSignup } from './userSlice';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const UserSignupForm = ({ isSignupOpen, toggleSignupModal, toggle }) => {
   
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleSignup = (values) => {
        const newUser = {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            username: values.username,
            password: values.password,
            admin: false,
            date: new Date(Date.now()).toISOString()
        };
    
        dispatch(userSignup(newUser));
        toggleSignupModal();
        toggle();
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return(            
            <Modal 
                isOpen={isSignupOpen} 
                toggle={toggleSignupModal} 
                id='userSignupModal'>
                <ModalHeader toggle={toggleSignupModal}>
                    Signup
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                                firstname:'',
                                lastname:'',
                                email:'',
                                username: '', 
                                password: ''
                            }}
                        onSubmit={handleSignup}
                        validate={validateUserSignupForm}
                    >
                        <Form>
                        <FormGroup>
                                <Label htmlFor='firstname'>
                                    First Name
                                </Label>
                                <Field 
                                    id='firstname'
                                    name='firstname'
                                    placeholder='Nombre'
                                    className='form-control'
                                />
                                <ErrorMessage name='firstname'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor='lastname'>
                                    Last Name
                                </Label>
                                <Field 
                                    id='lastname'
                                    name='lastname'
                                    placeholder='Apellido'
                                    className='form-control'
                                />
                                <ErrorMessage name='lastname'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor='email'>
                                    E-Mail
                                </Label>
                                <Field 
                                    id='email'
                                    name='email'
                                    placeholder='E-Mail'
                                    className='form-control'
                                />
                                <ErrorMessage name='email'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor='username'>
                                    Username
                                </Label>
                                <Field 
                                    id='username'
                                    name='username'
                                    placeholder='Username'
                                    className='form-control'
                                />
                                <ErrorMessage name='username'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor='password'>
                                    Password
                                </Label>
                                <InputGroup>
                                    <Field 
                                        id='password'
                                        name='password'
                                        placeholder='Password'
                                        type={passwordVisible ? 'text' : 'password'}
                                        className='form-control'
                                    />
                                    <Button onClick={togglePasswordVisibility} color='primary'>
                                        {passwordVisible ? <i className='fa fa-regular fa-eye-slash' /> : <i className="fa fa-regular fa-eye" />}
                                    </Button>  
                                </InputGroup>
                                <ErrorMessage name='password'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <Button type='submit' color='primary'>
                                Submit
                            </Button>{' '}
                            <Button onClick={toggleSignupModal} color='danger'>
                                Cancel
                            </Button>
                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
    );
};

export default UserSignupForm;
