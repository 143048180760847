import Group from '../app/assets/img/ADMS2024.jpeg';
import { Container, Row, Col } from 'reactstrap';
import { Parallax } from "react-parallax";
import { motion } from 'framer-motion';

const ImageThree = () => (
            <Parallax 
                bgImageStyle={{opacity: '0.5'}}
                blur={0}
                bgImage={Group} 
                bgImageAlt='Group' 
                strength={300}>
                    <Container>
                        <Row>
                            <Col>
                                <motion.div 
                                    className='content'
                                    initial={{ opacity: 0, x: 100 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ delay: 2, duration: 2, type: 'tween' }}
                                    viewport={{ once: true }}>
                                    <span className='img-txt'>Dios les bendiga!</span>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
            </Parallax>
);

export default ImageThree;