import { Row, Col, Card, CardBody, CardText, CardLink, CardTitle, CardImg } from 'reactstrap';
import LittleGirlThinking from '../app/assets/img/littleGirlThinking.jpg';
import Events from '../app/assets/img/concert.jpg';
import Registration from '../app/assets/img/registration.jpg';
import { motion } from 'framer-motion';
import CampScene from './CampScene';

const CampHeader = () => {
    return(
        <>
        <CampScene />
        <Row className='campCSS'>
            <Col>
                <motion.div 
                    id='welcome'
                    className="text-box" 
                    intial={{ delay: 4, scale: 0 }}
                    animate={{ rotate: 360, scale: 1 }}
                    whileInView={{ opacity: 1 }}
                    transition={{
                        delay: 3,
                        duration: 6,
                        type: 'spring',
                        stiffness: 269,
                        damping: 60
                    }}>
                    <h1 style={{ color: '#FFFFFF' }}>Bienvenidas!</h1>
                    <h2>A Nuestro Campamento</h2>
                </motion.div>
                <motion.div 
                    initial={{ opacity: 0, scale: 0.3 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 4, duration: 1.2 }}>
                        <p className='centerCardTitle'>
                            Aqui podras recordar y ver los campamentos pasados, saber mas de los eventos del campamento,
                            y registrarse y hacer pagos para el proximo campamento.  
                        </p>
                </motion.div>
                <Row style={{ display: 'flex', justifyContent: 'space-around'}}>
                    <Card className='cardSize'>
                        <CardBody>
                            <CardTitle tag='h3' className='centerCardTitle'>
                                Memorias
                            </CardTitle>
                        </CardBody>
                        <CardImg src={LittleGirlThinking} alt='little girl thinking' className='cardPicBorder' />
                        <CardText className='cardText'>
                            Vea y haga memoria de los campamentos pasados.  
                            Siga el enlace para mas detalle.
                        </CardText>
                        <CardLink href='#memory' className='centerCardTitle'>
                            Memorias
                        </CardLink>
                    </Card>
                    <Card className='cardSize'>
                        <CardBody>
                            <CardTitle tag='h3' className='centerCardTitle'>
                                Eventos
                            </CardTitle>
                        </CardBody>
                        <CardImg src={Events} alt='Concert' className='cardPicBorder' />
                        <CardText className='cardText'>
                            Para mas detalles de los eventos del campamento siga el enlace:
                        </CardText>
                        <CardLink href='#events' className='centerCardTitle'>
                            Eventos
                        </CardLink>
                    </Card>
                    <Card className='cardSize'>
                        <CardBody>
                            <CardTitle tag='h3' className='centerCardTitle'>
                                Registracion
                            </CardTitle>
                        </CardBody>
                        <CardImg src={Registration} alt='Registration Form' className='cardPicBorder' />
                        <CardText className='cardText'>
                            Para mas detalles y llenar la aplicacion para el campamento siga el enlace:
                        </CardText>
                        <CardLink href='#registration' className='centerCardTitle'>
                            Registracion
                        </CardLink>
                    </Card>
                </Row>
            </Col>
        </Row>
    </>
    );
};

export default CampHeader;