import { Card, CardImg, CardText, CardBody, CardTitle } from 'reactstrap';
import { useSpring, a } from '@react-spring/web';
import { motion } from 'framer-motion';
import { useState } from 'react';

const AnimatedLeaderCard = ({ item, idx }) => {
    
    const [flipped, setFlipped] = useState(true);
    const { opacity, transform } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
        config: {mass: 5, tension: 500, friction: 80 }
    });
    const { title, name, image, location, personalData } = item;

    return(
        <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.20 * idx, duration: 1, type: 'tween' }}
            viewport={{ once: true }}>
            <div onClick={() => setFlipped(state => !state)}>
                <a.div  style={{  opacity: opacity.to( o => 1 - o ), transform }}>
                    <Card className='img-thumbnail cardDisplay flipper'>
                        <CardTitle><strong>Un Poco de Mi</strong></CardTitle>
                        <CardBody>
                            <p style={{ marginTop: '-2rem'}}>
                                {personalData}
                            </p>
                        </CardBody>
                    </Card>
                </a.div> 
                <a.div style={{ opacity, transform, rotateY: '180deg' }}>
                    <Card className='img-thumbnail cardDisplay '>
                        <CardImg src={image} alt={title} />
                        <CardBody>
                            <CardText id='cardTextName'>
                                    {name}
                            </CardText>
                            <CardText id='cardTextTitle'>
                                    {title}
                            </CardText>
                            <CardText id='cardTextLocation'>
                                    {location}
                            </CardText>
                        </CardBody>
                    </Card>
                </a.div>
            </div>
        </motion.div> 
    );
};

export default AnimatedLeaderCard;