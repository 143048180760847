export const CostaRicaData = {
    "type": "Feature",
    "properties": {
     "name": "Costa Rica",
     "density": "102",
     "code": "CRI",
     "group": "Countries"
    },
    "geometry": {
     "type": "Polygon",
     "coordinates": [
      [
       [
        -82.965783,
        8.225028
       ],
       [
        -83.508437,
        8.446927
       ],
       [
        -83.711474,
        8.656836
       ],
       [
        -83.596313,
        8.830443
       ],
       [
        -83.632642,
        9.051386
       ],
       [
        -83.909886,
        9.290803
       ],
       [
        -84.303402,
        9.487354
       ],
       [
        -84.647644,
        9.615537
       ],
       [
        -84.713351,
        9.908052
       ],
       [
        -84.97566,
        10.086723
       ],
       [
        -84.911375,
        9.795992
       ],
       [
        -85.110923,
        9.55704
       ],
       [
        -85.339488,
        9.834542
       ],
       [
        -85.660787,
        9.933347
       ],
       [
        -85.797445,
        10.134886
       ],
       [
        -85.791709,
        10.439337
       ],
       [
        -85.659314,
        10.754331
       ],
       [
        -85.941725,
        10.895278
       ],
       [
        -85.71254,
        11.088445
       ],
       [
        -85.561852,
        11.217119
       ],
       [
        -84.903003,
        10.952303
       ],
       [
        -84.673069,
        11.082657
       ],
       [
        -84.355931,
        10.999226
       ],
       [
        -84.190179,
        10.79345
       ],
       [
        -83.895054,
        10.726839
       ],
       [
        -83.655612,
        10.938764
       ],
       [
        -83.40232,
        10.395438
       ],
       [
        -83.015677,
        9.992982
       ],
       [
        -82.546196,
        9.566135
       ],
       [
        -82.932891,
        9.476812
       ],
       [
        -82.927155,
        9.07433
       ],
       [
        -82.719183,
        8.925709
       ],
       [
        -82.868657,
        8.807266
       ],
       [
        -82.829771,
        8.626295
       ],
       [
        -82.913176,
        8.423517
       ],
       [
        -82.965783,
        8.225028
       ]
      ]
     ]
    },
    "_id": "costa rica"
   }