export const EuropeData = {
    "type": "FeatureCollection",
    "name": "europe-1938",
    "features": [
      {
        "type": "Feature",
        "properties": {
          "name": "Luxembourg",
          "density": "253",
          "cartodb_id": 1,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [6.027164, 49.524078],
                [6.088064, 49.680508],
                [5.948726, 49.772232],
                [5.941587, 49.91943],
                [6.03742, 50.064381],
                [6.147439, 50.130783],
                [6.211109, 50.166946],
                [6.312177, 50.134426],
                [6.340263, 49.998951],
                [6.525142, 49.858585],
                [6.734586, 49.815399],
                [6.715019, 49.685902],
                [6.603117, 49.621159],
                [6.636707, 49.462303],
                [6.57162, 49.490456],
                [6.315996, 49.495625],
                [6.027164, 49.524078]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Switzerland",
          "density": "213.04",
          "cartodb_id": 2,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [9.700788, 47.577412],
                [9.788012, 47.426029],
                [9.668275, 47.302494],
                [9.764495, 47.172832],
                [9.724424, 47.086411],
                [10.004237, 47.058636],
                [10.007842, 46.993839],
                [10.255184, 46.884708],
                [10.515508, 47.033947],
                [10.612238, 46.981499],
                [10.599363, 46.904385],
                [10.542387, 46.76532],
                [10.592857, 46.669731],
                [10.575771, 46.583275],
                [10.434008, 46.606228],
                [10.354904, 46.678837],
                [10.227317, 46.637871],
                [10.191092, 46.515026],
                [10.300774, 46.455887],
                [10.198193, 46.269447],
                [10.088621, 46.419502],
                [9.877404, 46.396763],
                [9.845713, 46.342194],
                [9.74853, 46.328556],
                [9.644661, 46.392208],
                [9.580463, 46.551422],
                [9.413733, 46.515026],
                [9.421941, 46.364929],
                [9.230639, 46.192165],
                [9.222591, 45.951332],
                [9.094389, 45.883209],
                [8.921244, 46.024017],
                [8.965364, 46.105816],
                [8.825603, 46.13763],
                [8.585223, 46.30127],
                [8.578172, 46.487732],
                [8.228458, 46.305817],
                [8.274562, 46.210346],
                [8.117544, 46.015949],
                [7.993593, 45.943939],
                [7.710453, 46.019485],
                [7.428459, 45.927906],
                [7.158947, 45.955875],
                [7.113599, 46.146778],
                [6.972738, 46.509579],
                [6.695372, 46.443142],
                [6.311584, 46.142506],
                [6.145153, 46.192513],
                [6.252652, 46.294132],
                [6.258284, 46.4389],
                [6.222259, 46.500515],
                [6.262032, 46.633038],
                [6.585193, 46.821102],
                [6.567965, 46.96299],
                [6.827006, 47.070984],
                [6.832334, 47.104923],
                [7.069931, 47.327202],
                [7.147897, 47.342644],
                [7.095629, 47.382786],
                [7.026623, 47.395145],
                [7.125581, 47.531059],
                [7.326581, 47.527973],
                [7.324548, 47.484726],
                [7.537084, 47.481636],
                [7.710246, 47.620678],
                [7.832073, 47.580502],
                [7.95471, 47.633038],
                [8.076324, 47.589771],
                [8.331659, 47.660854],
                [8.456723, 47.611408],
                [8.615914, 47.620678],
                [8.535692, 47.710316],
                [8.8545, 47.799961],
                [8.862229, 47.731945],
                [8.95167, 47.738136],
                [9.01905, 47.697945],
                [9.402222, 47.694859],
                [9.700788, 47.577412]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "France",
          "density": "118",
          "cartodb_id": 3,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [9.570715, 43.005074],
                [9.617903, 42.926498],
                [9.635825, 42.811108],
                [9.609159, 42.597569],
                [9.662216, 42.573029],
                [9.690375, 42.423351],
                [9.656173, 42.065277],
                [9.56112, 41.918182],
                [9.508203, 41.592239],
                [9.376688, 41.366879],
                [9.242603, 41.386471],
                [9.216932, 41.474651],
                [9.032416, 41.508949],
                [8.930585, 41.577541],
                [9.058312, 41.67065],
                [9.026918, 41.704948],
                [8.83059, 41.749062],
                [8.946114, 41.898571],
                [8.896493, 41.930435],
                [8.76553, 41.898571],
                [8.741596, 41.964752],
                [8.793252, 41.974564],
                [8.871976, 42.045658],
                [8.830118, 42.114315],
                [8.714362, 42.151096],
                [8.72091, 42.224674],
                [8.832086, 42.278629],
                [8.70411, 42.376751],
                [8.812964, 42.504318],
                [9.082666, 42.641739],
                [9.204057, 42.651562],
                [9.222092, 42.708012],
                [9.353125, 42.727642],
                [9.441357, 42.681011],
                [9.484056, 42.744827],
                [9.474184, 42.899483],
                [9.506853, 43.000164],
                [9.570715, 43.005074]
              ]
            ],
            [
              [
                [-1.710914, 43.322388],
                [-1.542406, 43.377132],
                [-1.357744, 43.541397],
                [-1.110715, 44.575935],
                [-0.932186, 44.617073],
                [-1.026657, 44.747383],
                [-1.128114, 44.630798],
                [-1.07185, 45.145702],
                [-0.971528, 45.517475],
                [-0.919777, 45.448589],
                [-0.6967, 45.310883],
                [-0.610563, 45.138813],
                [-0.524822, 45.035614],
                [-0.651039, 45.338421],
                [-0.934731, 45.600136],
                [-1.043731, 45.70512],
                [-1.032842, 45.817017],
                [-0.871536, 46.03159],
                [-0.962318, 46.200653],
                [-1.052393, 46.323803],
                [-1.199005, 46.420906],
                [-1.507063, 46.465763],
                [-1.738814, 46.722771],
                [-1.925997, 46.867817],
                [-1.800982, 47.059475],
                [-1.905814, 47.141014],
                [-2.060086, 47.199856],
                [-2.290048, 47.263893],
                [-2.37282, 47.341839],
                [-2.26188, 47.511356],
                [-2.409988, 47.589222],
                [-2.63881, 47.508877],
                [-2.859033, 47.620911],
                [-3.229862, 47.733784],
                [-3.508204, 47.815121],
                [-3.654438, 47.808781],
                [-3.751174, 47.92131],
                [-4.16071, 47.846283],
                [-4.187272, 47.95882],
                [-4.306464, 48.015102],
                [-4.471734, 48.033867],
                [-4.496643, 48.080776],
                [-4.109202, 48.118305],
                [-4.14732, 48.221539],
                [-4.384305, 48.268467],
                [-4.185238, 48.315411],
                [-4.146538, 48.407478],
                [-4.27013, 48.39991],
                [-4.388358, 48.40929],
                [-4.575971, 48.371746],
                [-4.5583, 48.568974],
                [-4.42204, 48.563641],
                [-4.284636, 48.629238],
                [-4.047813, 48.668884],
                [-3.795209, 48.747509],
                [-3.698554, 48.662933],
                [-3.617643, 48.738121],
                [-3.40421, 48.709923],
                [-3.354006, 48.827049],
                [-3.231394, 48.853432],
                [-2.887562, 48.888523],
                [-2.710796, 48.723759],
                [-2.586872, 48.606552],
                [-2.432714, 48.568974],
                [-2.139222, 48.69112],
                [-1.996408, 48.615955],
                [-1.872304, 48.67313],
                [-1.666903, 48.728714],
                [-1.653949, 48.62534],
                [-1.387693, 48.654472],
                [-1.252924, 48.700523],
                [-1.371884, 48.785114],
                [-1.384748, 48.897923],
                [-1.359745, 49.053211],
                [-1.376116, 49.245983],
                [-1.435336, 49.23658],
                [-1.68788, 49.543324],
                [-1.73762, 49.726276],
                [-1.320583, 49.669743],
                [-1.047306, 49.71685],
                [-1.084997, 49.537945],
                [-0.97348, 49.349522],
                [-0.819527, 49.415432],
                [-0.261285, 49.368351],
                [-0.035701, 49.30246],
                [0.0, 49.308784],
                [0.236534, 49.350677],
                [0.534512, 49.471928],
                [0.294036, 49.527527],
                [0.318718, 49.619247],
                [0.393037, 49.697998],
                [0.732224, 49.842773],
                [1.072421, 49.924221],
                [1.478183, 50.021275],
                [1.619892, 50.126816],
                [1.755653, 50.245781],
                [1.731961, 50.5947],
                [1.792584, 50.838856],
                [2.027219, 50.951534],
                [2.233992, 51.023628],
                [2.64626, 51.065002],
                [2.725796, 51.108093],
                [2.819393, 50.907188],
                [2.959146, 50.75613],
                [3.078724, 50.698795],
                [3.14194, 50.730728],
                [3.326508, 50.824104],
                [3.39554, 50.747696],
                [3.486423, 50.541298],
                [3.573981, 50.481579],
                [3.807043, 50.487011],
                [3.878626, 50.351341],
                [3.995129, 50.356758],
                [4.254551, 50.33506],
                [4.423234, 50.259129],
                [4.35148, 50.150688],
                [4.392124, 50.071918],
                [4.359979, 49.98275],
                [4.488292, 49.927952],
                [4.671145, 49.961704],
                [4.871759, 49.977348],
                [4.958583, 50.11816],
                [5.069075, 50.150688],
                [5.015288, 49.98275],
                [5.08073, 49.901772],
                [5.063536, 49.799213],
                [5.301371, 49.777626],
                [5.454942, 49.691299],
                [5.621752, 49.59959],
                [5.67908, 49.497112],
                [5.991509, 49.575626],
                [6.027164, 49.524078],
                [6.315996, 49.495625],
                [6.57162, 49.490456],
                [6.636707, 49.462303],
                [6.829165, 49.31876],
                [6.980906, 49.193874],
                [7.158078, 49.218708],
                [7.178404, 49.153542],
                [7.488662, 49.181465],
                [7.521042, 49.203186],
                [7.626476, 49.200081],
                [7.669094, 49.116306],
                [7.874122, 49.075981],
                [8.089372, 49.08218],
                [8.361959, 48.995319],
                [7.932742, 48.632591],
                [7.908243, 48.536549],
                [7.884306, 48.375496],
                [7.739692, 48.208309],
                [7.7058, 48.075245],
                [7.746782, 48.041203],
                [7.649627, 47.725765],
                [7.710246, 47.620678],
                [7.537084, 47.481636],
                [7.324548, 47.484726],
                [7.326581, 47.527973],
                [7.125581, 47.531059],
                [7.026623, 47.395145],
                [7.095629, 47.382786],
                [7.147897, 47.342644],
                [7.069931, 47.327202],
                [6.832334, 47.104923],
                [6.827006, 47.070984],
                [6.567965, 46.96299],
                [6.585193, 46.821102],
                [6.262032, 46.633038],
                [6.222259, 46.500515],
                [6.258284, 46.4389],
                [6.252652, 46.294132],
                [6.145153, 46.192513],
                [6.311584, 46.142506],
                [6.695372, 46.443142],
                [6.972738, 46.509579],
                [7.113599, 46.146778],
                [7.158947, 45.955875],
                [6.878174, 45.820244],
                [7.117994, 45.665268],
                [7.129212, 45.533653],
                [7.322778, 45.447456],
                [7.232861, 45.324989],
                [6.989592, 45.166294],
                [6.907252, 45.216167],
                [6.710983, 45.132679],
                [6.868982, 44.971493],
                [7.146267, 44.885624],
                [7.207134, 44.736507],
                [7.128358, 44.731991],
                [6.986581, 44.564846],
                [7.073442, 44.366142],
                [7.412861, 44.190067],
                [7.794744, 44.221668],
                [7.822438, 44.095284],
                [7.756474, 43.884422],
                [7.600782, 43.781067],
                [7.50128, 43.684273],
                [7.126744, 43.464638],
                [6.726678, 43.167824],
                [6.534889, 43.128841],
                [6.391442, 43.109261],
                [6.032234, 43.037788],
                [5.850219, 43.050289],
                [5.638154, 43.120628],
                [5.255481, 43.34507],
                [5.11351, 43.383411],
                [4.954258, 43.370232],
                [4.850178, 43.337837],
                [4.620429, 43.363117],
                [4.28186, 43.504059],
                [4.011133, 43.4842],
                [3.682377, 43.329067],
                [3.521532, 43.212925],
                [3.271564, 42.961536],
                [3.205555, 42.787781],
                [3.249563, 42.556427],
                [3.290745, 42.385616],
                [3.140168, 42.426613],
                [2.802931, 42.365116],
                [2.785174, 42.303635],
                [2.566838, 42.310467],
                [2.374771, 42.399288],
                [2.197335, 42.324127],
                [2.079782, 42.330959],
                [2.063759, 42.399288],
                [1.912892, 42.440285],
                [1.820269, 42.426613],
                [1.651816, 42.392452],
                [1.56806, 42.426613],
                [1.552369, 42.556454],
                [1.511114, 42.652145],
                [0.947863, 42.788864],
                [0.787806, 42.788864],
                [0.795587, 42.645306],
                [0.551507, 42.652145],
                [0.399929, 42.624798],
                [0.383214, 42.679489],
                [0.038037, 42.66581],
                [0.0, 42.717285],
                [-0.012477, 42.734173],
                [-0.206251, 42.80254],
                [-0.265173, 42.768364],
                [-0.383126, 42.782032],
                [-0.408318, 42.747849],
                [-0.636355, 42.925625],
                [-0.70369, 42.89827],
                [-1.193609, 43.048729],
                [-1.243889, 43.000851],
                [-1.311464, 43.014534],
                [-1.354007, 43.062405],
                [-1.261988, 43.17186],
                [-1.287719, 43.226593],
                [-1.498728, 43.21291],
                [-1.710914, 43.322388]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Estonia",
          "density": "31",
          "cartodb_id": 6,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [28.216757, 59.475193],
                [28.282095, 59.451118],
                [28.354141, 59.39217],
                [28.334494, 59.313576],
                [28.255896, 59.293926],
                [28.17075, 59.293926],
                [28.118353, 59.293926],
                [28.079054, 59.274277],
                [28.052855, 59.215328],
                [27.974258, 59.090885],
                [27.876013, 58.999187],
                [27.731918, 58.940243],
                [27.640223, 58.881294],
                [27.600924, 58.809246],
                [27.614023, 58.737202],
                [27.672972, 58.54071],
                [27.705721, 58.455563],
                [27.718819, 58.383514],
                [27.640223, 58.32457],
                [27.640223, 58.245972],
                [27.718819, 58.141178],
                [27.810516, 58.101879],
                [27.849813, 58.06913],
                [27.849813, 58.036381],
                [27.856363, 57.997082],
                [27.876013, 57.931587],
                [27.954609, 57.911938],
                [27.980808, 57.885738],
                [27.902212, 57.852989],
                [27.784317, 57.83334],
                [27.712271, 57.80714],
                [27.686071, 57.761292],
                [27.646772, 57.708897],
                [27.587826, 57.708897],
                [27.574726, 57.656498],
                [27.568176, 57.617199],
                [27.515778, 57.591],
                [27.552526, 57.543331],
                [27.496128, 57.532051],
                [27.456831, 57.538601],
                [27.319286, 57.55825],
                [27.24724, 57.58445],
                [27.168644, 57.617199],
                [27.076948, 57.636848],
                [27.018, 57.591],
                [26.926304, 57.604099],
                [26.867357, 57.58445],
                [26.795309, 57.545151],
                [26.729813, 57.538601],
                [26.664316, 57.5779],
                [26.611917, 57.591],
                [26.533321, 57.591],
                [26.441626, 57.643398],
                [26.389227, 57.721996],
                [26.297531, 57.774391],
                [26.232035, 57.794041],
                [26.199286, 57.846439],
                [26.11414, 57.846439],
                [26.002794, 57.866089],
                [25.911098, 57.918488],
                [25.897999, 57.918488],
                [25.773554, 57.925037],
                [25.727707, 57.970882],
                [25.616362, 57.990532],
                [25.563963, 58.036381],
                [25.478817, 58.07568],
                [25.478817, 58.042931],
                [25.42642, 58.003632],
                [25.367472, 58.029831],
                [25.360922, 58.06258],
                [25.321625, 58.07568],
                [25.275776, 58.07568],
                [25.151331, 58.023281],
                [25.105484, 58.010181],
                [25.046535, 58.003632],
                [24.94829, 57.997082],
                [24.882793, 57.977432],
                [24.764898, 57.964333],
                [24.640453, 57.925037],
                [24.588057, 57.879189],
                [24.549313, 57.879189],
                [24.619308, 57.964905],
                [24.618065, 58.12286],
                [24.700449, 58.230125],
                [24.534756, 58.324905],
                [24.262014, 58.128502],
                [24.111732, 58.241421],
                [23.874523, 58.258362],
                [23.894129, 58.682457],
                [23.715534, 58.597847],
                [23.564484, 58.784393],
                [23.635643, 58.875053],
                [23.54155, 58.989365],
                [23.660643, 59.022465],
                [23.619421, 59.135937],
                [23.773848, 59.155045],
                [24.235771, 59.192715],
                [24.233223, 59.252388],
                [24.485609, 59.301113],
                [24.545231, 59.385853],
                [24.741232, 59.340382],
                [24.774588, 59.3913],
                [24.917477, 59.368805],
                [24.954885, 59.465435],
                [25.451954, 59.378769],
                [25.652458, 59.510933],
                [25.693657, 59.437016],
                [25.924786, 59.476814],
                [26.546923, 59.463619],
                [27.065565, 59.388721],
                [27.202681, 59.334702],
                [27.468756, 59.355015],
                [27.905703, 59.314476],
                [28.097261, 59.363083],
                [28.219515, 59.471127],
                [28.216757, 59.475193]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Latvia",
          "density":"29",
          "cartodb_id": 7,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [27.552526, 57.543331],
                [27.718819, 57.545151],
                [27.712271, 57.492756],
                [27.712271, 57.440357],
                [27.817064, 57.407608],
                [27.90876, 57.368309],
                [27.948059, 57.33556],
                [28.039755, 57.309364],
                [28.033205, 57.270065],
                [28.020107, 57.217667],
                [28.013557, 57.178368],
                [27.967709, 57.145618],
                [27.92841, 57.139069],
                [27.882563, 57.125969],
                [27.941509, 57.086674],
                [27.90876, 57.027725],
                [27.92186, 56.975327],
                [27.889112, 56.936028],
                [27.843264, 56.903282],
                [27.823614, 56.857433],
                [27.91531, 56.883633],
                [27.980808, 56.890182],
                [28.039755, 56.857433],
                [28.079054, 56.850883],
                [28.111803, 56.831234],
                [28.085604, 56.791935],
                [28.065954, 56.759186],
                [28.118353, 56.713337],
                [28.177299, 56.687141],
                [28.190399, 56.615093],
                [28.255896, 56.569244],
                [28.327944, 56.549595],
                [28.301744, 56.510296],
                [28.360691, 56.457901],
                [28.354141, 56.372753],
                [28.39344, 56.26796],
                [28.373791, 56.228661],
                [28.367241, 56.176262],
                [28.340082, 56.157391],
                [28.231224, 56.137321],
                [28.123306, 56.114197],
                [28.069345, 56.060238],
                [27.969135, 56.044819],
                [27.907467, 55.975445],
                [27.8458, 55.936901],
                [27.814966, 55.890652],
                [27.784132, 55.798149],
                [27.699339, 55.78273],
                [27.591421, 55.798149],
                [27.483501, 55.813564],
                [27.33704, 55.828983],
                [27.175163, 55.828983],
                [27.09808, 55.798149],
                [27.051826, 55.736481],
                [26.951618, 55.697937],
                [26.88224, 55.697937],
                [26.835991, 55.705647],
                [26.797447, 55.682522],
                [26.627861, 55.697937],
                [26.504526, 55.728771],
                [26.43515, 55.805855],
                [26.373482, 55.875233],
                [26.22702, 55.936901],
                [26.003475, 56.037109],
                [25.849306, 56.145031],
                [25.67972, 56.160446],
                [25.417631, 56.175861],
                [25.27117, 56.229824],
                [25.140127, 56.314617],
                [25.101583, 56.430244],
                [25.01679, 56.422535],
                [24.939707, 56.391701],
                [24.777828, 56.314617],
                [24.600534, 56.306908],
                [24.492615, 56.306908],
                [24.36928, 56.268364],
                [24.26136, 56.306908],
                [24.122608, 56.353157],
                [23.852812, 56.360867],
                [23.721767, 56.330032],
                [23.559891, 56.360867],
                [23.428846, 56.383991],
                [23.35947, 56.368576],
                [23.236134, 56.337742],
                [23.189884, 56.360867],
                [23.135923, 56.422535],
                [23.066547, 56.422535],
                [22.95092, 56.391701],
                [22.866127, 56.391701],
                [22.526955, 56.39941],
                [22.326534, 56.43795],
                [21.987362, 56.360867],
                [21.717566, 56.291489],
                [21.524853, 56.245239],
                [21.409227, 56.183571],
                [21.386101, 56.098778],
                [21.149113, 56.086929],
                [21.119671, 56.113644],
                [21.15271, 56.490162],
                [21.168491, 56.625996],
                [21.225716, 56.700848],
                [21.528044, 56.871387],
                [21.543411, 57.194042],
                [21.663252, 57.266579],
                [21.783178, 57.389572],
                [21.880995, 57.480629],
                [22.013918, 57.492352],
                [22.489067, 57.582012],
                [22.563591, 57.602638],
                [22.636629, 57.63253],
                [22.767302, 57.63253],
                [22.789085, 57.525623],
                [23.085037, 57.351116],
                [23.274553, 57.27829],
                [23.378328, 57.065464],
                [23.419113, 56.997639],
                [23.618134, 56.909386],
                [23.971478, 56.882854],
                [24.362047, 56.992458],
                [24.469969, 57.047119],
                [24.550163, 57.176693],
                [24.526604, 57.353863],
                [24.43985, 57.745132],
                [24.529106, 57.854443],
                [24.549313, 57.879189],
                [24.588057, 57.879189],
                [24.640453, 57.925037],
                [24.764898, 57.964333],
                [24.882793, 57.977432],
                [24.94829, 57.997082],
                [25.046535, 58.003632],
                [25.105484, 58.010181],
                [25.151331, 58.023281],
                [25.275776, 58.07568],
                [25.321625, 58.07568],
                [25.360922, 58.06258],
                [25.367472, 58.029831],
                [25.42642, 58.003632],
                [25.478817, 58.042931],
                [25.478817, 58.07568],
                [25.563963, 58.036381],
                [25.616362, 57.990532],
                [25.727707, 57.970882],
                [25.773554, 57.925037],
                [25.897999, 57.918488],
                [25.911098, 57.918488],
                [26.002794, 57.866089],
                [26.11414, 57.846439],
                [26.199286, 57.846439],
                [26.232035, 57.794041],
                [26.297531, 57.774391],
                [26.389227, 57.721996],
                [26.441626, 57.643398],
                [26.533321, 57.591],
                [26.611917, 57.591],
                [26.664316, 57.5779],
                [26.729813, 57.538601],
                [26.795309, 57.545151],
                [26.867357, 57.58445],
                [26.926304, 57.604099],
                [27.018, 57.591],
                [27.076948, 57.636848],
                [27.168644, 57.617199],
                [27.24724, 57.58445],
                [27.319286, 57.55825],
                [27.456831, 57.538601],
                [27.496128, 57.532051],
                [27.552526, 57.543331]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Lithuania",
          "density": "43",
          "cartodb_id": 8,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [22.9027, 54.431873],
                [22.889254, 54.449165],
                [22.873837, 54.510834],
                [22.866127, 54.587917],
                [22.896961, 54.68042],
                [22.927795, 54.749798],
                [23.028006, 54.803757],
                [23.004881, 54.857716],
                [22.997171, 54.919384],
                [22.920088, 54.950218],
                [22.835293, 54.965633],
                [22.789043, 55.011883],
                [22.7505, 55.073551],
                [22.65029, 55.065842],
                [22.534664, 55.065842],
                [22.334244, 55.065842],
                [22.234034, 55.042717],
                [22.19549, 55.08897],
                [21.964237, 55.119804],
                [21.84861, 55.150639],
                [21.7484, 55.204597],
                [21.64048, 55.204597],
                [21.601938, 55.227722],
                [21.578814, 55.297096],
                [21.524853, 55.281681],
                [21.365986, 55.238354],
                [21.346699, 55.272812],
                [21.404596, 55.278366],
                [21.41996, 55.360638],
                [21.222115, 55.650982],
                [21.218479, 55.714027],
                [21.199621, 56.0411],
                [21.149113, 56.086929],
                [21.386101, 56.098778],
                [21.409227, 56.183571],
                [21.524853, 56.245239],
                [21.717566, 56.291489],
                [21.987362, 56.360867],
                [22.326534, 56.43795],
                [22.526955, 56.39941],
                [22.866127, 56.391701],
                [22.95092, 56.391701],
                [23.066547, 56.422535],
                [23.135923, 56.422535],
                [23.189884, 56.360867],
                [23.236134, 56.337742],
                [23.35947, 56.368576],
                [23.428846, 56.383991],
                [23.559891, 56.360867],
                [23.721767, 56.330032],
                [23.852812, 56.360867],
                [24.122608, 56.353157],
                [24.26136, 56.306908],
                [24.36928, 56.268364],
                [24.492615, 56.306908],
                [24.600534, 56.306908],
                [24.777828, 56.314617],
                [24.939707, 56.391701],
                [25.01679, 56.422535],
                [25.101583, 56.430244],
                [25.140127, 56.314617],
                [25.27117, 56.229824],
                [25.417631, 56.175861],
                [25.67972, 56.160446],
                [25.849306, 56.145031],
                [26.003475, 56.037109],
                [26.22702, 55.936901],
                [26.373482, 55.875233],
                [26.43515, 55.805855],
                [26.504526, 55.728771],
                [26.627861, 55.697937],
                [26.797447, 55.682522],
                [26.78974, 55.605438],
                [26.751198, 55.53606],
                [26.735781, 55.482101],
                [26.68182, 55.420433],
                [26.643278, 55.358765],
                [26.797447, 55.343349],
                [26.920784, 55.320225],
                [26.959326, 55.297096],
                [26.905367, 55.273972],
                [26.866823, 55.273972],
                [26.820574, 55.204597],
                [26.812864, 55.13522],
                [26.735781, 55.12751],
                [26.68182, 55.166054],
                [26.627861, 55.142929],
                [26.519943, 55.158344],
                [26.450567, 55.142929],
                [26.412024, 55.073551],
                [26.365774, 55.004177],
                [26.250147, 54.973343],
                [26.057434, 54.973343],
                [25.995768, 54.911674],
                [25.941809, 54.857716],
                [25.92639, 54.788338],
                [25.887848, 54.711254],
                [25.934099, 54.634171],
                [25.903265, 54.572502],
                [25.795347, 54.503124],
                [25.795347, 54.441456],
                [25.764513, 54.402916],
                [25.733679, 54.341248],
                [25.810764, 54.325829],
                [25.903265, 54.325829],
                [25.964933, 54.23333],
                [25.92639, 54.148537],
                [25.803055, 54.140827],
                [25.702845, 54.171661],
                [25.702845, 54.241035],
                [25.687428, 54.279579],
                [25.648886, 54.310413],
                [25.5718, 54.287289],
                [25.517841, 54.248745],
                [25.386797, 54.264164],
                [25.355965, 54.202496],
                [25.248045, 54.148537],
                [25.178669, 54.148537],
                [25.093876, 54.163952],
                [24.970541, 54.117702],
                [25.009083, 54.040615],
                [24.978249, 53.986656],
                [24.901163, 53.971241],
                [24.839497, 53.986656],
                [24.746994, 53.963531],
                [24.577408, 53.894157],
                [24.446363, 53.894157],
                [24.415531, 53.940407],
                [24.36157, 53.963531],
                [24.253653, 53.940407],
                [24.084066, 53.932697],
                [23.976147, 53.917282],
                [23.845104, 53.932697],
                [23.775726, 53.917282],
                [23.734165, 53.933266],
                [23.69347, 54.010098],
                [23.686558, 54.023151],
                [23.642204, 54.106888],
                [23.514282, 54.325867],
                [23.208748, 54.43523],
                [23.190722, 54.441681],
                [22.9027, 54.431873]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "United Kingdom",
          "density": "277.12",
          "cartodb_id": 9,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [0.0, 53.540257],
                [0.28468, 53.403198],
                [0.378183, 53.310032],
                [0.471512, 53.143635],
                [0.454531, 53.057026],
                [0.209165, 52.803638],
                [0.530496, 52.724449],
                [0.708334, 52.864483],
                [0.945503, 52.918167],
                [1.216328, 52.905388],
                [1.579591, 52.826214],
                [1.789378, 52.667015],
                [1.895355, 52.328392],
                [1.860536, 52.228756],
                [1.715554, 52.042728],
                [1.23495, 51.829693],
                [0.889882, 51.577358],
                [0.457837, 51.47057],
                [1.007917, 51.395241],
                [1.233731, 51.37582],
                [1.39303, 51.422443],
                [1.568344, 51.369877],
                [1.538628, 51.137669],
                [1.08968, 50.998863],
                [1.092011, 50.909645],
                [0.876233, 50.947639],
                [0.395474, 50.748257],
                [0.0, 50.812302],
                [-0.132042, 50.833687],
                [-0.635376, 50.738773],
                [-0.899696, 50.833687],
                [-1.168848, 50.783245],
                [-1.881695, 50.719795],
                [-1.820022, 50.586983],
                [-2.228331, 50.662861],
                [-2.346841, 50.586983],
                [-2.756955, 50.738773],
                [-2.907221, 50.674335],
                [-3.317803, 50.634403],
                [-3.359519, 50.378407],
                [-3.511372, 50.236309],
                [-3.67808, 50.217369],
                [-3.800574, 50.331036],
                [-4.038965, 50.408432],
                [-4.099631, 50.340515],
                [-4.336902, 50.374924],
                [-4.76337, 50.229218],
                [-5.078675, 49.980797],
                [-5.225554, 50.084824],
                [-5.57415, 50.151089],
                [-5.034102, 50.363266],
                [-4.812984, 50.577492],
                [-4.611607, 50.62114],
                [-4.400901, 50.795719],
                [-4.347873, 51.01413],
                [-4.238894, 50.985634],
                [-4.073621, 51.080643],
                [-4.07733, 51.204208],
                [-3.621568, 51.251751],
                [-3.279332, 51.198082],
                [-2.86238, 51.19471],
                [-2.829485, 51.346867],
                [-2.691, 51.41082],
                [-2.546491, 51.660999],
                [-2.969088, 51.483879],
                [-3.295454, 51.476627],
                [-3.955667, 51.693787],
                [-4.795294, 51.718674],
                [-4.974939, 51.804443],
                [-5.045094, 51.877186],
                [-4.8578, 52.023407],
                [-4.415419, 52.110489],
                [-4.090994, 52.217293],
                [-3.919945, 52.390236],
                [-3.890211, 52.762482],
                [-3.943882, 52.848869],
                [-4.264655, 52.828274],
                [-4.417686, 52.847954],
                [-4.288219, 53.00795],
                [-4.113878, 53.108181],
                [-3.753061, 53.222122],
                [-3.125872, 53.249989],
                [-2.710812, 53.284115],
                [-2.853077, 53.530586],
                [-2.775877, 53.837826],
                [-2.661657, 54.045246],
                [-2.682274, 54.185638],
                [-3.057811, 54.158154],
                [-3.275601, 54.291573],
                [-3.36557, 54.431999],
                [-3.226742, 54.660053],
                [-2.948861, 54.85503],
                [-3.105038, 54.901707],
                [-3.373012, 54.941456],
                [-3.704014, 54.806641],
                [-3.917943, 54.779408],
                [-4.257038, 54.879375],
                [-4.29661, 54.79208],
                [-4.396248, 54.704727],
                [-4.698854, 54.751087],
                [-4.907755, 54.811047],
                [-4.86299, 54.965439],
                [-4.706329, 55.127316],
                [-4.434693, 55.444885],
                [-4.566852, 55.505394],
                [-4.704101, 55.680843],
                [-4.691439, 55.789078],
                [-4.554761, 55.836685],
                [-4.419631, 55.924934],
                [-4.507053, 56.148281],
                [-4.644703, 56.114132],
                [-4.930965, 55.9104],
                [-5.129889, 55.896492],
                [-5.366504, 55.76749],
                [-5.429394, 55.956833],
                [-5.293355, 56.194244],
                [-5.096451, 56.445633],
                [-5.06704, 56.561119],
                [-5.254591, 56.649113],
                [-5.342916, 56.669323],
                [-5.44084, 56.533257],
                [-5.618013, 56.417469],
                [-5.839882, 56.491737],
                [-5.705296, 56.750229],
                [-5.462271, 56.954815],
                [-5.421626, 57.016186],
                [-5.522675, 57.274979],
                [-5.357937, 57.316231],
                [-5.256976, 57.405117],
                [-5.559302, 57.636749],
                [-5.3648, 57.794361],
                [-5.026308, 57.726616],
                [-4.851922, 57.76799],
                [-5.14227, 58.109715],
                [-4.703465, 58.185936],
                [-4.909051, 58.411892],
                [-4.721896, 58.583927],
                [-4.306188, 58.419899],
                [-3.935221, 58.461788],
                [-3.626884, 58.524162],
                [-3.236881, 58.545521],
                [-2.961345, 58.539173],
                [-2.824726, 58.422718],
                [-2.962711, 58.29208],
                [-3.739539, 57.975391],
                [-3.946792, 57.851788],
                [-4.044841, 57.653301],
                [-3.944762, 57.571499],
                [-3.592155, 57.558502],
                [-3.197155, 57.593418],
                [-2.888973, 57.593998],
                [-2.650317, 57.560291],
                [-1.946699, 57.588951],
                [-1.740963, 57.418633],
                [-1.904145, 57.227303],
                [-1.999498, 57.081783],
                [-1.955284, 57.031292],
                [-2.209477, 56.734226],
                [-2.311088, 56.684311],
                [-2.463472, 56.487972],
                [-2.776865, 56.370556],
                [-2.948743, 56.35379],
                [-2.745764, 56.269978],
                [-2.517192, 56.195164],
                [-2.569196, 56.188286],
                [-2.691676, 56.102474],
                [-2.966147, 56.079079],
                [-3.089843, 55.990887],
                [-3.691301, 56.002041],
                [-3.509376, 55.901669],
                [-3.373515, 55.901669],
                [-3.152188, 55.859661],
                [-2.957798, 55.862656],
                [-2.724238, 55.963005],
                [-2.538468, 55.974159],
                [-2.336682, 55.901669],
                [-2.041347, 55.827938],
                [-1.738332, 55.573044],
                [-1.515347, 55.470715],
                [-1.462316, 55.396561],
                [-1.492325, 55.289474],
                [-1.351955, 54.938583],
                [-1.185228, 54.697453],
                [-0.988393, 54.612328],
                [-0.666932, 54.56414],
                [-0.40114, 54.317135],
                [-0.091837, 54.05014],
                [-0.031217, 53.816357],
                [-0.115552, 53.595886],
                [0.0, 53.540257]
              ]
            ],
            [
              [
                [-5.494506, 55.347698],
                [-5.552016, 55.49424],
                [-5.484993, 55.645092],
                [-5.314173, 55.684994],
                [-5.278733, 55.459671],
                [-5.416678, 55.346493],
                [-5.494506, 55.347698]
              ]
            ],
            [
              [
                [-6.002729, 53.973721],
                [-6.082709, 54.096016],
                [-6.517889, 54.063469],
                [-6.462516, 54.178909],
                [-6.626208, 54.246284],
                [-6.743479, 54.36182],
                [-6.894162, 54.409977],
                [-7.008009, 54.246284],
                [-7.345887, 54.130806],
                [-7.695886, 54.217411],
                [-7.876394, 54.342556],
                [-7.921582, 54.46777],
                [-7.53459, 54.593048],
                [-7.752056, 54.708748],
                [-7.384132, 54.756966],
                [-7.210852, 54.974873],
                [-7.223852, 55.080776],
                [-7.021829, 55.121162],
                [-6.79316, 55.188995],
                [-6.441534, 55.2234],
                [-6.250647, 55.203522],
                [-6.075601, 55.156666],
                [-5.912858, 55.177197],
                [-5.814524, 55.109974],
                [-5.581223, 54.754391],
                [-5.56002, 54.673965],
                [-5.596262, 54.53986],
                [-5.457849, 54.339218],
                [-5.527725, 54.191841],
                [-5.70849, 54.04435],
                [-5.868044, 53.990562],
                [-6.002729, 53.973721]
              ]
            ],
            [
              [
                [33.984161, 35.225109],
                [33.97633, 35.146255],
                [34.128338, 34.9702],
                [33.995621, 34.974342],
                [33.936241, 34.925594],
                [33.889484, 34.951839],
                [33.742176, 34.961128],
                [33.687599, 34.812763],
                [33.335056, 34.697308],
                [33.162281, 34.676311],
                [33.11821, 34.652699],
                [33.118179, 34.558231],
                [33.020321, 34.555611],
                [32.987801, 34.6422],
                [32.807056, 34.631706],
                [32.560844, 34.697308],
                [32.474754, 34.736668],
                [32.474644, 34.831127],
                [32.402164, 34.878361],
                [32.347385, 35.059517],
                [32.38414, 35.075287],
                [32.438122, 35.030605],
                [32.50465, 35.033234],
                [32.686779, 35.169914],
                [32.800648, 35.172546],
                [32.948616, 35.131245],
                [32.983189, 35.169914],
                [33.022736, 35.298717],
                [32.998775, 35.377583],
                [33.281143, 35.335583],
                [33.577374, 35.323452],
                [33.742706, 35.348667],
                [34.110981, 35.453819],
                [34.421001, 35.590538],
                [34.661877, 35.661533],
                [34.448318, 35.528816],
                [34.25209, 35.424904],
                [33.984161, 35.225109]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Ireland",
          "density": "73",
          "cartodb_id": 10,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-6.002729, 53.973721],
                [-6.139483, 53.956615],
                [-6.239732, 53.916351],
                [-6.131317, 53.802956],
                [-5.96855, 53.456169],
                [-5.839397, 52.910061],
                [-5.867019, 52.783577],
                [-6.028449, 52.630306],
                [-6.137232, 52.444035],
                [-6.202307, 52.231411],
                [-6.285486, 52.21133],
                [-6.413157, 52.237637],
                [-6.690233, 52.223621],
                [-6.758016, 52.128174],
                [-7.168744, 52.109085],
                [-7.546985, 52.049679],
                [-7.657986, 51.985065],
                [-7.95515, 51.856617],
                [-8.361567, 51.632423],
                [-8.644094, 51.616795],
                [-9.039646, 51.489601],
                [-9.615446, 51.451527],
                [-9.59158, 51.621605],
                [-9.631757, 51.820236],
                [-10.013244, 51.775322],
                [-10.090367, 51.994598],
                [-9.760111, 52.109085],
                [-10.293665, 52.118626],
                [-10.265574, 52.223621],
                [-9.686976, 52.211403],
                [-9.597942, 52.258057],
                [-9.580339, 52.357677],
                [-9.532055, 52.497253],
                [-9.300324, 52.557522],
                [-8.988971, 52.57143],
                [-8.930625, 52.697868],
                [-9.412026, 52.653606],
                [-9.237901, 52.85025],
                [-9.322093, 52.921528],
                [-9.105686, 53.13224],
                [-8.793335, 53.137417],
                [-8.719919, 53.177547],
                [-8.798007, 53.317348],
                [-8.954495, 53.237671],
                [-9.093696, 53.296776],
                [-9.237001, 53.28318],
                [-9.41304, 53.36285],
                [-9.599256, 53.362324],
                [-9.68115, 53.314369],
                [-9.956161, 53.391094],
                [-9.81741, 53.528828],
                [-9.947762, 53.592613],
                [-9.605936, 53.649387],
                [-9.668726, 53.784691],
                [-9.49282, 53.890392],
                [-9.709187, 53.98653],
                [-9.974874, 53.928844],
                [-9.956012, 54.150043],
                [-9.785124, 54.270687],
                [-9.688311, 54.342556],
                [-9.27063, 54.258282],
                [-9.119242, 54.292042],
                [-8.882181, 54.275162],
                [-8.386421, 54.219822],
                [-8.483068, 54.381084],
                [-8.096916, 54.506321],
                [-8.007815, 54.65089],
                [-8.625694, 54.65089],
                [-8.556378, 54.718399],
                [-8.300076, 54.766617],
                [-8.27259, 54.896824],
                [-8.141717, 55.112255],
                [-7.914654, 55.193604],
                [-7.552561, 55.269699],
                [-7.453618, 55.133801],
                [-7.294167, 55.066711],
                [-7.223852, 55.080776],
                [-7.210852, 54.974873],
                [-7.384132, 54.756966],
                [-7.752056, 54.708748],
                [-7.53459, 54.593048],
                [-7.921582, 54.46777],
                [-7.876394, 54.342556],
                [-7.695886, 54.217411],
                [-7.345887, 54.130806],
                [-7.008009, 54.246284],
                [-6.894162, 54.409977],
                [-6.743479, 54.36182],
                [-6.626208, 54.246284],
                [-6.462516, 54.178909],
                [-6.517889, 54.063469],
                [-6.082709, 54.096016],
                [-6.002729, 53.973721]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Belgium",
          "density": "376",
          "cartodb_id": 11,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [2.725796, 51.108093],
                [3.198926, 51.330452],
                [3.417269, 51.370541],
                [3.675684, 51.331364],
                [3.884961, 51.33839],
                [3.978711, 51.404716],
                [4.193188, 51.360718],
                [4.376709, 51.34185],
                [4.610384, 51.357048],
                [4.599015, 51.427876],
                [4.736783, 51.422424],
                [4.874737, 51.422424],
                [4.995041, 51.504173],
                [5.135809, 51.395187],
                [5.213784, 51.449673],
                [5.316191, 51.422424],
                [5.447318, 51.253567],
                [5.688087, 51.302528],
                [6.02756, 51.133804],
                [5.977904, 50.943398],
                [5.900422, 50.899899],
                [5.900751, 50.747696],
                [6.203239, 50.758572],
                [6.35821, 50.67873],
                [6.485089, 50.514572],
                [6.502018, 50.303841],
                [6.312177, 50.134426],
                [6.211109, 50.166946],
                [6.147439, 50.130783],
                [6.03742, 50.064381],
                [5.941587, 49.91943],
                [5.948726, 49.772232],
                [6.088064, 49.680508],
                [6.027164, 49.524078],
                [5.991509, 49.575626],
                [5.67908, 49.497112],
                [5.621752, 49.59959],
                [5.454942, 49.691299],
                [5.301371, 49.777626],
                [5.063536, 49.799213],
                [5.08073, 49.901772],
                [5.015288, 49.98275],
                [5.069075, 50.150688],
                [4.958583, 50.11816],
                [4.871759, 49.977348],
                [4.671145, 49.961704],
                [4.488292, 49.927952],
                [4.359979, 49.98275],
                [4.392124, 50.071918],
                [4.35148, 50.150688],
                [4.423234, 50.259129],
                [4.254551, 50.33506],
                [3.995129, 50.356758],
                [3.878626, 50.351341],
                [3.807043, 50.487011],
                [3.573981, 50.481579],
                [3.486423, 50.541298],
                [3.39554, 50.747696],
                [3.326508, 50.824104],
                [3.14194, 50.730728],
                [3.078724, 50.698795],
                [2.959146, 50.75613],
                [2.819393, 50.907188],
                [2.725796, 51.108093]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Italy",
          "density": "200",
          "cartodb_id": 12,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [9.023223, 38.993885],
                [8.899763, 39.012783],
                [8.78604, 39.082741],
                [8.620838, 39.229176],
                [8.577886, 39.324818],
                [8.668697, 39.65691],
                [8.712144, 39.963448],
                [8.691639, 40.225769],
                [8.631853, 40.481827],
                [8.405216, 40.67997],
                [8.363151, 40.801647],
                [8.444371, 40.872311],
                [8.609217, 40.879055],
                [8.969736, 40.879772],
                [9.122087, 40.956997],
                [9.509868, 41.20142],
                [9.794516, 40.919884],
                [9.997403, 40.580654],
                [9.991511, 40.458927],
                [9.899387, 40.330662],
                [9.834464, 40.112827],
                [9.901673, 39.710468],
                [9.852002, 39.474186],
                [9.682584, 39.161129],
                [9.499939, 39.250095],
                [9.266206, 39.236862],
                [9.20218, 39.192066],
                [9.135032, 39.070675],
                [9.023223, 38.993885]
              ]
            ],
            [
              [
                [15.146062, 36.755535],
                [14.825686, 36.793114],
                [14.419577, 37.066296],
                [14.072068, 37.173923],
                [13.215231, 37.541866],
                [12.840543, 37.598473],
                [12.7357, 37.655643],
                [12.657811, 37.782982],
                [12.720308, 37.936142],
                [12.86799, 38.08947],
                [12.986504, 38.13435],
                [13.269616, 38.083904],
                [13.526139, 38.237484],
                [13.831627, 38.04678],
                [14.013194, 37.983387],
                [14.338137, 37.990425],
                [14.969529, 38.074585],
                [15.590405, 38.222519],
                [15.657351, 38.184387],
                [15.676557, 38.127029],
                [15.626187, 38.069546],
                [15.384696, 37.699253],
                [15.315845, 37.476006],
                [15.319912, 37.303917],
                [15.505011, 37.074871],
                [15.424295, 37.017349],
                [15.231008, 36.762089],
                [15.146062, 36.755535]
              ]
            ],
            [
              [
                [14.003648, 45.583344],
                [13.876844, 45.73148],
                [13.768278, 45.743496],
                [13.641394, 45.655403],
                [13.57136, 45.71146],
                [13.359829, 45.743496],
                [13.308272, 45.599358],
                [13.102314, 45.575344],
                [12.651819, 45.411259],
                [12.686876, 45.479286],
                [12.483407, 45.419262],
                [12.455081, 45.307255],
                [12.384035, 45.287251],
                [12.379853, 45.231262],
                [12.516177, 45.195267],
                [12.525801, 45.059338],
                [12.612652, 45.005981],
                [12.726754, 44.899723],
                [12.679708, 44.804058],
                [12.477248, 44.68961],
                [12.484395, 44.567097],
                [12.54874, 44.318012],
                [12.900894, 43.951729],
                [13.804535, 43.551777],
                [13.935221, 43.391132],
                [13.995298, 43.140316],
                [14.118754, 42.889736],
                [14.296433, 42.626499],
                [14.608597, 42.370083],
                [14.722973, 42.246674],
                [14.923619, 42.158733],
                [14.942191, 42.064129],
                [15.126069, 41.988743],
                [15.652269, 41.873699],
                [16.216507, 41.921295],
                [16.391075, 41.857834],
                [16.402079, 41.762642],
                [16.19281, 41.651619],
                [16.076918, 41.519115],
                [16.194708, 41.423115],
                [16.623932, 41.295696],
                [17.149628, 41.110786],
                [17.522936, 40.946224],
                [18.237583, 40.613625],
                [18.263605, 40.530506],
                [18.587276, 40.324722],
                [18.726313, 40.111103],
                [18.637342, 39.915379],
                [18.568583, 39.763695],
                [18.363794, 39.890106],
                [18.220732, 39.964867],
                [18.17141, 40.183865],
                [18.053268, 40.260468],
                [17.822813, 40.30481],
                [17.605314, 40.308899],
                [17.407835, 40.414913],
                [17.446466, 40.512733],
                [17.287745, 40.542084],
                [17.152376, 40.498062],
                [17.047794, 40.380108],
                [16.931229, 40.200577],
                [16.808077, 40.10688],
                [16.827517, 39.984726],
                [16.73867, 39.848537],
                [16.735765, 39.714485],
                [17.07765, 39.560623],
                [17.352734, 39.438774],
                [17.311083, 39.297443],
                [17.324661, 39.102993],
                [17.384377, 39.039181],
                [17.298353, 38.931995],
                [17.001364, 38.942829],
                [16.816994, 38.844299],
                [16.725866, 38.741997],
                [16.775665, 38.474091],
                [16.534357, 38.337715],
                [16.256607, 37.948147],
                [15.988901, 37.926678],
                [15.913891, 37.958408],
                [15.83114, 38.021183],
                [15.830241, 38.250053],
                [15.994533, 38.31337],
                [16.137131, 38.556889],
                [16.030964, 38.639706],
                [16.19351, 38.751736],
                [16.355873, 38.718239],
                [16.41589, 38.794926],
                [16.380873, 38.947964],
                [16.286226, 39.029446],
                [16.193216, 39.445324],
                [16.028336, 39.677601],
                [15.960686, 39.9506],
                [15.869864, 40.108833],
                [15.719588, 40.08292],
                [15.635697, 40.005943],
                [15.230927, 40.229168],
                [15.102335, 40.25843],
                [15.195984, 40.389191],
                [15.154669, 40.568451],
                [14.946981, 40.721786],
                [14.537791, 40.610577],
                [14.553433, 40.659496],
                [14.698255, 40.746933],
                [14.573975, 40.868443],
                [14.44366, 40.869915],
                [14.254538, 40.784485],
                [14.145542, 41.015007],
                [14.056165, 41.107929],
                [14.00067, 41.210419],
                [13.702054, 41.318836],
                [13.47511, 41.331211],
                [13.180441, 41.388348],
                [12.845181, 41.548069],
                [12.598513, 41.746521],
                [12.167097, 42.021713],
                [11.701029, 42.393326],
                [11.308427, 42.566055],
                [10.94642, 42.867481],
                [10.691409, 43.252911],
                [10.496143, 43.722408],
                [10.340411, 43.921741],
                [10.112965, 44.127449],
                [9.895599, 44.236572],
                [9.360708, 44.390209],
                [9.100249, 44.454159],
                [8.875388, 44.434376],
                [8.390024, 44.143372],
                [8.18319, 43.981888],
                [7.892784, 43.904018],
                [7.756474, 43.884422],
                [7.822438, 44.095284],
                [7.794744, 44.221668],
                [7.412861, 44.190067],
                [7.073442, 44.366142],
                [6.986581, 44.564846],
                [7.128358, 44.731991],
                [7.207134, 44.736507],
                [7.146267, 44.885624],
                [6.868982, 44.971493],
                [6.710983, 45.132679],
                [6.907252, 45.216167],
                [6.989592, 45.166294],
                [7.232861, 45.324989],
                [7.322778, 45.447456],
                [7.129212, 45.533653],
                [7.117994, 45.665268],
                [6.878174, 45.820244],
                [7.158947, 45.955875],
                [7.428459, 45.927906],
                [7.710453, 46.019485],
                [7.993593, 45.943939],
                [8.117544, 46.015949],
                [8.274562, 46.210346],
                [8.228458, 46.305817],
                [8.578172, 46.487732],
                [8.585223, 46.30127],
                [8.825603, 46.13763],
                [8.965364, 46.105816],
                [8.921244, 46.024017],
                [9.094389, 45.883209],
                [9.222591, 45.951332],
                [9.230639, 46.192165],
                [9.421941, 46.364929],
                [9.413733, 46.515026],
                [9.580463, 46.551422],
                [9.644661, 46.392208],
                [9.74853, 46.328556],
                [9.845713, 46.342194],
                [9.877404, 46.396763],
                [10.088621, 46.419502],
                [10.198193, 46.269447],
                [10.300774, 46.455887],
                [10.191092, 46.515026],
                [10.227317, 46.637871],
                [10.354904, 46.678837],
                [10.434008, 46.606228],
                [10.575771, 46.583275],
                [10.592857, 46.669731],
                [10.542387, 46.76532],
                [10.599363, 46.904385],
                [10.883028, 46.827271],
                [11.151505, 46.811852],
                [11.299464, 46.996922],
                [11.464509, 47.040127],
                [11.546635, 47.003101],
                [11.74692, 47.052464],
                [11.882265, 47.006184],
                [12.267993, 47.108017],
                [12.278418, 46.944477],
                [12.421473, 46.901291],
                [12.565518, 46.725513],
                [13.155559, 46.614429],
                [13.557677, 46.577633],
                [13.772563, 46.527397],
                [13.824765, 46.437855],
                [13.548136, 46.301479],
                [13.592371, 46.233303],
                [13.72566, 46.23254],
                [13.810001, 46.149109],
                [13.6444, 46.020859],
                [13.795671, 45.980778],
                [13.738722, 45.818241],
                [13.900801, 45.768734],
                [14.028622, 45.679661],
                [14.003648, 45.583344]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Spain",
          "density": "95",
          "cartodb_id": 13,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-8.602391, 41.906151],
                [-8.656822, 42.031506],
                [-8.594907, 42.211502],
                [-8.440399, 42.436722],
                [-8.601381, 42.648529],
                [-8.73806, 42.841167],
                [-8.96946, 42.872875],
                [-9.043196, 42.917747],
                [-9.06714, 43.065662],
                [-8.993803, 43.175186],
                [-8.809926, 43.297829],
                [-8.645553, 43.343216],
                [-8.392058, 43.35659],
                [-8.10675, 43.370022],
                [-8.047033, 43.447399],
                [-8.016561, 43.634182],
                [-7.775865, 43.757027],
                [-7.432611, 43.738384],
                [-6.947639, 43.571915],
                [-6.604312, 43.540394],
                [-6.26391, 43.579697],
                [-6.004383, 43.657494],
                [-5.775992, 43.722351],
                [-5.616787, 43.72266],
                [-5.333754, 43.594425],
                [-5.021233, 43.52422],
                [-4.598672, 43.486416],
                [-3.906552, 43.468483],
                [-3.643942, 43.456135],
                [-3.213337, 43.392624],
                [-2.992301, 43.463856],
                [-2.803542, 43.567245],
                [-2.564699, 43.554848],
                [-2.163721, 43.343216],
                [-1.861981, 43.350243],
                [-1.710914, 43.322388],
                [-1.498728, 43.21291],
                [-1.287719, 43.226593],
                [-1.261988, 43.17186],
                [-1.354007, 43.062405],
                [-1.311464, 43.014534],
                [-1.243889, 43.000851],
                [-1.193609, 43.048729],
                [-0.70369, 42.89827],
                [-0.636355, 42.925625],
                [-0.408318, 42.747849],
                [-0.383126, 42.782032],
                [-0.265173, 42.768364],
                [-0.206251, 42.80254],
                [-0.012477, 42.734173],
                [0.0, 42.717285],
                [0.038037, 42.66581],
                [0.383214, 42.679489],
                [0.399929, 42.624798],
                [0.551507, 42.652145],
                [0.795587, 42.645306],
                [0.787806, 42.788864],
                [0.947863, 42.788864],
                [1.511114, 42.652145],
                [1.552369, 42.556454],
                [1.56806, 42.426613],
                [1.651816, 42.392452],
                [1.820269, 42.426613],
                [1.912892, 42.440285],
                [2.063759, 42.399288],
                [2.079782, 42.330959],
                [2.197335, 42.324127],
                [2.374771, 42.399288],
                [2.566838, 42.310467],
                [2.785174, 42.303635],
                [2.802931, 42.365116],
                [3.140168, 42.426613],
                [3.290745, 42.385616],
                [3.290192, 42.346085],
                [3.28995, 42.137695],
                [3.360218, 42.092693],
                [3.332543, 41.874199],
                [2.831923, 41.636513],
                [2.404254, 41.46312],
                [2.284389, 41.31546],
                [1.813492, 41.226128],
                [1.514909, 41.174225],
                [1.082866, 41.038708],
                [0.937416, 40.891926],
                [0.913446, 40.80212],
                [1.030789, 40.744396],
                [0.99122, 40.673859],
                [0.708805, 40.60973],
                [0.441591, 40.257172],
                [0.043039, 39.902996],
                [0.0, 39.81641],
                [-0.080852, 39.653744],
                [-0.191185, 39.451431],
                [-0.032239, 39.079376],
                [0.0, 39.029625],
                [0.037969, 38.971035],
                [0.32978, 38.838326],
                [0.376234, 38.755318],
                [0.09666, 38.608471],
                [0.0, 38.561001],
                [-0.231492, 38.447311],
                [-0.360463, 38.231838],
                [-0.461065, 38.193546],
                [-0.66868, 37.776772],
                [-0.5989, 37.631977],
                [-0.714608, 37.587315],
                [-1.160904, 37.565365],
                [-1.422049, 37.456463],
                [-1.613216, 37.309448],
                [-1.736811, 36.968571],
                [-2.035134, 36.745369],
                [-2.151434, 36.841022],
                [-2.420668, 36.82827],
                [-2.557833, 36.707111],
                [-2.711668, 36.707111],
                [-2.766129, 36.758121],
                [-4.242922, 36.738991],
                [-4.55364, 36.503078],
                [-5.013072, 36.426567],
                [-5.170564, 36.254448],
                [-5.183086, 36.126957],
                [-5.220596, 36.088715],
                [-5.253427, 36.18433],
                [-5.284269, 36.190697],
                [-5.281823, 36.08234],
                [-5.456923, 36.024979],
                [-5.64635, 36.088715],
                [-6.000797, 36.299068],
                [-6.101308, 36.471195],
                [-6.085217, 36.589149],
                [-6.240361, 36.640156],
                [-6.296822, 36.735802],
                [-6.21805, 36.809135],
                [-6.42849, 37.038723],
                [-6.744291, 37.166286],
                [-6.806435, 37.182232],
                [-7.02416, 37.242828],
                [-7.28784, 37.225128],
                [-7.284335, 37.233326],
                [-7.14778, 37.661407],
                [-7.038851, 37.827869],
                [-6.641111, 38.117184],
                [-6.824435, 38.256554],
                [-7.190213, 38.311958],
                [-7.113677, 38.516598],
                [-6.885761, 38.811474],
                [-6.72972, 38.978321],
                [-6.803211, 39.067287],
                [-7.007772, 39.315178],
                [-7.227335, 39.537487],
                [-7.097593, 39.595684],
                [-6.88054, 39.616047],
                [-6.674659, 39.719368],
                [-6.655012, 39.808895],
                [-6.680509, 40.231007],
                [-6.621201, 40.673538],
                [-6.653507, 40.878551],
                [-6.630785, 41.077507],
                [-6.592964, 41.109798],
                [-6.037485, 41.337452],
                [-5.907874, 41.434433],
                [-5.935834, 41.543411],
                [-6.314763, 41.766071],
                [-6.382416, 41.874893],
                [-6.505041, 41.977016],
                [-6.655855, 42.001884],
                [-6.894772, 41.884926],
                [-7.714897, 41.906139],
                [-7.953598, 42.104012],
                [-8.201384, 42.019108],
                [-8.602391, 41.906151]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Albania",
          "density": "98.52",
          "cartodb_id": 14,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [20.326046, 39.567982],
                [20.294493, 39.619503],
                [20.232224, 39.830753],
                [20.007313, 40.033936],
                [19.572731, 40.277252],
                [19.583889, 40.340553],
                [19.687376, 40.312862],
                [19.698147, 40.419689],
                [19.542887, 40.605709],
                [19.730919, 40.882862],
                [19.649067, 40.981869],
                [19.733614, 41.235416],
                [19.620655, 41.362225],
                [19.702579, 41.540592],
                [19.823048, 41.576275],
                [19.810629, 41.778511],
                [19.661551, 41.815056],
                [19.571377, 41.863342],
                [19.572325, 42.017315],
                [19.512478, 42.209621],
                [19.638283, 42.441544],
                [19.761362, 42.564301],
                [20.088224, 42.514683],
                [20.250717, 42.504494],
                [20.276661, 42.502865],
                [20.448849, 42.336773],
                [20.778645, 41.946884],
                [20.786631, 41.897686],
                [20.802586, 41.799393],
                [20.743227, 41.606564],
                [20.776485, 41.318115],
                [20.910639, 41.017536],
                [21.235302, 40.743763],
                [21.194838, 40.730724],
                [20.992609, 40.351746],
                [20.811579, 40.177887],
                [20.588345, 39.965538],
                [20.326046, 39.567982]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Portugal",
          "density": "112",
          "cartodb_id": 15,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-7.28784, 37.225128],
                [-7.399773, 37.179951],
                [-7.632379, 37.049225],
                [-7.829974, 37.025761],
                [-8.076575, 37.105553],
                [-8.33741, 37.105553],
                [-8.463396, 37.138412],
                [-8.787186, 37.006981],
                [-8.81298, 37.086773],
                [-8.637848, 37.542118],
                [-8.647365, 37.927155],
                [-8.729122, 37.978813],
                [-8.619329, 38.223045],
                [-8.64364, 38.401539],
                [-8.585257, 38.452953],
                [-8.649988, 38.516613],
                [-8.818659, 38.477993],
                [-9.032907, 38.434422],
                [-9.081494, 38.63644],
                [-8.865489, 38.659931],
                [-8.738229, 38.758888],
                [-8.757163, 38.835434],
                [-8.837186, 38.937153],
                [-8.972091, 38.749203],
                [-9.157973, 38.688118],
                [-9.318733, 38.706909],
                [-9.345344, 38.791489],
                [-9.256099, 38.970051],
                [-9.194043, 39.247334],
                [-9.211606, 39.383648],
                [-9.095606, 39.434708],
                [-8.914259, 39.661007],
                [-8.711922, 40.155121],
                [-8.766006, 40.206989],
                [-8.490406, 41.009144],
                [-8.648114, 41.424801],
                [-8.651091, 41.613823],
                [-8.717072, 41.783993],
                [-8.602391, 41.906151],
                [-8.201384, 42.019108],
                [-7.953598, 42.104012],
                [-7.714897, 41.906139],
                [-6.894772, 41.884926],
                [-6.655855, 42.001884],
                [-6.505041, 41.977016],
                [-6.382416, 41.874893],
                [-6.314763, 41.766071],
                [-5.935834, 41.543411],
                [-5.907874, 41.434433],
                [-6.037485, 41.337452],
                [-6.592964, 41.109798],
                [-6.630785, 41.077507],
                [-6.653507, 40.878551],
                [-6.621201, 40.673538],
                [-6.680509, 40.231007],
                [-6.655012, 39.808895],
                [-6.674659, 39.719368],
                [-6.88054, 39.616047],
                [-7.097593, 39.595684],
                [-7.227335, 39.537487],
                [-7.007772, 39.315178],
                [-6.803211, 39.067287],
                [-6.72972, 38.978321],
                [-6.885761, 38.811474],
                [-7.113677, 38.516598],
                [-7.190213, 38.311958],
                [-6.824435, 38.256554],
                [-6.641111, 38.117184],
                [-7.038851, 37.827869],
                [-7.14778, 37.661407],
                [-7.284335, 37.233326],
                [-7.28784, 37.225128]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Greece",
          "density": "80",
          "cartodb_id": 16,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [24.897131, 34.957443],
                [24.919302, 35.051743],
                [24.89373, 35.109413],
                [24.528021, 35.224762],
                [24.216862, 35.227386],
                [23.989258, 35.279823],
                [23.771387, 35.271954],
                [23.716799, 35.292931],
                [23.670549, 35.334877],
                [23.733103, 35.586597],
                [23.87513, 35.589222],
                [23.872751, 35.628555],
                [23.868797, 35.683628],
                [23.90032, 35.715096],
                [23.937342, 35.644291],
                [23.946539, 35.578732],
                [24.083117, 35.569389],
                [24.160477, 35.544643],
                [24.242668, 35.578732],
                [24.236927, 35.615444],
                [24.285521, 35.628555],
                [24.35634, 35.581352],
                [24.339481, 35.539398],
                [24.29512, 35.536774],
                [24.276987, 35.513176],
                [24.316868, 35.502689],
                [24.381409, 35.486958],
                [24.423367, 35.465973],
                [24.433268, 35.408291],
                [24.591948, 35.389938],
                [24.972231, 35.445],
                [25.201145, 35.437992],
                [25.217264, 35.379452],
                [25.303755, 35.36372],
                [25.577682, 35.334877],
                [25.7153, 35.311737],
                [25.876871, 35.366341],
                [25.917198, 35.343983],
                [25.872131, 35.203793],
                [25.945467, 35.153976],
                [25.992638, 35.153976],
                [26.139156, 35.230007],
                [26.267817, 35.256222],
                [26.303017, 35.23262],
                [26.37249, 35.26408],
                [26.477173, 35.329632],
                [26.439709, 35.245735],
                [26.446331, 35.161846],
                [26.364149, 35.041256],
                [26.102345, 35.062229],
                [25.9454, 35.025826],
                [25.794388, 35.03339],
                [24.897131, 34.957443]
              ]
            ],
            [
              [
                [20.326046, 39.567982],
                [20.588345, 39.965538],
                [20.811579, 40.177887],
                [20.992609, 40.351746],
                [21.194838, 40.730724],
                [21.235302, 40.743763],
                [21.598925, 40.777805],
                [21.872051, 40.84338],
                [22.049364, 41.036613],
                [22.213909, 41.108025],
                [22.376604, 41.089687],
                [22.656248, 41.065594],
                [22.89603, 41.175892],
                [23.123907, 41.247662],
                [23.164642, 41.260704],
                [23.999279, 41.34866],
                [24.359076, 41.466064],
                [24.621727, 41.493168],
                [25.284573, 41.265953],
                [25.562984, 41.171329],
                [25.737343, 41.185104],
                [26.240086, 41.309669],
                [26.27886, 41.425301],
                [26.235458, 41.572586],
                [26.389954, 41.669655],
                [26.553539, 41.657726],
                [26.600769, 41.657982],
                [26.793226, 41.569233],
                [26.850121, 41.46693],
                [26.545223, 41.183136],
                [26.371265, 40.932236],
                [26.224388, 40.686295],
                [26.196539, 40.869431],
                [25.839369, 40.894348],
                [25.504778, 40.912914],
                [25.249981, 40.969246],
                [25.222593, 41.034119],
                [25.015779, 40.950394],
                [24.904327, 40.895313],
                [24.722792, 40.890038],
                [24.708616, 41.001041],
                [24.557966, 40.987934],
                [24.422722, 40.846657],
                [24.228819, 40.763332],
                [24.089617, 40.749866],
                [23.952059, 40.821404],
                [23.838362, 40.789726],
                [23.836641, 40.730141],
                [23.91272, 40.647007],
                [23.988838, 40.494164],
                [24.127367, 40.467781],
                [24.499016, 40.193462],
                [24.424299, 40.161819],
                [24.258442, 40.277855],
                [24.251442, 40.325329],
                [24.127197, 40.362255],
                [23.978437, 40.409737],
                [23.925833, 40.365215],
                [23.830898, 40.362255],
                [23.862465, 40.304234],
                [23.85541, 40.246201],
                [24.007328, 40.172363],
                [24.072323, 40.177647],
                [24.123774, 40.07217],
                [24.110281, 40.014168],
                [23.999697, 40.003624],
                [23.799437, 40.20929],
                [23.646151, 40.272579],
                [23.552742, 40.29895],
                [23.495424, 40.304234],
                [23.496803, 40.262028],
                [23.462723, 40.246201],
                [23.524698, 40.172363],
                [23.570503, 40.124901],
                [23.640131, 40.061626],
                [23.727013, 40.035259],
                [23.795849, 40.019436],
                [23.842249, 39.977314],
                [23.800554, 39.951027],
                [23.578056, 39.972061],
                [23.46419, 39.987843],
                [23.479973, 40.066895],
                [23.418442, 40.198738],
                [23.344805, 40.283127],
                [23.163824, 40.33168],
                [22.951719, 40.499439],
                [23.067566, 40.5522],
                [23.079126, 40.613297],
                [23.055611, 40.668308],
                [22.973631, 40.684143],
                [22.904919, 40.594421],
                [22.69573, 40.515263],
                [22.805929, 40.414211],
                [22.693281, 40.247498],
                [22.671026, 40.135452],
                [22.729277, 40.081139],
                [22.758793, 40.003624],
                [22.898762, 39.851456],
                [22.958138, 39.83532],
                [23.017096, 39.619713],
                [23.277832, 39.430424],
                [23.439749, 39.220131],
                [23.362164, 39.157051],
                [23.255581, 39.120258],
                [23.303471, 39.204357],
                [23.248917, 39.288471],
                [23.184965, 39.346302],
                [23.12265, 39.362076],
                [23.054518, 39.383102],
                [22.923922, 39.304241],
                [22.969723, 39.219761],
                [23.038548, 39.183334],
                [23.074726, 39.041321],
                [22.987877, 38.957306],
                [22.694162, 38.893826],
                [22.805977, 38.873211],
                [22.926952, 38.810143],
                [23.103037, 38.783863],
                [23.187895, 38.684013],
                [23.303465, 38.68927],
                [23.373156, 38.684013],
                [23.394659, 38.627316],
                [23.55982, 38.506474],
                [23.697508, 38.500103],
                [23.75943, 38.368736],
                [23.886415, 38.363483],
                [24.007612, 38.363483],
                [24.164408, 38.226887],
                [24.170654, 38.169106],
                [24.083689, 38.132328],
                [24.190964, 37.780399],
                [24.121845, 37.701614],
                [24.039177, 37.701614],
                [23.937347, 37.874828],
                [23.837812, 37.89595],
                [23.744379, 38.021072],
                [23.699366, 38.085049],
                [23.622921, 38.085049],
                [23.399506, 38.022011],
                [23.184278, 37.974739],
                [23.101721, 37.916962],
                [23.243631, 37.874939],
                [23.249056, 37.79055],
                [23.252539, 37.659603],
                [23.355047, 37.607094],
                [23.424212, 37.618797],
                [23.525372, 37.654354],
                [23.515823, 37.565079],
                [23.621788, 37.486313],
                [23.388643, 37.444305],
                [23.389456, 37.3918],
                [23.313229, 37.363613],
                [23.174641, 37.402294],
                [23.22901, 37.496815],
                [23.145733, 37.535362],
                [22.916954, 37.560406],
                [22.845982, 37.601837],
                [22.842409, 37.509258],
                [22.929903, 37.305481],
                [23.017014, 37.229027],
                [22.996773, 37.155529],
                [23.090551, 37.063652],
                [23.186216, 36.631725],
                [22.877218, 36.771255],
                [22.728111, 36.739098],
                [22.59396, 36.541344],
                [22.395071, 36.872208],
                [22.187836, 37.037453],
                [21.984579, 36.839516],
                [21.791832, 36.998413],
                [21.735107, 37.132118],
                [21.830164, 37.32349],
                [21.817371, 37.43182],
                [21.720978, 37.552731],
                [21.500011, 37.743515],
                [21.424795, 37.845367],
                [21.432095, 37.921879],
                [21.644115, 38.196487],
                [21.918163, 38.152412],
                [22.10943, 38.286709],
                [22.200352, 38.267754],
                [22.950878, 37.969578],
                [23.10232, 38.014526],
                [23.320009, 38.174366],
                [23.200407, 38.196316],
                [23.079302, 38.221573],
                [22.537405, 38.44368],
                [22.030136, 38.48093],
                [21.864922, 38.455082],
                [21.568119, 38.428978],
                [21.376068, 38.364811],
                [20.975494, 38.867905],
                [21.096262, 38.912804],
                [21.210173, 38.887516],
                [21.30835, 38.938747],
                [21.36318, 39.021797],
                [21.210848, 39.129959],
                [21.104813, 39.078705],
                [20.881416, 39.097397],
                [20.415472, 39.421898],
                [20.326046, 39.567982]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Denmark",
          "density": "137.72",
          "cartodb_id": 17,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [8.387151, 56.815453],
                [8.562537, 57.039711],
                [8.7377, 57.157555],
                [8.864964, 57.121071],
                [9.163523, 57.149136],
                [9.506084, 57.182816],
                [9.652349, 57.244587],
                [10.052923, 57.598759],
                [10.493185, 57.688831],
                [10.593801, 57.677567],
                [10.683124, 57.776104],
                [10.76172, 57.762024],
                [10.604846, 57.52285],
                [10.654392, 57.475002],
                [10.666501, 57.258633],
                [10.536954, 57.188435],
                [10.460217, 57.022884],
                [9.706668, 56.992035],
                [9.537425, 57.039711],
                [9.344905, 57.025684],
                [9.282211, 57.053734],
                [9.174544, 57.017269],
                [9.050689, 57.003254],
                [8.981746, 56.815453],
                [8.869645, 56.71743],
                [8.710785, 56.664238],
                [8.674592, 56.613861],
                [8.571893, 56.68383],
                [8.387151, 56.815453]
              ]
            ],
            [
              [
                [9.699646, 54.955265],
                [8.872263, 54.897312],
                [8.819761, 54.904335],
                [8.848739, 55.086018],
                [8.763224, 55.373096],
                [8.685002, 55.366211],
                [8.522635, 55.498047],
                [8.417703, 55.415569],
                [8.238231, 55.492886],
                [8.328737, 55.652802],
                [8.523694, 55.784706],
                [8.520638, 55.865887],
                [8.320278, 56.10923],
                [8.328143, 56.224449],
                [8.387094, 56.448437],
                [8.512494, 56.496178],
                [8.6559, 56.428574],
                [8.878552, 56.374718],
                [9.072243, 56.633083],
                [9.206366, 56.674129],
                [9.339517, 56.47739],
                [9.515538, 56.498096],
                [9.525726, 56.735909],
                [9.573811, 56.899239],
                [10.038287, 56.906914],
                [10.419852, 56.866821],
                [10.433348, 56.622097],
                [10.517425, 56.486446],
                [11.029489, 56.460262],
                [11.09234, 56.385719],
                [10.954332, 56.114254],
                [10.699757, 56.050598],
                [10.646144, 56.167873],
                [10.49711, 56.154041],
                [10.37882, 56.092846],
                [10.433542, 55.967865],
                [10.35081, 55.781864],
                [10.210784, 55.74736],
                [10.179411, 55.637321],
                [9.893737, 55.621838],
                [9.964405, 55.518669],
                [9.833847, 55.476379],
                [9.747529, 55.368237],
                [9.720225, 55.24004],
                [9.721485, 55.145653],
                [9.624684, 54.993454],
                [9.699646, 54.955265]
              ]
            ],
            [
              [
                [12.173278, 54.971157],
                [12.110996, 55.038265],
                [11.970886, 55.19297],
                [11.788441, 55.179153],
                [11.489062, 55.20554],
                [11.433763, 55.353779],
                [11.305589, 55.650642],
                [11.499926, 55.691566],
                [11.7714, 55.901749],
                [11.931716, 55.678856],
                [12.166154, 55.598236],
                [12.256286, 55.720051],
                [12.291661, 55.983948],
                [12.441381, 56.004543],
                [12.647712, 55.984623],
                [12.672889, 55.727604],
                [12.612764, 55.565304],
                [12.54803, 55.524677],
                [12.362099, 55.396065],
                [12.633383, 55.261665],
                [12.505019, 55.187252],
                [12.280639, 55.011662],
                [12.173278, 54.971157]
              ]
            ],
            [
              [
                [-24.015186, 65.550713],
                [-23.957872, 65.623596],
                [-23.76898, 65.704079],
                [-23.366858, 65.69754],
                [-23.063307, 65.741837],
                [-23.222475, 65.843643],
                [-23.404631, 65.996605],
                [-23.261906, 66.150406],
                [-23.137432, 66.150635],
                [-22.707256, 65.96138],
                [-22.405634, 65.940056],
                [-22.270475, 66.013351],
                [-22.356392, 66.130157],
                [-22.63743, 66.298012],
                [-22.472706, 66.408279],
                [-22.006155, 66.306526],
                [-21.575409, 66.270706],
                [-21.239586, 66.146889],
                [-21.038826, 65.898788],
                [-21.092505, 65.716385],
                [-21.291052, 65.584938],
                [-20.876057, 65.22242],
                [-20.626955, 65.382568],
                [-20.468819, 65.441017],
                [-20.185263, 65.492424],
                [-19.964783, 65.572868],
                [-19.937613, 65.667542],
                [-20.013721, 66.01757],
                [-19.769642, 66.127701],
                [-19.544001, 65.9235],
                [-19.352346, 65.785233],
                [-19.132332, 65.690903],
                [-19.035519, 65.683807],
                [-18.95223, 65.742256],
                [-19.070866, 65.946274],
                [-18.961773, 66.019547],
                [-18.84046, 66.034386],
                [-18.472635, 66.10817],
                [-18.165369, 65.911476],
                [-17.924263, 65.802498],
                [-17.680105, 65.773796],
                [-17.657377, 65.854057],
                [-17.818819, 66.094765],
                [-17.366011, 66.029839],
                [-16.718477, 65.994522],
                [-16.364323, 66.046318],
                [-16.172022, 66.149063],
                [-16.181559, 66.47139],
                [-15.656222, 66.435692],
                [-15.370321, 66.22374],
                [-14.867088, 66.078362],
                [-14.559673, 66.071632],
                [-14.437016, 65.962265],
                [-14.550407, 65.714043],
                [-14.196449, 65.583664],
                [-13.443236, 65.512314],
                [-13.386125, 65.447014],
                [-13.32191, 65.200317],
                [-13.35371, 65.02636],
                [-13.42218, 64.910866],
                [-13.490523, 64.795601],
                [-13.689761, 64.737679],
                [-14.056658, 64.729797],
                [-14.144459, 64.686508],
                [-14.162232, 64.492531],
                [-14.393874, 64.284103],
                [-14.617537, 64.211998],
                [-14.873145, 64.225876],
                [-15.062872, 64.29718],
                [-15.430476, 64.074455],
                [-15.672832, 64.009628],
                [-16.330175, 63.801105],
                [-16.713875, 63.750385],
                [-17.040718, 63.749798],
                [-17.387798, 63.706314],
                [-17.74044, 63.441814],
                [-17.953259, 63.384434],
                [-18.4382, 63.34079],
                [-18.757128, 63.354435],
                [-19.349428, 63.431652],
                [-19.623083, 63.495274],
                [-20.200283, 63.715317],
                [-20.676218, 63.757263],
                [-20.87546, 63.728313],
                [-21.343842, 63.734592],
                [-22.169247, 63.790157],
                [-22.302925, 63.882793],
                [-22.107674, 63.968956],
                [-21.600447, 64.127335],
                [-21.284811, 64.393112],
                [-21.653049, 64.39959],
                [-21.882515, 64.456566],
                [-22.074942, 64.635773],
                [-22.188759, 64.678673],
                [-22.349926, 64.642426],
                [-23.053867, 64.590813],
                [-23.304644, 64.575996],
                [-23.470913, 64.676285],
                [-23.407503, 64.762672],
                [-23.233007, 64.820564],
                [-22.931984, 64.864311],
                [-22.574759, 64.872169],
                [-22.246628, 64.923203],
                [-21.774628, 64.967308],
                [-21.605501, 65.054329],
                [-21.525286, 65.199425],
                [-21.590096, 65.315422],
                [-21.751133, 65.39502],
                [-21.942825, 65.401894],
                [-22.157928, 65.466919],
                [-22.610058, 65.487877],
                [-22.979576, 65.443558],
                [-23.170565, 65.37056],
                [-23.474789, 65.333672],
                [-23.943224, 65.456291],
                [-24.015186, 65.550713]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Netherlands",
          "density": "522",
          "cartodb_id": 18,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [7.363603, 53.180973],
                [7.431668, 52.983688],
                [7.276559, 52.821236],
                [7.238455, 52.640476],
                [6.981754, 52.656902],
                [6.9305, 52.575306],
                [6.917232, 52.492672],
                [7.115585, 52.432491],
                [7.200515, 52.459846],
                [7.27269, 52.383255],
                [7.271382, 52.235615],
                [7.085898, 52.142704],
                [7.02828, 51.973358],
                [6.884033, 51.904724],
                [6.585109, 51.836872],
                [6.415042, 51.896915],
                [6.336338, 51.855408],
                [6.183032, 51.820499],
                [6.151562, 51.738644],
                [6.320525, 51.65683],
                [6.357486, 51.537643],
                [6.425942, 51.406075],
                [6.383284, 51.339302],
                [6.292901, 51.215458],
                [6.379871, 51.161022],
                [6.273839, 51.101158],
                [6.167907, 51.041309],
                [6.131559, 50.997787],
                [6.21333, 50.981468],
                [6.27759, 50.883587],
                [6.203239, 50.758572],
                [5.900751, 50.747696],
                [5.900422, 50.899899],
                [5.977904, 50.943398],
                [6.02756, 51.133804],
                [5.688087, 51.302528],
                [5.447318, 51.253567],
                [5.316191, 51.422424],
                [5.213784, 51.449673],
                [5.135809, 51.395187],
                [4.995041, 51.504173],
                [4.874737, 51.422424],
                [4.736783, 51.422424],
                [4.599015, 51.427876],
                [4.610384, 51.357048],
                [4.376709, 51.34185],
                [4.193188, 51.360718],
                [3.978711, 51.404716],
                [3.94714, 51.477425],
                [3.723984, 51.596107],
                [4.0818, 51.808701],
                [4.468075, 52.087864],
                [4.590904, 52.247337],
                [4.844676, 52.772671],
                [4.922438, 52.819389],
                [5.212068, 52.873165],
                [5.376949, 52.760391],
                [5.438292, 52.639824],
                [5.311908, 52.548473],
                [5.209752, 52.573856],
                [5.239408, 52.350689],
                [5.153492, 52.294918],
                [5.67685, 52.193577],
                [5.778033, 52.299988],
                [5.960577, 52.350689],
                [6.020808, 52.538334],
                [5.814919, 52.548473],
                [5.759374, 52.604294],
                [5.750556, 52.695667],
                [5.857104, 52.76675],
                [5.780046, 52.776905],
                [5.52941, 52.807381],
                [5.561683, 52.95472],
                [5.546417, 53.013283],
                [5.535784, 53.028419],
                [5.593187, 53.155544],
                [5.851014, 53.244732],
                [6.169971, 53.351982],
                [6.46438, 53.338715],
                [6.954386, 53.406834],
                [7.064709, 53.282734],
                [7.212723, 53.262383],
                [7.246947, 53.180977],
                [7.363603, 53.180973]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Austria",
          "density": "106.81",
          "cartodb_id": 19,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [9.700788, 47.577412],
                [9.866776, 47.571232],
                [9.940373, 47.633038],
                [9.983644, 47.583595],
                [10.095658, 47.580502],
                [10.141933, 47.518703],
                [10.229475, 47.497086],
                [10.224617, 47.422943],
                [10.341638, 47.405628],
                [10.306602, 47.317932],
                [10.403173, 47.317932],
                [10.60679, 47.472363],
                [10.59199, 47.595959],
                [10.699069, 47.577408],
                [10.816867, 47.602139],
                [11.03847, 47.561966],
                [11.080283, 47.497086],
                [11.106603, 47.432209],
                [11.37401, 47.441471],
                [11.543672, 47.487812],
                [11.563496, 47.546513],
                [11.707096, 47.552689],
                [11.763231, 47.629951],
                [12.325696, 47.645401],
                [12.366941, 47.725765],
                [12.561845, 47.741226],
                [12.649758, 47.679394],
                [12.903743, 47.707218],
                [12.969055, 47.654671],
                [12.947391, 47.580502],
                [13.145685, 47.500175],
                [13.182581, 47.524879],
                [13.226663, 47.679394],
                [13.201744, 47.750496],
                [13.051193, 47.756683],
                [13.13997, 47.889633],
                [12.897852, 48.146408],
                [13.061913, 48.248554],
                [13.497288, 48.381687],
                [13.570317, 48.45911],
                [13.587808, 48.610905],
                [13.647358, 48.623299],
                [13.843703, 48.55513],
                [14.150347, 48.672073],
                [14.21547, 48.665886],
                [14.374601, 48.607971],
                [14.592174, 48.56345],
                [14.861148, 48.636696],
                [15.196807, 48.98476],
                [15.81518, 48.831223],
                [16.273294, 48.729168],
                [16.872818, 48.810757],
                [17.117998, 48.720627],
                [17.14245, 48.678017],
                [17.166561, 48.636005],
                [17.122915, 48.374657],
                [17.192629, 48.257607],
                [17.337214, 48.153702],
                [17.534882, 48.01165],
                [17.24885, 47.880856],
                [17.240534, 47.715778],
                [16.925219, 47.691628],
                [16.851107, 47.75201],
                [16.704821, 47.75201],
                [16.604532, 47.663448],
                [16.808184, 47.635277],
                [16.869143, 47.546749],
                [16.798721, 47.45422],
                [16.614473, 47.426052],
                [16.596561, 47.257164],
                [16.68816, 47.172745],
                [16.618881, 47.040112],
                [16.463238, 46.905777],
                [16.151058, 46.835239],
                [16.188242, 46.658566],
                [15.787649, 46.710766],
                [15.653701, 46.622448],
                [15.271523, 46.658566],
                [15.016524, 46.610405],
                [14.725433, 46.3857],
                [14.596609, 46.453899],
                [14.321087, 46.44989],
                [14.240313, 46.498032],
                [13.772563, 46.527397],
                [13.557677, 46.577633],
                [13.155559, 46.614429],
                [12.565518, 46.725513],
                [12.421473, 46.901291],
                [12.278418, 46.944477],
                [12.267993, 47.108017],
                [11.882265, 47.006184],
                [11.74692, 47.052464],
                [11.546635, 47.003101],
                [11.464509, 47.040127],
                [11.299464, 46.996922],
                [11.151505, 46.811852],
                [10.883028, 46.827271],
                [10.599363, 46.904385],
                [10.612238, 46.981499],
                [10.515508, 47.033947],
                [10.255184, 46.884708],
                [10.007842, 46.993839],
                [10.004237, 47.058636],
                [9.724424, 47.086411],
                [9.764495, 47.172832],
                [9.668275, 47.302494],
                [9.788012, 47.426029],
                [9.700788, 47.577412]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Norway",
          "density": "15",
          "cartodb_id": 20,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [22.174444, 79.799828],
                [21.456301, 79.806007],
                [20.547874, 79.813255],
                [19.765337, 79.78479],
                [19.421728, 79.757195],
                [18.534258, 79.899338],
                [18.89949, 80.104378],
                [19.271282, 80.181953],
                [19.60243, 80.38562],
                [20.119061, 80.650398],
                [20.728121, 80.339417],
                [21.177328, 80.291939],
                [21.679535, 80.331696],
                [22.084846, 80.293777],
                [22.401363, 80.140266],
                [22.791063, 80.285576],
                [23.029678, 80.48996],
                [23.420046, 80.627769],
                [23.280428, 80.257561],
                [23.319073, 80.113312],
                [23.701925, 80.11409],
                [24.147907, 80.336731],
                [24.625099, 80.454247],
                [24.986526, 80.396637],
                [25.23764, 80.184441],
                [25.55496, 80.165878],
                [26.458075, 80.215836],
                [27.152279, 80.130676],
                [27.400459, 79.961327],
                [27.172295, 79.870834],
                [26.270622, 79.591072],
                [25.54035, 79.419983],
                [25.058825, 79.392311],
                [24.620001, 79.329124],
                [24.024181, 79.164429],
                [21.201958, 79.208054],
                [21.186989, 79.377869],
                [21.85853, 79.495071],
                [22.174444, 79.799828]
              ]
            ],
            [
              [
                [13.455883, 79.817703],
                [13.486621, 79.504059],
                [13.600394, 79.450684],
                [14.362556, 79.380798],
                [14.461752, 79.550621],
                [14.520254, 79.738892],
                [14.778358, 79.802231],
                [15.013776, 79.820663],
                [15.440853, 79.606155],
                [15.977807, 79.259254],
                [16.336838, 79.153328],
                [16.592476, 79.251549],
                [16.492817, 79.38488],
                [16.285622, 79.545151],
                [16.138695, 79.759911],
                [16.120262, 79.858749],
                [16.529947, 80.042107],
                [16.98031, 79.995529],
                [17.198992, 79.91481],
                [17.774244, 79.906914],
                [18.037718, 79.826424],
                [18.001858, 79.637909],
                [17.925842, 79.503616],
                [17.97267, 79.432274],
                [18.21965, 79.379257],
                [18.554211, 79.531609],
                [18.752403, 79.576691],
                [19.050127, 79.398666],
                [19.355246, 79.247917],
                [19.708538, 79.177528],
                [20.135515, 79.125122],
                [20.887852, 78.931847],
                [21.111759, 78.826363],
                [20.524839, 78.701958],
                [19.632812, 78.6036],
                [19.319487, 78.471519],
                [18.99044, 77.956757],
                [18.592279, 77.558594],
                [18.033108, 77.50592],
                [17.688524, 77.265038],
                [17.483557, 76.999931],
                [17.470663, 76.829834],
                [17.116623, 76.73584],
                [16.866575, 76.743843],
                [16.149767, 76.903793],
                [15.30254, 77.13224],
                [14.688241, 77.387886],
                [14.981588, 77.551743],
                [15.685715, 77.570259],
                [16.490473, 77.632126],
                [16.915218, 77.805588],
                [16.621712, 77.917564],
                [15.801742, 77.85537],
                [15.097086, 77.802124],
                [14.334693, 77.783379],
                [14.108754, 78.016876],
                [14.54018, 78.043762],
                [15.157085, 78.140564],
                [16.124554, 78.281853],
                [16.820711, 78.484238],
                [15.684007, 78.666389],
                [15.287572, 78.797623],
                [15.022694, 78.735466],
                [14.556491, 78.444885],
                [14.07542, 78.312851],
                [13.822096, 78.286201],
                [13.26575, 78.363747],
                [13.113765, 78.442154],
                [12.641137, 78.607857],
                [12.278234, 78.712624],
                [11.963994, 78.897156],
                [12.728498, 79.075539],
                [12.360652, 79.33242],
                [11.74883, 79.188995],
                [11.538889, 79.224113],
                [11.123105, 79.562141],
                [11.488005, 79.724113],
                [11.720203, 79.733505],
                [12.203976, 79.788338],
                [12.661357, 79.771225],
                [12.921046, 79.798683],
                [13.455883, 79.817703]
              ]
            ],
            [
              [
                [11.382519, 59.087261],
                [11.315714, 59.182079],
                [11.032854, 59.122814],
                [10.972601, 59.134663],
                [10.86047, 59.241367],
                [10.752935, 59.395603],
                [10.837221, 59.466824],
                [10.773406, 59.597477],
                [10.610722, 59.51432],
                [10.58229, 59.38372],
                [10.633252, 59.276943],
                [10.567005, 59.229507],
                [10.414729, 59.087261],
                [10.375999, 59.004337],
                [10.141288, 58.956974],
                [9.901243, 59.01619],
                [9.549864, 58.862282],
                [9.575284, 58.80312],
                [9.349558, 58.673038],
                [9.125399, 58.554863],
                [8.378904, 58.189018],
                [8.357411, 58.106518],
                [8.23386, 58.082951],
                [8.112095, 58.082951],
                [7.162305, 57.988708],
                [7.077845, 58.094727],
                [6.929325, 58.15366],
                [6.847319, 58.059383],
                [6.68278, 58.106518],
                [6.855373, 58.189018],
                [6.434996, 58.295151],
                [6.077504, 58.44857],
                [5.819148, 58.472187],
                [5.603399, 58.732147],
                [5.734663, 58.897781],
                [5.678311, 58.9925],
                [5.831152, 58.980652],
                [5.869458, 58.838619],
                [5.997326, 58.933296],
                [6.297217, 58.814949],
                [6.187375, 58.903694],
                [6.24482, 58.968815],
                [6.469809, 59.010258],
                [6.254212, 58.998417],
                [6.144064, 58.95105],
                [6.025966, 59.033955],
                [5.975521, 59.093189],
                [6.236242, 59.21764],
                [6.44119, 59.300667],
                [6.223968, 59.271008],
                [6.08116, 59.330322],
                [6.279914, 59.437138],
                [6.317094, 59.544018],
                [6.019573, 59.330322],
                [6.003166, 59.449009],
                [5.884132, 59.526203],
                [5.886578, 59.431202],
                [5.920813, 59.354046],
                [5.732185, 59.282871],
                [5.547508, 59.282871],
                [5.553996, 59.407463],
                [5.486253, 59.288803],
                [5.380312, 59.324387],
                [5.290268, 59.526203],
                [5.586293, 59.728245],
                [5.622221, 59.532139],
                [5.652971, 59.674736],
                [5.849832, 59.603424],
                [5.944787, 59.645016],
                [5.870216, 59.69257],
                [6.173565, 59.728245],
                [6.389053, 59.841232],
                [6.11416, 59.769867],
                [5.897711, 59.769867],
                [5.747002, 59.841232],
                [5.835398, 59.900738],
                [6.009427, 59.966225],
                [6.097728, 60.178234],
                [5.728283, 60.184498],
                [5.482716, 60.142307],
                [5.299894, 60.281105],
                [5.46544, 60.344105],
                [5.754806, 60.52586],
                [5.659495, 60.609379],
                [5.45889, 60.581116],
                [5.279136, 60.608665],
                [5.184039, 60.706215],
                [5.312191, 60.92308],
                [5.590712, 61.042557],
                [5.323277, 61.154072],
                [5.221977, 61.322109],
                [5.344616, 61.406528],
                [5.30481, 61.715561],
                [5.352647, 61.757854],
                [5.619695, 61.78651],
                [5.796084, 61.806667],
                [6.000752, 61.786884],
                [6.037564, 61.83638],
                [5.923131, 61.896519],
                [5.731848, 61.949524],
                [5.62935, 62.026234],
                [5.673335, 62.110668],
                [5.768258, 62.167274],
                [6.027295, 62.195992],
                [6.257153, 62.351772],
                [6.512054, 62.302799],
                [6.736177, 62.352669],
                [6.816623, 62.600307],
                [7.192347, 62.558544],
                [7.492921, 62.502537],
                [7.750401, 62.616226],
                [7.305227, 62.721615],
                [7.185524, 62.863113],
                [7.291844, 62.92717],
                [7.810152, 62.921028],
                [8.192825, 62.957241],
                [8.434958, 62.858379],
                [8.46163, 63.071289],
                [8.675953, 63.19249],
                [8.809567, 63.342068],
                [9.160861, 63.421013],
                [9.357885, 63.335991],
                [9.419731, 63.300533],
                [9.644562, 63.514496],
                [10.035769, 63.700592],
                [10.073722, 63.893406],
                [10.269823, 64.072479],
                [10.94351, 64.403145],
                [11.09426, 64.489487],
                [11.200282, 64.511215],
                [11.417164, 64.432709],
                [11.567719, 64.432991],
                [11.711386, 64.533691],
                [11.6102, 64.63401],
                [11.673605, 64.691612],
                [11.893392, 64.792625],
                [12.099231, 64.929703],
                [12.315805, 65.146889],
                [12.425876, 65.118118],
                [12.730117, 65.125923],
                [12.699834, 65.401443],
                [12.487751, 65.451859],
                [12.682882, 65.634003],
                [12.912722, 65.92585],
                [13.291053, 65.897385],
                [13.312463, 66.050713],
                [13.355571, 66.153099],
                [13.784674, 66.205086],
                [13.606465, 66.365791],
                [13.45906, 66.409515],
                [13.372312, 66.475311],
                [13.445741, 66.585503],
                [13.586857, 66.695923],
                [13.795691, 66.674294],
                [14.195311, 66.70443],
                [13.950853, 66.939377],
                [14.062731, 66.976418],
                [14.258403, 66.991531],
                [14.576742, 67.110069],
                [14.842933, 67.140083],
                [14.981324, 67.233505],
                [14.820137, 67.262238],
                [14.772872, 67.346313],
                [14.902811, 67.391357],
                [15.179821, 67.539909],
                [15.430326, 67.525528],
                [15.588167, 67.481415],
                [15.783607, 67.600288],
                [15.949331, 67.614265],
                [15.965482, 67.69326],
                [15.775899, 67.837669],
                [16.057878, 67.942207],
                [16.123405, 68.065941],
                [16.274721, 68.061646],
                [16.436392, 67.928062],
                [16.615278, 67.89122],
                [16.852211, 68.062721],
                [16.725567, 68.357872],
                [16.922804, 68.328331],
                [17.236938, 68.354607],
                [17.330084, 68.433052],
                [17.05842, 68.574753],
                [17.243399, 68.675499],
                [17.610579, 68.788513],
                [17.652578, 68.903969],
                [17.769527, 68.991341],
                [18.098377, 69.052048],
                [18.131811, 69.022049],
                [18.255209, 69.04483],
                [18.301018, 69.074974],
                [18.522717, 69.37635],
                [18.756905, 69.263847],
                [19.188509, 69.332413],
                [19.373819, 69.272499],
                [19.492836, 69.227562],
                [19.783644, 69.198029],
                [20.024878, 69.386703],
                [20.361315, 69.764801],
                [20.49004, 69.802879],
                [20.561981, 69.727356],
                [20.481829, 69.598763],
                [20.400167, 69.334663],
                [20.75635, 69.538879],
                [20.937603, 69.796158],
                [21.306391, 69.834663],
                [21.615627, 70.032387],
                [21.812225, 69.979515],
                [22.111736, 69.821014],
                [22.366974, 70.110085],
                [22.510113, 70.23259],
                [22.949238, 70.187576],
                [23.40727, 69.959747],
                [23.496132, 70.028267],
                [23.712072, 70.296043],
                [24.030952, 70.495811],
                [24.158518, 70.519028],
                [24.411379, 70.488838],
                [24.563278, 70.627266],
                [24.774172, 70.758354],
                [25.052702, 70.843575],
                [25.592695, 70.9216],
                [26.035719, 70.914734],
                [25.434357, 70.575157],
                [25.326088, 70.452217],
                [25.277744, 70.161354],
                [25.466688, 70.12352],
                [25.777704, 70.384109],
                [26.466381, 70.807716],
                [26.810181, 70.931595],
                [26.822769, 70.816055],
                [26.821087, 70.409019],
                [27.138014, 70.486259],
                [27.334745, 70.594048],
                [27.719675, 70.987282],
                [28.151207, 71.034386],
                [28.414824, 71.034882],
                [28.512077, 70.965622],
                [28.340103, 70.849701],
                [28.188955, 70.733978],
                [28.222593, 70.626427],
                [28.079063, 70.442032],
                [28.493389, 70.389183],
                [28.592571, 70.588722],
                [28.998476, 70.881744],
                [29.188002, 70.874382],
                [29.944895, 70.621956],
                [30.245102, 70.561111],
                [30.665625, 70.554222],
                [30.957867, 70.447388],
                [31.002504, 70.324936],
                [30.455544, 70.148117],
                [29.93856, 70.131866],
                [28.933744, 70.152901],
                [29.020758, 70.114891],
                [29.283684, 69.978203],
                [29.759596, 69.850372],
                [29.93224, 69.714539],
                [30.25947, 69.737831],
                [30.564959, 69.783783],
                [30.939022, 69.769333],
                [31.257446, 69.656563],
                [31.075363, 69.527061],
                [30.844463, 69.435463],
                [30.4736, 69.426041],
                [30.127262, 69.371574],
                [29.666445, 69.151161],
                [29.211081, 68.848801],
                [28.900818, 68.712502],
                [28.900066, 68.802269],
                [28.943388, 68.967361],
                [29.336666, 69.382584],
                [29.316916, 69.503006],
                [28.920649, 69.659386],
                [28.618542, 69.710701],
                [28.112345, 69.90464],
                [27.862204, 69.903389],
                [27.612535, 69.871864],
                [26.710915, 69.829422],
                [26.321339, 69.661217],
                [26.066208, 69.471359],
                [25.949293, 69.274986],
                [25.913612, 69.049385],
                [25.829033, 68.824036],
                [25.266493, 68.611656],
                [25.004356, 68.445984],
                [24.865353, 68.512459],
                [24.285507, 68.73378],
                [23.539845, 68.632736],
                [22.904816, 68.651917],
                [22.663425, 68.710518],
                [22.275944, 68.978279],
                [21.828718, 69.25396],
                [21.609472, 69.290466],
                [21.449711, 69.244492],
                [20.994308, 68.949242],
                [20.943108, 68.977966],
                [20.75857, 68.992035],
                [20.369238, 68.975044],
                [20.242647, 68.495277],
                [20.168808, 68.293434],
                [20.046322, 68.18103],
                [19.900848, 68.187759],
                [19.599743, 68.245819],
                [19.162371, 68.310669],
                [18.383152, 68.485764],
                [18.3078, 68.403244],
                [18.297857, 68.105103],
                [18.260698, 68.015633],
                [18.117254, 67.881134],
                [17.804611, 67.879539],
                [17.412342, 67.922089],
                [17.286661, 67.928871],
                [17.077585, 67.853531],
                [16.40971, 67.324226],
                [16.431953, 67.228302],
                [16.508749, 67.00013],
                [16.424194, 66.845093],
                [16.147202, 66.689323],
                [15.551096, 66.275711],
                [15.242035, 66.076744],
                [14.800406, 66.067154],
                [14.745709, 65.957382],
                [14.718959, 65.520363],
                [14.575062, 65.251083],
                [14.402606, 65.040199],
                [14.144036, 64.81562],
                [13.9216, 64.570274],
                [14.073893, 64.513649],
                [14.245239, 64.392632],
                [14.358625, 64.128258],
                [14.282035, 64.049202],
                [14.083857, 63.969563],
                [13.754181, 63.953587],
                [13.33465, 63.965706],
                [13.056787, 63.914268],
                [12.780734, 63.798618],
                [12.502826, 63.583214],
                [12.327814, 63.361588],
                [12.293259, 63.062923],
                [12.136316, 62.570946],
                [12.429262, 62.242672],
                [12.278222, 61.715431],
                [12.540249, 61.540115],
                [12.861703, 61.456505],
                [12.9954, 61.34692],
                [12.801045, 61.045593],
                [12.377703, 60.973377],
                [12.634694, 60.642818],
                [12.751622, 60.450844],
                [12.639524, 60.295071],
                [12.626436, 60.055752],
                [12.280689, 59.882896],
                [12.094275, 59.876934],
                [12.041543, 59.686619],
                [11.813972, 59.585602],
                [11.912518, 59.211899],
                [11.766934, 58.880028],
                [11.582312, 58.874115],
                [11.514426, 59.028027],
                [11.382519, 59.087261]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Finland",
          "density": "39",
          "cartodb_id": 21,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [28.900818, 68.712502],
                [28.889616, 68.58532],
                [28.773785, 68.375732],
                [28.825884, 68.137642],
                [29.079393, 68.042259],
                [29.471792, 67.955124],
                [29.905487, 67.727364],
                [30.149778, 67.55088],
                [29.96064, 67.387268],
                [29.374535, 67.037605],
                [29.226578, 66.904388],
                [29.857924, 66.277008],
                [30.205393, 65.739098],
                [30.117113, 65.578697],
                [29.918985, 65.338211],
                [29.834822, 65.156738],
                [29.821886, 64.990387],
                [29.988081, 64.761253],
                [30.567114, 64.133797],
                [30.635187, 64.019829],
                [30.609489, 63.905476],
                [30.365868, 63.718807],
                [30.218594, 63.632549],
                [30.416306, 63.484097],
                [31.027288, 63.259769],
                [31.343615, 63.140713],
                [31.539591, 62.964405],
                [31.611965, 62.808937],
                [31.564583, 62.624748],
                [31.371206, 62.383575],
                [31.105511, 62.142441],
                [30.48229, 61.801422],
                [29.589777, 61.263615],
                [28.713703, 60.748783],
                [28.635439, 60.620441],
                [28.215101, 60.527702],
                [27.677282, 60.44305],
                [27.609884, 60.36179],
                [27.382145, 60.459366],
                [27.149235, 60.393284],
                [27.125973, 60.333096],
                [26.81674, 60.364803],
                [26.72954, 60.413441],
                [26.755302, 60.587669],
                [26.6465, 60.350315],
                [26.526377, 60.287212],
                [26.241409, 60.26627],
                [26.093765, 60.252808],
                [26.049196, 60.132454],
                [25.784208, 60.279308],
                [25.653559, 60.155369],
                [25.349455, 60.12672],
                [25.196644, 60.046543],
                [25.173134, 60.103806],
                [24.946644, 60.055202],
                [24.719622, 60.00647],
                [24.624714, 59.886723],
                [24.485022, 59.95512],
                [24.182611, 59.898121],
                [24.164778, 59.949425],
                [23.86832, 59.852524],
                [23.622519, 59.846821],
                [23.405106, 59.83543],
                [23.185789, 59.949425],
                [23.085665, 60.072536],
                [23.208864, 60.225731],
                [22.828279, 60.218075],
                [22.59573, 60.138172],
                [22.625132, 60.310158],
                [22.424318, 60.275742],
                [22.134617, 60.397743],
                [21.950472, 60.425243],
                [21.763815, 60.529457],
                [21.646841, 60.710621],
                [21.63092, 61.03207],
                [21.701971, 61.200245],
                [21.724453, 61.396542],
                [21.764669, 61.501892],
                [21.608881, 61.719406],
                [21.414864, 61.887886],
                [21.550104, 62.184105],
                [21.54122, 62.360577],
                [21.403803, 62.452156],
                [21.237392, 62.49427],
                [21.265471, 62.628708],
                [21.39847, 62.777657],
                [21.684948, 62.905785],
                [21.74963, 62.991032],
                [21.69832, 63.140003],
                [21.870142, 63.204277],
                [22.154987, 63.147987],
                [22.278708, 63.119793],
                [22.496021, 63.219666],
                [22.632439, 63.411938],
                [22.679495, 63.618587],
                [22.772682, 63.739986],
                [22.907887, 63.597614],
                [23.029194, 63.597851],
                [23.783949, 64.09903],
                [24.297699, 64.393539],
                [24.673758, 64.652496],
                [25.13558, 64.833008],
                [25.528753, 64.819366],
                [25.555569, 64.95607],
                [25.497078, 65.020866],
                [25.336224, 65.092918],
                [25.38979, 65.259628],
                [25.455172, 65.433861],
                [25.307276, 65.557098],
                [24.794804, 65.599754],
                [24.418465, 65.802879],
                [24.151396, 65.813652],
                [24.100409, 65.918922],
                [23.953117, 66.056747],
                [23.859777, 66.297417],
                [24.08489, 66.657608],
                [24.071836, 66.833847],
                [23.889334, 67.14212],
                [23.661549, 67.428902],
                [23.663404, 67.665749],
                [23.691317, 67.851295],
                [23.344902, 68.102173],
                [23.116396, 68.205215],
                [22.404411, 68.298431],
                [21.91614, 68.430183],
                [21.336992, 68.726212],
                [21.060061, 68.93457],
                [20.994308, 68.949242],
                [21.449711, 69.244492],
                [21.609472, 69.290466],
                [21.828718, 69.25396],
                [22.275944, 68.978279],
                [22.663425, 68.710518],
                [22.904816, 68.651917],
                [23.539845, 68.632736],
                [24.285507, 68.73378],
                [24.865353, 68.512459],
                [25.004356, 68.445984],
                [25.266493, 68.611656],
                [25.829033, 68.824036],
                [25.913612, 69.049385],
                [25.949293, 69.274986],
                [26.066208, 69.471359],
                [26.321339, 69.661217],
                [26.710915, 69.829422],
                [27.612535, 69.871864],
                [27.862204, 69.903389],
                [28.112345, 69.90464],
                [28.618542, 69.710701],
                [28.920649, 69.659386],
                [29.316916, 69.503006],
                [29.336666, 69.382584],
                [28.943388, 68.967361],
                [28.900066, 68.802269],
                [28.900818, 68.712502]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Sweden",
          "density": "26",
          "cartodb_id": 22,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [24.151396, 65.813652],
                [24.047747, 65.815445],
                [23.609352, 65.741806],
                [23.407684, 65.697723],
                [23.001825, 65.871796],
                [22.849218, 65.951759],
                [22.693466, 65.900391],
                [22.405233, 65.732262],
                [22.161146, 65.462708],
                [22.02239, 65.39711],
                [21.725992, 65.389282],
                [21.48101, 65.229248],
                [21.627377, 65.120857],
                [21.693113, 65.034134],
                [21.533106, 64.911324],
                [21.124857, 64.730797],
                [21.267986, 64.608971],
                [21.44071, 64.537514],
                [21.589493, 64.401527],
                [21.25256, 64.236137],
                [21.070145, 64.042587],
                [20.904324, 63.835098],
                [20.188549, 63.534241],
                [19.87394, 63.405514],
                [19.668676, 63.497677],
                [19.521889, 63.4618],
                [19.279591, 63.219471],
                [18.988655, 63.154984],
                [18.690289, 62.998215],
                [18.592613, 62.870346],
                [18.506855, 62.806396],
                [18.171104, 62.791595],
                [18.071833, 62.805592],
                [18.139919, 62.564976],
                [17.921776, 62.373707],
                [17.493904, 62.457695],
                [17.591026, 62.217739],
                [17.700436, 62.105072],
                [17.590637, 61.675407],
                [17.366306, 61.646877],
                [17.268841, 61.555351],
                [17.353853, 61.21468],
                [17.349873, 60.969799],
                [17.521292, 60.770042],
                [17.392162, 60.691265],
                [17.808628, 60.65451],
                [17.91012, 60.570553],
                [18.134413, 60.607281],
                [18.428051, 60.339939],
                [18.620529, 60.198597],
                [19.00775, 60.12537],
                [19.096735, 59.942661],
                [19.240988, 59.880215],
                [19.247215, 59.729397],
                [19.071556, 59.729397],
                [18.842915, 59.5839],
                [18.455086, 59.44891],
                [18.233747, 59.376255],
                [17.998947, 59.381447],
                [17.933617, 59.433334],
                [17.571648, 59.485237],
                [17.274261, 59.563122],
                [16.989202, 59.594288],
                [16.927753, 59.547543],
                [16.726871, 59.599487],
                [16.61124, 59.46447],
                [16.980139, 59.495628],
                [17.203499, 59.376255],
                [17.469442, 59.355507],
                [17.561787, 59.26215],
                [17.778585, 59.231045],
                [17.788076, 59.288086],
                [18.065395, 59.256966],
                [18.181299, 59.345123],
                [18.315042, 59.339947],
                [18.531834, 59.386639],
                [18.633224, 59.350319],
                [18.645035, 59.417763],
                [18.719133, 59.417763],
                [18.814991, 59.350319],
                [18.801859, 59.314014],
                [18.477036, 59.303638],
                [18.575624, 59.174042],
                [18.219332, 59.054882],
                [18.143801, 58.925461],
                [17.970129, 58.88924],
                [17.930086, 58.930634],
                [17.948864, 59.003101],
                [17.942772, 59.127396],
                [17.826845, 59.117043],
                [17.785852, 58.909939],
                [17.560339, 58.759941],
                [17.449053, 58.734089],
                [17.286663, 58.765106],
                [17.284395, 58.666901],
                [16.64653, 58.651394],
                [16.716858, 58.445087],
                [16.947708, 58.328922],
                [16.977209, 58.054874],
                [16.928249, 57.906296],
                [16.801428, 57.883427],
                [16.663195, 57.912178],
                [16.680195, 57.719101],
                [16.810869, 57.682785],
                [16.754143, 57.562122],
                [16.862343, 57.459923],
                [16.697823, 57.295895],
                [16.598188, 57.091274],
                [16.579838, 56.839394],
                [16.235109, 56.370045],
                [15.974205, 56.050182],
                [15.899893, 56.077934],
                [15.700705, 56.184216],
                [15.424465, 56.097336],
                [14.856557, 56.137589],
                [14.808894, 55.997757],
                [14.695492, 56.035011],
                [14.462589, 55.916252],
                [14.329685, 55.683556],
                [14.490014, 55.532467],
                [14.320859, 55.364086],
                [14.104775, 55.422127],
                [14.026963, 55.438789],
                [13.505515, 55.317669],
                [13.006036, 55.369884],
                [13.005584, 55.433739],
                [13.091219, 55.468578],
                [13.062978, 55.549889],
                [13.189529, 55.683556],
                [12.902678, 55.92207],
                [12.717021, 56.137589],
                [12.618077, 56.260014],
                [12.856043, 56.312504],
                [12.819099, 56.452579],
                [13.008471, 56.418171],
                [13.075239, 56.528488],
                [12.989523, 56.633656],
                [12.861145, 56.627811],
                [12.729696, 56.797367],
                [12.492285, 56.896828],
                [12.208806, 57.342178],
                [12.080286, 57.342178],
                [12.084778, 57.525936],
                [11.969748, 57.689651],
                [11.813502, 57.823917],
                [11.912808, 58.04171],
                [12.00555, 58.194912],
                [11.817524, 58.26432],
                [11.634758, 58.218498],
                [11.610373, 58.348244],
                [11.425022, 58.325294],
                [11.344055, 58.868191],
                [11.242907, 58.927368],
                [11.382519, 59.087261],
                [11.514426, 59.028027],
                [11.582312, 58.874115],
                [11.766934, 58.880028],
                [11.912518, 59.211899],
                [11.813972, 59.585602],
                [12.041543, 59.686619],
                [12.094275, 59.876934],
                [12.280689, 59.882896],
                [12.626436, 60.055752],
                [12.639524, 60.295071],
                [12.751622, 60.450844],
                [12.634694, 60.642818],
                [12.377703, 60.973377],
                [12.801045, 61.045593],
                [12.9954, 61.34692],
                [12.861703, 61.456505],
                [12.540249, 61.540115],
                [12.278222, 61.715431],
                [12.429262, 62.242672],
                [12.136316, 62.570946],
                [12.293259, 63.062923],
                [12.327814, 63.361588],
                [12.502826, 63.583214],
                [12.780734, 63.798618],
                [13.056787, 63.914268],
                [13.33465, 63.965706],
                [13.754181, 63.953587],
                [14.083857, 63.969563],
                [14.282035, 64.049202],
                [14.358625, 64.128258],
                [14.245239, 64.392632],
                [14.073893, 64.513649],
                [13.9216, 64.570274],
                [14.144036, 64.81562],
                [14.402606, 65.040199],
                [14.575062, 65.251083],
                [14.718959, 65.520363],
                [14.745709, 65.957382],
                [14.800406, 66.067154],
                [15.242035, 66.076744],
                [15.551096, 66.275711],
                [16.147202, 66.689323],
                [16.424194, 66.845093],
                [16.508749, 67.00013],
                [16.431953, 67.228302],
                [16.40971, 67.324226],
                [17.077585, 67.853531],
                [17.286661, 67.928871],
                [17.412342, 67.922089],
                [17.804611, 67.879539],
                [18.117254, 67.881134],
                [18.260698, 68.015633],
                [18.297857, 68.105103],
                [18.3078, 68.403244],
                [18.383152, 68.485764],
                [19.162371, 68.310669],
                [19.599743, 68.245819],
                [19.900848, 68.187759],
                [20.046322, 68.18103],
                [20.168808, 68.293434],
                [20.242647, 68.495277],
                [20.369238, 68.975044],
                [20.75857, 68.992035],
                [20.943108, 68.977966],
                [20.994308, 68.949242],
                [21.060061, 68.93457],
                [21.336992, 68.726212],
                [21.91614, 68.430183],
                [22.404411, 68.298431],
                [23.116396, 68.205215],
                [23.344902, 68.102173],
                [23.691317, 67.851295],
                [23.663404, 67.665749],
                [23.661549, 67.428902],
                [23.889334, 67.14212],
                [24.071836, 66.833847],
                [24.08489, 66.657608],
                [23.859777, 66.297417],
                [23.953117, 66.056747],
                [24.100409, 65.918922],
                [24.151396, 65.813652]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Poland",
          "density": "122",
          "cartodb_id": 28,
          "created_at": "2013-12-02T19:45:13",
          "updated_at": "2013-12-02T19:45:13"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [19.015549, 49.586899],
                [18.859288239482957, 49.765096479894972],
                [18.674448685267329, 49.821970188884393],
                [18.610465762654226, 49.992591315852664],
                [18.471546, 50.025015],
                [18.760988, 50.447862],
                [18.351165, 51.418494],
                [17.962912, 51.612621],
                [17.747216, 52.108722],
                [17.143266, 52.108722],
                [16.625595, 52.281279],
                [16.517747, 52.496975],
                [16.496178, 53.165634],
                [16.92757, 53.144064],
                [17.919773, 53.683305],
                [17.811925, 53.834292],
                [18.503161, 54.733254],
                [18.963194, 54.553387],
                [18.56658, 54.685863],
                [18.54216, 54.619617],
                [18.657471, 54.542351],
                [18.71682, 54.332752],
                [18.876741, 54.272114],
                [19.16864, 54.244556],
                [19.458668, 54.294167],
                [19.937262, 54.478146],
                [19.96138, 54.476147],
                [19.521273, 54.288651],
                [19.435726, 54.239044],
                [19.433971, 54.183941],
                [19.531763, 54.178425],
                [19.660486, 54.233536],
                [19.936783, 54.32724],
                [20.181202, 54.440914],
                [20.559933, 54.438431],
                [21.570406, 54.386517],
                [22.9027, 54.431873],
                [23.190722, 54.441681],
                [23.208748, 54.43523],
                [23.514282, 54.325867],
                [23.642204, 54.106888],
                [23.686558, 54.023151],
                [23.69347, 54.010098],
                [23.734165, 53.933266],
                [23.775726, 53.917282],
                [23.845104, 53.932697],
                [23.976147, 53.917282],
                [24.084066, 53.932697],
                [24.253653, 53.940407],
                [24.36157, 53.963531],
                [24.415531, 53.940407],
                [24.446363, 53.894157],
                [24.577408, 53.894157],
                [24.746994, 53.963531],
                [24.839497, 53.986656],
                [24.901163, 53.971241],
                [24.978249, 53.986656],
                [25.009083, 54.040615],
                [24.970541, 54.117702],
                [25.093876, 54.163952],
                [25.178669, 54.148537],
                [25.248045, 54.148537],
                [25.355965, 54.202496],
                [25.386797, 54.264164],
                [25.517841, 54.248745],
                [25.5718, 54.287289],
                [25.648886, 54.310413],
                [25.687428, 54.279579],
                [25.702845, 54.241035],
                [25.702845, 54.171661],
                [25.803055, 54.140827],
                [25.92639, 54.148537],
                [25.964933, 54.23333],
                [25.903265, 54.325829],
                [25.810764, 54.325829],
                [25.733679, 54.341248],
                [25.764513, 54.402916],
                [25.795347, 54.441456],
                [25.795347, 54.503124],
                [25.903265, 54.572502],
                [25.934099, 54.634171],
                [25.887848, 54.711254],
                [25.92639, 54.788338],
                [25.941809, 54.857716],
                [25.995768, 54.911674],
                [26.057434, 54.973343],
                [26.250147, 54.973343],
                [26.365774, 55.004177],
                [26.412024, 55.073551],
                [26.450567, 55.142929],
                [26.519943, 55.158344],
                [26.627861, 55.142929],
                [26.68182, 55.166054],
                [26.735781, 55.12751],
                [26.812864, 55.13522],
                [26.820574, 55.204597],
                [26.866823, 55.273972],
                [26.905367, 55.273972],
                [26.959326, 55.297096],
                [26.920784, 55.320225],
                [26.797447, 55.343349],
                [26.643278, 55.358765],
                [26.68182, 55.420433],
                [26.735781, 55.482101],
                [26.751198, 55.53606],
                [26.78974, 55.605438],
                [26.797447, 55.682522],
                [26.835991, 55.705647],
                [26.88224, 55.697937],
                [26.951618, 55.697937],
                [27.051826, 55.736481],
                [27.09808, 55.798149],
                [27.175163, 55.828983],
                [27.33704, 55.828983],
                [27.483501, 55.813564],
                [27.591421, 55.798149],
                [27.699339, 55.78273],
                [27.784132, 55.798149],
                [27.932289, 55.517733],
                [27.878365, 55.140265],
                [27.366087, 53.87305],
                [27.285201, 52.929379],
                [27.527859, 52.336214],
                [27.473935, 51.74305],
                [27.608745, 51.500392],
                [27.689631, 50.853303],
                [27.150391, 50.341024],
                [27.204315, 49.693936],
                [27.150391, 49.127733],
                [27.193065, 48.69773],
                [26.366327, 48.957562],
                [26.285321, 49.146575],
                [26.150311, 48.876556],
                [25.880291, 49.227581],
                [25.71828, 49.227581],
                [25.691278, 48.957562],
                [25.151239, 48.876556],
                [24.881219, 48.552532],
                [24.530193, 48.52553],
                [24.412692, 47.961058],
                [24.24746, 47.970897],
                [24.169447, 48.371079],
                [24.06576, 48.336517],
                [23.979353, 48.52661],
                [23.841103, 48.492048],
                [23.841103, 48.630298],
                [23.530041, 48.80311],
                [23.478197, 48.647579],
                [23.132572, 48.768548],
                [22.959759, 49.027767],
                [22.579572, 49.027767],
                [22.494122, 49.194713],
                [22.450899, 49.211858],
                [22.400095, 49.23201],
                [22.209478, 49.277443],
                [22.162823, 49.374466],
                [21.846554, 49.457985],
                [21.788673, 49.516552],
                [21.536417, 49.482491],
                [21.307875, 49.500893],
                [21.274784, 49.439156],
                [21.106834, 49.358295],
                [20.899752, 49.472565],
                [20.682507, 49.451775],
                [20.606749, 49.476898],
                [20.356043, 49.38525],
                [20.309046, 49.305836],
                [20.241749, 49.245113],
                [20.160765, 49.299007],
                [19.985825, 49.261272],
                [19.984568, 49.352901],
                [19.996456, 49.390636],
                [19.96657, 49.471508],
                [19.817381, 49.460228],
                [19.784769, 49.536217],
                [19.697104, 49.644093],
                [19.579891, 49.633316],
                [19.552082, 49.583145],
                [19.465229, 49.590157],
                [19.382977, 49.482292],
                [19.189697, 49.460724],
                [19.175789, 49.563183],
                [19.047859, 49.581512],
                [19.015549, 49.586899]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Yugoslavia",
          "density": "92.6",
          "cartodb_id": 25,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [22.532906, 42.357292],
                [22.530014, 42.309647],
                [22.589886, 42.213638],
                [22.889843, 42.080444],
                [23.050894, 41.908016],
                [23.152157, 41.696785],
                [23.149702, 41.472244],
                [23.102753, 41.337315],
                [23.123907, 41.247662],
                [22.89603, 41.175892],
                [22.656248, 41.065594],
                [22.376604, 41.089687],
                [22.213909, 41.108025],
                [22.049364, 41.036613],
                [21.872051, 40.84338],
                [21.598925, 40.777805],
                [21.235302, 40.743763],
                [20.910639, 41.017536],
                [20.776485, 41.318115],
                [20.743227, 41.606564],
                [20.802586, 41.799393],
                [20.786631, 41.897686],
                [20.778645, 41.946884],
                [20.448849, 42.336773],
                [20.276661, 42.502865],
                [20.250717, 42.504494],
                [20.088224, 42.514683],
                [19.761362, 42.564301],
                [19.638283, 42.441544],
                [19.512478, 42.209621],
                [19.572325, 42.017315],
                [19.571377, 41.863342],
                [19.54213, 41.878994],
                [19.346701, 41.949062],
                [19.339209, 42.016514],
                [19.113564, 42.234764],
                [18.732307, 42.369717],
                [18.717253, 42.379112],
                [18.452375, 42.544411],
                [18.205032, 42.71143],
                [17.850044, 42.881573],
                [17.799343, 42.905872],
                [17.751644, 42.937092],
                [17.278751, 43.239719],
                [17.195927, 43.339687],
                [16.843477, 43.406708],
                [16.752174, 43.478287],
                [16.570412, 43.529995],
                [16.263071, 43.450451],
                [16.116499, 43.66922],
                [15.615982, 43.951729],
                [15.353132, 44.256901],
                [15.478069, 44.253586],
                [15.751886, 44.246319],
                [15.489608, 44.34589],
                [15.19277, 44.556999],
                [15.110625, 44.740295],
                [15.149197, 44.943562],
                [15.019055, 45.094841],
                [14.72825, 45.283257],
                [14.558152, 45.339863],
                [14.442286, 45.079327],
                [14.378272, 45.027359],
                [14.392177, 44.959518],
                [14.264064, 44.911674],
                [14.194921, 44.800072],
                [14.098766, 44.776154],
                [13.875723, 45.067329],
                [13.847253, 45.19606],
                [13.730555, 45.471283],
                [13.75994, 45.476574],
                [13.952749, 45.511303],
                [14.003648, 45.583344],
                [14.028622, 45.679661],
                [13.900801, 45.768734],
                [13.738722, 45.818241],
                [13.795671, 45.980778],
                [13.6444, 46.020859],
                [13.810001, 46.149109],
                [13.72566, 46.23254],
                [13.592371, 46.233303],
                [13.548136, 46.301479],
                [13.824765, 46.437855],
                [13.772563, 46.527397],
                [14.240313, 46.498032],
                [14.321087, 46.44989],
                [14.596609, 46.453899],
                [14.725433, 46.3857],
                [15.016524, 46.610405],
                [15.271523, 46.658566],
                [15.653701, 46.622448],
                [15.787649, 46.710766],
                [16.188242, 46.658566],
                [16.151058, 46.835239],
                [16.463238, 46.905777],
                [16.600031, 46.640095],
                [16.76375, 46.489914],
                [16.860415, 46.401241],
                [17.496065, 46.125648],
                [17.998072, 45.823624],
                [18.259478, 45.779671],
                [18.55979, 45.800732],
                [18.754665, 45.814751],
                [19.01972, 45.90609],
                [19.710396, 46.144104],
                [20.110079, 46.178703],
                [20.518398, 46.148479],
                [20.542887, 46.009583],
                [20.798712, 45.929443],
                [20.884752, 45.82262],
                [20.975714, 45.806602],
                [20.941923, 45.646439],
                [20.988197, 45.59306],
                [20.962351, 45.553429],
                [21.28277, 45.341507],
                [21.5322, 45.278149],
                [21.669695, 45.224987],
                [21.701057, 45.161007],
                [21.615437, 45.103916],
                [21.559444, 45.038414],
                [21.641956, 45.011765],
                [21.749432, 44.974533],
                [21.627806, 44.942444],
                [21.547913, 44.90012],
                [21.571152, 44.825722],
                [21.681316, 44.809772],
                [21.786978, 44.809772],
                [21.795515, 44.724754],
                [22.062567, 44.698193],
                [22.150173, 44.63575],
                [22.336561, 44.528206],
                [22.403072, 44.581318],
                [22.499565, 44.708824],
                [22.61319, 44.767269],
                [22.894766, 44.655689],
                [22.94603, 44.597256],
                [22.788479, 44.60257],
                [22.64423, 44.512272],
                [22.709625, 44.363571],
                [22.897665, 44.280064],
                [22.750202, 44.124649],
                [22.577578, 43.956264],
                [22.497019, 43.820606],
                [22.521755, 43.698429],
                [23.118828, 43.193375],
                [23.131485, 43.103405],
                [22.995916, 42.967644],
                [22.65313, 42.817913],
                [22.542059, 42.682323],
                [22.538595, 42.451],
                [22.532906, 42.357292]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Bulgaria",
          "density": "62",
          "cartodb_id": 26,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [22.532906, 42.357292],
                [22.538595, 42.451],
                [22.542059, 42.682323],
                [22.65313, 42.817913],
                [22.995916, 42.967644],
                [23.131485, 43.103405],
                [23.118828, 43.193375],
                [22.521755, 43.698429],
                [22.497019, 43.820606],
                [22.577578, 43.956264],
                [22.750202, 44.124649],
                [22.897665, 44.280064],
                [23.074047, 44.188362],
                [23.23192, 44.140583],
                [23.126774, 43.997288],
                [23.148039, 43.91238],
                [23.267721, 43.922997],
                [23.614552, 44.007896],
                [23.841969, 44.007046],
                [24.360037, 43.829578],
                [24.513187, 43.843285],
                [24.824009, 43.960873],
                [24.958679, 43.955158],
                [25.58177, 43.797596],
                [25.752756, 43.824276],
                [26.609869, 44.171391],
                [26.776525, 44.230182],
                [26.925426, 44.00596],
                [27.329857, 43.871149],
                [27.572515, 43.628491],
                [27.788211, 43.601529],
                [27.923021, 43.493681],
                [28.057831, 43.493681],
                [28.238302, 43.408875],
                [28.090984, 43.080498],
                [28.073572, 42.816849],
                [28.039827, 42.752495],
                [27.857708, 42.70071],
                [27.705614, 42.533318],
                [27.698366, 42.417873],
                [27.811535, 42.43375],
                [27.926197, 42.333187],
                [27.941374, 42.263973],
                [28.117071, 42.095074],
                [28.121616, 41.994644],
                [27.521603, 41.9967],
                [27.284349, 42.046757],
                [27.060785, 42.026272],
                [26.7775, 41.857853],
                [26.553539, 41.657726],
                [26.389954, 41.669655],
                [26.235458, 41.572586],
                [26.27886, 41.425301],
                [26.240086, 41.309669],
                [25.737343, 41.185104],
                [25.562984, 41.171329],
                [25.284573, 41.265953],
                [24.621727, 41.493168],
                [24.359076, 41.466064],
                [23.999279, 41.34866],
                [23.164642, 41.260704],
                [23.123907, 41.247662],
                [23.102753, 41.337315],
                [23.149702, 41.472244],
                [23.152157, 41.696785],
                [23.050894, 41.908016],
                [22.889843, 42.080444],
                [22.589886, 42.213638],
                [22.530014, 42.309647],
                [22.532906, 42.357292]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Hungary",
          "density":"109.17",
          "cartodb_id": 27,
          "created_at": "2013-12-02T19:45:13",
          "updated_at": "2013-12-02T19:45:13"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [22.104279, 48.614444],
                [22.371239769579908, 48.417900498207985],
                [22.776464946129558, 48.368136002842235],
                [22.872439330049211, 48.147750380508221],
                [23.110597986442425, 48.140641166884542],
                [23.232592, 48.029198],
                [23.029961, 47.877045],
                [22.523676, 47.757065],
                [22.262337, 47.573341],
                [21.826668, 46.920502],
                [21.543386, 46.542217],
                [21.204279, 46.287247],
                [21.041912, 46.214993],
                [20.716248, 46.193775],
                [20.597698, 46.147732],
                [20.518398, 46.148479],
                [20.110079, 46.178703],
                [19.710396, 46.144104],
                [19.01972, 45.90609],
                [18.754665, 45.814751],
                [18.55979, 45.800732],
                [18.259478, 45.779671],
                [17.998072, 45.823624],
                [17.496065, 46.125648],
                [16.860415, 46.401241],
                [16.76375, 46.489914],
                [16.600031, 46.640095],
                [16.463238, 46.905777],
                [16.618881, 47.040112],
                [16.68816, 47.172745],
                [16.596561, 47.257164],
                [16.614473, 47.426052],
                [16.798721, 47.45422],
                [16.869143, 47.546749],
                [16.808184, 47.635277],
                [16.604532, 47.663448],
                [16.704821, 47.75201],
                [16.851107, 47.75201],
                [16.925219, 47.691628],
                [17.240534, 47.715778],
                [17.24885, 47.880856],
                [17.534882, 48.01165],
                [17.337214, 48.153702],
                [17.704076, 48.226191],
                [17.876633, 48.161482],
                [18.09233, 48.334039],
                [18.955114, 48.226191],
                [19.278659, 48.398748],
                [19.559064, 48.334039],
                [19.79633, 48.485026],
                [20.076735, 48.463456],
                [20.141444, 48.657583],
                [20.702254, 48.743861],
                [20.831671, 48.873279],
                [21.543469, 48.894849],
                [21.737595, 48.80857],
                [21.867013, 48.614444],
                [22.104279, 48.614444]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Czechoslovakia",
          "density":"121",
          "cartodb_id": 24,
          "created_at": "2013-12-02T19:45:13",
          "updated_at": "2013-12-02T19:45:13"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [18.674448685267329, 49.821970188884393],
                [18.859288239482957, 49.765096479894972],
                [19.015549, 49.586899],
                [19.047859, 49.581512],
                [19.175789, 49.563183],
                [19.189697, 49.460724],
                [19.382977, 49.482292],
                [19.465229, 49.590157],
                [19.552082, 49.583145],
                [19.579891, 49.633316],
                [19.697104, 49.644093],
                [19.784769, 49.536217],
                [19.817381, 49.460228],
                [19.96657, 49.471508],
                [19.996456, 49.390636],
                [19.984568, 49.352901],
                [19.985825, 49.261272],
                [20.160765, 49.299007],
                [20.241749, 49.245113],
                [20.309046, 49.305836],
                [20.356043, 49.38525],
                [20.606749, 49.476898],
                [20.682507, 49.451775],
                [20.899752, 49.472565],
                [21.106834, 49.358295],
                [21.274784, 49.439156],
                [21.307875, 49.500893],
                [21.536417, 49.482491],
                [21.788673, 49.516552],
                [21.846554, 49.457985],
                [22.162823, 49.374466],
                [22.209478, 49.277443],
                [22.400095, 49.23201],
                [22.450899, 49.211858],
                [22.494122, 49.194713],
                [22.579572, 49.027767],
                [22.959759, 49.027767],
                [23.132572, 48.768548],
                [23.478197, 48.647579],
                [23.530041, 48.80311],
                [23.841103, 48.630298],
                [23.841103, 48.492048],
                [23.979353, 48.52661],
                [24.06576, 48.336517],
                [24.169447, 48.371079],
                [24.24746, 47.970897],
                [24.187349, 47.974476],
                [23.756584, 48.061443],
                [23.648056, 48.013096],
                [23.293867, 48.067455],
                [23.232592, 48.029198],
                [23.110597986442425, 48.140641166884542],
                [22.872439330049211, 48.147750380508221],
                [22.776464946129558, 48.368136002842235],
                [22.371239769579908, 48.417900498207985],
                [22.104279, 48.614444],
                [21.867013, 48.614444],
                [21.737595, 48.80857],
                [21.543469, 48.894849],
                [20.831671, 48.873279],
                [20.702254, 48.743861],
                [20.141444, 48.657583],
                [20.076735, 48.463456],
                [19.79633, 48.485026],
                [19.559064, 48.334039],
                [19.278659, 48.398748],
                [18.955114, 48.226191],
                [18.09233, 48.334039],
                [17.876633, 48.161482],
                [17.704076, 48.226191],
                [17.337214, 48.153702],
                [17.192629, 48.257607],
                [17.122915, 48.374657],
                [17.166561, 48.636005],
                [17.14245, 48.678017],
                [17.117998, 48.720627],
                [16.872818, 48.810757],
                [16.273294, 48.729168],
                [15.81518, 48.831223],
                [15.196807, 48.98476],
                [14.861148, 48.636696],
                [14.592174, 48.56345],
                [14.374601, 48.607971],
                [14.21547, 48.665886],
                [14.150347, 48.672073],
                [13.949145, 48.956921],
                [13.855733, 48.973072],
                [13.781816, 49.053864],
                [13.683398, 49.016155],
                [13.600607, 49.106506],
                [13.406775, 49.183144],
                [13.14892, 49.41489],
                [13.064469, 49.382545],
                [12.84944, 49.501556],
                [12.739963, 49.579548],
                [12.72337, 49.684555],
                [12.651974, 49.723118],
                [12.688014, 49.905819],
                [12.757827, 50.018593],
                [12.572054, 50.062611],
                [12.456005, 50.232864],
                [12.439023, 50.257778],
                [12.548414, 50.33371],
                [12.665282, 50.415096],
                [12.797515, 50.400536],
                [12.987333, 50.491077],
                [13.186008, 50.491077],
                [13.221165, 50.572521],
                [13.39306, 50.57795],
                [13.43666, 50.68074],
                [13.597818, 50.708317],
                [13.676928, 50.675713],
                [13.727822, 50.773518],
                [14.058445, 50.784389],
                [14.114322, 50.860481],
                [14.350149, 50.882229],
                [14.478413, 50.925728],
                [14.592433, 50.963799],
                [14.465324, 51.06171],
                [14.661462, 51.094357],
                [14.800047, 51.039948],
                [14.813987, 50.914852],
                [14.968475, 50.882229],
                [15.032544, 50.933884],
                [15.200931, 50.933884],
                [15.211932, 51.001865],
                [15.184624, 51.034508],
                [15.21295, 51.072594],
                [15.37555, 51.080757],
                [15.470676, 51.039948],
                [15.512417, 50.923008],
                [15.675827, 50.849609],
                [15.766463, 50.838261],
                [16.022007, 50.814281],
                [16.08115, 50.732761],
                [16.206596, 50.749065],
                [16.204521, 50.672993],
                [16.413872, 50.702881],
                [16.441448, 50.732761],
                [16.55681, 50.721889],
                [16.64649, 50.640404],
                [16.602255, 50.58609],
                [16.50841, 50.575233],
                [16.408066, 50.480221],
                [16.655087, 50.368977],
                [16.788618, 50.198124],
                [16.931686, 50.165596],
                [17.030722, 50.249641],
                [17.215197, 50.303875],
                [17.088104, 50.439518],
                [17.089252, 50.504665],
                [17.218088, 50.493797],
                [17.446993, 50.40696],
                [17.56341, 50.352695],
                [17.882423, 50.35524],
                [17.800781, 50.227951],
                [17.949236, 50.265911],
                [17.948372, 50.152042],
                [17.954321, 50.052948],
                [18.086159, 50.047081],
                [18.251699, 50.119522],
                [18.391432, 50.038227],
                [18.471546, 50.025015],
                [18.610465762654226, 49.992591315852664],
                [18.674448685267329, 49.821970188884393]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Romania",
          "density": "86.43",
          "cartodb_id": 29,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [24.412692, 47.961058],
                [24.530193, 48.52553],
                [24.881219, 48.552532],
                [25.151239, 48.876556],
                [25.691278, 48.957562],
                [25.71828, 49.227581],
                [25.880291, 49.227581],
                [26.150311, 48.876556],
                [26.285321, 49.146575],
                [26.366327, 48.957562],
                [27.193065, 48.69773],
                [27.311395, 48.66054],
                [27.311395, 48.849554],
                [27.689423, 48.687542],
                [27.689423, 48.768548],
                [27.851435, 48.768548],
                [27.909782, 48.456669],
                [27.926805, 48.450993],
                [28.02327, 48.411274],
                [28.08569, 48.35453],
                [28.148108, 48.331833],
                [28.2162, 48.326157],
                [28.261595, 48.297783],
                [28.284292, 48.269413],
                [28.318338, 48.235367],
                [28.363733, 48.241039],
                [28.420477, 48.229691],
                [28.499918, 48.246716],
                [28.550987, 48.246716],
                [28.562336, 48.218342],
                [28.522615, 48.172947],
                [28.505592, 48.150249],
                [28.550987, 48.167274],
                [28.590708, 48.155926],
                [28.590708, 48.12188],
                [28.61908, 48.099182],
                [28.630454, 48.114348],
                [29.111886, 47.83536],
                [29.319261, 47.299641],
                [29.630324, 46.833047],
                [30.010511, 46.521985],
                [30.26973, 46.297329],
                [30.636511, 46.192722],
                [30.272486, 45.878372],
                [29.933413, 45.786964],
                [29.833176, 45.76733],
                [29.704094, 45.54034],
                [29.739758, 45.46917],
                [29.81958, 45.427177],
                [29.886875, 45.391762],
                [29.914429, 45.275307],
                [29.782625, 45.074474],
                [29.661186, 44.932121],
                [29.465246, 44.912395],
                [29.293413, 44.892704],
                [29.121901, 44.782722],
                [28.889912, 44.505024],
                [28.776777, 44.214794],
                [28.733549, 43.860443],
                [28.71377, 43.760048],
                [28.684202, 43.609257],
                [28.649357, 43.493336],
                [28.531759, 43.454498],
                [28.390722, 43.467091],
                [28.238302, 43.408875],
                [28.057831, 43.493681],
                [27.923021, 43.493681],
                [27.788211, 43.601529],
                [27.572515, 43.628491],
                [27.329857, 43.871149],
                [26.925426, 44.00596],
                [26.776525, 44.230182],
                [26.609869, 44.171391],
                [25.752756, 43.824276],
                [25.58177, 43.797596],
                [24.958679, 43.955158],
                [24.824009, 43.960873],
                [24.513187, 43.843285],
                [24.360037, 43.829578],
                [23.841969, 44.007046],
                [23.614552, 44.007896],
                [23.267721, 43.922997],
                [23.148039, 43.91238],
                [23.126774, 43.997288],
                [23.23192, 44.140583],
                [23.074047, 44.188362],
                [22.897665, 44.280064],
                [22.709625, 44.363571],
                [22.64423, 44.512272],
                [22.788479, 44.60257],
                [22.94603, 44.597256],
                [22.894766, 44.655689],
                [22.61319, 44.767269],
                [22.499565, 44.708824],
                [22.403072, 44.581318],
                [22.336561, 44.528206],
                [22.150173, 44.63575],
                [22.062567, 44.698193],
                [21.795515, 44.724754],
                [21.786978, 44.809772],
                [21.681316, 44.809772],
                [21.571152, 44.825722],
                [21.547913, 44.90012],
                [21.627806, 44.942444],
                [21.749432, 44.974533],
                [21.641956, 45.011765],
                [21.559444, 45.038414],
                [21.615437, 45.103916],
                [21.701057, 45.161007],
                [21.669695, 45.224987],
                [21.5322, 45.278149],
                [21.28277, 45.341507],
                [20.962351, 45.553429],
                [20.988197, 45.59306],
                [20.941923, 45.646439],
                [20.975714, 45.806602],
                [20.884752, 45.82262],
                [20.798712, 45.929443],
                [20.542887, 46.009583],
                [20.518398, 46.148479],
                [20.597698, 46.147732],
                [20.716248, 46.193775],
                [21.041912, 46.214993],
                [21.204279, 46.287247],
                [21.543386, 46.542217],
                [21.826668, 46.920502],
                [22.262337, 47.573341],
                [22.523676, 47.757065],
                [23.029961, 47.877045],
                [23.232592, 48.029198],
                [23.293867, 48.067455],
                [23.648056, 48.013096],
                [23.756584, 48.061443],
                [24.187349, 47.974476],
                [24.24746, 47.970897],
                [24.412692, 47.961058]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Germany",
          "density": "239",
          "cartodb_id": 30,
          "created_at": "2013-12-02T19:45:13+01:00",
          "updated_at": "2013-12-02T19:45:13+01:00"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [14.27361, 53.934742],
                [14.377831, 53.923733],
                [14.40907, 53.830177],
                [14.34909, 53.843426],
                [14.272015, 53.860447],
                [14.232381, 53.882454],
                [14.031434, 53.846691],
                [13.981067, 53.965595],
                [13.982075, 54.061378],
                [14.27361, 53.934742]
              ]
            ],
            [
              [
                [14.6766, 53.893749],
                [14.662734, 53.835686],
                [14.537523, 53.846691],
                [14.451559, 53.832935],
                [14.430905, 53.926483],
                [14.508926, 53.931992],
                [14.628502, 53.931992],
                [14.6766, 53.893749]
              ]
            ],
            [
              [
                [8.8545, 47.799961],
                [8.535692, 47.710316],
                [8.615914, 47.620678],
                [8.456723, 47.611408],
                [8.331659, 47.660854],
                [8.076324, 47.589771],
                [7.95471, 47.633038],
                [7.832073, 47.580502],
                [7.710246, 47.620678],
                [7.649627, 47.725765],
                [7.746782, 48.041203],
                [7.7058, 48.075245],
                [7.739692, 48.208309],
                [7.884306, 48.375496],
                [7.908243, 48.536549],
                [7.932742, 48.632591],
                [8.361959, 48.995319],
                [8.089372, 49.08218],
                [7.874122, 49.075981],
                [7.669094, 49.116306],
                [7.626476, 49.200081],
                [7.521042, 49.203186],
                [7.488662, 49.181465],
                [7.178404, 49.153542],
                [7.158078, 49.218708],
                [6.980906, 49.193874],
                [6.829165, 49.31876],
                [6.636707, 49.462303],
                [6.603117, 49.621159],
                [6.715019, 49.685902],
                [6.734586, 49.815399],
                [6.525142, 49.858585],
                [6.340263, 49.998951],
                [6.312177, 50.134426],
                [6.502018, 50.303841],
                [6.485089, 50.514572],
                [6.35821, 50.67873],
                [6.203239, 50.758572],
                [6.27759, 50.883587],
                [6.21333, 50.981468],
                [6.131559, 50.997787],
                [6.167907, 51.041309],
                [6.273839, 51.101158],
                [6.379871, 51.161022],
                [6.292901, 51.215458],
                [6.383284, 51.339302],
                [6.425942, 51.406075],
                [6.357486, 51.537643],
                [6.320525, 51.65683],
                [6.151562, 51.738644],
                [6.183032, 51.820499],
                [6.336338, 51.855408],
                [6.415042, 51.896915],
                [6.585109, 51.836872],
                [6.884033, 51.904724],
                [7.02828, 51.973358],
                [7.085898, 52.142704],
                [7.271382, 52.235615],
                [7.27269, 52.383255],
                [7.200515, 52.459846],
                [7.115585, 52.432491],
                [6.917232, 52.492672],
                [6.9305, 52.575306],
                [6.981754, 52.656902],
                [7.238455, 52.640476],
                [7.276559, 52.821236],
                [7.431668, 52.983688],
                [7.363603, 53.180973],
                [7.393409, 53.247112],
                [7.184373, 53.33363],
                [7.240996, 53.527119],
                [7.369303, 53.608643],
                [7.536056, 53.621399],
                [8.040045, 53.649067],
                [8.172203, 53.654507],
                [8.214686, 53.582664],
                [8.302397, 53.460907],
                [8.232746, 53.389622],
                [8.378651, 53.338715],
                [8.445598, 53.369263],
                [8.464436, 53.455818],
                [8.392665, 53.547493],
                [8.509315, 53.542404],
                [8.705467, 53.466],
                [8.632321, 53.629021],
                [8.743206, 53.817654],
                [9.052142, 53.766651],
                [9.328703, 53.807449],
                [9.060761, 53.883961],
                [9.022635, 53.986012],
                [9.150004, 54.031944],
                [9.036318, 54.082989],
                [8.982451, 54.149376],
                [9.05696, 54.185127],
                [9.087676, 54.246422],
                [8.784389, 54.210659],
                [8.75761, 54.292412],
                [9.056087, 54.348621],
                [9.154265, 54.435528],
                [8.945732, 54.624775],
                [8.79965, 54.778324],
                [8.819761, 54.904335],
                [8.872263, 54.897312],
                [9.699646, 54.955265],
                [9.813927, 54.897015],
                [10.068108, 54.743263],
                [10.190351, 54.609417],
                [10.181674, 54.507118],
                [10.068472, 54.428371],
                [10.268592, 54.450867],
                [10.345392, 54.353725],
                [10.416541, 54.388889],
                [10.543903, 54.379288],
                [10.649638, 54.309006],
                [10.852259, 54.275948],
                [11.24227, 54.330223],
                [11.27667, 54.236603],
                [11.24503, 54.16967],
                [11.219551, 54.113628],
                [10.920381, 53.980904],
                [11.062584, 53.894157],
                [11.355042, 53.955387],
                [11.406153, 53.873756],
                [11.611486, 53.876263],
                [11.768683, 54.052357],
                [12.244738, 54.113628],
                [12.515549, 54.218914],
                [12.856182, 54.338398],
                [12.968464, 54.2873],
                [13.146802, 54.363956],
                [13.229475, 54.282188],
                [13.267429, 54.210659],
                [13.552832, 54.08046],
                [13.63468, 54.047218],
                [13.871279, 54.101139],
                [13.941446, 54.04726],
                [13.96799, 53.894157],
                [13.984163, 53.792149],
                [14.053834, 53.746262],
                [14.213405, 53.690182],
                [14.380527, 53.700378],
                [14.39324, 53.641766],
                [14.483855, 53.63039],
                [14.636819, 53.611187],
                [14.740725, 53.555138],
                [14.698967, 53.641766],
                [14.768965, 53.710571],
                [14.760604, 53.776855],
                [14.686189, 53.835503],
                [14.6766, 53.893749],
                [14.671076, 53.927322],
                [15.089422, 53.996536],
                [15.283346, 54.019489],
                [15.515353, 54.087975],
                [16.102024, 54.145374],
                [16.318344, 54.216579],
                [16.676912, 54.432007],
                [17.179565, 54.56628],
                [17.861277, 54.702435],
                [18.483112, 54.741093],
                [18.503161, 54.733254],
                [17.811925, 53.834292],
                [17.919773, 53.683305],
                [16.92757, 53.144064],
                [16.496178, 53.165634],
                [16.517747, 52.496975],
                [16.625595, 52.281279],
                [17.143266, 52.108722],
                [17.747216, 52.108722],
                [17.962912, 51.612621],
                [18.351165, 51.418494],
                [18.760988, 50.447862],
                [18.471546, 50.025015],
                [18.391432, 50.038227],
                [18.251699, 50.119522],
                [18.086159, 50.047081],
                [17.954321, 50.052948],
                [17.948372, 50.152042],
                [17.949236, 50.265911],
                [17.800781, 50.227951],
                [17.882423, 50.35524],
                [17.56341, 50.352695],
                [17.446993, 50.40696],
                [17.218088, 50.493797],
                [17.089252, 50.504665],
                [17.088104, 50.439518],
                [17.215197, 50.303875],
                [17.030722, 50.249641],
                [16.931686, 50.165596],
                [16.788618, 50.198124],
                [16.655087, 50.368977],
                [16.408066, 50.480221],
                [16.50841, 50.575233],
                [16.602255, 50.58609],
                [16.64649, 50.640404],
                [16.55681, 50.721889],
                [16.441448, 50.732761],
                [16.413872, 50.702881],
                [16.204521, 50.672993],
                [16.206596, 50.749065],
                [16.08115, 50.732761],
                [16.022007, 50.814281],
                [15.766463, 50.838261],
                [15.675827, 50.849609],
                [15.512417, 50.923008],
                [15.470676, 51.039948],
                [15.37555, 51.080757],
                [15.21295, 51.072594],
                [15.184624, 51.034508],
                [15.211932, 51.001865],
                [15.200931, 50.933884],
                [15.032544, 50.933884],
                [14.968475, 50.882229],
                [14.813987, 50.914852],
                [14.800047, 51.039948],
                [14.661462, 51.094357],
                [14.465324, 51.06171],
                [14.592433, 50.963799],
                [14.478413, 50.925728],
                [14.350149, 50.882229],
                [14.114322, 50.860481],
                [14.058445, 50.784389],
                [13.727822, 50.773518],
                [13.676928, 50.675713],
                [13.597818, 50.708317],
                [13.43666, 50.68074],
                [13.39306, 50.57795],
                [13.221165, 50.572521],
                [13.186008, 50.491077],
                [12.987333, 50.491077],
                [12.797515, 50.400536],
                [12.665282, 50.415096],
                [12.548414, 50.33371],
                [12.439023, 50.257778],
                [12.456005, 50.232864],
                [12.572054, 50.062611],
                [12.757827, 50.018593],
                [12.688014, 49.905819],
                [12.651974, 49.723118],
                [12.72337, 49.684555],
                [12.739963, 49.579548],
                [12.84944, 49.501556],
                [13.064469, 49.382545],
                [13.14892, 49.41489],
                [13.406775, 49.183144],
                [13.600607, 49.106506],
                [13.683398, 49.016155],
                [13.781816, 49.053864],
                [13.855733, 48.973072],
                [13.949145, 48.956921],
                [14.150347, 48.672073],
                [13.843703, 48.55513],
                [13.647358, 48.623299],
                [13.587808, 48.610905],
                [13.570317, 48.45911],
                [13.497288, 48.381687],
                [13.061913, 48.248554],
                [12.897852, 48.146408],
                [13.13997, 47.889633],
                [13.051193, 47.756683],
                [13.201744, 47.750496],
                [13.226663, 47.679394],
                [13.182581, 47.524879],
                [13.145685, 47.500175],
                [12.947391, 47.580502],
                [12.969055, 47.654671],
                [12.903743, 47.707218],
                [12.649758, 47.679394],
                [12.561845, 47.741226],
                [12.366941, 47.725765],
                [12.325696, 47.645401],
                [11.763231, 47.629951],
                [11.707096, 47.552689],
                [11.563496, 47.546513],
                [11.543672, 47.487812],
                [11.37401, 47.441471],
                [11.106603, 47.432209],
                [11.080283, 47.497086],
                [11.03847, 47.561966],
                [10.816867, 47.602139],
                [10.699069, 47.577408],
                [10.59199, 47.595959],
                [10.60679, 47.472363],
                [10.403173, 47.317932],
                [10.306602, 47.317932],
                [10.341638, 47.405628],
                [10.224617, 47.422943],
                [10.229475, 47.497086],
                [10.141933, 47.518703],
                [10.095658, 47.580502],
                [9.983644, 47.583595],
                [9.940373, 47.633038],
                [9.866776, 47.571232],
                [9.700788, 47.577412],
                [9.402222, 47.694859],
                [9.01905, 47.697945],
                [8.95167, 47.738136],
                [8.862229, 47.731945],
                [8.8545, 47.799961]
              ]
            ],
            [
              [
                [22.534664, 55.065842],
                [22.65029, 55.065842],
                [22.7505, 55.073551],
                [22.789043, 55.011883],
                [22.835293, 54.965633],
                [22.920088, 54.950218],
                [22.997171, 54.919384],
                [23.004881, 54.857716],
                [23.028006, 54.803757],
                [22.927795, 54.749798],
                [22.896961, 54.68042],
                [22.866127, 54.587917],
                [22.873837, 54.510834],
                [22.889254, 54.449165],
                [22.9027, 54.431873],
                [21.570406, 54.386517],
                [20.559933, 54.438431],
                [20.181202, 54.440914],
                [20.392309, 54.538948],
                [20.530376, 54.580978],
                [20.397896, 54.592018],
                [20.209368, 54.56443],
                [20.141783, 54.625145],
                [20.085474, 54.542351],
                [20.036911, 54.547871],
                [20.066833, 54.768703],
                [20.146235, 54.866829],
                [20.330761, 54.862614],
                [21.000814, 54.834949],
                [21.188868, 54.828613],
                [21.341007, 54.862431],
                [21.395607, 54.976551],
                [21.317009, 55.106216],
                [21.427517, 55.128422],
                [21.396786, 55.183323],
                [21.365986, 55.238354],
                [21.524853, 55.281681],
                [21.578814, 55.297096],
                [21.601938, 55.227722],
                [21.64048, 55.204597],
                [21.7484, 55.204597],
                [21.84861, 55.150639],
                [21.964237, 55.119804],
                [22.19549, 55.08897],
                [22.234034, 55.042717],
                [22.334244, 55.065842],
                [22.534664, 55.065842]
              ]
            ]
          ]
        }
      }
    ]
  }