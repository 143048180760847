import { Row, Col } from 'reactstrap';
import { Parallax } from 'react-parallax';
import Trees1 from '../app/assets/img/camp/Trees.png';
import Mountain from '../app/assets/img/camp/Mountain.png';

const CampScene = () => {
    return(
        <Parallax 
            bgImageStyle={{ height: '100vh', width:'100vw' }}
            bgImageAlt='Mountain' 
            bgImage={Mountain}
            strength={500}
            style={{ backgroundImage:'radial-gradient(#FF0000, #FFFF00, #87CEEB)' }}
            blur={0}>
                <Parallax 
                    bgImageStyle={{ height: '100vh', width:'100vw' }}
                    bgImageAlt='Tree Line' 
                    bgImage={Trees1}
                    strength={200}
                    blur={0}>
                        <Row>
                            <Col>
                                <p></p>
                                <p></p>
                                <p></p>
                                <h1 className='campTitle'>Campamento!</h1>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                            </Col>
                        </Row>
                </Parallax>
        </Parallax>
    );
}

export default CampScene;