import CampMap from '../app/assets/img/camp/cartoonMap.jpg';
import { Container, Row, Col } from 'reactstrap';
import { Parallax } from "react-parallax";
import '../App.css';

const CampOne = () => (  
        <Parallax 
       // bgImageStyle={{ opacity: '0.5' }}
        bgImageAlt='Camp Screen Background' 
        bgImage={CampMap}
        strength={300}
        blur={0}>
            <Container>
                <Row>
                    <Col>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                    </Col>
                </Row>
            </Container>
        </Parallax>
);

export default CampOne;