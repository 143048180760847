import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navigator from './components/Navigator';
import DirectoryDetail from './pages/DirectoryDetail';
import Footer from './components/Footer';
import Directory from './pages/Directory';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import { useEffect } from 'react';
import './App.css';

function App() {

  const ScrollToTopOnPageChange = () => {
    const { pathname }  = useLocation();

    useEffect( () => {
      window.scrollTo(0, 0);
    }, [pathname])
  }

  return (
    <div className="App">
      <ScrollToTopOnPageChange />
      <Navigator />
      <Routes>
        <Route path='/' element={<Navigate to='/home' />} />
        <Route path='/home' element={<Home />} />
        <Route path='/directory' element={<Directory />} />
        <Route path='/directory/:detail' element={<DirectoryDetail />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
