import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import { southAmericaData } from '../app/shared/southAmericaData';
//import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { ElSalvadorData } from '../app/shared/ElSalvadorData';
import { CaribbeanData } from '../app/shared/CaribbeanData';
import { GuatemalaData } from '../app/shared/GuatemalaData';
import { NicaraguaData } from '../app/shared/NicaraguaData';
import { CostaRicaData } from '../app/shared/CostaRicaData';
import { HondurasData } from '../app/shared/HondurasData';
//import icon from 'leaflet/dist/images/marker-icon.png';
import { statesData } from '../app/shared/StatesData';
import { mexicoData } from '../app/shared/mexicoData';
import { CanadaData } from '../app/shared/CanadaData';
import { EuropeData } from '../app/shared/EuropeData';
import { BelizeData } from '../app/shared/BelizeData';
import { PanamaData } from '../app/shared/PanamaData';
import { IndiaData } from '../app/shared/IndiaData';
import { Container, Row, Col } from 'reactstrap';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MyMap = () => {
    // let DefaultIcon = L.icon({
    //     iconUrl: icon,
    //     shadowUrl: iconShadow
    // });
    
    // L.Marker.prototype.options.icon = DefaultIcon;

    const position = [37.8, -96];

    function getColor(d) {
        return d > 1000 ? '#400034' :
               d > 500  ? '#810C69' :
               d > 200  ? '#C0009B' :
               d > 100  ? '#E600BB' :
               d > 50   ? '#FF00CD' :
               d > 20   ? '#FF31D6' :
               d > 10   ? '#FF58DF' :
                          '#FF7EE5'
    };

    function style(feature) {
        return {
            fillColor: getColor(feature.properties.density),
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.7
        };
    }

    function highlightFeature(e) {
        const layer = e.target;
        layer.setStyle({
            weight: 5,
            color: '#0068FF',
            dashArray: '',
            fillOpacity: 0.7
        });
    
        layer.bringToFront();
        layer.bindTooltip(`<strong>${layer.feature.properties.name}</strong>: ${layer.feature.properties.density}`, { permanent: true, direction: 'auto', className: 'map-tooltip' }).openTooltip();
        //info.update(layer.feature.properties);
    }

    function resetHighlight(e, feature) {
        const layer = e.target;
        const originalStyle = layer.feature ? style(layer.feature) : {
            fillColor: getColor(feature.properties.density),
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.7}
        layer.setStyle(originalStyle);
        layer.unbindTooltip();
       // info.update(feature);
    }

    function zoomToFeature(e) {
        const map = e.target._map;
        map.fitBounds(e.target.getBounds());
    }

    function CustomControl() {
        const map = useMap();
        const info = L.control();

        info.onAdd = function () {
            this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
            this.update();
            return this._div;
        };

        // method that we will use to update the control based on feature properties passed
        info.update = function (props) {
            this._div.innerHTML = '<h4>Damas por Estado</h4>' +  (props ?
                '<b>' + props.name + '</b><br />' + props.density + ' people / mi<sup>2</sup>'
                : 'Hover over a state');
        };

        info.addTo(map);
    }

    return(
        <Container>
            <Row>
                <Col style={{ padding:'0px'}}>
                    <MapContainer center={position} zoom={4} scrollWheelZoom={true}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <GeoJSON 
                            data={[
                                    statesData, 
                                    mexicoData, 
                                    southAmericaData, 
                                    CaribbeanData, 
                                    CanadaData, 
                                    EuropeData, 
                                    ElSalvadorData,
                                    BelizeData,
                                    GuatemalaData,
                                    HondurasData,
                                    NicaraguaData,
                                    PanamaData,
                                    CostaRicaData,
                                    IndiaData
                                ]}  
                            style={style} 
                            onEachFeature={(feature, layer) => {
                                layer.on({
                                    mouseover:highlightFeature,
                                    mouseout: resetHighlight,
                                    click: zoomToFeature
                                })}} /> 
                        <CustomControl />
                    </MapContainer>
                </Col>
            </Row>
        </Container>
    );
}

export default MyMap;