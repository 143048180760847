import Camp1 from '../assets/img/camp/Camp1.jpg';
import Camp2 from '../assets/img/camp/Camp2.jpg';
import Camp3 from '../assets/img/camp/campAerial.png';
import Camp2010_1 from '../assets/img/camp/camp2010/camp1.jpg';
import Camp2010_2 from '../assets/img/camp/camp2010/camp2.jpg';
import Camp2010_3 from '../assets/img/camp/camp2010/camp3.jpg';
import Camp2010_4 from '../assets/img/camp/camp2010/camp4.jpg';
import Camp2010_5 from '../assets/img/camp/camp2010/camp5.jpg';
import Camp2010_6 from '../assets/img/camp/camp2010/camp6.jpg';
import Camp2010_7 from '../assets/img/camp/camp2010/camp7.jpg';
import Camp2010_8 from '../assets/img/camp/camp2010/camp8.jpg';
import Camp2010_9 from '../assets/img/camp/camp2010/camp9.jpg';
import Camp2010_10 from '../assets/img/camp/camp2010/camp10.jpg';
import Camp2010_11 from '../assets/img/camp/camp2010/camp11.jpg';
import Camp2010_12 from '../assets/img/camp/camp2010/camp12.jpg';
import Camp2010_13 from '../assets/img/camp/camp2010/camp13.jpg';
import Camp2010_14 from '../assets/img/camp/camp2010/camp14.jpg';
import Camp2010_15 from '../assets/img/camp/camp2010/camp15.jpg';
import Camp2010_16 from '../assets/img/camp/camp2010/camp16.jpg';
import Camp2010_17 from '../assets/img/camp/camp2010/camp17.jpg';
import Camp2010_18 from '../assets/img/camp/camp2010/camp18.jpg';
import Camp2010_19 from '../assets/img/camp/camp2010/camp19.jpg';
import Camp2010_20 from '../assets/img/camp/camp2010/camp20.jpg';
import Camp2010_21 from '../assets/img/camp/camp2010/camp21.jpg';
import Camp2010_22 from '../assets/img/camp/camp2010/camp22.jpg';
import Camp2010_23 from '../assets/img/camp/camp2010/camp23.jpg';
import Camp2010_24 from '../assets/img/camp/camp2010/camp24.jpg';
import Camp2010_25 from '../assets/img/camp/camp2010/camp25.jpg';
import Camp2010_26 from '../assets/img/camp/camp2010/camp26.jpg';
import Camp2010_27 from '../assets/img/camp/camp2010/camp27.jpg';
import Camp2010_28 from '../assets/img/camp/camp2010/camp28.jpg';
import Camp2010_29 from '../assets/img/camp/camp2010/camp29.jpg';
import Camp2010_30 from '../assets/img/camp/camp2010/camp30.jpg';
import Camp2010_31 from '../assets/img/camp/camp2010/camp31.jpg';
import Camp2010_32 from '../assets/img/camp/camp2010/camp32.jpg';
import Camp2010_33 from '../assets/img/camp/camp2010/camp33.jpg';
import Camp2010_34 from '../assets/img/camp/camp2010/camp34.jpg';
import Camp2010_35 from '../assets/img/camp/camp2010/camp35.jpg';
import Camp2010_36 from '../assets/img/camp/camp2010/camp36.jpg';
import Camp2010_37 from '../assets/img/camp/camp2010/camp37.jpg';
import Camp2010_38 from '../assets/img/camp/camp2010/camp38.jpg';
import Camp2010_39 from '../assets/img/camp/camp2010/camp39.jpg';
import Camp2010_40 from '../assets/img/camp/camp2010/camp40.jpg';
import Camp2010_41 from '../assets/img/camp/camp2010/camp41.jpg';
import Camp2010_42 from '../assets/img/camp/camp2010/camp42.jpg';
import Camp2010_43 from '../assets/img/camp/camp2010/camp43.jpg';
import Camp2010_44 from '../assets/img/camp/camp2010/camp44.jpg';
import Camp2010_45 from '../assets/img/camp/camp2010/camp45.jpg';
import Camp2010_46 from '../assets/img/camp/camp2010/camp46.jpg';
import Camp2010_47 from '../assets/img/camp/camp2010/camp47.jpg';
import Camp2010_48 from '../assets/img/camp/camp2010/camp48.jpg';
import Camp2010_49 from '../assets/img/camp/camp2010/camp49.jpg';
import Camp2010_50 from '../assets/img/camp/camp2010/camp50.jpg';
import Camp2010_51 from '../assets/img/camp/camp2010/camp51.jpg';
import Camp2010_52 from '../assets/img/camp/camp2010/camp52.jpg';
import Camp2010_53 from '../assets/img/camp/camp2010/camp53.jpg';
import Camp2010_54 from '../assets/img/camp/camp2010/camp54.jpg';
import Camp2010_55 from '../assets/img/camp/camp2010/camp55.jpg';
import Camp2010_56 from '../assets/img/camp/camp2010/camp56.jpg';
import Camp2010_57 from '../assets/img/camp/camp2010/camp57.jpg';
import Camp2012_1 from '../assets/img/camp/camp2012/camp1.jpg';
import Camp2012_2 from '../assets/img/camp/camp2012/camp2.jpg';
import Camp2012_3 from '../assets/img/camp/camp2012/camp3.jpg';
import Camp2012_4 from '../assets/img/camp/camp2012/camp4.jpg';
import Camp2012_5 from '../assets/img/camp/camp2012/camp5.jpg';
import Camp2012_6 from '../assets/img/camp/camp2012/camp6.jpg';
import Camp2012_7 from '../assets/img/camp/camp2012/camp7.jpg';
import Camp2012_8 from '../assets/img/camp/camp2012/camp8.jpg';
import Camp2012_9 from '../assets/img/camp/camp2012/camp9.jpg';
import Camp2012_10 from '../assets/img/camp/camp2012/camp10.jpg';
import Camp2012_11 from '../assets/img/camp/camp2012/camp11.jpg';
import Camp2012_12 from '../assets/img/camp/camp2012/camp12.jpg';
import Camp2012_13 from '../assets/img/camp/camp2012/camp13.jpg';
import Camp2012_14 from '../assets/img/camp/camp2012/camp14.jpg';
import Camp2012_15 from '../assets/img/camp/camp2012/camp15.jpg';
import Camp2012_16 from '../assets/img/camp/camp2012/camp16.jpg';
import Camp2012_17 from '../assets/img/camp/camp2012/camp17.jpg';
import Camp2012_18 from '../assets/img/camp/camp2012/camp18.jpg';
import Camp2012_19 from '../assets/img/camp/camp2012/camp19.jpg';
import Camp2012_20 from '../assets/img/camp/camp2012/camp20.jpg';
import Camp2012_21 from '../assets/img/camp/camp2012/camp21.jpg';
import Camp2012_22 from '../assets/img/camp/camp2012/camp22.jpg';
import Camp2012_23 from '../assets/img/camp/camp2012/camp23.jpg';
import Camp2012_24 from '../assets/img/camp/camp2012/camp24.jpg';
import Camp2012_25 from '../assets/img/camp/camp2012/camp25.jpg';
import Camp2012_26 from '../assets/img/camp/camp2012/camp26.jpg';
import Camp2012_27 from '../assets/img/camp/camp2012/camp27.jpg';
import Camp2012_28 from '../assets/img/camp/camp2012/camp28.jpg';
import Camp2012_29 from '../assets/img/camp/camp2012/camp29.jpg';
import Camp2012_30 from '../assets/img/camp/camp2012/camp30.jpg';
import Camp2012_31 from '../assets/img/camp/camp2012/camp31.jpg';
import Camp2012_32 from '../assets/img/camp/camp2012/camp32.jpg';
import Camp2012_33 from '../assets/img/camp/camp2012/camp33.jpg';
import Camp2012_34 from '../assets/img/camp/camp2012/camp34.jpg';
import Camp2012_35 from '../assets/img/camp/camp2012/camp35.jpg';
import Camp2012_36 from '../assets/img/camp/camp2012/camp36.jpg';
import Camp2012_37 from '../assets/img/camp/camp2012/camp37.jpg';
import Camp2012_38 from '../assets/img/camp/camp2012/camp38.jpg';
import Camp2012_39 from '../assets/img/camp/camp2012/camp39.jpg';
import Camp2012_40 from '../assets/img/camp/camp2012/camp40.jpg';
import Camp2012_41 from '../assets/img/camp/camp2012/camp41.jpg';
import Camp2012_42 from '../assets/img/camp/camp2012/camp42.jpg';
import Camp2012_43 from '../assets/img/camp/camp2012/camp43.jpg';
import Camp2012_44 from '../assets/img/camp/camp2012/camp44.jpg';
import Camp2012_45 from '../assets/img/camp/camp2012/camp45.jpg';
import Camp2012_46 from '../assets/img/camp/camp2012/camp46.jpg';
import Camp2012_47 from '../assets/img/camp/camp2012/camp47.jpg';
import Camp2012_48 from '../assets/img/camp/camp2012/camp48.jpg';
import Camp2012_49 from '../assets/img/camp/camp2012/camp49.jpg';
import Camp2024_1 from '../assets/img/camp/camp2024/camp01.jpg';
import Camp2024_2 from '../assets/img/camp/camp2024/camp02.jpg';
import Camp2024_3 from '../assets/img/camp/camp2024/camp03.jpg';
import Camp2024_4 from '../assets/img/camp/camp2024/camp04.jpg';
import Camp2024_5 from '../assets/img/camp/camp2024/camp05.jpg';
import Camp2024_6 from '../assets/img/camp/camp2024/camp06.jpg';
import Camp2024_7 from '../assets/img/camp/camp2024/camp07.jpg';
import Camp2024_8 from '../assets/img/camp/camp2024/camp08.jpg';
import Camp2024_9 from '../assets/img/camp/camp2024/camp09.jpg';
import Camp2024_10 from '../assets/img/camp/camp2024/camp10.jpg';
import Camp2024_11 from '../assets/img/camp/camp2024/camp11.jpg';
import Camp2024_12 from '../assets/img/camp/camp2024/camp12.jpg';
import Camp2024_13 from '../assets/img/camp/camp2024/camp13.jpg';
import Camp2024_14 from '../assets/img/camp/camp2024/camp14.jpg';
import Camp2024_15 from '../assets/img/camp/camp2024/camp15.jpg';
import Camp2024_16 from '../assets/img/camp/camp2024/camp16.jpg';
import Camp2024_17 from '../assets/img/camp/camp2024/camp17.jpg';
import Camp2024_18 from '../assets/img/camp/camp2024/camp18.jpg';
import Camp2024_19 from '../assets/img/camp/camp2024/camp19.jpg';
import Camp2024_20 from '../assets/img/camp/camp2024/camp20.jpg';
import Camp2024_21 from '../assets/img/camp/camp2024/camp21.jpg';
import Camp2024_22 from '../assets/img/camp/camp2024/camp22.jpg';
import Camp2024_23 from '../assets/img/camp/camp2024/camp23.jpg';
import Camp2024_24 from '../assets/img/camp/camp2024/camp24.jpg';
import Camp2024_25 from '../assets/img/camp/camp2024/camp25.jpg';
import Camp2024_26 from '../assets/img/camp/camp2024/camp26.jpg';
import Camp2024_27 from '../assets/img/camp/camp2024/camp27.jpg';
import Camp2024_28 from '../assets/img/camp/camp2024/camp28.jpg';
import Camp2024_29 from '../assets/img/camp/camp2024/camp29.jpg';
import Camp2024_30 from '../assets/img/camp/camp2024/camp30.jpg';
import Camp2024_31 from '../assets/img/camp/camp2024/camp31.jpg';
import Camp2024_32 from '../assets/img/camp/camp2024/camp32.jpg';
import Camp2024_33 from '../assets/img/camp/camp2024/camp33.jpg';
import Camp2024_34 from '../assets/img/camp/camp2024/camp34.jpg';
import Camp2024_35 from '../assets/img/camp/camp2024/camp35.jpg';
import Camp2024_36 from '../assets/img/camp/camp2024/camp36.jpg';

export const CampArray = [
    {
        id: 1,
        campTitle: 'Camp 2010',
        mainCampImage: Camp1,
        campImages: [
            {
                imageId: 1,
                imageTitle: 'Camp 2010 1',
                image: Camp2010_1
            },
            {
                imageId: 2,
                imageTitle: 'Camp 2010 2',
                image: Camp2010_2
            },
            {
                imageId: 3,
                imageTitle: 'Camp 2010 3',
                image: Camp2010_3
            },
            {
                imageId: 4,
                imageTitle: 'Camp 2010 4',
                image: Camp2010_4
            },
            {
                imageId: 5,
                imageTitle: 'Camp 2010 5',
                image: Camp2010_5
            },
            {
                imageId: 6,
                imageTitle: 'Camp 2010 6',
                image: Camp2010_6
            },
            {
                imageId: 7,
                imageTitle: 'Camp 2010 7',
                image: Camp2010_7
            },
            {
                imageId: 8,
                imageTitle: 'Camp 2010 8',
                image: Camp2010_8
            },
            {
                imageId: 9,
                imageTitle: 'Camp 2010 9',
                image: Camp2010_9
            },
            {
                imageId: 10,
                imageTitle: 'Camp 2010 10',
                image: Camp2010_10
            },
            {
                imageId: 11,
                imageTitle: 'Camp 2010 11',
                image: Camp2010_11
            },
            {
                imageId: 12,
                imageTitle: 'Camp 2010 12',
                image: Camp2010_12
            },
            {
                imageId: 13,
                imageTitle: 'Camp 2010 13',
                image: Camp2010_13
            },
            {
                imageId: 14,
                imageTitle: 'Camp 2010 14',
                image: Camp2010_14
            },
            {
                imageId: 15,
                imageTitle: 'Camp 2010 15',
                image: Camp2010_15
            },
            {
                imageId: 16,
                imageTitle: 'Camp 2010 16',
                image: Camp2010_16
            },
            {
                imageId: 17,
                imageTitle: 'Camp 2010 17',
                image: Camp2010_17
            },
            {
                imageId: 18,
                imageTitle: 'Camp 2010 18',
                image: Camp2010_18
            },
            {
                imageId: 19,
                imageTitle: 'Camp 2010 19',
                image: Camp2010_19
            },
            {
                imageId: 20,
                imageTitle: 'Camp 2010 20',
                image: Camp2010_20
            },
            {
                imageId: 21,
                imageTitle: 'Camp 2010 21',
                image: Camp2010_21
            },
            {
                imageId: 22,
                imageTitle: 'Camp 2010 22',
                image: Camp2010_22
            },
            {
                imageId: 23,
                imageTitle: 'Camp 2010 23',
                image: Camp2010_23
            },
            {
                imageId: 24,
                imageTitle: 'Camp 2010 24',
                image: Camp2010_24
            },
            {
                imageId: 25,
                imageTitle: 'Camp 2010 25',
                image: Camp2010_25
            },
            {
                imageId: 26,
                imageTitle: 'Camp 2010 26',
                image: Camp2010_26
            },
            {
                imageId: 27,
                imageTitle: 'Camp 2010 27',
                image: Camp2010_27
            },
            {
                imageId: 28,
                imageTitle: 'Camp 2010 28',
                image: Camp2010_28
            },
            {
                imageId: 29,
                imageTitle: 'Camp 2010 29',
                image: Camp2010_29
            },
            {
                imageId: 30,
                imageTitle: 'Camp 2010 30',
                image: Camp2010_30
            },
            {
                imageId: 31,
                imageTitle: 'Camp 2010 31',
                image: Camp2010_31
            },
            {
                imageId: 32,
                imageTitle: 'Camp 2010 32',
                image: Camp2010_32
            },
            {
                imageId: 33,
                imageTitle: 'Camp 2010 33',
                image: Camp2010_33
            },
            {
                imageId: 34,
                imageTitle: 'Camp 2010 34',
                image: Camp2010_34
            },
            {
                imageId: 35,
                imageTitle: 'Camp 2010 35',
                image: Camp2010_35
            },
            {
                imageId: 36,
                imageTitle: 'Camp 2010 36',
                image: Camp2010_36
            },
            {
                imageId: 37,
                imageTitle: 'Camp 2010 37',
                image: Camp2010_37
            },
            {
                imageId: 38,
                imageTitle: 'Camp 2010 38',
                image: Camp2010_38
            },
            {
                imageId: 39,
                imageTitle: 'Camp 2010 39',
                image: Camp2010_39
            },
            {
                imageId: 40,
                imageTitle: 'Camp 2010 40',
                image: Camp2010_40
            },
            {
                imageId: 41,
                imageTitle: 'Camp 2010 41',
                image: Camp2010_41
            },
            {
                imageId: 42,
                imageTitle: 'Camp 2010 42',
                image: Camp2010_42
            },
            {
                imageId: 43,
                imageTitle: 'Camp 2010 43',
                image: Camp2010_43
            },
            {
                imageId: 44,
                imageTitle: 'Camp 2010 44',
                image: Camp2010_44
            },
            {
                imageId: 45,
                imageTitle: 'Camp 2010 45',
                image: Camp2010_45
            },
            {
                imageId: 46,
                imageTitle: 'Camp 2010 46',
                image: Camp2010_46
            },
            {
                imageId: 47,
                imageTitle: 'Camp 2010 47',
                image: Camp2010_47
            },
            {
                imageId: 48,
                imageTitle: 'Camp 2010 48',
                image: Camp2010_48
            },
            {
                imageId: 49,
                imageTitle: 'Camp 2010 49',
                image: Camp2010_49
            },
            {
                imageId: 50,
                imageTitle: 'Camp 2010 50',
                image: Camp2010_50
            },
            {
                imageId: 51,
                imageTitle: 'Camp 2010 51',
                image: Camp2010_51
            },
            {
                imageId: 52,
                imageTitle: 'Camp 2010 52',
                image: Camp2010_52
            },
            {
                imageId: 53,
                imageTitle: 'Camp 2010 53',
                image: Camp2010_53
            },
            {
                imageId: 54,
                imageTitle: 'Camp 2010 54',
                image: Camp2010_54
            },
            {
                imageId: 55,
                imageTitle: 'Camp 2010 55',
                image: Camp2010_55
            },
            {
                imageId: 56,
                imageTitle: 'Camp 2010 56',
                image: Camp2010_56
            },
            {
                imageId: 57,
                imageTitle: 'Camp 2010 57',
                image: Camp2010_57
            }
        ]
    },
    {
        id: 2,
        campTitle: 'Camp 2012',
        mainCampImage: Camp2,
        campImages: [
            {
                imageId: 1,
                imageTitle: 'Camp 2012 1',
                image: Camp2012_1
            },
            {
                imageId: 2,
                imageTitle: 'Camp 2012 2',
                image: Camp2012_2
            },
            {
                imageId: 3,
                imageTitle: 'Camp 2012 3',
                image: Camp2012_3
            },
            {
                imageId: 4,
                imageTitle: 'Camp 2012 4',
                image: Camp2012_4
            },
            {
                imageId: 5,
                imageTitle: 'Camp 2012 5',
                image: Camp2012_5
            },
            {
                imageId: 6,
                imageTitle: 'Camp 2012 6',
                image: Camp2012_6
            },
            {
                imageId: 7,
                imageTitle: 'Camp 2012 7',
                image: Camp2012_7
            },
            {
                imageId: 8,
                imageTitle: 'Camp 2012 8',
                image: Camp2012_8
            },
            {
                imageId: 9,
                imageTitle: 'Camp 2012 9',
                image: Camp2012_9
            },
            {
                imageId: 10,
                imageTitle: 'Camp 2012 10',
                image: Camp2012_10
            },
            {
                imageId: 11,
                imageTitle: 'Camp 2012 11',
                image: Camp2012_11
            },
            {
                imageId: 12,
                imageTitle: 'Camp 2012 12',
                image: Camp2012_12
            },
            {
                imageId: 13,
                imageTitle: 'Camp 2012 13',
                image: Camp2012_13
            },
            {
                imageId: 14,
                imageTitle: 'Camp 2012 14',
                image: Camp2012_14
            },
            {
                imageId: 15,
                imageTitle: 'Camp 2012 15',
                image: Camp2012_15
            },
            {
                imageId: 16,
                imageTitle: 'Camp 2012 16',
                image: Camp2012_16
            },
            {
                imageId: 17,
                imageTitle: 'Camp 2012 17',
                image: Camp2012_17
            },
            {
                imageId: 18,
                imageTitle: 'Camp 2012 18',
                image: Camp2012_18
            },
            {
                imageId: 19,
                imageTitle: 'Camp 2012 19',
                image: Camp2012_19
            },
            {
                imageId: 20,
                imageTitle: 'Camp 2012 20',
                image: Camp2012_20
            },
            {
                imageId: 21,
                imageTitle: 'Camp 2012 21',
                image: Camp2012_21
            },
            {
                imageId: 22,
                imageTitle: 'Camp 2012 22',
                image: Camp2012_22
            },
            {
                imageId: 23,
                imageTitle: 'Camp 2012 23',
                image: Camp2012_23
            },
            {
                imageId: 24,
                imageTitle: 'Camp 2012 24',
                image: Camp2012_24
            },
            {
                imageId: 25,
                imageTitle: 'Camp 2012 25',
                image: Camp2012_25
            },
            {
                imageId: 26,
                imageTitle: 'Camp 2012 26',
                image: Camp2012_26
            },
            {
                imageId: 27,
                imageTitle: 'Camp 2012 27',
                image: Camp2012_27
            },
            {
                imageId: 28,
                imageTitle: 'Camp 2012 28',
                image: Camp2012_28
            },
            {
                imageId: 29,
                imageTitle: 'Camp 2012 29',
                image: Camp2012_29
            },
            {
                imageId: 30,
                imageTitle: 'Camp 2012 30',
                image: Camp2012_30
            },
            {
                imageId: 31,
                imageTitle: 'Camp 2012 31',
                image: Camp2012_31
            },
            {
                imageId: 32,
                imageTitle: 'Camp 2012 32',
                image: Camp2012_32
            },
            {
                imageId: 33,
                imageTitle: 'Camp 2012 33',
                image: Camp2012_33
            },
            {
                imageId: 34,
                imageTitle: 'Camp 2012 34',
                image: Camp2012_34
            },
            {
                imageId: 35,
                imageTitle: 'Camp 2012 35',
                image: Camp2012_35
            },
            {
                imageId: 36,
                imageTitle: 'Camp 2012 36',
                image: Camp2012_36
            },
            {
                imageId: 37,
                imageTitle: 'Camp 2012 37',
                image: Camp2012_37
            },
            {
                imageId: 38,
                imageTitle: 'Camp 2012 38',
                image: Camp2012_38
            },
            {
                imageId: 39,
                imageTitle: 'Camp 2012 39',
                image: Camp2012_39
            },
            {
                imageId: 40,
                imageTitle: 'Camp 2012 40',
                image: Camp2012_40
            },
            {
                imageId: 41,
                imageTitle: 'Camp 2012 41',
                image: Camp2012_41
            },
            {
                imageId: 42,
                imageTitle: 'Camp 2012 42',
                image: Camp2012_42
            },
            {
                imageId: 43,
                imageTitle: 'Camp 2012 43',
                image: Camp2012_43
            },
            {
                imageId: 44,
                imageTitle: 'Camp 2012 44',
                image: Camp2012_44
            },
            {
                imageId: 45,
                imageTitle: 'Camp 2012 45',
                image: Camp2012_45
            },
            {
                imageId: 46,
                imageTitle: 'Camp 2012 46',
                image: Camp2012_46
            },
            {
                imageId: 47,
                imageTitle: 'Camp 2012 47',
                image: Camp2012_47
            },
            {
                imageId: 48,
                imageTitle: 'Camp 2012 48',
                image: Camp2012_48
            },
            {
                imageId: 49,
                imageTitle: 'Camp 2012 49',
                image: Camp2012_49
            }
        ]
    },
    {
        id: 3,
        campTitle: 'Camp 2024',
        mainCampImage: Camp3,
        campImages: [
            {
                imageId: 1,
                imageTitle: 'Camp 2024 1',
                image: Camp2024_1
            },
            {
                imageId: 2,
                imageTitle: 'Camp 2024 2',
                image: Camp2024_2
            },
            {
                imageId: 3,
                imageTitle: 'Camp 2024 3',
                image: Camp2024_3
            },
            {
                imageId: 4,
                imageTitle: 'Camp 2024 4',
                image: Camp2024_4
            },
            {
                imageId: 5,
                imageTitle: 'Camp 2024 5',
                image: Camp2024_5
            },
            {
                imageId: 6,
                imageTitle: 'Camp 2024 6',
                image: Camp2024_6
            },
            {
                imageId: 7,
                imageTitle: 'Camp 2024 7',
                image: Camp2024_7
            },
            {
                imageId: 8,
                imageTitle: 'Camp 2024 8',
                image: Camp2024_8
            },
            {
                imageId: 9,
                imageTitle: 'Camp 2024 9',
                image: Camp2024_9
            },
            {
                imageId: 10,
                imageTitle: 'Camp 2024 10',
                image: Camp2024_10
            },
            {
                imageId: 11,
                imageTitle: 'Camp 2024 11',
                image: Camp2024_11
            },
            {
                imageId: 12,
                imageTitle: 'Camp 2024 12',
                image: Camp2024_12
            },
            {
                imageId: 13,
                imageTitle: 'Camp 2024 13',
                image: Camp2024_13
            },
            {
                imageId: 14,
                imageTitle: 'Camp 2024 14',
                image: Camp2024_14
            },
            {
                imageId: 15,
                imageTitle: 'Camp 2024 15',
                image: Camp2024_15
            },
            {
                imageId: 16,
                imageTitle: 'Camp 2024 16',
                image: Camp2024_16
            },
            {
                imageId: 17,
                imageTitle: 'Camp 2024 17',
                image: Camp2024_17
            },
            {
                imageId: 18,
                imageTitle: 'Camp 2024 18',
                image: Camp2024_18
            },
            {
                imageId: 19,
                imageTitle: 'Camp 2024 19',
                image: Camp2024_19
            },
            {
                imageId: 20,
                imageTitle: 'Camp 2024 20',
                image: Camp2024_20
            },
            {
                imageId: 21,
                imageTitle: 'Camp 2024 21',
                image: Camp2024_21
            },
            {
                imageId: 22,
                imageTitle: 'Camp 2024 22',
                image: Camp2024_22
            },
            {
                imageId: 23,
                imageTitle: 'Camp 2024 23',
                image: Camp2024_23
            },
            {
                imageId: 24,
                imageTitle: 'Camp 2024 24',
                image: Camp2024_24
            },
            {
                imageId: 25,
                imageTitle: 'Camp 2024 25',
                image: Camp2024_25
            },
            {
                imageId: 26,
                imageTitle: 'Camp 2024 26',
                image: Camp2024_26
            },
            {
                imageId: 27,
                imageTitle: 'Camp 2024 27',
                image: Camp2024_27
            },
            {
                imageId: 28,
                imageTitle: 'Camp 2024 28',
                image: Camp2024_28
            },
            {
                imageId: 29,
                imageTitle: 'Camp 2024 29',
                image: Camp2024_29
            },
            {
                imageId: 30,
                imageTitle: 'Camp 2024 30',
                image: Camp2024_30
            },
            {
                imageId: 31,
                imageTitle: 'Camp 2024 31',
                image: Camp2024_31
            },
            {
                imageId: 32,
                imageTitle: 'Camp 2024 32',
                image: Camp2024_32
            },
            {
                imageId: 33,
                imageTitle: 'Camp 2024 33',
                image: Camp2024_33
            },
            {
                imageId: 34,
                imageTitle: 'Camp 2024 34',
                image: Camp2024_34
            },
            {
                imageId: 35,
                imageTitle: 'Camp 2024 35',
                image: Camp2024_35
            },
            {
                imageId: 36,
                imageTitle: 'Camp 2024 36',
                image: Camp2024_36
            },
        ]
    }    
];