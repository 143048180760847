export const PanamaData = {
    "type": "Feature",
    "properties": {
     "name": "Panama",
     "density": "60",
     "code": "PAN",
     "group": "Countries"
    },
    "geometry": {
     "type": "Polygon",
     "coordinates": [
      [
       [
        -77.881571,
        7.223771
       ],
       [
        -78.214936,
        7.512255
       ],
       [
        -78.429161,
        8.052041
       ],
       [
        -78.182096,
        8.319182
       ],
       [
        -78.435465,
        8.387705
       ],
       [
        -78.622121,
        8.718124
       ],
       [
        -79.120307,
        8.996092
       ],
       [
        -79.557877,
        8.932375
       ],
       [
        -79.760578,
        8.584515
       ],
       [
        -80.164481,
        8.333316
       ],
       [
        -80.382659,
        8.298409
       ],
       [
        -80.480689,
        8.090308
       ],
       [
        -80.00369,
        7.547524
       ],
       [
        -80.276671,
        7.419754
       ],
       [
        -80.421158,
        7.271572
       ],
       [
        -80.886401,
        7.220541
       ],
       [
        -81.059543,
        7.817921
       ],
       [
        -81.189716,
        7.647906
       ],
       [
        -81.519515,
        7.70661
       ],
       [
        -81.721311,
        8.108963
       ],
       [
        -82.131441,
        8.175393
       ],
       [
        -82.390934,
        8.292362
       ],
       [
        -82.820081,
        8.290864
       ],
       [
        -82.850958,
        8.073823
       ],
       [
        -82.965783,
        8.225028
       ],
       [
        -82.913176,
        8.423517
       ],
       [
        -82.829771,
        8.626295
       ],
       [
        -82.868657,
        8.807266
       ],
       [
        -82.719183,
        8.925709
       ],
       [
        -82.927155,
        9.07433
       ],
       [
        -82.932891,
        9.476812
       ],
       [
        -82.546196,
        9.566135
       ],
       [
        -82.187123,
        9.207449
       ],
       [
        -82.207586,
        8.995575
       ],
       [
        -81.808567,
        8.950617
       ],
       [
        -81.714154,
        9.031955
       ],
       [
        -81.439287,
        8.786234
       ],
       [
        -80.947302,
        8.858504
       ],
       [
        -80.521901,
        9.111072
       ],
       [
        -79.9146,
        9.312765
       ],
       [
        -79.573303,
        9.61161
       ],
       [
        -79.021192,
        9.552931
       ],
       [
        -79.05845,
        9.454565
       ],
       [
        -78.500888,
        9.420459
       ],
       [
        -78.055928,
        9.24773
       ],
       [
        -77.729514,
        8.946844
       ],
       [
        -77.353361,
        8.670505
       ],
       [
        -77.474723,
        8.524286
       ],
       [
        -77.242566,
        7.935278
       ],
       [
        -77.431108,
        7.638061
       ],
       [
        -77.753414,
        7.70984
       ],
       [
        -77.881571,
        7.223771
       ]
      ]
     ]
    },
    "_id": "panama"
   }