import validateCampRegistrationForm  from '../utils/validateCampRegistrationForm';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { formatDate } from '../utils/formatDate';
import { useDispatch } from 'react-redux';


const CampRegistrationForm = () => {
    const campPrice = 235;
    let deposit = 0;
    let balance = 0;
    const dispatch = useDispatch();
    const initialFormValues = {
        condado:'',
        firstname: '',
        lastname: '',
        age: '',
        maritalStatus: '',
        phone: '',
        address: '',
        city:'',
        state:'',
        zipcode:'',
        country:'',
        email: '',
        church: '',
        handicapped: '',
        physicalCondition: '',
        pastorSignature: '',
        damaSignature: '',
        balance: {balance},
        deposit: 0,
        paidComplete: 'no',
        fecha: '',
        date: new Date(Date.now()).toISOString()
    };

    const handleSubmit = (values) => {
        console.log('Value Object from Formik: ', values);
    };

    return(
        <>
            <h1 className='centerFormHeading'>Asociacíon de Damas</h1>
            <h2 className='centerFormHeading'>Mensajeras del Señor</h2>
            <h5 className='centerFormHeading'>Del Concilio Latino Americano</h5>
            <h2 className='centerFormHeading'>Campamento General</h2>
            <hr style={{ width: '50%', margin: '1rem auto'}}/>
            <Formik
                initialValues={initialFormValues}
                validate={validateCampRegistrationForm}
                onSubmit={handleSubmit}
            >
                <Form>
                    <FormGroup>
                        <Row style={{display:'flex', justifyContent:'center', margin: '3rem 0rem'}}>
                            <Col md='2'>
                                <Label htmlFor='condado'>
                                    Condado :
                                </Label>
                            </Col>
                            <Col md='3'>
                                <Field
                                    id='condado'
                                    placeholder='Condado' 
                                    name='condado' 
                                    className='form-control'
                                    as='select'>
                                    <option value=''>Click to Select</option>
                                    <option value='Alabama'>Alabama</option>
                                    <option value='Alaska'>Alaska</option>
                                    <option value='Arizona'>Arizona</option>
                                    <option value='Arkansas'>Arkansas</option>
                                    <option value='California'>California</option>
                                    <option value='Colorado'>Colorado</option>
                                    <option value='Connecticut'>Connecticut</option>
                                    <option value='Delaware'>Delaware</option>
                                    <option value='Florida'>Florida</option>
                                    <option value='Georgia'>Georgia</option>
                                    <option value='Hawaii'>Hawaii</option>
                                    <option value='Idaho'>Idaho</option>
                                    <option value='Illinois'>Illinois</option>
                                    <option value='Indiana'>Indiana</option>
                                    <option value='Iowa'>Iowa</option>
                                    <option value='Kansas'>Kansas</option>
                                    <option value='Kentucky'>Kentucky</option>
                                    <option value='Lousiana'>Lousiana</option>
                                    <option value='Maine'>Maine</option>
                                    <option value='Maryland'>Maryland</option>
                                    <option value='Massachusetts'>Massachusetts</option>
                                    <option value='Michigan'>Michigan</option>
                                    <option value='Minnesota'>Minnesota</option>
                                    <option value='Mississippi'>Mississippi</option>
                                    <option value='Missouri'>Missouri</option>
                                    <option value='Montana'>Montana</option>
                                    <option value='Nebraska'>Nebraska</option>
                                    <option value='Nevada'>Nevada</option>
                                    <option value='New Hamphire'>New Hamphire</option>
                                    <option value='New Jersey'>New Jersey</option>
                                    <option value='New Mexico'>New Mexico</option>
                                    <option value='New York'>New York</option>
                                    <option value='North Carolina'>North Carolina</option>
                                    <option value='North Dakota'>North Dakota</option>
                                    <option value='Ohio'>Ohio</option>
                                    <option value='Oklahoma'>Oklahoma</option>
                                    <option value='Oregon'>Oregon</option>
                                    <option value='Pennsylvania'>Pennsylvania</option>
                                    <option value='Puerto Rico'>Puerto Rico</option>
                                    <option value='Rhode Island'>Rhode Island</option>
                                    <option value='South Carolina'>South Carolina</option>
                                    <option value='South Dakota'>South Dakota</option>
                                    <option value='Tennessee'>Tennessee</option>
                                    <option value='Texas'>Texas</option>
                                    <option value='Utah'>Utah</option>
                                    <option value='Vermont'>Vermont</option>
                                    <option value='Virginia'>Virginia</option>
                                    <option value='Washington'>Washington</option>
                                    <option value='West Virginia'>West Virginia</option>
                                    <option value='Wisconsin'>Wisconsin</option>
                                    <option value='Wyoming'>Wyoming</option>
                                </Field>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='firstname'>
                                    First Name :
                                </Label>
                                <Field 
                                    id='firstname'
                                    name='firstname'
                                    placeholder='Nombre'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='lastname'>
                                    Last Name :
                                </Label>
                                <Field 
                                    id='lastname'
                                    name='lastname'
                                    placeholder='Apellido'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='2' className='campFormSpacing'>
                                <Label htmlFor='age'>
                                    Age :
                                </Label>
                                <Field 
                                    id='age'
                                    name='age'
                                    placeholder='Edad'
                                    className='form-control'
                                />
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='maritalStatus'>
                                    Marital Status :
                                </Label>
                                <Field 
                                    id='maritalStatus'
                                    name='maritalStatus' 
                                    className='form-control'                                  
                                    as='select'>
                                        <option value=''>Click to Select</option>
                                        <option value='Married'>Married</option>
                                        <option value='Divorced'>Divorced</option>
                                        <option value='Single'>Single</option>
                                        <option value='Widowed'>Widowed</option>
                                </Field>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='address'>
                                    Address :
                                </Label>
                                <Field 
                                    id='address'
                                    name='address'
                                    placeholder='Dirección'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='city'>
                                    City :    
                                </Label>
                                <Field 
                                    id='city'
                                    name='city'
                                    placeholder='Cuidad'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='2' className='campFormSpacing'>
                                <Label htmlFor='state'>
                                    State :
                                </Label>
                                <Field
                                    id='state' 
                                    name='state' 
                                    className='form-control'
                                    as='select'>
                                    <option value=''>Click to Select</option>
                                    <option value='Alabama'>AL</option>
                                    <option value='Alaska'>AK</option>
                                    <option value='Arizona'>AZ</option>
                                    <option value='Arkansas'>AR</option>
                                    <option value='California'>CA</option>
                                    <option value='Colorado'>CO</option>
                                    <option value='Connecticut'>CT</option>
                                    <option value='Delaware'>DE</option>
                                    <option value='Florida'>FL</option>
                                    <option value='Georgia'>GA</option>
                                    <option value='Hawaii'>HI</option>
                                    <option value='Idaho'>ID</option>
                                    <option value='Illinois'>IL</option>
                                    <option value='Indiana'>IN</option>
                                    <option value='Iowa'>IA</option>
                                    <option value='Kansas'>KS</option>
                                    <option value='Kentucky'>KY</option>
                                    <option value='Lousiana'>LA</option>
                                    <option value='Maine'>ME</option>
                                    <option value='Maryland'>MD</option>
                                    <option value='Massachusetts'>MA</option>
                                    <option value='Michigan'>MI</option>
                                    <option value='Minnesota'>MN</option>
                                    <option value='Mississippi'>MS</option>
                                    <option value='Missouri'>MO</option>
                                    <option value='Montana'>MT</option>
                                    <option value='Nebraska'>NE</option>
                                    <option value='Nevada'>NV</option>
                                    <option value='New Hamphire'>NH</option>
                                    <option value='New Jersey'>NJ</option>
                                    <option value='New Mexico'>NM</option>
                                    <option value='New York'>NY</option>
                                    <option value='North Carolina'>NC</option>
                                    <option value='North Dakota'>ND</option>
                                    <option value='Ohio'>OH</option>
                                    <option value='Oklahoma'>OK</option>
                                    <option value='Oregon'>OR</option>
                                    <option value='Pennsylvania'>PA</option>
                                    <option value='Puerto Rico'>PR</option>
                                    <option value='Rhode Island'>RI</option>
                                    <option value='South Carolina'>SC</option>
                                    <option value='South Dakota'>SD</option>
                                    <option value='Tennessee'>TN</option>
                                    <option value='Texas'>TX</option>
                                    <option value='Utah'>UT</option>
                                    <option value='Vermont'>VT</option>
                                    <option value='Virginia'>VA</option>
                                    <option value='Washington'>WA</option>
                                    <option value='West Virginia'>WV</option>
                                    <option value='Wisconsin'>WI</option>
                                    <option value='Wyoming'>WY</option>
                                </Field>
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='zipcode'>
                                    Zip Code :
                                </Label>
                                <Field 
                                    id='zipcode'
                                    name='zipcode'
                                    placeholder='Zip Code'
                                    className='form-control'
                                />
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='country'>
                                    Country : 
                                </Label>
                                <Field 
                                    id='country'
                                    name='country'
                                    placeholder='Pais'
                                    className='form-control'
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='email'>
                                    E-Mail :
                                </Label>
                                <Field 
                                    id='email'
                                    name='email'
                                    placeholder='Correo Electronico'
                                    className='form-control'
                                />
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='phone'>
                                    Phone Number :
                                </Label>
                                <Field 
                                    id='phone'
                                    name='phone'
                                    placeholder='Telefono'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='physicalCondition'>
                                    Physical Condition :
                                </Label>
                                <Field 
                                    id='physicalCondition'
                                    name='physicalCondition'
                                    placeholder=' Alguna Condicion Física'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='handicapped'>
                                    Handicapped (Incapacitado):
                                </Label>
                                <div style={{
                                            display:'flex', 
                                            justifyContent:'center', 
                                            alignSelf:'center',
                                            padding: '5px', 
                                            border: '1px #FFFFFF solid',
                                            borderRadius: '15px'}}>
                                    <Label style={{ marginBottom: '0px'}}>
                                        <Field 
                                        id='handicapped'
                                        name='handicapped'
                                        type='radio'
                                        value='Yes'
                                        />{' '}
                                            Yes
                                    </Label>
                                    <Label style={{ marginLeft: '3rem', marginBottom: '0px' }}>
                                        <Field 
                                        id='handicapped'
                                        name='handicapped'
                                        type='radio'
                                        value='No'
                                        />{' '}
                                            No
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='4' className='campFormSpacing'>
                                <Label htmlFor='church'>
                                    Church :
                                </Label>
                                <Field 
                                    id='church'
                                    name='church'
                                    placeholder='Iglesia'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='pastorSignature'>
                                    Pastor's Signature :
                                </Label>
                                <Field 
                                    id='pastorSignature'
                                    name='pastorSignature'
                                    placeholder='Firma del Pastor'
                                    className='form-control'
                                />
                            </Col>
                            <Col md='3' className='campFormSpacing'>
                                <Label htmlFor='damaSignature'>
                                    Signature :
                                </Label>
                                <Field 
                                    id='damaSignature'
                                    name='damaSignature'
                                    placeholder='Su Firma'
                                    className='form-control'
                                />
                            </Col>
                            <Col className='campFormSpacing'>
                                <Label htmlFor='fecha'>
                                    Date :
                                </Label>
                                <Field 
                                    id='fecha'
                                    name='fecha'
                                    type='date'
                                    className='form-control'
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md='4' className='campFormSpacing centeredLabel'>
                                <Label>
                                    Precio de Campamento : ${campPrice}.00
                                </Label>
                            </Col>
                            <Col md='4' className='campFormSpacing centeredLabel'>
                                <Label>
                                    Deposito : ${deposit}.00
                                </Label>
                            </Col>
                            <Col className='campFormSpacing centeredLabel'>
                                <Label>
                                    Balance : ${balance = campPrice - deposit}.00
                                </Label>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Formik>
        </>
    )
};

export default CampRegistrationForm;