import userIcon from '../assets/img/profile/userIcon.jpg';
import Quetcy from '../assets/img/profile/quetcyGonzalez.jpeg';
import Karla from '../assets/img/profile/KarlaMarcano.jpeg';
import Kei from '../assets/img/profile/keilanyCabezas.jpeg';
import Elsa from '../assets/img/profile/elseMendoza.jpeg';
import Eufemia from '../assets/img/profile/eufemiaRios.jpeg';
import Isabel from '../assets/img/profile/isabelCosme.jpeg';
import Jazmin from '../assets/img/profile/jazminAguinaga.jpeg';
import Maria from '../assets/img/profile/mariaRodriguez1.jpeg';
import Nora from '../assets/img/profile/noraYelland.jpeg';
import Sheila from '../assets/img/profile/sheilaRodriguez.jpeg';
import WandaF from '../assets/img/profile/wandaFontanez.jpeg';
import WandaU from '../assets/img/profile/wandaUrbaez.jpeg';
import Adelicia from '../assets/img/profile/adeliciaDavila.jpeg';
import Amarilis from '../assets/img/profile/AmarilisPolanco.jpg';
import Sarah from'../assets/img/profile/SarahGonzalez.jpeg';

export const SENIORLEADERSHIP = [
    {
        id:0,
        title: 'Presidenta',
        name: 'Mis. Quetcy Gonzalez',
        personalData: 'I am the President! I am the President! I am the President!',
        image: Quetcy
    },
    {
        id:1,
        title: 'Vice Presidenta',
        name: 'Mis. Elsa Mendoza',
        personalData: 'I am the Vice President! I am the Vice President! I am the Vice President!',
        image: Elsa 
    },
    {
        id:2,
        title: 'Secretaria',
        name: 'Mis. Karla Marcano',
        personalData: 'I am the Secretary I am the Secretary I am the Secretary',
        image: Karla
    },
    {
        id:3,
        title: 'Sub-Secretaria',
        name: 'Mis. Wanda Urbaez',
        personalData: 'I am the Sub-Scretary I am the Sub-Scretary I am the Sub-Scretary',
        image: WandaU
    },
    {
        id:4,
        title: 'Tesorera',
        name: 'Mis. Keilany Cabezas',
        personalData: 'I am the Treasurer! I am the Treasurer! I am the Treasurer!',
        image: Kei
    },
    {
        id:5,
        title: 'Sub-Tesorera',
        name: 'Mis. Eufemia Rios',
        personalData: 'I am the Sub-Treasurer! I am the Sub-Treasurer! I am the Sub-Treasurer!',
        image: Eufemia
    },
    {
        id:6,
        title: 'Vocal 1',
        name: 'Mis. Adelicia Dávila',
        personalData: 'I am Vocal One! I am Vocal One! I am Vocal One!',
        image: Adelicia
    },
    {
        id:7,
        title: 'Vocal 2',
        name: 'Wanda Fontanez',
        personalData: 'Mi propósito es apoyar y estimular a las damas para que participen activamente en la obra del Señor. Que cumplan el propósito que Dios ha trazado y alcancen su potencial en Cristo tanto en el área espiritual, servicio, social y de liderazgo.',
        image: WandaF
    },
    {
        id:8,
        title: 'Vocal 3',
        name: 'Mis. Amarilis Polanco',
        personalData: 'I am Vocal Three! I am Vocal Three! I am Vocal Three!',
        image: Amarilis
    }
];

export const VPLEADERSHIP = [
    {
        id:0,
        title: 'Vice Presidenta',
        name: 'Nerys',
        location: 'Boston',
        personalData: 'I am Boston VP I am Boston VP I am Boston VP',
        image: userIcon
    },
    {
        id:1,
        title: 'Vice Presidenta',
        name: 'Isabel Cosme',
        location: 'The Bronx',
        personalData: 'Anhelo fervientemente trabajar en la Asociación de Damas CLA para fomentar la koinonia y crecimiento espiritual de cada una de ellas.',
        image: Isabel
    },
    {
        id:2,
        title: 'Vice Presidenta',
        name: 'Karen Salas',
        location: 'Brooklyn',
        personalData: 'I am Brooklyn VP I am Brooklyn VP I am Brooklyn VP',
        image: userIcon
    },
    {
        id:3,
        title: 'Vice Presidenta',
        name: 'Sandra L. Garcia',
        location: 'Connecticut',
        personalData: 'Trabajar para el señor es mi pasión,  y ser parte de ADMS  ha bendecido mi vida.  He visto que la prioridad de los líderes ADMS,  es dar la oportunidad a las damas de desarrollar habilidades y talentos que Dios ha depositado en nuestras vidas con el fin de desarrollar líderes firmes en el señor.',
        image: userIcon
    },
    {
        id:4,
        title: 'Vice Presidenta',
        name: 'Santa Colon',
        location: 'Manhattan',
        personalData: 'I am Manhattan VP I am Manhattan VP I am Manhattan VP',
        image: userIcon
    },
    {
        id:5,
        title: 'Vice Presidenta',
        name: 'Jazmin Aguinaga',
        location: 'NJ Central',
        personalData: 'I am NJ Central VP I am NJ Central VP I am NJ Central VP',
        image: Jazmin
    },
    {
        id:6,
        title: 'Vice Presidenta',
        name: 'Sarah Gonzalez',
        location: 'NJ Norte',
        personalData: 'I am NJ Norte VP I am NJ Norte VP I am NJ Norte VP',
        image: Sarah
    },
    {
        id:7,
        title: 'Vice Presidenta',
        name: 'Joyce Suarez',
        location: 'NJ Sur',
        personalData: 'El ser parte de la ADMS me ha brindado la oportunidad de trabajar para más en la obra del Señor ya sea levantando el ánimo de las que ya estamos dentro del redil o a salir a las calles a buscarlas Las que faltan por venir a sus pies. Me brinda la oportunidad de trabajar para él con eficiencia.',
        image: userIcon
    },
    {
        id:8,
        title: 'Vice Presidenta',
        name: 'Nancy Charon',
        location: 'Pennsylvania',
        personalData: 'I am NJ Sur VP I am NJ Sur VP I am NJ Sur VP',
        image: userIcon
    },
    {
        id:9,
        title: 'Vice Presidenta',
        name: 'Maria Rodriguez',
        location: 'Queens',
        personalData: 'Mi primera motivación es servir a Dios y poder dejar un legado en las nuevas generaciones para que ellas vean nuestro amor por la obra de Dios y quieran seguir sirviendo.',
        image: Maria
    },
    {
        id:10,
        title: 'Vice Presidenta',
        name: 'Sheila Rodriguez',
        location: 'Mass Sur / Alto NY',
        personalData: 'I am Mass Sur VP I am Mass Sur VP I am Mass Sur VP',
        image: Sheila
    },
    {
        id:11,
        title: 'Vice Presidenta',
        name: 'Nora Yelland',
        location: 'Washington',
        personalData: 'Mi inspiración es hacer y estar en la perfecta voluntad de Dios para mi vida. El Salmo 27 es mi favorito. Dios les bendiga hoy y siempre. Adelante mensajeras del Señor.',
        image: Nora
    }
];