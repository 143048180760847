import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id='footer'>
            <Container>
                <Row>
                    <Col xs='12' sm='6' lg='4' id='footerLeft'>
                        <div>
                            <h3>Asociacíon de Damas</h3>
                            <h5>Mensajeras del Señor</h5>
                            <address>
                                661 Main Avenue
                                <br />
                                Hackensack, New Jersey 07601
                            </address>
                        </div><br />
                        <div>
                            <h5>Comuníquese:</h5>
                            <a role='button'
                               className='btn btn-link' 
                               href='tel:+12018810097'
                               style={{ color: '#FFFFFF'}}
                               >
                                <i className='fa fa-phone' /> 201-881-0097
                            </a>
                            <br />
                            <a role='button'
                               className='btn btn-link' 
                               href='mailto:ADMDS1959@gmail.com'
                               style={{ color: '#FFFFFF'}}
                               >
                                <i className='fa fa-envelope' /> ADMDS1959@gmail.com
                            </a>
                        </div>
                    </Col>
                    <Col sm='6' lg='4' id='footerCenter'>
                        <div>
                            <h3>Enlaces Directorial</h3>
                        </div>
                        <ul className='list-unstyled'>
                            <li>
                                <Link to='/' style={{color: '#FFFFFF'}}>
                                <i className='fa fa-home fa-lg' /> Home
                                </Link>
                            </li>
                            <br />
                            <li>
                                <Link to='/directory' style={{color: '#FFFFFF'}}>
                                    <i className='fa fa-list fa-lg' /> Directory
                                </Link>
                            </li>
                            <br />
                            <li>
                                <Link to='/aboutus' style={{color: '#FFFFFF'}}>
                                    <i className='fa fa-info fa-lg' /> About Us
                                </Link>
                            </li>
                            <br />
                            <li>
                                <Link to='/contactus' style={{color: '#FFFFFF'}}>
                                    <i className='fa fa-address-card fa-lg' /> Contact Us
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col id='footerRight'>
                        <div>
                            <h3>Siganos por las redes</h3>
                            <a
                                className='btn btn-social-icon btn-lg btn-primary btn-instagram'
                                href='http://instagram.com/'
                                target='_blank'
                                rel="noreferrer"
                            >
                                <i className='fa fa-instagram' />
                            </a>{' '}
                            <a
                                className='btn btn-social-icon btn-lg btn-primary btn-facebook'
                                href='https://www.facebook.com/ADMSCLA'
                                target='_blank'
                                rel="noreferrer"
                            >
                                <i className='fa fa-facebook' />
                            </a>{' '}
                            <a
                                className='btn btn-social-icon btn-lg btn-primary btn-x'
                                href='http://twitter.com/'
                                target='_blank'
                                rel="noreferrer"
                            >
                                <i className='fa fa-twitter' />
                            </a>{' '}
                            <a
                                className='btn btn-social-icon btn-lg btn-primary btn-google'
                                href='http://youtube.com/'
                                target='_blank'
                                rel="noreferrer"
                            >
                                <i className='fa fa-youtube' />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
};

export default Footer;