import { useParams } from 'react-router-dom';
import Convention from './Convention';
import Regions from './Regions';
import Market from './Market';
import Camp from './Camp';

const DirectoryDetail = () => {
    const { detail } = useParams();
    
    if(detail === 'convention'){
        return(
           <Convention />
        );
    } else if (detail === 'camp'){
        return(
            <Camp />
        );
    } else if (detail === 'market'){
        return(
            <Market />
        );
    } else if (detail === 'regions'){
        return(
            <Regions />
        );
    }   
};

export default DirectoryDetail;