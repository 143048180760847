export const HondurasData = {
    "type": "Feature",
    "properties": {
     "name": "Honduras",
     "density": "94.18",
     "code": "HND",
     "group": "Countries"
    },
    "geometry": {
     "type": "Polygon",
     "coordinates": [
      [
       [
        -87.316654,
        12.984686
       ],
       [
        -87.489409,
        13.297535
       ],
       [
        -87.793111,
        13.38448
       ],
       [
        -87.723503,
        13.78505
       ],
       [
        -87.859515,
        13.893312
       ],
       [
        -88.065343,
        13.964626
       ],
       [
        -88.503998,
        13.845486
       ],
       [
        -88.541231,
        13.980155
       ],
       [
        -88.843073,
        14.140507
       ],
       [
        -89.058512,
        14.340029
       ],
       [
        -89.353326,
        14.424133
       ],
       [
        -89.145535,
        14.678019
       ],
       [
        -89.22522,
        14.874286
       ],
       [
        -89.154811,
        15.066419
       ],
       [
        -88.68068,
        15.346247
       ],
       [
        -88.225023,
        15.727722
       ],
       [
        -88.121153,
        15.688655
       ],
       [
        -87.901813,
        15.864458
       ],
       [
        -87.61568,
        15.878799
       ],
       [
        -87.522921,
        15.797279
       ],
       [
        -87.367762,
        15.84694
       ],
       [
        -86.903191,
        15.756713
       ],
       [
        -86.440946,
        15.782835
       ],
       [
        -86.119234,
        15.893449
       ],
       [
        -86.001954,
        16.005406
       ],
       [
        -85.683317,
        15.953652
       ],
       [
        -85.444004,
        15.885749
       ],
       [
        -85.182444,
        15.909158
       ],
       [
        -84.983722,
        15.995923
       ],
       [
        -84.52698,
        15.857224
       ],
       [
        -84.368256,
        15.835158
       ],
       [
        -84.063055,
        15.648244
       ],
       [
        -83.773977,
        15.424072
       ],
       [
        -83.410381,
        15.270903
       ],
       [
        -83.147219,
        14.995829
       ],
       [
        -83.489989,
        15.016267
       ],
       [
        -83.628585,
        14.880074
       ],
       [
        -83.975721,
        14.749436
       ],
       [
        -84.228342,
        14.748764
       ],
       [
        -84.449336,
        14.621614
       ],
       [
        -84.649582,
        14.666805
       ],
       [
        -84.820037,
        14.819587
       ],
       [
        -84.924501,
        14.790493
       ],
       [
        -85.052787,
        14.551541
       ],
       [
        -85.148751,
        14.560197
       ],
       [
        -85.165365,
        14.35437
       ],
       [
        -85.514413,
        14.079012
       ],
       [
        -85.698665,
        13.960078
       ],
       [
        -85.801295,
        13.836055
       ],
       [
        -86.096264,
        14.038187
       ],
       [
        -86.312142,
        13.771356
       ],
       [
        -86.520708,
        13.778487
       ],
       [
        -86.755087,
        13.754845
       ],
       [
        -86.733822,
        13.263093
       ],
       [
        -86.880557,
        13.254204
       ],
       [
        -87.005769,
        13.025794
       ],
       [
        -87.316654,
        12.984686
       ]
      ]
     ]
    },
    "_id": "honduras"
   }