export const southAmericaData = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" }
    },
  
    "features": [
      {
        "type": "Feature",
        "properties": {
          "name": "French Guiana (France)",
          "density": "4",
          "cartodb_id": 1,
          "created_at": "2013-11-28T06:32:17+0100",
          "updated_at": "2013-11-28T06:33:02+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-52.288916, 4.876123],
              [-52.324609, 4.770898],
              [-52.219971, 4.862793],
              [-52.058105, 4.717383],
              [-52.012305, 4.645996],
              [-51.961914, 4.514404],
              [-51.979346, 4.429883],
              [-52.001709, 4.38623],
              [-52.00293, 4.352295],
              [-51.954785, 4.399072],
              [-51.927686, 4.436133],
              [-51.91958, 4.524316],
              [-51.880273, 4.63374],
              [-51.827539, 4.635693],
              [-51.785645, 4.570508],
              [-51.698633, 4.286816],
              [-51.66582, 4.228809],
              [-51.653271, 4.13877],
              [-51.658105, 4.098486],
              [-51.652539, 4.061279],
              [-51.683447, 4.039697],
              [-51.76709, 3.992676],
              [-51.805273, 3.929932],
              [-51.82749, 3.86958],
              [-51.879492, 3.828564],
              [-51.928906, 3.776953],
              [-51.944336, 3.735107],
              [-51.99062, 3.702002],
              [-51.999512, 3.646875],
              [-52.116113, 3.452295],
              [-52.162598, 3.364697],
              [-52.229443, 3.27168],
              [-52.27124, 3.237109],
              [-52.327881, 3.181738],
              [-52.356641, 3.117725],
              [-52.356641, 3.051562],
              [-52.396387, 2.972217],
              [-52.418408, 2.903857],
              [-52.455859, 2.86416],
              [-52.554688, 2.647656],
              [-52.559473, 2.573145],
              [-52.583008, 2.528906],
              [-52.653174, 2.425732],
              [-52.700635, 2.363672],
              [-52.783398, 2.317188],
              [-52.87041, 2.26665],
              [-52.903467, 2.211523],
              [-52.964844, 2.183545],
              [-53.009717, 2.181738],
              [-53.082275, 2.201709],
              [-53.180078, 2.211328],
              [-53.229785, 2.204883],
              [-53.252197, 2.232275],
              [-53.285498, 2.295215],
              [-53.334424, 2.339746],
              [-53.366016, 2.324219],
              [-53.431836, 2.279443],
              [-53.508984, 2.253125],
              [-53.563965, 2.261914],
              [-53.683691, 2.29292],
              [-53.734717, 2.308545],
              [-53.750146, 2.33501],
              [-53.767773, 2.354834],
              [-53.794238, 2.345996],
              [-53.829541, 2.312939],
              [-53.876611, 2.278271],
              [-53.946436, 2.232568],
              [-54.089746, 2.150488],
              [-54.130078, 2.121045],
              [-54.167383, 2.137061],
              [-54.227979, 2.15332],
              [-54.293066, 2.154248],
              [-54.433105, 2.20752],
              [-54.515088, 2.245459],
              [-54.550488, 2.293066],
              [-54.591943, 2.31377],
              [-54.61626, 2.326758],
              [-54.604736, 2.335791],
              [-54.568408, 2.342578],
              [-54.535937, 2.343311],
              [-54.485547, 2.416113],
              [-54.402002, 2.461523],
              [-54.256738, 2.713721],
              [-54.195508, 2.817871],
              [-54.188086, 2.874854],
              [-54.170703, 2.993604],
              [-54.203125, 3.138184],
              [-54.188037, 3.17876],
              [-54.063184, 3.35332],
              [-54.00957, 3.448535],
              [-54.005957, 3.530518],
              [-53.990479, 3.589551],
              [-54.005908, 3.62041],
              [-54.034229, 3.629395],
              [-54.081982, 3.705957],
              [-54.112793, 3.769434],
              [-54.197461, 3.834424],
              [-54.255518, 3.901074],
              [-54.350732, 4.054102],
              [-54.342139, 4.140039],
              [-54.369141, 4.170947],
              [-54.398389, 4.20249],
              [-54.39624, 4.241406],
              [-54.416016, 4.337646],
              [-54.440674, 4.428027],
              [-54.449609, 4.48501],
              [-54.426074, 4.583008],
              [-54.440234, 4.691992],
              [-54.471143, 4.749316],
              [-54.479687, 4.836523],
              [-54.47334, 4.914697],
              [-54.446875, 4.958789],
              [-54.452197, 5.013477],
              [-54.331641, 5.187402],
              [-54.240186, 5.288232],
              [-54.155957, 5.358984],
              [-54.085303, 5.411816],
              [-53.9896, 5.676025],
              [-53.919922, 5.768994],
              [-53.847168, 5.782227],
              [-53.454443, 5.563477],
              [-53.270361, 5.543262],
              [-52.899316, 5.425049],
              [-52.76499, 5.273486],
              [-52.453955, 5.021338],
              [-52.290527, 4.942188],
              [-52.288916, 4.876123]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Brazil",
          "density": "25.41",
          "cartodb_id": 33,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-48.485889, -27.766992],
                [-48.55459, -27.812207],
                [-48.542187, -27.574805],
                [-48.505176, -27.495508],
                [-48.464746, -27.436328],
                [-48.414893, -27.399609],
                [-48.37793, -27.451465],
                [-48.40957, -27.566309],
                [-48.496777, -27.707031],
                [-48.485889, -27.766992]
              ]
            ],
            [
              [
                [-48.584424, -26.401562],
                [-48.603076, -26.41377],
                [-48.665771, -26.289648],
                [-48.539746, -26.170313],
                [-48.497607, -26.21875],
                [-48.531104, -26.313184],
                [-48.568066, -26.379687],
                [-48.584424, -26.401562]
              ]
            ],
            [
              [
                [-45.260254, -23.88916],
                [-45.260889, -23.941309],
                [-45.302539, -23.914746],
                [-45.412842, -23.934961],
                [-45.451416, -23.895605],
                [-45.302344, -23.727539],
                [-45.272266, -23.751953],
                [-45.249072, -23.782617],
                [-45.233105, -23.825391],
                [-45.250293, -23.853027],
                [-45.260254, -23.88916]
              ]
            ],
            [
              [
                [-44.129297, -23.141895],
                [-44.098047, -23.169336],
                [-44.155762, -23.166602],
                [-44.220508, -23.19082],
                [-44.320068, -23.212305],
                [-44.360156, -23.17207],
                [-44.274121, -23.116211],
                [-44.242871, -23.074121],
                [-44.22041, -23.08291],
                [-44.191602, -23.113281],
                [-44.129297, -23.141895]
              ]
            ],
            [
              [
                [-38.903564, -13.473437],
                [-38.937891, -13.532324],
                [-38.977588, -13.523535],
                [-38.993213, -13.484082],
                [-39.022168, -13.445605],
                [-39.006592, -13.415527],
                [-38.980127, -13.398437],
                [-38.907129, -13.401074],
                [-38.903564, -13.473437]
              ]
            ],
            [
              [
                [-38.743848, -13.09707],
                [-38.783008, -13.118652],
                [-38.786963, -13.055078],
                [-38.684863, -12.974902],
                [-38.668115, -12.880176],
                [-38.614551, -12.924023],
                [-38.600293, -12.972461],
                [-38.601172, -12.992578],
                [-38.743848, -13.09707]
              ]
            ],
            [
              [
                [-44.499316, -2.939648],
                [-44.597754, -3.037598],
                [-44.565332, -2.923926],
                [-44.581885, -2.845605],
                [-44.569092, -2.784961],
                [-44.501953, -2.72627],
                [-44.481445, -2.717578],
                [-44.487305, -2.789746],
                [-44.482568, -2.811914],
                [-44.499316, -2.939648]
              ]
            ],
            [
              [
                [-44.883105, -1.317871],
                [-44.947119, -1.366016],
                [-44.967871, -1.39082],
                [-45.02085, -1.372363],
                [-45.01123, -1.344727],
                [-44.995605, -1.347559],
                [-44.978662, -1.267285],
                [-44.888281, -1.276855],
                [-44.883105, -1.317871]
              ]
            ],
            [
              [
                [-51.83252, -1.433789],
                [-51.938379, -1.452637],
                [-51.802051, -1.202539],
                [-51.680029, -1.086133],
                [-51.678271, -0.855078],
                [-51.546045, -0.649609],
                [-51.424463, -0.565918],
                [-51.254004, -0.541406],
                [-51.160742, -0.666699],
                [-51.276318, -1.021777],
                [-51.310107, -1.023828],
                [-51.465137, -1.211133],
                [-51.637695, -1.341895],
                [-51.83252, -1.433789]
              ]
            ],
            [
              [
                [-49.628662, -0.229199],
                [-49.535205, -0.233594],
                [-49.402881, -0.214648],
                [-49.314258, -0.167871],
                [-49.215088, -0.158691],
                [-49.116992, -0.163574],
                [-48.786572, -0.215527],
                [-48.588037, -0.231641],
                [-48.51543, -0.248242],
                [-48.444482, -0.271875],
                [-48.392676, -0.297363],
                [-48.379687, -0.352832],
                [-48.428027, -0.441504],
                [-48.463965, -0.534766],
                [-48.497461, -0.664941],
                [-48.52334, -0.691406],
                [-48.56665, -0.684473],
                [-48.539697, -0.800977],
                [-48.549512, -0.847559],
                [-48.570947, -0.892871],
                [-48.624072, -0.986914],
                [-48.70459, -1.106641],
                [-48.728516, -1.131738],
                [-48.789844, -1.17334],
                [-48.839697, -1.226563],
                [-48.829004, -1.276562],
                [-48.804053, -1.326953],
                [-48.833594, -1.390039],
                [-48.928906, -1.482324],
                [-48.985938, -1.504688],
                [-49.038477, -1.514062],
                [-49.086865, -1.505078],
                [-49.172705, -1.412598],
                [-49.181689, -1.484961],
                [-49.204785, -1.558984],
                [-49.233984, -1.599512],
                [-49.344824, -1.595215],
                [-49.406592, -1.555566],
                [-49.506641, -1.511621],
                [-49.525684, -1.630469],
                [-49.587891, -1.712402],
                [-49.650586, -1.738086],
                [-49.748779, -1.755371],
                [-49.805127, -1.790234],
                [-49.911328, -1.762988],
                [-50.009961, -1.708496],
                [-50.065723, -1.703809],
                [-50.109277, -1.747852],
                [-50.338428, -1.755957],
                [-50.443457, -1.800684],
                [-50.507617, -1.787988],
                [-50.602051, -1.697754],
                [-50.617188, -1.637695],
                [-50.673389, -1.516016],
                [-50.723828, -1.371484],
                [-50.759766, -1.240234],
                [-50.729492, -1.126758],
                [-50.668311, -1.130566],
                [-50.595898, -1.147461],
                [-50.580518, -1.139453],
                [-50.576953, -1.103125],
                [-50.59292, -1.072949],
                [-50.709619, -1.077734],
                [-50.783301, -1.010352],
                [-50.796094, -0.90625],
                [-50.780957, -0.689844],
                [-50.771387, -0.64541],
                [-50.719922, -0.583398],
                [-50.703076, -0.528516],
                [-50.71582, -0.470215],
                [-50.693701, -0.364453],
                [-50.645508, -0.272852],
                [-50.461572, -0.157422],
                [-50.248242, -0.116406],
                [-49.628662, -0.229199]
              ]
            ],
            [
              [
                [-50.652881, -0.131641],
                [-50.926367, -0.327344],
                [-51.018994, -0.263086],
                [-51.038086, -0.225879],
                [-51.022363, -0.188379],
                [-51.025732, -0.172363],
                [-50.995068, -0.105273],
                [-50.842188, -0.050195],
                [-50.765283, -0.040869],
                [-50.666992, -0.058008],
                [-50.650586, -0.105859],
                [-50.652881, -0.131641]
              ]
            ],
            [
              [
                [-49.443896, -0.112402],
                [-49.708838, -0.14375],
                [-49.830078, -0.093896],
                [-49.802686, -0.051855],
                [-49.712305, 0.015137],
                [-49.602197, 0.062695],
                [-49.503467, 0.083691],
                [-49.400488, 0.057227],
                [-49.372314, 0.001074],
                [-49.380859, -0.055469],
                [-49.443896, -0.112402]
              ]
            ],
            [
              [
                [-49.738232, 0.268164],
                [-49.697266, 0.215967],
                [-49.838965, 0.006885],
                [-49.91709, -0.023193],
                [-50.00249, -0.029297],
                [-50.113135, 0.033008],
                [-50.285596, 0.028564],
                [-50.339453, 0.043359],
                [-50.345117, 0.134473],
                [-50.272656, 0.231738],
                [-50.127979, 0.226514],
                [-49.879004, 0.304541],
                [-49.738232, 0.268164]
              ]
            ],
            [
              [
                [-50.426123, 0.139258],
                [-50.443945, -0.007666],
                [-50.623926, 0.054395],
                [-50.610449, 0.204785],
                [-50.526221, 0.246924],
                [-50.451563, 0.326904],
                [-50.426074, 0.424951],
                [-50.424561, 0.558252],
                [-50.396875, 0.581396],
                [-50.372754, 0.590869],
                [-50.350977, 0.581738],
                [-50.342529, 0.381592],
                [-50.332275, 0.259033],
                [-50.426123, 0.139258]
              ]
            ],
            [
              [
                [-50.15293, 0.393018],
                [-50.261328, 0.35918],
                [-50.281543, 0.39082],
                [-50.281689, 0.516504],
                [-50.251172, 0.585449],
                [-50.112793, 0.604736],
                [-50.098633, 0.625],
                [-50.058838, 0.638037],
                [-50.036816, 0.594824],
                [-50.040039, 0.522803],
                [-50.15293, 0.393018]
              ]
            ],
            [
              [
                [-50.298975, 1.938525],
                [-50.398779, 1.892871],
                [-50.456104, 1.910498],
                [-50.508984, 2.029541],
                [-50.491016, 2.128613],
                [-50.41875, 2.161475],
                [-50.362646, 2.154443],
                [-50.341992, 2.141748],
                [-50.29209, 1.97959],
                [-50.298975, 1.938525]
              ]
            ],
            [
              [
                [-60.24165, 5.257959],
                [-60.181738, 5.238818],
                [-60.142041, 5.238818],
                [-60.105957, 5.194238],
                [-60.078076, 5.143994],
                [-59.990674, 5.082861],
                [-59.999365, 4.989844],
                [-60.015479, 4.90752],
                [-60.026758, 4.812695],
                [-60.031787, 4.740527],
                [-60.068945, 4.66665],
                [-60.124561, 4.597656],
                [-60.140918, 4.569629],
                [-60.148633, 4.533252],
                [-60.111133, 4.511182],
                [-60.04502, 4.50459],
                [-59.962354, 4.501709],
                [-59.906104, 4.480322],
                [-59.83335, 4.475928],
                [-59.745801, 4.41665],
                [-59.703271, 4.381104],
                [-59.699707, 4.353516],
                [-59.72749, 4.287646],
                [-59.738574, 4.226758],
                [-59.716895, 4.188184],
                [-59.691211, 4.1604],
                [-59.620215, 4.023145],
                [-59.586426, 3.975391],
                [-59.557764, 3.96001],
                [-59.551123, 3.933545],
                [-59.575391, 3.883447],
                [-59.604443, 3.819678],
                [-59.670215, 3.752734],
                [-59.679004, 3.699805],
                [-59.731641, 3.666553],
                [-59.854395, 3.5875],
                [-59.833057, 3.462158],
                [-59.828809, 3.398584],
                [-59.831152, 3.349219],
                [-59.873047, 3.283105],
                [-59.945654, 3.087842],
                [-59.972314, 2.990479],
                [-59.995898, 2.76543],
                [-59.994336, 2.68999],
                [-59.960791, 2.588379],
                [-59.889648, 2.362939],
                [-59.849121, 2.327051],
                [-59.755225, 2.274121],
                [-59.743506, 2.121631],
                [-59.751758, 1.962402],
                [-59.756201, 1.900635],
                [-59.740723, 1.87417],
                [-59.698535, 1.861475],
                [-59.668506, 1.842334],
                [-59.66377, 1.795215],
                [-59.666602, 1.746289],
                [-59.596631, 1.718018],
                [-59.535693, 1.7],
                [-59.479443, 1.632422],
                [-59.377686, 1.527344],
                [-59.337256, 1.508203],
                [-59.316992, 1.4646],
                [-59.231201, 1.376025],
                [-59.100391, 1.343652],
                [-58.968506, 1.30459],
                [-58.916602, 1.248877],
                [-58.8625, 1.203613],
                [-58.821777, 1.201221],
                [-58.787207, 1.208496],
                [-58.730322, 1.24751],
                [-58.684619, 1.281055],
                [-58.605078, 1.27915],
                [-58.511865, 1.284668],
                [-58.495703, 1.312256],
                [-58.486865, 1.347754],
                [-58.506055, 1.438672],
                [-58.472949, 1.46626],
                [-58.395801, 1.481738],
                [-58.380371, 1.530225],
                [-58.362695, 1.556689],
                [-58.340674, 1.587549],
                [-58.314209, 1.591943],
                [-58.281152, 1.574316],
                [-58.23042, 1.563281],
                [-58.173096, 1.547852],
                [-58.142236, 1.516992],
                [-58.091309, 1.514355],
                [-58.034668, 1.520264],
                [-58.011768, 1.539941],
                [-57.995117, 1.574316],
                [-57.982812, 1.648438],
                [-57.946338, 1.650586],
                [-57.873438, 1.667285],
                [-57.795654, 1.7],
                [-57.691748, 1.704785],
                [-57.594434, 1.704102],
                [-57.545752, 1.726074],
                [-57.500439, 1.773828],
                [-57.412695, 1.908936],
                [-57.366797, 1.940137],
                [-57.31748, 1.963477],
                [-57.275586, 1.959229],
                [-57.1896, 1.981592],
                [-57.118896, 2.013965],
                [-57.092676, 2.005811],
                [-57.037598, 1.936475],
                [-57.010059, 1.92124],
                [-56.969531, 1.916406],
                [-56.836719, 1.88125],
                [-56.76626, 1.892188],
                [-56.689844, 1.914307],
                [-56.616455, 1.922656],
                [-56.563574, 1.907227],
                [-56.525488, 1.927246],
                [-56.482812, 1.942139],
                [-56.452832, 1.932324],
                [-56.38584, 1.923877],
                [-56.227148, 1.885352],
                [-56.019922, 1.842236],
                [-55.96333, 1.85708],
                [-55.929639, 1.8875],
                [-55.921631, 1.97666],
                [-55.915332, 2.039551],
                [-55.961963, 2.095117],
                [-56.020068, 2.158154],
                [-56.073633, 2.236768],
                [-56.137695, 2.259033],
                [-56.129395, 2.299512],
                [-56.087793, 2.341309],
                [-56.045117, 2.364404],
                [-56.020361, 2.392773],
                [-55.993506, 2.49751],
                [-55.975586, 2.515967],
                [-55.957471, 2.520459],
                [-55.935937, 2.516602],
                [-55.89375, 2.489502],
                [-55.730566, 2.406152],
                [-55.658936, 2.41875],
                [-55.385352, 2.440625],
                [-55.343994, 2.48877],
                [-55.286035, 2.499658],
                [-55.187695, 2.54751],
                [-55.148828, 2.550781],
                [-55.114111, 2.539209],
                [-55.070312, 2.54834],
                [-55.005811, 2.592969],
                [-54.978662, 2.597656],
                [-54.968408, 2.54834],
                [-54.926562, 2.497363],
                [-54.876074, 2.450391],
                [-54.85166, 2.439551],
                [-54.766846, 2.454736],
                [-54.722217, 2.44165],
                [-54.70293, 2.397949],
                [-54.697412, 2.359814],
                [-54.661865, 2.327539],
                [-54.61626, 2.326758],
                [-54.591943, 2.31377],
                [-54.550488, 2.293066],
                [-54.515088, 2.245459],
                [-54.433105, 2.20752],
                [-54.293066, 2.154248],
                [-54.227979, 2.15332],
                [-54.167383, 2.137061],
                [-54.130078, 2.121045],
                [-54.089746, 2.150488],
                [-53.946436, 2.232568],
                [-53.876611, 2.278271],
                [-53.829541, 2.312939],
                [-53.794238, 2.345996],
                [-53.767773, 2.354834],
                [-53.750146, 2.33501],
                [-53.734717, 2.308545],
                [-53.683691, 2.29292],
                [-53.563965, 2.261914],
                [-53.508984, 2.253125],
                [-53.431836, 2.279443],
                [-53.366016, 2.324219],
                [-53.334424, 2.339746],
                [-53.285498, 2.295215],
                [-53.252197, 2.232275],
                [-53.229785, 2.204883],
                [-53.180078, 2.211328],
                [-53.082275, 2.201709],
                [-53.009717, 2.181738],
                [-52.964844, 2.183545],
                [-52.903467, 2.211523],
                [-52.87041, 2.26665],
                [-52.783398, 2.317188],
                [-52.700635, 2.363672],
                [-52.653174, 2.425732],
                [-52.583008, 2.528906],
                [-52.559473, 2.573145],
                [-52.554688, 2.647656],
                [-52.455859, 2.86416],
                [-52.418408, 2.903857],
                [-52.396387, 2.972217],
                [-52.356641, 3.051562],
                [-52.356641, 3.117725],
                [-52.327881, 3.181738],
                [-52.27124, 3.237109],
                [-52.229443, 3.27168],
                [-52.162598, 3.364697],
                [-52.116113, 3.452295],
                [-51.999512, 3.646875],
                [-51.990625, 3.702002],
                [-51.944336, 3.735107],
                [-51.928906, 3.776953],
                [-51.879492, 3.828564],
                [-51.82749, 3.86958],
                [-51.805273, 3.929932],
                [-51.76709, 3.992676],
                [-51.683447, 4.039697],
                [-51.652539, 4.061279],
                [-51.557812, 4.233789],
                [-51.54707, 4.310889],
                [-51.461523, 4.31377],
                [-51.3271, 4.224756],
                [-51.219922, 4.093604],
                [-51.07627, 3.67168],
                [-51.052393, 3.281836],
                [-50.994141, 3.077539],
                [-50.827197, 2.651855],
                [-50.816504, 2.573047],
                [-50.789697, 2.477783],
                [-50.736963, 2.376758],
                [-50.67876, 2.210352],
                [-50.676563, 2.179443],
                [-50.714404, 2.134033],
                [-50.658936, 2.130957],
                [-50.608691, 2.104102],
                [-50.575879, 1.998584],
                [-50.534424, 1.927246],
                [-50.458887, 1.82959],
                [-50.304297, 1.797656],
                [-50.187598, 1.785986],
                [-50.054687, 1.730713],
                [-49.957129, 1.659863],
                [-49.881592, 1.419922],
                [-49.90625, 1.269043],
                [-49.898877, 1.162988],
                [-49.937939, 1.121436],
                [-50.047217, 1.051953],
                [-50.070996, 1.015088],
                [-50.294434, 0.835742],
                [-50.343262, 0.751025],
                [-50.462988, 0.637305],
                [-50.581543, 0.420508],
                [-50.755078, 0.222559],
                [-50.816357, 0.172559],
                [-50.910156, 0.160986],
                [-50.96709, 0.130273],
                [-51.101953, -0.03125],
                [-51.28291, -0.085205],
                [-51.299561, -0.178809],
                [-51.40415, -0.392676],
                [-51.496289, -0.509473],
                [-51.555029, -0.549121],
                [-51.702637, -0.762305],
                [-51.721533, -0.855469],
                [-51.720605, -1.018457],
                [-51.819141, -1.117773],
                [-51.921631, -1.180859],
                [-51.934473, -1.320312],
                [-51.980811, -1.367969],
                [-52.020459, -1.399023],
                [-52.229248, -1.3625],
                [-52.553418, -1.514062],
                [-52.66416, -1.551758],
                [-52.310303, -1.55957],
                [-52.19668, -1.640137],
                [-51.947559, -1.586719],
                [-51.646289, -1.394336],
                [-51.531201, -1.354102],
                [-51.297363, -1.223535],
                [-51.202344, -1.136523],
                [-51.028955, -1.032129],
                [-50.992041, -0.986328],
                [-50.894922, -0.937598],
                [-50.842285, -0.999609],
                [-50.838184, -1.038867],
                [-50.917871, -1.115234],
                [-50.897168, -1.164453],
                [-50.84458, -1.22627],
                [-50.825537, -1.311426],
                [-50.818652, -1.37627],
                [-50.786133, -1.489941],
                [-50.678955, -1.643848],
                [-50.675293, -1.694727],
                [-50.690039, -1.761719],
                [-50.63877, -1.81709],
                [-50.585596, -1.849902],
                [-50.403223, -2.015527],
                [-50.260449, -1.922949],
                [-50.172705, -1.896191],
                [-50.116602, -1.85752],
                [-49.999219, -1.831836],
                [-49.902979, -1.870605],
                [-49.719531, -1.926367],
                [-49.585352, -1.867187],
                [-49.313672, -1.731738],
                [-49.398633, -1.971582],
                [-49.460156, -2.191504],
                [-49.506982, -2.280273],
                [-49.553369, -2.519922],
                [-49.599316, -2.583887],
                [-49.636523, -2.656934],
                [-49.575879, -2.631445],
                [-49.523926, -2.596875],
                [-49.45752, -2.50459],
                [-49.407666, -2.344336],
                [-49.211035, -1.916504],
                [-49.154785, -1.878516],
                [-48.991309, -1.829785],
                [-48.71001, -1.487695],
                [-48.6, -1.48877],
                [-48.52959, -1.56748],
                [-48.462939, -1.613965],
                [-48.44585, -1.52041],
                [-48.349805, -1.482129],
                [-48.451465, -1.43584],
                [-48.468066, -1.393848],
                [-48.477734, -1.323828],
                [-48.408594, -1.229199],
                [-48.449805, -1.145508],
                [-48.306494, -1.039844],
                [-48.317578, -0.960547],
                [-48.266455, -0.895117],
                [-48.201758, -0.82793],
                [-48.128467, -0.795215],
                [-48.115088, -0.7375],
                [-48.068848, -0.713672],
                [-48.032568, -0.705078],
                [-47.960938, -0.769629],
                [-47.883398, -0.693359],
                [-47.807666, -0.663477],
                [-47.77373, -0.676758],
                [-47.731494, -0.710449],
                [-47.687109, -0.724805],
                [-47.651074, -0.71875],
                [-47.557324, -0.669922],
                [-47.470703, -0.748535],
                [-47.418652, -0.765918],
                [-47.43291, -0.721875],
                [-47.460352, -0.680957],
                [-47.439063, -0.647656],
                [-47.398096, -0.62666],
                [-47.268604, -0.64541],
                [-47.200537, -0.680469],
                [-47.126904, -0.74541],
                [-47.024609, -0.750195],
                [-46.944336, -0.743359],
                [-46.893652, -0.779883],
                [-46.81123, -0.779688],
                [-46.769922, -0.836523],
                [-46.644434, -0.916406],
                [-46.617236, -0.970605],
                [-46.516309, -0.996875],
                [-46.421729, -1.030078],
                [-46.32085, -1.03916],
                [-46.219141, -1.03125],
                [-46.21499, -1.099805],
                [-46.140381, -1.118359],
                [-46.044629, -1.103027],
                [-45.972266, -1.187402],
                [-45.778809, -1.250781],
                [-45.644775, -1.347852],
                [-45.556934, -1.330664],
                [-45.458594, -1.35625],
                [-45.353027, -1.567383],
                [-45.32915, -1.717285],
                [-45.282129, -1.696582],
                [-45.238574, -1.629492],
                [-45.18208, -1.507031],
                [-45.076367, -1.466406],
                [-45.025781, -1.513477],
                [-44.919775, -1.588867],
                [-44.828369, -1.67168],
                [-44.789844, -1.724805],
                [-44.721143, -1.733496],
                [-44.778516, -1.798828],
                [-44.720947, -1.792285],
                [-44.65127, -1.745801],
                [-44.59165, -1.841797],
                [-44.546777, -1.946289],
                [-44.537793, -2.052734],
                [-44.580029, -2.113867],
                [-44.617285, -2.152148],
                [-44.658643, -2.227539],
                [-44.70752, -2.241113],
                [-44.756348, -2.265527],
                [-44.700635, -2.32041],
                [-44.662402, -2.373242],
                [-44.579004, -2.230469],
                [-44.520361, -2.190332],
                [-44.435449, -2.168066],
                [-44.391309, -2.269629],
                [-44.381836, -2.365527],
                [-44.520117, -2.405469],
                [-44.520654, -2.48125],
                [-44.562012, -2.524219],
                [-44.589014, -2.573437],
                [-44.610791, -2.676855],
                [-44.638965, -2.7625],
                [-44.721387, -3.142285],
                [-44.723047, -3.204785],
                [-44.622656, -3.137891],
                [-44.437549, -2.944434],
                [-44.381152, -2.738379],
                [-44.308154, -2.535156],
                [-44.228613, -2.471289],
                [-44.179395, -2.471191],
                [-44.105566, -2.493457],
                [-44.101367, -2.560059],
                [-44.112646, -2.598535],
                [-44.191602, -2.699609],
                [-44.225195, -2.75498],
                [-44.192676, -2.80957],
                [-44.013232, -2.642187],
                [-43.93291, -2.583496],
                [-43.864453, -2.59541],
                [-43.728613, -2.518164],
                [-43.455127, -2.502051],
                [-43.434619, -2.413672],
                [-43.380078, -2.376074],
                [-43.229687, -2.386035],
                [-42.936719, -2.465039],
                [-42.832275, -2.52959],
                [-42.675879, -2.589648],
                [-42.593555, -2.661035],
                [-42.249609, -2.791992],
                [-41.999854, -2.806055],
                [-41.876172, -2.746582],
                [-41.721875, -2.808887],
                [-41.640137, -2.878613],
                [-41.479932, -2.916504],
                [-41.318213, -2.93623],
                [-41.194531, -2.886133],
                [-40.875586, -2.869629],
                [-40.474561, -2.795605],
                [-40.235352, -2.813184],
                [-39.964697, -2.861523],
                [-39.771826, -2.98584],
                [-39.609424, -3.05625],
                [-39.511182, -3.125586],
                [-39.352686, -3.197363],
                [-39.014355, -3.390234],
                [-38.895996, -3.501758],
                [-38.68623, -3.653711],
                [-38.475781, -3.71748],
                [-38.361914, -3.876465],
                [-38.271875, -3.948047],
                [-38.048828, -4.216406],
                [-37.795654, -4.404297],
                [-37.626318, -4.59209],
                [-37.301465, -4.713086],
                [-37.174658, -4.912402],
                [-36.954883, -4.936719],
                [-36.861133, -4.966602],
                [-36.747363, -5.050684],
                [-36.590723, -5.097559],
                [-36.386719, -5.084277],
                [-36.161768, -5.09375],
                [-35.979883, -5.054395],
                [-35.549414, -5.129395],
                [-35.481689, -5.166016],
                [-35.392578, -5.250879],
                [-35.235449, -5.566699],
                [-35.141748, -5.917187],
                [-35.095459, -6.185352],
                [-34.988184, -6.39375],
                [-34.92959, -6.785059],
                [-34.879883, -6.908203],
                [-34.875977, -7.00293],
                [-34.833887, -7.024414],
                [-34.805469, -7.288379],
                [-34.816602, -7.394824],
                [-34.857764, -7.533301],
                [-34.86084, -7.59502],
                [-34.854785, -7.634277],
                [-34.872998, -7.69209],
                [-34.878613, -7.747461],
                [-34.836914, -7.871777],
                [-34.834668, -7.971484],
                [-34.890527, -8.092188],
                [-34.96665, -8.407617],
                [-35.157764, -8.930566],
                [-35.340869, -9.230664],
                [-35.59707, -9.540625],
                [-35.763965, -9.702539],
                [-35.830127, -9.719043],
                [-35.89082, -9.687012],
                [-35.847754, -9.772461],
                [-35.885449, -9.847656],
                [-36.05498, -10.075781],
                [-36.223535, -10.225098],
                [-36.39834, -10.484082],
                [-36.411621, -10.489941],
                [-36.635742, -10.589941],
                [-36.768311, -10.67168],
                [-36.937793, -10.82041],
                [-37.093359, -11.054785],
                [-37.125488, -11.084961],
                [-37.182812, -11.068457],
                [-37.181201, -11.1875],
                [-37.315137, -11.375977],
                [-37.356006, -11.403906],
                [-37.354883, -11.350488],
                [-37.331641, -11.309863],
                [-37.320801, -11.266602],
                [-37.321777, -11.215137],
                [-37.359229, -11.252539],
                [-37.438477, -11.39375],
                [-37.411816, -11.497266],
                [-37.469336, -11.653613],
                [-37.688721, -12.1],
                [-37.957324, -12.475488],
                [-38.019238, -12.591309],
                [-38.239746, -12.844238],
                [-38.401758, -12.966211],
                [-38.447314, -12.96709],
                [-38.498926, -12.956641],
                [-38.524902, -12.762305],
                [-38.654004, -12.644629],
                [-38.690967, -12.623926],
                [-38.743896, -12.748535],
                [-38.787988, -12.782715],
                [-38.851758, -12.790137],
                [-38.783594, -12.844434],
                [-38.763721, -12.907227],
                [-38.833154, -13.03291],
                [-38.835303, -13.147168],
                [-38.95918, -13.273047],
                [-39.030908, -13.365137],
                [-39.067383, -13.480469],
                [-39.089355, -13.588184],
                [-39.034912, -13.558789],
                [-39.009082, -13.581445],
                [-38.988623, -13.615039],
                [-39.001221, -13.664551],
                [-39.041113, -13.758105],
                [-39.034912, -13.991016],
                [-39.048145, -14.043945],
                [-39.008496, -14.101172],
                [-38.966504, -14.003418],
                [-38.942334, -14.030664],
                [-39.05957, -14.654785],
                [-39.013379, -14.935645],
                [-38.996191, -15.253809],
                [-38.943213, -15.564355],
                [-38.885254, -15.841992],
                [-38.880615, -15.864258],
                [-38.960791, -16.186523],
                [-39.063232, -16.504395],
                [-39.125049, -16.763574],
                [-39.163965, -17.043555],
                [-39.202881, -17.178125],
                [-39.215234, -17.31582],
                [-39.170605, -17.64209],
                [-39.154004, -17.703906],
                [-39.278369, -17.849414],
                [-39.412598, -17.92002],
                [-39.486768, -17.990137],
                [-39.650781, -18.252344],
                [-39.739795, -18.639844],
                [-39.741943, -18.845996],
                [-39.699854, -19.277832],
                [-39.731445, -19.453906],
                [-39.783301, -19.571777],
                [-39.844727, -19.649121],
                [-40.001367, -19.741992],
                [-40.141699, -19.968262],
                [-40.202734, -20.206055],
                [-40.298877, -20.292676],
                [-40.318555, -20.425781],
                [-40.395947, -20.569434],
                [-40.596582, -20.783789],
                [-40.727051, -20.846191],
                [-40.789258, -20.906055],
                [-40.82876, -21.031348],
                [-40.954541, -21.237891],
                [-41.047266, -21.505664],
                [-41.023145, -21.596875],
                [-41.021582, -21.61084],
                [-40.987842, -21.920312],
                [-41.000293, -21.999023],
                [-41.12251, -22.084375],
                [-41.58291, -22.243652],
                [-41.705518, -22.309668],
                [-41.98042, -22.580664],
                [-41.997559, -22.644629],
                [-41.986133, -22.73584],
                [-41.940918, -22.788281],
                [-41.9875, -22.845117],
                [-42.042383, -22.94707],
                [-42.122461, -22.94082],
                [-42.581055, -22.941016],
                [-42.829297, -22.97334],
                [-42.958301, -22.96709],
                [-43.016211, -22.942578],
                [-43.081152, -22.902539],
                [-43.100684, -22.850098],
                [-43.06543, -22.770703],
                [-43.086279, -22.72334],
                [-43.154297, -22.725195],
                [-43.229004, -22.747656],
                [-43.241943, -22.795117],
                [-43.236621, -22.828809],
                [-43.208838, -22.878125],
                [-43.193604, -22.938574],
                [-43.22417, -22.991211],
                [-43.369482, -22.998047],
                [-43.532813, -23.046387],
                [-43.736523, -23.066602],
                [-43.898828, -23.101465],
                [-43.973828, -23.057324],
                [-43.898828, -23.035254],
                [-43.791406, -23.045996],
                [-43.675977, -23.009473],
                [-43.70293, -22.966309],
                [-43.866162, -22.910547],
                [-44.047461, -22.944727],
                [-44.147998, -23.011035],
                [-44.36792, -23.00498],
                [-44.637256, -23.055469],
                [-44.681152, -23.106934],
                [-44.673828, -23.206641],
                [-44.621094, -23.228516],
                [-44.569678, -23.274023],
                [-44.619092, -23.316406],
                [-44.667188, -23.335156],
                [-44.95166, -23.381445],
                [-45.21543, -23.575586],
                [-45.325391, -23.599707],
                [-45.423291, -23.685352],
                [-45.433398, -23.758496],
                [-45.464307, -23.802539],
                [-45.5271, -23.804785],
                [-45.664648, -23.764844],
                [-45.843164, -23.763672],
                [-45.97207, -23.795508],
                [-46.630762, -24.110352],
                [-46.867285, -24.236328],
                [-47.137207, -24.493164],
                [-47.592187, -24.781055],
                [-47.831152, -24.95293],
                [-47.876562, -24.997461],
                [-47.914307, -24.999902],
                [-47.98916, -25.035742],
                [-47.959375, -25.06543],
                [-47.90835, -25.068164],
                [-47.929395, -25.168262],
                [-48.024365, -25.236719],
                [-48.202734, -25.416504],
                [-48.242432, -25.40332],
                [-48.185937, -25.309863],
                [-48.273486, -25.306348],
                [-48.40249, -25.27207],
                [-48.458496, -25.310742],
                [-48.427637, -25.40332],
                [-48.476123, -25.442969],
                [-48.56416, -25.447461],
                [-48.643994, -25.436523],
                [-48.731738, -25.36875],
                [-48.692187, -25.491504],
                [-48.507031, -25.521289],
                [-48.429883, -25.550195],
                [-48.401172, -25.597363],
                [-48.545166, -25.815918],
                [-48.665771, -25.844336],
                [-48.679004, -25.875195],
                [-48.612842, -25.875],
                [-48.576318, -25.935449],
                [-48.619434, -26.179395],
                [-48.679004, -26.225781],
                [-48.71377, -26.226953],
                [-48.748291, -26.268652],
                [-48.700684, -26.34834],
                [-48.651611, -26.406445],
                [-48.658154, -26.519141],
                [-48.676514, -26.612402],
                [-48.677734, -26.70293],
                [-48.615674, -26.878125],
                [-48.593408, -27.058008],
                [-48.568359, -27.123437],
                [-48.55415, -27.195996],
                [-48.595508, -27.263867],
                [-48.571973, -27.372754],
                [-48.642578, -27.55791],
                [-48.605664, -27.825195],
                [-48.620801, -28.075586],
                [-48.648438, -28.207227],
                [-48.693213, -28.310156],
                [-48.797266, -28.442676],
                [-48.799658, -28.575293],
                [-49.023584, -28.698633],
                [-49.271289, -28.871191],
                [-49.499902, -29.075391],
                [-49.745996, -29.363184],
                [-50.03335, -29.800977],
                [-50.299512, -30.425781],
                [-50.619971, -30.897656],
                [-50.748145, -31.068066],
                [-50.921387, -31.258398],
                [-51.151758, -31.480371],
                [-51.4604, -31.702441],
                [-51.798145, -31.900293],
                [-51.920215, -31.989551],
                [-52.039209, -32.114844],
                [-52.068945, -32.063086],
                [-52.043164, -31.977539],
                [-52.05957, -31.913477],
                [-52.063232, -31.830371],
                [-51.995117, -31.815039],
                [-51.893164, -31.867773],
                [-51.841211, -31.832031],
                [-51.803418, -31.79668],
                [-51.680664, -31.774609],
                [-51.446191, -31.557324],
                [-51.272168, -31.476953],
                [-51.174316, -31.339746],
                [-51.15752, -31.266797],
                [-51.161426, -31.118848],
                [-51.105957, -31.081348],
                [-50.980078, -31.094238],
                [-50.954395, -31.052148],
                [-50.965332, -31.005469],
                [-50.94082, -30.903711],
                [-50.770166, -30.813379],
                [-50.689307, -30.704199],
                [-50.716309, -30.425977],
                [-50.685059, -30.413477],
                [-50.614844, -30.456836],
                [-50.581934, -30.438867],
                [-50.546533, -30.316895],
                [-50.563525, -30.253613],
                [-50.646191, -30.236816],
                [-50.931885, -30.374316],
                [-51.024951, -30.368652],
                [-51.040381, -30.260645],
                [-51.179297, -30.211035],
                [-51.233594, -30.121387],
                [-51.249854, -30.059961],
                [-51.298047, -30.034863],
                [-51.29502, -30.141016],
                [-51.281787, -30.244141],
                [-51.157275, -30.364258],
                [-51.187549, -30.411914],
                [-51.246582, -30.467578],
                [-51.287695, -30.591211],
                [-51.283057, -30.751562],
                [-51.316406, -30.702734],
                [-51.359082, -30.674512],
                [-51.376465, -30.846875],
                [-51.459131, -30.912793],
                [-51.485254, -30.977539],
                [-51.463672, -31.052637],
                [-51.506299, -31.104492],
                [-51.716895, -31.24375],
                [-51.926807, -31.338867],
                [-51.972461, -31.383789],
                [-51.994873, -31.489941],
                [-52.026953, -31.599023],
                [-52.119824, -31.694922],
                [-52.193555, -31.885547],
                [-52.191553, -31.967578],
                [-52.16709, -32.088477],
                [-52.127393, -32.167773],
                [-52.190186, -32.220801],
                [-52.274609, -32.32373],
                [-52.34165, -32.439746],
                [-52.508496, -32.875293],
                [-52.652246, -33.137793],
                [-52.762891, -33.266406],
                [-52.92085, -33.401953],
                [-53.370605, -33.742188],
                [-53.397559, -33.737305],
                [-53.463574, -33.709863],
                [-53.518848, -33.677246],
                [-53.531348, -33.655469],
                [-53.537646, -33.622852],
                [-53.530371, -33.500293],
                [-53.531348, -33.170898],
                [-53.511865, -33.108691],
                [-53.482861, -33.068555],
                [-53.395215, -33.010352],
                [-53.310107, -32.927051],
                [-53.214062, -32.821094],
                [-53.125586, -32.736719],
                [-53.157275, -32.680078],
                [-53.23125, -32.625391],
                [-53.362744, -32.581152],
                [-53.489404, -32.503223],
                [-53.601709, -32.403027],
                [-53.653613, -32.29873],
                [-53.701123, -32.186328],
                [-53.746582, -32.097461],
                [-53.761719, -32.056836],
                [-53.806104, -32.039941],
                [-53.876514, -31.994531],
                [-53.920605, -31.952344],
                [-53.985156, -31.928125],
                [-54.100439, -31.901563],
                [-54.220557, -31.855176],
                [-54.369922, -31.74502],
                [-54.477686, -31.622754],
                [-54.530908, -31.541992],
                [-54.587646, -31.485156],
                [-54.895996, -31.391211],
                [-55.036035, -31.279004],
                [-55.091162, -31.313965],
                [-55.173535, -31.27959],
                [-55.254639, -31.225586],
                [-55.278955, -31.18418],
                [-55.313281, -31.141699],
                [-55.345508, -31.092969],
                [-55.366064, -31.046191],
                [-55.449561, -30.964453],
                [-55.557324, -30.875977],
                [-55.603027, -30.850781],
                [-55.627148, -30.858105],
                [-55.650488, -30.89209],
                [-55.665234, -30.924902],
                [-55.705957, -30.946582],
                [-55.756348, -30.987109],
                [-55.807764, -31.036719],
                [-55.873682, -31.069629],
                [-55.952002, -31.080859],
                [-56.004687, -31.079199],
                [-56.015527, -31.059668],
                [-56.018457, -30.991895],
                [-55.998975, -30.837207],
                [-56.044824, -30.777637],
                [-56.105859, -30.71377],
                [-56.176172, -30.628418],
                [-56.407227, -30.447461],
                [-56.72168, -30.186914],
                [-56.832715, -30.107227],
                [-56.937256, -30.101074],
                [-57.032715, -30.109961],
                [-57.120508, -30.144434],
                [-57.186914, -30.264844],
                [-57.214453, -30.283398],
                [-57.383838, -30.280664],
                [-57.552295, -30.26123],
                [-57.608887, -30.187793],
                [-57.563867, -30.139941],
                [-57.405225, -30.033887],
                [-57.31748, -29.939453],
                [-57.300684, -29.856543],
                [-57.224658, -29.782129],
                [-57.089355, -29.716211],
                [-56.938623, -29.594824],
                [-56.772461, -29.417871],
                [-56.671533, -29.287305],
                [-56.63584, -29.203027],
                [-56.570703, -29.138086],
                [-56.475977, -29.09248],
                [-56.393262, -28.997266],
                [-56.322363, -28.852441],
                [-56.225537, -28.737207],
                [-56.102881, -28.651758],
                [-56.034229, -28.580859],
                [-56.019629, -28.524609],
                [-55.984912, -28.488574],
                [-55.930176, -28.472852],
                [-55.903662, -28.443262],
                [-55.90542, -28.399609],
                [-55.890527, -28.37002],
                [-55.858887, -28.354199],
                [-55.806055, -28.359766],
                [-55.731982, -28.386621],
                [-55.687256, -28.381641],
                [-55.671973, -28.344922],
                [-55.691504, -28.302832],
                [-55.745996, -28.255469],
                [-55.725488, -28.204102],
                [-55.582373, -28.120996],
                [-55.47666, -28.089355],
                [-55.409814, -28.037793],
                [-55.346484, -27.955957],
                [-55.24375, -27.898828],
                [-55.101514, -27.866797],
                [-55.063867, -27.835937],
                [-55.068994, -27.796289],
                [-55.039941, -27.767773],
                [-54.955908, -27.747168],
                [-54.910205, -27.708594],
                [-54.902783, -27.651953],
                [-54.875732, -27.599219],
                [-54.829102, -27.550586],
                [-54.7771, -27.53252],
                [-54.719727, -27.544922],
                [-54.665869, -27.526563],
                [-54.61543, -27.477148],
                [-54.554932, -27.454102],
                [-54.484326, -27.457324],
                [-54.448145, -27.446484],
                [-54.327002, -27.423535],
                [-54.260156, -27.382031],
                [-54.205225, -27.289648],
                [-54.156445, -27.253809],
                [-54.113818, -27.274707],
                [-54.040137, -27.24375],
                [-53.935352, -27.161133],
                [-53.915625, -27.15957],
                [-53.838184, -27.121094],
                [-53.758496, -26.97832],
                [-53.717285, -26.882812],
                [-53.727148, -26.804688],
                [-53.75332, -26.748633],
                [-53.74458, -26.666504],
                [-53.718164, -26.443164],
                [-53.710938, -26.351855],
                [-53.668555, -26.288184],
                [-53.671289, -26.225098],
                [-53.746924, -26.083691],
                [-53.823242, -25.95957],
                [-53.864209, -25.748828],
                [-53.891162, -25.668848],
                [-53.954785, -25.647656],
                [-54.012305, -25.57793],
                [-54.08501, -25.571875],
                [-54.119238, -25.545215],
                [-54.15459, -25.523047],
                [-54.206152, -25.52959],
                [-54.250098, -25.57041],
                [-54.331885, -25.571875],
                [-54.38335, -25.588672],
                [-54.443945, -25.625],
                [-54.501514, -25.608301],
                [-54.537842, -25.576465],
                [-54.615869, -25.576074],
                [-54.610547, -25.432715],
                [-54.473145, -25.220215],
                [-54.43623, -25.121289],
                [-54.454102, -25.065234],
                [-54.412988, -24.86748],
                [-54.312939, -24.528125],
                [-54.281006, -24.306055],
                [-54.317285, -24.20127],
                [-54.318262, -24.128125],
                [-54.266895, -24.06582],
                [-54.241797, -24.047266],
                [-54.370801, -23.971191],
                [-54.440234, -23.901758],
                [-54.52959, -23.852148],
                [-54.625488, -23.8125],
                [-54.671777, -23.829004],
                [-54.721387, -23.852148],
                [-54.817285, -23.888477],
                [-54.926465, -23.951367],
                [-54.982666, -23.974512],
                [-55.081885, -23.997656],
                [-55.194336, -24.01748],
                [-55.286914, -24.004297],
                [-55.366309, -23.991016],
                [-55.415918, -23.951367],
                [-55.442383, -23.865332],
                [-55.442383, -23.792578],
                [-55.458887, -23.686719],
                [-55.518457, -23.627246],
                [-55.538281, -23.580957],
                [-55.541602, -23.524707],
                [-55.534961, -23.461914],
                [-55.518457, -23.415625],
                [-55.528369, -23.359375],
                [-55.554834, -23.319629],
                [-55.548193, -23.250195],
                [-55.561426, -23.154297],
                [-55.601123, -23.094727],
                [-55.620996, -23.025293],
                [-55.620996, -22.955859],
                [-55.650732, -22.886426],
                [-55.654053, -22.810352],
                [-55.627588, -22.740918],
                [-55.617676, -22.671484],
                [-55.647412, -22.621875],
                [-55.703662, -22.59209],
                [-55.746631, -22.512695],
                [-55.753271, -22.410156],
                [-55.799561, -22.353906],
                [-55.84917, -22.307617],
                [-55.905371, -22.307617],
                [-55.991406, -22.281152],
                [-56.06748, -22.284473],
                [-56.189844, -22.281152],
                [-56.246045, -22.264648],
                [-56.275781, -22.228223],
                [-56.351855, -22.178613],
                [-56.394873, -22.092676],
                [-56.447803, -22.076172],
                [-56.523828, -22.102539],
                [-56.550293, -22.135645],
                [-56.580078, -22.181934],
                [-56.633008, -22.234863],
                [-56.702441, -22.231543],
                [-56.775195, -22.261328],
                [-56.844678, -22.264648],
                [-56.937256, -22.271289],
                [-57.029883, -22.244824],
                [-57.142334, -22.215039],
                [-57.238232, -22.195215],
                [-57.330859, -22.215039],
                [-57.393652, -22.198437],
                [-57.476367, -22.188574],
                [-57.568945, -22.181934],
                [-57.641699, -22.129004],
                [-57.721094, -22.099219],
                [-57.764063, -22.10918],
                [-57.820313, -22.142285],
                [-57.879834, -22.135645],
                [-57.955908, -22.10918],
                [-57.985693, -22.046387],
                [-57.979053, -22.006641],
                [-57.9625, -21.966992],
                [-57.932764, -21.910742],
                [-57.949316, -21.851172],
                [-57.942676, -21.79834],
                [-57.929443, -21.751953],
                [-57.916211, -21.699121],
                [-57.926172, -21.649512],
                [-57.929443, -21.596582],
                [-57.936084, -21.546973],
                [-57.945996, -21.494043],
                [-57.906299, -21.417969],
                [-57.873242, -21.355078],
                [-57.893066, -21.302246],
                [-57.886475, -21.26582],
                [-57.86001, -21.20625],
                [-57.826953, -21.133594],
                [-57.830225, -20.997949],
                [-57.86001, -20.918555],
                [-57.892236, -20.89707],
                [-57.900488, -20.873047],
                [-57.884814, -20.841699],
                [-57.901904, -20.809375],
                [-57.908496, -20.776367],
                [-57.891406, -20.747461],
                [-57.915137, -20.690332],
                [-57.9625, -20.673828],
                [-57.979053, -20.657324],
                [-57.995605, -20.594434],
                [-58.008838, -20.52168],
                [-58.002246, -20.46543],
                [-58.025391, -20.41582],
                [-58.058447, -20.386133],
                [-58.091504, -20.333203],
                [-58.124609, -20.293457],
                [-58.137793, -20.237305],
                [-58.159766, -20.164648],
                [-58.09375, -20.151074],
                [-58.067627, -20.110352],
                [-58.021143, -20.055176],
                [-57.960156, -20.040723],
                [-57.887598, -20.02041],
                [-57.860742, -19.97959],
                [-58.029932, -19.832715],
                [-58.131494, -19.744531],
                [-58.072021, -19.625293],
                [-57.97168, -19.424219],
                [-57.874512, -19.229492],
                [-57.800391, -19.080957],
                [-57.781445, -19.053516],
                [-57.716797, -19.044043],
                [-57.728613, -18.967383],
                [-57.730859, -18.917188],
                [-57.783105, -18.914258],
                [-57.725, -18.733203],
                [-57.63916, -18.475],
                [-57.574023, -18.279297],
                [-57.553125, -18.246484],
                [-57.506152, -18.237305],
                [-57.495654, -18.214648],
                [-57.552051, -18.183105],
                [-57.586475, -18.122266],
                [-57.66167, -17.947363],
                [-57.780176, -17.671777],
                [-57.788867, -17.573047],
                [-57.832471, -17.512109],
                [-57.905029, -17.532324],
                [-57.990918, -17.512891],
                [-58.205566, -17.363086],
                [-58.347754, -17.282129],
                [-58.395996, -17.234277],
                [-58.417383, -17.080566],
                [-58.459814, -16.910742],
                [-58.478125, -16.700684],
                [-58.470605, -16.650195],
                [-58.350391, -16.49082],
                [-58.350781, -16.410254],
                [-58.340576, -16.339941],
                [-58.345605, -16.284375],
                [-58.375391, -16.283594],
                [-58.423682, -16.30791],
                [-58.496582, -16.32666],
                [-58.537939, -16.328223],
                [-58.957275, -16.313184],
                [-59.434277, -16.295996],
                [-59.831152, -16.281738],
                [-60.175586, -16.269336],
                [-60.187207, -16.132129],
                [-60.206641, -15.901953],
                [-60.22041, -15.738672],
                [-60.242334, -15.47959],
                [-60.380469, -15.318262],
                [-60.530469, -15.143164],
                [-60.583203, -15.09834],
                [-60.402002, -15.092773],
                [-60.27334, -15.08877],
                [-60.298877, -14.618555],
                [-60.338037, -14.570508],
                [-60.372705, -14.41875],
                [-60.39624, -14.332812],
                [-60.460156, -14.263086],
                [-60.474658, -14.184766],
                [-60.462988, -14.132422],
                [-60.428076, -14.1],
                [-60.40498, -14.019238],
                [-60.422363, -13.937988],
                [-60.460156, -13.862402],
                [-60.506592, -13.789844],
                [-60.595312, -13.745313],
                [-60.722363, -13.664355],
                [-60.914502, -13.561426],
                [-61.077002, -13.489746],
                [-61.12915, -13.498535],
                [-61.416064, -13.526562],
                [-61.511572, -13.541211],
                [-61.575684, -13.524805],
                [-61.789941, -13.525586],
                [-61.874121, -13.47041],
                [-61.944727, -13.40625],
                [-62.094775, -13.241992],
                [-62.118018, -13.159766],
                [-62.176074, -13.133691],
                [-62.263916, -13.143652],
                [-62.352832, -13.132422],
                [-62.525537, -13.064258],
                [-62.687061, -12.994336],
                [-62.765479, -12.997266],
                [-62.835156, -12.953711],
                [-62.95791, -12.84707],
                [-63.015186, -12.805566],
                [-63.041357, -12.750391],
                [-63.06748, -12.669141],
                [-63.116797, -12.65166],
                [-63.180664, -12.666211],
                [-63.249756, -12.70791],
                [-63.34668, -12.680078],
                [-63.465234, -12.605176],
                [-63.541895, -12.54668],
                [-63.585645, -12.518945],
                [-63.688574, -12.478027],
                [-63.788086, -12.469434],
                [-63.938574, -12.529687],
                [-64.061621, -12.505078],
                [-64.255029, -12.483301],
                [-64.420508, -12.439746],
                [-64.480762, -12.326172],
                [-64.513428, -12.250977],
                [-64.61167, -12.203906],
                [-64.690039, -12.146484],
                [-64.783447, -12.059375],
                [-64.829883, -12.030273],
                [-64.914355, -12.005957],
                [-64.992529, -11.975195],
                [-65.001221, -11.92002],
                [-65.030273, -11.847363],
                [-65.037109, -11.829395],
                [-65.090283, -11.741211],
                [-65.115137, -11.735059],
                [-65.142676, -11.752344],
                [-65.163379, -11.765137],
                [-65.185742, -11.749512],
                [-65.189746, -11.710059],
                [-65.175391, -11.646875],
                [-65.206201, -11.580566],
                [-65.282275, -11.511035],
                [-65.322021, -11.43916],
                [-65.325488, -11.364746],
                [-65.342383, -11.315039],
                [-65.372852, -11.289941],
                [-65.389893, -11.246289],
                [-65.393604, -11.184277],
                [-65.371582, -11.110352],
                [-65.323779, -11.024805],
                [-65.334033, -10.892773],
                [-65.402295, -10.714746],
                [-65.43999, -10.58623],
                [-65.447119, -10.507422],
                [-65.436914, -10.449023],
                [-65.395459, -10.392285],
                [-65.313086, -10.253027],
                [-65.298584, -10.146777],
                [-65.324561, -10.026953],
                [-65.328125, -9.935547],
                [-65.309326, -9.872656],
                [-65.337891, -9.790234],
                [-65.396143, -9.712402],
                [-65.436768, -9.710449],
                [-65.491992, -9.731738],
                [-65.558691, -9.797461],
                [-65.637109, -9.809082],
                [-65.706787, -9.768457],
                [-65.924707, -9.785449],
                [-66.263574, -9.826074],
                [-66.399219, -9.868164],
                [-66.478906, -9.886133],
                [-66.575342, -9.899902],
                [-66.72998, -9.975488],
                [-67.111523, -10.268945],
                [-67.190479, -10.311426],
                [-67.280469, -10.317285],
                [-67.332715, -10.35791],
                [-67.416943, -10.389844],
                [-67.582422, -10.505957],
                [-67.66665, -10.598926],
                [-67.721777, -10.683105],
                [-67.785693, -10.686035],
                [-67.83501, -10.662793],
                [-67.991699, -10.674414],
                [-68.07168, -10.703125],
                [-68.158643, -10.785059],
                [-68.266602, -10.933105],
                [-68.311133, -10.975195],
                [-68.397998, -11.01875],
                [-68.49834, -11.054785],
                [-68.622656, -11.10918],
                [-68.678369, -11.112793],
                [-68.72749, -11.122461],
                [-68.769922, -11.097656],
                [-68.784082, -11.044629],
                [-68.84834, -11.011133],
                [-69.00166, -10.994336],
                [-69.228516, -10.955664],
                [-69.462549, -10.948145],
                [-69.578613, -10.951758],
                [-69.674023, -10.954102],
                [-69.839795, -10.933398],
                [-69.960352, -10.929883],
                [-70.066309, -10.982422],
                [-70.220068, -11.047656],
                [-70.290381, -11.064258],
                [-70.341992, -11.066699],
                [-70.392285, -11.058594],
                [-70.450879, -11.024805],
                [-70.533252, -10.946875],
                [-70.596533, -10.976855],
                [-70.642334, -11.010254],
                [-70.641553, -10.84082],
                [-70.640332, -10.586035],
                [-70.639355, -10.361328],
                [-70.638525, -10.181543],
                [-70.637598, -9.971777],
                [-70.636914, -9.82373],
                [-70.593799, -9.76748],
                [-70.567236, -9.70459],
                [-70.59917, -9.620508],
                [-70.592236, -9.543457],
                [-70.570166, -9.489844],
                [-70.541113, -9.4375],
                [-70.60791, -9.463672],
                [-70.636914, -9.478223],
                [-70.672461, -9.517969],
                [-70.758496, -9.57168],
                [-70.81626, -9.625293],
                [-70.884521, -9.669043],
                [-70.970752, -9.765723],
                [-71.041748, -9.81875],
                [-71.115283, -9.852441],
                [-71.237939, -9.966016],
                [-71.339404, -9.988574],
                [-71.608008, -10.006055],
                [-71.887451, -10.005566],
                [-72.142969, -10.005176],
                [-72.181592, -10.003711],
                [-72.179102, -9.910156],
                [-72.172852, -9.844043],
                [-72.259961, -9.774316],
                [-72.26582, -9.688477],
                [-72.289014, -9.629199],
                [-72.318066, -9.556641],
                [-72.379053, -9.510156],
                [-72.464746, -9.492187],
                [-72.605469, -9.452051],
                [-72.814258, -9.410352],
                [-73.01377, -9.407422],
                [-73.209424, -9.411426],
                [-73.089844, -9.265723],
                [-72.970361, -9.120117],
                [-72.974023, -8.993164],
                [-73.070508, -8.882812],
                [-73.122559, -8.814063],
                [-73.203125, -8.719336],
                [-73.302441, -8.654004],
                [-73.356738, -8.566992],
                [-73.351709, -8.51416],
                [-73.3604, -8.479297],
                [-73.398145, -8.458984],
                [-73.435889, -8.427051],
                [-73.488135, -8.392187],
                [-73.549121, -8.345801],
                [-73.549121, -8.299316],
                [-73.572363, -8.249902],
                [-73.610107, -8.191895],
                [-73.610107, -8.14541],
                [-73.644922, -8.072852],
                [-73.682666, -8.020605],
                [-73.72041, -7.985742],
                [-73.775586, -7.936426],
                [-73.772705, -7.895703],
                [-73.732031, -7.875391],
                [-73.7146, -7.829004],
                [-73.72041, -7.78252],
                [-73.766895, -7.753516],
                [-73.82207, -7.738965],
                [-73.894629, -7.654785],
                [-73.946875, -7.61123],
                [-73.981738, -7.585059],
                [-74.002051, -7.556055],
                [-73.981738, -7.535742],
                [-73.958496, -7.506641],
                [-73.952686, -7.460254],
                [-73.964307, -7.416699],
                [-73.964307, -7.378906],
                [-73.929443, -7.367285],
                [-73.891748, -7.373145],
                [-73.854004, -7.349902],
                [-73.804639, -7.341211],
                [-73.749463, -7.335352],
                [-73.72041, -7.309277],
                [-73.72334, -7.262793],
                [-73.758203, -7.172754],
                [-73.793018, -7.135059],
                [-73.804639, -7.079883],
                [-73.77627, -6.973535],
                [-73.758105, -6.905762],
                [-73.694531, -6.833789],
                [-73.499902, -6.679492],
                [-73.325488, -6.574707],
                [-73.240332, -6.564063],
                [-73.177441, -6.525195],
                [-73.137354, -6.46582],
                [-73.126318, -6.400879],
                [-73.135352, -6.344336],
                [-73.167725, -6.260645],
                [-73.206494, -6.156445],
                [-73.235547, -6.098438],
                [-73.209375, -6.028711],
                [-73.162891, -5.933398],
                [-73.068066, -5.789551],
                [-72.979883, -5.634863],
                [-72.970215, -5.589648],
                [-72.958936, -5.495215],
                [-72.918262, -5.302539],
                [-72.895801, -5.198242],
                [-72.907471, -5.157715],
                [-72.887061, -5.122754],
                [-72.831934, -5.09375],
                [-72.69873, -5.067187],
                [-72.60835, -5.00957],
                [-72.468994, -4.90127],
                [-72.352832, -4.786035],
                [-72.256787, -4.748926],
                [-72.08252, -4.642285],
                [-71.982422, -4.574609],
                [-71.943164, -4.55332],
                [-71.844727, -4.504395],
                [-71.668359, -4.487305],
                [-71.521338, -4.469727],
                [-71.438281, -4.437598],
                [-71.316797, -4.424316],
                [-71.23501, -4.388184],
                [-71.144238, -4.387207],
                [-70.973682, -4.350488],
                [-70.915625, -4.295313],
                [-70.866016, -4.22959],
                [-70.799512, -4.17334],
                [-70.721582, -4.158887],
                [-70.63457, -4.168652],
                [-70.530664, -4.167578],
                [-70.404639, -4.150098],
                [-70.343652, -4.193652],
                [-70.316895, -4.246973],
                [-70.23916, -4.301172],
                [-70.183984, -4.298145],
                [-70.128809, -4.286621],
                [-70.05332, -4.333105],
                [-70.003955, -4.327246],
                [-69.972021, -4.301172],
                [-69.965918, -4.235938],
                [-69.948193, -4.200586],
                [-69.911035, -3.996582],
                [-69.849756, -3.659863],
                [-69.794141, -3.35459],
                [-69.732617, -3.016699],
                [-69.669043, -2.667676],
                [-69.604687, -2.314258],
                [-69.551855, -2.024219],
                [-69.506445, -1.774902],
                [-69.478613, -1.621973],
                [-69.434912, -1.42168],
                [-69.417871, -1.245703],
                [-69.400244, -1.194922],
                [-69.411426, -1.152246],
                [-69.449121, -1.091602],
                [-69.44873, -1.064941],
                [-69.444336, -1.02959],
                [-69.44873, -0.99873],
                [-69.488428, -0.965723],
                [-69.519287, -0.945801],
                [-69.543555, -0.917187],
                [-69.55459, -0.877441],
                [-69.574414, -0.837793],
                [-69.583252, -0.795898],
                [-69.611914, -0.762793],
                [-69.620703, -0.720898],
                [-69.600879, -0.68125],
                [-69.592041, -0.639355],
                [-69.600879, -0.599609],
                [-69.611914, -0.55332],
                [-69.633984, -0.509277],
                [-69.66748, -0.482422],
                [-69.747461, -0.452539],
                [-69.82793, -0.381348],
                [-69.922754, -0.31748],
                [-70.044043, -0.196191],
                [-70.070508, -0.138867],
                [-70.070947, 0.018555],
                [-70.065723, 0.189355],
                [-70.05791, 0.447363],
                [-70.053906, 0.578613],
                [-69.985449, 0.58584],
                [-69.925098, 0.589404],
                [-69.862061, 0.598486],
                [-69.807129, 0.607471],
                [-69.756738, 0.626367],
                [-69.718896, 0.649805],
                [-69.673828, 0.665088],
                [-69.638721, 0.659668],
                [-69.603613, 0.680371],
                [-69.564844, 0.700195],
                [-69.527051, 0.716406],
                [-69.472119, 0.729932],
                [-69.420801, 0.698389],
                [-69.391992, 0.666895],
                [-69.358643, 0.651562],
                [-69.327148, 0.655176],
                [-69.305518, 0.652441],
                [-69.283008, 0.627246],
                [-69.254199, 0.625439],
                [-69.212793, 0.629932],
                [-69.174072, 0.635352],
                [-69.156055, 0.642529],
                [-69.15332, 0.658789],
                [-69.163232, 0.68667],
                [-69.176758, 0.712842],
                [-69.165967, 0.75332],
                [-69.165039, 0.801953],
                [-69.163232, 0.864063],
                [-69.193848, 0.898291],
                [-69.224463, 0.963135],
                [-69.258691, 1.015381],
                [-69.311816, 1.050488],
                [-69.361377, 1.064014],
                [-69.402783, 1.042383],
                [-69.441504, 1.038818],
                [-69.470312, 1.058594],
                [-69.517139, 1.059473],
                [-69.567578, 1.065771],
                [-69.620898, 1.073242],
                [-69.716992, 1.059082],
                [-69.751318, 1.076611],
                [-69.798145, 1.078418],
                [-69.852148, 1.059521],
                [-69.850781, 1.308789],
                [-69.849463, 1.543896],
                [-69.848584, 1.70874],
                [-69.799951, 1.705176],
                [-69.7396, 1.734863],
                [-69.650049, 1.739453],
                [-69.58125, 1.770752],
                [-69.54292, 1.773242],
                [-69.470166, 1.75791],
                [-69.394336, 1.725781],
                [-69.319727, 1.72124],
                [-69.124268, 1.721289],
                [-68.913184, 1.721387],
                [-68.678467, 1.721484],
                [-68.443457, 1.721582],
                [-68.239551, 1.72168],
                [-68.176562, 1.719824],
                [-68.213281, 1.774561],
                [-68.255957, 1.845508],
                [-68.239453, 1.901367],
                [-68.218359, 1.957617],
                [-68.193799, 1.987012],
                [-68.130273, 1.955762],
                [-68.077051, 1.860107],
                [-68.032861, 1.788037],
                [-67.989746, 1.752539],
                [-67.93623, 1.748486],
                [-67.875537, 1.760596],
                [-67.815088, 1.790088],
                [-67.711865, 1.922119],
                [-67.609229, 2.035059],
                [-67.556055, 2.072998],
                [-67.499658, 2.10791],
                [-67.457764, 2.121143],
                [-67.400439, 2.116699],
                [-67.351953, 2.08584],
                [-67.320605, 2.03208],
                [-67.205811, 1.844824],
                [-67.119238, 1.703613],
                [-67.090137, 1.615576],
                [-67.088281, 1.400586],
                [-67.093652, 1.21001],
                [-67.082275, 1.1854],
                [-67.065234, 1.178369],
                [-66.876025, 1.223047],
                [-66.619043, 0.992139],
                [-66.429248, 0.82168],
                [-66.347119, 0.767187],
                [-66.30166, 0.751953],
                [-66.191211, 0.763281],
                [-66.060059, 0.785352],
                [-65.996338, 0.809766],
                [-65.925879, 0.863135],
                [-65.811328, 0.937256],
                [-65.718115, 0.978027],
                [-65.681445, 0.983447],
                [-65.644678, 0.970361],
                [-65.566016, 0.926074],
                [-65.522998, 0.843408],
                [-65.562695, 0.74751],
                [-65.556055, 0.687988],
                [-65.473389, 0.69126],
                [-65.407227, 0.790479],
                [-65.36084, 0.868652],
                [-65.263965, 0.931885],
                [-65.169629, 1.022217],
                [-65.10376, 1.108105],
                [-65.026562, 1.158447],
                [-64.910107, 1.219727],
                [-64.817969, 1.257129],
                [-64.731543, 1.25332],
                [-64.667432, 1.293848],
                [-64.584375, 1.369873],
                [-64.52627, 1.431006],
                [-64.486035, 1.452783],
                [-64.405127, 1.446875],
                [-64.304199, 1.455273],
                [-64.205029, 1.529492],
                [-64.114844, 1.619287],
                [-64.067041, 1.770508],
                [-64.035449, 1.904443],
                [-64.008496, 1.931592],
                [-63.975781, 1.953027],
                [-63.937158, 1.966992],
                [-63.844482, 1.976709],
                [-63.682129, 2.048145],
                [-63.570264, 2.120508],
                [-63.463916, 2.136035],
                [-63.43252, 2.155566],
                [-63.393945, 2.22251],
                [-63.374854, 2.34043],
                [-63.389258, 2.411914],
                [-63.584619, 2.433936],
                [-63.712549, 2.434033],
                [-63.92417, 2.452441],
                [-64.024902, 2.481885],
                [-64.046582, 2.502393],
                [-64.048828, 2.525098],
                [-64.028711, 2.576074],
                [-64.009033, 2.671875],
                [-64.037793, 2.801514],
                [-64.143555, 3.004883],
                [-64.218848, 3.204687],
                [-64.22876, 3.343994],
                [-64.227051, 3.491211],
                [-64.221094, 3.587402],
                [-64.275293, 3.662695],
                [-64.56792, 3.899805],
                [-64.668994, 4.011816],
                [-64.702588, 4.089307],
                [-64.817871, 4.232275],
                [-64.788672, 4.276025],
                [-64.722266, 4.274414],
                [-64.665527, 4.237109],
                [-64.613672, 4.157715],
                [-64.576367, 4.139893],
                [-64.525537, 4.13999],
                [-64.255664, 4.140332],
                [-64.19248, 4.126855],
                [-64.154297, 4.100146],
                [-64.121729, 4.066992],
                [-64.073389, 3.974414],
                [-64.021484, 3.929102],
                [-63.914648, 3.930664],
                [-63.746973, 3.932568],
                [-63.65293, 3.94082],
                [-63.596631, 3.915039],
                [-63.526807, 3.893701],
                [-63.379785, 3.942871],
                [-63.338672, 3.943896],
                [-63.294727, 3.922266],
                [-63.13623, 3.756445],
                [-63.045313, 3.686475],
                [-62.968652, 3.593945],
                [-62.856982, 3.593457],
                [-62.7646, 3.672949],
                [-62.739941, 3.940332],
                [-62.712109, 4.01792],
                [-62.665332, 4.039648],
                [-62.609766, 4.042285],
                [-62.543945, 4.084326],
                [-62.472559, 4.138525],
                [-62.410645, 4.156738],
                [-62.153125, 4.098389],
                [-62.081592, 4.126318],
                [-61.82085, 4.197021],
                [-61.554248, 4.287793],
                [-61.479395, 4.402246],
                [-61.367529, 4.433008],
                [-61.280078, 4.516895],
                [-61.209424, 4.508057],
                [-61.102441, 4.504687],
                [-61.036279, 4.519336],
                [-61.002832, 4.535254],
                [-60.966406, 4.574707],
                [-60.90625, 4.686816],
                [-60.833398, 4.729199],
                [-60.741748, 4.774121],
                [-60.67915, 4.8271],
                [-60.627588, 4.892529],
                [-60.603857, 4.949365],
                [-60.604492, 4.99458],
                [-60.63501, 5.081982],
                [-60.671973, 5.164355],
                [-60.711963, 5.191553],
                [-60.742139, 5.202051],
                [-60.651367, 5.221143],
                [-60.576416, 5.19248],
                [-60.459521, 5.188086],
                [-60.408789, 5.210156],
                [-60.335205, 5.199316],
                [-60.24165, 5.257959]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Chile",
          "density": "26",
          "cartodb_id": 41,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-67.575195, -55.889648],
                [-67.611426, -55.891699],
                [-67.699512, -55.873145],
                [-67.831543, -55.864844],
                [-67.846436, -55.857227],
                [-67.849707, -55.842578],
                [-67.834082, -55.827539],
                [-67.762061, -55.816113],
                [-67.544824, -55.825977],
                [-67.517285, -55.832812],
                [-67.509814, -55.844336],
                [-67.545264, -55.877441],
                [-67.575195, -55.889648]
              ]
            ],
            [
              [
                [-67.288867, -55.776855],
                [-67.325293, -55.784766],
                [-67.352246, -55.766016],
                [-67.393359, -55.752734],
                [-67.559961, -55.724805],
                [-67.563477, -55.707813],
                [-67.546143, -55.683691],
                [-67.512793, -55.662012],
                [-67.448828, -55.640625],
                [-67.397363, -55.585156],
                [-67.374072, -55.589355],
                [-67.350586, -55.612109],
                [-67.310449, -55.688672],
                [-67.262451, -55.74375],
                [-67.267285, -55.762793],
                [-67.288867, -55.776855]
              ]
            ],
            [
              [
                [-66.472119, -55.229102],
                [-66.551709, -55.272852],
                [-66.611133, -55.269922],
                [-66.630176, -55.254102],
                [-66.636621, -55.234375],
                [-66.624756, -55.213086],
                [-66.599707, -55.193652],
                [-66.541553, -55.169434],
                [-66.523145, -55.165527],
                [-66.435791, -55.189746],
                [-66.472119, -55.229102]
              ]
            ],
            [
              [
                [-67.079932, -55.153809],
                [-67.109473, -55.19209],
                [-67.172559, -55.242578],
                [-67.257422, -55.281836],
                [-67.339697, -55.292578],
                [-67.399268, -55.272266],
                [-67.429395, -55.236523],
                [-67.443262, -55.201172],
                [-67.463477, -55.181738],
                [-67.494727, -55.177441],
                [-67.535254, -55.178516],
                [-67.585205, -55.191992],
                [-67.691455, -55.242969],
                [-67.736963, -55.256445],
                [-67.767773, -55.25957],
                [-68.07002, -55.221094],
                [-68.099512, -55.206836],
                [-68.135107, -55.172656],
                [-68.174316, -55.071289],
                [-68.301367, -54.980664],
                [-68.106934, -54.929395],
                [-67.874121, -54.929688],
                [-67.424561, -54.968945],
                [-67.245264, -54.977637],
                [-67.107324, -55.063574],
                [-67.085498, -55.115234],
                [-67.079932, -55.153809]
              ]
            ],
            [
              [
                [-69.702979, -54.919043],
                [-68.900781, -55.017773],
                [-68.653516, -54.95791],
                [-68.458008, -54.959668],
                [-68.399805, -55.041992],
                [-68.598096, -55.12832],
                [-68.613281, -55.155566],
                [-68.585547, -55.177734],
                [-68.381738, -55.191602],
                [-68.330078, -55.219434],
                [-68.282666, -55.255176],
                [-68.322754, -55.308203],
                [-68.326562, -55.332715],
                [-68.30542, -55.356641],
                [-68.152588, -55.436914],
                [-68.089893, -55.47832],
                [-68.058301, -55.517969],
                [-68.045557, -55.5875],
                [-68.04834, -55.643164],
                [-68.082666, -55.650586],
                [-68.15708, -55.633691],
                [-68.229639, -55.601562],
                [-68.293359, -55.521387],
                [-68.338037, -55.505273],
                [-68.466699, -55.489063],
                [-68.594189, -55.45],
                [-68.693555, -55.452246],
                [-68.78501, -55.435645],
                [-68.867041, -55.450195],
                [-68.896143, -55.423828],
                [-68.931299, -55.370605],
                [-68.93208, -55.347363],
                [-68.888965, -55.263281],
                [-68.890088, -55.241211],
                [-68.912646, -55.238574],
                [-69.008203, -55.255762],
                [-69.046826, -55.244336],
                [-69.150781, -55.183398],
                [-69.192627, -55.171875],
                [-69.29707, -55.16582],
                [-69.356152, -55.273926],
                [-69.359229, -55.300684],
                [-69.299023, -55.369336],
                [-69.180859, -55.474805],
                [-69.24082, -55.476758],
                [-69.411816, -55.444238],
                [-69.455713, -55.424023],
                [-69.508691, -55.370898],
                [-69.610254, -55.339941],
                [-69.645898, -55.320898],
                [-69.656299, -55.298438],
                [-69.657373, -55.229004],
                [-69.679834, -55.218945],
                [-69.824023, -55.236523],
                [-69.853711, -55.219824],
                [-69.865771, -55.190625],
                [-69.886768, -55.174121],
                [-69.979785, -55.147461],
                [-69.987988, -55.130762],
                [-69.946533, -55.111035],
                [-69.92085, -55.061133],
                [-69.884424, -54.882031],
                [-69.702979, -54.919043]
              ]
            ],
            [
              [
                [-70.991602, -54.867969],
                [-70.945117, -54.931348],
                [-70.92793, -54.942969],
                [-70.804834, -54.967676],
                [-70.749316, -54.952734],
                [-70.615283, -54.945605],
                [-70.534766, -54.921289],
                [-70.417529, -54.908887],
                [-70.283057, -55.065918],
                [-70.297852, -55.11377],
                [-70.40415, -55.165625],
                [-70.475586, -55.177051],
                [-70.543457, -55.161328],
                [-70.538721, -55.134961],
                [-70.551074, -55.111914],
                [-70.597461, -55.082031],
                [-70.640918, -55.084863],
                [-70.710986, -55.106934],
                [-70.744434, -55.104199],
                [-70.815479, -55.079883],
                [-70.939844, -55.061914],
                [-70.964502, -55.039648],
                [-70.967285, -55.006836],
                [-70.990723, -54.99043],
                [-71.120361, -54.937793],
                [-71.20332, -54.892969],
                [-71.273633, -54.886914],
                [-71.299316, -54.892285],
                [-71.325342, -54.91377],
                [-71.388574, -54.934277],
                [-71.406641, -54.930859],
                [-71.426904, -54.91377],
                [-71.437207, -54.889258],
                [-71.410547, -54.839355],
                [-71.374268, -54.83457],
                [-71.19707, -54.844434],
                [-71.088623, -54.86748],
                [-70.991602, -54.867969]
              ]
            ],
            [
              [
                [-71.390479, -54.032813],
                [-71.16875, -54.112598],
                [-71.021924, -54.111816],
                [-71.022852, -54.161719],
                [-71.004883, -54.24668],
                [-71.028027, -54.281152],
                [-71.082959, -54.316309],
                [-71.117529, -54.366309],
                [-71.143262, -54.374023],
                [-71.304639, -54.313574],
                [-71.473291, -54.231152],
                [-71.558105, -54.245605],
                [-71.670605, -54.225391],
                [-71.76123, -54.229785],
                [-71.817578, -54.276465],
                [-71.948535, -54.300879],
                [-71.972363, -54.207227],
                [-72.091553, -54.11875],
                [-72.210449, -54.047754],
                [-72.146045, -53.938867],
                [-72.068945, -53.921289],
                [-71.996484, -53.884863],
                [-71.705127, -53.92334],
                [-71.55415, -53.956055],
                [-71.390479, -54.032813]
              ]
            ],
            [
              [
                [-72.923242, -53.481641],
                [-72.896289, -53.562793],
                [-72.882227, -53.57832],
                [-72.809375, -53.565332],
                [-72.685498, -53.55791],
                [-72.482275, -53.588086],
                [-72.459229, -53.598828],
                [-72.3729, -53.6875],
                [-72.306689, -53.725391],
                [-72.20542, -53.807422],
                [-72.30625, -53.862109],
                [-72.365967, -53.94082],
                [-72.369141, -53.980762],
                [-72.408545, -54.003809],
                [-72.470508, -54.027734],
                [-72.562891, -54.07373],
                [-72.676562, -54.078906],
                [-72.788623, -54.103125],
                [-72.840381, -54.125098],
                [-72.870996, -54.126563],
                [-72.907275, -54.114648],
                [-72.946094, -54.09209],
                [-72.958594, -54.065918],
                [-72.881738, -54.041602],
                [-72.781689, -53.954785],
                [-72.76377, -53.864844],
                [-72.871729, -53.848535],
                [-72.936133, -53.86084],
                [-72.984229, -53.860547],
                [-73.039453, -53.832813],
                [-73.073047, -53.875293],
                [-73.085547, -53.915918],
                [-73.07085, -53.978027],
                [-73.080762, -53.998047],
                [-73.119971, -54.009375],
                [-73.210645, -53.98584],
                [-73.304736, -53.943945],
                [-73.312158, -53.919629],
                [-73.292871, -53.83584],
                [-73.294922, -53.79209],
                [-73.314355, -53.729199],
                [-73.324805, -53.722656],
                [-73.360107, -53.724023],
                [-73.470947, -53.736133],
                [-73.581641, -53.655469],
                [-73.641504, -53.570313],
                [-73.845459, -53.545801],
                [-73.686523, -53.426855],
                [-73.44707, -53.410059],
                [-73.365869, -53.470215],
                [-73.099365, -53.511914],
                [-73.115332, -53.448047],
                [-73.110889, -53.425195],
                [-73.074316, -53.396777],
                [-73.053613, -53.394434],
                [-73.02207, -53.414551],
                [-72.970947, -53.423047],
                [-72.947266, -53.44248],
                [-72.923242, -53.481641]
              ]
            ],
            [
              [
                [-74.385742, -52.922363],
                [-74.369336, -52.931445],
                [-74.32998, -52.929297],
                [-74.274609, -52.945508],
                [-74.065967, -52.965332],
                [-73.879199, -53.012207],
                [-73.781787, -53.056055],
                [-73.654004, -53.069824],
                [-73.549268, -53.125684],
                [-73.504541, -53.140039],
                [-73.450586, -53.144336],
                [-73.310352, -53.247656],
                [-73.30249, -53.259473],
                [-73.143359, -53.340918],
                [-73.135205, -53.353906],
                [-73.225732, -53.358398],
                [-73.409375, -53.320508],
                [-73.501025, -53.318457],
                [-73.567285, -53.306836],
                [-73.582812, -53.300195],
                [-73.595947, -53.25293],
                [-73.61709, -53.229688],
                [-73.793506, -53.120703],
                [-73.866943, -53.096875],
                [-73.993994, -53.075781],
                [-74.138574, -53.090527],
                [-74.236377, -53.076465],
                [-74.270215, -53.081543],
                [-74.414404, -52.994922],
                [-74.558301, -52.921875],
                [-74.619922, -52.834766],
                [-74.711523, -52.768164],
                [-74.712012, -52.74873],
                [-74.669971, -52.733887],
                [-74.571533, -52.771289],
                [-74.474561, -52.835645],
                [-74.422266, -52.860059],
                [-74.385742, -52.922363]
              ]
            ],
            [
              [
                [-69.167041, -52.667578],
                [-69.079932, -52.674316],
                [-68.789795, -52.576758],
                [-68.75752, -52.582031],
                [-68.659229, -52.631543],
                [-68.629932, -52.652637],
                [-68.631689, -52.949512],
                [-68.633447, -53.241895],
                [-68.635059, -53.51543],
                [-68.63667, -53.788867],
                [-68.638232, -54.05293],
                [-68.639795, -54.324023],
                [-68.64751, -54.627832],
                [-68.653223, -54.853613],
                [-68.803857, -54.853613],
                [-68.843555, -54.876758],
                [-69.081641, -54.909863],
                [-69.486279, -54.858887],
                [-69.587549, -54.812793],
                [-69.723438, -54.712109],
                [-69.771826, -54.73916],
                [-69.899463, -54.781836],
                [-70.030518, -54.815527],
                [-70.138086, -54.819238],
                [-70.237793, -54.777539],
                [-70.259082, -54.756348],
                [-70.281738, -54.751758],
                [-70.497168, -54.80957],
                [-70.735156, -54.750586],
                [-70.924707, -54.714355],
                [-71.229248, -54.694141],
                [-71.440918, -54.619629],
                [-71.831543, -54.626172],
                [-71.901562, -54.601562],
                [-71.927734, -54.528711],
                [-71.906982, -54.494824],
                [-71.823438, -54.474414],
                [-71.800146, -54.433984],
                [-71.71582, -54.443652],
                [-71.606299, -54.497168],
                [-71.572754, -54.495313],
                [-71.500391, -54.444922],
                [-71.393408, -54.400195],
                [-71.355225, -54.39541],
                [-71.158838, -54.450586],
                [-71.079932, -54.444238],
                [-70.966455, -54.419531],
                [-70.946191, -54.398047],
                [-70.928174, -54.360059],
                [-70.898242, -54.337891],
                [-70.797266, -54.327246],
                [-70.698828, -54.348828],
                [-70.687549, -54.414746],
                [-70.701123, -54.485449],
                [-70.572998, -54.504395],
                [-70.41792, -54.502246],
                [-70.310986, -54.528516],
                [-70.297656, -54.485547],
                [-70.468311, -54.373242],
                [-70.53999, -54.303418],
                [-70.636133, -54.262305],
                [-70.759863, -54.241309],
                [-70.863086, -54.110449],
                [-70.856738, -53.995801],
                [-70.867725, -53.88418],
                [-70.644482, -53.822852],
                [-70.695605, -53.727441],
                [-70.61875, -53.655078],
                [-70.531299, -53.627344],
                [-70.443164, -53.893457],
                [-70.379736, -53.986719],
                [-70.460547, -54.005664],
                [-70.629834, -54.005566],
                [-70.535303, -54.136133],
                [-70.37998, -54.180664],
                [-70.246094, -54.277441],
                [-70.243359, -54.347656],
                [-70.168994, -54.379297],
                [-69.990137, -54.381348],
                [-69.866992, -54.36748],
                [-69.809082, -54.320801],
                [-69.741846, -54.305859],
                [-69.62168, -54.364063],
                [-69.419287, -54.407129],
                [-69.364795, -54.437598],
                [-69.325098, -54.488184],
                [-69.322461, -54.542676],
                [-69.312061, -54.571484],
                [-69.253174, -54.557422],
                [-69.169189, -54.483301],
                [-69.127881, -54.457617],
                [-69.077246, -54.44502],
                [-69.045215, -54.428418],
                [-69.044336, -54.406738],
                [-69.195654, -54.354395],
                [-69.988135, -54.109082],
                [-70.085596, -54.011133],
                [-70.151123, -53.888086],
                [-70.148828, -53.761133],
                [-70.091113, -53.721777],
                [-69.949707, -53.671582],
                [-69.689746, -53.600879],
                [-69.389941, -53.499414],
                [-69.352441, -53.47998],
                [-69.355957, -53.416309],
                [-69.393555, -53.373437],
                [-69.512549, -53.341992],
                [-69.637012, -53.334082],
                [-69.755615, -53.337207],
                [-69.874121, -53.350488],
                [-70.090381, -53.418164],
                [-70.212842, -53.413965],
                [-70.329297, -53.377637],
                [-70.415674, -53.304785],
                [-70.460254, -53.20625],
                [-70.459961, -53.143359],
                [-70.443359, -53.085547],
                [-70.390674, -53.026465],
                [-70.32002, -53.000684],
                [-70.256348, -53.004102],
                [-70.196484, -52.990234],
                [-70.160889, -52.969922],
                [-70.130615, -52.942773],
                [-70.139551, -52.919336],
                [-70.162744, -52.899023],
                [-70.25918, -52.857227],
                [-70.297363, -52.816992],
                [-70.380127, -52.751953],
                [-70.334912, -52.733789],
                [-70.189648, -52.723633],
                [-70.088232, -52.768555],
                [-69.993555, -52.821289],
                [-69.935449, -52.821094],
                [-69.883203, -52.799023],
                [-69.763574, -52.731348],
                [-69.663281, -52.646289],
                [-69.571875, -52.549316],
                [-69.498389, -52.491406],
                [-69.414062, -52.48623],
                [-69.167041, -52.667578]
              ]
            ],
            [
              [
                [-74.142187, -51.931055],
                [-74.17207, -51.94209],
                [-74.283105, -51.91875],
                [-74.338672, -51.897949],
                [-74.423633, -51.845117],
                [-74.437109, -51.790625],
                [-74.475391, -51.725684],
                [-74.450781, -51.724902],
                [-74.362109, -51.750684],
                [-74.325684, -51.770215],
                [-74.277051, -51.811621],
                [-74.133398, -51.870898],
                [-74.11543, -51.888477],
                [-74.118896, -51.911133],
                [-74.142187, -51.931055]
              ]
            ],
            [
              [
                [-74.822949, -51.630176],
                [-74.780127, -51.824707],
                [-74.749512, -51.851855],
                [-74.647461, -51.866211],
                [-74.536816, -51.965137],
                [-74.531836, -51.991992],
                [-74.665967, -52.160059],
                [-74.694482, -52.279199],
                [-74.851807, -52.270703],
                [-74.917725, -52.152246],
                [-75.017139, -52.037891],
                [-75.050684, -51.903906],
                [-75.105371, -51.788867],
                [-75.008105, -51.72373],
                [-74.915186, -51.738281],
                [-74.909668, -51.65],
                [-74.822949, -51.630176]
              ]
            ],
            [
              [
                [-74.558643, -51.277051],
                [-74.560889, -51.36084],
                [-74.592578, -51.3875],
                [-74.620361, -51.395703],
                [-74.690723, -51.370215],
                [-74.730908, -51.367383],
                [-74.797363, -51.411719],
                [-74.85332, -51.43418],
                [-74.93667, -51.42832],
                [-75.047363, -51.39834],
                [-75.146289, -51.524316],
                [-75.192432, -51.566699],
                [-75.289111, -51.625391],
                [-75.300049, -51.556445],
                [-75.238477, -51.453516],
                [-75.21001, -51.383301],
                [-75.153662, -51.278809],
                [-75.040332, -51.318164],
                [-74.881445, -51.279492],
                [-74.73667, -51.207617],
                [-74.611572, -51.207129],
                [-74.570508, -51.24541],
                [-74.558643, -51.277051]
              ]
            ],
            [
              [
                [-75.302002, -50.67998],
                [-75.330469, -50.772363],
                [-75.411377, -50.764355],
                [-75.438525, -50.741113],
                [-75.452637, -50.68252],
                [-75.477393, -50.654199],
                [-75.442676, -50.595508],
                [-75.419775, -50.530371],
                [-75.427637, -50.480566],
                [-75.303711, -50.483984],
                [-75.156152, -50.496777],
                [-75.115332, -50.510449],
                [-75.160449, -50.554395],
                [-75.203418, -50.580664],
                [-75.292334, -50.596875],
                [-75.302002, -50.67998]
              ]
            ],
            [
              [
                [-75.054785, -50.296094],
                [-75.250391, -50.37627],
                [-75.307861, -50.343066],
                [-75.449121, -50.343359],
                [-75.412109, -50.256641],
                [-75.397852, -50.192676],
                [-75.376709, -50.167969],
                [-75.368848, -50.112695],
                [-75.32666, -50.011816],
                [-75.209668, -50.04541],
                [-75.122559, -50.055273],
                [-75.004248, -50.088672],
                [-74.875977, -50.109961],
                [-74.838574, -50.197266],
                [-74.963379, -50.237305],
                [-75.054785, -50.296094]
              ]
            ],
            [
              [
                [-75.106689, -48.836523],
                [-75.115088, -48.916016],
                [-75.262695, -49.068945],
                [-75.389941, -49.15918],
                [-75.506104, -49.230664],
                [-75.580371, -49.22998],
                [-75.641162, -49.19541],
                [-75.572852, -49.138867],
                [-75.487646, -49.082422],
                [-75.514551, -49.00957],
                [-75.540137, -48.988477],
                [-75.576172, -48.980762],
                [-75.637842, -48.942578],
                [-75.619141, -48.885938],
                [-75.583105, -48.858887],
                [-75.535254, -48.838184],
                [-75.490479, -48.850488],
                [-75.297266, -48.810645],
                [-75.236182, -48.778613],
                [-75.118604, -48.772949],
                [-75.106689, -48.836523]
              ]
            ],
            [
              [
                [-74.476172, -49.147852],
                [-74.466797, -49.294531],
                [-74.483594, -49.441895],
                [-74.52207, -49.622949],
                [-74.515771, -49.65957],
                [-74.47085, -49.668555],
                [-74.458838, -49.691113],
                [-74.471973, -49.78623],
                [-74.496094, -49.859473],
                [-74.542578, -49.919141],
                [-74.569824, -49.990723],
                [-74.594727, -50.006641],
                [-74.703369, -50.019238],
                [-74.762988, -50.011426],
                [-74.81084, -49.929687],
                [-74.824707, -49.879492],
                [-74.821924, -49.813867],
                [-74.88042, -49.725879],
                [-74.882227, -49.692187],
                [-74.859326, -49.63418],
                [-74.812012, -49.605273],
                [-74.804834, -49.516016],
                [-74.781006, -49.489258],
                [-74.727051, -49.452344],
                [-74.718848, -49.437012],
                [-74.723828, -49.423828],
                [-74.743848, -49.422461],
                [-74.960107, -49.533008],
                [-74.981299, -49.56416],
                [-74.99082, -49.605664],
                [-74.993506, -49.751758],
                [-75.031543, -49.83623],
                [-75.066016, -49.852344],
                [-75.166943, -49.855957],
                [-75.300098, -49.847461],
                [-75.451172, -49.769922],
                [-75.549805, -49.791309],
                [-75.570117, -49.69707],
                [-75.520752, -49.62168],
                [-75.337061, -49.628223],
                [-75.305859, -49.494043],
                [-75.364209, -49.4625],
                [-75.428857, -49.408398],
                [-75.46748, -49.358887],
                [-75.433154, -49.32207],
                [-75.32666, -49.268652],
                [-75.269629, -49.262891],
                [-75.216846, -49.292773],
                [-75.086035, -49.270215],
                [-75.093701, -49.185352],
                [-75.210156, -49.148047],
                [-75.184229, -49.083594],
                [-75.037109, -49.02207],
                [-74.949219, -48.960156],
                [-74.945215, -48.889453],
                [-74.980762, -48.818848],
                [-74.969531, -48.791309],
                [-74.89624, -48.733203],
                [-74.793457, -48.705078],
                [-74.74668, -48.708887],
                [-74.651563, -48.749902],
                [-74.566602, -48.754785],
                [-74.546094, -48.766895],
                [-74.530664, -48.812598],
                [-74.476172, -49.147852]
              ]
            ],
            [
              [
                [-75.510254, -48.763477],
                [-75.622852, -48.764648],
                [-75.650928, -48.586328],
                [-75.518457, -48.328809],
                [-75.509033, -48.230664],
                [-75.553516, -48.156738],
                [-75.571484, -48.095898],
                [-75.560693, -48.070898],
                [-75.391406, -48.019727],
                [-75.338379, -48.074023],
                [-75.275488, -48.218457],
                [-75.155518, -48.425195],
                [-75.158496, -48.622656],
                [-75.225098, -48.671387],
                [-75.433984, -48.721191],
                [-75.510254, -48.763477]
              ]
            ],
            [
              [
                [-74.567285, -48.591992],
                [-74.586279, -48.615723],
                [-74.70957, -48.601172],
                [-74.923047, -48.626465],
                [-75.012842, -48.535742],
                [-75.052148, -48.391406],
                [-75.078906, -48.361523],
                [-75.131934, -48.279297],
                [-75.158496, -48.225293],
                [-75.212891, -48.141699],
                [-75.233887, -48.053418],
                [-75.247266, -48.026758],
                [-75.198291, -47.974609],
                [-74.975098, -47.922852],
                [-74.895654, -47.839355],
                [-74.827441, -47.850391],
                [-74.846191, -48.020801],
                [-74.805225, -48.078223],
                [-74.729297, -48.125879],
                [-74.715234, -48.145508],
                [-74.702393, -48.205859],
                [-74.664355, -48.299316],
                [-74.615137, -48.343066],
                [-74.602441, -48.370312],
                [-74.600146, -48.393066],
                [-74.618213, -48.425195],
                [-74.567285, -48.591992]
              ]
            ],
            [
              [
                [-75.112207, -47.837695],
                [-75.18584, -47.850684],
                [-75.194336, -47.818066],
                [-75.261035, -47.763867],
                [-75.203125, -47.728027],
                [-75.089844, -47.690625],
                [-75.003955, -47.694727],
                [-74.926465, -47.723145],
                [-74.916016, -47.756641],
                [-75.05127, -47.800488],
                [-75.084473, -47.824512],
                [-75.112207, -47.837695]
              ]
            ],
            [
              [
                [-74.312891, -45.691504],
                [-74.368457, -45.73584],
                [-74.465527, -45.757227],
                [-74.561621, -45.722461],
                [-74.677734, -45.738574],
                [-74.689844, -45.662598],
                [-74.646436, -45.6],
                [-74.558398, -45.525586],
                [-74.494678, -45.425879],
                [-74.502344, -45.285156],
                [-74.45, -45.25293],
                [-74.421875, -45.203223],
                [-74.310547, -45.172656],
                [-74.2854, -45.277246],
                [-74.31543, -45.464063],
                [-74.240039, -45.574512],
                [-74.229199, -45.611328],
                [-74.243896, -45.653613],
                [-74.312891, -45.691504]
              ]
            ],
            [
              [
                [-75.04248, -44.890137],
                [-75.06748, -44.906543],
                [-75.09873, -44.901758],
                [-75.124219, -44.869922],
                [-75.142139, -44.815625],
                [-75.107422, -44.795117],
                [-75.079492, -44.795117],
                [-75.048437, -44.823926],
                [-75.032227, -44.870508],
                [-75.04248, -44.890137]
              ]
            ],
            [
              [
                [-73.632178, -44.821484],
                [-73.664844, -44.83291],
                [-73.69458, -44.831152],
                [-73.724756, -44.796875],
                [-73.734863, -44.75166],
                [-73.800146, -44.684082],
                [-73.818457, -44.652148],
                [-73.816992, -44.613965],
                [-73.779492, -44.55918],
                [-73.723926, -44.544238],
                [-73.686475, -44.546289],
                [-73.641211, -44.61084],
                [-73.628223, -44.680762],
                [-73.616602, -44.75293],
                [-73.632178, -44.821484]
              ]
            ],
            [
              [
                [-72.986133, -44.780078],
                [-73.228467, -44.859961],
                [-73.35, -44.833203],
                [-73.39707, -44.774316],
                [-73.420068, -44.724805],
                [-73.445068, -44.641016],
                [-73.403662, -44.596094],
                [-73.314941, -44.531348],
                [-73.281982, -44.489551],
                [-73.266016, -44.440234],
                [-73.271582, -44.394141],
                [-73.26001, -44.350293],
                [-73.207715, -44.334961],
                [-73.028418, -44.384082],
                [-72.842432, -44.457715],
                [-72.776367, -44.508594],
                [-72.764062, -44.549023],
                [-72.845313, -44.638477],
                [-72.897168, -44.712012],
                [-72.986133, -44.780078]
              ]
            ],
            [
              [
                [-73.735352, -44.394531],
                [-73.78457, -44.4375],
                [-73.862305, -44.445117],
                [-73.983301, -44.494824],
                [-73.996045, -44.537988],
                [-74.002051, -44.590918],
                [-73.918555, -44.654688],
                [-73.877393, -44.728809],
                [-73.827881, -44.839844],
                [-73.792139, -44.945801],
                [-73.795361, -44.978613],
                [-73.786475, -45.033594],
                [-73.727148, -45.119043],
                [-73.72168, -45.157617],
                [-73.728174, -45.195898],
                [-73.7521, -45.266797],
                [-73.770996, -45.276563],
                [-73.829883, -45.283496],
                [-73.834473, -45.326563],
                [-73.848975, -45.340625],
                [-74.01626, -45.344922],
                [-74.099072, -45.325391],
                [-74.089258, -45.195703],
                [-74.195215, -45.144824],
                [-74.267969, -45.058984],
                [-74.349902, -44.91084],
                [-74.41875, -44.865234],
                [-74.498828, -44.748145],
                [-74.617773, -44.647949],
                [-74.480518, -44.58457],
                [-74.501807, -44.473535],
                [-74.42168, -44.435449],
                [-74.301221, -44.395703],
                [-74.2125, -44.426953],
                [-74.132813, -44.415918],
                [-74.097217, -44.389355],
                [-74.108105, -44.275879],
                [-74.082812, -44.186426],
                [-73.994922, -44.140234],
                [-73.900195, -44.134863],
                [-73.864551, -44.185352],
                [-73.817773, -44.234961],
                [-73.703223, -44.274121],
                [-73.703711, -44.325391],
                [-73.735352, -44.394531]
              ]
            ],
            [
              [
                [-73.810645, -43.827246],
                [-73.789648, -43.876465],
                [-73.833643, -43.883203],
                [-73.90415, -43.875391],
                [-73.938281, -43.914258],
                [-73.955664, -43.921973],
                [-74.117773, -43.8875],
                [-74.142969, -43.872168],
                [-74.139941, -43.820996],
                [-73.967187, -43.816504],
                [-73.856934, -43.783789],
                [-73.841406, -43.788965],
                [-73.810645, -43.827246]
              ]
            ],
            [
              [
                [-74.66875, -43.607812],
                [-74.810449, -43.625391],
                [-74.842676, -43.595508],
                [-74.841992, -43.570312],
                [-74.817676, -43.549414],
                [-74.74502, -43.535937],
                [-74.697461, -43.553027],
                [-74.672656, -43.577441],
                [-74.664795, -43.599609],
                [-74.66875, -43.607812]
              ]
            ],
            [
              [
                [-73.773389, -43.345898],
                [-73.848584, -43.366797],
                [-73.918701, -43.371973],
                [-73.989941, -43.356641],
                [-74.114404, -43.35791],
                [-74.238574, -43.318848],
                [-74.354932, -43.263574],
                [-74.387354, -43.231641],
                [-74.373145, -43.185742],
                [-74.289355, -43.079492],
                [-74.209473, -42.878711],
                [-74.156299, -42.590527],
                [-74.198828, -42.481348],
                [-74.193555, -42.436035],
                [-74.174072, -42.381543],
                [-74.164355, -42.325488],
                [-74.170313, -42.268945],
                [-74.160205, -42.216406],
                [-74.072314, -42.105859],
                [-74.059375, -42.05625],
                [-74.056836, -42.002344],
                [-74.018799, -41.890918],
                [-74.030518, -41.854004],
                [-74.063037, -41.822754],
                [-74.03667, -41.795508],
                [-73.730957, -41.877246],
                [-73.527832, -41.896289],
                [-73.516943, -41.980859],
                [-73.477783, -42.047168],
                [-73.454492, -42.165918],
                [-73.4229, -42.192871],
                [-73.439258, -42.277832],
                [-73.532813, -42.314453],
                [-73.524561, -42.392578],
                [-73.470801, -42.466309],
                [-73.549268, -42.492578],
                [-73.633887, -42.508203],
                [-73.653467, -42.528711],
                [-73.714746, -42.544727],
                [-73.789258, -42.585742],
                [-73.766846, -42.621875],
                [-73.673047, -42.704395],
                [-73.568262, -42.761621],
                [-73.510742, -42.847168],
                [-73.436328, -42.936523],
                [-73.472656, -42.993262],
                [-73.54082, -43.07373],
                [-73.649609, -43.127148],
                [-73.749658, -43.159082],
                [-73.737891, -43.291406],
                [-73.773389, -43.345898]
              ]
            ],
            [
              [
                [-78.80415, -33.646484],
                [-78.98335, -33.667773],
                [-78.989453, -33.661719],
                [-78.979297, -33.644141],
                [-78.938135, -33.613574],
                [-78.888281, -33.576367],
                [-78.877441, -33.575195],
                [-78.859033, -33.578125],
                [-78.838184, -33.585059],
                [-78.784668, -33.610156],
                [-78.768945, -33.627344],
                [-78.774707, -33.641602],
                [-78.80415, -33.646484]
              ]
            ],
            [
              [
                [-109.27998, -27.14043],
                [-109.434131, -27.171289],
                [-109.42915, -27.116211],
                [-109.390479, -27.068359],
                [-109.276465, -27.095898],
                [-109.222852, -27.101074],
                [-109.27998, -27.14043]
              ]
            ],
            [
              [
                [-67.707324, -22.88916],
                [-67.579932, -22.891699],
                [-67.362256, -22.855176],
                [-67.194873, -22.82168],
                [-67.008789, -23.001367],
                [-67.089746, -23.245117],
                [-67.219141, -23.633984],
                [-67.319141, -23.934668],
                [-67.335596, -23.974805],
                [-67.356201, -24.033789],
                [-67.571777, -24.118945],
                [-67.88623, -24.243359],
                [-68.047363, -24.308301],
                [-68.250293, -24.391992],
                [-68.299512, -24.460352],
                [-68.358105, -24.497266],
                [-68.422559, -24.545117],
                [-68.447119, -24.596973],
                [-68.507275, -24.629785],
                [-68.562012, -24.747363],
                [-68.562012, -24.837695],
                [-68.527051, -24.899219],
                [-68.466309, -24.925195],
                [-68.447119, -24.998926],
                [-68.428027, -25.050977],
                [-68.384229, -25.091895],
                [-68.395215, -25.124707],
                [-68.430713, -25.149316],
                [-68.496338, -25.162988],
                [-68.54082, -25.236719],
                [-68.59209, -25.42002],
                [-68.600293, -25.485645],
                [-68.541895, -25.651563],
                [-68.51084, -25.741016],
                [-68.426758, -26.06543],
                [-68.414502, -26.153711],
                [-68.529834, -26.276953],
                [-68.575781, -26.351953],
                [-68.592187, -26.418066],
                [-68.591602, -26.47041],
                [-68.581152, -26.518359],
                [-68.485107, -26.670313],
                [-68.37334, -26.806445],
                [-68.318652, -26.877539],
                [-68.318652, -26.973242],
                [-68.345996, -27.02793],
                [-68.405371, -27.048145],
                [-68.537354, -27.085352],
                [-68.59209, -27.140039],
                [-68.652197, -27.14834],
                [-68.709619, -27.104492],
                [-68.769775, -27.11543],
                [-68.846338, -27.153711],
                [-68.875098, -27.24668],
                [-68.941992, -27.405176],
                [-68.999414, -27.449023],
                [-69.042187, -27.57002],
                [-69.118506, -27.743555],
                [-69.155273, -27.848145],
                [-69.174414, -27.924707],
                [-69.251221, -27.973633],
                [-69.340723, -28.070801],
                [-69.40957, -28.165332],
                [-69.436914, -28.192676],
                [-69.488867, -28.200879],
                [-69.527148, -28.285645],
                [-69.656934, -28.413574],
                [-69.687891, -28.562012],
                [-69.734912, -28.641113],
                [-69.743164, -28.783887],
                [-69.814844, -29.045508],
                [-69.827881, -29.103223],
                [-69.900342, -29.148828],
                [-69.995605, -29.25],
                [-70.026807, -29.324023],
                [-69.982617, -29.54541],
                [-69.927637, -29.769141],
                [-69.924121, -29.874023],
                [-69.945459, -30.016406],
                [-69.959961, -30.07832],
                [-69.923535, -30.103906],
                [-69.863379, -30.120312],
                [-69.844287, -30.175],
                [-69.888037, -30.213281],
                [-69.907129, -30.281641],
                [-69.956348, -30.358203],
                [-70.102002, -30.388281],
                [-70.153223, -30.360937],
                [-70.169629, -30.385547],
                [-70.161426, -30.440234],
                [-70.193945, -30.504688],
                [-70.269385, -30.677246],
                [-70.319238, -30.833984],
                [-70.348145, -30.902344],
                [-70.336426, -30.959766],
                [-70.311816, -30.992578],
                [-70.309082, -31.022656],
                [-70.350586, -31.060449],
                [-70.388379, -31.121094],
                [-70.429395, -31.129297],
                [-70.473096, -31.112793],
                [-70.51958, -31.148438],
                [-70.529053, -31.222852],
                [-70.554688, -31.317383],
                [-70.566406, -31.42793],
                [-70.585205, -31.569434],
                [-70.525635, -31.666406],
                [-70.450146, -31.841895],
                [-70.393848, -31.883789],
                [-70.330957, -31.881055],
                [-70.281738, -31.916602],
                [-70.254395, -31.957715],
                [-70.290918, -32.031055],
                [-70.355566, -32.042383],
                [-70.36377, -32.083496],
                [-70.344629, -32.176465],
                [-70.32002, -32.266699],
                [-70.257812, -32.309961],
                [-70.229785, -32.430664],
                [-70.169629, -32.47168],
                [-70.176953, -32.626074],
                [-70.116162, -32.807422],
                [-70.052051, -32.859961],
                [-70.021973, -32.88457],
                [-70.042139, -32.963672],
                [-70.093066, -33.026758],
                [-70.104004, -33.12793],
                [-70.084863, -33.201758],
                [-70.019824, -33.271484],
                [-69.969043, -33.279395],
                [-69.896191, -33.250977],
                [-69.819629, -33.283789],
                [-69.808691, -33.343945],
                [-69.797754, -33.398633],
                [-69.83877, -33.469727],
                [-69.882568, -33.600977],
                [-69.894336, -33.731348],
                [-69.881494, -33.929785],
                [-69.861523, -34.083594],
                [-69.857373, -34.180469],
                [-69.852441, -34.224316],
                [-69.879785, -34.254395],
                [-69.946338, -34.269922],
                [-70.002832, -34.27627],
                [-70.052051, -34.300781],
                [-70.062988, -34.35],
                [-70.101465, -34.432031],
                [-70.14126, -34.492871],
                [-70.210693, -34.58125],
                [-70.254687, -34.672656],
                [-70.289941, -34.732812],
                [-70.286768, -34.774512],
                [-70.312109, -34.85498],
                [-70.338135, -34.921777],
                [-70.393164, -35.146875],
                [-70.466602, -35.193652],
                [-70.525098, -35.216797],
                [-70.555176, -35.246875],
                [-70.532324, -35.30791],
                [-70.47041, -35.326172],
                [-70.448535, -35.375391],
                [-70.456738, -35.451953],
                [-70.415723, -35.523047],
                [-70.419727, -35.60918],
                [-70.380176, -35.771875],
                [-70.415723, -35.878516],
                [-70.403662, -35.970508],
                [-70.404785, -36.061719],
                [-70.456738, -36.132715],
                [-70.563379, -36.146387],
                [-70.621875, -36.211914],
                [-70.721924, -36.283203],
                [-70.732861, -36.340625],
                [-70.749268, -36.392578],
                [-70.790283, -36.411719],
                [-70.853174, -36.411719],
                [-70.905127, -36.419922],
                [-70.97793, -36.487305],
                [-71.055518, -36.52373],
                [-71.073242, -36.578027],
                [-71.066406, -36.644043],
                [-71.107422, -36.685059],
                [-71.159375, -36.761621],
                [-71.192188, -36.843652],
                [-71.159375, -36.920215],
                [-71.123828, -37.056934],
                [-71.118408, -37.114355],
                [-71.163477, -37.227441],
                [-71.200391, -37.300293],
                [-71.164893, -37.393262],
                [-71.134814, -37.445117],
                [-71.162842, -37.55918],
                [-71.186719, -37.631055],
                [-71.167578, -37.762305],
                [-71.096191, -37.909961],
                [-71.028174, -38.041211],
                [-71.018164, -38.193945],
                [-71.000488, -38.314844],
                [-70.967969, -38.445898],
                [-70.899658, -38.497852],
                [-70.847656, -38.541602],
                [-70.858643, -38.604492],
                [-70.896924, -38.681055],
                [-70.951611, -38.738477],
                [-71.087109, -38.75752],
                [-71.197266, -38.809375],
                [-71.285742, -38.84541],
                [-71.353174, -38.888867],
                [-71.401562, -38.935059],
                [-71.425586, -38.985645],
                [-71.409375, -39.205957],
                [-71.42002, -39.287207],
                [-71.465381, -39.402344],
                [-71.507764, -39.495215],
                [-71.525781, -39.523145],
                [-71.53125, -39.56416],
                [-71.539453, -39.602441],
                [-71.587012, -39.611133],
                [-71.654297, -39.594238],
                [-71.692578, -39.605176],
                [-71.719922, -39.635254],
                [-71.696826, -39.707031],
                [-71.67207, -39.833301],
                [-71.637891, -39.886816],
                [-71.647119, -39.929199],
                [-71.659766, -40.020801],
                [-71.704395, -40.094922],
                [-71.763672, -40.094629],
                [-71.801953, -40.124707],
                [-71.818311, -40.17666],
                [-71.800586, -40.244336],
                [-71.722656, -40.299707],
                [-71.695313, -40.335254],
                [-71.708984, -40.381738],
                [-71.769141, -40.400879],
                [-71.804639, -40.43916],
                [-71.838525, -40.524414],
                [-71.883789, -40.620605],
                [-71.932129, -40.691699],
                [-71.941357, -40.78916],
                [-71.873047, -40.892969],
                [-71.880713, -40.994629],
                [-71.885596, -41.292383],
                [-71.892187, -41.393359],
                [-71.871143, -41.560547],
                [-71.897607, -41.606641],
                [-71.911279, -41.650391],
                [-71.844482, -41.771973],
                [-71.77002, -41.968555],
                [-71.75, -42.046777],
                [-71.760937, -42.101465],
                [-71.860791, -42.147852],
                [-71.944092, -42.16709],
                [-71.993311, -42.134277],
                [-72.026123, -42.147949],
                [-72.064404, -42.205371],
                [-72.108203, -42.251855],
                [-72.124609, -42.29834],
                [-72.078125, -42.358496],
                [-72.053467, -42.473242],
                [-72.10542, -42.522461],
                [-72.143701, -42.577148],
                [-72.130029, -42.648242],
                [-72.113623, -42.776758],
                [-72.146436, -42.990039],
                [-72.102393, -43.065625],
                [-72.054687, -43.101953],
                [-71.898584, -43.145312],
                [-71.781494, -43.166797],
                [-71.750635, -43.237305],
                [-71.763867, -43.294629],
                [-71.820215, -43.322949],
                [-71.90498, -43.347559],
                [-71.90498, -43.440137],
                [-71.832422, -43.527148],
                [-71.750635, -43.590137],
                [-71.732764, -43.646777],
                [-71.737402, -43.704687],
                [-71.794727, -43.753223],
                [-71.715967, -43.858398],
                [-71.680078, -43.92959],
                [-71.716162, -43.984473],
                [-71.767188, -44.066699],
                [-71.812354, -44.106055],
                [-71.812109, -44.150781],
                [-71.830762, -44.241406],
                [-71.835059, -44.330176],
                [-71.82002, -44.383105],
                [-71.325732, -44.424902],
                [-71.212598, -44.441211],
                [-71.150879, -44.494043],
                [-71.159717, -44.560254],
                [-71.221484, -44.630762],
                [-71.261133, -44.763086],
                [-71.358154, -44.785156],
                [-71.455176, -44.749805],
                [-71.5604, -44.762012],
                [-71.65166, -44.77041],
                [-71.782812, -44.774414],
                [-71.957031, -44.791504],
                [-72.063721, -44.771875],
                [-72.07251, -44.82041],
                [-72.041699, -44.904199],
                [-71.812354, -44.930664],
                [-71.596289, -44.979199],
                [-71.531299, -45.067871],
                [-71.443457, -45.168262],
                [-71.35376, -45.230469],
                [-71.349316, -45.331934],
                [-71.49043, -45.437695],
                [-71.508105, -45.512695],
                [-71.693311, -45.534766],
                [-71.746191, -45.578906],
                [-71.772656, -45.724414],
                [-71.750635, -45.839062],
                [-71.680078, -45.878711],
                [-71.631543, -45.953711],
                [-71.684473, -46.041895],
                [-71.809277, -46.102734],
                [-71.875684, -46.160547],
                [-71.834131, -46.206738],
                [-71.777637, -46.27998],
                [-71.762109, -46.319824],
                [-71.731299, -46.427832],
                [-71.695215, -46.578418],
                [-71.699658, -46.651367],
                [-71.732715, -46.705859],
                [-71.856445, -46.791602],
                [-71.940234, -46.83125],
                [-71.956641, -46.936816],
                [-71.962988, -47.016016],
                [-71.954248, -47.0875],
                [-71.900537, -47.144336],
                [-71.90498, -47.20166],
                [-71.978516, -47.213867],
                [-72.041699, -47.241406],
                [-72.103418, -47.342773],
                [-72.28291, -47.446289],
                [-72.345947, -47.492676],
                [-72.341504, -47.57207],
                [-72.412598, -47.685547],
                [-72.472217, -47.78418],
                [-72.51792, -47.876367],
                [-72.509082, -47.97334],
                [-72.40791, -48.015918],
                [-72.32832, -48.110059],
                [-72.293018, -48.229102],
                [-72.354736, -48.36582],
                [-72.498145, -48.417383],
                [-72.582861, -48.475391],
                [-72.608398, -48.519336],
                [-72.585938, -48.6625],
                [-72.591748, -48.729688],
                [-72.614404, -48.792871],
                [-72.65127, -48.841602],
                [-72.728467, -48.896289],
                [-72.86543, -48.943945],
                [-72.981738, -48.976758],
                [-73.033643, -49.014355],
                [-73.09458, -49.096875],
                [-73.148877, -49.187988],
                [-73.135254, -49.300684],
                [-73.461572, -49.313867],
                [-73.483643, -49.397656],
                [-73.554199, -49.463867],
                [-73.57627, -49.58291],
                [-73.504541, -49.698047],
                [-73.47041, -49.794531],
                [-73.528906, -49.910938],
                [-73.507715, -50.030273],
                [-73.50127, -50.125293],
                [-73.386621, -50.231152],
                [-73.311719, -50.361914],
                [-73.27417, -50.472559],
                [-73.251611, -50.558496],
                [-73.221631, -50.610742],
                [-73.174512, -50.67002],
                [-73.15293, -50.738281],
                [-73.082373, -50.760352],
                [-72.955566, -50.696484],
                [-72.865918, -50.653125],
                [-72.803613, -50.637695],
                [-72.62041, -50.647656],
                [-72.509814, -50.60752],
                [-72.460156, -50.611719],
                [-72.392578, -50.634277],
                [-72.340234, -50.681836],
                [-72.300635, -50.789551],
                [-72.276318, -50.910254],
                [-72.307373, -51.033398],
                [-72.35918, -51.060156],
                [-72.376807, -51.09541],
                [-72.35918, -51.17041],
                [-72.301855, -51.22334],
                [-72.303223, -51.298926],
                [-72.366406, -51.470313],
                [-72.407666, -51.54082],
                [-72.334521, -51.620313],
                [-72.268994, -51.691113],
                [-72.136963, -51.744043],
                [-72.028418, -51.818652],
                [-71.953467, -51.880371],
                [-71.971094, -51.96416],
                [-71.918652, -51.989551],
                [-71.716602, -51.991309],
                [-71.414746, -51.993945],
                [-70.943164, -51.998145],
                [-70.482861, -52.002246],
                [-69.960254, -52.008203],
                [-69.712598, -52.075391],
                [-69.488428, -52.136133],
                [-69.206201, -52.136133],
                [-68.924561, -52.208105],
                [-68.715186, -52.255469],
                [-68.589795, -52.27334],
                [-68.460986, -52.29043],
                [-68.443359, -52.356641],
                [-69.007227, -52.262695],
                [-69.133789, -52.211426],
                [-69.241016, -52.205469],
                [-69.446875, -52.269434],
                [-69.560596, -52.421582],
                [-69.620312, -52.464746],
                [-69.76333, -52.505566],
                [-69.907227, -52.513574],
                [-70.390967, -52.66084],
                [-70.562939, -52.673438],
                [-70.680322, -52.7125],
                [-70.795117, -52.76875],
                [-70.839063, -52.889551],
                [-70.821191, -52.963086],
                [-70.952051, -53.226953],
                [-70.984326, -53.373633],
                [-70.985107, -53.44834],
                [-70.947803, -53.57041],
                [-70.99585, -53.779297],
                [-71.082812, -53.825],
                [-71.297754, -53.883398],
                [-71.443896, -53.840918],
                [-71.693799, -53.803125],
                [-71.871875, -53.722656],
                [-72.100928, -53.66582],
                [-72.174414, -53.632324],
                [-72.376807, -53.471191],
                [-72.398242, -53.417773],
                [-72.412891, -53.350195],
                [-72.306055, -53.253711],
                [-72.248633, -53.24668],
                [-72.081152, -53.249609],
                [-71.941699, -53.234082],
                [-71.852734, -53.285742],
                [-71.828223, -53.39834],
                [-71.867334, -53.458398],
                [-71.902783, -53.495508],
                [-71.891699, -53.523535],
                [-71.791455, -53.48457],
                [-71.740527, -53.232617],
                [-71.400342, -53.107031],
                [-71.288965, -53.033691],
                [-71.180225, -52.920508],
                [-71.163281, -52.888086],
                [-71.155078, -52.845605],
                [-71.227148, -52.810645],
                [-71.387744, -52.764258],
                [-71.897998, -53.001758],
                [-72.129102, -53.064355],
                [-72.278027, -53.132324],
                [-72.458301, -53.254492],
                [-72.492578, -53.290625],
                [-72.530811, -53.37168],
                [-72.548926, -53.460742],
                [-72.726807, -53.42002],
                [-72.998389, -53.290723],
                [-73.052734, -53.243457],
                [-72.998242, -53.177246],
                [-72.915527, -53.121973],
                [-72.909912, -52.936523],
                [-72.88916, -52.871582],
                [-72.831885, -52.819531],
                [-72.727686, -52.762305],
                [-72.675977, -52.749023],
                [-72.63208, -52.773828],
                [-72.626611, -52.817578],
                [-72.453467, -52.814453],
                [-72.117578, -52.65],
                [-71.979297, -52.646094],
                [-71.79707, -52.682812],
                [-71.591211, -52.660742],
                [-71.55415, -52.643945],
                [-71.511279, -52.605371],
                [-71.664746, -52.560059],
                [-71.811914, -52.537012],
                [-72.225684, -52.520996],
                [-72.315381, -52.538574],
                [-72.437695, -52.625781],
                [-72.478271, -52.604004],
                [-72.504395, -52.560059],
                [-72.644824, -52.529102],
                [-72.712109, -52.535547],
                [-72.776562, -52.577441],
                [-72.766016, -52.642578],
                [-72.801904, -52.712402],
                [-72.931885, -52.781641],
                [-73.020264, -52.891797],
                [-73.016113, -52.977441],
                [-73.022998, -53.02207],
                [-73.055469, -53.045605],
                [-73.122461, -53.073926],
                [-73.338184, -53.054687],
                [-73.459863, -52.964844],
                [-73.50752, -52.903516],
                [-73.645215, -52.837012],
                [-73.345947, -52.754297],
                [-73.24082, -52.707129],
                [-73.144824, -52.601953],
                [-73.073193, -52.535059],
                [-73.123926, -52.487988],
                [-73.183789, -52.487891],
                [-73.178174, -52.562695],
                [-73.244141, -52.624023],
                [-73.382129, -52.595117],
                [-73.585693, -52.685742],
                [-73.71084, -52.661523],
                [-73.914697, -52.688184],
                [-74.014453, -52.639355],
                [-74.03584, -52.577246],
                [-73.999902, -52.512598],
                [-74.037354, -52.40293],
                [-74.093506, -52.37627],
                [-74.15083, -52.38252],
                [-74.176562, -52.317187],
                [-74.238477, -52.202344],
                [-74.265967, -52.171289],
                [-74.295654, -52.117871],
                [-74.264941, -52.104883],
                [-74.194922, -52.120215],
                [-74.133545, -52.154785],
                [-74.040234, -52.15918],
                [-73.834473, -52.233984],
                [-73.749121, -52.216016],
                [-73.702783, -52.198828],
                [-73.6854, -52.136719],
                [-73.684326, -52.077734],
                [-73.649023, -52.077734],
                [-73.532227, -52.153125],
                [-73.457959, -52.145996],
                [-73.326758, -52.165918],
                [-73.260449, -52.157813],
                [-73.137354, -52.129687],
                [-72.943701, -52.046875],
                [-72.843213, -51.961133],
                [-72.79502, -51.949512],
                [-72.7354, -51.960547],
                [-72.695459, -51.985156],
                [-72.694824, -52.044727],
                [-72.649561, -52.099902],
                [-72.587988, -52.145117],
                [-72.57085, -52.200098],
                [-72.583447, -52.254199],
                [-72.693604, -52.330273],
                [-72.714014, -52.356738],
                [-72.677051, -52.384668],
                [-72.631494, -52.371582],
                [-72.568701, -52.333984],
                [-72.53291, -52.282324],
                [-72.52334, -52.255469],
                [-72.519336, -52.21709],
                [-72.524121, -52.170313],
                [-72.613574, -52.037012],
                [-72.624756, -52.006934],
                [-72.624609, -51.946484],
                [-72.522852, -51.890918],
                [-72.494141, -51.847559],
                [-72.489648, -51.763672],
                [-72.542529, -51.706152],
                [-72.76123, -51.573242],
                [-73.126758, -51.439941],
                [-73.16875, -51.453906],
                [-73.197021, -51.478027],
                [-73.163379, -51.495605],
                [-73.11499, -51.504492],
                [-72.789355, -51.614258],
                [-72.70459, -51.67793],
                [-72.649072, -51.69502],
                [-72.583301, -51.737305],
                [-72.600049, -51.799121],
                [-72.928369, -51.859863],
                [-73.188672, -51.990625],
                [-73.383252, -52.07002],
                [-73.518164, -52.041016],
                [-73.582324, -51.960352],
                [-73.650293, -51.85625],
                [-73.752637, -51.795508],
                [-73.810645, -51.801172],
                [-73.857568, -51.789941],
                [-73.894434, -51.757812],
                [-73.973242, -51.784473],
                [-74.146436, -51.712109],
                [-74.19668, -51.680566],
                [-74.06958, -51.578711],
                [-73.929785, -51.617871],
                [-73.895898, -51.331445],
                [-73.939502, -51.266309],
                [-74.12124, -51.19541],
                [-74.210498, -51.20459],
                [-74.332324, -51.19502],
                [-74.414355, -51.1625],
                [-74.507861, -51.149609],
                [-74.586865, -51.130664],
                [-74.690088, -51.086523],
                [-74.814746, -51.062891],
                [-74.983105, -50.881055],
                [-75.055322, -50.785547],
                [-75.094678, -50.68125],
                [-74.836621, -50.678906],
                [-74.685742, -50.662012],
                [-74.648926, -50.618457],
                [-74.702051, -50.535352],
                [-74.775879, -50.469922],
                [-74.72168, -50.408496],
                [-74.644482, -50.360937],
                [-74.564111, -50.382031],
                [-74.365576, -50.487891],
                [-74.331445, -50.55957],
                [-74.190186, -50.778027],
                [-74.156104, -50.797461],
                [-74.139404, -50.817773],
                [-73.847461, -50.940039],
                [-73.806543, -50.938379],
                [-73.824609, -50.83584],
                [-73.740576, -50.69668],
                [-73.659033, -50.650684],
                [-73.618164, -50.651172],
                [-73.613965, -50.62793],
                [-73.693262, -50.57002],
                [-73.654443, -50.492676],
                [-73.679932, -50.490234],
                [-73.750195, -50.539844],
                [-73.891504, -50.782715],
                [-73.978027, -50.827051],
                [-74.096729, -50.71709],
                [-74.164111, -50.637891],
                [-74.197217, -50.609766],
                [-74.185596, -50.485352],
                [-73.950342, -50.510547],
                [-74.031055, -50.469824],
                [-74.305566, -50.398047],
                [-74.374121, -50.362988],
                [-74.425098, -50.350195],
                [-74.516406, -50.265625],
                [-74.62959, -50.194043],
                [-74.434326, -50.065234],
                [-74.33374, -49.974609],
                [-74.019434, -50.022754],
                [-73.958594, -49.994727],
                [-74.01123, -49.928516],
                [-74.073291, -49.948535],
                [-74.171338, -49.907324],
                [-74.323926, -49.783398],
                [-74.31875, -49.720117],
                [-74.29082, -49.604102],
                [-74.230371, -49.579297],
                [-74.102002, -49.555371],
                [-73.955518, -49.593066],
                [-73.891553, -49.62373],
                [-73.836377, -49.609375],
                [-73.89248, -49.523437],
                [-73.988037, -49.490918],
                [-74.094434, -49.429687],
                [-74.083496, -49.361816],
                [-74.049219, -49.305664],
                [-74.023438, -49.244141],
                [-74.005615, -49.158008],
                [-74.015381, -49.090918],
                [-73.984766, -49.059961],
                [-73.937891, -49.046094],
                [-73.934961, -49.020898],
                [-74.027734, -49.026172],
                [-74.061328, -49.111035],
                [-74.073877, -49.188379],
                [-74.139795, -49.250488],
                [-74.167871, -49.320508],
                [-74.18457, -49.404395],
                [-74.221289, -49.500586],
                [-74.301514, -49.463965],
                [-74.348535, -49.42627],
                [-74.366553, -49.400488],
                [-74.358154, -49.351367],
                [-74.37998, -49.047852],
                [-74.382129, -48.793652],
                [-74.341016, -48.595703],
                [-74.227686, -48.516992],
                [-74.176221, -48.494141],
                [-74.129297, -48.504199],
                [-74.056934, -48.503613],
                [-74.009082, -48.475],
                [-74.171533, -48.427441],
                [-74.270068, -48.45459],
                [-74.342969, -48.492578],
                [-74.474414, -48.463965],
                [-74.499414, -48.362305],
                [-74.577197, -48.274414],
                [-74.590723, -48.161914],
                [-74.584668, -47.999023],
                [-74.400488, -48.013086],
                [-74.250439, -48.044922],
                [-73.853809, -48.042188],
                [-73.528174, -48.198242],
                [-73.384473, -48.177344],
                [-73.391064, -48.145898],
                [-73.500977, -48.106641],
                [-73.56958, -48.019336],
                [-73.609912, -47.993945],
                [-73.628906, -47.941504],
                [-73.635107, -47.880371],
                [-73.715869, -47.655469],
                [-73.748242, -47.661328],
                [-73.779248, -47.738477],
                [-73.84668, -47.866992],
                [-73.940869, -47.929395],
                [-74.084766, -47.954688],
                [-74.227051, -47.968945],
                [-74.350586, -47.944336],
                [-74.379346, -47.891211],
                [-74.376221, -47.829687],
                [-74.429688, -47.799609],
                [-74.569238, -47.772949],
                [-74.608887, -47.758008],
                [-74.654932, -47.702246],
                [-74.588428, -47.617969],
                [-74.533789, -47.567676],
                [-74.466895, -47.577637],
                [-74.403564, -47.600391],
                [-74.322559, -47.666699],
                [-74.242969, -47.679297],
                [-74.151953, -47.62666],
                [-74.134082, -47.59082],
                [-74.191016, -47.568359],
                [-74.24292, -47.559668],
                [-74.323682, -47.531445],
                [-74.482666, -47.430469],
                [-74.403271, -47.327539],
                [-74.215674, -47.20957],
                [-74.158398, -47.18252],
                [-74.208057, -47.083105],
                [-74.151904, -46.974414],
                [-74.209473, -46.884766],
                [-74.313574, -46.788184],
                [-74.454199, -46.766797],
                [-74.484424, -46.79502],
                [-74.489355, -46.83457],
                [-74.466992, -46.864355],
                [-74.480176, -46.88584],
                [-74.512256, -46.885156],
                [-74.69082, -46.863965],
                [-74.810645, -46.799707],
                [-75.005957, -46.741113],
                [-75.031445, -46.695312],
                [-75.052539, -46.628027],
                [-74.98418, -46.512109],
                [-75.01875, -46.510547],
                [-75.145752, -46.600098],
                [-75.337402, -46.64707],
                [-75.478418, -46.662402],
                [-75.540332, -46.69873],
                [-75.565088, -46.728711],
                [-75.527588, -46.746387],
                [-75.445996, -46.750781],
                [-75.386426, -46.862695],
                [-75.401221, -46.905664],
                [-75.430371, -46.93457],
                [-75.496631, -46.940137],
                [-75.635254, -46.862793],
                [-75.708105, -46.775],
                [-75.706396, -46.705273],
                [-75.656787, -46.610352],
                [-75.436914, -46.483008],
                [-75.376025, -46.429102],
                [-75.247021, -46.369336],
                [-75.074854, -46.23457],
                [-74.924463, -46.159668],
                [-74.997656, -46.097656],
                [-75.074512, -46.004492],
                [-75.066699, -45.874902],
                [-74.763135, -45.823633],
                [-74.630664, -45.844727],
                [-74.462793, -45.840723],
                [-74.369141, -45.809668],
                [-74.301172, -45.803027],
                [-74.157861, -45.767187],
                [-74.096191, -45.716797],
                [-74.081836, -45.67832],
                [-74.08252, -45.644727],
                [-74.099268, -45.603418],
                [-74.122705, -45.496191],
                [-74.098926, -45.460352],
                [-74.037549, -45.417676],
                [-73.957178, -45.404395],
                [-73.920312, -45.407715],
                [-73.825, -45.446875],
                [-73.844141, -45.502441],
                [-73.882324, -45.569336],
                [-73.960254, -45.835254],
                [-73.999512, -45.895312],
                [-74.061035, -45.947363],
                [-74.019922, -46.055859],
                [-74.081543, -46.131836],
                [-74.356787, -46.212695],
                [-74.392969, -46.217383],
                [-74.372461, -46.246289],
                [-74.213135, -46.239453],
                [-74.089746, -46.222363],
                [-73.967578, -46.154102],
                [-73.929199, -46.049902],
                [-73.878711, -45.846875],
                [-73.812549, -45.818164],
                [-73.735254, -45.811719],
                [-73.694873, -45.85957],
                [-73.70791, -45.966699],
                [-73.708154, -46.070313],
                [-73.810645, -46.377344],
                [-73.934814, -46.500684],
                [-73.948633, -46.533105],
                [-73.94375, -46.571582],
                [-73.845361, -46.566016],
                [-73.770264, -46.499805],
                [-73.716211, -46.415234],
                [-73.662061, -46.297461],
                [-73.668213, -46.212109],
                [-73.65166, -46.159277],
                [-73.629443, -45.986523],
                [-73.591846, -45.899121],
                [-73.594336, -45.776855],
                [-73.661963, -45.730762],
                [-73.756592, -45.702832],
                [-73.780371, -45.62793],
                [-73.730762, -45.47998],
                [-73.549902, -45.483789],
                [-73.378564, -45.382813],
                [-73.266211, -45.346191],
                [-73.202344, -45.353809],
                [-72.978174, -45.451172],
                [-72.933838, -45.452344],
                [-72.94082, -45.417285],
                [-72.975537, -45.392578],
                [-73.063867, -45.359766],
                [-73.226367, -45.255176],
                [-73.444971, -45.238184],
                [-73.404883, -45.102344],
                [-73.362451, -44.978223],
                [-73.256445, -44.961035],
                [-73.078418, -44.920215],
                [-72.738965, -44.73418],
                [-72.680078, -44.593945],
                [-72.663867, -44.436426],
                [-72.827539, -44.39541],
                [-73.001025, -44.292383],
                [-73.140967, -44.2375],
                [-73.265088, -44.168652],
                [-73.240723, -44.065723],
                [-73.224463, -43.897949],
                [-73.068799, -43.862012],
                [-72.996582, -43.631543],
                [-73.100977, -43.455176],
                [-73.075977, -43.323633],
                [-72.93999, -43.211328],
                [-72.915479, -43.133594],
                [-72.878027, -43.048145],
                [-72.758008, -43.039453],
                [-72.755371, -42.992969],
                [-72.766016, -42.908203],
                [-72.844971, -42.808008],
                [-72.848047, -42.669141],
                [-72.773926, -42.505176],
                [-72.654834, -42.516602],
                [-72.631836, -42.509668],
                [-72.716309, -42.410449],
                [-72.785156, -42.30127],
                [-72.773242, -42.257715],
                [-72.707373, -42.220508],
                [-72.631055, -42.199805],
                [-72.548438, -42.255762],
                [-72.430273, -42.433887],
                [-72.412354, -42.388184],
                [-72.460107, -42.206641],
                [-72.499414, -41.980859],
                [-72.623975, -42.010547],
                [-72.738184, -41.994629],
                [-72.781201, -41.95957],
                [-72.824072, -41.908789],
                [-72.783838, -41.846777],
                [-72.743701, -41.800586],
                [-72.659863, -41.74248],
                [-72.486035, -41.72207],
                [-72.3604, -41.649121],
                [-72.318262, -41.499023],
                [-72.359473, -41.513867],
                [-72.427734, -41.645898],
                [-72.542383, -41.690625],
                [-72.60083, -41.684082],
                [-72.669775, -41.659375],
                [-72.805127, -41.544336],
                [-72.87998, -41.517578],
                [-72.952832, -41.514746],
                [-73.01499, -41.543848],
                [-73.174072, -41.746582],
                [-73.241797, -41.780859],
                [-73.521289, -41.79707],
                [-73.624023, -41.773633],
                [-73.735156, -41.74248],
                [-73.721875, -41.69248],
                [-73.688086, -41.639258],
                [-73.625049, -41.611914],
                [-73.623926, -41.581348],
                [-73.710645, -41.573633],
                [-73.810742, -41.51748],
                [-73.855029, -41.446387],
                [-73.876172, -41.319336],
                [-73.965869, -41.118262],
                [-73.983594, -40.974316],
                [-73.920312, -40.871582],
                [-73.784033, -40.468457],
                [-73.74248, -40.262988],
                [-73.669434, -40.082324],
                [-73.670996, -39.963184],
                [-73.482227, -39.854297],
                [-73.4104, -39.78916],
                [-73.249902, -39.422363],
                [-73.226465, -39.224414],
                [-73.480762, -38.624023],
                [-73.520215, -38.509375],
                [-73.532568, -38.366797],
                [-73.471875, -38.130078],
                [-73.464795, -38.040332],
                [-73.516748, -37.910547],
                [-73.661816, -37.698535],
                [-73.6646, -37.59043],
                [-73.603418, -37.479102],
                [-73.662402, -37.341016],
                [-73.633643, -37.255469],
                [-73.60166, -37.188477],
                [-73.374561, -37.224316],
                [-73.271094, -37.207422],
                [-73.215967, -37.166895],
                [-73.172852, -37.053516],
                [-73.15127, -36.876172],
                [-73.137793, -36.799902],
                [-73.118066, -36.688379],
                [-73.006592, -36.643457],
                [-72.967822, -36.537793],
                [-72.874561, -36.39043],
                [-72.778418, -35.978516],
                [-72.683398, -35.876953],
                [-72.587354, -35.759668],
                [-72.623926, -35.585742],
                [-72.562061, -35.505371],
                [-72.505176, -35.446973],
                [-72.45498, -35.34082],
                [-72.386719, -35.24043],
                [-72.223779, -35.096191],
                [-72.182422, -34.920215],
                [-72.055957, -34.61582],
                [-72.030762, -34.420508],
                [-71.991504, -34.288477],
                [-72.002832, -34.165332],
                [-71.926855, -34.015625],
                [-71.853955, -33.889551],
                [-71.830957, -33.819531],
                [-71.664355, -33.652637],
                [-71.636279, -33.519238],
                [-71.695508, -33.429004],
                [-71.696582, -33.289062],
                [-71.742969, -33.095117],
                [-71.635547, -33.022559],
                [-71.592041, -32.969531],
                [-71.452246, -32.65957],
                [-71.461426, -32.537891],
                [-71.421289, -32.386816],
                [-71.513037, -32.20791],
                [-71.525879, -31.805859],
                [-71.577295, -31.496387],
                [-71.661963, -31.169531],
                [-71.653906, -30.986621],
                [-71.705664, -30.759277],
                [-71.708936, -30.628027],
                [-71.669482, -30.330371],
                [-71.400391, -30.142969],
                [-71.348047, -29.933203],
                [-71.315723, -29.649707],
                [-71.326709, -29.443164],
                [-71.353271, -29.350391],
                [-71.48584, -29.198242],
                [-71.519238, -28.926465],
                [-71.493604, -28.855273],
                [-71.384082, -28.778711],
                [-71.306738, -28.672461],
                [-71.266846, -28.50752],
                [-71.186426, -28.377832],
                [-71.154492, -28.064063],
                [-71.086523, -27.814453],
                [-71.052637, -27.727344],
                [-70.945801, -27.617871],
                [-70.925781, -27.588672],
                [-70.909277, -27.505176],
                [-70.914258, -27.30791],
                [-70.8979, -27.1875],
                [-70.812744, -26.950586],
                [-70.80293, -26.840918],
                [-70.708398, -26.596973],
                [-70.686963, -26.421777],
                [-70.646582, -26.329395],
                [-70.662256, -26.225391],
                [-70.635449, -25.992676],
                [-70.699609, -25.861133],
                [-70.713721, -25.78418],
                [-70.633008, -25.545605],
                [-70.578125, -25.4875],
                [-70.489502, -25.376465],
                [-70.452197, -25.251855],
                [-70.445361, -25.172656],
                [-70.558643, -24.778516],
                [-70.574121, -24.644336],
                [-70.546436, -24.331641],
                [-70.507422, -24.129687],
                [-70.520068, -23.968555],
                [-70.507422, -23.885742],
                [-70.487793, -23.781738],
                [-70.409961, -23.655566],
                [-70.392334, -23.565918],
                [-70.419629, -23.528516],
                [-70.511963, -23.482812],
                [-70.588135, -23.368359],
                [-70.593359, -23.255469],
                [-70.568848, -23.17334],
                [-70.563184, -23.057031],
                [-70.449658, -23.03418],
                [-70.38916, -22.969629],
                [-70.331689, -22.848633],
                [-70.259521, -22.556055],
                [-70.228516, -22.193164],
                [-70.185449, -21.974609],
                [-70.155078, -21.866602],
                [-70.12959, -21.64082],
                [-70.087549, -21.493066],
                [-70.080029, -21.356836],
                [-70.088379, -21.253223],
                [-70.197021, -20.725391],
                [-70.193652, -20.531445],
                [-70.147461, -20.229785],
                [-70.148145, -19.805078],
                [-70.157422, -19.705859],
                [-70.19834, -19.612988],
                [-70.2104, -19.486914],
                [-70.275781, -19.267578],
                [-70.334863, -18.827539],
                [-70.336084, -18.595215],
                [-70.361621, -18.398047],
                [-70.418262, -18.345605],
                [-70.37749, -18.333594],
                [-70.282275, -18.325391],
                [-70.183789, -18.325195],
                [-70.059082, -18.283496],
                [-69.926367, -18.206055],
                [-69.839697, -18.093457],
                [-69.802588, -17.990234],
                [-69.802441, -17.9],
                [-69.841504, -17.785156],
                [-69.8521, -17.703809],
                [-69.806104, -17.664941],
                [-69.684766, -17.649805],
                [-69.586426, -17.573242],
                [-69.510937, -17.506055],
                [-69.49502, -17.619531],
                [-69.358008, -17.77168],
                [-69.313379, -17.943164],
                [-69.282324, -17.964844],
                [-69.093945, -18.050488],
                [-69.09043, -18.070703],
                [-69.118066, -18.102734],
                [-69.145459, -18.144043],
                [-69.126367, -18.202441],
                [-69.092285, -18.282422],
                [-69.080957, -18.356641],
                [-69.060156, -18.433008],
                [-69.039404, -18.550098],
                [-69.026807, -18.65625],
                [-68.978857, -18.812988],
                [-68.969092, -18.909668],
                [-68.968311, -18.967969],
                [-68.931006, -19.025195],
                [-68.857959, -19.093359],
                [-68.759082, -19.162207],
                [-68.680664, -19.242383],
                [-68.620557, -19.29668],
                [-68.547852, -19.341113],
                [-68.491992, -19.381934],
                [-68.470166, -19.409961],
                [-68.462891, -19.432813],
                [-68.487012, -19.454395],
                [-68.575293, -19.560156],
                [-68.698291, -19.721094],
                [-68.696191, -19.740723],
                [-68.578271, -19.856543],
                [-68.559375, -19.902344],
                [-68.560693, -19.96709],
                [-68.600195, -20.044922],
                [-68.72749, -20.069629],
                [-68.755811, -20.09082],
                [-68.759326, -20.115527],
                [-68.730029, -20.148437],
                [-68.73457, -20.225195],
                [-68.688574, -20.310059],
                [-68.712305, -20.338965],
                [-68.759229, -20.378027],
                [-68.760547, -20.416211],
                [-68.745166, -20.458594],
                [-68.695801, -20.492969],
                [-68.499854, -20.612012],
                [-68.484326, -20.628418],
                [-68.487402, -20.640723],
                [-68.563184, -20.720117],
                [-68.571045, -20.769141],
                [-68.568945, -20.849805],
                [-68.558252, -20.901953],
                [-68.533838, -20.923633],
                [-68.435498, -20.948242],
                [-68.313867, -21.129688],
                [-68.197021, -21.300293],
                [-68.198535, -21.447266],
                [-68.186426, -21.618555],
                [-68.112158, -21.753027],
                [-68.101807, -21.860645],
                [-68.076758, -21.982813],
                [-67.988379, -22.057129],
                [-67.953906, -22.204004],
                [-67.944922, -22.282227],
                [-67.950391, -22.333691],
                [-67.881738, -22.493359],
                [-67.87373, -22.630566],
                [-67.88999, -22.729199],
                [-67.88916, -22.78418],
                [-67.879443, -22.822949],
                [-67.820508, -22.857715],
                [-67.794434, -22.879492],
                [-67.707324, -22.88916]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Argentina",
          "density": "16.46",
          "cartodb_id": 9,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-64.54917, -54.716211],
                [-64.438818, -54.739355],
                [-64.220508, -54.721973],
                [-64.105322, -54.72168],
                [-64.054932, -54.729883],
                [-64.032422, -54.742383],
                [-63.881934, -54.722949],
                [-63.81543, -54.725098],
                [-63.832568, -54.767969],
                [-63.97124, -54.810645],
                [-64.02832, -54.792578],
                [-64.3229, -54.796484],
                [-64.453271, -54.840332],
                [-64.508691, -54.839941],
                [-64.637354, -54.902539],
                [-64.731445, -54.862988],
                [-64.757324, -54.826562],
                [-64.689209, -54.774707],
                [-64.625098, -54.773633],
                [-64.581348, -54.752734],
                [-64.54917, -54.716211]
              ]
            ],
            [
              [
                [-68.278223, -52.983984],
                [-68.240137, -53.081836],
                [-68.333008, -53.019629],
                [-68.431152, -53.055273],
                [-68.479492, -53.11377],
                [-68.520801, -53.177246],
                [-68.520508, -53.221875],
                [-68.488525, -53.260937],
                [-68.393115, -53.294922],
                [-68.161133, -53.306445],
                [-68.144092, -53.319043],
                [-68.008496, -53.564062],
                [-67.940283, -53.61875],
                [-67.861084, -53.662207],
                [-67.678125, -53.787109],
                [-67.502588, -53.921973],
                [-67.294238, -54.049805],
                [-67.069482, -54.148047],
                [-66.865137, -54.222559],
                [-66.670068, -54.313574],
                [-66.462012, -54.441016],
                [-66.235645, -54.533496],
                [-65.992578, -54.598926],
                [-65.74707, -54.653418],
                [-65.369287, -54.632129],
                [-65.251953, -54.638086],
                [-65.179004, -54.678125],
                [-65.252344, -54.788867],
                [-65.345996, -54.87793],
                [-65.471143, -54.914648],
                [-65.60332, -54.928125],
                [-65.722754, -54.926367],
                [-65.841992, -54.909961],
                [-65.95376, -54.919336],
                [-66.060645, -54.956738],
                [-66.172021, -54.975293],
                [-66.286768, -54.977734],
                [-66.398682, -55.009375],
                [-66.511133, -55.032129],
                [-66.627686, -55.013281],
                [-66.930469, -54.924902],
                [-67.1271, -54.903809],
                [-67.793262, -54.868652],
                [-68.007129, -54.848438],
                [-68.220117, -54.817578],
                [-68.331689, -54.816309],
                [-68.491016, -54.83623],
                [-68.618652, -54.833789],
                [-68.653223, -54.853613],
                [-68.64751, -54.627832],
                [-68.639795, -54.324023],
                [-68.638232, -54.05293],
                [-68.63667, -53.788867],
                [-68.635059, -53.51543],
                [-68.633447, -53.241895],
                [-68.631689, -52.949512],
                [-68.629932, -52.652637],
                [-68.571191, -52.694922],
                [-68.33877, -52.900098],
                [-68.278223, -52.983984]
              ]
            ],
            [
              [
                [-61.875781, -39.171875],
                [-61.865967, -39.234863],
                [-61.918018, -39.227441],
                [-62.041602, -39.166895],
                [-62.083301, -39.110156],
                [-62.093018, -39.08623],
                [-61.96665, -39.112207],
                [-61.907129, -39.135645],
                [-61.875781, -39.171875]
              ]
            ],
            [
              [
                [-65.771045, -22.099609],
                [-65.686182, -22.110254],
                [-65.518799, -22.094531],
                [-65.484863, -22.098145],
                [-65.057812, -22.102734],
                [-64.992627, -22.109668],
                [-64.843066, -22.143945],
                [-64.758643, -22.171289],
                [-64.700098, -22.185547],
                [-64.605518, -22.228809],
                [-64.523633, -22.371582],
                [-64.477734, -22.485352],
                [-64.445508, -22.585352],
                [-64.373975, -22.761035],
                [-64.325293, -22.827637],
                [-64.30791, -22.795312],
                [-64.266406, -22.60332],
                [-64.209082, -22.491309],
                [-64.131836, -22.36582],
                [-63.976123, -22.072559],
                [-63.92168, -22.028613],
                [-63.861035, -22.007227],
                [-63.818652, -22.005469],
                [-63.775586, -22.027246],
                [-63.716943, -22.027539],
                [-63.675342, -22.004297],
                [-63.267188, -22.000586],
                [-62.843359, -21.997266],
                [-62.834277, -21.999121],
                [-62.815088, -22.049609],
                [-62.74458, -22.159863],
                [-62.665283, -22.217969],
                [-62.650977, -22.233691],
                [-62.625684, -22.261523],
                [-62.625977, -22.29043],
                [-62.541553, -22.349609],
                [-62.37251, -22.43916],
                [-62.21416, -22.612402],
                [-62.066602, -22.869434],
                [-61.928027, -23.059277],
                [-61.798535, -23.182031],
                [-61.679492, -23.26875],
                [-61.570996, -23.319434],
                [-61.513037, -23.360449],
                [-61.505518, -23.391992],
                [-61.403955, -23.45752],
                [-61.208398, -23.557031],
                [-61.084717, -23.656445],
                [-61.03291, -23.755664],
                [-60.839844, -23.858105],
                [-60.505371, -23.963574],
                [-60.262207, -24.013965],
                [-60.110303, -24.00918],
                [-59.89248, -24.093555],
                [-59.608594, -24.266797],
                [-59.4354, -24.387012],
                [-59.372949, -24.453906],
                [-59.187256, -24.562305],
                [-58.724023, -24.786621],
                [-58.519629, -24.842871],
                [-58.422803, -24.894141],
                [-58.365381, -24.959277],
                [-58.308691, -24.979102],
                [-58.252783, -24.953809],
                [-58.136475, -24.977148],
                [-57.959814, -25.049219],
                [-57.82168, -25.136426],
                [-57.643896, -25.328418],
                [-57.587158, -25.405078],
                [-57.563135, -25.47373],
                [-57.57168, -25.53418],
                [-57.62583, -25.59873],
                [-57.725488, -25.667188],
                [-57.754785, -25.69707],
                [-57.75708, -25.725977],
                [-57.782471, -25.783691],
                [-57.865234, -25.906934],
                [-57.88623, -25.964258],
                [-57.890625, -26.006543],
                [-57.943115, -26.05293],
                [-58.082422, -26.138574],
                [-58.111133, -26.180176],
                [-58.118066, -26.224902],
                [-58.135645, -26.251465],
                [-58.154688, -26.262598],
                [-58.181494, -26.307422],
                [-58.203027, -26.381445],
                [-58.205176, -26.476562],
                [-58.187939, -26.592578],
                [-58.191309, -26.62998],
                [-58.22207, -26.65],
                [-58.239355, -26.676855],
                [-58.245557, -26.731055],
                [-58.27168, -26.770703],
                [-58.317676, -26.795898],
                [-58.334668, -26.824902],
                [-58.322559, -26.857617],
                [-58.356445, -26.890039],
                [-58.436328, -26.921973],
                [-58.485254, -26.968457],
                [-58.503223, -27.029492],
                [-58.547705, -27.083984],
                [-58.618604, -27.132129],
                [-58.641748, -27.196094],
                [-58.604834, -27.314355],
                [-58.168262, -27.273437],
                [-57.812207, -27.316602],
                [-57.39126, -27.430469],
                [-57.111816, -27.470117],
                [-56.973975, -27.435742],
                [-56.871729, -27.440625],
                [-56.805176, -27.484668],
                [-56.715723, -27.49375],
                [-56.603369, -27.467871],
                [-56.510547, -27.487891],
                [-56.437158, -27.553809],
                [-56.370508, -27.537402],
                [-56.310547, -27.43877],
                [-56.241699, -27.366797],
                [-56.164063, -27.321484],
                [-56.067334, -27.307715],
                [-55.951465, -27.325684],
                [-55.859033, -27.361914],
                [-55.78999, -27.416406],
                [-55.714648, -27.414844],
                [-55.63291, -27.357129],
                [-55.593799, -27.288086],
                [-55.597266, -27.207617],
                [-55.564893, -27.15],
                [-55.496729, -27.115332],
                [-55.450635, -27.068359],
                [-55.42666, -27.009277],
                [-55.345801, -26.973145],
                [-55.208008, -26.960156],
                [-55.135938, -26.931152],
                [-55.129639, -26.886035],
                [-55.088867, -26.844531],
                [-55.013623, -26.806641],
                [-54.962158, -26.759375],
                [-54.934473, -26.702539],
                [-54.888916, -26.666797],
                [-54.825488, -26.652246],
                [-54.755078, -26.53291],
                [-54.677734, -26.308789],
                [-54.631934, -26.005762],
                [-54.615869, -25.576074],
                [-54.537842, -25.576465],
                [-54.501514, -25.608301],
                [-54.443945, -25.625],
                [-54.38335, -25.588672],
                [-54.331885, -25.571875],
                [-54.250098, -25.57041],
                [-54.206152, -25.52959],
                [-54.15459, -25.523047],
                [-54.119238, -25.545215],
                [-54.08501, -25.571875],
                [-54.012305, -25.57793],
                [-53.954785, -25.647656],
                [-53.891162, -25.668848],
                [-53.864209, -25.748828],
                [-53.823242, -25.95957],
                [-53.746924, -26.083691],
                [-53.671289, -26.225098],
                [-53.668555, -26.288184],
                [-53.710938, -26.351855],
                [-53.718164, -26.443164],
                [-53.74458, -26.666504],
                [-53.75332, -26.748633],
                [-53.727148, -26.804688],
                [-53.717285, -26.882812],
                [-53.758496, -26.97832],
                [-53.838184, -27.121094],
                [-53.915625, -27.15957],
                [-53.935352, -27.161133],
                [-54.040137, -27.24375],
                [-54.113818, -27.274707],
                [-54.156445, -27.253809],
                [-54.205225, -27.289648],
                [-54.260156, -27.382031],
                [-54.327002, -27.423535],
                [-54.448145, -27.446484],
                [-54.484326, -27.457324],
                [-54.554932, -27.454102],
                [-54.61543, -27.477148],
                [-54.665869, -27.526563],
                [-54.719727, -27.544922],
                [-54.7771, -27.53252],
                [-54.829102, -27.550586],
                [-54.875732, -27.599219],
                [-54.902783, -27.651953],
                [-54.910205, -27.708594],
                [-54.955908, -27.747168],
                [-55.039941, -27.767773],
                [-55.068994, -27.796289],
                [-55.063867, -27.835937],
                [-55.101514, -27.866797],
                [-55.24375, -27.898828],
                [-55.346484, -27.955957],
                [-55.409814, -28.037793],
                [-55.47666, -28.089355],
                [-55.582373, -28.120996],
                [-55.725488, -28.204102],
                [-55.745996, -28.255469],
                [-55.691504, -28.302832],
                [-55.671973, -28.344922],
                [-55.687256, -28.381641],
                [-55.731982, -28.386621],
                [-55.806055, -28.359766],
                [-55.858887, -28.354199],
                [-55.890527, -28.37002],
                [-55.90542, -28.399609],
                [-55.903662, -28.443262],
                [-55.930176, -28.472852],
                [-55.984912, -28.488574],
                [-56.019629, -28.524609],
                [-56.034229, -28.580859],
                [-56.102881, -28.651758],
                [-56.225537, -28.737207],
                [-56.322363, -28.852441],
                [-56.393262, -28.997266],
                [-56.475977, -29.09248],
                [-56.570703, -29.138086],
                [-56.63584, -29.203027],
                [-56.671533, -29.287305],
                [-56.772461, -29.417871],
                [-56.938623, -29.594824],
                [-57.089355, -29.716211],
                [-57.224658, -29.782129],
                [-57.300684, -29.856543],
                [-57.31748, -29.939453],
                [-57.405225, -30.033887],
                [-57.563867, -30.139941],
                [-57.608887, -30.187793],
                [-57.645752, -30.226953],
                [-57.650879, -30.29502],
                [-57.712695, -30.384473],
                [-57.831201, -30.495215],
                [-57.87251, -30.591016],
                [-57.818555, -30.712012],
                [-57.810596, -30.858594],
                [-57.834082, -30.91748],
                [-57.886328, -30.937402],
                [-57.898291, -30.975195],
                [-57.870068, -31.031055],
                [-57.868408, -31.104395],
                [-57.893359, -31.195312],
                [-57.94834, -31.299414],
                [-58.033398, -31.416602],
                [-58.053857, -31.494922],
                [-58.009668, -31.534375],
                [-57.987988, -31.576172],
                [-57.988867, -31.620605],
                [-58.006982, -31.684961],
                [-58.042334, -31.769238],
                [-58.09585, -31.831836],
                [-58.16748, -31.872656],
                [-58.189014, -31.924219],
                [-58.1604, -31.986523],
                [-58.156348, -32.051562],
                [-58.177002, -32.119043],
                [-58.164795, -32.184863],
                [-58.119727, -32.248926],
                [-58.123047, -32.321875],
                [-58.201172, -32.47168],
                [-58.219971, -32.563965],
                [-58.170996, -32.959277],
                [-58.200781, -33.014648],
                [-58.250391, -33.07832],
                [-58.308887, -33.08291],
                [-58.375977, -33.071875],
                [-58.424463, -33.111523],
                [-58.454834, -33.285937],
                [-58.547217, -33.663477],
                [-58.530566, -33.753027],
                [-58.456592, -33.89834],
                [-58.429492, -33.990918],
                [-58.409033, -34.060742],
                [-58.39248, -34.192969],
                [-58.435498, -34.252539],
                [-58.475244, -34.262988],
                [-58.525488, -34.296191],
                [-58.466211, -34.457422],
                [-58.418945, -34.531641],
                [-58.28335, -34.683496],
                [-57.763574, -34.894531],
                [-57.547852, -35.018945],
                [-57.303662, -35.188477],
                [-57.170654, -35.3625],
                [-57.158887, -35.505957],
                [-57.353906, -35.720312],
                [-57.375488, -35.900293],
                [-57.335449, -36.026758],
                [-57.26499, -36.144141],
                [-57.076172, -36.296777],
                [-56.937158, -36.352539],
                [-56.749463, -36.346484],
                [-56.717383, -36.389062],
                [-56.698096, -36.426465],
                [-56.668262, -36.735254],
                [-56.672021, -36.85127],
                [-56.727148, -36.957715],
                [-57.087695, -37.446387],
                [-57.395752, -37.744629],
                [-57.507275, -37.909277],
                [-57.546973, -38.085645],
                [-57.645605, -38.169629],
                [-58.179199, -38.43584],
                [-59.007227, -38.67334],
                [-59.67627, -38.79668],
                [-59.82832, -38.838184],
                [-60.903955, -38.973926],
                [-61.112207, -38.992969],
                [-61.382861, -38.980859],
                [-61.602539, -38.998828],
                [-61.8479, -38.961816],
                [-62.066895, -38.919141],
                [-62.189258, -38.813281],
                [-62.334766, -38.800098],
                [-62.374463, -38.85293],
                [-62.303613, -38.988086],
                [-62.338086, -39.150586],
                [-62.295068, -39.243262],
                [-62.209082, -39.261816],
                [-62.126465, -39.309766],
                [-62.053662, -39.373828],
                [-62.179346, -39.380469],
                [-62.130566, -39.431543],
                [-62.076807, -39.461523],
                [-62.082764, -39.568359],
                [-62.131543, -39.825391],
                [-62.253955, -39.880469],
                [-62.286914, -39.895313],
                [-62.323975, -39.950684],
                [-62.401855, -40.196582],
                [-62.427002, -40.355957],
                [-62.393604, -40.458789],
                [-62.246338, -40.674609],
                [-62.301855, -40.814648],
                [-62.39502, -40.89082],
                [-62.797998, -41.047168],
                [-62.959033, -41.109668],
                [-63.212842, -41.152441],
                [-63.621777, -41.159766],
                [-63.772998, -41.15],
                [-64.123193, -41.007812],
                [-64.383447, -40.922461],
                [-64.621484, -40.854492],
                [-64.852979, -40.81377],
                [-64.819873, -40.793262],
                [-64.804395, -40.756543],
                [-64.869482, -40.73584],
                [-64.916895, -40.731348],
                [-65.069434, -40.805273],
                [-65.133398, -40.880664],
                [-65.151855, -40.946973],
                [-65.15498, -41.105664],
                [-65.127881, -41.23877],
                [-65.018262, -41.566895],
                [-65.007031, -41.745117],
                [-65.059082, -41.969922],
                [-64.986377, -42.102051],
                [-64.898047, -42.161816],
                [-64.699512, -42.220801],
                [-64.622461, -42.261035],
                [-64.537744, -42.25459],
                [-64.511719, -42.270215],
                [-64.524219, -42.299219],
                [-64.574121, -42.355957],
                [-64.570996, -42.416016],
                [-64.42041, -42.433789],
                [-64.2646, -42.42168],
                [-64.100879, -42.395117],
                [-64.062207, -42.353418],
                [-64.061182, -42.266113],
                [-64.25293, -42.250781],
                [-64.228516, -42.218262],
                [-64.083252, -42.182813],
                [-63.892871, -42.124609],
                [-63.795557, -42.113867],
                [-63.729492, -42.15293],
                [-63.684766, -42.188672],
                [-63.629883, -42.282715],
                [-63.595898, -42.406543],
                [-63.594434, -42.555566],
                [-63.617334, -42.695801],
                [-63.644482, -42.745703],
                [-63.69248, -42.805273],
                [-64.034766, -42.88125],
                [-64.130664, -42.861426],
                [-64.219922, -42.755566],
                [-64.247949, -42.646094],
                [-64.324268, -42.572266],
                [-64.487842, -42.513477],
                [-64.650488, -42.531445],
                [-64.811963, -42.633203],
                [-64.970703, -42.666309],
                [-65.026904, -42.758887],
                [-64.629199, -42.908984],
                [-64.441553, -42.950684],
                [-64.380371, -42.949219],
                [-64.319141, -42.968945],
                [-64.375684, -43.024609],
                [-64.432227, -43.05918],
                [-64.715234, -43.135547],
                [-64.839941, -43.188867],
                [-64.985547, -43.293555],
                [-65.189746, -43.52207],
                [-65.252344, -43.571875],
                [-65.283594, -43.62998],
                [-65.304687, -43.7875],
                [-65.238574, -44.04873],
                [-65.308398, -44.158203],
                [-65.265527, -44.279687],
                [-65.289844, -44.360742],
                [-65.361279, -44.477344],
                [-65.647607, -44.661426],
                [-65.69834, -44.796191],
                [-65.599121, -44.875586],
                [-65.605713, -44.94502],
                [-65.63877, -45.007812],
                [-65.757715, -45.007129],
                [-66.190137, -44.964746],
                [-66.347754, -45.033594],
                [-66.493604, -45.117578],
                [-66.533447, -45.157813],
                [-66.585059, -45.18291],
                [-66.882471, -45.227637],
                [-66.941406, -45.257324],
                [-67.257617, -45.577246],
                [-67.393018, -45.775586],
                [-67.556641, -45.970117],
                [-67.599561, -46.052539],
                [-67.608887, -46.166797],
                [-67.586084, -46.269531],
                [-67.563379, -46.34541],
                [-67.506445, -46.442773],
                [-67.386621, -46.553809],
                [-66.776855, -47.005859],
                [-66.650391, -47.045312],
                [-65.998535, -47.09375],
                [-65.853662, -47.156738],
                [-65.769092, -47.256738],
                [-65.738086, -47.344922],
                [-65.775391, -47.568359],
                [-65.814307, -47.638184],
                [-65.886328, -47.701562],
                [-66.040625, -47.783301],
                [-66.225244, -47.826758],
                [-66.172363, -47.857617],
                [-66.097363, -47.853223],
                [-65.934229, -47.826758],
                [-65.863672, -47.853223],
                [-65.810059, -47.941113],
                [-65.912158, -47.976758],
                [-65.943408, -48.019336],
                [-66.017187, -48.084277],
                [-66.393359, -48.342383],
                [-66.596289, -48.419531],
                [-66.782813, -48.522949],
                [-67.033105, -48.627734],
                [-67.130957, -48.687891],
                [-67.26333, -48.814258],
                [-67.466309, -48.951758],
                [-67.684863, -49.24668],
                [-67.693701, -49.304004],
                [-67.661963, -49.342188],
                [-67.783496, -49.858887],
                [-67.825977, -49.919629],
                [-67.913965, -49.984473],
                [-68.145654, -50.091406],
                [-68.257227, -50.10459],
                [-68.404639, -50.042676],
                [-68.487891, -49.97793],
                [-68.569287, -49.866992],
                [-68.667578, -49.752539],
                [-68.672656, -49.793457],
                [-68.638477, -49.862988],
                [-68.661621, -49.935742],
                [-68.912988, -49.96875],
                [-68.97959, -50.003027],
                [-68.752686, -49.987695],
                [-68.597949, -50.009473],
                [-68.532568, -50.036133],
                [-68.47373, -50.091406],
                [-68.421875, -50.15791],
                [-68.46543, -50.194727],
                [-68.589355, -50.225195],
                [-68.749854, -50.281152],
                [-68.939453, -50.382324],
                [-69.044775, -50.499121],
                [-69.090186, -50.583105],
                [-69.141406, -50.752539],
                [-69.15498, -50.864453],
                [-69.235156, -50.950586],
                [-69.358594, -51.028125],
                [-69.351758, -51.045801],
                [-69.267969, -51.006152],
                [-69.201025, -50.993652],
                [-69.143506, -51.096973],
                [-69.065723, -51.303516],
                [-69.02959, -51.446484],
                [-69.035303, -51.488965],
                [-69.058301, -51.547168],
                [-69.218066, -51.56123],
                [-69.360547, -51.559473],
                [-69.46543, -51.584473],
                [-69.409082, -51.610254],
                [-69.313037, -51.601074],
                [-69.180127, -51.662305],
                [-69.03252, -51.63623],
                [-68.965332, -51.677148],
                [-68.916797, -51.714648],
                [-68.69082, -52.013086],
                [-68.493506, -52.197559],
                [-68.39375, -52.307031],
                [-68.443359, -52.356641],
                [-68.460986, -52.29043],
                [-68.589795, -52.27334],
                [-68.715186, -52.255469],
                [-68.924561, -52.208105],
                [-69.206201, -52.136133],
                [-69.488428, -52.136133],
                [-69.712598, -52.075391],
                [-69.960254, -52.008203],
                [-70.482861, -52.002246],
                [-70.943164, -51.998145],
                [-71.414746, -51.993945],
                [-71.716602, -51.991309],
                [-71.918652, -51.989551],
                [-71.971094, -51.96416],
                [-71.953467, -51.880371],
                [-72.028418, -51.818652],
                [-72.136963, -51.744043],
                [-72.268994, -51.691113],
                [-72.334521, -51.620313],
                [-72.407666, -51.54082],
                [-72.366406, -51.470313],
                [-72.303223, -51.298926],
                [-72.301855, -51.22334],
                [-72.35918, -51.17041],
                [-72.376807, -51.09541],
                [-72.35918, -51.060156],
                [-72.307373, -51.033398],
                [-72.276318, -50.910254],
                [-72.300635, -50.789551],
                [-72.340234, -50.681836],
                [-72.392578, -50.634277],
                [-72.460156, -50.611719],
                [-72.509814, -50.60752],
                [-72.62041, -50.647656],
                [-72.803613, -50.637695],
                [-72.865918, -50.653125],
                [-72.955566, -50.696484],
                [-73.082373, -50.760352],
                [-73.15293, -50.738281],
                [-73.174512, -50.67002],
                [-73.221631, -50.610742],
                [-73.251611, -50.558496],
                [-73.27417, -50.472559],
                [-73.311719, -50.361914],
                [-73.386621, -50.231152],
                [-73.50127, -50.125293],
                [-73.507715, -50.030273],
                [-73.528906, -49.910938],
                [-73.47041, -49.794531],
                [-73.504541, -49.698047],
                [-73.57627, -49.58291],
                [-73.554199, -49.463867],
                [-73.483643, -49.397656],
                [-73.461572, -49.313867],
                [-73.135254, -49.300684],
                [-73.148877, -49.187988],
                [-73.09458, -49.096875],
                [-73.033643, -49.014355],
                [-72.981738, -48.976758],
                [-72.86543, -48.943945],
                [-72.728467, -48.896289],
                [-72.65127, -48.841602],
                [-72.614404, -48.792871],
                [-72.591748, -48.729688],
                [-72.585938, -48.6625],
                [-72.608398, -48.519336],
                [-72.582861, -48.475391],
                [-72.498145, -48.417383],
                [-72.354736, -48.36582],
                [-72.293018, -48.229102],
                [-72.32832, -48.110059],
                [-72.40791, -48.015918],
                [-72.509082, -47.97334],
                [-72.51792, -47.876367],
                [-72.472217, -47.78418],
                [-72.412598, -47.685547],
                [-72.341504, -47.57207],
                [-72.345947, -47.492676],
                [-72.28291, -47.446289],
                [-72.103418, -47.342773],
                [-72.041699, -47.241406],
                [-71.978516, -47.213867],
                [-71.90498, -47.20166],
                [-71.900537, -47.144336],
                [-71.954248, -47.0875],
                [-71.962988, -47.016016],
                [-71.956641, -46.936816],
                [-71.940234, -46.83125],
                [-71.856445, -46.791602],
                [-71.732715, -46.705859],
                [-71.699658, -46.651367],
                [-71.695215, -46.578418],
                [-71.731299, -46.427832],
                [-71.762109, -46.319824],
                [-71.777637, -46.27998],
                [-71.834131, -46.206738],
                [-71.875684, -46.160547],
                [-71.809277, -46.102734],
                [-71.684473, -46.041895],
                [-71.631543, -45.953711],
                [-71.680078, -45.878711],
                [-71.750635, -45.839062],
                [-71.772656, -45.724414],
                [-71.746191, -45.578906],
                [-71.693311, -45.534766],
                [-71.508105, -45.512695],
                [-71.49043, -45.437695],
                [-71.349316, -45.331934],
                [-71.35376, -45.230469],
                [-71.443457, -45.168262],
                [-71.531299, -45.067871],
                [-71.596289, -44.979199],
                [-71.812354, -44.930664],
                [-72.041699, -44.904199],
                [-72.07251, -44.82041],
                [-72.063721, -44.771875],
                [-71.957031, -44.791504],
                [-71.782812, -44.774414],
                [-71.65166, -44.77041],
                [-71.5604, -44.762012],
                [-71.455176, -44.749805],
                [-71.358154, -44.785156],
                [-71.261133, -44.763086],
                [-71.221484, -44.630762],
                [-71.159717, -44.560254],
                [-71.150879, -44.494043],
                [-71.212598, -44.441211],
                [-71.325732, -44.424902],
                [-71.82002, -44.383105],
                [-71.835059, -44.330176],
                [-71.830762, -44.241406],
                [-71.812109, -44.150781],
                [-71.812354, -44.106055],
                [-71.767188, -44.066699],
                [-71.716162, -43.984473],
                [-71.680078, -43.92959],
                [-71.715967, -43.858398],
                [-71.794727, -43.753223],
                [-71.737402, -43.704687],
                [-71.732764, -43.646777],
                [-71.750635, -43.590137],
                [-71.832422, -43.527148],
                [-71.90498, -43.440137],
                [-71.90498, -43.347559],
                [-71.820215, -43.322949],
                [-71.763867, -43.294629],
                [-71.750635, -43.237305],
                [-71.781494, -43.166797],
                [-71.898584, -43.145312],
                [-72.054687, -43.101953],
                [-72.102393, -43.065625],
                [-72.146436, -42.990039],
                [-72.113623, -42.776758],
                [-72.130029, -42.648242],
                [-72.143701, -42.577148],
                [-72.10542, -42.522461],
                [-72.053467, -42.473242],
                [-72.078125, -42.358496],
                [-72.124609, -42.29834],
                [-72.108203, -42.251855],
                [-72.064404, -42.205371],
                [-72.026123, -42.147949],
                [-71.993311, -42.134277],
                [-71.944092, -42.16709],
                [-71.860791, -42.147852],
                [-71.760937, -42.101465],
                [-71.75, -42.046777],
                [-71.77002, -41.968555],
                [-71.844482, -41.771973],
                [-71.911279, -41.650391],
                [-71.897607, -41.606641],
                [-71.871143, -41.560547],
                [-71.892187, -41.393359],
                [-71.885596, -41.292383],
                [-71.880713, -40.994629],
                [-71.873047, -40.892969],
                [-71.941357, -40.78916],
                [-71.932129, -40.691699],
                [-71.883789, -40.620605],
                [-71.838525, -40.524414],
                [-71.804639, -40.43916],
                [-71.769141, -40.400879],
                [-71.708984, -40.381738],
                [-71.695313, -40.335254],
                [-71.722656, -40.299707],
                [-71.800586, -40.244336],
                [-71.818311, -40.17666],
                [-71.801953, -40.124707],
                [-71.763672, -40.094629],
                [-71.704395, -40.094922],
                [-71.659766, -40.020801],
                [-71.647119, -39.929199],
                [-71.637891, -39.886816],
                [-71.67207, -39.833301],
                [-71.696826, -39.707031],
                [-71.719922, -39.635254],
                [-71.692578, -39.605176],
                [-71.654297, -39.594238],
                [-71.587012, -39.611133],
                [-71.539453, -39.602441],
                [-71.53125, -39.56416],
                [-71.525781, -39.523145],
                [-71.507764, -39.495215],
                [-71.465381, -39.402344],
                [-71.42002, -39.287207],
                [-71.409375, -39.205957],
                [-71.425586, -38.985645],
                [-71.401562, -38.935059],
                [-71.353174, -38.888867],
                [-71.285742, -38.84541],
                [-71.197266, -38.809375],
                [-71.087109, -38.75752],
                [-70.951611, -38.738477],
                [-70.896924, -38.681055],
                [-70.858643, -38.604492],
                [-70.847656, -38.541602],
                [-70.899658, -38.497852],
                [-70.967969, -38.445898],
                [-71.000488, -38.314844],
                [-71.018164, -38.193945],
                [-71.028174, -38.041211],
                [-71.096191, -37.909961],
                [-71.167578, -37.762305],
                [-71.186719, -37.631055],
                [-71.162842, -37.55918],
                [-71.134814, -37.445117],
                [-71.164893, -37.393262],
                [-71.200391, -37.300293],
                [-71.163477, -37.227441],
                [-71.118408, -37.114355],
                [-71.123828, -37.056934],
                [-71.159375, -36.920215],
                [-71.192188, -36.843652],
                [-71.159375, -36.761621],
                [-71.107422, -36.685059],
                [-71.066406, -36.644043],
                [-71.073242, -36.578027],
                [-71.055518, -36.52373],
                [-70.97793, -36.487305],
                [-70.905127, -36.419922],
                [-70.853174, -36.411719],
                [-70.790283, -36.411719],
                [-70.749268, -36.392578],
                [-70.732861, -36.340625],
                [-70.721924, -36.283203],
                [-70.621875, -36.211914],
                [-70.563379, -36.146387],
                [-70.456738, -36.132715],
                [-70.404785, -36.061719],
                [-70.403662, -35.970508],
                [-70.415723, -35.878516],
                [-70.380176, -35.771875],
                [-70.419727, -35.60918],
                [-70.415723, -35.523047],
                [-70.456738, -35.451953],
                [-70.448535, -35.375391],
                [-70.47041, -35.326172],
                [-70.532324, -35.30791],
                [-70.555176, -35.246875],
                [-70.525098, -35.216797],
                [-70.466602, -35.193652],
                [-70.393164, -35.146875],
                [-70.338135, -34.921777],
                [-70.312109, -34.85498],
                [-70.286768, -34.774512],
                [-70.289941, -34.732812],
                [-70.254687, -34.672656],
                [-70.210693, -34.58125],
                [-70.14126, -34.492871],
                [-70.101465, -34.432031],
                [-70.062988, -34.35],
                [-70.052051, -34.300781],
                [-70.002832, -34.27627],
                [-69.946338, -34.269922],
                [-69.879785, -34.254395],
                [-69.852441, -34.224316],
                [-69.857373, -34.180469],
                [-69.861523, -34.083594],
                [-69.881494, -33.929785],
                [-69.894336, -33.731348],
                [-69.882568, -33.600977],
                [-69.83877, -33.469727],
                [-69.797754, -33.398633],
                [-69.808691, -33.343945],
                [-69.819629, -33.283789],
                [-69.896191, -33.250977],
                [-69.969043, -33.279395],
                [-70.019824, -33.271484],
                [-70.084863, -33.201758],
                [-70.104004, -33.12793],
                [-70.093066, -33.026758],
                [-70.042139, -32.963672],
                [-70.021973, -32.88457],
                [-70.052051, -32.859961],
                [-70.116162, -32.807422],
                [-70.176953, -32.626074],
                [-70.169629, -32.47168],
                [-70.229785, -32.430664],
                [-70.257812, -32.309961],
                [-70.32002, -32.266699],
                [-70.344629, -32.176465],
                [-70.36377, -32.083496],
                [-70.355566, -32.042383],
                [-70.290918, -32.031055],
                [-70.254395, -31.957715],
                [-70.281738, -31.916602],
                [-70.330957, -31.881055],
                [-70.393848, -31.883789],
                [-70.450146, -31.841895],
                [-70.525635, -31.666406],
                [-70.585205, -31.569434],
                [-70.566406, -31.42793],
                [-70.554688, -31.317383],
                [-70.529053, -31.222852],
                [-70.51958, -31.148438],
                [-70.473096, -31.112793],
                [-70.429395, -31.129297],
                [-70.388379, -31.121094],
                [-70.350586, -31.060449],
                [-70.309082, -31.022656],
                [-70.311816, -30.992578],
                [-70.336426, -30.959766],
                [-70.348145, -30.902344],
                [-70.319238, -30.833984],
                [-70.269385, -30.677246],
                [-70.193945, -30.504688],
                [-70.161426, -30.440234],
                [-70.169629, -30.385547],
                [-70.153223, -30.360937],
                [-70.102002, -30.388281],
                [-69.956348, -30.358203],
                [-69.907129, -30.281641],
                [-69.888037, -30.213281],
                [-69.844287, -30.175],
                [-69.863379, -30.120312],
                [-69.923535, -30.103906],
                [-69.959961, -30.07832],
                [-69.945459, -30.016406],
                [-69.924121, -29.874023],
                [-69.927637, -29.769141],
                [-69.982617, -29.54541],
                [-70.026807, -29.324023],
                [-69.995605, -29.25],
                [-69.900342, -29.148828],
                [-69.827881, -29.103223],
                [-69.814844, -29.045508],
                [-69.743164, -28.783887],
                [-69.734912, -28.641113],
                [-69.687891, -28.562012],
                [-69.656934, -28.413574],
                [-69.527148, -28.285645],
                [-69.488867, -28.200879],
                [-69.436914, -28.192676],
                [-69.40957, -28.165332],
                [-69.340723, -28.070801],
                [-69.251221, -27.973633],
                [-69.174414, -27.924707],
                [-69.155273, -27.848145],
                [-69.118506, -27.743555],
                [-69.042187, -27.57002],
                [-68.999414, -27.449023],
                [-68.941992, -27.405176],
                [-68.875098, -27.24668],
                [-68.846338, -27.153711],
                [-68.769775, -27.11543],
                [-68.709619, -27.104492],
                [-68.652197, -27.14834],
                [-68.59209, -27.140039],
                [-68.537354, -27.085352],
                [-68.405371, -27.048145],
                [-68.345996, -27.02793],
                [-68.318652, -26.973242],
                [-68.318652, -26.877539],
                [-68.37334, -26.806445],
                [-68.485107, -26.670313],
                [-68.581152, -26.518359],
                [-68.591602, -26.47041],
                [-68.592187, -26.418066],
                [-68.575781, -26.351953],
                [-68.529834, -26.276953],
                [-68.414502, -26.153711],
                [-68.426758, -26.06543],
                [-68.51084, -25.741016],
                [-68.541895, -25.651563],
                [-68.600293, -25.485645],
                [-68.59209, -25.42002],
                [-68.54082, -25.236719],
                [-68.496338, -25.162988],
                [-68.430713, -25.149316],
                [-68.395215, -25.124707],
                [-68.384229, -25.091895],
                [-68.428027, -25.050977],
                [-68.447119, -24.998926],
                [-68.466309, -24.925195],
                [-68.527051, -24.899219],
                [-68.562012, -24.837695],
                [-68.562012, -24.747363],
                [-68.507275, -24.629785],
                [-68.447119, -24.596973],
                [-68.422559, -24.545117],
                [-68.358105, -24.497266],
                [-68.299512, -24.460352],
                [-68.250293, -24.391992],
                [-68.047363, -24.308301],
                [-67.88623, -24.243359],
                [-67.571777, -24.118945],
                [-67.356201, -24.033789],
                [-67.335596, -23.974805],
                [-67.319141, -23.934668],
                [-67.219141, -23.633984],
                [-67.089746, -23.245117],
                [-67.008789, -23.001367],
                [-67.194873, -22.82168],
                [-67.161914, -22.773828],
                [-67.05542, -22.650879],
                [-67.033545, -22.552246],
                [-66.991113, -22.509863],
                [-66.800293, -22.409668],
                [-66.76748, -22.343066],
                [-66.750635, -22.269336],
                [-66.711719, -22.216309],
                [-66.639014, -22.205371],
                [-66.506982, -22.158398],
                [-66.365186, -22.11377],
                [-66.322461, -22.053125],
                [-66.282129, -21.947461],
                [-66.247607, -21.830469],
                [-66.220166, -21.802539],
                [-66.174658, -21.805664],
                [-66.098584, -21.835059],
                [-66.058594, -21.879492],
                [-65.860156, -22.019727],
                [-65.771045, -22.099609]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Bolivia",
          "density": "11.13",
          "cartodb_id": 32,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-58.159766, -20.164648],
              [-58.139941, -19.998828],
              [-58.160059, -19.854883],
              [-58.180176, -19.817871],
              [-58.474219, -19.646094],
              [-58.741113, -19.490234],
              [-59.090527, -19.28623],
              [-59.540869, -19.291797],
              [-60.007373, -19.297559],
              [-60.451611, -19.38877],
              [-60.88877, -19.478516],
              [-61.095996, -19.520996],
              [-61.511816, -19.606445],
              [-61.756836, -19.645313],
              [-61.820898, -19.809473],
              [-61.916943, -20.055371],
              [-62.011816, -20.199023],
              [-62.121631, -20.349902],
              [-62.276318, -20.5625],
              [-62.276514, -20.820801],
              [-62.27666, -21.066016],
              [-62.385449, -21.411719],
              [-62.477832, -21.705273],
              [-62.566943, -21.988672],
              [-62.628516, -22.183984],
              [-62.650977, -22.233691],
              [-62.665283, -22.217969],
              [-62.74458, -22.159863],
              [-62.815088, -22.049609],
              [-62.834277, -21.999121],
              [-62.843359, -21.997266],
              [-63.267187, -22.000586],
              [-63.675342, -22.004297],
              [-63.716943, -22.027539],
              [-63.775586, -22.027246],
              [-63.818652, -22.005469],
              [-63.861035, -22.007227],
              [-63.92168, -22.028613],
              [-63.976123, -22.072559],
              [-64.131836, -22.36582],
              [-64.209082, -22.491309],
              [-64.266406, -22.60332],
              [-64.30791, -22.795313],
              [-64.325293, -22.827637],
              [-64.373975, -22.761035],
              [-64.445508, -22.585352],
              [-64.477734, -22.485352],
              [-64.523633, -22.371582],
              [-64.605518, -22.228809],
              [-64.700098, -22.185547],
              [-64.758643, -22.171289],
              [-64.843066, -22.143945],
              [-64.992627, -22.109668],
              [-65.057812, -22.102734],
              [-65.484863, -22.098145],
              [-65.518799, -22.094531],
              [-65.686182, -22.110254],
              [-65.771045, -22.099609],
              [-65.860156, -22.019727],
              [-66.058594, -21.879492],
              [-66.098584, -21.835059],
              [-66.174658, -21.805664],
              [-66.220166, -21.802539],
              [-66.247607, -21.830469],
              [-66.282129, -21.947461],
              [-66.322461, -22.053125],
              [-66.365186, -22.11377],
              [-66.506982, -22.158398],
              [-66.639014, -22.205371],
              [-66.711719, -22.216309],
              [-66.750635, -22.269336],
              [-66.76748, -22.343066],
              [-66.800293, -22.409668],
              [-66.991113, -22.509863],
              [-67.033545, -22.552246],
              [-67.05542, -22.650879],
              [-67.161914, -22.773828],
              [-67.194873, -22.82168],
              [-67.362256, -22.855176],
              [-67.579932, -22.891699],
              [-67.707324, -22.88916],
              [-67.794434, -22.879492],
              [-67.820508, -22.857715],
              [-67.879443, -22.822949],
              [-67.88916, -22.78418],
              [-67.88999, -22.729199],
              [-67.87373, -22.630566],
              [-67.881738, -22.493359],
              [-67.950391, -22.333691],
              [-67.944922, -22.282227],
              [-67.953906, -22.204004],
              [-67.988379, -22.057129],
              [-68.076758, -21.982813],
              [-68.101807, -21.860645],
              [-68.112158, -21.753027],
              [-68.186426, -21.618555],
              [-68.198535, -21.447266],
              [-68.197021, -21.300293],
              [-68.313867, -21.129688],
              [-68.435498, -20.948242],
              [-68.533838, -20.923633],
              [-68.558252, -20.901953],
              [-68.568945, -20.849805],
              [-68.571045, -20.769141],
              [-68.563184, -20.720117],
              [-68.487402, -20.640723],
              [-68.484326, -20.628418],
              [-68.499854, -20.612012],
              [-68.695801, -20.492969],
              [-68.745166, -20.458594],
              [-68.760547, -20.416211],
              [-68.759229, -20.378027],
              [-68.712305, -20.338965],
              [-68.688574, -20.310059],
              [-68.73457, -20.225195],
              [-68.730029, -20.148438],
              [-68.759326, -20.115527],
              [-68.755811, -20.09082],
              [-68.72749, -20.069629],
              [-68.600195, -20.044922],
              [-68.560693, -19.96709],
              [-68.559375, -19.902344],
              [-68.578271, -19.856543],
              [-68.696191, -19.740723],
              [-68.698291, -19.721094],
              [-68.575293, -19.560156],
              [-68.487012, -19.454395],
              [-68.462891, -19.432813],
              [-68.470166, -19.409961],
              [-68.491992, -19.381934],
              [-68.547852, -19.341113],
              [-68.620557, -19.29668],
              [-68.680664, -19.242383],
              [-68.759082, -19.162207],
              [-68.857959, -19.093359],
              [-68.931006, -19.025195],
              [-68.968311, -18.967969],
              [-68.969092, -18.909668],
              [-68.978857, -18.812988],
              [-69.026807, -18.65625],
              [-69.039404, -18.550098],
              [-69.060156, -18.433008],
              [-69.080957, -18.356641],
              [-69.092285, -18.282422],
              [-69.126367, -18.202441],
              [-69.145459, -18.144043],
              [-69.118066, -18.102734],
              [-69.09043, -18.070703],
              [-69.093945, -18.050488],
              [-69.282324, -17.964844],
              [-69.313379, -17.943164],
              [-69.358008, -17.77168],
              [-69.49502, -17.619531],
              [-69.510937, -17.506055],
              [-69.511084, -17.504883],
              [-69.510986, -17.460352],
              [-69.521924, -17.388965],
              [-69.563818, -17.33291],
              [-69.625879, -17.294434],
              [-69.645703, -17.248535],
              [-69.624854, -17.200195],
              [-69.50332, -17.104785],
              [-69.43833, -17.088379],
              [-69.421094, -17.040039],
              [-69.381543, -17.001367],
              [-69.267236, -16.860938],
              [-69.199805, -16.768457],
              [-69.13252, -16.713086],
              [-69.054541, -16.674316],
              [-69.020703, -16.642188],
              [-69.038379, -16.542676],
              [-69.03291, -16.475977],
              [-69.00625, -16.433691],
              [-68.928027, -16.389063],
              [-68.857812, -16.354785],
              [-68.842773, -16.337891],
              [-68.848828, -16.312793],
              [-68.913477, -16.261914],
              [-69.04624, -16.217676],
              [-69.13418, -16.221973],
              [-69.187988, -16.182813],
              [-69.217578, -16.149121],
              [-69.391895, -15.736914],
              [-69.420898, -15.640625],
              [-69.418506, -15.603418],
              [-69.301904, -15.399414],
              [-69.254297, -15.33291],
              [-69.172461, -15.236621],
              [-69.187109, -15.19873],
              [-69.330713, -15.038965],
              [-69.374707, -14.962988],
              [-69.37373, -14.8875],
              [-69.359473, -14.795313],
              [-69.276025, -14.745898],
              [-69.252344, -14.671094],
              [-69.234912, -14.59707],
              [-69.199268, -14.572559],
              [-69.162695, -14.530957],
              [-69.119727, -14.470313],
              [-69.052783, -14.417578],
              [-69.013135, -14.377246],
              [-69.004492, -14.265039],
              [-68.971777, -14.234375],
              [-68.880322, -14.198828],
              [-68.870898, -14.169727],
              [-68.891699, -14.094336],
              [-68.937451, -14.014648],
              [-68.974268, -13.975977],
              [-69.023047, -13.780273],
              [-69.074121, -13.682813],
              [-69.052832, -13.643945],
              [-69.017529, -13.594434],
              [-68.983447, -13.496387],
              [-68.972266, -13.382324],
              [-68.980518, -12.962598],
              [-68.978613, -12.880078],
              [-68.93374, -12.82207],
              [-68.867676, -12.755176],
              [-68.811816, -12.72959],
              [-68.759082, -12.687207],
              [-68.762891, -12.607715],
              [-68.728125, -12.560742],
              [-68.685254, -12.501953],
              [-68.818701, -12.27041],
              [-68.936035, -12.066797],
              [-69.046191, -11.875684],
              [-69.17373, -11.654297],
              [-69.257715, -11.508594],
              [-69.362012, -11.327539],
              [-69.453613, -11.16875],
              [-69.578613, -10.951758],
              [-69.462549, -10.948145],
              [-69.228516, -10.955664],
              [-69.00166, -10.994336],
              [-68.84834, -11.011133],
              [-68.784082, -11.044629],
              [-68.769922, -11.097656],
              [-68.72749, -11.122461],
              [-68.678369, -11.112793],
              [-68.622656, -11.10918],
              [-68.49834, -11.054785],
              [-68.397998, -11.01875],
              [-68.311133, -10.975195],
              [-68.266602, -10.933105],
              [-68.158643, -10.785059],
              [-68.07168, -10.703125],
              [-67.991699, -10.674414],
              [-67.83501, -10.662793],
              [-67.785693, -10.686035],
              [-67.721777, -10.683105],
              [-67.66665, -10.598926],
              [-67.582422, -10.505957],
              [-67.416943, -10.389844],
              [-67.332715, -10.35791],
              [-67.280469, -10.317285],
              [-67.190479, -10.311426],
              [-67.111523, -10.268945],
              [-66.72998, -9.975488],
              [-66.575342, -9.899902],
              [-66.478906, -9.886133],
              [-66.399219, -9.868164],
              [-66.263574, -9.826074],
              [-65.924707, -9.785449],
              [-65.706787, -9.768457],
              [-65.637109, -9.809082],
              [-65.558691, -9.797461],
              [-65.491992, -9.731738],
              [-65.436768, -9.710449],
              [-65.396143, -9.712402],
              [-65.337891, -9.790234],
              [-65.309326, -9.872656],
              [-65.328125, -9.935547],
              [-65.324561, -10.026953],
              [-65.298584, -10.146777],
              [-65.313086, -10.253027],
              [-65.395459, -10.392285],
              [-65.436914, -10.449023],
              [-65.447119, -10.507422],
              [-65.43999, -10.58623],
              [-65.402295, -10.714746],
              [-65.334033, -10.892773],
              [-65.323779, -11.024805],
              [-65.371582, -11.110352],
              [-65.393604, -11.184277],
              [-65.389893, -11.246289],
              [-65.372852, -11.289941],
              [-65.342383, -11.315039],
              [-65.325488, -11.364746],
              [-65.322021, -11.43916],
              [-65.282275, -11.511035],
              [-65.206201, -11.580566],
              [-65.175391, -11.646875],
              [-65.189746, -11.710059],
              [-65.185742, -11.749512],
              [-65.163379, -11.765137],
              [-65.142676, -11.752344],
              [-65.115137, -11.735059],
              [-65.090283, -11.741211],
              [-65.037109, -11.829395],
              [-65.030273, -11.847363],
              [-65.001221, -11.92002],
              [-64.992529, -11.975195],
              [-64.914355, -12.005957],
              [-64.829883, -12.030273],
              [-64.783447, -12.059375],
              [-64.690039, -12.146484],
              [-64.61167, -12.203906],
              [-64.513428, -12.250977],
              [-64.480762, -12.326172],
              [-64.420508, -12.439746],
              [-64.255029, -12.483301],
              [-64.061621, -12.505078],
              [-63.938574, -12.529688],
              [-63.788086, -12.469434],
              [-63.688574, -12.478027],
              [-63.585645, -12.518945],
              [-63.541895, -12.54668],
              [-63.465234, -12.605176],
              [-63.34668, -12.680078],
              [-63.249756, -12.70791],
              [-63.180664, -12.666211],
              [-63.116797, -12.65166],
              [-63.06748, -12.669141],
              [-63.041357, -12.750391],
              [-63.015186, -12.805566],
              [-62.95791, -12.84707],
              [-62.835156, -12.953711],
              [-62.765479, -12.997266],
              [-62.687061, -12.994336],
              [-62.525537, -13.064258],
              [-62.352832, -13.132422],
              [-62.263916, -13.143652],
              [-62.176074, -13.133691],
              [-62.118018, -13.159766],
              [-62.094775, -13.241992],
              [-61.944727, -13.40625],
              [-61.874121, -13.47041],
              [-61.789941, -13.525586],
              [-61.575684, -13.524805],
              [-61.511572, -13.541211],
              [-61.416064, -13.526563],
              [-61.12915, -13.498535],
              [-61.077002, -13.489746],
              [-60.914502, -13.561426],
              [-60.722363, -13.664355],
              [-60.595312, -13.745313],
              [-60.506592, -13.789844],
              [-60.460156, -13.862402],
              [-60.422363, -13.937988],
              [-60.40498, -14.019238],
              [-60.428076, -14.1],
              [-60.462988, -14.132422],
              [-60.474658, -14.184766],
              [-60.460156, -14.263086],
              [-60.39624, -14.332813],
              [-60.372705, -14.41875],
              [-60.338037, -14.570508],
              [-60.298877, -14.618555],
              [-60.27334, -15.08877],
              [-60.402002, -15.092773],
              [-60.583203, -15.09834],
              [-60.530469, -15.143164],
              [-60.380469, -15.318262],
              [-60.242334, -15.47959],
              [-60.22041, -15.738672],
              [-60.206641, -15.901953],
              [-60.187207, -16.132129],
              [-60.175586, -16.269336],
              [-59.831152, -16.281738],
              [-59.434277, -16.295996],
              [-58.957275, -16.313184],
              [-58.537939, -16.328223],
              [-58.496582, -16.32666],
              [-58.423682, -16.30791],
              [-58.375391, -16.283594],
              [-58.345605, -16.284375],
              [-58.340576, -16.339941],
              [-58.350781, -16.410254],
              [-58.350391, -16.49082],
              [-58.470605, -16.650195],
              [-58.478125, -16.700684],
              [-58.459814, -16.910742],
              [-58.417383, -17.080566],
              [-58.395996, -17.234277],
              [-58.347754, -17.282129],
              [-58.205566, -17.363086],
              [-57.990918, -17.512891],
              [-57.905029, -17.532324],
              [-57.832471, -17.512109],
              [-57.788867, -17.573047],
              [-57.780176, -17.671777],
              [-57.66167, -17.947363],
              [-57.586475, -18.122266],
              [-57.552051, -18.183105],
              [-57.495654, -18.214648],
              [-57.506152, -18.237305],
              [-57.553125, -18.246484],
              [-57.574023, -18.279297],
              [-57.63916, -18.475],
              [-57.725, -18.733203],
              [-57.783105, -18.914258],
              [-57.730859, -18.917188],
              [-57.728613, -18.967383],
              [-57.716797, -19.044043],
              [-57.781445, -19.053516],
              [-57.800391, -19.080957],
              [-57.874512, -19.229492],
              [-57.97168, -19.424219],
              [-58.072021, -19.625293],
              [-58.131494, -19.744531],
              [-58.029932, -19.832715],
              [-57.860742, -19.97959],
              [-57.887598, -20.02041],
              [-57.960156, -20.040723],
              [-58.021143, -20.055176],
              [-58.067627, -20.110352],
              [-58.09375, -20.151074],
              [-58.159766, -20.164648]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Colombia",
          "density": "45.62",
          "cartodb_id": 48,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-78.113721, 2.541748],
                [-78.14082, 2.519678],
                [-78.19248, 2.559277],
                [-78.210107, 2.60918],
                [-78.178418, 2.646338],
                [-78.137646, 2.63418],
                [-78.119141, 2.603613],
                [-78.113721, 2.541748]
              ]
            ],
            [
              [
                [-71.262109, 12.335303],
                [-71.155029, 12.16416],
                [-71.137305, 12.046338],
                [-71.28418, 11.918311],
                [-71.319727, 11.861914],
                [-71.355566, 11.849756],
                [-71.400195, 11.823535],
                [-71.536084, 11.774072],
                [-71.719482, 11.726855],
                [-71.958105, 11.666406],
                [-72.012305, 11.601953],
                [-72.248486, 11.196436],
                [-72.446094, 11.114258],
                [-72.518018, 11.053906],
                [-72.572266, 10.977148],
                [-72.690088, 10.83584],
                [-72.73916, 10.727197],
                [-72.869336, 10.49126],
                [-72.940381, 10.195752],
                [-72.967383, 10.029736],
                [-73.006543, 9.78916],
                [-73.064062, 9.668213],
                [-73.14126, 9.554639],
                [-73.224268, 9.443604],
                [-73.295654, 9.322021],
                [-73.356348, 9.226855],
                [-73.366211, 9.194141],
                [-73.336719, 9.16792],
                [-73.193164, 9.194141],
                [-73.136719, 9.222803],
                [-73.058398, 9.25957],
                [-73.009277, 9.239941],
                [-72.960156, 9.135156],
                [-72.904443, 9.12207],
                [-72.8521, 9.135156],
                [-72.796387, 9.108984],
                [-72.725537, 8.848291],
                [-72.66543, 8.627588],
                [-72.525732, 8.489697],
                [-72.416553, 8.381982],
                [-72.390332, 8.287061],
                [-72.36416, 8.152783],
                [-72.357617, 8.087305],
                [-72.391699, 8.047705],
                [-72.446045, 7.966113],
                [-72.45957, 7.809863],
                [-72.468896, 7.757959],
                [-72.478955, 7.613232],
                [-72.471973, 7.524268],
                [-72.442969, 7.454883],
                [-72.394629, 7.415088],
                [-72.296338, 7.394531],
                [-72.207715, 7.370264],
                [-72.156689, 7.249707],
                [-72.084277, 7.096875],
                [-72.006641, 7.032617],
                [-71.892676, 6.990332],
                [-71.811279, 7.005811],
                [-71.620898, 7.03291],
                [-71.457129, 7.026367],
                [-71.217822, 6.985205],
                [-71.128613, 6.986719],
                [-71.013281, 6.994434],
                [-70.810693, 7.077588],
                [-70.737158, 7.090039],
                [-70.655078, 7.082764],
                [-70.535547, 7.040527],
                [-70.470654, 7.007129],
                [-70.3875, 6.972607],
                [-70.266113, 6.947949],
                [-70.188135, 6.952051],
                [-70.129199, 6.953613],
                [-70.09502, 6.937939],
                [-69.904199, 6.700244],
                [-69.738965, 6.494385],
                [-69.594824, 6.321484],
                [-69.439258, 6.134912],
                [-69.427148, 6.123975],
                [-69.35708, 6.147998],
                [-69.31084, 6.137598],
                [-69.268164, 6.099707],
                [-69.194531, 6.115332],
                [-69.089941, 6.184375],
                [-68.937207, 6.198193],
                [-68.736475, 6.156787],
                [-68.471777, 6.156543],
                [-68.143066, 6.19751],
                [-67.938867, 6.241943],
                [-67.85918, 6.289893],
                [-67.727148, 6.284961],
                [-67.568066, 6.241797],
                [-67.481982, 6.180273],
                [-67.471582, 6.119775],
                [-67.439355, 6.025537],
                [-67.473877, 5.92998],
                [-67.575195, 5.833105],
                [-67.631348, 5.709375],
                [-67.642285, 5.558789],
                [-67.694629, 5.44751],
                [-67.788428, 5.375488],
                [-67.824902, 5.270459],
                [-67.804199, 5.13252],
                [-67.814307, 4.930811],
                [-67.855273, 4.665479],
                [-67.855273, 4.506885],
                [-67.814307, 4.455078],
                [-67.79541, 4.380713],
                [-67.798633, 4.283887],
                [-67.783203, 4.198242],
                [-67.732324, 4.086523],
                [-67.661621, 3.864258],
                [-67.602539, 3.768799],
                [-67.551123, 3.733838],
                [-67.498682, 3.691113],
                [-67.347705, 3.46377],
                [-67.311133, 3.415869],
                [-67.322168, 3.373975],
                [-67.336279, 3.342627],
                [-67.353613, 3.322656],
                [-67.514844, 3.187256],
                [-67.834766, 2.892822],
                [-67.86123, 2.855322],
                [-67.859082, 2.793604],
                [-67.766455, 2.833301],
                [-67.667236, 2.800195],
                [-67.618701, 2.793604],
                [-67.59668, 2.769336],
                [-67.568018, 2.689941],
                [-67.534961, 2.676758],
                [-67.486426, 2.643652],
                [-67.391602, 2.559912],
                [-67.312256, 2.47168],
                [-67.252734, 2.429443],
                [-67.21084, 2.390137],
                [-67.197607, 2.332764],
                [-67.215234, 2.275488],
                [-67.165479, 2.142578],
                [-67.131445, 2.10127],
                [-67.113818, 2.050586],
                [-67.131445, 1.999854],
                [-67.089551, 1.940332],
                [-67.043896, 1.823193],
                [-66.988135, 1.680176],
                [-66.981543, 1.600781],
                [-66.95835, 1.564209],
                [-66.931104, 1.458008],
                [-66.884473, 1.358252],
                [-66.895508, 1.289893],
                [-66.876025, 1.223047],
                [-67.065234, 1.178369],
                [-67.082275, 1.1854],
                [-67.093652, 1.21001],
                [-67.088281, 1.400586],
                [-67.090137, 1.615576],
                [-67.119238, 1.703613],
                [-67.205811, 1.844824],
                [-67.320605, 2.03208],
                [-67.351953, 2.08584],
                [-67.400439, 2.116699],
                [-67.457764, 2.121143],
                [-67.499658, 2.10791],
                [-67.556055, 2.072998],
                [-67.609229, 2.035059],
                [-67.711865, 1.922119],
                [-67.815088, 1.790088],
                [-67.875537, 1.760596],
                [-67.93623, 1.748486],
                [-67.989746, 1.752539],
                [-68.032861, 1.788037],
                [-68.077051, 1.860107],
                [-68.130273, 1.955762],
                [-68.193799, 1.987012],
                [-68.218359, 1.957617],
                [-68.239453, 1.901367],
                [-68.255957, 1.845508],
                [-68.213281, 1.774561],
                [-68.176562, 1.719824],
                [-68.239551, 1.72168],
                [-68.443457, 1.721582],
                [-68.678467, 1.721484],
                [-68.913184, 1.721387],
                [-69.124268, 1.721289],
                [-69.319727, 1.72124],
                [-69.394336, 1.725781],
                [-69.470166, 1.75791],
                [-69.54292, 1.773242],
                [-69.58125, 1.770752],
                [-69.650049, 1.739453],
                [-69.7396, 1.734863],
                [-69.799951, 1.705176],
                [-69.848584, 1.70874],
                [-69.849463, 1.543896],
                [-69.850781, 1.308789],
                [-69.852148, 1.059521],
                [-69.798145, 1.078418],
                [-69.751318, 1.076611],
                [-69.716992, 1.059082],
                [-69.620898, 1.073242],
                [-69.567578, 1.065771],
                [-69.517139, 1.059473],
                [-69.470312, 1.058594],
                [-69.441504, 1.038818],
                [-69.402783, 1.042383],
                [-69.361377, 1.064014],
                [-69.311816, 1.050488],
                [-69.258691, 1.015381],
                [-69.224463, 0.963135],
                [-69.193848, 0.898291],
                [-69.163232, 0.864063],
                [-69.165039, 0.801953],
                [-69.165967, 0.75332],
                [-69.176758, 0.712842],
                [-69.163232, 0.68667],
                [-69.15332, 0.658789],
                [-69.156055, 0.642529],
                [-69.174072, 0.635352],
                [-69.212793, 0.629932],
                [-69.254199, 0.625439],
                [-69.283008, 0.627246],
                [-69.305518, 0.652441],
                [-69.327148, 0.655176],
                [-69.358643, 0.651562],
                [-69.391992, 0.666895],
                [-69.420801, 0.698389],
                [-69.472119, 0.729932],
                [-69.527051, 0.716406],
                [-69.564844, 0.700195],
                [-69.603613, 0.680371],
                [-69.638721, 0.659668],
                [-69.673828, 0.665088],
                [-69.718896, 0.649805],
                [-69.756738, 0.626367],
                [-69.807129, 0.607471],
                [-69.862061, 0.598486],
                [-69.925098, 0.589404],
                [-69.985449, 0.58584],
                [-70.053906, 0.578613],
                [-70.05791, 0.447363],
                [-70.065723, 0.189355],
                [-70.070947, 0.018555],
                [-70.070508, -0.138867],
                [-70.044043, -0.196191],
                [-69.922754, -0.31748],
                [-69.82793, -0.381348],
                [-69.747461, -0.452539],
                [-69.66748, -0.482422],
                [-69.633984, -0.509277],
                [-69.611914, -0.55332],
                [-69.600879, -0.599609],
                [-69.592041, -0.639355],
                [-69.600879, -0.68125],
                [-69.620703, -0.720898],
                [-69.611914, -0.762793],
                [-69.583252, -0.795898],
                [-69.574414, -0.837793],
                [-69.55459, -0.877441],
                [-69.543555, -0.917187],
                [-69.519287, -0.945801],
                [-69.488428, -0.965723],
                [-69.44873, -0.99873],
                [-69.444336, -1.02959],
                [-69.44873, -1.064941],
                [-69.449121, -1.091602],
                [-69.411426, -1.152246],
                [-69.400244, -1.194922],
                [-69.417871, -1.245703],
                [-69.434912, -1.42168],
                [-69.478613, -1.621973],
                [-69.506445, -1.774902],
                [-69.551855, -2.024219],
                [-69.604687, -2.314258],
                [-69.669043, -2.667676],
                [-69.732617, -3.016699],
                [-69.794141, -3.35459],
                [-69.849756, -3.659863],
                [-69.911035, -3.996582],
                [-69.948193, -4.200586],
                [-69.965918, -4.235938],
                [-70.017188, -4.162012],
                [-70.094775, -4.092188],
                [-70.167529, -4.050195],
                [-70.198389, -3.995117],
                [-70.240283, -3.882715],
                [-70.298438, -3.844238],
                [-70.339502, -3.814355],
                [-70.379199, -3.81875],
                [-70.421094, -3.849609],
                [-70.48584, -3.869336],
                [-70.529687, -3.866406],
                [-70.706201, -3.788965],
                [-70.735107, -3.781543],
                [-70.62168, -3.60459],
                [-70.418994, -3.288281],
                [-70.290137, -3.087305],
                [-70.14707, -2.864063],
                [-70.074023, -2.750195],
                [-70.064453, -2.730762],
                [-70.064746, -2.70166],
                [-70.09585, -2.658203],
                [-70.164746, -2.639844],
                [-70.244434, -2.606543],
                [-70.294629, -2.552539],
                [-70.36416, -2.529297],
                [-70.418213, -2.490723],
                [-70.516797, -2.453125],
                [-70.575879, -2.418262],
                [-70.647998, -2.405762],
                [-70.705371, -2.341992],
                [-70.914551, -2.218555],
                [-70.968555, -2.206836],
                [-71.027295, -2.225781],
                [-71.113379, -2.24541],
                [-71.196387, -2.313086],
                [-71.300098, -2.334863],
                [-71.396973, -2.334082],
                [-71.447461, -2.29375],
                [-71.496094, -2.279199],
                [-71.559473, -2.224219],
                [-71.671484, -2.182129],
                [-71.752539, -2.152734],
                [-71.802734, -2.166309],
                [-71.867285, -2.227734],
                [-71.932471, -2.288672],
                [-71.984277, -2.326562],
                [-72.053809, -2.324609],
                [-72.136816, -2.380664],
                [-72.218457, -2.400488],
                [-72.300732, -2.409277],
                [-72.395605, -2.428906],
                [-72.500684, -2.39502],
                [-72.586719, -2.365137],
                [-72.625342, -2.35166],
                [-72.660156, -2.361035],
                [-72.71416, -2.392188],
                [-72.81123, -2.405469],
                [-72.887158, -2.408496],
                [-72.941113, -2.394043],
                [-72.989648, -2.339746],
                [-73.068164, -2.312012],
                [-73.154492, -2.278223],
                [-73.172656, -2.208398],
                [-73.160205, -2.156348],
                [-73.126514, -2.081055],
                [-73.145215, -2.00332],
                [-73.181494, -1.880371],
                [-73.196973, -1.830273],
                [-73.223975, -1.787695],
                [-73.266455, -1.772266],
                [-73.349512, -1.783887],
                [-73.440283, -1.737402],
                [-73.496289, -1.693066],
                [-73.525244, -1.638867],
                [-73.494336, -1.536621],
                [-73.521387, -1.449707],
                [-73.575488, -1.401367],
                [-73.610254, -1.316406],
                [-73.664307, -1.248828],
                [-73.735742, -1.21416],
                [-73.807178, -1.217969],
                [-73.863184, -1.19668],
                [-73.926953, -1.125195],
                [-73.986816, -1.098145],
                [-74.054395, -1.028613],
                [-74.180762, -0.997754],
                [-74.246387, -0.970605],
                [-74.283887, -0.927832],
                [-74.334424, -0.850879],
                [-74.328613, -0.808398],
                [-74.353125, -0.766602],
                [-74.374902, -0.691406],
                [-74.417871, -0.580664],
                [-74.465186, -0.517676],
                [-74.513867, -0.470117],
                [-74.555078, -0.429883],
                [-74.616357, -0.37002],
                [-74.69165, -0.335254],
                [-74.755371, -0.298633],
                [-74.780469, -0.244531],
                [-74.801758, -0.200098],
                [-74.8375, -0.20332],
                [-74.888818, -0.199414],
                [-74.945312, -0.188184],
                [-75.00498, -0.155859],
                [-75.054687, -0.116699],
                [-75.138379, -0.050488],
                [-75.184082, -0.041748],
                [-75.224609, -0.041748],
                [-75.284473, -0.106543],
                [-75.463965, -0.038428],
                [-75.617334, 0.062891],
                [-75.77666, 0.089258],
                [-75.879785, 0.150977],
                [-75.974854, 0.247754],
                [-76.026172, 0.313086],
                [-76.06792, 0.345557],
                [-76.270605, 0.439404],
                [-76.311035, 0.448486],
                [-76.388184, 0.40498],
                [-76.413379, 0.378857],
                [-76.417969, 0.303906],
                [-76.427295, 0.26123],
                [-76.494629, 0.235449],
                [-76.603027, 0.240967],
                [-76.678516, 0.268164],
                [-76.729004, 0.272119],
                [-76.739307, 0.25083],
                [-76.767725, 0.24165],
                [-76.829346, 0.247754],
                [-76.920117, 0.268506],
                [-77.002441, 0.29624],
                [-77.114111, 0.355078],
                [-77.165723, 0.347754],
                [-77.292676, 0.3604],
                [-77.396338, 0.393896],
                [-77.422754, 0.424854],
                [-77.467676, 0.636523],
                [-77.481396, 0.651172],
                [-77.526123, 0.660352],
                [-77.601318, 0.689502],
                [-77.648633, 0.723633],
                [-77.673193, 0.782227],
                [-77.702881, 0.837842],
                [-77.829541, 0.825391],
                [-78.037012, 0.89873],
                [-78.180664, 0.968555],
                [-78.312109, 1.046094],
                [-78.511523, 1.198828],
                [-78.587646, 1.23667],
                [-78.681641, 1.283447],
                [-78.737109, 1.358691],
                [-78.828857, 1.434668],
                [-78.859668, 1.455371],
                [-78.888477, 1.524072],
                [-79.025439, 1.623682],
                [-78.957666, 1.752197],
                [-78.792969, 1.84873],
                [-78.576904, 1.773779],
                [-78.550439, 1.923633],
                [-78.628613, 2.05625],
                [-78.617041, 2.306787],
                [-78.591699, 2.356641],
                [-78.534717, 2.423682],
                [-78.460449, 2.470068],
                [-78.416895, 2.483496],
                [-78.342871, 2.460547],
                [-78.296143, 2.510498],
                [-78.12002, 2.488184],
                [-78.06665, 2.509131],
                [-78.030176, 2.543066],
                [-77.987207, 2.568994],
                [-77.932275, 2.629248],
                [-77.900781, 2.698828],
                [-77.874512, 2.725879],
                [-77.813574, 2.716357],
                [-77.807959, 2.746387],
                [-77.77666, 2.787305],
                [-77.67002, 2.878857],
                [-77.671094, 2.919336],
                [-77.700977, 3.007568],
                [-77.693652, 3.039941],
                [-77.632031, 3.051172],
                [-77.559131, 3.075977],
                [-77.520264, 3.160254],
                [-77.472217, 3.233789],
                [-77.417139, 3.341797],
                [-77.356543, 3.348584],
                [-77.324414, 3.474756],
                [-77.242773, 3.585352],
                [-77.076807, 3.913281],
                [-77.126855, 3.906055],
                [-77.166602, 3.862256],
                [-77.212012, 3.867432],
                [-77.263525, 3.893213],
                [-77.248389, 4.040967],
                [-77.278027, 4.058496],
                [-77.358203, 3.944727],
                [-77.427295, 4.060449],
                [-77.433545, 4.130957],
                [-77.404492, 4.200781],
                [-77.40874, 4.247754],
                [-77.520703, 4.212793],
                [-77.515527, 4.256299],
                [-77.44585, 4.301025],
                [-77.414258, 4.347607],
                [-77.353516, 4.398291],
                [-77.32832, 4.475],
                [-77.313672, 4.593848],
                [-77.286328, 4.721729],
                [-77.306543, 4.784668],
                [-77.339453, 4.838525],
                [-77.366748, 5.076563],
                [-77.35918, 5.215186],
                [-77.373291, 5.323975],
                [-77.401758, 5.416162],
                [-77.534424, 5.537109],
                [-77.324609, 5.675635],
                [-77.249268, 5.780176],
                [-77.344678, 5.995361],
                [-77.469434, 6.176758],
                [-77.473047, 6.285645],
                [-77.440088, 6.271729],
                [-77.398242, 6.275],
                [-77.359863, 6.504492],
                [-77.368799, 6.575586],
                [-77.438867, 6.690332],
                [-77.525977, 6.693115],
                [-77.602148, 6.837305],
                [-77.64585, 6.869629],
                [-77.680957, 6.9604],
                [-77.803711, 7.137256],
                [-77.901172, 7.229346],
                [-77.82832, 7.442822],
                [-77.764697, 7.483691],
                [-77.743896, 7.536963],
                [-77.76875, 7.668066],
                [-77.761914, 7.698828],
                [-77.746924, 7.711865],
                [-77.732031, 7.710938],
                [-77.706348, 7.691211],
                [-77.658594, 7.634619],
                [-77.618604, 7.564551],
                [-77.586572, 7.543066],
                [-77.538281, 7.56626],
                [-77.350781, 7.705859],
                [-77.362744, 7.749072],
                [-77.345605, 7.836523],
                [-77.282959, 7.908154],
                [-77.215967, 7.93252],
                [-77.195996, 7.972461],
                [-77.212305, 8.033887],
                [-77.282617, 8.187061],
                [-77.345508, 8.269531],
                [-77.385889, 8.35166],
                [-77.407275, 8.427246],
                [-77.478516, 8.498438],
                [-77.44834, 8.565869],
                [-77.393066, 8.644678],
                [-77.374219, 8.658301],
                [-77.344141, 8.636719],
                [-77.261572, 8.493701],
                [-77.130127, 8.400586],
                [-76.992285, 8.250342],
                [-76.93584, 8.146826],
                [-76.890967, 8.127979],
                [-76.851855, 8.090479],
                [-76.869092, 8.062695],
                [-76.912207, 8.033398],
                [-76.924658, 7.973193],
                [-76.896631, 7.939453],
                [-76.866895, 7.917969],
                [-76.786572, 7.931592],
                [-76.742334, 8.002148],
                [-76.77207, 8.310547],
                [-76.818604, 8.464697],
                [-76.872217, 8.512744],
                [-76.920459, 8.57373],
                [-76.887988, 8.619873],
                [-76.802246, 8.640674],
                [-76.689355, 8.694727],
                [-76.276855, 8.989111],
                [-76.135498, 9.265625],
                [-76.027246, 9.365771],
                [-75.905029, 9.430908],
                [-75.755566, 9.415625],
                [-75.639355, 9.450439],
                [-75.603613, 9.538477],
                [-75.635352, 9.657812],
                [-75.680029, 9.729785],
                [-75.637109, 9.834277],
                [-75.592676, 9.992725],
                [-75.595898, 10.12583],
                [-75.538574, 10.205176],
                [-75.558398, 10.236426],
                [-75.642188, 10.172168],
                [-75.70835, 10.143408],
                [-75.670898, 10.196338],
                [-75.553711, 10.327734],
                [-75.492773, 10.527637],
                [-75.445996, 10.610889],
                [-75.280615, 10.727197],
                [-75.247949, 10.783252],
                [-75.123047, 10.87041],
                [-74.921582, 11.057568],
                [-74.84458, 11.109717],
                [-74.454248, 10.989063],
                [-74.330225, 10.99668],
                [-74.352393, 10.974658],
                [-74.40957, 10.967188],
                [-74.492285, 10.934473],
                [-74.51626, 10.8625],
                [-74.460254, 10.787061],
                [-74.400879, 10.765234],
                [-74.350195, 10.813721],
                [-74.299951, 10.952246],
                [-74.219141, 11.105322],
                [-74.200195, 11.265723],
                [-74.14292, 11.32085],
                [-74.059131, 11.340625],
                [-73.90957, 11.308887],
                [-73.795703, 11.275684],
                [-73.676904, 11.271484],
                [-73.313379, 11.295752],
                [-72.721826, 11.712158],
                [-72.44707, 11.801709],
                [-72.275, 11.889258],
                [-72.165234, 12.060205],
                [-72.135742, 12.188574],
                [-72.055078, 12.238428],
                [-71.970117, 12.238281],
                [-71.93125, 12.269531],
                [-71.919141, 12.309082],
                [-71.714551, 12.419971],
                [-71.597461, 12.434375],
                [-71.493994, 12.432275],
                [-71.262109, 12.335303]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Ecuador",
          "density": "73",
          "cartodb_id": 64,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-80.131592, -2.973145],
                [-80.150684, -3.011719],
                [-80.245703, -3.008301],
                [-80.272949, -2.995898],
                [-80.272168, -2.951758],
                [-80.249805, -2.811914],
                [-80.223682, -2.753125],
                [-80.145703, -2.696289],
                [-80.080762, -2.668848],
                [-79.997266, -2.673828],
                [-79.909033, -2.725586],
                [-80.013232, -2.819531],
                [-80.071191, -2.833789],
                [-80.093408, -2.845898],
                [-80.131592, -2.973145]
              ]
            ],
            [
              [
                [-90.423926, -1.339941],
                [-90.464404, -1.341992],
                [-90.519531, -1.299121],
                [-90.477197, -1.220996],
                [-90.431982, -1.239844],
                [-90.39873, -1.262305],
                [-90.37915, -1.292285],
                [-90.423926, -1.339941]
              ]
            ],
            [
              [
                [-89.418896, -0.911035],
                [-89.536621, -0.952344],
                [-89.577295, -0.933789],
                [-89.602637, -0.913477],
                [-89.608594, -0.888574],
                [-89.543457, -0.826855],
                [-89.479932, -0.793359],
                [-89.423145, -0.722266],
                [-89.318408, -0.680078],
                [-89.287842, -0.689844],
                [-89.267432, -0.70459],
                [-89.259375, -0.728418],
                [-89.294873, -0.785937],
                [-89.35835, -0.826074],
                [-89.418896, -0.911035]
              ]
            ],
            [
              [
                [-90.334863, -0.771582],
                [-90.387109, -0.77334],
                [-90.542139, -0.676465],
                [-90.531689, -0.581445],
                [-90.469727, -0.517383],
                [-90.269385, -0.484668],
                [-90.185303, -0.544824],
                [-90.192725, -0.658789],
                [-90.261084, -0.741992],
                [-90.31543, -0.757227],
                [-90.334863, -0.771582]
              ]
            ],
            [
              [
                [-91.425977, -0.46084],
                [-91.526367, -0.478223],
                [-91.610742, -0.443945],
                [-91.646582, -0.39082],
                [-91.65415, -0.310938],
                [-91.64668, -0.284473],
                [-91.460156, -0.255664],
                [-91.399365, -0.322461],
                [-91.399951, -0.420898],
                [-91.425977, -0.46084]
              ]
            ],
            [
              [
                [-90.573926, -0.333984],
                [-90.620459, -0.364258],
                [-90.809033, -0.329395],
                [-90.867773, -0.271387],
                [-90.820361, -0.192187],
                [-90.780371, -0.160449],
                [-90.667529, -0.189844],
                [-90.55332, -0.278418],
                [-90.573926, -0.333984]
              ]
            ],
            [
              [
                [-91.272168, 0.025146],
                [-91.210059, -0.039307],
                [-91.176221, -0.223047],
                [-90.975537, -0.416895],
                [-90.950635, -0.525195],
                [-90.968457, -0.575586],
                [-90.958936, -0.595313],
                [-90.862549, -0.671777],
                [-90.799658, -0.752051],
                [-90.905518, -0.940527],
                [-91.131055, -1.019629],
                [-91.371533, -1.016992],
                [-91.419043, -0.99668],
                [-91.483545, -0.924609],
                [-91.49541, -0.860937],
                [-91.458301, -0.799512],
                [-91.334082, -0.70625],
                [-91.144678, -0.622852],
                [-91.120947, -0.559082],
                [-91.197021, -0.496973],
                [-91.249512, -0.373633],
                [-91.369189, -0.287207],
                [-91.428857, -0.023389],
                [-91.468701, -0.010303],
                [-91.55, -0.04668],
                [-91.590088, -0.014795],
                [-91.596826, 0.0021],
                [-91.50918, 0.062256],
                [-91.491016, 0.105176],
                [-91.361377, 0.12583],
                [-91.305762, 0.091406],
                [-91.272168, 0.025146]
              ]
            ],
            [
              [
                [-78.909229, 1.252783],
                [-78.965625, 1.245361],
                [-78.991699, 1.293213],
                [-78.923242, 1.348926],
                [-78.899805, 1.359766],
                [-78.909229, 1.252783]
              ]
            ],
            [
              [
                [-78.312109, 1.046094],
                [-78.180664, 0.968555],
                [-78.037012, 0.89873],
                [-77.829541, 0.825391],
                [-77.702881, 0.837842],
                [-77.673193, 0.782227],
                [-77.648633, 0.723633],
                [-77.601318, 0.689502],
                [-77.526123, 0.660352],
                [-77.481396, 0.651172],
                [-77.467676, 0.636523],
                [-77.422754, 0.424854],
                [-77.396338, 0.393896],
                [-77.292676, 0.3604],
                [-77.165723, 0.347754],
                [-77.114111, 0.355078],
                [-77.002441, 0.29624],
                [-76.920117, 0.268506],
                [-76.829346, 0.247754],
                [-76.767725, 0.24165],
                [-76.739307, 0.25083],
                [-76.729004, 0.272119],
                [-76.678516, 0.268164],
                [-76.603027, 0.240967],
                [-76.494629, 0.235449],
                [-76.427295, 0.26123],
                [-76.417969, 0.303906],
                [-76.413379, 0.378857],
                [-76.388184, 0.40498],
                [-76.311035, 0.448486],
                [-76.270605, 0.439404],
                [-76.06792, 0.345557],
                [-76.026172, 0.313086],
                [-75.974854, 0.247754],
                [-75.879785, 0.150977],
                [-75.77666, 0.089258],
                [-75.617334, 0.062891],
                [-75.463965, -0.038428],
                [-75.284473, -0.106543],
                [-75.340479, -0.142188],
                [-75.398389, -0.145996],
                [-75.475977, -0.157129],
                [-75.58374, -0.122852],
                [-75.62627, -0.122852],
                [-75.632031, -0.157617],
                [-75.560596, -0.200098],
                [-75.491064, -0.24834],
                [-75.465967, -0.321777],
                [-75.424707, -0.408887],
                [-75.325244, -0.506543],
                [-75.263232, -0.555371],
                [-75.259375, -0.590137],
                [-75.278711, -0.653906],
                [-75.283594, -0.707129],
                [-75.249609, -0.951855],
                [-75.272412, -0.966797],
                [-75.30918, -0.968066],
                [-75.348193, -0.966797],
                [-75.380127, -0.940234],
                [-75.408057, -0.924316],
                [-75.42041, -0.962207],
                [-75.44917, -1.071191],
                [-75.513867, -1.316309],
                [-75.570557, -1.53125],
                [-75.64165, -1.607324],
                [-75.744531, -1.728125],
                [-75.885449, -1.893457],
                [-76.089795, -2.133105],
                [-76.240918, -2.243945],
                [-76.360156, -2.331348],
                [-76.499365, -2.432324],
                [-76.679102, -2.562598],
                [-76.880762, -2.635938],
                [-77.161475, -2.737695],
                [-77.360059, -2.809668],
                [-77.506494, -2.859961],
                [-77.658984, -2.912402],
                [-77.860596, -2.981641],
                [-77.938477, -3.046973],
                [-78.06792, -3.206836],
                [-78.128223, -3.283887],
                [-78.183301, -3.350195],
                [-78.194629, -3.380469],
                [-78.187451, -3.399805],
                [-78.160986, -3.432129],
                [-78.158496, -3.465137],
                [-78.194873, -3.48584],
                [-78.226318, -3.48916],
                [-78.240381, -3.472559],
                [-78.250732, -3.436133],
                [-78.28418, -3.399023],
                [-78.323047, -3.388281],
                [-78.345361, -3.397363],
                [-78.347266, -3.43125],
                [-78.398047, -3.594824],
                [-78.399951, -3.674316],
                [-78.421436, -3.705762],
                [-78.419775, -3.776855],
                [-78.471045, -3.843066],
                [-78.493457, -3.902051],
                [-78.509082, -3.952148],
                [-78.550439, -3.986914],
                [-78.565137, -4.041602],
                [-78.603369, -4.157324],
                [-78.647998, -4.248145],
                [-78.679395, -4.325879],
                [-78.685156, -4.383984],
                [-78.66123, -4.425098],
                [-78.65293, -4.458203],
                [-78.674463, -4.517676],
                [-78.686035, -4.562402],
                [-78.743066, -4.592676],
                [-78.861523, -4.665039],
                [-78.907617, -4.714453],
                [-78.925781, -4.770703],
                [-78.914209, -4.818652],
                [-78.919189, -4.858398],
                [-78.975391, -4.873242],
                [-78.995264, -4.908008],
                [-79.033301, -4.969141],
                [-79.07627, -4.990625],
                [-79.18667, -4.958203],
                [-79.268115, -4.957617],
                [-79.330957, -4.927832],
                [-79.399414, -4.840039],
                [-79.455762, -4.766211],
                [-79.501904, -4.670605],
                [-79.516162, -4.53916],
                [-79.577686, -4.500586],
                [-79.638525, -4.454883],
                [-79.710986, -4.467578],
                [-79.797266, -4.476367],
                [-79.845117, -4.445898],
                [-79.962891, -4.390332],
                [-80.063525, -4.327539],
                [-80.139551, -4.296094],
                [-80.197461, -4.311035],
                [-80.232178, -4.349023],
                [-80.293359, -4.416797],
                [-80.383496, -4.463672],
                [-80.42417, -4.461426],
                [-80.478564, -4.430078],
                [-80.488477, -4.393652],
                [-80.443848, -4.33584],
                [-80.352881, -4.208496],
                [-80.45376, -4.205176],
                [-80.488477, -4.165527],
                [-80.493457, -4.119141],
                [-80.51001, -4.069531],
                [-80.490137, -4.010059],
                [-80.437207, -3.978613],
                [-80.357861, -4.003418],
                [-80.303271, -4.005078],
                [-80.266895, -3.948828],
                [-80.230518, -3.924023],
                [-80.194141, -3.905859],
                [-80.179248, -3.877734],
                [-80.217578, -3.787695],
                [-80.228857, -3.738867],
                [-80.217285, -3.710742],
                [-80.218945, -3.654492],
                [-80.220605, -3.613184],
                [-80.24375, -3.576758],
                [-80.24541, -3.522168],
                [-80.265234, -3.49248],
                [-80.271875, -3.461035],
                [-80.273535, -3.424609],
                [-80.29834, -3.406445],
                [-80.324658, -3.387891],
                [-80.303125, -3.374805],
                [-80.159863, -3.324316],
                [-80.100342, -3.274023],
                [-80.02666, -3.228125],
                [-79.96333, -3.157715],
                [-79.921582, -3.090137],
                [-79.822705, -2.776953],
                [-79.729883, -2.579102],
                [-79.745508, -2.484668],
                [-79.822412, -2.356543],
                [-79.839746, -2.167871],
                [-79.832617, -2.110547],
                [-79.842139, -2.067383],
                [-79.893408, -2.145703],
                [-79.880322, -2.423633],
                [-79.925586, -2.548535],
                [-79.989014, -2.578711],
                [-80.030176, -2.556738],
                [-80.006641, -2.353809],
                [-80.053076, -2.390723],
                [-80.127148, -2.528418],
                [-80.248633, -2.630566],
                [-80.255273, -2.664648],
                [-80.284717, -2.706738],
                [-80.378564, -2.667969],
                [-80.450098, -2.625977],
                [-80.684863, -2.396875],
                [-80.839062, -2.349023],
                [-80.932178, -2.269141],
                [-80.951611, -2.235449],
                [-80.962793, -2.189258],
                [-80.867627, -2.141211],
                [-80.770361, -2.07666],
                [-80.760596, -1.93457],
                [-80.763135, -1.822949],
                [-80.83501, -1.632422],
                [-80.801416, -1.383398],
                [-80.82002, -1.28584],
                [-80.902393, -1.078906],
                [-80.841406, -0.974707],
                [-80.623682, -0.89873],
                [-80.553906, -0.847949],
                [-80.505078, -0.683789],
                [-80.455469, -0.585449],
                [-80.358398, -0.625098],
                [-80.282373, -0.620508],
                [-80.384766, -0.583984],
                [-80.468311, -0.436035],
                [-80.482275, -0.368262],
                [-80.321289, -0.16582],
                [-80.237012, -0.113086],
                [-80.133398, -0.006055],
                [-80.046143, 0.155371],
                [-80.025, 0.410156],
                [-80.061035, 0.592285],
                [-80.088281, 0.784766],
                [-80.035937, 0.83457],
                [-79.903516, 0.860205],
                [-79.79585, 0.922266],
                [-79.741211, 0.979785],
                [-79.613184, 0.971143],
                [-79.465381, 1.060059],
                [-79.229053, 1.10459],
                [-78.899658, 1.20625],
                [-78.827051, 1.295947],
                [-78.859668, 1.455371],
                [-78.828857, 1.434668],
                [-78.737109, 1.358691],
                [-78.681641, 1.283447],
                [-78.587646, 1.23667],
                [-78.511523, 1.198828],
                [-78.312109, 1.046094]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Guyana",
          "density": "4",
          "cartodb_id": 90,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-57.194775, 5.548437],
              [-57.2479, 5.485254],
              [-57.25752, 5.445166],
              [-57.291895, 5.373975],
              [-57.318555, 5.335352],
              [-57.279639, 5.246777],
              [-57.235303, 5.242871],
              [-57.218457, 5.231543],
              [-57.207324, 5.214209],
              [-57.209814, 5.19541],
              [-57.226855, 5.178516],
              [-57.269287, 5.157031],
              [-57.30957, 5.105859],
              [-57.305762, 5.049561],
              [-57.331006, 5.020166],
              [-57.412158, 5.00459],
              [-57.570898, 5.004492],
              [-57.648828, 5.000684],
              [-57.711084, 4.991064],
              [-57.752002, 4.954492],
              [-57.804102, 4.929053],
              [-57.844922, 4.923047],
              [-57.881104, 4.880615],
              [-57.917041, 4.82041],
              [-57.904883, 4.779297],
              [-57.867871, 4.724316],
              [-57.845996, 4.668164],
              [-57.874707, 4.5771],
              [-57.90625, 4.506787],
              [-57.924707, 4.453125],
              [-57.949756, 4.349951],
              [-58.010742, 4.236475],
              [-58.054492, 4.171924],
              [-58.054297, 4.10166],
              [-58.032227, 4.001953],
              [-57.907715, 3.856689],
              [-57.866553, 3.787256],
              [-57.832666, 3.675977],
              [-57.720361, 3.588281],
              [-57.649463, 3.517383],
              [-57.656104, 3.42373],
              [-57.646729, 3.394531],
              [-57.602734, 3.370947],
              [-57.549609, 3.352832],
              [-57.490576, 3.354297],
              [-57.437891, 3.362256],
              [-57.425586, 3.375439],
              [-57.303662, 3.3771],
              [-57.289941, 3.353613],
              [-57.28291, 3.218848],
              [-57.27793, 3.164307],
              [-57.248975, 3.142285],
              [-57.231641, 3.108887],
              [-57.230566, 3.078564],
              [-57.225, 3.003076],
              [-57.206934, 2.963379],
              [-57.209814, 2.882812],
              [-57.197363, 2.853271],
              [-57.163623, 2.833252],
              [-57.121143, 2.775537],
              [-57.105127, 2.768262],
              [-57.096875, 2.747852],
              [-57.060449, 2.665674],
              [-57.041943, 2.641113],
              [-57.028955, 2.6375],
              [-57.023486, 2.608984],
              [-56.997119, 2.532178],
              [-56.979297, 2.513232],
              [-56.945215, 2.456836],
              [-56.931494, 2.395361],
              [-56.886426, 2.325977],
              [-56.840527, 2.277148],
              [-56.819824, 2.22666],
              [-56.761133, 2.114893],
              [-56.704346, 2.036475],
              [-56.627197, 2.016016],
              [-56.562695, 2.005078],
              [-56.522363, 1.974805],
              [-56.482812, 1.942139],
              [-56.525488, 1.927246],
              [-56.563574, 1.907227],
              [-56.616455, 1.922656],
              [-56.689844, 1.914307],
              [-56.76626, 1.892187],
              [-56.836719, 1.88125],
              [-56.969531, 1.916406],
              [-57.010059, 1.92124],
              [-57.037598, 1.936475],
              [-57.092676, 2.005811],
              [-57.118896, 2.013965],
              [-57.1896, 1.981592],
              [-57.275586, 1.959229],
              [-57.31748, 1.963477],
              [-57.366797, 1.940137],
              [-57.412695, 1.908936],
              [-57.500439, 1.773828],
              [-57.545752, 1.726074],
              [-57.594434, 1.704102],
              [-57.691748, 1.704785],
              [-57.795654, 1.7],
              [-57.873437, 1.667285],
              [-57.946338, 1.650586],
              [-57.982812, 1.648438],
              [-57.995117, 1.574316],
              [-58.011768, 1.539941],
              [-58.034668, 1.520264],
              [-58.091309, 1.514355],
              [-58.142236, 1.516992],
              [-58.173096, 1.547852],
              [-58.23042, 1.563281],
              [-58.281152, 1.574316],
              [-58.314209, 1.591943],
              [-58.340674, 1.587549],
              [-58.362695, 1.556689],
              [-58.380371, 1.530225],
              [-58.395801, 1.481738],
              [-58.472949, 1.46626],
              [-58.506055, 1.438672],
              [-58.486865, 1.347754],
              [-58.495703, 1.312256],
              [-58.511865, 1.284668],
              [-58.605078, 1.27915],
              [-58.684619, 1.281055],
              [-58.730322, 1.24751],
              [-58.787207, 1.208496],
              [-58.821777, 1.201221],
              [-58.8625, 1.203613],
              [-58.916602, 1.248877],
              [-58.968506, 1.30459],
              [-59.100391, 1.343652],
              [-59.231201, 1.376025],
              [-59.316992, 1.4646],
              [-59.337256, 1.508203],
              [-59.377686, 1.527344],
              [-59.479443, 1.632422],
              [-59.535693, 1.7],
              [-59.596631, 1.718018],
              [-59.666602, 1.746289],
              [-59.66377, 1.795215],
              [-59.668506, 1.842334],
              [-59.698535, 1.861475],
              [-59.740723, 1.87417],
              [-59.756201, 1.900635],
              [-59.751758, 1.962402],
              [-59.743506, 2.121631],
              [-59.755225, 2.274121],
              [-59.849121, 2.327051],
              [-59.889648, 2.362939],
              [-59.960791, 2.588379],
              [-59.994336, 2.68999],
              [-59.995898, 2.76543],
              [-59.972314, 2.990479],
              [-59.945654, 3.087842],
              [-59.873047, 3.283105],
              [-59.831152, 3.349219],
              [-59.828809, 3.398584],
              [-59.833057, 3.462158],
              [-59.854395, 3.5875],
              [-59.731641, 3.666553],
              [-59.679004, 3.699805],
              [-59.670215, 3.752734],
              [-59.604443, 3.819678],
              [-59.575391, 3.883447],
              [-59.551123, 3.933545],
              [-59.557764, 3.96001],
              [-59.586426, 3.975391],
              [-59.620215, 4.023145],
              [-59.691211, 4.1604],
              [-59.716895, 4.188184],
              [-59.738574, 4.226758],
              [-59.72749, 4.287646],
              [-59.699707, 4.353516],
              [-59.703271, 4.381104],
              [-59.745801, 4.41665],
              [-59.83335, 4.475928],
              [-59.906104, 4.480322],
              [-59.962354, 4.501709],
              [-60.04502, 4.50459],
              [-60.111133, 4.511182],
              [-60.148633, 4.533252],
              [-60.140918, 4.569629],
              [-60.124561, 4.597656],
              [-60.068945, 4.66665],
              [-60.031787, 4.740527],
              [-60.026758, 4.812695],
              [-60.015479, 4.90752],
              [-59.999365, 4.989844],
              [-59.990674, 5.082861],
              [-60.078076, 5.143994],
              [-60.105957, 5.194238],
              [-60.142041, 5.238818],
              [-60.181738, 5.238818],
              [-60.24165, 5.257959],
              [-60.335205, 5.199316],
              [-60.408789, 5.210156],
              [-60.459521, 5.188086],
              [-60.576416, 5.19248],
              [-60.651367, 5.221143],
              [-60.742139, 5.202051],
              [-60.954004, 5.437402],
              [-61.167187, 5.674219],
              [-61.376807, 5.906982],
              [-61.39082, 5.93877],
              [-61.303125, 6.049512],
              [-61.224951, 6.129199],
              [-61.159473, 6.174414],
              [-61.128711, 6.214307],
              [-61.152295, 6.385107],
              [-61.151025, 6.446533],
              [-61.181592, 6.513379],
              [-61.203613, 6.588379],
              [-61.177246, 6.650928],
              [-61.145605, 6.694531],
              [-61.104785, 6.711377],
              [-61.00708, 6.726611],
              [-60.937988, 6.732764],
              [-60.913574, 6.757812],
              [-60.87334, 6.786914],
              [-60.82085, 6.788477],
              [-60.71792, 6.768311],
              [-60.671045, 6.805957],
              [-60.586084, 6.85708],
              [-60.39502, 6.945361],
              [-60.3521, 7.002881],
              [-60.32207, 7.092041],
              [-60.325488, 7.133984],
              [-60.345068, 7.15],
              [-60.392383, 7.164551],
              [-60.464941, 7.166553],
              [-60.523193, 7.143701],
              [-60.583203, 7.156201],
              [-60.633301, 7.211084],
              [-60.636182, 7.256592],
              [-60.606543, 7.32085],
              [-60.62373, 7.36333],
              [-60.719238, 7.498682],
              [-60.718652, 7.535937],
              [-60.649463, 7.596631],
              [-60.610107, 7.64834],
              [-60.556348, 7.772021],
              [-60.513623, 7.813184],
              [-60.380615, 7.827637],
              [-60.346777, 7.854004],
              [-60.278906, 7.919434],
              [-60.178174, 7.994043],
              [-60.032422, 8.053564],
              [-59.990723, 8.162012],
              [-59.964844, 8.191602],
              [-59.849072, 8.248682],
              [-59.828906, 8.27915],
              [-59.831641, 8.305957],
              [-60.017529, 8.549316],
              [-59.980615, 8.532617],
              [-59.836523, 8.373828],
              [-59.756738, 8.339502],
              [-59.739941, 8.338721],
              [-59.739307, 8.37998],
              [-59.666113, 8.362598],
              [-59.476904, 8.254004],
              [-59.200244, 8.074609],
              [-58.811572, 7.735596],
              [-58.701074, 7.606641],
              [-58.626611, 7.545898],
              [-58.511084, 7.398047],
              [-58.477295, 7.325781],
              [-58.480566, 7.038135],
              [-58.58291, 6.843652],
              [-58.60791, 6.697314],
              [-58.613477, 6.502539],
              [-58.672949, 6.390771],
              [-58.593994, 6.451514],
              [-58.569482, 6.627246],
              [-58.502295, 6.733984],
              [-58.41499, 6.851172],
              [-58.298437, 6.879297],
              [-58.172852, 6.829395],
              [-58.071777, 6.820605],
              [-57.982568, 6.785889],
              [-57.792871, 6.598535],
              [-57.607568, 6.450391],
              [-57.540137, 6.331543],
              [-57.343652, 6.272119],
              [-57.227539, 6.178418],
              [-57.190234, 6.097314],
              [-57.167236, 5.88501],
              [-57.205273, 5.5646],
              [-57.194775, 5.548437]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Peru",
          "density": "27",
          "cartodb_id": 171,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-69.965918, -4.235938],
              [-69.972021, -4.301172],
              [-70.003955, -4.327246],
              [-70.05332, -4.333105],
              [-70.128809, -4.286621],
              [-70.183984, -4.298145],
              [-70.23916, -4.301172],
              [-70.316895, -4.246973],
              [-70.343652, -4.193652],
              [-70.404639, -4.150098],
              [-70.530664, -4.167578],
              [-70.63457, -4.168652],
              [-70.721582, -4.158887],
              [-70.799512, -4.17334],
              [-70.866016, -4.22959],
              [-70.915625, -4.295313],
              [-70.973682, -4.350488],
              [-71.144238, -4.387207],
              [-71.23501, -4.388184],
              [-71.316797, -4.424316],
              [-71.438281, -4.437598],
              [-71.521338, -4.469727],
              [-71.668359, -4.487305],
              [-71.844727, -4.504395],
              [-71.943164, -4.55332],
              [-71.982422, -4.574609],
              [-72.08252, -4.642285],
              [-72.256787, -4.748926],
              [-72.352832, -4.786035],
              [-72.468994, -4.90127],
              [-72.60835, -5.00957],
              [-72.69873, -5.067188],
              [-72.831934, -5.09375],
              [-72.887061, -5.122754],
              [-72.907471, -5.157715],
              [-72.895801, -5.198242],
              [-72.918262, -5.302539],
              [-72.958936, -5.495215],
              [-72.970215, -5.589648],
              [-72.979883, -5.634863],
              [-73.068066, -5.789551],
              [-73.162891, -5.933398],
              [-73.209375, -6.028711],
              [-73.235547, -6.098438],
              [-73.206494, -6.156445],
              [-73.167725, -6.260645],
              [-73.135352, -6.344336],
              [-73.126318, -6.400879],
              [-73.137354, -6.46582],
              [-73.177441, -6.525195],
              [-73.240332, -6.564063],
              [-73.325488, -6.574707],
              [-73.499902, -6.679492],
              [-73.694531, -6.833789],
              [-73.758105, -6.905762],
              [-73.77627, -6.973535],
              [-73.804639, -7.079883],
              [-73.793018, -7.135059],
              [-73.758203, -7.172754],
              [-73.72334, -7.262793],
              [-73.72041, -7.309277],
              [-73.749463, -7.335352],
              [-73.804639, -7.341211],
              [-73.854004, -7.349902],
              [-73.891748, -7.373145],
              [-73.929443, -7.367285],
              [-73.964307, -7.378906],
              [-73.964307, -7.416699],
              [-73.952686, -7.460254],
              [-73.958496, -7.506641],
              [-73.981738, -7.535742],
              [-74.002051, -7.556055],
              [-73.981738, -7.585059],
              [-73.946875, -7.61123],
              [-73.894629, -7.654785],
              [-73.82207, -7.738965],
              [-73.766895, -7.753516],
              [-73.72041, -7.78252],
              [-73.7146, -7.829004],
              [-73.732031, -7.875391],
              [-73.772705, -7.895703],
              [-73.775586, -7.936426],
              [-73.72041, -7.985742],
              [-73.682666, -8.020605],
              [-73.644922, -8.072852],
              [-73.610107, -8.14541],
              [-73.610107, -8.191895],
              [-73.572363, -8.249902],
              [-73.549121, -8.299316],
              [-73.549121, -8.345801],
              [-73.488135, -8.392188],
              [-73.435889, -8.427051],
              [-73.398145, -8.458984],
              [-73.3604, -8.479297],
              [-73.351709, -8.51416],
              [-73.356738, -8.566992],
              [-73.302441, -8.654004],
              [-73.203125, -8.719336],
              [-73.122559, -8.814063],
              [-73.070508, -8.882812],
              [-72.974023, -8.993164],
              [-72.970361, -9.120117],
              [-73.089844, -9.265723],
              [-73.209424, -9.411426],
              [-73.01377, -9.407422],
              [-72.814258, -9.410352],
              [-72.605469, -9.452051],
              [-72.464746, -9.492188],
              [-72.379053, -9.510156],
              [-72.318066, -9.556641],
              [-72.289014, -9.629199],
              [-72.26582, -9.688477],
              [-72.259961, -9.774316],
              [-72.172852, -9.844043],
              [-72.179102, -9.910156],
              [-72.181592, -10.003711],
              [-72.142969, -10.005176],
              [-71.887451, -10.005566],
              [-71.608008, -10.006055],
              [-71.339404, -9.988574],
              [-71.237939, -9.966016],
              [-71.115283, -9.852441],
              [-71.041748, -9.81875],
              [-70.970752, -9.765723],
              [-70.884521, -9.669043],
              [-70.81626, -9.625293],
              [-70.758496, -9.57168],
              [-70.672461, -9.517969],
              [-70.636914, -9.478223],
              [-70.60791, -9.463672],
              [-70.541113, -9.4375],
              [-70.570166, -9.489844],
              [-70.592236, -9.543457],
              [-70.59917, -9.620508],
              [-70.567236, -9.70459],
              [-70.593799, -9.76748],
              [-70.636914, -9.82373],
              [-70.637598, -9.971777],
              [-70.638525, -10.181543],
              [-70.639355, -10.361328],
              [-70.640332, -10.586035],
              [-70.641553, -10.84082],
              [-70.642334, -11.010254],
              [-70.596533, -10.976855],
              [-70.533252, -10.946875],
              [-70.450879, -11.024805],
              [-70.392285, -11.058594],
              [-70.341992, -11.066699],
              [-70.290381, -11.064258],
              [-70.220068, -11.047656],
              [-70.066309, -10.982422],
              [-69.960352, -10.929883],
              [-69.839795, -10.933398],
              [-69.674023, -10.954102],
              [-69.578613, -10.951758],
              [-69.453613, -11.16875],
              [-69.362012, -11.327539],
              [-69.257715, -11.508594],
              [-69.17373, -11.654297],
              [-69.046191, -11.875684],
              [-68.936035, -12.066797],
              [-68.818701, -12.27041],
              [-68.685254, -12.501953],
              [-68.728125, -12.560742],
              [-68.762891, -12.607715],
              [-68.759082, -12.687207],
              [-68.811816, -12.72959],
              [-68.867676, -12.755176],
              [-68.93374, -12.82207],
              [-68.978613, -12.880078],
              [-68.980518, -12.962598],
              [-68.972266, -13.382324],
              [-68.983447, -13.496387],
              [-69.017529, -13.594434],
              [-69.052832, -13.643945],
              [-69.074121, -13.682813],
              [-69.023047, -13.780273],
              [-68.974268, -13.975977],
              [-68.937451, -14.014648],
              [-68.891699, -14.094336],
              [-68.870898, -14.169727],
              [-68.880322, -14.198828],
              [-68.971777, -14.234375],
              [-69.004492, -14.265039],
              [-69.013135, -14.377246],
              [-69.052783, -14.417578],
              [-69.119727, -14.470313],
              [-69.162695, -14.530957],
              [-69.199268, -14.572559],
              [-69.234912, -14.59707],
              [-69.252344, -14.671094],
              [-69.276025, -14.745898],
              [-69.359473, -14.795313],
              [-69.37373, -14.8875],
              [-69.374707, -14.962988],
              [-69.330713, -15.038965],
              [-69.187109, -15.19873],
              [-69.172461, -15.236621],
              [-69.254297, -15.33291],
              [-69.301904, -15.399414],
              [-69.418506, -15.603418],
              [-69.420898, -15.640625],
              [-69.391895, -15.736914],
              [-69.217578, -16.149121],
              [-69.187988, -16.182813],
              [-69.13418, -16.221973],
              [-69.04624, -16.217676],
              [-68.913477, -16.261914],
              [-68.848828, -16.312793],
              [-68.842773, -16.337891],
              [-68.857812, -16.354785],
              [-68.928027, -16.389063],
              [-69.00625, -16.433691],
              [-69.03291, -16.475977],
              [-69.038379, -16.542676],
              [-69.020703, -16.642188],
              [-69.054541, -16.674316],
              [-69.13252, -16.713086],
              [-69.199805, -16.768457],
              [-69.267236, -16.860938],
              [-69.381543, -17.001367],
              [-69.421094, -17.040039],
              [-69.43833, -17.088379],
              [-69.50332, -17.104785],
              [-69.624854, -17.200195],
              [-69.645703, -17.248535],
              [-69.625879, -17.294434],
              [-69.563818, -17.33291],
              [-69.521924, -17.388965],
              [-69.510986, -17.460352],
              [-69.511084, -17.504883],
              [-69.510937, -17.506055],
              [-69.586426, -17.573242],
              [-69.684766, -17.649805],
              [-69.806104, -17.664941],
              [-69.8521, -17.703809],
              [-69.841504, -17.785156],
              [-69.802441, -17.9],
              [-69.802588, -17.990234],
              [-69.839697, -18.093457],
              [-69.926367, -18.206055],
              [-70.059082, -18.283496],
              [-70.183789, -18.325195],
              [-70.282275, -18.325391],
              [-70.37749, -18.333594],
              [-70.418262, -18.345605],
              [-70.491602, -18.277734],
              [-70.81748, -18.052539],
              [-70.941699, -17.932031],
              [-71.056592, -17.875684],
              [-71.336963, -17.68252],
              [-71.364941, -17.620508],
              [-71.399414, -17.421973],
              [-71.435889, -17.366016],
              [-71.532227, -17.294336],
              [-71.774463, -17.198828],
              [-71.868359, -17.151074],
              [-71.966895, -17.064063],
              [-72.111279, -17.002539],
              [-72.268604, -16.876172],
              [-72.3625, -16.775],
              [-72.467676, -16.708105],
              [-72.793945, -16.614551],
              [-72.957715, -16.520898],
              [-73.26377, -16.388574],
              [-73.400049, -16.304297],
              [-73.727686, -16.20166],
              [-73.824951, -16.152832],
              [-74.14707, -15.9125],
              [-74.3729, -15.833984],
              [-74.554883, -15.699023],
              [-75.104248, -15.411914],
              [-75.190527, -15.320117],
              [-75.274561, -15.178125],
              [-75.396582, -15.093555],
              [-75.533643, -14.899219],
              [-75.737695, -14.784961],
              [-75.933887, -14.633594],
              [-76.006299, -14.495801],
              [-76.136475, -14.320312],
              [-76.175146, -14.22666],
              [-76.289014, -14.133105],
              [-76.297021, -13.948438],
              [-76.376465, -13.863086],
              [-76.319482, -13.821484],
              [-76.259229, -13.802832],
              [-76.183936, -13.515234],
              [-76.223633, -13.371191],
              [-76.427344, -13.109961],
              [-76.502148, -12.984375],
              [-76.555225, -12.823438],
              [-76.637109, -12.728027],
              [-76.758008, -12.527148],
              [-76.832129, -12.34873],
              [-76.994092, -12.219238],
              [-77.038135, -12.172754],
              [-77.062695, -12.106836],
              [-77.152734, -12.060352],
              [-77.157617, -11.923438],
              [-77.220312, -11.663379],
              [-77.309912, -11.532422],
              [-77.633203, -11.287793],
              [-77.638574, -11.193555],
              [-77.664307, -11.02207],
              [-77.736084, -10.836719],
              [-78.095459, -10.260645],
              [-78.185596, -10.089063],
              [-78.275586, -9.810352],
              [-78.356494, -9.652051],
              [-78.445654, -9.370605],
              [-78.580127, -9.156641],
              [-78.6646, -8.971094],
              [-78.75459, -8.74043],
              [-78.762256, -8.616992],
              [-78.925391, -8.40459],
              [-79.012256, -8.210156],
              [-79.164404, -8.047168],
              [-79.312842, -7.923242],
              [-79.377246, -7.835547],
              [-79.588867, -7.418945],
              [-79.617725, -7.295605],
              [-79.761963, -7.066504],
              [-79.904687, -6.90166],
              [-79.994971, -6.768945],
              [-80.110254, -6.649609],
              [-80.811621, -6.282227],
              [-81.058447, -6.129395],
              [-81.142041, -6.056738],
              [-81.180518, -5.942383],
              [-81.164307, -5.875293],
              [-81.091846, -5.812402],
              [-80.99165, -5.860938],
              [-80.930664, -5.84082],
              [-80.882715, -5.758984],
              [-80.881934, -5.635059],
              [-80.943115, -5.475391],
              [-81.167676, -5.16709],
              [-81.150732, -5.101855],
              [-81.108496, -5.027832],
              [-81.195068, -4.879492],
              [-81.289404, -4.760742],
              [-81.336621, -4.669531],
              [-81.283203, -4.322266],
              [-81.232031, -4.234277],
              [-80.891943, -3.881641],
              [-80.798584, -3.731055],
              [-80.652734, -3.638184],
              [-80.503662, -3.496094],
              [-80.324658, -3.387891],
              [-80.29834, -3.406445],
              [-80.273535, -3.424609],
              [-80.271875, -3.461035],
              [-80.265234, -3.49248],
              [-80.24541, -3.522168],
              [-80.24375, -3.576758],
              [-80.220605, -3.613184],
              [-80.218945, -3.654492],
              [-80.217285, -3.710742],
              [-80.228857, -3.738867],
              [-80.217578, -3.787695],
              [-80.179248, -3.877734],
              [-80.194141, -3.905859],
              [-80.230518, -3.924023],
              [-80.266895, -3.948828],
              [-80.303271, -4.005078],
              [-80.357861, -4.003418],
              [-80.437207, -3.978613],
              [-80.490137, -4.010059],
              [-80.51001, -4.069531],
              [-80.493457, -4.119141],
              [-80.488477, -4.165527],
              [-80.45376, -4.205176],
              [-80.352881, -4.208496],
              [-80.443848, -4.33584],
              [-80.488477, -4.393652],
              [-80.478564, -4.430078],
              [-80.42417, -4.461426],
              [-80.383496, -4.463672],
              [-80.293359, -4.416797],
              [-80.232178, -4.349023],
              [-80.197461, -4.311035],
              [-80.139551, -4.296094],
              [-80.063525, -4.327539],
              [-79.962891, -4.390332],
              [-79.845117, -4.445898],
              [-79.797266, -4.476367],
              [-79.710986, -4.467578],
              [-79.638525, -4.454883],
              [-79.577686, -4.500586],
              [-79.516162, -4.53916],
              [-79.501904, -4.670605],
              [-79.455762, -4.766211],
              [-79.399414, -4.840039],
              [-79.330957, -4.927832],
              [-79.268115, -4.957617],
              [-79.18667, -4.958203],
              [-79.07627, -4.990625],
              [-79.033301, -4.969141],
              [-78.995264, -4.908008],
              [-78.975391, -4.873242],
              [-78.919189, -4.858398],
              [-78.914209, -4.818652],
              [-78.925781, -4.770703],
              [-78.907617, -4.714453],
              [-78.861523, -4.665039],
              [-78.743066, -4.592676],
              [-78.686035, -4.562402],
              [-78.674463, -4.517676],
              [-78.65293, -4.458203],
              [-78.66123, -4.425098],
              [-78.685156, -4.383984],
              [-78.679395, -4.325879],
              [-78.647998, -4.248145],
              [-78.603369, -4.157324],
              [-78.565137, -4.041602],
              [-78.550439, -3.986914],
              [-78.509082, -3.952148],
              [-78.493457, -3.902051],
              [-78.471045, -3.843066],
              [-78.419775, -3.776855],
              [-78.421436, -3.705762],
              [-78.399951, -3.674316],
              [-78.398047, -3.594824],
              [-78.347266, -3.43125],
              [-78.345361, -3.397363],
              [-78.323047, -3.388281],
              [-78.28418, -3.399023],
              [-78.250732, -3.436133],
              [-78.240381, -3.472559],
              [-78.226318, -3.48916],
              [-78.194873, -3.48584],
              [-78.158496, -3.465137],
              [-78.160986, -3.432129],
              [-78.187451, -3.399805],
              [-78.194629, -3.380469],
              [-78.183301, -3.350195],
              [-78.128223, -3.283887],
              [-78.06792, -3.206836],
              [-77.938477, -3.046973],
              [-77.860596, -2.981641],
              [-77.658984, -2.912402],
              [-77.506494, -2.859961],
              [-77.360059, -2.809668],
              [-77.161475, -2.737695],
              [-76.880762, -2.635938],
              [-76.679102, -2.562598],
              [-76.499365, -2.432324],
              [-76.360156, -2.331348],
              [-76.240918, -2.243945],
              [-76.089795, -2.133105],
              [-75.885449, -1.893457],
              [-75.744531, -1.728125],
              [-75.64165, -1.607324],
              [-75.570557, -1.53125],
              [-75.513867, -1.316309],
              [-75.44917, -1.071191],
              [-75.42041, -0.962207],
              [-75.408057, -0.924316],
              [-75.380127, -0.940234],
              [-75.348193, -0.966797],
              [-75.30918, -0.968066],
              [-75.272412, -0.966797],
              [-75.249609, -0.951855],
              [-75.283594, -0.707129],
              [-75.278711, -0.653906],
              [-75.259375, -0.590137],
              [-75.263232, -0.555371],
              [-75.325244, -0.506543],
              [-75.424707, -0.408887],
              [-75.465967, -0.321777],
              [-75.491064, -0.24834],
              [-75.560596, -0.200098],
              [-75.632031, -0.157617],
              [-75.62627, -0.122852],
              [-75.58374, -0.122852],
              [-75.475977, -0.157129],
              [-75.398389, -0.145996],
              [-75.340479, -0.142188],
              [-75.284473, -0.106543],
              [-75.224609, -0.041748],
              [-75.184082, -0.041748],
              [-75.138379, -0.050488],
              [-75.054688, -0.116699],
              [-75.00498, -0.155859],
              [-74.945312, -0.188184],
              [-74.888818, -0.199414],
              [-74.8375, -0.20332],
              [-74.801758, -0.200098],
              [-74.780469, -0.244531],
              [-74.755371, -0.298633],
              [-74.69165, -0.335254],
              [-74.616357, -0.37002],
              [-74.555078, -0.429883],
              [-74.513867, -0.470117],
              [-74.465186, -0.517676],
              [-74.417871, -0.580664],
              [-74.374902, -0.691406],
              [-74.353125, -0.766602],
              [-74.328613, -0.808398],
              [-74.334424, -0.850879],
              [-74.283887, -0.927832],
              [-74.246387, -0.970605],
              [-74.180762, -0.997754],
              [-74.054395, -1.028613],
              [-73.986816, -1.098145],
              [-73.926953, -1.125195],
              [-73.863184, -1.19668],
              [-73.807178, -1.217969],
              [-73.735742, -1.21416],
              [-73.664307, -1.248828],
              [-73.610254, -1.316406],
              [-73.575488, -1.401367],
              [-73.521387, -1.449707],
              [-73.494336, -1.536621],
              [-73.525244, -1.638867],
              [-73.496289, -1.693066],
              [-73.440283, -1.737402],
              [-73.349512, -1.783887],
              [-73.266455, -1.772266],
              [-73.223975, -1.787695],
              [-73.196973, -1.830273],
              [-73.181494, -1.880371],
              [-73.145215, -2.00332],
              [-73.126514, -2.081055],
              [-73.160205, -2.156348],
              [-73.172656, -2.208398],
              [-73.154492, -2.278223],
              [-73.068164, -2.312012],
              [-72.989648, -2.339746],
              [-72.941113, -2.394043],
              [-72.887158, -2.408496],
              [-72.81123, -2.405469],
              [-72.71416, -2.392188],
              [-72.660156, -2.361035],
              [-72.625342, -2.35166],
              [-72.586719, -2.365137],
              [-72.500684, -2.39502],
              [-72.395605, -2.428906],
              [-72.300732, -2.409277],
              [-72.218457, -2.400488],
              [-72.136816, -2.380664],
              [-72.053809, -2.324609],
              [-71.984277, -2.326563],
              [-71.932471, -2.288672],
              [-71.867285, -2.227734],
              [-71.802734, -2.166309],
              [-71.752539, -2.152734],
              [-71.671484, -2.182129],
              [-71.559473, -2.224219],
              [-71.496094, -2.279199],
              [-71.447461, -2.29375],
              [-71.396973, -2.334082],
              [-71.300098, -2.334863],
              [-71.196387, -2.313086],
              [-71.113379, -2.24541],
              [-71.027295, -2.225781],
              [-70.968555, -2.206836],
              [-70.914551, -2.218555],
              [-70.705371, -2.341992],
              [-70.647998, -2.405762],
              [-70.575879, -2.418262],
              [-70.516797, -2.453125],
              [-70.418213, -2.490723],
              [-70.36416, -2.529297],
              [-70.294629, -2.552539],
              [-70.244434, -2.606543],
              [-70.164746, -2.639844],
              [-70.09585, -2.658203],
              [-70.064746, -2.70166],
              [-70.064453, -2.730762],
              [-70.074023, -2.750195],
              [-70.14707, -2.864063],
              [-70.290137, -3.087305],
              [-70.418994, -3.288281],
              [-70.62168, -3.60459],
              [-70.735107, -3.781543],
              [-70.706201, -3.788965],
              [-70.529687, -3.866406],
              [-70.48584, -3.869336],
              [-70.421094, -3.849609],
              [-70.379199, -3.81875],
              [-70.339502, -3.814355],
              [-70.298437, -3.844238],
              [-70.240283, -3.882715],
              [-70.198389, -3.995117],
              [-70.167529, -4.050195],
              [-70.094775, -4.092188],
              [-70.017187, -4.162012],
              [-69.965918, -4.235938]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Paraguay",
          "density":"16.87",
          "cartodb_id": 179,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-58.159766, -20.164648],
              [-58.137793, -20.237305],
              [-58.124609, -20.293457],
              [-58.091504, -20.333203],
              [-58.058447, -20.386133],
              [-58.025391, -20.41582],
              [-58.002246, -20.46543],
              [-58.008838, -20.52168],
              [-57.995605, -20.594434],
              [-57.979053, -20.657324],
              [-57.9625, -20.673828],
              [-57.915137, -20.690332],
              [-57.891406, -20.747461],
              [-57.908496, -20.776367],
              [-57.901904, -20.809375],
              [-57.884814, -20.841699],
              [-57.900488, -20.873047],
              [-57.892236, -20.89707],
              [-57.86001, -20.918555],
              [-57.830225, -20.997949],
              [-57.826953, -21.133594],
              [-57.86001, -21.20625],
              [-57.886475, -21.26582],
              [-57.893066, -21.302246],
              [-57.873242, -21.355078],
              [-57.906299, -21.417969],
              [-57.945996, -21.494043],
              [-57.936084, -21.546973],
              [-57.929443, -21.596582],
              [-57.926172, -21.649512],
              [-57.916211, -21.699121],
              [-57.929443, -21.751953],
              [-57.942676, -21.79834],
              [-57.949316, -21.851172],
              [-57.932764, -21.910742],
              [-57.9625, -21.966992],
              [-57.979053, -22.006641],
              [-57.985693, -22.046387],
              [-57.955908, -22.10918],
              [-57.879834, -22.135645],
              [-57.820313, -22.142285],
              [-57.764062, -22.10918],
              [-57.721094, -22.099219],
              [-57.641699, -22.129004],
              [-57.568945, -22.181934],
              [-57.476367, -22.188574],
              [-57.393652, -22.198438],
              [-57.330859, -22.215039],
              [-57.238232, -22.195215],
              [-57.142334, -22.215039],
              [-57.029883, -22.244824],
              [-56.937256, -22.271289],
              [-56.844678, -22.264648],
              [-56.775195, -22.261328],
              [-56.702441, -22.231543],
              [-56.633008, -22.234863],
              [-56.580078, -22.181934],
              [-56.550293, -22.135645],
              [-56.523828, -22.102539],
              [-56.447803, -22.076172],
              [-56.394873, -22.092676],
              [-56.351855, -22.178613],
              [-56.275781, -22.228223],
              [-56.246045, -22.264648],
              [-56.189844, -22.281152],
              [-56.06748, -22.284473],
              [-55.991406, -22.281152],
              [-55.905371, -22.307617],
              [-55.84917, -22.307617],
              [-55.799561, -22.353906],
              [-55.753271, -22.410156],
              [-55.746631, -22.512695],
              [-55.703662, -22.59209],
              [-55.647412, -22.621875],
              [-55.617676, -22.671484],
              [-55.627588, -22.740918],
              [-55.654053, -22.810352],
              [-55.650732, -22.886426],
              [-55.620996, -22.955859],
              [-55.620996, -23.025293],
              [-55.601123, -23.094727],
              [-55.561426, -23.154297],
              [-55.548193, -23.250195],
              [-55.554834, -23.319629],
              [-55.528369, -23.359375],
              [-55.518457, -23.415625],
              [-55.534961, -23.461914],
              [-55.541602, -23.524707],
              [-55.538281, -23.580957],
              [-55.518457, -23.627246],
              [-55.458887, -23.686719],
              [-55.442383, -23.792578],
              [-55.442383, -23.865332],
              [-55.415918, -23.951367],
              [-55.366309, -23.991016],
              [-55.286914, -24.004297],
              [-55.194336, -24.01748],
              [-55.081885, -23.997656],
              [-54.982666, -23.974512],
              [-54.926465, -23.951367],
              [-54.817285, -23.888477],
              [-54.721387, -23.852148],
              [-54.671777, -23.829004],
              [-54.625488, -23.8125],
              [-54.52959, -23.852148],
              [-54.440234, -23.901758],
              [-54.370801, -23.971191],
              [-54.241797, -24.047266],
              [-54.266895, -24.06582],
              [-54.318262, -24.128125],
              [-54.317285, -24.20127],
              [-54.281006, -24.306055],
              [-54.312939, -24.528125],
              [-54.412988, -24.86748],
              [-54.454102, -25.065234],
              [-54.43623, -25.121289],
              [-54.473145, -25.220215],
              [-54.610547, -25.432715],
              [-54.615869, -25.576074],
              [-54.631934, -26.005762],
              [-54.677734, -26.308789],
              [-54.755078, -26.53291],
              [-54.825488, -26.652246],
              [-54.888916, -26.666797],
              [-54.934473, -26.702539],
              [-54.962158, -26.759375],
              [-55.013623, -26.806641],
              [-55.088867, -26.844531],
              [-55.129639, -26.886035],
              [-55.135937, -26.931152],
              [-55.208008, -26.960156],
              [-55.345801, -26.973145],
              [-55.42666, -27.009277],
              [-55.450635, -27.068359],
              [-55.496729, -27.115332],
              [-55.564893, -27.15],
              [-55.597266, -27.207617],
              [-55.593799, -27.288086],
              [-55.63291, -27.357129],
              [-55.714648, -27.414844],
              [-55.78999, -27.416406],
              [-55.859033, -27.361914],
              [-55.951465, -27.325684],
              [-56.067334, -27.307715],
              [-56.164062, -27.321484],
              [-56.241699, -27.366797],
              [-56.310547, -27.43877],
              [-56.370508, -27.537402],
              [-56.437158, -27.553809],
              [-56.510547, -27.487891],
              [-56.603369, -27.467871],
              [-56.715723, -27.49375],
              [-56.805176, -27.484668],
              [-56.871729, -27.440625],
              [-56.973975, -27.435742],
              [-57.111816, -27.470117],
              [-57.39126, -27.430469],
              [-57.812207, -27.316602],
              [-58.168262, -27.273438],
              [-58.604834, -27.314355],
              [-58.641748, -27.196094],
              [-58.618604, -27.132129],
              [-58.547705, -27.083984],
              [-58.503223, -27.029492],
              [-58.485254, -26.968457],
              [-58.436328, -26.921973],
              [-58.356445, -26.890039],
              [-58.322559, -26.857617],
              [-58.334668, -26.824902],
              [-58.317676, -26.795898],
              [-58.27168, -26.770703],
              [-58.245557, -26.731055],
              [-58.239355, -26.676855],
              [-58.22207, -26.65],
              [-58.191309, -26.62998],
              [-58.187939, -26.592578],
              [-58.205176, -26.476562],
              [-58.203027, -26.381445],
              [-58.181494, -26.307422],
              [-58.154687, -26.262598],
              [-58.135645, -26.251465],
              [-58.118066, -26.224902],
              [-58.111133, -26.180176],
              [-58.082422, -26.138574],
              [-57.943115, -26.05293],
              [-57.890625, -26.006543],
              [-57.88623, -25.964258],
              [-57.865234, -25.906934],
              [-57.782471, -25.783691],
              [-57.75708, -25.725977],
              [-57.754785, -25.69707],
              [-57.725488, -25.667188],
              [-57.62583, -25.59873],
              [-57.57168, -25.53418],
              [-57.563135, -25.47373],
              [-57.587158, -25.405078],
              [-57.643896, -25.328418],
              [-57.82168, -25.136426],
              [-57.959814, -25.049219],
              [-58.136475, -24.977148],
              [-58.252783, -24.953809],
              [-58.308691, -24.979102],
              [-58.365381, -24.959277],
              [-58.422803, -24.894141],
              [-58.519629, -24.842871],
              [-58.724023, -24.786621],
              [-59.187256, -24.562305],
              [-59.372949, -24.453906],
              [-59.4354, -24.387012],
              [-59.608594, -24.266797],
              [-59.89248, -24.093555],
              [-60.110303, -24.00918],
              [-60.262207, -24.013965],
              [-60.505371, -23.963574],
              [-60.839844, -23.858105],
              [-61.03291, -23.755664],
              [-61.084717, -23.656445],
              [-61.208398, -23.557031],
              [-61.403955, -23.45752],
              [-61.505518, -23.391992],
              [-61.513037, -23.360449],
              [-61.570996, -23.319434],
              [-61.679492, -23.26875],
              [-61.798535, -23.182031],
              [-61.928027, -23.059277],
              [-62.066602, -22.869434],
              [-62.21416, -22.612402],
              [-62.37251, -22.43916],
              [-62.541553, -22.349609],
              [-62.625977, -22.29043],
              [-62.625684, -22.261523],
              [-62.650977, -22.233691],
              [-62.628516, -22.183984],
              [-62.566943, -21.988672],
              [-62.477832, -21.705273],
              [-62.385449, -21.411719],
              [-62.27666, -21.066016],
              [-62.276514, -20.820801],
              [-62.276318, -20.5625],
              [-62.121631, -20.349902],
              [-62.011816, -20.199023],
              [-61.916943, -20.055371],
              [-61.820898, -19.809473],
              [-61.756836, -19.645313],
              [-61.511816, -19.606445],
              [-61.095996, -19.520996],
              [-60.88877, -19.478516],
              [-60.451611, -19.38877],
              [-60.007373, -19.297559],
              [-59.540869, -19.291797],
              [-59.090527, -19.28623],
              [-58.741113, -19.490234],
              [-58.474219, -19.646094],
              [-58.180176, -19.817871],
              [-58.160059, -19.854883],
              [-58.139941, -19.998828],
              [-58.159766, -20.164648]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Suriname",
          "density": "3.80",
          "cartodb_id": 203,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-54.155957, 5.358984],
              [-54.240186, 5.288232],
              [-54.331641, 5.187402],
              [-54.452197, 5.013477],
              [-54.446875, 4.958789],
              [-54.47334, 4.914697],
              [-54.479687, 4.836523],
              [-54.471143, 4.749316],
              [-54.440234, 4.691992],
              [-54.426074, 4.583008],
              [-54.449609, 4.48501],
              [-54.440674, 4.428027],
              [-54.416016, 4.337646],
              [-54.39624, 4.241406],
              [-54.398389, 4.20249],
              [-54.369141, 4.170947],
              [-54.342139, 4.140039],
              [-54.350732, 4.054102],
              [-54.255518, 3.901074],
              [-54.197461, 3.834424],
              [-54.112793, 3.769434],
              [-54.081982, 3.705957],
              [-54.034229, 3.629395],
              [-54.005908, 3.62041],
              [-53.990479, 3.589551],
              [-54.005957, 3.530518],
              [-54.00957, 3.448535],
              [-54.063184, 3.35332],
              [-54.188037, 3.17876],
              [-54.203125, 3.138184],
              [-54.170703, 2.993604],
              [-54.188086, 2.874854],
              [-54.195508, 2.817871],
              [-54.256738, 2.713721],
              [-54.402002, 2.461523],
              [-54.485547, 2.416113],
              [-54.535937, 2.343311],
              [-54.568408, 2.342578],
              [-54.604736, 2.335791],
              [-54.61626, 2.326758],
              [-54.661865, 2.327539],
              [-54.697412, 2.359814],
              [-54.70293, 2.397949],
              [-54.722217, 2.44165],
              [-54.766846, 2.454736],
              [-54.85166, 2.439551],
              [-54.876074, 2.450391],
              [-54.926562, 2.497363],
              [-54.968408, 2.54834],
              [-54.978662, 2.597656],
              [-55.005811, 2.592969],
              [-55.070312, 2.54834],
              [-55.114111, 2.539209],
              [-55.148828, 2.550781],
              [-55.187695, 2.54751],
              [-55.286035, 2.499658],
              [-55.343994, 2.48877],
              [-55.385352, 2.440625],
              [-55.658936, 2.41875],
              [-55.730566, 2.406152],
              [-55.89375, 2.489502],
              [-55.935937, 2.516602],
              [-55.957471, 2.520459],
              [-55.975586, 2.515967],
              [-55.993506, 2.49751],
              [-56.020361, 2.392773],
              [-56.045117, 2.364404],
              [-56.087793, 2.341309],
              [-56.129395, 2.299512],
              [-56.137695, 2.259033],
              [-56.073633, 2.236768],
              [-56.020068, 2.158154],
              [-55.961963, 2.095117],
              [-55.915332, 2.039551],
              [-55.921631, 1.97666],
              [-55.929639, 1.8875],
              [-55.96333, 1.85708],
              [-56.019922, 1.842236],
              [-56.227148, 1.885352],
              [-56.38584, 1.923877],
              [-56.452832, 1.932324],
              [-56.482812, 1.942139],
              [-56.522363, 1.974805],
              [-56.562695, 2.005078],
              [-56.627197, 2.016016],
              [-56.704346, 2.036475],
              [-56.761133, 2.114893],
              [-56.819824, 2.22666],
              [-56.840527, 2.277148],
              [-56.886426, 2.325977],
              [-56.931494, 2.395361],
              [-56.945215, 2.456836],
              [-56.979297, 2.513232],
              [-56.997119, 2.532178],
              [-57.023486, 2.608984],
              [-57.028955, 2.6375],
              [-57.041943, 2.641113],
              [-57.060449, 2.665674],
              [-57.096875, 2.747852],
              [-57.105127, 2.768262],
              [-57.121143, 2.775537],
              [-57.163623, 2.833252],
              [-57.197363, 2.853271],
              [-57.209814, 2.882812],
              [-57.206934, 2.963379],
              [-57.225, 3.003076],
              [-57.230566, 3.078564],
              [-57.231641, 3.108887],
              [-57.248975, 3.142285],
              [-57.27793, 3.164307],
              [-57.28291, 3.218848],
              [-57.289941, 3.353613],
              [-57.303662, 3.3771],
              [-57.425586, 3.375439],
              [-57.437891, 3.362256],
              [-57.490576, 3.354297],
              [-57.549609, 3.352832],
              [-57.602734, 3.370947],
              [-57.646729, 3.394531],
              [-57.656104, 3.42373],
              [-57.649463, 3.517383],
              [-57.720361, 3.588281],
              [-57.832666, 3.675977],
              [-57.866553, 3.787256],
              [-57.907715, 3.856689],
              [-58.032227, 4.001953],
              [-58.054297, 4.10166],
              [-58.054492, 4.171924],
              [-58.010742, 4.236475],
              [-57.949756, 4.349951],
              [-57.924707, 4.453125],
              [-57.90625, 4.506787],
              [-57.874707, 4.5771],
              [-57.845996, 4.668164],
              [-57.867871, 4.724316],
              [-57.904883, 4.779297],
              [-57.917041, 4.82041],
              [-57.881104, 4.880615],
              [-57.844922, 4.923047],
              [-57.804102, 4.929053],
              [-57.752002, 4.954492],
              [-57.711084, 4.991064],
              [-57.648828, 5.000684],
              [-57.570898, 5.004492],
              [-57.412158, 5.00459],
              [-57.331006, 5.020166],
              [-57.305762, 5.049561],
              [-57.30957, 5.105859],
              [-57.269287, 5.157031],
              [-57.226855, 5.178516],
              [-57.209814, 5.19541],
              [-57.207324, 5.214209],
              [-57.218457, 5.231543],
              [-57.235303, 5.242871],
              [-57.279639, 5.246777],
              [-57.318555, 5.335352],
              [-57.291895, 5.373975],
              [-57.25752, 5.445166],
              [-57.2479, 5.485254],
              [-57.194775, 5.548437],
              [-57.182129, 5.528906],
              [-57.14082, 5.643799],
              [-57.136035, 5.737207],
              [-57.10459, 5.829395],
              [-57.056641, 5.938672],
              [-56.969824, 5.992871],
              [-56.466016, 5.937744],
              [-56.235596, 5.885352],
              [-55.939551, 5.795459],
              [-55.897607, 5.699316],
              [-55.895508, 5.795459],
              [-55.909912, 5.892627],
              [-55.828174, 5.96167],
              [-55.64834, 5.985889],
              [-55.379297, 5.952637],
              [-55.148291, 5.993457],
              [-54.833691, 5.98833],
              [-54.356152, 5.909863],
              [-54.142334, 5.856348],
              [-54.054199, 5.80791],
              [-54.037402, 5.720508],
              [-54.045947, 5.608887],
              [-54.080469, 5.502246],
              [-54.155957, 5.358984]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Uruguay",
          "density": "19.43",
          "cartodb_id": 226,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-53.370605, -33.742188],
              [-53.41958, -33.779199],
              [-53.472461, -33.849316],
              [-53.534521, -34.01748],
              [-53.74292, -34.249512],
              [-53.785303, -34.380371],
              [-54.010254, -34.516992],
              [-54.168555, -34.670703],
              [-54.272119, -34.666895],
              [-54.365332, -34.732715],
              [-54.902295, -34.932813],
              [-55.095117, -34.895117],
              [-55.237891, -34.895801],
              [-55.370605, -34.807617],
              [-55.673145, -34.775684],
              [-55.862939, -34.810938],
              [-56.11792, -34.90791],
              [-56.194629, -34.906445],
              [-56.249951, -34.90127],
              [-56.387842, -34.861035],
              [-56.463086, -34.775391],
              [-56.855176, -34.67666],
              [-57.170703, -34.452344],
              [-57.543457, -34.448047],
              [-57.829102, -34.477344],
              [-57.873242, -34.447656],
              [-57.902148, -34.390137],
              [-57.96123, -34.306934],
              [-58.207031, -34.109082],
              [-58.400195, -33.912402],
              [-58.438135, -33.719141],
              [-58.411328, -33.508887],
              [-58.353369, -33.260059],
              [-58.363525, -33.182324],
              [-58.292187, -33.137988],
              [-58.221582, -33.129102],
              [-58.153564, -33.064648],
              [-58.092676, -32.967383],
              [-58.082324, -32.893652],
              [-58.12959, -32.757227],
              [-58.162207, -32.566504],
              [-58.201172, -32.47168],
              [-58.123047, -32.321875],
              [-58.119727, -32.248926],
              [-58.164795, -32.184863],
              [-58.177002, -32.119043],
              [-58.156348, -32.051563],
              [-58.1604, -31.986523],
              [-58.189014, -31.924219],
              [-58.16748, -31.872656],
              [-58.09585, -31.831836],
              [-58.042334, -31.769238],
              [-58.006982, -31.684961],
              [-57.988867, -31.620605],
              [-57.987988, -31.576172],
              [-58.009668, -31.534375],
              [-58.053857, -31.494922],
              [-58.033398, -31.416602],
              [-57.94834, -31.299414],
              [-57.893359, -31.195312],
              [-57.868408, -31.104395],
              [-57.870068, -31.031055],
              [-57.898291, -30.975195],
              [-57.886328, -30.937402],
              [-57.834082, -30.91748],
              [-57.810596, -30.858594],
              [-57.818555, -30.712012],
              [-57.87251, -30.591016],
              [-57.831201, -30.495215],
              [-57.712695, -30.384473],
              [-57.650879, -30.29502],
              [-57.645752, -30.226953],
              [-57.608887, -30.187793],
              [-57.552295, -30.26123],
              [-57.383838, -30.280664],
              [-57.214453, -30.283398],
              [-57.186914, -30.264844],
              [-57.120508, -30.144434],
              [-57.032715, -30.109961],
              [-56.937256, -30.101074],
              [-56.832715, -30.107227],
              [-56.72168, -30.186914],
              [-56.407227, -30.447461],
              [-56.176172, -30.628418],
              [-56.105859, -30.71377],
              [-56.044824, -30.777637],
              [-55.998975, -30.837207],
              [-56.018457, -30.991895],
              [-56.015527, -31.059668],
              [-56.004687, -31.079199],
              [-55.952002, -31.080859],
              [-55.873682, -31.069629],
              [-55.807764, -31.036719],
              [-55.756348, -30.987109],
              [-55.705957, -30.946582],
              [-55.665234, -30.924902],
              [-55.650488, -30.89209],
              [-55.627148, -30.858105],
              [-55.603027, -30.850781],
              [-55.557324, -30.875977],
              [-55.449561, -30.964453],
              [-55.366064, -31.046191],
              [-55.345508, -31.092969],
              [-55.313281, -31.141699],
              [-55.278955, -31.18418],
              [-55.254639, -31.225586],
              [-55.173535, -31.27959],
              [-55.091162, -31.313965],
              [-55.036035, -31.279004],
              [-54.895996, -31.391211],
              [-54.587646, -31.485156],
              [-54.530908, -31.541992],
              [-54.477686, -31.622754],
              [-54.369922, -31.74502],
              [-54.220557, -31.855176],
              [-54.100439, -31.901563],
              [-53.985156, -31.928125],
              [-53.920605, -31.952344],
              [-53.876514, -31.994531],
              [-53.806104, -32.039941],
              [-53.761719, -32.056836],
              [-53.746582, -32.097461],
              [-53.701123, -32.186328],
              [-53.653613, -32.29873],
              [-53.601709, -32.403027],
              [-53.489404, -32.503223],
              [-53.362744, -32.581152],
              [-53.23125, -32.625391],
              [-53.157275, -32.680078],
              [-53.125586, -32.736719],
              [-53.214062, -32.821094],
              [-53.310107, -32.927051],
              [-53.395215, -33.010352],
              [-53.482861, -33.068555],
              [-53.511865, -33.108691],
              [-53.531348, -33.170898],
              [-53.530371, -33.500293],
              [-53.537646, -33.622852],
              [-53.531348, -33.655469],
              [-53.518848, -33.677246],
              [-53.463574, -33.709863],
              [-53.397559, -33.737305],
              [-53.370605, -33.742188]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Venezuela",
          "density":"31.62",
          "cartodb_id": 231,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:16:23+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-60.9979, 8.867334],
                [-61.059961, 8.847021],
                [-61.069189, 8.947314],
                [-61.050488, 8.974365],
                [-60.944775, 9.055029],
                [-60.91582, 9.070313],
                [-60.89458, 9.053369],
                [-60.899902, 9.031885],
                [-60.84917, 8.995703],
                [-60.861426, 8.949609],
                [-60.916406, 8.899268],
                [-60.9979, 8.867334]
              ]
            ],
            [
              [
                [-60.821191, 9.138379],
                [-60.941406, 9.105566],
                [-60.939453, 9.132324],
                [-60.907275, 9.178711],
                [-60.844873, 9.191797],
                [-60.821387, 9.207666],
                [-60.781592, 9.218359],
                [-60.758887, 9.216455],
                [-60.73584, 9.20332],
                [-60.790381, 9.177197],
                [-60.821191, 9.138379]
              ]
            ],
            [
              [
                [-65.2125, 10.906445],
                [-65.266406, 10.883984],
                [-65.365234, 10.906445],
                [-65.414648, 10.937891],
                [-65.383203, 10.973828],
                [-65.302344, 10.973828],
                [-65.226562, 10.930225],
                [-65.2125, 10.906445]
              ]
            ],
            [
              [
                [-63.849365, 11.131006],
                [-63.817285, 11.000342],
                [-63.8271, 10.97583],
                [-63.917627, 10.887549],
                [-63.993555, 10.881201],
                [-64.054687, 10.884326],
                [-64.101172, 10.901416],
                [-64.160889, 10.958789],
                [-64.218945, 10.941602],
                [-64.3625, 10.961523],
                [-64.402344, 10.981592],
                [-64.348633, 11.051904],
                [-64.249756, 11.080322],
                [-64.213672, 11.086133],
                [-64.184814, 11.042969],
                [-64.112793, 11.005664],
                [-64.02832, 11.001855],
                [-64.007324, 11.068457],
                [-63.893115, 11.167236],
                [-63.849365, 11.131006]
              ]
            ],
            [
              [
                [-69.762402, 11.676025],
                [-69.711914, 11.564209],
                [-69.631592, 11.479932],
                [-69.569824, 11.485449],
                [-69.525732, 11.499512],
                [-69.232568, 11.518457],
                [-69.05459, 11.461035],
                [-68.827979, 11.431738],
                [-68.616211, 11.309375],
                [-68.398633, 11.160986],
                [-68.343164, 11.052832],
                [-68.324805, 10.949316],
                [-68.27207, 10.880029],
                [-68.324707, 10.80874],
                [-68.296289, 10.689355],
                [-68.234082, 10.569141],
                [-68.139941, 10.492725],
                [-67.871631, 10.47207],
                [-67.581348, 10.52373],
                [-67.133301, 10.57041],
                [-66.989063, 10.610645],
                [-66.247217, 10.632227],
                [-66.105859, 10.574609],
                [-66.092139, 10.51709],
                [-66.090479, 10.472949],
                [-65.851758, 10.257764],
                [-65.655859, 10.228467],
                [-65.489355, 10.159424],
                [-65.317383, 10.122363],
                [-65.129102, 10.070068],
                [-65.023291, 10.07666],
                [-64.944043, 10.09502],
                [-64.850488, 10.098096],
                [-64.18833, 10.457812],
                [-63.833691, 10.448535],
                [-63.779053, 10.471924],
                [-63.731885, 10.503418],
                [-63.862695, 10.558154],
                [-64.15791, 10.579248],
                [-64.24751, 10.542578],
                [-64.298193, 10.635156],
                [-64.201953, 10.632666],
                [-63.873437, 10.66377],
                [-63.496777, 10.643262],
                [-63.189893, 10.70918],
                [-63.035498, 10.720117],
                [-62.946729, 10.70708],
                [-62.702344, 10.749805],
                [-62.242285, 10.699561],
                [-61.879492, 10.741016],
                [-61.921387, 10.681445],
                [-62.04043, 10.645361],
                [-62.23291, 10.633984],
                [-62.37998, 10.546875],
                [-62.693555, 10.562988],
                [-62.913574, 10.531494],
                [-62.842969, 10.507227],
                [-62.843018, 10.41792],
                [-62.812939, 10.399902],
                [-62.78125, 10.399219],
                [-62.706299, 10.333057],
                [-62.68584, 10.289795],
                [-62.661621, 10.198584],
                [-62.694678, 10.100098],
                [-62.740576, 10.056152],
                [-62.651172, 10.070654],
                [-62.600488, 10.116943],
                [-62.60791, 10.163428],
                [-62.600488, 10.217285],
                [-62.550342, 10.200439],
                [-62.515137, 10.176123],
                [-62.400928, 9.918408],
                [-62.32041, 9.783057],
                [-62.299805, 9.788184],
                [-62.280664, 9.792969],
                [-62.256738, 9.818896],
                [-62.221143, 9.882568],
                [-62.19043, 9.842187],
                [-62.171973, 9.826709],
                [-62.153369, 9.821777],
                [-62.170313, 9.879492],
                [-62.147461, 9.953418],
                [-62.155322, 9.979248],
                [-62.119629, 9.984863],
                [-62.0771, 9.975049],
                [-62.016504, 9.954687],
                [-61.908594, 9.869922],
                [-61.837256, 9.78208],
                [-61.831152, 9.733057],
                [-61.805371, 9.705518],
                [-61.75874, 9.676514],
                [-61.735937, 9.631201],
                [-61.731738, 9.70249],
                [-61.75918, 9.754443],
                [-61.765918, 9.813818],
                [-61.625391, 9.816455],
                [-61.588867, 9.894531],
                [-61.512305, 9.84751],
                [-61.309375, 9.633057],
                [-61.234424, 9.597607],
                [-61.013379, 9.556445],
                [-60.874072, 9.45332],
                [-60.79248, 9.360742],
                [-60.840967, 9.263672],
                [-60.971045, 9.215186],
                [-61.023145, 9.15459],
                [-61.053076, 9.095117],
                [-61.053564, 9.035254],
                [-61.092969, 8.965771],
                [-61.098828, 8.941309],
                [-61.122363, 8.843359],
                [-61.175879, 8.725391],
                [-61.247266, 8.600342],
                [-61.618701, 8.597461],
                [-61.526904, 8.546143],
                [-61.442578, 8.508691],
                [-61.304004, 8.4104],
                [-61.19375, 8.487598],
                [-61.035986, 8.493115],
                [-60.865234, 8.578809],
                [-60.800977, 8.592139],
                [-60.481494, 8.547266],
                [-60.404492, 8.610254],
                [-60.340234, 8.62876],
                [-60.16748, 8.616992],
                [-60.017529, 8.549316],
                [-59.831641, 8.305957],
                [-59.828906, 8.27915],
                [-59.849072, 8.248682],
                [-59.964844, 8.191602],
                [-59.990723, 8.162012],
                [-60.032422, 8.053564],
                [-60.178174, 7.994043],
                [-60.278906, 7.919434],
                [-60.346777, 7.854004],
                [-60.380615, 7.827637],
                [-60.513623, 7.813184],
                [-60.556348, 7.772021],
                [-60.610107, 7.64834],
                [-60.649463, 7.596631],
                [-60.718652, 7.535937],
                [-60.719238, 7.498682],
                [-60.62373, 7.36333],
                [-60.606543, 7.32085],
                [-60.636182, 7.256592],
                [-60.633301, 7.211084],
                [-60.583203, 7.156201],
                [-60.523193, 7.143701],
                [-60.464941, 7.166553],
                [-60.392383, 7.164551],
                [-60.345068, 7.15],
                [-60.325488, 7.133984],
                [-60.32207, 7.092041],
                [-60.3521, 7.002881],
                [-60.39502, 6.945361],
                [-60.586084, 6.85708],
                [-60.671045, 6.805957],
                [-60.71792, 6.768311],
                [-60.82085, 6.788477],
                [-60.87334, 6.786914],
                [-60.913574, 6.757812],
                [-60.937988, 6.732764],
                [-61.00708, 6.726611],
                [-61.104785, 6.711377],
                [-61.145605, 6.694531],
                [-61.177246, 6.650928],
                [-61.203613, 6.588379],
                [-61.181592, 6.513379],
                [-61.151025, 6.446533],
                [-61.152295, 6.385107],
                [-61.128711, 6.214307],
                [-61.159473, 6.174414],
                [-61.224951, 6.129199],
                [-61.303125, 6.049512],
                [-61.39082, 5.93877],
                [-61.376807, 5.906982],
                [-61.167187, 5.674219],
                [-60.954004, 5.437402],
                [-60.742139, 5.202051],
                [-60.711963, 5.191553],
                [-60.671973, 5.164355],
                [-60.63501, 5.081982],
                [-60.604492, 4.99458],
                [-60.603857, 4.949365],
                [-60.627588, 4.892529],
                [-60.67915, 4.8271],
                [-60.741748, 4.774121],
                [-60.833398, 4.729199],
                [-60.90625, 4.686816],
                [-60.966406, 4.574707],
                [-61.002832, 4.535254],
                [-61.036279, 4.519336],
                [-61.102441, 4.504687],
                [-61.209424, 4.508057],
                [-61.280078, 4.516895],
                [-61.367529, 4.433008],
                [-61.479395, 4.402246],
                [-61.554248, 4.287793],
                [-61.82085, 4.197021],
                [-62.081592, 4.126318],
                [-62.153125, 4.098389],
                [-62.410645, 4.156738],
                [-62.472559, 4.138525],
                [-62.543945, 4.084326],
                [-62.609766, 4.042285],
                [-62.665332, 4.039648],
                [-62.712109, 4.01792],
                [-62.739941, 3.940332],
                [-62.7646, 3.672949],
                [-62.856982, 3.593457],
                [-62.968652, 3.593945],
                [-63.045313, 3.686475],
                [-63.13623, 3.756445],
                [-63.294727, 3.922266],
                [-63.338672, 3.943896],
                [-63.379785, 3.942871],
                [-63.526807, 3.893701],
                [-63.596631, 3.915039],
                [-63.65293, 3.94082],
                [-63.746973, 3.932568],
                [-63.914648, 3.930664],
                [-64.021484, 3.929102],
                [-64.073389, 3.974414],
                [-64.121729, 4.066992],
                [-64.154297, 4.100146],
                [-64.19248, 4.126855],
                [-64.255664, 4.140332],
                [-64.525537, 4.13999],
                [-64.576367, 4.139893],
                [-64.613672, 4.157715],
                [-64.665527, 4.237109],
                [-64.722266, 4.274414],
                [-64.788672, 4.276025],
                [-64.817871, 4.232275],
                [-64.702588, 4.089307],
                [-64.668994, 4.011816],
                [-64.56792, 3.899805],
                [-64.275293, 3.662695],
                [-64.221094, 3.587402],
                [-64.227051, 3.491211],
                [-64.22876, 3.343994],
                [-64.218848, 3.204687],
                [-64.143555, 3.004883],
                [-64.037793, 2.801514],
                [-64.009033, 2.671875],
                [-64.028711, 2.576074],
                [-64.048828, 2.525098],
                [-64.046582, 2.502393],
                [-64.024902, 2.481885],
                [-63.92417, 2.452441],
                [-63.712549, 2.434033],
                [-63.584619, 2.433936],
                [-63.389258, 2.411914],
                [-63.374854, 2.34043],
                [-63.393945, 2.22251],
                [-63.43252, 2.155566],
                [-63.463916, 2.136035],
                [-63.570264, 2.120508],
                [-63.682129, 2.048145],
                [-63.844482, 1.976709],
                [-63.937158, 1.966992],
                [-63.975781, 1.953027],
                [-64.008496, 1.931592],
                [-64.035449, 1.904443],
                [-64.067041, 1.770508],
                [-64.114844, 1.619287],
                [-64.205029, 1.529492],
                [-64.304199, 1.455273],
                [-64.405127, 1.446875],
                [-64.486035, 1.452783],
                [-64.52627, 1.431006],
                [-64.584375, 1.369873],
                [-64.667432, 1.293848],
                [-64.731543, 1.25332],
                [-64.817969, 1.257129],
                [-64.910107, 1.219727],
                [-65.026562, 1.158447],
                [-65.10376, 1.108105],
                [-65.169629, 1.022217],
                [-65.263965, 0.931885],
                [-65.36084, 0.868652],
                [-65.407227, 0.790479],
                [-65.473389, 0.69126],
                [-65.556055, 0.687988],
                [-65.562695, 0.74751],
                [-65.522998, 0.843408],
                [-65.566016, 0.926074],
                [-65.644678, 0.970361],
                [-65.681445, 0.983447],
                [-65.718115, 0.978027],
                [-65.811328, 0.937256],
                [-65.925879, 0.863135],
                [-65.996338, 0.809766],
                [-66.060059, 0.785352],
                [-66.191211, 0.763281],
                [-66.30166, 0.751953],
                [-66.347119, 0.767187],
                [-66.429248, 0.82168],
                [-66.619043, 0.992139],
                [-66.876025, 1.223047],
                [-66.895508, 1.289893],
                [-66.884473, 1.358252],
                [-66.931104, 1.458008],
                [-66.95835, 1.564209],
                [-66.981543, 1.600781],
                [-66.988135, 1.680176],
                [-67.043896, 1.823193],
                [-67.089551, 1.940332],
                [-67.131445, 1.999854],
                [-67.113818, 2.050586],
                [-67.131445, 2.10127],
                [-67.165479, 2.142578],
                [-67.215234, 2.275488],
                [-67.197607, 2.332764],
                [-67.21084, 2.390137],
                [-67.252734, 2.429443],
                [-67.312256, 2.47168],
                [-67.391602, 2.559912],
                [-67.486426, 2.643652],
                [-67.534961, 2.676758],
                [-67.568018, 2.689941],
                [-67.59668, 2.769336],
                [-67.618701, 2.793604],
                [-67.667236, 2.800195],
                [-67.766455, 2.833301],
                [-67.859082, 2.793604],
                [-67.86123, 2.855322],
                [-67.834766, 2.892822],
                [-67.514844, 3.187256],
                [-67.353613, 3.322656],
                [-67.336279, 3.342627],
                [-67.322168, 3.373975],
                [-67.311133, 3.415869],
                [-67.347705, 3.46377],
                [-67.498682, 3.691113],
                [-67.551123, 3.733838],
                [-67.602539, 3.768799],
                [-67.661621, 3.864258],
                [-67.732324, 4.086523],
                [-67.783203, 4.198242],
                [-67.798633, 4.283887],
                [-67.79541, 4.380713],
                [-67.814307, 4.455078],
                [-67.855273, 4.506885],
                [-67.855273, 4.665479],
                [-67.814307, 4.930811],
                [-67.804199, 5.13252],
                [-67.824902, 5.270459],
                [-67.788428, 5.375488],
                [-67.694629, 5.44751],
                [-67.642285, 5.558789],
                [-67.631348, 5.709375],
                [-67.575195, 5.833105],
                [-67.473877, 5.92998],
                [-67.439355, 6.025537],
                [-67.471582, 6.119775],
                [-67.481982, 6.180273],
                [-67.568066, 6.241797],
                [-67.727148, 6.284961],
                [-67.85918, 6.289893],
                [-67.938867, 6.241943],
                [-68.143066, 6.19751],
                [-68.471777, 6.156543],
                [-68.736475, 6.156787],
                [-68.937207, 6.198193],
                [-69.089941, 6.184375],
                [-69.194531, 6.115332],
                [-69.268164, 6.099707],
                [-69.31084, 6.137598],
                [-69.35708, 6.147998],
                [-69.427148, 6.123975],
                [-69.439258, 6.134912],
                [-69.594824, 6.321484],
                [-69.738965, 6.494385],
                [-69.904199, 6.700244],
                [-70.09502, 6.937939],
                [-70.129199, 6.953613],
                [-70.188135, 6.952051],
                [-70.266113, 6.947949],
                [-70.3875, 6.972607],
                [-70.470654, 7.007129],
                [-70.535547, 7.040527],
                [-70.655078, 7.082764],
                [-70.737158, 7.090039],
                [-70.810693, 7.077588],
                [-71.013281, 6.994434],
                [-71.128613, 6.986719],
                [-71.217822, 6.985205],
                [-71.457129, 7.026367],
                [-71.620898, 7.03291],
                [-71.811279, 7.005811],
                [-71.892676, 6.990332],
                [-72.006641, 7.032617],
                [-72.084277, 7.096875],
                [-72.156689, 7.249707],
                [-72.207715, 7.370264],
                [-72.296338, 7.394531],
                [-72.394629, 7.415088],
                [-72.442969, 7.454883],
                [-72.471973, 7.524268],
                [-72.478955, 7.613232],
                [-72.468896, 7.757959],
                [-72.45957, 7.809863],
                [-72.446045, 7.966113],
                [-72.391699, 8.047705],
                [-72.357617, 8.087305],
                [-72.36416, 8.152783],
                [-72.390332, 8.287061],
                [-72.416553, 8.381982],
                [-72.525732, 8.489697],
                [-72.66543, 8.627588],
                [-72.725537, 8.848291],
                [-72.796387, 9.108984],
                [-72.8521, 9.135156],
                [-72.904443, 9.12207],
                [-72.960156, 9.135156],
                [-73.009277, 9.239941],
                [-73.058398, 9.25957],
                [-73.136719, 9.222803],
                [-73.193164, 9.194141],
                [-73.336719, 9.16792],
                [-73.366211, 9.194141],
                [-73.356348, 9.226855],
                [-73.295654, 9.322021],
                [-73.224268, 9.443604],
                [-73.14126, 9.554639],
                [-73.064062, 9.668213],
                [-73.006543, 9.78916],
                [-72.967383, 10.029736],
                [-72.940381, 10.195752],
                [-72.869336, 10.49126],
                [-72.73916, 10.727197],
                [-72.690088, 10.83584],
                [-72.572266, 10.977148],
                [-72.518018, 11.053906],
                [-72.446094, 11.114258],
                [-72.248486, 11.196436],
                [-72.012305, 11.601953],
                [-71.958105, 11.666406],
                [-71.719482, 11.726855],
                [-71.536084, 11.774072],
                [-71.400195, 11.823535],
                [-71.355566, 11.849756],
                [-71.319727, 11.861914],
                [-71.349414, 11.814941],
                [-71.414551, 11.755176],
                [-71.488379, 11.71875],
                [-71.868652, 11.627344],
                [-71.90752, 11.607959],
                [-71.956934, 11.569922],
                [-71.957227, 11.482812],
                [-71.946973, 11.414453],
                [-71.835107, 11.190332],
                [-71.791455, 11.135059],
                [-71.641602, 11.013525],
                [-71.675684, 10.996729],
                [-71.730908, 10.994678],
                [-71.69043, 10.835498],
                [-71.598437, 10.726221],
                [-71.594336, 10.657373],
                [-71.664844, 10.44375],
                [-71.793506, 10.315967],
                [-71.884766, 10.167236],
                [-71.955713, 10.108057],
                [-72.112842, 9.815576],
                [-71.993262, 9.641504],
                [-71.97627, 9.553223],
                [-71.873047, 9.427637],
                [-71.805664, 9.386426],
                [-71.760742, 9.335742],
                [-71.781348, 9.25],
                [-71.740137, 9.133887],
                [-71.686719, 9.07251],
                [-71.619531, 9.047949],
                [-71.536621, 9.048291],
                [-71.297949, 9.125635],
                [-71.241406, 9.160449],
                [-71.205371, 9.222461],
                [-71.08584, 9.348242],
                [-71.078418, 9.510791],
                [-71.052686, 9.705811],
                [-71.081738, 9.833203],
                [-71.207227, 10.0146],
                [-71.262207, 10.143604],
                [-71.386621, 10.26377],
                [-71.462793, 10.469238],
                [-71.494238, 10.533203],
                [-71.517871, 10.621826],
                [-71.544629, 10.778711],
                [-71.461133, 10.835645],
                [-71.469531, 10.96416],
                [-71.264355, 10.999512],
                [-70.820508, 11.208447],
                [-70.545605, 11.261377],
                [-70.23252, 11.372998],
                [-70.159961, 11.428076],
                [-70.097119, 11.519775],
                [-70.048535, 11.530322],
                [-69.885352, 11.444336],
                [-69.804785, 11.474219],
                [-69.7729, 11.541309],
                [-69.817334, 11.67207],
                [-69.910937, 11.672119],
                [-70.192578, 11.624609],
                [-70.220117, 11.680859],
                [-70.22002, 11.730078],
                [-70.286523, 11.886035],
                [-70.245117, 12.003516],
                [-70.202783, 12.098389],
                [-70.122021, 12.136621],
                [-70.003955, 12.177881],
                [-69.914355, 12.1146],
                [-69.860107, 12.054199],
                [-69.830615, 11.995605],
                [-69.810547, 11.836865],
                [-69.762402, 11.676025]
              ]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "name": "Falkland Islands (U.K.)",
          "density": "0.3115",
          "cartodb_id": 72,
          "created_at": "2013-11-27T20:16:22+0100",
          "updated_at": "2013-11-27T20:40:04+0100"
        },
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-59.682666, -52.231641],
                [-59.746582, -52.250879],
                [-59.764453, -52.242187],
                [-59.784863, -52.204687],
                [-59.785937, -52.156152],
                [-59.793311, -52.13418],
                [-59.753223, -52.141406],
                [-59.681006, -52.180078],
                [-59.682666, -52.231641]
              ]
            ],
            [
              [
                [-58.438818, -52.011035],
                [-58.432715, -52.099023],
                [-58.512842, -52.071094],
                [-58.541406, -52.028418],
                [-58.49707, -51.999414],
                [-58.460547, -52.001562],
                [-58.438818, -52.011035]
              ]
            ],
            [
              [
                [-61.01875, -51.785742],
                [-60.947266, -51.799512],
                [-60.875977, -51.794238],
                [-60.916162, -51.896973],
                [-60.947559, -51.946289],
                [-61.031982, -51.94248],
                [-61.115771, -51.875293],
                [-61.14502, -51.839453],
                [-61.05166, -51.813965],
                [-61.01875, -51.785742]
              ]
            ],
            [
              [
                [-60.28623, -51.461914],
                [-60.141553, -51.480957],
                [-60.008691, -51.410547],
                [-59.91709, -51.388086],
                [-59.841602, -51.40332],
                [-59.788428, -51.445996],
                [-59.711328, -51.439258],
                [-59.493457, -51.395703],
                [-59.465088, -51.410547],
                [-59.387598, -51.359961],
                [-59.32085, -51.383594],
                [-59.268066, -51.427539],
                [-59.293945, -51.478516],
                [-59.354199, -51.510937],
                [-59.392432, -51.556152],
                [-59.437012, -51.592676],
                [-59.514209, -51.626562],
                [-59.573193, -51.680859],
                [-59.714893, -51.807715],
                [-59.921387, -51.969531],
                [-59.989746, -51.984082],
                [-60.132275, -51.993848],
                [-60.19375, -51.982715],
                [-60.246338, -51.986426],
                [-60.288281, -52.07373],
                [-60.353467, -52.139941],
                [-60.384229, -52.154004],
                [-60.452002, -52.160254],
                [-60.484082, -52.170313],
                [-60.508398, -52.194727],
                [-60.686377, -52.188379],
                [-60.812207, -52.147754],
                [-60.961426, -52.057324],
                [-60.7625, -51.946484],
                [-60.591064, -51.951563],
                [-60.449756, -51.877148],
                [-60.334473, -51.839551],
                [-60.288672, -51.80127],
                [-60.238477, -51.771973],
                [-60.238135, -51.733789],
                [-60.276514, -51.716602],
                [-60.32832, -51.718359],
                [-60.37959, -51.735156],
                [-60.500098, -51.756543],
                [-60.58252, -51.712695],
                [-60.528076, -51.696387],
                [-60.467236, -51.697168],
                [-60.280957, -51.656055],
                [-60.245166, -51.638867],
                [-60.302637, -51.580469],
                [-60.414941, -51.54502],
                [-60.505811, -51.485449],
                [-60.522754, -51.463184],
                [-60.518262, -51.427832],
                [-60.568457, -51.357812],
                [-60.515723, -51.354297],
                [-60.445459, -51.399414],
                [-60.28623, -51.461914]
              ]
            ],
            [
              [
                [-60.111719, -51.395898],
                [-60.248828, -51.395996],
                [-60.275879, -51.363184],
                [-60.275342, -51.280566],
                [-60.171387, -51.273437],
                [-60.069824, -51.30791],
                [-60.076465, -51.342578],
                [-60.111719, -51.395898]
              ]
            ],
            [
              [
                [-58.850195, -51.269922],
                [-58.69751, -51.328516],
                [-58.50625, -51.308105],
                [-58.42583, -51.324219],
                [-58.378711, -51.373047],
                [-58.406738, -51.418359],
                [-58.467432, -51.411816],
                [-58.519238, -51.423926],
                [-58.508936, -51.483594],
                [-58.47373, -51.509082],
                [-58.271582, -51.574707],
                [-58.234521, -51.578613],
                [-58.241113, -51.551074],
                [-58.276221, -51.506055],
                [-58.289307, -51.45752],
                [-58.259229, -51.41709],
                [-58.206445, -51.404687],
                [-57.976514, -51.384375],
                [-57.92251, -51.403516],
                [-57.808496, -51.517969],
                [-57.91543, -51.533789],
                [-57.960449, -51.583203],
                [-57.866357, -51.60459],
                [-57.791797, -51.636133],
                [-57.831152, -51.68457],
                [-57.838184, -51.70918],
                [-58.003955, -51.743457],
                [-58.150928, -51.76543],
                [-58.217627, -51.822461],
                [-58.335986, -51.86377],
                [-58.683496, -51.93623],
                [-58.643066, -51.994824],
                [-58.637695, -52.023047],
                [-58.652783, -52.099219],
                [-59.13125, -52.00791],
                [-59.19585, -52.017676],
                [-59.068018, -52.173047],
                [-59.162793, -52.201758],
                [-59.256348, -52.183105],
                [-59.341504, -52.195996],
                [-59.395654, -52.308008],
                [-59.532227, -52.236426],
                [-59.64873, -52.134375],
                [-59.64917, -52.077246],
                [-59.53667, -51.970605],
                [-59.570801, -51.925391],
                [-59.30874, -51.780469],
                [-59.261768, -51.737305],
                [-59.180029, -51.7125],
                [-59.09541, -51.704102],
                [-59.059521, -51.685449],
                [-59.065381, -51.650195],
                [-59.099463, -51.589746],
                [-59.096631, -51.491406],
                [-58.88667, -51.35791],
                [-58.91748, -51.27207],
                [-58.850195, -51.269922]
              ]
            ]
          ]
        }
      }
    ]
  };