import { Container, Row, Col } from 'reactstrap';
import ContactForm from '../components/ContactForm';

const ContactUs = () => {
    return(
        <Container>
        <h1 style={{ color: 'darkblue', marginTop: '1rem'}} >Contactenos</h1><hr />
        <Row className='mx-auto'style={{ textAlign:'center', marginBottom: '3rem'}}>
            <Col sm='12' lg='6' style={{ color: '#00008B'}}>
               <h5>Dirrección</h5>
               <address>
                    661 Main Avenue<br />
                    Hackensack, NJ 07601<br />
                    EE.UU.
               </address>
            </Col>
            <Col>
                <a role='button' className='btn btn-link' href='tel:+12018810097' style={{ color: '#00008F'}}><i className='fa fa-phone' /> 201-881-0097</a>
                <br />
                <a role='button' className='btn btn-link' href='mailto:ADMDS1959@gmail.com' style={{ color: '#00008F'}}><i className='fa fa-envelope-o' /> ADMDS1959@gmail.com</a>
            </Col>
            <hr />
        </Row>
        <Row className='row-content'>
            <Col md='10'>
                <ContactForm />
            </Col>
        </Row>
    </Container>
    );
};

export default ContactUs;