import CampHeader from "../components/CampHeader";
import CampTextBoxOne from "../components/CampTextBoxOne";
import CampTextBoxTwo from "../components/CampTextBoxTwo";
import CampTextBoxThree from "../components/CampTextBoxThree";
import CampOne from "../components/CampOne";
import CampTwo from '../components/CampTwo';
import CampThree from '../components/CampThree';

const Camp = () => {
    return(
        <>
            <CampHeader />
            <CampOne />
            <CampTextBoxOne />
            <CampTwo />
            <CampTextBoxTwo />
            <CampThree />
            <CampTextBoxThree />
        </>
    );
};

export default Camp;