export const CanadaData = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-78.019173, 62.59193],
                [-77.867218, 62.589161],
                [-77.837784, 62.556938],
                [-78.103333, 62.559158],
                [-78.107773, 62.582771],
                [-78.019173, 62.59193]
              ]
            ],
            [
              [
                [-69.182503, 59.128601],
                [-69.198608, 59.067211],
                [-69.132767, 59.055271],
                [-69.221123, 58.991661],
                [-69.223053, 58.95332],
                [-69.290283, 58.977211],
                [-69.35556, 58.949711],
                [-69.278343, 59.066669],
                [-69.357224, 59.139721],
                [-69.275284, 59.154991],
                [-69.182503, 59.128601]
              ]
            ],
            [
              [
                [-79.496384, 56.766941],
                [-79.486107, 56.658039],
                [-79.523621, 56.66721],
                [-79.567497, 56.615269],
                [-79.583893, 56.652771],
                [-79.549438, 56.68111],
                [-79.551941, 56.73193],
                [-79.589996, 56.76833],
                [-79.58168, 56.807209],
                [-79.496384, 56.766941]
              ]
            ],
            [
              [
                [-78.900284, 56.319439],
                [-79.061394, 56.124439],
                [-79.210831, 55.856098],
                [-79.188599, 55.942211],
                [-78.986656, 56.26749],
                [-78.983322, 56.389721],
                [-79.055267, 56.34444],
                [-79.100281, 56.221371],
                [-79.04834, 56.222759],
                [-79.061394, 56.18832],
                [-79.283333, 55.864441],
                [-79.133904, 56.126659],
                [-79.150558, 56.233051],
                [-79.243881, 56.1511],
                [-79.360283, 55.969711],
                [-79.493057, 55.858891],
                [-79.636124, 55.898609],
                [-79.785553, 55.788891],
                [-79.474716, 56.098331],
                [-79.513062, 56.134991],
                [-79.597229, 56.091381],
                [-79.877213, 55.83194],
                [-79.906113, 55.824711],
                [-79.947487, 55.889992],
                [-80.024719, 55.901379],
                [-79.74472, 56.15638],
                [-79.529167, 56.275551],
                [-79.475563, 56.38055],
                [-79.456673, 56.553322],
                [-79.419449, 56.4436],
                [-79.480003, 56.346378],
                [-79.491096, 56.264439],
                [-79.609734, 56.13166],
                [-79.427223, 56.203049],
                [-79.301102, 56.447208],
                [-79.269173, 56.624989],
                [-79.252792, 56.550831],
                [-79.133057, 56.54277],
                [-79.075562, 56.444149],
                [-78.928879, 56.425831],
                [-78.908051, 56.343048],
                [-78.951401, 56.316101],
                [-78.900284, 56.319439]
              ]
            ],
            [
              [
                [-79.555557, 56.4986],
                [-79.701111, 56.306381],
                [-79.986107, 56.199711],
                [-80.109734, 56.197769],
                [-80.044449, 56.310822],
                [-79.869164, 56.331669],
                [-79.877213, 56.379162],
                [-79.698883, 56.368881],
                [-79.614441, 56.43166],
                [-79.585281, 56.499161],
                [-79.543327, 56.527771],
                [-79.555557, 56.4986]
              ]
            ],
            [
              [
                [-78.660828, 56.414711],
                [-78.676392, 56.18111],
                [-78.933319, 56.115551],
                [-78.916946, 56.172489],
                [-78.825287, 56.262772],
                [-78.845551, 56.276661],
                [-78.809433, 56.338879],
                [-78.832497, 56.35083],
                [-78.731667, 56.44054],
                [-78.667221, 56.439709],
                [-78.660828, 56.414711]
              ]
            ],
            [
              [
                [-79.009354, 56.06361],
                [-78.957497, 56.083599],
                [-78.948883, 56.03722],
                [-79.002792, 56.012772],
                [-78.934158, 56.020271],
                [-79.002502, 55.979988],
                [-79.054443, 55.865551],
                [-79.135559, 55.789162],
                [-79.030838, 55.968601],
                [-79.009354, 56.06361]
              ]
            ],
            [
              [
                [-63.492229, 49.840832],
                [-62.944439, 49.732208],
                [-62.510281, 49.590832],
                [-62.39389, 49.492222],
                [-62.25111, 49.456661],
                [-62.20583, 49.411381],
                [-61.875561, 49.34499],
                [-61.661671, 49.14444],
                [-61.70055, 49.090271],
                [-61.81361, 49.06694],
                [-62.242771, 49.06221],
                [-62.456951, 49.125549],
                [-63.09, 49.228039],
                [-63.283329, 49.317768],
                [-63.5825, 49.387211],
                [-63.61972, 49.492771],
                [-63.841942, 49.63916],
                [-64.238602, 49.751659],
                [-64.362778, 49.82444],
                [-64.389999, 49.789719],
                [-64.513901, 49.863609],
                [-64.458618, 49.900829],
                [-64.203613, 49.950272],
                [-63.930828, 49.885269],
                [-63.492229, 49.840832]
              ]
            ],
            [
              [
                [-61.40778, 47.641109],
                [-61.38055, 47.62027],
                [-61.493061, 47.55249],
                [-61.54945, 47.553879],
                [-61.470551, 47.61694],
                [-61.54195, 47.61721],
                [-61.690109, 47.534672],
                [-61.711842, 47.518051],
                [-61.85556, 47.41721],
                [-61.777222, 47.43166],
                [-61.674999, 47.535],
                [-61.59444, 47.555271],
                [-61.701389, 47.49194],
                [-61.763889, 47.397491],
                [-61.829731, 47.401661],
                [-61.860001, 47.352489],
                [-61.933331, 47.333328],
                [-61.9575, 47.26194],
                [-61.908329, 47.27277],
                [-61.81945, 47.23333],
                [-61.783058, 47.260551],
                [-61.79945, 47.232769],
                [-61.85931, 47.217739],
                [-62.009998, 47.221371],
                [-61.928051, 47.407211],
                [-61.535839, 47.63166],
                [-61.40778, 47.641109]
              ]
            ],
            [
              [
                [-70.814438, 46.998329],
                [-70.904167, 46.913601],
                [-71.13028, 46.856098],
                [-70.896667, 47.013329],
                [-70.836403, 47.027771],
                [-70.814438, 46.998329]
              ]
            ],
            [
              [
                [-57.10424, 51.41267],
                [-57.208611, 51.415821],
                [-57.247219, 51.504169],
                [-57.60223, 51.428051],
                [-57.581669, 51.465271],
                [-57.67667, 51.429989],
                [-57.684441, 51.47694],
                [-57.748051, 51.47221],
                [-57.76445, 51.401661],
                [-57.800831, 51.433331],
                [-58.021389, 51.30888],
                [-58.227219, 51.271381],
                [-58.26112, 51.288052],
                [-58.249168, 51.332771],
                [-58.283058, 51.335819],
                [-58.29723, 51.2686],
                [-58.430279, 51.309719],
                [-58.580002, 51.24416],
                [-58.628052, 51.275551],
                [-58.61972, 51.231659],
                [-58.667221, 51.25639],
                [-58.68, 51.234161],
                [-58.59222, 51.184719],
                [-58.61861, 51.153049],
                [-58.83778, 51.0825],
                [-58.829731, 51.041111],
                [-58.889999, 50.992222],
                [-58.91333, 51],
                [-58.89806, 51.053879],
                [-59.00333, 51.015549],
                [-58.952499, 50.992489],
                [-58.958611, 50.92194],
                [-58.939442, 50.883331],
                [-58.975269, 50.857769],
                [-58.948051, 50.828331],
                [-59.029171, 50.775551],
                [-59.015282, 50.7486],
                [-59.087219, 50.775269],
                [-59.09333, 50.820831],
                [-59.055271, 50.870831],
                [-59.098881, 50.879429],
                [-59.118889, 50.8036],
                [-59.193611, 50.72971],
                [-59.256111, 50.75972],
                [-59.268608, 50.71249],
                [-59.350281, 50.662769],
                [-59.423889, 50.661381],
                [-59.404999, 50.63472],
                [-59.454441, 50.621929],
                [-59.58527, 50.478039],
                [-59.816109, 50.43388],
                [-59.881672, 50.371658],
                [-59.853889, 50.326099],
                [-59.814999, 50.349159],
                [-59.833889, 50.31916],
                [-59.948051, 50.255001],
                [-60.114449, 50.233051],
                [-60.134171, 50.20388],
                [-60.132771, 50.22971],
                [-60.17223, 50.231659],
                [-60.14999, 50.288601],
                [-60.243328, 50.232208],
                [-60.26722, 50.236111],
                [-60.23695, 50.268051],
                [-60.274719, 50.288891],
                [-60.291672, 50.24527],
                [-60.360001, 50.250832],
                [-60.35611, 50.22332],
                [-60.448051, 50.259441],
                [-60.583611, 50.208328],
                [-60.61972, 50.246941],
                [-60.666111, 50.220268],
                [-60.748341, 50.23444],
                [-60.788609, 50.201099],
                [-60.840549, 50.219151],
                [-60.792782, 50.261669],
                [-60.934441, 50.1936],
                [-61.02639, 50.225819],
                [-61.112782, 50.187771],
                [-61.15583, 50.202221],
                [-61.162498, 50.17749],
                [-61.297501, 50.199162],
                [-61.720829, 50.09193],
                [-61.783058, 50.106941],
                [-61.59306, 50.15527],
                [-61.577221, 50.1861],
                [-61.790279, 50.13361],
                [-61.849998, 50.21249],
                [-62.21056, 50.234161],
                [-62.397499, 50.294441],
                [-62.44611, 50.2575],
                [-62.73695, 50.282211],
                [-62.712502, 50.315269],
                [-62.805271, 50.288601],
                [-62.963058, 50.285549],
                [-62.994999, 50.311939],
                [-63.163891, 50.291382],
                [-63.159161, 50.254711],
                [-63.358608, 50.241661],
                [-63.394718, 50.213329],
                [-63.488609, 50.26194],
                [-63.597778, 50.250832],
                [-63.812771, 50.313049],
                [-64.162216, 50.262211],
                [-64.455002, 50.319439],
                [-64.872223, 50.267208],
                [-64.91362, 50.2925],
                [-65.189987, 50.286659],
                [-65.258057, 50.31889],
                [-65.713898, 50.257221],
                [-65.952499, 50.288891],
                [-66.056381, 50.216099],
                [-66.093338, 50.244438],
                [-66.089722, 50.19138],
                [-66.235817, 50.21888],
                [-66.369164, 50.193321],
                [-66.441673, 50.269161],
                [-66.543327, 50.20805],
                [-66.527222, 50.16972],
                [-66.42778, 50.164711],
                [-66.459442, 50.133049],
                [-66.638062, 50.181381],
                [-66.61972, 50.149158],
                [-66.741096, 50.042221],
                [-66.958618, 49.974709],
                [-66.963333, 49.914711],
                [-67.037514, 49.820831],
                [-67.073898, 49.848049],
                [-67.151398, 49.806938],
                [-67.179718, 49.74527],
                [-67.163063, 49.679722],
                [-67.198883, 49.651661],
                [-67.238892, 49.590549],
                [-67.233887, 49.470268],
                [-67.386124, 49.32222],
                [-67.569733, 49.32999],
                [-67.809723, 49.273609],
                [-67.957779, 49.295269],
                [-68.11972, 49.27166],
                [-68.156387, 49.20166],
                [-68.299438, 49.195541],
                [-68.19249, 49.164711],
                [-68.189713, 49.112209],
                [-68.325562, 49.09972],
                [-68.408051, 49.05555],
                [-68.444443, 49.059719],
                [-68.367767, 49.132771],
                [-68.443604, 49.191662],
                [-68.404167, 49.122761],
                [-68.606949, 49.0425],
                [-68.643341, 48.9561],
                [-68.696381, 48.939991],
                [-68.642227, 48.91666],
                [-68.787781, 48.907768],
                [-68.95639, 48.81583],
                [-68.96666, 48.782768],
                [-69.060822, 48.76749],
                [-69.142502, 48.59499],
                [-69.231377, 48.585548],
                [-69.29277, 48.457771],
                [-69.43277, 48.30777],
                [-69.57695, 48.23999],
                [-69.683884, 48.137772],
                [-69.810272, 48.155819],
                [-69.959442, 48.26944],
                [-70.151108, 48.274441],
                [-70.373322, 48.374161],
                [-70.684433, 48.3811],
                [-70.761398, 48.431938],
                [-70.98056, 48.462212],
                [-71.070572, 48.440109],
                [-70.969727, 48.447208],
                [-70.785553, 48.395828],
                [-70.887222, 48.358601],
                [-70.864166, 48.317211],
                [-70.768066, 48.350552],
                [-70.464172, 48.349159],
                [-70.272781, 48.298328],
                [-70.180557, 48.241379],
                [-69.936943, 48.221931],
                [-69.860001, 48.143879],
                [-69.730293, 48.10944],
                [-69.925827, 47.773048],
                [-70.139183, 47.659161],
                [-70.206123, 47.57666],
                [-70.225563, 47.49638],
                [-70.5, 47.43499],
                [-70.574173, 47.274441],
                [-70.721657, 47.101391],
                [-70.973618, 47.00333],
                [-71.197487, 46.852489],
                [-71.299156, 46.742222],
                [-71.143623, 46.844711],
                [-71.006119, 46.847771],
                [-70.541382, 47.002491],
                [-70.339172, 47.152489],
                [-70.201401, 47.312489],
                [-70.044159, 47.390831],
                [-70.017776, 47.487499],
                [-69.896393, 47.541111],
                [-69.545837, 47.8325],
                [-69.556381, 47.866661],
                [-69.450562, 47.97916],
                [-69.34111, 48.01416],
                [-68.823334, 48.36388],
                [-68.726936, 48.37249],
                [-68.541382, 48.451389],
                [-68.453613, 48.53249],
                [-68.058609, 48.691101],
                [-67.973618, 48.695541],
                [-67.553047, 48.85471],
                [-67.067497, 48.96693],
                [-66.639183, 49.120831],
                [-66.225014, 49.200829],
                [-65.722229, 49.227211],
                [-65.588058, 49.260551],
                [-64.94249, 49.214439],
                [-64.588058, 49.112209],
                [-64.23555, 48.910271],
                [-64.158051, 48.7561],
                [-64.298889, 48.823879],
                [-64.557503, 48.880268],
                [-64.473328, 48.832211],
                [-64.539436, 48.821381],
                [-64.401108, 48.81694],
                [-64.374161, 48.787769],
                [-64.428329, 48.774441],
                [-64.336937, 48.75555],
                [-64.160004, 48.622761],
                [-64.26973, 48.60471],
                [-64.279167, 48.554161],
                [-64.189163, 48.520828],
                [-64.309433, 48.463329],
                [-64.315552, 48.419159],
                [-64.719162, 48.338329],
                [-64.693604, 48.3111],
                [-64.771393, 48.196381],
                [-64.96611, 48.18499],
                [-64.945831, 48.143051],
                [-65.269997, 48.012772],
                [-65.455566, 48.000271],
                [-65.504181, 48.048882],
                [-65.810547, 48.116661],
                [-65.891678, 48.171101],
                [-65.893341, 48.21944],
                [-66.080002, 48.096378],
                [-66.293327, 48.113049],
                [-66.268066, 48.095829],
                [-66.29361, 48.063881],
                [-66.47084, 48.119438],
                [-66.538063, 48.05582],
                [-66.843697, 47.996651],
                [-66.943604, 47.963051],
                [-66.946953, 47.899158],
                [-67.047234, 47.93082],
                [-67.188599, 47.883881],
                [-67.322777, 47.896389],
                [-67.364166, 47.841381],
                [-67.604172, 47.9361],
                [-67.612213, 47.99749],
                [-68.116096, 48.001942],
                [-68.12027, 47.926659],
                [-68.372772, 47.924709],
                [-68.378052, 47.65416],
                [-68.38028, 47.558601],
                [-68.576111, 47.424709],
                [-69.039711, 47.29715],
                [-69.047478, 47.421989],
                [-69.230789, 47.4533],
                [-69.985458, 46.69133],
                [-70.014633, 46.57056],
                [-70.047089, 46.426079],
                [-70.191544, 46.334801],
                [-70.283974, 46.190208],
                [-70.229797, 46.137402],
                [-70.305328, 46.06662],
                [-70.280502, 46.05312],
                [-70.310783, 45.968739],
                [-70.247948, 45.94458],
                [-70.254448, 45.89896],
                [-70.416687, 45.79026],
                [-70.396873, 45.722],
                [-70.55275, 45.660622],
                [-70.72039, 45.512909],
                [-70.635406, 45.391918],
                [-70.797447, 45.425129],
                [-70.829613, 45.390678],
                [-70.813148, 45.35463],
                [-70.843361, 45.278091],
                [-70.87693, 45.225399],
                [-70.959862, 45.338821],
                [-71.08799, 45.301418],
                [-71.153572, 45.237919],
                [-71.297707, 45.293449],
                [-71.386864, 45.23489],
                [-71.447037, 45.236038],
                [-71.403, 45.202759],
                [-71.430893, 45.116951],
                [-71.503471, 45.059841],
                [-71.505836, 45.013309],
                [-71.902328, 45.00729],
                [-72.547668, 45.005329],
                [-73.188972, 45.00845],
                [-73.345154, 45.0061],
                [-74.021927, 44.990799],
                [-74.736481, 44.99287],
                [-74.506393, 45.071659],
                [-74.433609, 45.149441],
                [-74.324722, 45.201099],
                [-74.479172, 45.316669],
                [-74.396667, 45.564159],
                [-74.646118, 45.635551],
                [-74.875839, 45.64159],
                [-75.453339, 45.523609],
                [-75.847229, 45.37249],
                [-76.076683, 45.513889],
                [-76.184433, 45.521099],
                [-76.337784, 45.45583],
                [-76.607773, 45.55249],
                [-76.666397, 45.590549],
                [-76.710564, 45.712769],
                [-76.783333, 45.76194],
                [-76.804718, 45.864719],
                [-76.910553, 45.889992],
                [-76.920837, 45.806381],
                [-77.002792, 45.790279],
                [-77.206123, 45.877209],
                [-77.278877, 45.937771],
                [-77.321953, 46.026661],
                [-77.554993, 46.151932],
                [-77.680557, 46.183601],
                [-78.703613, 46.337212],
                [-78.75528, 46.400539],
                [-78.950562, 46.511669],
                [-79.155563, 46.812489],
                [-79.305557, 46.94054],
                [-79.443878, 47.11055],
                [-79.439438, 47.26527],
                [-79.576401, 47.441929],
                [-79.511322, 47.565571],
                [-79.519012, 48.605289],
                [-79.520683, 48.764679],
                [-79.537613, 50.958599],
                [-79.355827, 50.777489],
                [-79.348053, 50.73193],
                [-79.330002, 50.758331],
                [-79.383987, 50.836269],
                [-79.421944, 50.880859],
                [-79.537804, 50.982368],
                [-79.539436, 51.191662],
                [-79.547096, 51.460129],
                [-79.508347, 51.468601],
                [-79.551392, 51.553051],
                [-79.353882, 51.656101],
                [-79.239723, 51.634991],
                [-79.285278, 51.562489],
                [-79.274719, 51.530548],
                [-79.127487, 51.538052],
                [-79.024437, 51.476379],
                [-78.938316, 51.285831],
                [-78.951401, 51.215549],
                [-78.844727, 51.163601],
                [-78.920547, 51.231098],
                [-78.904449, 51.305271],
                [-78.823624, 51.34943],
                [-78.888344, 51.39666],
                [-78.763634, 51.486382],
                [-78.688599, 51.48444],
                [-78.820282, 51.51305],
                [-78.804001, 51.61021],
                [-78.943047, 51.658878],
                [-78.938316, 51.694439],
                [-79.033333, 51.776379],
                [-78.836937, 51.841381],
                [-78.894173, 51.93832],
                [-78.747772, 51.97332],
                [-78.695831, 52.008049],
                [-78.704178, 52.0536],
                [-78.638062, 52.057209],
                [-78.648064, 52.085819],
                [-78.579453, 52.111382],
                [-78.537514, 52.18082],
                [-78.556381, 52.23555],
                [-78.405838, 52.23777],
                [-78.451683, 52.271381],
                [-78.556664, 52.272221],
                [-78.497223, 52.358601],
                [-78.544159, 52.435822],
                [-78.506958, 52.460548],
                [-78.566391, 52.485271],
                [-78.545273, 52.514721],
                [-78.763344, 52.564442],
                [-78.69194, 52.5961],
                [-78.730003, 52.615002],
                [-78.725281, 52.648048],
                [-78.778877, 52.653599],
                [-78.754997, 52.695271],
                [-78.820847, 52.703609],
                [-78.771393, 52.709721],
                [-78.783333, 52.734718],
                [-78.850281, 52.764721],
                [-78.724716, 52.785549],
                [-78.728882, 52.823879],
                [-78.780838, 52.81805],
                [-78.68721, 52.87999],
                [-78.85611, 52.877769],
                [-78.899727, 52.922771],
                [-78.864723, 52.963612],
                [-78.786667, 52.96944],
                [-78.826111, 52.978039],
                [-78.845551, 53.01194],
                [-78.983063, 53.023609],
                [-78.918327, 53.052769],
                [-78.974716, 53.069439],
                [-78.915558, 53.165272],
                [-78.944443, 53.182499],
                [-78.905563, 53.191929],
                [-78.92749, 53.218601],
                [-78.888062, 53.224709],
                [-78.920273, 53.247768],
                [-78.895844, 53.26527],
                [-78.956673, 53.283611],
                [-78.941101, 53.379162],
                [-78.988052, 53.4436],
                [-79.063316, 53.43943],
                [-79.051392, 53.457211],
                [-79.110283, 53.502491],
                [-79.012787, 53.531109],
                [-79.041382, 53.50555],
                [-79.001404, 53.490829],
                [-78.959167, 53.51416],
                [-79.031113, 53.560822],
                [-78.915833, 53.560551],
                [-78.984734, 53.614719],
                [-79.063049, 53.596939],
                [-79.003342, 53.641659],
                [-79.04361, 53.6675],
                [-79.011124, 53.683601],
                [-79.151398, 53.70499],
                [-79.036392, 53.72971],
                [-79.067497, 53.764439],
                [-79.042503, 53.784721],
                [-79.06778, 53.80249],
                [-79.020554, 53.821941],
                [-79.046951, 53.835548],
                [-78.901398, 53.815269],
                [-79.0625, 53.856659],
                [-79.059998, 53.889721],
                [-79.101669, 53.909988],
                [-78.993332, 53.91193],
                [-79.136398, 53.956661],
                [-79.033073, 53.946381],
                [-79.093887, 53.963329],
                [-79.044724, 53.974991],
                [-79.097778, 53.97887],
                [-79.001099, 53.999989],
                [-78.964951, 53.997162],
                [-78.976669, 54.01833],
                [-79.077499, 54.037498],
                [-79.126938, 54.081108],
                [-79.286942, 54.082211],
                [-79.175278, 54.086651],
                [-79.16777, 54.117771],
                [-79.116386, 54.10305],
                [-79.130829, 54.124989],
                [-79.098053, 54.12888],
                [-79.138344, 54.144711],
                [-79.048889, 54.183331],
                [-79.205841, 54.154991],
                [-79.379707, 54.1936],
                [-79.283333, 54.248878],
                [-79.402222, 54.263611],
                [-79.438599, 54.276379],
                [-79.419159, 54.30138],
                [-79.461121, 54.304161],
                [-79.472778, 54.364441],
                [-79.506958, 54.370831],
                [-79.446663, 54.416939],
                [-79.501404, 54.421379],
                [-79.488052, 54.458599],
                [-79.548607, 54.504711],
                [-79.524719, 54.523048],
                [-79.602783, 54.555271],
                [-79.521393, 54.587212],
                [-79.576401, 54.60944],
                [-79.630547, 54.57111],
                [-79.596657, 54.63916],
                [-79.675552, 54.62582],
                [-79.662514, 54.66777],
                [-79.761124, 54.658329],
                [-79.636124, 54.698879],
                [-79.62999, 54.740551],
                [-79.619164, 54.711109],
                [-79.240829, 54.81221],
                [-78.976669, 54.843048],
                [-78.862778, 54.9086],
                [-78.335564, 55.03944],
                [-77.760277, 55.287769],
                [-77.65834, 55.37027],
                [-77.214722, 55.595268],
                [-77.086121, 55.705551],
                [-77.18306, 55.689709],
                [-76.840286, 55.928879],
                [-76.797501, 55.990269],
                [-76.658051, 56.060822],
                [-76.619453, 56.185822],
                [-76.53833, 56.297779],
                [-76.51889, 56.406101],
                [-76.505569, 56.803051],
                [-76.555832, 57.035],
                [-76.529716, 57.105831],
                [-76.653061, 57.401379],
                [-76.811394, 57.62471],
                [-76.806107, 57.669159],
                [-77.147232, 58.02277],
                [-77.487503, 58.165272],
                [-77.444153, 58.187771],
                [-77.609444, 58.269989],
                [-78.012512, 58.378601],
                [-78.013062, 58.413052],
                [-78.074173, 58.454441],
                [-78.120003, 58.446941],
                [-78.268623, 58.519161],
                [-78.397232, 58.620831],
                [-78.426102, 58.606098],
                [-78.327499, 58.53722],
                [-78.383904, 58.54166],
                [-78.47139, 58.61166],
                [-78.568619, 58.614441],
                [-78.549438, 58.660271],
                [-78.570007, 58.67305],
                [-78.467216, 58.70166],
                [-78.549438, 58.767769],
                [-78.495003, 58.77721],
                [-78.488052, 58.820831],
                [-78.549438, 58.91082],
                [-78.530289, 58.938881],
                [-78.570557, 58.96138],
                [-78.450844, 58.909988],
                [-78.43721, 58.952221],
                [-78.361656, 58.958599],
                [-78.363892, 58.912491],
                [-78.338333, 58.912769],
                [-78.245003, 58.99194],
                [-78.316963, 58.98555],
                [-78.269173, 59.016941],
                [-78.283333, 59.049999],
                [-78.205566, 59.050541],
                [-78.21611, 59.106941],
                [-78.178329, 59.08527],
                [-78.127487, 59.10833],
                [-78.153343, 59.15221],
                [-78.08667, 59.156651],
                [-78.092773, 59.215],
                [-77.828888, 59.281109],
                [-77.890839, 59.310822],
                [-77.778061, 59.32333],
                [-77.764717, 59.351662],
                [-77.800827, 59.368599],
                [-77.684998, 59.39333],
                [-77.779167, 59.426102],
                [-77.912514, 59.415272],
                [-77.848618, 59.446098],
                [-77.867493, 59.5],
                [-77.72139, 59.539719],
                [-77.712784, 59.620831],
                [-77.801102, 59.67527],
                [-77.741943, 59.691101],
                [-77.773903, 59.709721],
                [-77.670273, 59.674438],
                [-77.570282, 59.69582],
                [-77.473053, 59.634991],
                [-77.454178, 59.579159],
                [-77.313889, 59.564991],
                [-77.440552, 59.59972],
                [-77.452499, 59.663052],
                [-77.541672, 59.688881],
                [-77.491943, 59.71249],
                [-77.533073, 59.754711],
                [-77.472504, 59.742771],
                [-77.43277, 59.784161],
                [-77.29361, 59.79361],
                [-77.363617, 59.890831],
                [-77.42749, 59.914711],
                [-77.300278, 59.965],
                [-77.323059, 59.98888],
                [-77.236389, 59.994709],
                [-77.313889, 60.012501],
                [-77.206947, 60.04277],
                [-77.189987, 60.086109],
                [-77.059433, 60.062771],
                [-76.958054, 60.10305],
                [-76.858887, 60.101101],
                [-76.788597, 60.068329],
                [-76.833618, 60.115002],
                [-76.758904, 60.159161],
                [-76.924438, 60.11166],
                [-77.199997, 60.150829],
                [-77.105003, 60.108601],
                [-77.259171, 60.11805],
                [-77.232498, 60.053879],
                [-77.315826, 60.030548],
                [-77.358047, 60.060822],
                [-77.433319, 60.036381],
                [-77.630829, 60.065269],
                [-77.558037, 60.126659],
                [-77.406387, 60.130268],
                [-77.551392, 60.19471],
                [-77.470284, 60.213329],
                [-77.573624, 60.25972],
                [-77.543327, 60.285831],
                [-77.623322, 60.26833],
                [-77.602783, 60.32999],
                [-77.743607, 60.39333],
                [-77.740829, 60.423611],
                [-77.574448, 60.502491],
                [-77.553879, 60.542221],
                [-77.41333, 60.544159],
                [-77.549438, 60.571381],
                [-77.670273, 60.549721],
                [-77.833893, 60.639431],
                [-77.607773, 60.758331],
                [-77.745003, 60.76416],
                [-77.511673, 60.83638],
                [-77.890289, 60.75],
                [-77.858337, 60.791939],
                [-77.965843, 60.791382],
                [-77.896393, 60.828049],
                [-78.190552, 60.788891],
                [-78.159439, 60.86721],
                [-77.946663, 61.004711],
                [-77.811111, 61.052219],
                [-77.790283, 61.151379],
                [-77.694443, 61.20277],
                [-77.752228, 61.229431],
                [-77.719162, 61.26527],
                [-77.79277, 61.45388],
                [-77.700287, 61.426102],
                [-77.678879, 61.461109],
                [-77.560547, 61.468048],
                [-77.54834, 61.486111],
                [-77.670273, 61.519161],
                [-77.621109, 61.5611],
                [-77.474716, 61.54166],
                [-77.631943, 61.579441],
                [-77.589172, 61.604439],
                [-77.710564, 61.605831],
                [-77.785828, 61.700001],
                [-77.898903, 61.686378],
                [-78.006119, 61.733051],
                [-78.003067, 61.77972],
                [-78.08139, 61.865829],
                [-78.08139, 61.951099],
                [-78.137787, 62.009159],
                [-78.161667, 62.169159],
                [-78.155838, 62.278332],
                [-78.023903, 62.39333],
                [-77.70195, 62.470268],
                [-77.486938, 62.590271],
                [-77.418327, 62.58971],
                [-77.374161, 62.551929],
                [-77.04834, 62.519161],
                [-76.948883, 62.535831],
                [-76.143066, 62.379162],
                [-76.097778, 62.340549],
                [-75.949722, 62.350269],
                [-75.645844, 62.291939],
                [-75.592216, 62.264439],
                [-75.836937, 62.187771],
                [-75.894997, 62.1586],
                [-75.82695, 62.158878],
                [-75.486938, 62.296391],
                [-75.321953, 62.3111],
                [-75.152496, 62.284721],
                [-75.152786, 62.227489],
                [-75.066673, 62.26833],
                [-74.928329, 62.249161],
                [-74.765007, 62.14444],
                [-74.556381, 62.10471],
                [-74.751106, 62.191101],
                [-74.72583, 62.244999],
                [-74.410004, 62.251389],
                [-74.280563, 62.321659],
                [-74, 62.358051],
                [-73.868057, 62.458328],
                [-73.678879, 62.479988],
                [-73.5625, 62.377769],
                [-73.387512, 62.36916],
                [-73.211403, 62.312771],
                [-73.204178, 62.261379],
                [-73.063606, 62.182499],
                [-72.903877, 62.142769],
                [-72.94249, 62.11805],
                [-72.784439, 62.106941],
                [-72.815002, 62.137772],
                [-72.736938, 62.14333],
                [-72.626663, 62.115551],
                [-72.617493, 62.09193],
                [-72.661942, 62.05666],
                [-72.596123, 62.04916],
                [-72.618332, 62.033611],
                [-72.604446, 61.9711],
                [-72.678879, 61.949429],
                [-72.680832, 61.900269],
                [-72.794197, 61.834751],
                [-72.66806, 61.879162],
                [-72.669159, 61.847771],
                [-72.596123, 61.80555],
                [-72.636124, 61.90221],
                [-72.59584, 61.94276],
                [-72.205841, 61.863331],
                [-72.12999, 61.79916],
                [-72.200287, 61.789162],
                [-72.224716, 61.751659],
                [-72.145844, 61.75388],
                [-72.061661, 61.73555],
                [-71.99028, 61.67638],
                [-72.087784, 61.598881],
                [-72.236656, 61.619438],
                [-72.303329, 61.567211],
                [-72.210831, 61.598331],
                [-72.08371, 61.582458],
                [-71.980293, 61.599998],
                [-71.933609, 61.663601],
                [-71.958054, 61.70694],
                [-71.675278, 61.658878],
                [-71.571671, 61.605549],
                [-71.549438, 61.55888],
                [-71.855003, 61.530548],
                [-71.789436, 61.521931],
                [-71.795273, 61.492489],
                [-71.746948, 61.465832],
                [-71.887787, 61.43082],
                [-71.585281, 61.406651],
                [-71.689163, 61.36832],
                [-71.822777, 61.372761],
                [-71.792503, 61.357498],
                [-71.818619, 61.346661],
                [-71.664436, 61.32777],
                [-71.72583, 61.285831],
                [-71.598892, 61.254169],
                [-71.62999, 61.204708],
                [-71.569458, 61.214439],
                [-71.578339, 61.15527],
                [-71.512222, 61.161659],
                [-71.530838, 61.213612],
                [-71.473328, 61.223049],
                [-71.370003, 61.118599],
                [-71.361389, 61.14777],
                [-71.215843, 61.16777],
                [-71.193329, 61.115829],
                [-71.174713, 61.139992],
                [-71.011398, 61.121658],
                [-70.928329, 61.102489],
                [-70.936111, 61.073051],
                [-70.818069, 61.064442],
                [-70.767227, 61.048328],
                [-70.780563, 61.078331],
                [-70.751404, 61.082211],
                [-70.656387, 61.050541],
                [-70.676941, 61.02166],
                [-70.547501, 61.0075],
                [-70.566391, 61.040829],
                [-70.527222, 61.060822],
                [-70.426392, 61.023319],
                [-70.444992, 61.053879],
                [-70.410004, 61.05471],
                [-70.41362, 61.086651],
                [-70.351936, 61.061378],
                [-70.326401, 61.09388],
                [-70.246658, 61.067501],
                [-70.146118, 61.084721],
                [-70.107773, 61.059719],
                [-70.159729, 61.014439],
                [-70.09639, 61.009991],
                [-70.114441, 60.985271],
                [-70.085831, 60.95499],
                [-70.088058, 60.89777],
                [-70.147781, 60.87999],
                [-70.067497, 60.875271],
                [-70.061111, 60.84444],
                [-69.92749, 60.80777],
                [-69.849731, 60.846661],
                [-69.93306, 60.850552],
                [-69.756958, 60.91888],
                [-69.740547, 60.881378],
                [-69.658623, 60.876942],
                [-69.64389, 60.90332],
                [-69.689713, 60.961658],
                [-69.656662, 61.0536],
                [-69.599991, 61.08194],
                [-69.51445, 61.069439],
                [-69.426392, 60.923611],
                [-69.366096, 60.90694],
                [-69.391113, 60.871929],
                [-69.368607, 60.8111],
                [-69.71611, 60.68277],
                [-69.685822, 60.64444],
                [-69.696663, 60.598049],
                [-69.656952, 60.574711],
                [-69.826111, 60.525551],
                [-69.748894, 60.461658],
                [-69.776398, 60.415539],
                [-69.730003, 60.404991],
                [-69.722504, 60.364159],
                [-69.768066, 60.31221],
                [-69.606377, 60.232769],
                [-69.651108, 60.183331],
                [-69.594162, 60.18082],
                [-69.62471, 60.067501],
                [-69.7164, 60.04166],
                [-69.841949, 60.063049],
                [-69.841377, 60.023048],
                [-69.770279, 60.001942],
                [-69.794037, 59.98941],
                [-70.33168, 60.028332],
                [-70.315002, 60.005001],
                [-70.556953, 59.992771],
                [-71.030563, 60.061939],
                [-70.566963, 59.968601],
                [-70.227219, 59.98666],
                [-70.061394, 59.944988],
                [-69.726936, 59.963612],
                [-69.717499, 59.923882],
                [-69.759171, 59.902489],
                [-69.562767, 59.86805],
                [-69.612213, 59.784439],
                [-69.545837, 59.763611],
                [-69.654167, 59.697769],
                [-69.519447, 59.653049],
                [-69.571953, 59.590271],
                [-69.679443, 59.56361],
                [-69.748337, 59.509991],
                [-69.759453, 59.481098],
                [-69.697487, 59.480549],
                [-69.645279, 59.419159],
                [-69.66777, 59.386662],
                [-69.631378, 59.374989],
                [-69.758347, 59.320271],
                [-69.631104, 59.298882],
                [-69.412514, 59.355],
                [-69.362503, 59.339989],
                [-69.367218, 59.307499],
                [-69.249733, 59.323608],
                [-69.266403, 59.283329],
                [-69.235001, 59.239429],
                [-69.29277, 59.208328],
                [-69.371933, 59.240551],
                [-69.373894, 59.18943],
                [-69.419159, 59.19249],
                [-69.420837, 59.223049],
                [-69.470284, 59.213879],
                [-69.540283, 59.166382],
                [-69.533073, 59.110821],
                [-69.501709, 59.106331],
                [-69.463058, 59.129429],
                [-69.344162, 59.091099],
                [-69.439713, 59.02277],
                [-69.506958, 59.069721],
                [-69.454178, 58.89222],
                [-69.518341, 58.885551],
                [-69.547501, 58.808041],
                [-69.670837, 58.792221],
                [-69.71611, 58.864719],
                [-69.672234, 58.89138],
                [-69.671661, 58.93055],
                [-69.711937, 58.91888],
                [-69.709442, 58.972759],
                [-69.756668, 58.98999],
                [-69.78833, 58.96693],
                [-69.869164, 59.053051],
                [-69.861107, 58.984989],
                [-69.895554, 58.957771],
                [-69.80722, 58.931938],
                [-69.797501, 58.82888],
                [-69.898354, 58.873322],
                [-69.899986, 58.82777],
                [-70.25, 58.769711],
                [-70.036392, 58.733601],
                [-69.919724, 58.77388],
                [-69.925003, 58.69249],
                [-69.79834, 58.704708],
                [-69.876663, 58.643608],
                [-69.813049, 58.589161],
                [-69.700287, 58.697208],
                [-69.642776, 58.695541],
                [-69.608047, 58.754711],
                [-69.507507, 58.774712],
                [-69.348892, 58.871658],
                [-69.12999, 58.901661],
                [-68.889183, 58.876942],
                [-68.763062, 58.921101],
                [-68.390694, 58.81171],
                [-68.355827, 58.774441],
                [-68.380547, 58.697769],
                [-68.326683, 58.664711],
                [-68.348343, 58.600552],
                [-68.274437, 58.590549],
                [-68.288887, 58.524712],
                [-68.193604, 58.540829],
                [-68.223587, 58.397831],
                [-68.31723, 58.241661],
                [-68.344162, 58.127491],
                [-68.46666, 58.045551],
                [-68.964722, 57.95916],
                [-69.202499, 57.868599],
                [-69.369049, 57.765251],
                [-68.951401, 57.938599],
                [-68.404167, 58.039719],
                [-68.313606, 58.10305],
                [-68.303329, 58.195541],
                [-68.23056, 58.25555],
                [-68.168327, 58.414711],
                [-68.171387, 58.48999],
                [-68.125397, 58.526058],
                [-68.026672, 58.51305],
                [-68.003342, 58.576389],
                [-67.959167, 58.558041],
                [-67.958344, 58.51194],
                [-67.896118, 58.500549],
                [-67.923317, 58.403049],
                [-67.855827, 58.314991],
                [-67.897232, 58.25333],
                [-68.09584, 58.138599],
                [-68.128326, 58.073608],
                [-68.006668, 58.131939],
                [-67.954453, 58.211941],
                [-67.861656, 58.232208],
                [-67.801392, 58.296661],
                [-67.830566, 58.331379],
                [-67.787514, 58.464439],
                [-67.731949, 58.461941],
                [-67.667503, 58.42749],
                [-67.707497, 58.383049],
                [-67.693047, 58.354439],
                [-67.737213, 58.326939],
                [-67.730827, 58.283611],
                [-67.642776, 58.2486],
                [-67.703056, 58.16193],
                [-67.671661, 58.070831],
                [-67.735817, 57.966381],
                [-67.709732, 57.923321],
                [-67.708344, 57.982491],
                [-67.635559, 58.04277],
                [-67.65361, 58.122761],
                [-67.481377, 58.27388],
                [-67.378883, 58.276939],
                [-67.16861, 58.37804],
                [-67.090561, 58.350552],
                [-67.056381, 58.388889],
                [-67.077499, 58.4175],
                [-67.011398, 58.424709],
                [-66.944153, 58.501942],
                [-66.876938, 58.47916],
                [-66.898064, 58.445271],
                [-66.846657, 58.426659],
                [-66.804443, 58.493881],
                [-66.618607, 58.48777],
                [-66.669449, 58.53194],
                [-66.620827, 58.54694],
                [-66.621109, 58.60471],
                [-66.583618, 58.631939],
                [-66.599167, 58.673321],
                [-66.563606, 58.673321],
                [-66.551941, 58.71138],
                [-66.458893, 58.721661],
                [-66.489723, 58.75444],
                [-66.465012, 58.819988],
                [-66.349991, 58.843048],
                [-66.11055, 58.720539],
                [-66.072243, 58.650829],
                [-65.999161, 58.65443],
                [-65.985947, 58.622162],
                [-65.935822, 58.60471],
                [-66.021393, 58.495541],
                [-65.991096, 58.40694],
                [-66.089447, 58.365269],
                [-66.065552, 58.320271],
                [-66.041672, 58.36805],
                [-65.965012, 58.391941],
                [-65.920273, 58.44582],
                [-65.98056, 58.483051],
                [-65.904999, 58.52972],
                [-65.884171, 58.58083],
                [-65.828613, 58.572769],
                [-65.935547, 58.643051],
                [-65.948608, 58.682209],
                [-66.031387, 58.689159],
                [-66.044998, 58.755829],
                [-66.103882, 58.773609],
                [-66.022507, 58.86166],
                [-65.970001, 58.826099],
                [-65.797501, 58.847488],
                [-65.990829, 58.899719],
                [-65.85083, 58.946381],
                [-65.880829, 59.010551],
                [-65.678047, 58.926941],
                [-65.678329, 58.963879],
                [-65.793327, 59.005829],
                [-65.739723, 59.018051],
                [-65.777496, 59.029991],
                [-65.660278, 59.044159],
                [-65.5, 58.98333],
                [-65.58223, 59.049721],
                [-65.317497, 59.041382],
                [-65.54361, 59.101101],
                [-65.638062, 59.069439],
                [-65.715012, 59.148331],
                [-65.684433, 59.19305],
                [-65.737778, 59.209991],
                [-65.743057, 59.265831],
                [-65.676102, 59.261108],
                [-65.591377, 59.188042],
                [-65.610817, 59.253052],
                [-65.492493, 59.255001],
                [-65.565552, 59.30138],
                [-65.570847, 59.37804],
                [-65.357224, 59.27721],
                [-65.444992, 59.351391],
                [-65.430832, 59.403599],
                [-65.383057, 59.401661],
                [-65.49527, 59.43388],
                [-65.469727, 59.444149],
                [-65.542221, 59.48999],
                [-65.188599, 59.45916],
                [-65.126663, 59.407768],
                [-64.983322, 59.376381],
                [-65.22084, 59.488331],
                [-65.411667, 59.509441],
                [-65.54361, 59.736382],
                [-65.468338, 59.75972],
                [-65.495003, 59.789989],
                [-65.371933, 59.796391],
                [-65.381668, 59.824169],
                [-65.333893, 59.84721],
                [-65.152786, 59.779991],
                [-64.983887, 59.762772],
                [-65.211121, 59.833599],
                [-65.231949, 59.88583],
                [-65.133904, 59.868599],
                [-65.20639, 59.919991],
                [-65.148903, 59.92305],
                [-65.138611, 59.953049],
                [-65.066673, 59.934429],
                [-65.12471, 59.971661],
                [-65.063316, 59.97694],
                [-65.126099, 60.011108],
                [-65.12471, 60.0425],
                [-65.071671, 60.035271],
                [-65.026947, 60.067501],
                [-65.0625, 60.096939],
                [-64.988892, 60.11694],
                [-64.993057, 60.14888],
                [-64.943604, 60.191101],
                [-64.874161, 60.20583],
                [-64.955276, 60.255829],
                [-64.861389, 60.29694],
                [-64.835564, 60.363331],
                [-64.641678, 60.344711],
                [-64.534821, 60.302601],
                [-64.853882, 60.261669],
                [-64.851936, 60.22332],
                [-64.767227, 60.174999],
                [-64.637222, 60.178329],
                [-64.598892, 60.111111],
                [-64.734161, 60.103321],
                [-64.702499, 60.064991],
                [-64.796951, 60.039719],
                [-64.906387, 60.04805],
                [-64.847778, 59.964161],
                [-64.65361, 59.929989],
                [-64.661911, 59.88361],
                [-64.767776, 59.87999],
                [-64.744453, 59.84499],
                [-64.814163, 59.81889],
                [-64.773064, 59.698879],
                [-64.82695, 59.531109],
                [-64.700844, 59.44582],
                [-64.432503, 59.52943],
                [-64.340286, 59.49638],
                [-64.5, 59.433048],
                [-64.485001, 59.396389],
                [-64.538063, 59.3736],
                [-64.499161, 59.33971],
                [-64.53833, 59.285549],
                [-64.507507, 59.25],
                [-64.534729, 59.197491],
                [-64.493057, 59.158878],
                [-64.493057, 59.104439],
                [-64.365013, 59.07333],
                [-64.291382, 59.016659],
                [-64.415833, 58.981098],
                [-64.656387, 59.028599],
                [-64.720001, 59.069988],
                [-64.829453, 59.03249],
                [-64.864723, 58.990551],
                [-64.838898, 58.958599],
                [-64.878883, 58.92749],
                [-64.809433, 58.911098],
                [-64.718887, 58.945271],
                [-64.568069, 58.887211],
                [-64.300278, 58.882488],
                [-64.258621, 58.849998],
                [-64.257507, 58.77943],
                [-64.156113, 58.74527],
                [-63.999439, 58.81916],
                [-63.841942, 58.82972],
                [-63.766651, 58.874081],
                [-63.588329, 58.846378],
                [-63.480549, 58.7486],
                [-63.574169, 58.715832],
                [-64.051102, 58.676102],
                [-64.097504, 58.60722],
                [-64.084167, 58.55555],
                [-64.022507, 58.52055],
                [-63.886951, 58.563049],
                [-63.81945, 58.471371],
                [-64.156387, 58.348331],
                [-64.21167, 58.23555],
                [-64.398064, 58.18166],
                [-64.431953, 58.086109],
                [-64.208618, 58.033329],
                [-64.214447, 57.977489],
                [-64.057503, 57.76944],
                [-64.022507, 57.771381],
                [-64.006668, 57.812771],
                [-63.92667, 57.797218],
                [-63.891941, 57.73027],
                [-63.825562, 57.690269],
                [-63.765282, 57.732769],
                [-63.70055, 57.662769],
                [-63.653332, 57.731098],
                [-63.595829, 57.734161],
                [-63.60722, 57.663601],
                [-63.721378, 57.602489],
                [-63.765011, 57.573879],
                [-63.763889, 57.482491],
                [-63.709721, 57.37249],
                [-63.836941, 57.325829],
                [-63.87611, 57.242771],
                [-63.855282, 57.193878],
                [-63.81472, 57.27972],
                [-63.741112, 57.232769],
                [-63.7925, 57.165272],
                [-63.78751, 57.111938],
                [-63.873611, 57.06638],
                [-63.863331, 56.992771],
                [-63.917782, 56.930271],
                [-63.87138, 56.87804],
                [-64.00528, 56.850552],
                [-63.992229, 56.785831],
                [-64.083618, 56.765549],
                [-64.12944, 56.71027],
                [-63.945, 56.549721],
                [-63.942219, 56.479431],
                [-63.87138, 56.43943],
                [-63.97472, 56.412769],
                [-64.169724, 56.427769],
                [-64.114166, 56.39027],
                [-64.105827, 56.331379],
                [-64.136673, 56.3036],
                [-64.08667, 56.259159],
                [-63.97472, 56.259159],
                [-63.876949, 56.217209],
                [-64.010834, 56.159988],
                [-64.020279, 56.076389],
                [-63.847778, 56.12027],
                [-63.880001, 56.076939],
                [-63.79084, 56.039162],
                [-63.445, 56.02277],
                [-63.69416, 55.942211],
                [-63.715279, 55.859161],
                [-63.488331, 55.696659],
                [-63.448051, 55.59943],
                [-63.29834, 55.523319],
                [-63.144169, 55.526379],
                [-63.176392, 55.419441],
                [-63.095829, 55.349998],
                [-63.176571, 55.296539],
                [-63.519451, 55.183601],
                [-63.566109, 55.060268],
                [-63.435268, 54.973598],
                [-63.66917, 54.612209],
                [-63.976189, 54.626461],
                [-64.325562, 54.751942],
                [-64.404327, 54.75251],
                [-64.552223, 54.720539],
                [-64.811661, 54.749161],
                [-65.121933, 54.69471],
                [-65.443878, 54.745831],
                [-65.689987, 54.713329],
                [-65.835281, 54.831379],
                [-65.858337, 54.907768],
                [-65.957779, 54.92944],
                [-66.035004, 54.90971],
                [-66.23999, 54.99194],
                [-66.576401, 55.250549],
                [-66.681953, 55.25333],
                [-66.688049, 55.301102],
                [-66.77861, 55.338879],
                [-66.844162, 55.326099],
                [-66.688889, 55.204159],
                [-66.740829, 55.17749],
                [-66.765839, 55.105549],
                [-66.65976, 54.978031],
                [-66.732773, 54.94833],
                [-66.678329, 54.894161],
                [-66.605003, 54.790829],
                [-66.712219, 54.78194],
                [-66.698608, 54.742222],
                [-66.738602, 54.732769],
                [-66.946953, 54.801659],
                [-67.028061, 54.89777],
                [-67.297783, 55.008049],
                [-67.273903, 55.066669],
                [-67.428329, 55.07111],
                [-67.453056, 55.018879],
                [-67.362213, 54.87582],
                [-67.263344, 54.825272],
                [-67.30777, 54.783878],
                [-67.184998, 54.71944],
                [-67.213898, 54.66888],
                [-67.036392, 54.621658],
                [-67.219452, 54.607498],
                [-67.305557, 54.504169],
                [-67.492493, 54.58194],
                [-67.601387, 54.56638],
                [-67.633057, 54.479988],
                [-67.586121, 54.4175],
                [-67.59584, 54.365269],
                [-67.49472, 54.314442],
                [-67.531677, 54.248878],
                [-67.485001, 54.234161],
                [-67.637222, 54.174999],
                [-67.781952, 54.063881],
                [-67.738441, 54.000011],
                [-67.609161, 53.928051],
                [-67.588058, 53.85722],
                [-67.430557, 53.778332],
                [-67.491943, 53.603882],
                [-67.141953, 53.542221],
                [-67.128883, 53.464161],
                [-67.068619, 53.4286],
                [-67.06778, 53.384159],
                [-66.987503, 53.403599],
                [-66.910004, 53.340271],
                [-66.991669, 53.195271],
                [-66.948608, 53.109718],
                [-66.979446, 53.027771],
                [-67.078056, 52.94804],
                [-67.058327, 52.804989],
                [-67.092773, 52.72971],
                [-67.067497, 52.699429],
                [-66.891388, 52.673321],
                [-66.87471, 52.747768],
                [-66.813316, 52.66415],
                [-66.66806, 52.725819],
                [-66.648064, 52.94582],
                [-66.525284, 52.943321],
                [-66.476936, 53.034161],
                [-66.345551, 52.974159],
                [-66.26973, 52.877491],
                [-66.378883, 52.836109],
                [-66.328339, 52.736938],
                [-66.433884, 52.706379],
                [-66.43222, 52.611382],
                [-66.373611, 52.5425],
                [-66.446663, 52.530819],
                [-66.358047, 52.406651],
                [-66.458618, 52.3661],
                [-66.486656, 52.312489],
                [-66.438889, 52.200829],
                [-66.335564, 52.136379],
                [-66.303879, 52.222759],
                [-66.319733, 52.289162],
                [-66.275558, 52.311661],
                [-66.173889, 52.220539],
                [-66.068069, 52.216381],
                [-66.063889, 52.11832],
                [-65.916946, 52.050831],
                [-65.801666, 52.04277],
                [-65.799438, 52.095539],
                [-65.758621, 52.111382],
                [-65.56723, 52.046661],
                [-65.538597, 52.123878],
                [-65.453613, 52.20805],
                [-65.108612, 52.17083],
                [-64.853333, 52.021931],
                [-64.84584, 51.93082],
                [-64.587784, 51.82888],
                [-64.593887, 51.796101],
                [-64.718063, 51.747768],
                [-64.703056, 51.69276],
                [-64.604172, 51.642769],
                [-64.581123, 51.55777],
                [-64.44249, 51.66777],
                [-64.264183, 51.739719],
                [-64.357224, 51.850552],
                [-64.317497, 51.903599],
                [-64.330841, 51.965271],
                [-64.274437, 52.00333],
                [-64.293327, 52.046391],
                [-64.257782, 52.12804],
                [-64.115829, 52.151379],
                [-64.131378, 52.28138],
                [-64.08168, 52.37249],
                [-64.208893, 52.548882],
                [-64.175278, 52.60944],
                [-64.202217, 52.672218],
                [-64.156662, 52.738331],
                [-64.201401, 52.790829],
                [-64.172501, 52.85083],
                [-64.080841, 52.881378],
                [-63.959999, 52.867771],
                [-63.982769, 52.933601],
                [-63.919449, 52.97694],
                [-63.923611, 53.077221],
                [-63.693329, 53.113609],
                [-63.634731, 53.094151],
                [-63.62611, 53.042221],
                [-63.54195, 52.970829],
                [-63.595829, 52.770771],
                [-63.423061, 52.707211],
                [-63.377781, 52.650829],
                [-63.839722, 52.61805],
                [-63.971378, 52.570831],
                [-64.08168, 52.470539],
                [-64.027496, 52.433048],
                [-63.976391, 52.458599],
                [-63.930279, 52.41777],
                [-63.92445, 52.383881],
                [-64.013062, 52.387211],
                [-63.987499, 52.362499],
                [-63.941269, 52.264],
                [-63.65535, 52.124359],
                [-63.637779, 52.039989],
                [-63.824718, 52.070549],
                [-63.75111, 51.999722],
                [-57.367771, 51.999439],
                [-57.101109, 52],
                [-57.10424, 51.41267]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Quebec",
          "density": "6.23",
          "cartodb_id": 1,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-55.88306, 53.486382],
                [-55.729439, 53.450272],
                [-55.79528, 53.458328],
                [-55.774441, 53.41444],
                [-55.817219, 53.41777],
                [-55.801392, 53.39249],
                [-55.971661, 53.44582],
                [-55.88306, 53.486382]
              ]
            ],
            [
              [
                [-55.466942, 50.784161],
                [-55.557499, 50.701389],
                [-55.653049, 50.727211],
                [-55.61916, 50.791382],
                [-55.589161, 50.772491],
                [-55.46278, 50.80582],
                [-55.466942, 50.784161]
              ]
            ],
            [
              [
                [-54.08112, 49.736382],
                [-53.98167, 49.649441],
                [-54.257778, 49.566669],
                [-54.293331, 49.580551],
                [-54.303329, 49.670269],
                [-54.25528, 49.672489],
                [-54.28278, 49.71666],
                [-54.215279, 49.720829],
                [-54.19194, 49.674999],
                [-54.148338, 49.74749],
                [-54.08112, 49.736382]
              ]
            ],
            [
              [
                [-54.52972, 49.633881],
                [-54.576672, 49.558601],
                [-54.727219, 49.528332],
                [-54.739441, 49.490269],
                [-54.871941, 49.487499],
                [-54.807499, 49.517208],
                [-54.884171, 49.546101],
                [-54.891941, 49.590549],
                [-54.793331, 49.602779],
                [-54.788342, 49.55777],
                [-54.743889, 49.544998],
                [-54.62722, 49.633049],
                [-54.620831, 49.602779],
                [-54.536121, 49.66415],
                [-54.52972, 49.633881]
              ]
            ],
            [
              [
                [-53.564159, 48.19054],
                [-53.509731, 48.19833],
                [-53.512501, 48.145271],
                [-53.553329, 48.106941],
                [-53.53083, 48.097771],
                [-53.634171, 48.075272],
                [-53.85611, 48.098049],
                [-53.935829, 48.182499],
                [-53.906391, 48.21027],
                [-53.850559, 48.161381],
                [-53.765282, 48.174709],
                [-53.68972, 48.147221],
                [-53.564159, 48.19054]
              ]
            ],
            [
              [
                [-55.927502, 47.676659],
                [-55.93, 47.639431],
                [-55.876389, 47.61166],
                [-55.901939, 47.602489],
                [-56.113621, 47.644711],
                [-55.927502, 47.676659]
              ]
            ],
            [
              [
                [-54.125, 47.640831],
                [-54.243328, 47.399441],
                [-54.364449, 47.406651],
                [-54.262218, 47.474709],
                [-54.205559, 47.546391],
                [-54.240002, 47.558041],
                [-54.172779, 47.621101],
                [-54.17194, 47.585548],
                [-54.135281, 47.668049],
                [-54.125, 47.640831]
              ]
            ],
            [
              [
                [-64.631828, 60.35746],
                [-64.790283, 60.391109],
                [-64.867493, 60.450272],
                [-64.85611, 60.473881],
                [-64.639999, 60.484718],
                [-64.525558, 60.413319],
                [-64.532227, 60.374161],
                [-64.47084, 60.405819],
                [-64.426941, 60.401379],
                [-64.427673, 60.372929],
                [-64.478333, 60.334431],
                [-64.423607, 60.28249],
                [-64.631828, 60.35746]
              ]
            ],
            [
              [
                [-64.534821, 60.302601],
                [-64.384171, 60.238331],
                [-64.613052, 60.28944],
                [-64.759453, 60.231098],
                [-64.554169, 60.262772],
                [-64.463333, 60.22694],
                [-64.562767, 60.17305],
                [-64.405563, 60.220268],
                [-64.426666, 60.19249],
                [-64.376938, 60.160549],
                [-64.486938, 60.115551],
                [-64.469162, 60.082771],
                [-64.627487, 60.070831],
                [-64.827499, 59.986382],
                [-64.603333, 60.051659],
                [-64.45639, 60.059429],
                [-64.373894, 60.1236],
                [-64.371933, 60.073051],
                [-64.416397, 60.06332],
                [-64.371109, 60.04361],
                [-64.389183, 60.01416],
                [-64.588608, 60.001942],
                [-64.398064, 59.971661],
                [-64.396957, 59.937771],
                [-64.499443, 59.891659],
                [-64.363327, 59.922489],
                [-64.330002, 59.984161],
                [-64.224442, 59.99527],
                [-64.325287, 60.015831],
                [-64.314713, 60.05138],
                [-64.173607, 60.028332],
                [-64.150284, 59.985271],
                [-64.294449, 59.948601],
                [-64.186394, 59.91972],
                [-64.259743, 59.872219],
                [-64.126099, 59.896099],
                [-64.177223, 59.785549],
                [-64.261948, 59.789162],
                [-64.254997, 59.75666],
                [-64.163887, 59.684158],
                [-63.956669, 59.689991],
                [-63.96611, 59.658878],
                [-63.923889, 59.653599],
                [-64.047501, 59.635269],
                [-64.122498, 59.519711],
                [-64.040833, 59.5536],
                [-63.997219, 59.626659],
                [-63.863621, 59.601662],
                [-63.98, 59.505268],
                [-63.87138, 59.56638],
                [-63.7225, 59.513889],
                [-63.799728, 59.41666],
                [-64.000557, 59.41444],
                [-64.06221, 59.382488],
                [-63.763889, 59.38805],
                [-63.755562, 59.358051],
                [-63.71167, 59.365269],
                [-63.805828, 59.240269],
                [-63.741112, 59.25639],
                [-63.64999, 59.362499],
                [-63.54306, 59.348049],
                [-63.534729, 59.264439],
                [-63.637779, 59.218601],
                [-63.605282, 59.209721],
                [-63.380001, 59.283878],
                [-63.45084, 59.240829],
                [-63.431671, 59.22443],
                [-63.462219, 59.1936],
                [-63.376659, 59.213329],
                [-63.366661, 59.186378],
                [-63.42556, 59.126381],
                [-63.551392, 59.084431],
                [-63.76778, 59.04916],
                [-63.934441, 59.081108],
                [-64.047234, 59.01944],
                [-63.898891, 59.05666],
                [-63.91222, 59.000549],
                [-63.868328, 58.95332],
                [-63.86916, 59.031658],
                [-63.527222, 59.030548],
                [-63.372768, 59.101101],
                [-63.125, 59.055271],
                [-63.175282, 59.026939],
                [-63.336109, 59.02499],
                [-63.224998, 59.001389],
                [-63.304169, 58.94249],
                [-63.173061, 58.97971],
                [-63.170559, 58.954708],
                [-63.226391, 58.939709],
                [-63.160549, 58.92638],
                [-63.325001, 58.855831],
                [-63.15778, 58.873322],
                [-63.182781, 58.833599],
                [-63.160278, 58.823051],
                [-63.071949, 58.902771],
                [-62.994999, 58.885551],
                [-63.053329, 58.859161],
                [-62.924171, 58.821381],
                [-62.90527, 58.789989],
                [-62.918331, 58.74527],
                [-63.04084, 58.707771],
                [-62.867771, 58.71138],
                [-62.843609, 58.659431],
                [-62.917221, 58.630821],
                [-62.915829, 58.600269],
                [-63.16917, 58.503052],
                [-63.439991, 58.51833],
                [-63.52417, 58.470829],
                [-63.385281, 58.488331],
                [-63.34333, 58.4561],
                [-63.589439, 58.300831],
                [-63.264721, 58.463051],
                [-63.089722, 58.458328],
                [-63.151939, 58.373878],
                [-63.050549, 58.450272],
                [-63.015011, 58.42527],
                [-62.80444, 58.492222],
                [-62.77528, 58.451099],
                [-62.745281, 58.49361],
                [-62.589439, 58.499722],
                [-62.556389, 58.478039],
                [-62.650551, 58.408329],
                [-62.61972, 58.376942],
                [-62.623051, 58.30444],
                [-62.858608, 58.262501],
                [-62.90527, 58.209991],
                [-62.832779, 58.251659],
                [-62.658611, 58.270271],
                [-62.59277, 58.2486],
                [-62.582779, 58.21693],
                [-62.661381, 58.17305],
                [-63.074718, 58.151379],
                [-63.012218, 58.135551],
                [-63.023891, 58.118881],
                [-63.21167, 58.062489],
                [-63.132221, 58.058331],
                [-63.15583, 58.026939],
                [-63.340839, 57.979988],
                [-63.12888, 57.997768],
                [-63.089161, 58.06221],
                [-62.838051, 58.145],
                [-62.846111, 58.118881],
                [-62.709438, 58.14249],
                [-62.652222, 58.118599],
                [-62.57362, 58.164711],
                [-62.554722, 58.121101],
                [-62.52306, 58.1661],
                [-62.452782, 58.17527],
                [-62.531391, 58.095268],
                [-62.50639, 58.055271],
                [-62.46674, 58.102371],
                [-62.368889, 58.11166],
                [-62.426392, 58.081379],
                [-62.38612, 58.07222],
                [-62.388889, 58.042221],
                [-62.33556, 58.067501],
                [-62.309719, 58.028599],
                [-62.386669, 57.993881],
                [-62.528339, 58.00555],
                [-62.672779, 57.929989],
                [-62.456951, 57.967491],
                [-62.423611, 57.957771],
                [-62.408611, 57.865551],
                [-62.34222, 57.975819],
                [-62.333611, 57.90638],
                [-62.276661, 57.950829],
                [-62.206108, 57.907768],
                [-62.127491, 57.968048],
                [-62.059719, 57.89777],
                [-62.13805, 57.835819],
                [-62.120831, 57.800831],
                [-61.99667, 57.772221],
                [-61.946949, 57.71471],
                [-61.965839, 57.687489],
                [-61.89333, 57.66972],
                [-61.88306, 57.637501],
                [-62.051392, 57.588879],
                [-62.13612, 57.528332],
                [-62.271938, 57.533051],
                [-62.33556, 57.456379],
                [-62.54528, 57.50111],
                [-62.36528, 57.41972],
                [-62.167221, 57.464439],
                [-61.891392, 57.41193],
                [-61.803051, 57.358891],
                [-61.91972, 57.365551],
                [-61.868328, 57.3386],
                [-61.863892, 57.285549],
                [-62.015839, 57.24305],
                [-61.89555, 57.211109],
                [-61.852501, 57.165272],
                [-61.77306, 57.19305],
                [-61.779171, 57.148331],
                [-61.636391, 57.232491],
                [-61.623329, 57.215271],
                [-61.696659, 57.153881],
                [-61.441669, 57.148609],
                [-61.358341, 57.08749],
                [-61.37944, 57.05138],
                [-61.348339, 57.025269],
                [-61.371941, 56.995541],
                [-61.346111, 56.951099],
                [-61.378052, 56.982208],
                [-61.487782, 56.981659],
                [-61.639999, 56.883881],
                [-61.661381, 56.809429],
                [-61.906391, 56.795269],
                [-61.889999, 56.69804],
                [-61.846111, 56.75639],
                [-61.702499, 56.73082],
                [-61.73444, 56.697208],
                [-61.825001, 56.706661],
                [-61.65889, 56.64777],
                [-61.688889, 56.61721],
                [-61.923061, 56.64555],
                [-61.895279, 56.68082],
                [-61.992771, 56.660271],
                [-62.49889, 56.77972],
                [-62.279442, 56.826389],
                [-62.04945, 56.8325],
                [-62.543892, 56.837212],
                [-62.58556, 56.79277],
                [-62.503059, 56.762211],
                [-61.916389, 56.62027],
                [-62.22472, 56.609161],
                [-61.945271, 56.57888],
                [-61.715, 56.57222],
                [-61.65527, 56.533878],
                [-61.66222, 56.5061],
                [-61.77306, 56.484718],
                [-62.046951, 56.504711],
                [-62.082218, 56.481659],
                [-61.949169, 56.46138],
                [-62.139172, 56.44471],
                [-61.98473, 56.415272],
                [-61.930279, 56.437489],
                [-61.79084, 56.39249],
                [-61.84277, 56.380821],
                [-61.772221, 56.336651],
                [-61.589161, 56.28722],
                [-61.811668, 56.305271],
                [-61.65889, 56.26749],
                [-61.778339, 56.25444],
                [-61.827782, 56.264721],
                [-61.753059, 56.277771],
                [-61.878609, 56.298611],
                [-62.076111, 56.285],
                [-61.919449, 56.212212],
                [-61.65527, 56.224159],
                [-61.56028, 56.194439],
                [-61.350842, 56.22221],
                [-61.33028, 56.176659],
                [-61.410549, 56.124439],
                [-61.342499, 56.14138],
                [-61.337502, 56.100269],
                [-61.452221, 56.056938],
                [-61.237499, 56.04277],
                [-61.50362, 56.006939],
                [-61.390839, 55.998329],
                [-61.376659, 55.97971],
                [-61.40472, 55.97221],
                [-61.460831, 55.99638],
                [-61.415001, 55.96027],
                [-61.117771, 55.966099],
                [-61.074451, 55.928329],
                [-61.07917, 55.893051],
                [-61.201672, 55.884159],
                [-61.10445, 55.845539],
                [-60.755562, 55.84943],
                [-60.733059, 55.80249],
                [-60.807781, 55.755268],
                [-60.945339, 55.751308],
                [-60.96666, 55.74638],
                [-60.9375, 55.727211],
                [-60.75639, 55.729431],
                [-60.647221, 55.822769],
                [-60.60194, 55.814709],
                [-60.62389, 55.783611],
                [-60.59277, 55.704159],
                [-60.661381, 55.66666],
                [-60.62888, 55.629162],
                [-60.679169, 55.563881],
                [-60.60223, 55.626659],
                [-60.503059, 55.803051],
                [-60.36195, 55.761669],
                [-60.34, 55.786381],
                [-60.33778, 55.7486],
                [-60.384171, 55.719711],
                [-60.37167, 55.678329],
                [-60.493889, 55.658039],
                [-60.531109, 55.59721],
                [-60.465839, 55.63166],
                [-60.393059, 55.601101],
                [-60.447781, 55.500271],
                [-60.340279, 55.57888],
                [-60.316391, 55.573879],
                [-60.339439, 55.533611],
                [-60.305561, 55.524158],
                [-60.45306, 55.444988],
                [-60.437771, 55.399441],
                [-60.478329, 55.347488],
                [-60.331669, 55.48666],
                [-60.30611, 55.486382],
                [-60.35722, 55.4286],
                [-60.285278, 55.438042],
                [-60.268608, 55.502491],
                [-60.201672, 55.4786],
                [-60.196659, 55.422218],
                [-60.354721, 55.395],
                [-60.399441, 55.289989],
                [-60.46666, 55.29472],
                [-60.533058, 55.204441],
                [-60.612499, 55.16666],
                [-60.553341, 55.181561],
                [-60.510761, 55.2076],
                [-60.46833, 55.237499],
                [-60.430279, 55.21693],
                [-60.396111, 55.253609],
                [-60.348049, 55.250549],
                [-60.46611, 55.189709],
                [-60.52306, 55.111382],
                [-60.670559, 55.04472],
                [-60.683331, 54.994999],
                [-60.482498, 55.105549],
                [-60.446659, 55.15638],
                [-60.385559, 55.150539],
                [-60.16, 55.285549],
                [-60.13805, 55.278049],
                [-60.194439, 55.192211],
                [-60.021389, 55.266109],
                [-60.18972, 55.096939],
                [-60.220551, 55.10638],
                [-60.288342, 55.053322],
                [-60.293892, 55.01944],
                [-60.099442, 55.136662],
                [-60.020279, 55.234989],
                [-59.91695, 55.233879],
                [-59.914719, 55.26416],
                [-59.779171, 55.32972],
                [-59.755001, 55.2761],
                [-59.711941, 55.269711],
                [-59.752781, 55.220829],
                [-59.732498, 55.197208],
                [-59.863892, 55.15416],
                [-59.96278, 55.161098],
                [-59.963619, 55.110279],
                [-59.921108, 55.139721],
                [-59.800831, 55.108891],
                [-59.729439, 55.14444],
                [-59.638889, 55.13055],
                [-59.535278, 55.16777],
                [-59.50584, 55.213329],
                [-59.501671, 55.141941],
                [-59.427219, 55.139992],
                [-59.519169, 55.054161],
                [-59.733059, 54.967491],
                [-59.715549, 54.946098],
                [-59.840549, 54.879162],
                [-59.860279, 54.845539],
                [-59.821388, 54.846939],
                [-59.827499, 54.82444],
                [-59.94416, 54.749722],
                [-59.791111, 54.785549],
                [-59.808048, 54.836109],
                [-59.75695, 54.895828],
                [-59.604172, 54.954441],
                [-59.433331, 55.06889],
                [-59.402222, 55.028049],
                [-59.286949, 55.18943],
                [-59.217499, 55.217209],
                [-59.21722, 55.164989],
                [-59.161381, 55.236938],
                [-59.127491, 55.20583],
                [-59.137218, 55.15443],
                [-59.252781, 55.102489],
                [-59.384171, 54.97332],
                [-59.250839, 55.035831],
                [-59.228882, 55.081108],
                [-59.181671, 55.097488],
                [-59.14917, 55.06805],
                [-59.12611, 55.123878],
                [-59.09, 55.083328],
                [-59.064159, 55.14666],
                [-59.023609, 55.156651],
                [-58.996109, 55.118599],
                [-58.96056, 55.134991],
                [-58.95583, 55.10083],
                [-59.043331, 54.994999],
                [-58.985561, 55.020828],
                [-58.894169, 54.95277],
                [-59.013618, 54.889721],
                [-58.883888, 54.916382],
                [-58.91666, 54.850552],
                [-58.779171, 54.84972],
                [-58.75639, 54.79805],
                [-58.73138, 54.843048],
                [-58.69194, 54.846378],
                [-58.685268, 54.81554],
                [-58.51722, 54.8036],
                [-58.565552, 54.766109],
                [-58.443611, 54.77388],
                [-58.40472, 54.738331],
                [-58.391941, 54.787498],
                [-58.196659, 54.795269],
                [-58.173328, 54.745541],
                [-57.998611, 54.714161],
                [-57.855, 54.737499],
                [-57.660278, 54.62804],
                [-57.545841, 54.661659],
                [-57.45055, 54.64999],
                [-57.414719, 54.5886],
                [-57.3475, 54.579441],
                [-57.431389, 54.55027],
                [-57.37722, 54.542221],
                [-57.385281, 54.50333],
                [-57.70528, 54.46693],
                [-57.421669, 54.453609],
                [-57.52528, 54.451099],
                [-57.523331, 54.41721],
                [-57.660831, 54.376942],
                [-58.146389, 54.365269],
                [-58.261951, 54.3111],
                [-58.095829, 54.325829],
                [-58.243061, 54.287498],
                [-58.233891, 54.254169],
                [-58.446388, 54.239159],
                [-58.72472, 54.145271],
                [-58.915279, 54.138599],
                [-59.37611, 54.04694],
                [-59.575562, 54.049438],
                [-59.509731, 53.995541],
                [-59.276112, 54.021099],
                [-58.687771, 54.12471],
                [-58.37944, 54.229988],
                [-58.610279, 54.04166],
                [-59.038609, 54.026939],
                [-58.919449, 54.01083],
                [-59.008339, 53.955269],
                [-59.11528, 53.946381],
                [-59.463329, 53.830551],
                [-59.82584, 53.84277],
                [-59.88055, 53.825272],
                [-59.80278, 53.806099],
                [-59.828892, 53.788601],
                [-60.13139, 53.77721],
                [-60.01778, 53.704159],
                [-60.097778, 53.683601],
                [-60.119999, 53.611382],
                [-60.064449, 53.560268],
                [-60.133888, 53.528332],
                [-60.39056, 53.665539],
                [-60.810829, 53.771931],
                [-60.875, 53.83083],
                [-60.856949, 53.79277],
                [-60.93306, 53.7286],
                [-60.91806, 53.709431],
                [-60.805561, 53.723049],
                [-60.53722, 53.678329],
                [-60.278881, 53.558601],
                [-60.048061, 53.49527],
                [-60.038059, 53.439709],
                [-60.13805, 53.453609],
                [-60.331951, 53.388599],
                [-60.363892, 53.351101],
                [-60.40583, 53.364159],
                [-60.39584, 53.331379],
                [-60.174999, 53.343048],
                [-60.39806, 53.265549],
                [-60.26112, 53.2686],
                [-60.16444, 53.309429],
                [-60.113892, 53.270271],
                [-60.09, 53.299999],
                [-60.147781, 53.32666],
                [-60.024719, 53.355],
                [-59.90889, 53.45583],
                [-59.87167, 53.4436],
                [-59.874168, 53.484989],
                [-59.785, 53.477211],
                [-59.893059, 53.528599],
                [-59.605831, 53.52972],
                [-59.36945, 53.648609],
                [-59.302219, 53.686378],
                [-59.279442, 53.662769],
                [-59.213619, 53.69138],
                [-59.074169, 53.683048],
                [-59.06834, 53.71777],
                [-59.027779, 53.711658],
                [-59.010559, 53.746658],
                [-59.066391, 53.794998],
                [-58.876389, 53.888329],
                [-58.841671, 53.943321],
                [-58.130001, 54.096939],
                [-57.78944, 54.0686],
                [-57.851669, 54.100269],
                [-58.159161, 54.13583],
                [-58.328892, 54.089432],
                [-58.418331, 54.139721],
                [-58.38361, 54.189709],
                [-58.17028, 54.23666],
                [-57.659161, 54.199429],
                [-57.604721, 54.18388],
                [-57.609169, 54.16193],
                [-57.428341, 54.182499],
                [-57.384171, 54.150539],
                [-57.396389, 54.128601],
                [-57.35973, 54.134159],
                [-57.370831, 54.10638],
                [-57.296391, 54.00555],
                [-57.142231, 53.934719],
                [-57.215, 53.929989],
                [-57.221378, 53.889992],
                [-57.076389, 53.823051],
                [-57.13361, 53.824169],
                [-57.099998, 53.800831],
                [-57.159721, 53.75861],
                [-57.119999, 53.7411],
                [-57.549999, 53.59193],
                [-57.444721, 53.618599],
                [-57.448608, 53.59222],
                [-57.376659, 53.607498],
                [-57.316669, 53.57972],
                [-57.344158, 53.54277],
                [-57.305561, 53.534439],
                [-57.30666, 53.49638],
                [-57.372219, 53.43082],
                [-57.289169, 53.43388],
                [-57.285278, 53.477489],
                [-57.13612, 53.54916],
                [-57.159161, 53.564159],
                [-57.136391, 53.60944],
                [-57.077499, 53.628601],
                [-57.014721, 53.71138],
                [-56.704441, 53.750549],
                [-56.600281, 53.710819],
                [-56.615841, 53.762501],
                [-56.464691, 53.782269],
                [-56.421669, 53.71693],
                [-56.725559, 53.67416],
                [-56.480549, 53.62138],
                [-56.333889, 53.629162],
                [-56.263062, 53.601391],
                [-56.33556, 53.603321],
                [-56.320278, 53.58527],
                [-56.027222, 53.575272],
                [-55.978882, 53.542221],
                [-56.00861, 53.5],
                [-56.063332, 53.540829],
                [-56.26334, 53.540279],
                [-56.031391, 53.459721],
                [-56.032219, 53.432209],
                [-55.965549, 53.409161],
                [-56.038342, 53.367489],
                [-55.91222, 53.395],
                [-55.85828, 53.365269],
                [-55.885559, 53.344151],
                [-55.808048, 53.340549],
                [-55.91111, 53.303879],
                [-55.812222, 53.290829],
                [-55.85973, 53.248051],
                [-55.745831, 53.249439],
                [-55.79084, 53.198879],
                [-55.841942, 53.205269],
                [-55.76334, 53.1786],
                [-55.754169, 53.134991],
                [-55.830002, 53.09721],
                [-55.944439, 53.112499],
                [-55.87944, 53.07999],
                [-55.911942, 53.028332],
                [-56.16695, 53.02943],
                [-56.084438, 53.01527],
                [-56.133331, 52.983051],
                [-56.11195, 52.96666],
                [-56.04084, 53.005829],
                [-55.889439, 52.969151],
                [-55.910831, 52.950272],
                [-55.834171, 52.92194],
                [-55.803329, 52.839432],
                [-55.987782, 52.806099],
                [-55.923328, 52.789989],
                [-55.996391, 52.773319],
                [-56.160549, 52.819439],
                [-56.16, 52.79388],
                [-56.053329, 52.762772],
                [-55.96056, 52.679161],
                [-55.76556, 52.665821],
                [-55.738609, 52.642769],
                [-55.794449, 52.593048],
                [-55.937222, 52.63916],
                [-56.122768, 52.6511],
                [-55.974998, 52.619709],
                [-56.047779, 52.576099],
                [-55.989441, 52.56694],
                [-55.999439, 52.548611],
                [-56.089722, 52.572491],
                [-56.237221, 52.541939],
                [-56.496868, 52.594151],
                [-56.245548, 52.52916],
                [-56.10722, 52.547489],
                [-55.971378, 52.50222],
                [-55.7514, 52.496101],
                [-55.735828, 52.469151],
                [-55.766399, 52.447769],
                [-55.64555, 52.437489],
                [-55.641941, 52.36388],
                [-55.799171, 52.384441],
                [-55.782501, 52.33416],
                [-56.195271, 52.435822],
                [-55.82362, 52.32333],
                [-55.849171, 52.298882],
                [-55.70805, 52.293331],
                [-55.79195, 52.237499],
                [-55.720829, 52.251389],
                [-55.618328, 52.215271],
                [-55.776661, 52.20916],
                [-55.688889, 52.18111],
                [-55.713329, 52.139992],
                [-55.68972, 52.101391],
                [-55.792782, 52.083881],
                [-55.763889, 52.047489],
                [-55.84277, 52.003052],
                [-55.959171, 52.019161],
                [-55.894451, 51.962212],
                [-55.915279, 51.932209],
                [-56.117771, 51.860279],
                [-56.209721, 51.789719],
                [-56.43306, 51.74638],
                [-56.493328, 51.688599],
                [-56.68195, 51.64222],
                [-56.695831, 51.584991],
                [-56.807499, 51.528332],
                [-56.81361, 51.491661],
                [-56.858059, 51.46888],
                [-56.933331, 51.491661],
                [-56.942768, 51.42749],
                [-57.10424, 51.41267],
                [-57.101109, 52],
                [-57.367771, 51.999439],
                [-63.75111, 51.999722],
                [-63.824718, 52.070549],
                [-63.637779, 52.039989],
                [-63.65535, 52.124359],
                [-63.941269, 52.264],
                [-63.987499, 52.362499],
                [-64.013062, 52.387211],
                [-63.92445, 52.383881],
                [-63.930279, 52.41777],
                [-63.976391, 52.458599],
                [-64.027496, 52.433048],
                [-64.08168, 52.470539],
                [-63.971378, 52.570831],
                [-63.839722, 52.61805],
                [-63.377781, 52.650829],
                [-63.423061, 52.707211],
                [-63.595829, 52.770771],
                [-63.54195, 52.970829],
                [-63.62611, 53.042221],
                [-63.634731, 53.094151],
                [-63.693329, 53.113609],
                [-63.923611, 53.077221],
                [-63.919449, 52.97694],
                [-63.982769, 52.933601],
                [-63.959999, 52.867771],
                [-64.080841, 52.881378],
                [-64.172501, 52.85083],
                [-64.201401, 52.790829],
                [-64.156662, 52.738331],
                [-64.202217, 52.672218],
                [-64.175278, 52.60944],
                [-64.208893, 52.548882],
                [-64.08168, 52.37249],
                [-64.131378, 52.28138],
                [-64.115829, 52.151379],
                [-64.257782, 52.12804],
                [-64.293327, 52.046391],
                [-64.274437, 52.00333],
                [-64.330841, 51.965271],
                [-64.317497, 51.903599],
                [-64.357224, 51.850552],
                [-64.264183, 51.739719],
                [-64.44249, 51.66777],
                [-64.581123, 51.55777],
                [-64.604172, 51.642769],
                [-64.703056, 51.69276],
                [-64.718063, 51.747768],
                [-64.593887, 51.796101],
                [-64.587784, 51.82888],
                [-64.84584, 51.93082],
                [-64.853333, 52.021931],
                [-65.108612, 52.17083],
                [-65.453613, 52.20805],
                [-65.538597, 52.123878],
                [-65.56723, 52.046661],
                [-65.758621, 52.111382],
                [-65.799438, 52.095539],
                [-65.801666, 52.04277],
                [-65.916946, 52.050831],
                [-66.063889, 52.11832],
                [-66.068069, 52.216381],
                [-66.173889, 52.220539],
                [-66.275558, 52.311661],
                [-66.319733, 52.289162],
                [-66.303879, 52.222759],
                [-66.335564, 52.136379],
                [-66.438889, 52.200829],
                [-66.486656, 52.312489],
                [-66.458618, 52.3661],
                [-66.358047, 52.406651],
                [-66.446663, 52.530819],
                [-66.373611, 52.5425],
                [-66.43222, 52.611382],
                [-66.433884, 52.706379],
                [-66.328339, 52.736938],
                [-66.378883, 52.836109],
                [-66.26973, 52.877491],
                [-66.345551, 52.974159],
                [-66.476936, 53.034161],
                [-66.525284, 52.943321],
                [-66.648064, 52.94582],
                [-66.66806, 52.725819],
                [-66.813316, 52.66415],
                [-66.87471, 52.747768],
                [-66.891388, 52.673321],
                [-67.067497, 52.699429],
                [-67.092773, 52.72971],
                [-67.058327, 52.804989],
                [-67.078056, 52.94804],
                [-66.979446, 53.027771],
                [-66.948608, 53.109718],
                [-66.991669, 53.195271],
                [-66.910004, 53.340271],
                [-66.987503, 53.403599],
                [-67.06778, 53.384159],
                [-67.068619, 53.4286],
                [-67.128883, 53.464161],
                [-67.141953, 53.542221],
                [-67.491943, 53.603882],
                [-67.430557, 53.778332],
                [-67.588058, 53.85722],
                [-67.609161, 53.928051],
                [-67.738441, 54.000011],
                [-67.781952, 54.063881],
                [-67.637222, 54.174999],
                [-67.485001, 54.234161],
                [-67.531677, 54.248878],
                [-67.49472, 54.314442],
                [-67.59584, 54.365269],
                [-67.586121, 54.4175],
                [-67.633057, 54.479988],
                [-67.601387, 54.56638],
                [-67.492493, 54.58194],
                [-67.305557, 54.504169],
                [-67.219452, 54.607498],
                [-67.036392, 54.621658],
                [-67.213898, 54.66888],
                [-67.184998, 54.71944],
                [-67.30777, 54.783878],
                [-67.263344, 54.825272],
                [-67.362213, 54.87582],
                [-67.453056, 55.018879],
                [-67.428329, 55.07111],
                [-67.273903, 55.066669],
                [-67.297783, 55.008049],
                [-67.028061, 54.89777],
                [-66.946953, 54.801659],
                [-66.738602, 54.732769],
                [-66.698608, 54.742222],
                [-66.712219, 54.78194],
                [-66.605003, 54.790829],
                [-66.678329, 54.894161],
                [-66.732773, 54.94833],
                [-66.65976, 54.978031],
                [-66.765839, 55.105549],
                [-66.740829, 55.17749],
                [-66.688889, 55.204159],
                [-66.844162, 55.326099],
                [-66.77861, 55.338879],
                [-66.688049, 55.301102],
                [-66.681953, 55.25333],
                [-66.576401, 55.250549],
                [-66.23999, 54.99194],
                [-66.035004, 54.90971],
                [-65.957779, 54.92944],
                [-65.858337, 54.907768],
                [-65.835281, 54.831379],
                [-65.689987, 54.713329],
                [-65.443878, 54.745831],
                [-65.121933, 54.69471],
                [-64.811661, 54.749161],
                [-64.552223, 54.720539],
                [-64.404327, 54.75251],
                [-64.325562, 54.751942],
                [-63.976189, 54.626461],
                [-63.66917, 54.612209],
                [-63.435268, 54.973598],
                [-63.566109, 55.060268],
                [-63.519451, 55.183601],
                [-63.176571, 55.296539],
                [-63.095829, 55.349998],
                [-63.176392, 55.419441],
                [-63.144169, 55.526379],
                [-63.29834, 55.523319],
                [-63.448051, 55.59943],
                [-63.488331, 55.696659],
                [-63.715279, 55.859161],
                [-63.69416, 55.942211],
                [-63.445, 56.02277],
                [-63.79084, 56.039162],
                [-63.880001, 56.076939],
                [-63.847778, 56.12027],
                [-64.020279, 56.076389],
                [-64.010834, 56.159988],
                [-63.876949, 56.217209],
                [-63.97472, 56.259159],
                [-64.08667, 56.259159],
                [-64.136673, 56.3036],
                [-64.105827, 56.331379],
                [-64.114166, 56.39027],
                [-64.169724, 56.427769],
                [-63.97472, 56.412769],
                [-63.87138, 56.43943],
                [-63.942219, 56.479431],
                [-63.945, 56.549721],
                [-64.12944, 56.71027],
                [-64.083618, 56.765549],
                [-63.992229, 56.785831],
                [-64.00528, 56.850552],
                [-63.87138, 56.87804],
                [-63.917782, 56.930271],
                [-63.863331, 56.992771],
                [-63.873611, 57.06638],
                [-63.78751, 57.111938],
                [-63.7925, 57.165272],
                [-63.741112, 57.232769],
                [-63.81472, 57.27972],
                [-63.855282, 57.193878],
                [-63.87611, 57.242771],
                [-63.836941, 57.325829],
                [-63.709721, 57.37249],
                [-63.763889, 57.482491],
                [-63.765011, 57.573879],
                [-63.721378, 57.602489],
                [-63.60722, 57.663601],
                [-63.595829, 57.734161],
                [-63.653332, 57.731098],
                [-63.70055, 57.662769],
                [-63.765282, 57.732769],
                [-63.825562, 57.690269],
                [-63.891941, 57.73027],
                [-63.92667, 57.797218],
                [-64.006668, 57.812771],
                [-64.022507, 57.771381],
                [-64.057503, 57.76944],
                [-64.214447, 57.977489],
                [-64.208618, 58.033329],
                [-64.431953, 58.086109],
                [-64.398064, 58.18166],
                [-64.21167, 58.23555],
                [-64.156387, 58.348331],
                [-63.81945, 58.471371],
                [-63.886951, 58.563049],
                [-64.022507, 58.52055],
                [-64.084167, 58.55555],
                [-64.097504, 58.60722],
                [-64.051102, 58.676102],
                [-63.574169, 58.715832],
                [-63.480549, 58.7486],
                [-63.588329, 58.846378],
                [-63.766651, 58.874081],
                [-63.841942, 58.82972],
                [-63.999439, 58.81916],
                [-64.156113, 58.74527],
                [-64.257507, 58.77943],
                [-64.258621, 58.849998],
                [-64.300278, 58.882488],
                [-64.568069, 58.887211],
                [-64.718887, 58.945271],
                [-64.809433, 58.911098],
                [-64.878883, 58.92749],
                [-64.838898, 58.958599],
                [-64.864723, 58.990551],
                [-64.829453, 59.03249],
                [-64.720001, 59.069988],
                [-64.656387, 59.028599],
                [-64.415833, 58.981098],
                [-64.291382, 59.016659],
                [-64.365013, 59.07333],
                [-64.493057, 59.104439],
                [-64.493057, 59.158878],
                [-64.534729, 59.197491],
                [-64.507507, 59.25],
                [-64.53833, 59.285549],
                [-64.499161, 59.33971],
                [-64.538063, 59.3736],
                [-64.485001, 59.396389],
                [-64.5, 59.433048],
                [-64.340286, 59.49638],
                [-64.432503, 59.52943],
                [-64.700844, 59.44582],
                [-64.82695, 59.531109],
                [-64.773064, 59.698879],
                [-64.814163, 59.81889],
                [-64.744453, 59.84499],
                [-64.767776, 59.87999],
                [-64.661911, 59.88361],
                [-64.65361, 59.929989],
                [-64.847778, 59.964161],
                [-64.906387, 60.04805],
                [-64.796951, 60.039719],
                [-64.702499, 60.064991],
                [-64.734161, 60.103321],
                [-64.598892, 60.111111],
                [-64.637222, 60.178329],
                [-64.767227, 60.174999],
                [-64.851936, 60.22332],
                [-64.853882, 60.261669],
                [-64.534821, 60.302601]
              ]
            ],
            [
              [
                [-64.047783, 59.84943],
                [-64.032784, 59.80444],
                [-64.075562, 59.78833],
                [-63.959721, 59.75639],
                [-63.997219, 59.723598],
                [-64.134171, 59.695541],
                [-64.16333, 59.703609],
                [-64.144447, 59.727211],
                [-64.204453, 59.73444],
                [-64.187767, 59.76833],
                [-64.129173, 59.757221],
                [-64.152786, 59.788052],
                [-64.121933, 59.84943],
                [-64.047783, 59.84943]
              ]
            ],
            [
              [
                [-61.884449, 57.86694],
                [-61.881939, 57.812771],
                [-61.947491, 57.78722],
                [-62.10944, 57.829441],
                [-61.994999, 57.909161],
                [-61.928341, 57.9086],
                [-61.939991, 57.872219],
                [-61.884449, 57.86694]
              ]
            ],
            [
              [
                [-61.654171, 57.771099],
                [-61.69611, 57.712212],
                [-61.76889, 57.71693],
                [-61.827782, 57.778881],
                [-61.89695, 57.758331],
                [-61.778881, 57.845268],
                [-61.698608, 57.83028],
                [-61.723881, 57.783329],
                [-61.676392, 57.795551],
                [-61.654171, 57.771099]
              ]
            ],
            [
              [
                [-61.926949, 57.452492],
                [-62.021938, 57.521099],
                [-61.974998, 57.581379],
                [-61.931671, 57.585548],
                [-61.946949, 57.54916],
                [-61.896111, 57.586109],
                [-61.781391, 57.54805],
                [-61.783058, 57.508331],
                [-61.926949, 57.452492]
              ]
            ],
            [
              [
                [-61.64389, 57.52277],
                [-61.69138, 57.495541],
                [-61.64695, 57.482769],
                [-61.674171, 57.463879],
                [-61.78973, 57.46027],
                [-61.826389, 57.43082],
                [-61.69416, 57.441662],
                [-61.614719, 57.418598],
                [-61.648338, 57.393608],
                [-61.90028, 57.437489],
                [-61.782261, 57.486259],
                [-61.737221, 57.536942],
                [-61.64389, 57.52277]
              ]
            ],
            [
              [
                [-61.355282, 56.91082],
                [-61.40472, 56.879711],
                [-61.361671, 56.848049],
                [-61.439442, 56.816101],
                [-61.448051, 56.84166],
                [-61.493061, 56.838039],
                [-61.503059, 56.804161],
                [-61.58556, 56.766392],
                [-61.38612, 56.775829],
                [-61.36861, 56.685822],
                [-61.404999, 56.662491],
                [-61.390839, 56.618881],
                [-61.445549, 56.62027],
                [-61.644451, 56.73444],
                [-61.631939, 56.859718],
                [-61.533619, 56.902489],
                [-61.52277, 56.941929],
                [-61.46777, 56.958889],
                [-61.42889, 56.930271],
                [-61.34277, 56.93388],
                [-61.355282, 56.91082]
              ]
            ],
            [
              [
                [-61.558048, 56.551929],
                [-61.325279, 56.512211],
                [-61.360561, 56.471931],
                [-61.17445, 56.47665],
                [-61.224171, 56.450829],
                [-61.148891, 56.441101],
                [-61.474442, 56.46666],
                [-61.50333, 56.4361],
                [-61.63055, 56.465271],
                [-61.630001, 56.490551],
                [-61.417782, 56.48333],
                [-61.633331, 56.50639],
                [-61.571671, 56.51833],
                [-61.60223, 56.552769],
                [-61.558048, 56.551929]
              ]
            ],
            [
              [
                [-61.546669, 56.390831],
                [-61.47472, 56.406651],
                [-61.411671, 56.372219],
                [-61.464161, 56.345539],
                [-61.416111, 56.32222],
                [-61.482769, 56.30999],
                [-61.68639, 56.352779],
                [-61.796108, 56.413052],
                [-61.546669, 56.390831]
              ]
            ],
            [
              [
                [-61.089161, 56.169991],
                [-61.045841, 56.153881],
                [-61.06583, 56.123051],
                [-60.944439, 56.09499],
                [-61.009171, 56.035271],
                [-60.934719, 56.011379],
                [-61.040562, 56.005268],
                [-61.220829, 56.050831],
                [-61.22694, 56.098049],
                [-61.14917, 56.091099],
                [-61.183331, 56.106659],
                [-61.089161, 56.169991]
              ]
            ],
            [
              [
                [-60.747501, 55.93166],
                [-60.75111, 55.90971],
                [-60.68639, 55.92194],
                [-60.75639, 55.880268],
                [-60.902222, 55.87138],
                [-60.815281, 55.88583],
                [-60.874439, 55.90443],
                [-60.865009, 55.95277],
                [-60.747501, 55.93166]
              ]
            ],
            [
              [
                [-57.94083, 54.91193],
                [-57.988609, 54.86721],
                [-57.983891, 54.802219],
                [-57.93861, 54.82777],
                [-57.884998, 54.802219],
                [-57.864719, 54.832211],
                [-57.841671, 54.812489],
                [-57.881939, 54.79055],
                [-58.139172, 54.757221],
                [-58.22472, 54.83527],
                [-58.215839, 54.87804],
                [-58.17028, 54.85416],
                [-57.94083, 54.91193]
              ]
            ],
            [
              [
                [-55.876949, 50.86277],
                [-55.906658, 50.89666],
                [-56.097221, 50.71693],
                [-56.091671, 50.759991],
                [-56.116112, 50.746101],
                [-56.133331, 50.773048],
                [-56.103889, 50.898331],
                [-56.15527, 50.885551],
                [-56.157219, 50.69083],
                [-56.09972, 50.679722],
                [-56.108059, 50.651932],
                [-56.206108, 50.595539],
                [-56.240841, 50.524158],
                [-56.321671, 50.52388],
                [-56.25861, 50.502781],
                [-56.40472, 50.374439],
                [-56.501671, 50.382771],
                [-56.423061, 50.352779],
                [-56.462502, 50.272221],
                [-56.566391, 50.157768],
                [-56.670559, 50.129162],
                [-56.63583, 50.100552],
                [-56.743061, 50.02277],
                [-56.77861, 49.93388],
                [-56.71833, 49.949162],
                [-56.759998, 49.83638],
                [-56.906391, 49.746941],
                [-56.781391, 49.727211],
                [-56.85751, 49.545551],
                [-56.735561, 49.666939],
                [-56.477779, 49.89222],
                [-56.4175, 49.84222],
                [-56.43195, 49.890549],
                [-56.36528, 49.896938],
                [-56.38805, 49.94305],
                [-56.33028, 50.02499],
                [-56.153881, 50.150539],
                [-56.116661, 50.153049],
                [-56.062771, 50.071659],
                [-56.188332, 49.923882],
                [-56.005001, 50.03138],
                [-56.028339, 49.970539],
                [-55.977779, 49.991379],
                [-55.962219, 50.035271],
                [-55.90527, 50.033878],
                [-55.746109, 49.92305],
                [-55.671391, 49.973049],
                [-55.594158, 49.96249],
                [-55.491699, 50.007309],
                [-55.459721, 49.9575],
                [-55.492229, 49.91721],
                [-55.891109, 49.75972],
                [-56.002781, 49.748051],
                [-56.007229, 49.716099],
                [-56.160278, 49.622761],
                [-56.17223, 49.579159],
                [-56.044449, 49.669991],
                [-55.89389, 49.714161],
                [-55.95834, 49.672489],
                [-55.833328, 49.686649],
                [-55.938332, 49.603611],
                [-55.88028, 49.584991],
                [-55.937222, 49.52972],
                [-56.080002, 49.486938],
                [-56.129169, 49.425549],
                [-55.827782, 49.524158],
                [-55.720829, 49.475819],
                [-55.779999, 49.44249],
                [-55.674171, 49.466099],
                [-55.698879, 49.42749],
                [-55.66444, 49.381939],
                [-55.52306, 49.48666],
                [-55.568611, 49.41888],
                [-55.566669, 49.36277],
                [-55.48917, 49.470829],
                [-55.36945, 49.50333],
                [-55.34972, 49.487209],
                [-55.431671, 49.422489],
                [-55.316391, 49.45166],
                [-55.44416, 49.355],
                [-55.391392, 49.374161],
                [-55.373051, 49.331108],
                [-55.32806, 49.406101],
                [-55.320839, 49.380821],
                [-55.29306, 49.4011],
                [-55.301941, 49.36055],
                [-55.338612, 49.35527],
                [-55.30444, 49.311661],
                [-55.300549, 49.348598],
                [-55.233608, 49.39444],
                [-55.311111, 49.529991],
                [-55.228611, 49.53833],
                [-55.19611, 49.5],
                [-55.193611, 49.53944],
                [-55.137218, 49.540829],
                [-55.124722, 49.465271],
                [-55.23222, 49.347771],
                [-55.221111, 49.26194],
                [-55.365841, 49.165272],
                [-55.31945, 49.149158],
                [-55.339722, 49.122219],
                [-55.307781, 49.099159],
                [-55.383331, 49.040829],
                [-55.182781, 49.12027],
                [-55.272221, 49.099998],
                [-55.279999, 49.160271],
                [-55.25333, 49.18388],
                [-55.288059, 49.18721],
                [-55.058609, 49.284439],
                [-55.07806, 49.356941],
                [-55.03751, 49.321941],
                [-55.007778, 49.33971],
                [-54.990002, 49.286942],
                [-55.041672, 49.224159],
                [-54.982769, 49.257221],
                [-54.959721, 49.299438],
                [-54.909439, 49.275829],
                [-54.87167, 49.293331],
                [-54.858891, 49.332211],
                [-54.890282, 49.35416],
                [-54.848049, 49.410271],
                [-54.824451, 49.269161],
                [-54.777222, 49.29916],
                [-54.76722, 49.352489],
                [-54.739719, 49.320549],
                [-54.541111, 49.526661],
                [-54.44722, 49.543049],
                [-54.530281, 49.444988],
                [-54.43195, 49.470829],
                [-54.488049, 49.371101],
                [-54.452221, 49.333328],
                [-54.48695, 49.25972],
                [-54.400829, 49.325272],
                [-54.407501, 49.37471],
                [-54.358059, 49.426941],
                [-54.24889, 49.397491],
                [-54.219719, 49.41721],
                [-54.228882, 49.361111],
                [-54.206108, 49.402771],
                [-54.166111, 49.37804],
                [-54.139999, 49.448601],
                [-54.041672, 49.48082],
                [-53.988049, 49.458599],
                [-54.033329, 49.42749],
                [-53.92778, 49.451099],
                [-53.455559, 49.251942],
                [-53.560001, 49.124989],
                [-53.619999, 49.117489],
                [-53.581951, 49.092491],
                [-53.593891, 49.035549],
                [-53.83527, 49.03249],
                [-53.725269, 49.00972],
                [-53.888889, 48.982769],
                [-53.813061, 48.92527],
                [-53.97472, 48.89138],
                [-53.883888, 48.886662],
                [-53.980831, 48.82888],
                [-54.178051, 48.800831],
                [-54.19944, 48.768051],
                [-54.172779, 48.761669],
                [-53.990841, 48.810822],
                [-53.998341, 48.76194],
                [-53.957779, 48.772221],
                [-53.98, 48.785831],
                [-53.93417, 48.83028],
                [-53.82056, 48.829441],
                [-53.808609, 48.805271],
                [-53.91333, 48.79805],
                [-53.900551, 48.759991],
                [-53.843609, 48.772491],
                [-53.89056, 48.733601],
                [-54.022499, 48.740829],
                [-53.937771, 48.722488],
                [-53.94833, 48.666382],
                [-53.89389, 48.646389],
                [-53.932499, 48.62471],
                [-53.790001, 48.679989],
                [-53.92445, 48.671379],
                [-53.831951, 48.749439],
                [-53.85194, 48.702221],
                [-53.7425, 48.711658],
                [-53.70306, 48.685261],
                [-53.722221, 48.654991],
                [-53.59972, 48.68499],
                [-53.658611, 48.641941],
                [-53.928341, 48.575829],
                [-53.952499, 48.53944],
                [-53.715279, 48.559719],
                [-53.78944, 48.543049],
                [-53.750839, 48.507771],
                [-53.902222, 48.47971],
                [-54.01112, 48.421661],
                [-54.09444, 48.425831],
                [-54.163891, 48.383049],
                [-54.127491, 48.353321],
                [-54.075562, 48.401932],
                [-53.992229, 48.402489],
                [-53.841671, 48.470829],
                [-53.81139, 48.465],
                [-53.884449, 48.383049],
                [-53.834171, 48.371658],
                [-53.849442, 48.392769],
                [-53.768608, 48.475269],
                [-53.647221, 48.541111],
                [-53.620831, 48.534439],
                [-53.659721, 48.51305],
                [-53.618061, 48.498878],
                [-53.725269, 48.426102],
                [-53.639721, 48.43943],
                [-53.586391, 48.525269],
                [-53.587219, 48.476101],
                [-53.547501, 48.48666],
                [-53.608891, 48.40749],
                [-53.473331, 48.5075],
                [-53.42445, 48.625549],
                [-53.338051, 48.612499],
                [-53.303329, 48.58194],
                [-53.318611, 48.549438],
                [-53.240002, 48.523319],
                [-53.153881, 48.628601],
                [-53.098881, 48.64222],
                [-53.072781, 48.700272],
                [-52.976391, 48.599159],
                [-53.05389, 48.44276],
                [-53.19416, 48.348598],
                [-53.35223, 48.385269],
                [-53.376389, 48.33028],
                [-53.37888, 48.278049],
                [-53.44833, 48.27943],
                [-53.66222, 48.163319],
                [-53.940552, 48.23082],
                [-53.944019, 48.16346],
                [-53.912498, 48.08416],
                [-53.66444, 48.064159],
                [-53.76334, 48.026379],
                [-53.91917, 48.022491],
                [-53.779171, 47.996658],
                [-53.605831, 48.046391],
                [-53.692768, 47.899441],
                [-53.788059, 47.900539],
                [-53.724442, 47.883331],
                [-53.721661, 47.84943],
                [-53.793331, 47.7686],
                [-53.874439, 47.845539],
                [-53.930279, 47.849159],
                [-53.85445, 47.794998],
                [-53.82917, 47.71944],
                [-53.85751, 47.710819],
                [-53.760281, 47.609989],
                [-53.695, 47.685822],
                [-53.665001, 47.67416],
                [-53.712502, 47.598049],
                [-53.639172, 47.573051],
                [-53.66666, 47.52166],
                [-53.617771, 47.556381],
                [-53.54528, 47.534439],
                [-53.559719, 47.576939],
                [-53.535561, 47.589432],
                [-53.561939, 47.61916],
                [-53.51445, 47.614441],
                [-53.461109, 47.80666],
                [-53.398609, 47.872761],
                [-53.355831, 47.865829],
                [-53.368061, 47.893879],
                [-53.29084, 47.999439],
                [-53.153881, 48.066101],
                [-53.053329, 48.049721],
                [-52.91972, 48.169159],
                [-52.832779, 48.096939],
                [-52.9175, 48.084721],
                [-52.953892, 48.007221],
                [-53.058048, 47.922489],
                [-53.053329, 47.867771],
                [-53.202221, 47.733601],
                [-53.162769, 47.71471],
                [-53.178341, 47.651379],
                [-53.26722, 47.606098],
                [-53.20583, 47.609989],
                [-53.248341, 47.57555],
                [-53.16972, 47.597488],
                [-53.261669, 47.546391],
                [-53.185829, 47.55444],
                [-53.20639, 47.46999],
                [-53.160549, 47.50111],
                [-53.152222, 47.471931],
                [-53.199718, 47.43832],
                [-53.14584, 47.44804],
                [-53.121941, 47.413319],
                [-52.89695, 47.558601],
                [-52.779442, 47.803051],
                [-52.70055, 47.749439],
                [-52.714451, 47.6661],
                [-52.65778, 47.65749],
                [-52.646111, 47.599159],
                [-52.68861, 47.551102],
                [-52.620281, 47.500271],
                [-52.688049, 47.461109],
                [-52.650551, 47.428329],
                [-52.743061, 47.309719],
                [-52.796951, 47.300541],
                [-52.759171, 47.293331],
                [-52.810001, 47.267208],
                [-52.865551, 47.098598],
                [-52.934441, 47.086941],
                [-52.848339, 47.067768],
                [-52.873611, 47.048328],
                [-52.852779, 47.022491],
                [-52.895279, 47.001389],
                [-52.884171, 46.941662],
                [-52.938332, 46.789162],
                [-53.023891, 46.73888],
                [-53.090839, 46.64333],
                [-53.207218, 46.630268],
                [-53.266941, 46.733879],
                [-53.387779, 46.681938],
                [-53.36195, 46.737499],
                [-53.561668, 46.61277],
                [-53.617771, 46.644161],
                [-53.644169, 46.70916],
                [-53.552502, 46.864441],
                [-53.481941, 46.897221],
                [-53.5425, 46.884159],
                [-53.64806, 46.796661],
                [-53.5975, 46.90638],
                [-53.512218, 46.955551],
                [-53.563061, 46.937489],
                [-53.566952, 46.977211],
                [-53.600559, 46.94138],
                [-53.60194, 46.991661],
                [-53.64167, 46.983879],
                [-53.577782, 47.08527],
                [-53.499168, 47.123878],
                [-53.523609, 47.16777],
                [-53.57333, 47.15443],
                [-53.559441, 47.20277],
                [-53.600559, 47.128601],
                [-53.639999, 47.101662],
                [-53.630829, 47.15443],
                [-53.654171, 47.14027],
                [-53.703609, 47.053051],
                [-53.765011, 47.034439],
                [-53.95084, 46.853882],
                [-54.053329, 46.794998],
                [-54.189991, 46.823608],
                [-54.178341, 46.94276],
                [-54.012218, 47.2286],
                [-53.931671, 47.22332],
                [-53.993889, 47.26527],
                [-53.997501, 47.310268],
                [-53.92194, 47.304161],
                [-53.875561, 47.35416],
                [-53.918331, 47.352779],
                [-53.913891, 47.374161],
                [-53.80389, 47.426941],
                [-53.906391, 47.424709],
                [-53.924171, 47.451389],
                [-53.885559, 47.576939],
                [-53.89695, 47.609989],
                [-53.930279, 47.606659],
                [-54.00639, 47.81916],
                [-54.063889, 47.797218],
                [-54.081669, 47.86916],
                [-54.11417, 47.824169],
                [-54.26334, 47.9011],
                [-54.19722, 47.86277],
                [-54.21278, 47.777771],
                [-54.285561, 47.692211],
                [-54.33778, 47.698879],
                [-54.320278, 47.651661],
                [-54.348511, 47.610649],
                [-54.460281, 47.494709],
                [-54.403332, 47.45055],
                [-54.448879, 47.42083],
                [-54.453609, 47.45583],
                [-54.51722, 47.369438],
                [-54.613331, 47.36277],
                [-54.418331, 47.603611],
                [-54.62138, 47.389992],
                [-54.671391, 47.37249],
                [-54.685551, 47.396389],
                [-54.71999, 47.352219],
                [-54.818611, 47.363609],
                [-54.78751, 47.41888],
                [-54.836941, 47.372761],
                [-54.83112, 47.421101],
                [-55.043892, 47.220829],
                [-55.012218, 47.172771],
                [-55.067501, 47.147221],
                [-55.084171, 47.201931],
                [-55.134171, 47.166939],
                [-55.08556, 47.16082],
                [-55.06945, 47.082211],
                [-55.104721, 47.078049],
                [-55.15472, 47.008049],
                [-55.16972, 47.065269],
                [-55.199169, 47.070549],
                [-55.189991, 46.996101],
                [-55.230282, 46.928329],
                [-55.365841, 46.904709],
                [-55.35445, 46.879711],
                [-55.384171, 46.865829],
                [-55.463619, 46.877491],
                [-55.46806, 46.928329],
                [-55.512218, 46.912491],
                [-55.52528, 46.939709],
                [-55.548611, 46.894161],
                [-55.57362, 46.910549],
                [-55.626949, 46.868881],
                [-55.740841, 46.849998],
                [-55.931389, 46.892769],
                [-55.983059, 46.952492],
                [-55.865551, 47.072769],
                [-55.487782, 47.137211],
                [-55.29834, 47.267208],
                [-55.266659, 47.39666],
                [-55.196388, 47.449429],
                [-54.951389, 47.505001],
                [-54.845829, 47.556938],
                [-54.841381, 47.583599],
                [-54.876949, 47.570271],
                [-54.850281, 47.60722],
                [-54.69722, 47.664711],
                [-54.769169, 47.678051],
                [-54.94944, 47.59972],
                [-55.079731, 47.589989],
                [-54.936111, 47.781658],
                [-55.11916, 47.576939],
                [-55.153881, 47.604439],
                [-55.130001, 47.66193],
                [-55.176392, 47.635269],
                [-55.31889, 47.668049],
                [-55.363892, 47.649158],
                [-55.35751, 47.726101],
                [-55.392502, 47.691101],
                [-55.427502, 47.711658],
                [-55.422501, 47.672771],
                [-55.467499, 47.61916],
                [-55.40028, 47.615551],
                [-55.432499, 47.581379],
                [-55.389721, 47.58638],
                [-55.406658, 47.49305],
                [-55.521111, 47.459721],
                [-55.58778, 47.398609],
                [-55.58778, 47.453049],
                [-55.664162, 47.436939],
                [-55.588612, 47.510281],
                [-55.62833, 47.541382],
                [-55.654171, 47.49527],
                [-55.70528, 47.502491],
                [-55.70528, 47.475269],
                [-55.763618, 47.454708],
                [-55.763062, 47.53833],
                [-55.80167, 47.45916],
                [-55.85778, 47.473598],
                [-55.92556, 47.439159],
                [-55.745831, 47.58527],
                [-56.104172, 47.463612],
                [-56.16917, 47.492489],
                [-55.805561, 47.605831],
                [-55.62833, 47.674709],
                [-55.83889, 47.61721],
                [-55.9175, 47.65749],
                [-55.830559, 47.732769],
                [-55.79945, 47.79916],
                [-55.821949, 47.834431],
                [-55.742229, 47.923321],
                [-55.773609, 47.95694],
                [-55.85445, 47.79472],
                [-55.987499, 47.7575],
                [-56.054722, 47.695541],
                [-56.10667, 47.693321],
                [-56.111111, 47.724159],
                [-56.053612, 47.77388],
                [-56.100559, 47.750271],
                [-56.15694, 47.832211],
                [-56.125, 47.757221],
                [-56.168331, 47.715832],
                [-56.141941, 47.684719],
                [-56.191109, 47.676941],
                [-56.161381, 47.634159],
                [-56.216389, 47.65527],
                [-56.232498, 47.625549],
                [-56.29945, 47.62249],
                [-56.309441, 47.7486],
                [-56.334171, 47.695271],
                [-56.310001, 47.639431],
                [-56.34528, 47.60833],
                [-56.682499, 47.623051],
                [-56.639999, 47.587212],
                [-56.67889, 47.57444],
                [-56.756672, 47.629429],
                [-56.751579, 47.589741],
                [-56.70639, 47.56554],
                [-56.779442, 47.526661],
                [-56.849171, 47.522491],
                [-56.85194, 47.54805],
                [-56.9925, 47.583881],
                [-57.12611, 47.563881],
                [-57.168331, 47.583599],
                [-57.141941, 47.598598],
                [-57.171391, 47.632488],
                [-57.184441, 47.598331],
                [-57.312771, 47.58083],
                [-57.334999, 47.639431],
                [-57.488892, 47.6236],
                [-57.53722, 47.64777],
                [-57.64389, 47.597488],
                [-57.738892, 47.653599],
                [-57.77055, 47.614159],
                [-57.916389, 47.648609],
                [-57.884171, 47.696659],
                [-57.952499, 47.661659],
                [-58.036388, 47.696098],
                [-58.074169, 47.666939],
                [-58.07917, 47.695271],
                [-58.353889, 47.643608],
                [-58.272221, 47.751659],
                [-58.338329, 47.745541],
                [-58.430561, 47.633049],
                [-58.535278, 47.666382],
                [-58.535, 47.631939],
                [-58.60611, 47.61166],
                [-58.642231, 47.62804],
                [-58.690552, 47.598881],
                [-59.118889, 47.55471],
                [-59.30444, 47.609989],
                [-59.28083, 47.751659],
                [-59.322231, 47.761108],
                [-59.400829, 47.906651],
                [-59.316391, 47.934158],
                [-59.218609, 48.02916],
                [-58.978882, 48.123878],
                [-58.58778, 48.43055],
                [-58.492771, 48.450272],
                [-58.591942, 48.39888],
                [-58.483608, 48.427769],
                [-58.418331, 48.48666],
                [-58.26556, 48.51749],
                [-58.519718, 48.500549],
                [-58.56945, 48.538601],
                [-58.682499, 48.554989],
                [-59.246948, 48.46666],
                [-59.228882, 48.52916],
                [-58.774719, 48.778881],
                [-58.958611, 48.61388],
                [-58.89584, 48.588879],
                [-58.89695, 48.551929],
                [-58.848339, 48.588879],
                [-58.848049, 48.634991],
                [-58.81778, 48.569988],
                [-58.709438, 48.570831],
                [-58.658051, 48.74305],
                [-58.543892, 48.860821],
                [-58.500839, 49.003609],
                [-58.35194, 49.150269],
                [-58.360001, 49.056938],
                [-58.17194, 49.061939],
                [-58.06028, 48.976101],
                [-57.961391, 48.956661],
                [-57.892231, 48.981659],
                [-58.00922, 48.980419],
                [-58.108059, 49.037769],
                [-58.144451, 49.121929],
                [-57.92556, 49.123051],
                [-57.849442, 49.181938],
                [-57.939442, 49.145],
                [-57.96999, 49.16415],
                [-57.924171, 49.18832],
                [-58.09111, 49.150829],
                [-58.10722, 49.183048],
                [-57.99472, 49.180271],
                [-57.928051, 49.240269],
                [-58.03944, 49.21249],
                [-58.20084, 49.239719],
                [-58.24194, 49.29166],
                [-58.223331, 49.39027],
                [-58.152222, 49.46888],
                [-57.998341, 49.559158],
                [-57.92028, 49.527771],
                [-57.918331, 49.465832],
                [-57.88055, 49.428051],
                [-57.906109, 49.477489],
                [-57.86306, 49.506939],
                [-57.746948, 49.453609],
                [-57.696388, 49.463612],
                [-57.788891, 49.500832],
                [-57.824718, 49.559719],
                [-57.877491, 49.528332],
                [-57.944721, 49.607498],
                [-57.94833, 49.67416],
                [-57.601109, 50.174999],
                [-57.526661, 50.364719],
                [-57.372501, 50.590832],
                [-57.150829, 50.6161],
                [-57.261951, 50.613331],
                [-57.277222, 50.630268],
                [-57.240841, 50.641109],
                [-57.39806, 50.69249],
                [-57.351109, 50.723598],
                [-57.230282, 50.72971],
                [-57.089439, 50.780548],
                [-56.977219, 50.8736],
                [-56.972771, 50.909161],
                [-56.927502, 50.915821],
                [-56.989719, 50.929161],
                [-56.966942, 50.968601],
                [-57.089161, 51.019989],
                [-57.004719, 51.016109],
                [-56.976662, 50.98555],
                [-56.902222, 51.013329],
                [-56.978882, 51.05027],
                [-56.880001, 51.06554],
                [-56.860828, 51.113609],
                [-56.784451, 51.137772],
                [-56.80666, 51.18943],
                [-56.746391, 51.204441],
                [-56.793892, 51.23999],
                [-56.688889, 51.336941],
                [-56.110561, 51.52388],
                [-55.890282, 51.629429],
                [-55.822781, 51.618599],
                [-55.884998, 51.562489],
                [-55.841942, 51.545269],
                [-55.877781, 51.492222],
                [-55.620281, 51.472759],
                [-55.73917, 51.55666],
                [-55.6175, 51.610279],
                [-55.653332, 51.590549],
                [-55.625, 51.56554],
                [-55.515839, 51.602219],
                [-55.404442, 51.571941],
                [-55.41917, 51.548328],
                [-55.467499, 51.56361],
                [-55.432499, 51.53722],
                [-55.45084, 51.499161],
                [-55.533058, 51.500832],
                [-55.452499, 51.460819],
                [-55.50806, 51.363331],
                [-55.562222, 51.38805],
                [-55.55917, 51.34277],
                [-55.609169, 51.344151],
                [-55.597778, 51.3036],
                [-55.759449, 51.346378],
                [-56.08556, 51.36832],
                [-56.09639, 51.318329],
                [-56.025002, 51.308601],
                [-56.010281, 51.270271],
                [-56.075279, 51.227211],
                [-56.013062, 51.213329],
                [-55.965, 51.153049],
                [-55.941669, 51.15527],
                [-55.959438, 51.197491],
                [-55.83778, 51.230549],
                [-55.709999, 51.173611],
                [-55.746948, 51.158039],
                [-55.719158, 51.123051],
                [-55.735561, 51.074169],
                [-55.810829, 51.056381],
                [-55.77639, 51.029991],
                [-55.836109, 50.968319],
                [-55.819172, 50.92527],
                [-55.859169, 50.94249],
                [-55.87389, 50.908329],
                [-55.844719, 50.90416],
                [-55.876949, 50.86277]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Newfoundland and Labrador",
          "density": "1.37",
          "cartodb_id": 5,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-131.077103, 52.150089],
                [-130.948105, 52.093609],
                [-130.992493, 52.060822],
                [-131.070007, 52.07222],
                [-130.985504, 52.012211],
                [-131.041702, 52.022491],
                [-131.007004, 51.97887],
                [-131.0392, 51.96693],
                [-131.021698, 51.94083],
                [-131.110306, 51.999161],
                [-131.085007, 52.023319],
                [-131.120499, 52.05555],
                [-131.122696, 52.123779],
                [-131.077103, 52.150089]
              ]
            ],
            [
              [
                [-130.164993, 55.01416],
                [-130.149704, 54.947491],
                [-130.270294, 54.83083],
                [-130.3992, 54.764721],
                [-130.459396, 54.817501],
                [-130.213303, 55.012501],
                [-130.164993, 55.01416]
              ]
            ],
            [
              [
                [-130.458603, 54.80027],
                [-130.435806, 54.763329],
                [-130.463303, 54.708599],
                [-130.538605, 54.70388],
                [-130.610504, 54.753052],
                [-130.458603, 54.80027]
              ]
            ],
            [
              [
                [-130.746902, 54.615269],
                [-130.760803, 54.55471],
                [-130.964996, 54.45694],
                [-130.925598, 54.619438],
                [-130.746902, 54.615269]
              ]
            ],
            [
              [
                [-132.8806, 53.600269],
                [-133.007797, 53.67638],
                [-132.925293, 53.690269],
                [-132.979401, 53.710819],
                [-132.972794, 53.763889],
                [-133.0336, 53.752781],
                [-133.109207, 53.786942],
                [-133.067001, 53.820831],
                [-133.134705, 53.87027],
                [-133.139999, 53.908039],
                [-133.090805, 53.954159],
                [-133.102798, 53.994999],
                [-133.041107, 54.031658],
                [-133.081894, 54.102489],
                [-133.029205, 54.158878],
                [-133.0672, 54.173882],
                [-132.827499, 54.12249],
                [-132.658401, 54.14222],
                [-132.575302, 54.115551],
                [-132.566696, 54.02916],
                [-132.6642, 53.98333],
                [-132.681702, 53.95277],
                [-132.658905, 53.93943],
                [-132.409698, 54.098598],
                [-132.290604, 54.11055],
                [-132.228302, 54.06583],
                [-132.232803, 54.025269],
                [-132.162506, 54.005268],
                [-132.111099, 53.87833],
                [-132.225006, 53.78027],
                [-132.6633, 53.67944],
                [-132.595001, 53.672771],
                [-132.599197, 53.626659],
                [-132.541702, 53.66444],
                [-132.491898, 53.64222],
                [-132.350296, 53.668331],
                [-132.463593, 53.61277],
                [-132.468307, 53.570831],
                [-132.418106, 53.606098],
                [-132.279205, 53.623322],
                [-132.328293, 53.64333],
                [-132.315308, 53.666939],
                [-132.200302, 53.65221],
                [-132.155594, 53.699711],
                [-132.190002, 53.783051],
                [-132.079407, 53.889431],
                [-132.133606, 54.010551],
                [-132.177505, 54.04055],
                [-131.9711, 54.025269],
                [-131.727798, 54.106098],
                [-131.655899, 54.15971],
                [-131.705002, 53.96693],
                [-131.868896, 53.793331],
                [-131.865494, 53.721661],
                [-131.933304, 53.615269],
                [-131.911102, 53.351662],
                [-131.982193, 53.251659],
                [-132.210495, 53.230549],
                [-132.272797, 53.21027],
                [-132.237793, 53.188881],
                [-132.124405, 53.194149],
                [-132.186996, 53.160549],
                [-132.316406, 53.189709],
                [-132.3228, 53.159431],
                [-132.446701, 53.143608],
                [-132.459198, 53.18388],
                [-132.499405, 53.159161],
                [-132.536697, 53.178879],
                [-132.520798, 53.193321],
                [-132.577805, 53.20055],
                [-132.549698, 53.23138],
                [-132.703094, 53.257771],
                [-132.609207, 53.314991],
                [-132.537003, 53.32111],
                [-132.752808, 53.316101],
                [-132.697495, 53.376942],
                [-132.514206, 53.358601],
                [-132.410599, 53.29472],
                [-132.400299, 53.33083],
                [-132.520798, 53.412209],
                [-132.660004, 53.434719],
                [-132.660599, 53.489429],
                [-132.688293, 53.44833],
                [-132.752808, 53.4575],
                [-132.719101, 53.490269],
                [-132.743607, 53.515549],
                [-132.867493, 53.463612],
                [-132.823593, 53.516941],
                [-132.923599, 53.519161],
                [-132.889999, 53.55777],
                [-132.968307, 53.551659],
                [-132.994095, 53.587769],
                [-132.8806, 53.600269]
              ]
            ],
            [
              [
                [-130.336105, 54.067501],
                [-130.228897, 53.96471],
                [-130.344498, 53.83527],
                [-130.406403, 53.83416],
                [-130.4272, 53.871658],
                [-130.483597, 53.84388],
                [-130.497498, 53.8736],
                [-130.449997, 53.888599],
                [-130.407806, 53.963329],
                [-130.327499, 53.96693],
                [-130.349197, 53.997219],
                [-130.443893, 53.9786],
                [-130.482498, 53.893608],
                [-130.667007, 53.963879],
                [-130.698105, 53.91415],
                [-130.617996, 53.85722],
                [-130.642502, 53.832771],
                [-130.710297, 53.85638],
                [-130.720306, 53.934719],
                [-130.661697, 53.990829],
                [-130.411102, 54.10083],
                [-130.336105, 54.067501]
              ]
            ],
            [
              [
                [-129.479996, 53.249161],
                [-129.491394, 53.209431],
                [-129.572495, 53.20916],
                [-129.658905, 53.29694],
                [-129.635605, 53.311939],
                [-129.660294, 53.342491],
                [-129.711105, 53.33416],
                [-129.807205, 53.384159],
                [-129.815796, 53.466381],
                [-129.895004, 53.477489],
                [-129.8703, 53.545551],
                [-129.922501, 53.563049],
                [-129.883896, 53.57972],
                [-129.936401, 53.587212],
                [-129.911697, 53.611382],
                [-130.016098, 53.572769],
                [-130.056396, 53.603882],
                [-129.965393, 53.641781],
                [-129.874405, 53.638889],
                [-129.9496, 53.659248],
                [-130.083893, 53.641109],
                [-130.099197, 53.702221],
                [-130.209702, 53.72887],
                [-130.2108, 53.810822],
                [-130.286407, 53.83749],
                [-130.266403, 53.881378],
                [-130.186401, 53.9161],
                [-130.030899, 53.845268],
                [-129.523605, 53.496658],
                [-129.635605, 53.480549],
                [-129.491898, 53.470268],
                [-129.425293, 53.43082],
                [-129.453598, 53.296661],
                [-129.516403, 53.302219],
                [-129.479996, 53.249161]
              ]
            ],
            [
              [
                [-130.271896, 53.797779],
                [-130.2258, 53.77499],
                [-130.225006, 53.720268],
                [-130.1194, 53.686649],
                [-130.091095, 53.569439],
                [-130.329193, 53.61805],
                [-130.403397, 53.682499],
                [-130.287506, 53.748878],
                [-130.301697, 53.79388],
                [-130.271896, 53.797779]
              ]
            ],
            [
              [
                [-128.823593, 53.70055],
                [-128.872192, 53.661098],
                [-128.871597, 53.57222],
                [-128.986404, 53.58083],
                [-129.085495, 53.505001],
                [-129.086395, 53.446098],
                [-129.163101, 53.38221],
                [-129.153107, 53.638599],
                [-129.008102, 53.693321],
                [-128.823593, 53.70055]
              ]
            ],
            [
              [
                [-130.391098, 53.61694],
                [-129.920593, 53.42416],
                [-129.729202, 53.215271],
                [-129.742493, 53.178329],
                [-129.931702, 53.158039],
                [-130.014496, 53.246941],
                [-130.078903, 53.270271],
                [-130.094498, 53.325829],
                [-130.140594, 53.321941],
                [-130.203293, 53.37888],
                [-130.305801, 53.384159],
                [-130.412003, 53.49332],
                [-130.333893, 53.514999],
                [-130.458603, 53.514721],
                [-130.527206, 53.552219],
                [-130.508408, 53.631939],
                [-130.391098, 53.61694]
              ]
            ],
            [
              [
                [-128.985504, 53.523048],
                [-128.901093, 53.386662],
                [-128.918106, 53.331379],
                [-129.122192, 53.31805],
                [-129.134506, 53.383881],
                [-129.054993, 53.450829],
                [-129.037796, 53.520271],
                [-128.985504, 53.523048]
              ]
            ],
            [
              [
                [-129.168594, 53.296101],
                [-129.131897, 53.113609],
                [-129.274994, 53.084431],
                [-129.332504, 53.137501],
                [-129.338303, 53.18499],
                [-129.245499, 53.282211],
                [-129.273895, 53.328049],
                [-129.226898, 53.326099],
                [-129.168594, 53.296101]
              ]
            ],
            [
              [
                [-128.593597, 52.659161],
                [-128.597198, 52.609161],
                [-128.751694, 52.60083],
                [-128.727798, 52.662491],
                [-128.748001, 52.754169],
                [-128.644699, 52.90416],
                [-128.653397, 52.96666],
                [-128.679993, 52.957771],
                [-128.681107, 52.898609],
                [-128.742798, 52.91444],
                [-128.724197, 52.876659],
                [-128.780594, 52.73999],
                [-128.766098, 52.661659],
                [-128.801102, 52.693321],
                [-128.892502, 52.64888],
                [-129.080307, 52.772491],
                [-129.119995, 52.86916],
                [-129.016998, 52.84444],
                [-129.068298, 52.889992],
                [-128.983902, 52.934719],
                [-128.941406, 53],
                [-128.836395, 53.035549],
                [-129.011703, 53.139721],
                [-128.993896, 53.09721],
                [-128.862198, 53.034161],
                [-128.957199, 53.008331],
                [-129.069199, 52.903881],
                [-129.165802, 52.92305],
                [-129.188004, 53.024158],
                [-129.161697, 53.07111],
                [-129.115005, 53.064709],
                [-129.073303, 53.109161],
                [-129.058899, 53.23138],
                [-129.086105, 53.29361],
                [-128.891403, 53.287769],
                [-128.705307, 53.170551],
                [-128.605606, 53.145271],
                [-128.531693, 53.021099],
                [-128.517807, 52.92749],
                [-128.593597, 52.659161]
              ]
            ],
            [
              [
                [-131.074402, 52.219379],
                [-131.014999, 52.225552],
                [-131.024704, 52.178051],
                [-131.113907, 52.16777],
                [-131.136993, 52.209721],
                [-131.143906, 52.140831],
                [-131.189697, 52.121929],
                [-131.212204, 52.191929],
                [-131.277206, 52.240829],
                [-131.232193, 52.16193],
                [-131.236893, 52.126942],
                [-131.270798, 52.120831],
                [-131.349197, 52.233051],
                [-131.360306, 52.189159],
                [-131.505905, 52.321659],
                [-131.551102, 52.323879],
                [-131.532501, 52.351391],
                [-131.571106, 52.350269],
                [-131.580597, 52.390549],
                [-131.510605, 52.408878],
                [-131.613007, 52.422771],
                [-131.661697, 52.478321],
                [-131.775299, 52.511669],
                [-131.892807, 52.582771],
                [-131.933594, 52.653881],
                [-132.081894, 52.727489],
                [-132.040298, 52.760551],
                [-131.968597, 52.726101],
                [-132.002502, 52.776939],
                [-131.917496, 52.722488],
                [-131.951706, 52.781109],
                [-132.077194, 52.854439],
                [-132.055603, 52.79361],
                [-132.127502, 52.750832],
                [-132.236694, 52.824711],
                [-132.2258, 52.864441],
                [-132.283295, 52.87471],
                [-132.343002, 52.942211],
                [-132.2453, 52.95388],
                [-132.171097, 52.923611],
                [-132.199997, 52.960548],
                [-132.141098, 52.93277],
                [-132.157501, 52.964161],
                [-132.112793, 52.997768],
                [-132.339996, 53.037769],
                [-132.479706, 53.02721],
                [-132.552795, 53.08971],
                [-132.5289, 53.112209],
                [-132.562195, 53.145271],
                [-132.413605, 53.127491],
                [-132.070801, 53.153881],
                [-131.985001, 53.212212],
                [-131.794495, 53.251389],
                [-131.762207, 53.196659],
                [-131.784195, 53.164711],
                [-131.637497, 53.109718],
                [-131.594696, 53.035271],
                [-131.794693, 53.073051],
                [-131.998901, 53.05138],
                [-131.945801, 53.04166],
                [-131.917007, 52.984718],
                [-131.847794, 53.043049],
                [-131.644699, 53.00388],
                [-131.598099, 52.975269],
                [-131.628601, 52.94276],
                [-131.611694, 52.926102],
                [-131.6875, 52.86916],
                [-131.8022, 52.864719],
                [-131.8983, 52.941101],
                [-131.944702, 52.93166],
                [-131.891403, 52.909988],
                [-131.982498, 52.879711],
                [-131.886993, 52.88916],
                [-131.852493, 52.861938],
                [-131.880798, 52.833328],
                [-131.812195, 52.797218],
                [-131.6492, 52.800541],
                [-131.851105, 52.766109],
                [-131.806396, 52.748329],
                [-131.837799, 52.710819],
                [-131.726898, 52.695541],
                [-131.681396, 52.637501],
                [-131.705002, 52.621658],
                [-131.615295, 52.56889],
                [-131.660599, 52.538891],
                [-131.467499, 52.501389],
                [-131.483597, 52.479431],
                [-131.415604, 52.45805],
                [-131.447006, 52.390549],
                [-131.391403, 52.390831],
                [-131.397202, 52.35305],
                [-131.347504, 52.36805],
                [-131.353104, 52.418331],
                [-131.236404, 52.439159],
                [-131.301102, 52.38055],
                [-131.304199, 52.356659],
                [-131.247498, 52.365829],
                [-131.263107, 52.34277],
                [-131.358902, 52.343609],
                [-131.328293, 52.285],
                [-131.226898, 52.251942],
                [-131.248306, 52.302219],
                [-131.136398, 52.311378],
                [-131.089401, 52.249161],
                [-131.180298, 52.228321],
                [-131.074402, 52.219379]
              ]
            ],
            [
              [
                [-129.462799, 53.179989],
                [-129.313004, 53.057499],
                [-129.289505, 52.971931],
                [-129.4142, 53.010551],
                [-129.475296, 53.10194],
                [-129.544693, 53.12833],
                [-129.521698, 53.183601],
                [-129.462799, 53.179989]
              ]
            ],
            [
              [
                [-128.498901, 52.870831],
                [-128.450806, 52.805271],
                [-128.512497, 52.641109],
                [-128.539993, 52.70332],
                [-128.498901, 52.870831]
              ]
            ],
            [
              [
                [-128.170303, 52.817768],
                [-128.247192, 52.620541],
                [-128.2845, 52.598598],
                [-128.324997, 52.7761],
                [-128.225006, 52.782211],
                [-128.170303, 52.817768]
              ]
            ],
            [
              [
                [-129.231094, 52.816101],
                [-128.923294, 52.606659],
                [-128.943604, 52.469711],
                [-128.982803, 52.453049],
                [-129.008896, 52.525551],
                [-129.109695, 52.552769],
                [-129.118896, 52.60471],
                [-129.2108, 52.64888],
                [-129.224197, 52.67749],
                [-129.193893, 52.683048],
                [-129.270599, 52.719151],
                [-129.281403, 52.817211],
                [-129.231094, 52.816101]
              ]
            ],
            [
              [
                [-128.373306, 52.791111],
                [-128.274994, 52.48999],
                [-128.365799, 52.38221],
                [-128.441696, 52.36805],
                [-128.467804, 52.393051],
                [-128.394196, 52.465],
                [-128.423096, 52.474991],
                [-128.437805, 52.54361],
                [-128.450607, 52.626942],
                [-128.418304, 52.70805],
                [-128.441696, 52.746941],
                [-128.373306, 52.791111]
              ]
            ],
            [
              [
                [-131.468903, 52.730549],
                [-131.439194, 52.70166],
                [-131.458298, 52.630821],
                [-131.5905, 52.662491],
                [-131.593307, 52.63361],
                [-131.547806, 52.611382],
                [-131.585495, 52.58527],
                [-131.709702, 52.705269],
                [-131.574707, 52.72694],
                [-131.674698, 52.70499],
                [-131.549393, 52.688042],
                [-131.468903, 52.730549]
              ]
            ],
            [
              [
                [-128.535294, 52.647221],
                [-128.464996, 52.473049],
                [-128.486893, 52.43721],
                [-128.536407, 52.43499],
                [-128.6828, 52.514439],
                [-128.714706, 52.483879],
                [-128.813004, 52.519989],
                [-128.802505, 52.546101],
                [-128.697006, 52.520828],
                [-128.738907, 52.58749],
                [-128.577499, 52.593609],
                [-128.552505, 52.665272],
                [-128.535294, 52.647221]
              ]
            ],
            [
              [
                [-128.616104, 52.448601],
                [-128.617798, 52.32999],
                [-128.675598, 52.266392],
                [-128.726395, 52.316101],
                [-128.759201, 52.449429],
                [-128.674393, 52.482208],
                [-128.616104, 52.448601]
              ]
            ],
            [
              [
                [-127.596298, 52.151791],
                [-127.6539, 52.130821],
                [-127.717499, 51.98027],
                [-127.8797, 51.944988],
                [-127.9011, 51.985821],
                [-127.8797, 52.084991],
                [-127.7892, 52.221931],
                [-127.510002, 52.306938],
                [-127.400299, 52.395271],
                [-127.263901, 52.436649],
                [-127.234703, 52.416939],
                [-127.244499, 52.36916],
                [-127.207802, 52.344711],
                [-127.232201, 52.313049],
                [-127.596298, 52.151791]
              ]
            ],
            [
              [
                [-128.092499, 52.408329],
                [-128.056702, 52.32888],
                [-128.213593, 52.27972],
                [-128.1147, 52.418331],
                [-128.092499, 52.408329]
              ]
            ],
            [
              [
                [-127.961899, 52.28944],
                [-127.907501, 52.204708],
                [-127.924698, 52.17416],
                [-128.092804, 52.192211],
                [-128.011703, 52.23193],
                [-128.046402, 52.258049],
                [-128.169998, 52.249722],
                [-128.047806, 52.300541],
                [-127.961899, 52.28944]
              ]
            ],
            [
              [
                [-128.148102, 52.183331],
                [-128.151093, 52.081108],
                [-128.2061, 52.018879],
                [-128.253098, 52.019711],
                [-128.291702, 52.118881],
                [-128.2397, 52.184158],
                [-128.148102, 52.183331]
              ]
            ],
            [
              [
                [-128.031693, 52.163288],
                [-127.879204, 52.16666],
                [-127.962799, 52.074711],
                [-128.105804, 52.100269],
                [-128.1147, 52.149158],
                [-128.031693, 52.163288]
              ]
            ],
            [
              [
                [-127.951202, 52.046909],
                [-127.970596, 51.925831],
                [-128.032196, 51.840549],
                [-128.0186, 51.770271],
                [-128.131897, 51.743881],
                [-128.110504, 51.801929],
                [-128.132797, 51.85527],
                [-128.254501, 51.865829],
                [-128.199203, 51.913052],
                [-128.222198, 51.95332],
                [-128.145294, 52.038601],
                [-127.951202, 52.046909]
              ]
            ],
            [
              [
                [-127.998001, 51.711658],
                [-127.873596, 51.464439],
                [-127.914398, 51.41082],
                [-128.003403, 51.468601],
                [-128.0811, 51.474709],
                [-128.153107, 51.601662],
                [-128.136398, 51.661659],
                [-127.998001, 51.711658]
              ]
            ],
            [
              [
                [-127.121101, 49.852291],
                [-127.241402, 49.961941],
                [-127.178596, 50.009159],
                [-127.176697, 50.0611],
                [-127.091904, 50.09943],
                [-127.090302, 50.141941],
                [-127.172501, 50.09193],
                [-127.292503, 50.146389],
                [-127.277496, 50.055271],
                [-127.380501, 50.0261],
                [-127.470802, 50.08543],
                [-127.420799, 50.12804],
                [-127.485001, 50.12804],
                [-127.428596, 50.180271],
                [-127.596901, 50.115551],
                [-127.635597, 50.135551],
                [-127.609703, 50.180271],
                [-127.650803, 50.175549],
                [-127.788101, 50.081669],
                [-127.906998, 50.120831],
                [-127.745003, 50.24194],
                [-127.8442, 50.275551],
                [-127.804398, 50.321381],
                [-127.931396, 50.316101],
                [-127.9786, 50.342491],
                [-127.923897, 50.462769],
                [-127.584198, 50.486938],
                [-127.446999, 50.372761],
                [-127.57, 50.512211],
                [-127.490303, 50.56805],
                [-127.4142, 50.575829],
                [-127.419403, 50.596661],
                [-127.553001, 50.56805],
                [-127.596703, 50.596939],
                [-127.873299, 50.623878],
                [-127.786697, 50.582211],
                [-127.634499, 50.578049],
                [-127.595596, 50.536659],
                [-127.995102, 50.466389],
                [-128.058594, 50.486382],
                [-128.051407, 50.44669],
                [-128.224197, 50.531109],
                [-128.241394, 50.58749],
                [-128.3367, 50.63694],
                [-128.272797, 50.66972],
                [-128.375305, 50.6786],
                [-128.416702, 50.769161],
                [-128.064194, 50.872219],
                [-127.909698, 50.87138],
                [-127.507202, 50.772221],
                [-127.477097, 50.700069],
                [-127.408897, 50.746101],
                [-127.407204, 50.700001],
                [-127.200302, 50.61277],
                [-126.854698, 50.55444],
                [-126.852501, 50.523319],
                [-126.776398, 50.546101],
                [-126.592499, 50.47694],
                [-126.327797, 50.48082],
                [-126.069199, 50.438599],
                [-125.9478, 50.384991],
                [-125.725601, 50.352219],
                [-125.564201, 50.36388],
                [-125.431702, 50.310551],
                [-125.351898, 50.149158],
                [-125.368301, 50.125271],
                [-125.300598, 50.10527],
                [-125.168602, 49.912769],
                [-125, 49.796299],
                [-124.866402, 49.711109],
                [-124.883904, 49.666382],
                [-124.977203, 49.668598],
                [-124.789398, 49.464161],
                [-124.716698, 49.463051],
                [-124.535599, 49.373878],
                [-124.170502, 49.311939],
                [-124.110802, 49.275269],
                [-124.175598, 49.255829],
                [-123.957802, 49.228321],
                [-123.909698, 49.127491],
                [-123.810303, 49.115829],
                [-123.749397, 49.035271],
                [-123.757004, 48.986938],
                [-123.8311, 48.997219],
                [-123.582802, 48.83194],
                [-123.603302, 48.79583],
                [-123.565598, 48.789719],
                [-123.567497, 48.744438],
                [-123.648102, 48.750271],
                [-123.538101, 48.691662],
                [-123.509499, 48.58749],
                [-123.535004, 48.529991],
                [-123.4758, 48.568329],
                [-123.4767, 48.63166],
                [-123.439201, 48.647221],
                [-123.4692, 48.67944],
                [-123.4011, 48.681381],
                [-123.341698, 48.508888],
                [-123.2761, 48.451099],
                [-123.336403, 48.396938],
                [-123.443001, 48.443321],
                [-123.546097, 48.30777],
                [-123.583298, 48.301102],
                [-123.762497, 48.36166],
                [-123.924202, 48.3661],
                [-124.423599, 48.516941],
                [-124.438301, 48.532768],
                [-124.396103, 48.56583],
                [-124.489403, 48.53944],
                [-124.688599, 48.578331],
                [-124.8228, 48.652489],
                [-125.113899, 48.731098],
                [-125.184998, 48.800831],
                [-125.005997, 48.88903],
                [-125, 48.939468],
                [-124.842796, 49.015549],
                [-124.7808, 49.131378],
                [-124.8078, 49.240551],
                [-124.826103, 49.12249],
                [-124.906097, 49.001942],
                [-125.092201, 48.972488],
                [-125.150597, 48.996941],
                [-125.241898, 48.95166],
                [-125.331902, 48.96999],
                [-125.258301, 49.01833],
                [-125.3414, 49.03611],
                [-125.371902, 48.97916],
                [-125.483597, 48.915821],
                [-125.737801, 49.049999],
                [-125.8769, 49.088329],
                [-125.858299, 49.110821],
                [-125.738098, 49.105549],
                [-125.605797, 49.21027],
                [-125.753304, 49.147491],
                [-125.735001, 49.257221],
                [-125.802498, 49.255001],
                [-125.776901, 49.365829],
                [-125.846397, 49.25816],
                [-125.894402, 49.273609],
                [-125.970299, 49.224709],
                [-126.0233, 49.268051],
                [-125.945503, 49.316669],
                [-125.904701, 49.435822],
                [-126.001999, 49.321941],
                [-126.065002, 49.348331],
                [-126.052803, 49.446098],
                [-126.1903, 49.400829],
                [-126.258598, 49.445541],
                [-126.296997, 49.357769],
                [-126.403603, 49.411098],
                [-126.422501, 49.470268],
                [-126.480301, 49.44305],
                [-126.466103, 49.380268],
                [-126.542198, 49.374439],
                [-126.577797, 49.407768],
                [-126.555, 49.44471],
                [-126.566101, 49.584431],
                [-126.470299, 49.635551],
                [-126.134201, 49.64999],
                [-126.086899, 49.657211],
                [-126.103104, 49.679161],
                [-126.324997, 49.64888],
                [-126.438301, 49.66415],
                [-126.483101, 49.702492],
                [-126.408897, 49.770271],
                [-126.456398, 49.78833],
                [-126.4972, 49.712769],
                [-126.584999, 49.701099],
                [-126.645599, 49.892769],
                [-126.731697, 49.880268],
                [-126.829498, 49.96471],
                [-126.800797, 49.913879],
                [-126.845596, 49.88221],
                [-126.884201, 49.879162],
                [-126.914703, 49.965832],
                [-126.9394, 49.86277],
                [-127.121101, 49.852291]
              ]
            ],
            [
              [
                [-126.797798, 50.768879],
                [-126.8647, 50.78944],
                [-126.9058, 50.822769],
                [-126.881401, 50.83028],
                [-126.794197, 50.80471],
                [-126.779198, 50.831669],
                [-126.643303, 50.846939],
                [-126.563004, 50.799999],
                [-126.797798, 50.768879]
              ]
            ],
            [
              [
                [-126.251701, 50.818932],
                [-126.176697, 50.743881],
                [-126.243896, 50.717209],
                [-126.266403, 50.654709],
                [-126.6175, 50.664989],
                [-126.389702, 50.806381],
                [-126.251701, 50.818932]
              ]
            ],
            [
              [
                [-126.336403, 50.52166],
                [-126.623901, 50.533878],
                [-126.286102, 50.598331],
                [-126.224701, 50.55666],
                [-126.336403, 50.52166]
              ]
            ],
            [
              [
                [-125.370796, 50.45583],
                [-125.330597, 50.42527],
                [-125.385803, 50.369709],
                [-125.457802, 50.34943],
                [-125.536903, 50.379162],
                [-125.370796, 50.45583]
              ]
            ],
            [
              [
                [-125.273102, 50.43111],
                [-125.168098, 50.382771],
                [-125.051903, 50.22665],
                [-125.049202, 50.193321],
                [-125.088303, 50.197208],
                [-125.133904, 50.122761],
                [-125.154198, 50.133331],
                [-125.1269, 50.21944],
                [-125.2136, 50.316669],
                [-125.393097, 50.29583],
                [-125.388603, 50.343048],
                [-125.313301, 50.340832],
                [-125.320602, 50.385551],
                [-125.273102, 50.43111]
              ]
            ],
            [
              [
                [-124.898598, 50.29388],
                [-124.811401, 50.275551],
                [-124.820297, 50.20583],
                [-124.756699, 50.178329],
                [-124.806999, 50.11388],
                [-124.870796, 50.156651],
                [-124.834396, 50.192211],
                [-124.915604, 50.188881],
                [-124.963097, 50.236382],
                [-124.898598, 50.29388]
              ]
            ],
            [
              [
                [-125.345497, 50.263901],
                [-125.2547, 50.29361],
                [-125.167198, 50.213612],
                [-125.186699, 50.141659],
                [-125.233101, 50.11805],
                [-125.178001, 50.078609],
                [-125.167801, 49.98082],
                [-125.215599, 50.07888],
                [-125.323601, 50.14333],
                [-125.345497, 50.263901]
              ]
            ],
            [
              [
                [-124.983299, 50.225552],
                [-124.891098, 50.06805],
                [-124.987198, 50.012772],
                [-125, 50.064308],
                [-125.067001, 50.107498],
                [-124.982201, 50.176102],
                [-125.005798, 50.225819],
                [-124.983299, 50.225552]
              ]
            ],
            [
              [
                [-126.7687, 49.87862],
                [-126.681702, 49.849998],
                [-126.636101, 49.759441],
                [-126.613297, 49.648331],
                [-126.633102, 49.5961],
                [-126.807999, 49.619709],
                [-126.974197, 49.740269],
                [-126.855797, 49.776939],
                [-126.988602, 49.811378],
                [-126.970299, 49.832211],
                [-126.7687, 49.87862]
              ]
            ],
            [
              [
                [-124.122803, 49.49361],
                [-124.440002, 49.612499],
                [-124.4767, 49.67194],
                [-124.654198, 49.736111],
                [-124.6567, 49.79694],
                [-124.351402, 49.69804],
                [-124.122803, 49.49361]
              ]
            ],
            [
              [
                [-126.1269, 49.39027],
                [-126.0728, 49.343048],
                [-126.064697, 49.250832],
                [-126.239197, 49.289719],
                [-126.234703, 49.374161],
                [-126.1269, 49.39027]
              ]
            ],
            [
              [
                [-123.674698, 49.093048],
                [-123.521698, 48.96027],
                [-123.3125, 48.86805],
                [-123.378899, 48.856941],
                [-123.529999, 48.938042],
                [-123.662498, 49.035271],
                [-123.705299, 49.100269],
                [-123.674698, 49.093048]
              ]
            ],
            [
              [
                [-123.577202, 48.929161],
                [-123.433899, 48.84444],
                [-123.494499, 48.845268],
                [-123.371696, 48.7575],
                [-123.456398, 48.76527],
                [-123.431999, 48.7211],
                [-123.524696, 48.722488],
                [-123.551903, 48.75222],
                [-123.532501, 48.795269],
                [-123.571701, 48.829159],
                [-123.547798, 48.859989],
                [-123.592796, 48.898331],
                [-123.596703, 48.946941],
                [-123.577202, 48.929161]
              ]
            ],
            [
              [
                [-117.030998, 49],
                [-117.4375, 49],
                [-118.199203, 49],
                [-118.842499, 49],
                [-120.855797, 48.999969],
                [-122.7603, 48.999439],
                [-122.862503, 49.022221],
                [-122.859398, 49.077221],
                [-122.900002, 49.087212],
                [-123.0392, 49.0425],
                [-123.034302, 48.999439],
                [-123.145798, 49.058601],
                [-123.036102, 49.149719],
                [-123.115501, 49.108601],
                [-123.205002, 49.1236],
                [-123.2033, 49.194439],
                [-123.127197, 49.19582],
                [-123.248901, 49.273609],
                [-123.146698, 49.271381],
                [-123.147797, 49.308041],
                [-122.923599, 49.28833],
                [-122.852798, 49.4361],
                [-122.876404, 49.455551],
                [-122.901398, 49.36055],
                [-122.953102, 49.310268],
                [-123.269203, 49.331108],
                [-123.288101, 49.374989],
                [-123.233299, 49.426102],
                [-123.256699, 49.512772],
                [-123.159698, 49.699162],
                [-123.241898, 49.660549],
                [-123.254501, 49.588329],
                [-123.4925, 49.50972],
                [-123.4767, 49.419159],
                [-123.535599, 49.381378],
                [-123.749199, 49.461109],
                [-123.896103, 49.470539],
                [-124.0681, 49.633881],
                [-124.021103, 49.726379],
                [-123.940598, 49.742489],
                [-123.833099, 49.627491],
                [-123.769699, 49.504711],
                [-123.759201, 49.601101],
                [-123.614998, 49.63916],
                [-123.534203, 49.701389],
                [-123.7967, 49.638329],
                [-123.931396, 49.76416],
                [-123.872803, 49.877209],
                [-123.959702, 49.981659],
                [-123.744499, 50.086941],
                [-123.821404, 50.15221],
                [-123.985001, 50.216099],
                [-123.809196, 50.099998],
                [-123.999199, 50.00639],
                [-123.979698, 49.91666],
                [-123.911102, 49.869709],
                [-123.926399, 49.825829],
                [-124.004204, 49.810551],
                [-124.037804, 49.922489],
                [-124.071999, 49.873322],
                [-124.046097, 49.841099],
                [-124.084396, 49.79916],
                [-124.257797, 49.775829],
                [-124.2714, 49.742771],
                [-124.511902, 49.796101],
                [-124.532799, 49.84444],
                [-124.741898, 49.958328],
                [-124.829697, 50.061939],
                [-124.686401, 49.98444],
                [-124.704697, 50.030819],
                [-124.668602, 50.076389],
                [-124.700302, 50.10833],
                [-124.626099, 50.165272],
                [-124.601402, 50.23888],
                [-124.714996, 50.327492],
                [-124.651901, 50.38916],
                [-124.434196, 50.43166],
                [-124.347801, 50.502491],
                [-124.563004, 50.42083],
                [-124.671402, 50.425831],
                [-124.830002, 50.309429],
                [-124.959999, 50.33305],
                [-124.948898, 50.37582],
                [-124.994698, 50.430271],
                [-124.9953, 50.353882],
                [-125.0783, 50.322491],
                [-125.056702, 50.47694],
                [-125, 50.490829],
                [-124.858002, 50.590549],
                [-124.860298, 50.6786],
                [-124.822502, 50.723598],
                [-124.913597, 50.79361],
                [-124.786903, 50.884441],
                [-124.849998, 50.935261],
                [-124.912804, 50.841099],
                [-124.983299, 50.8036],
                [-124.897003, 50.72971],
                [-124.9217, 50.661381],
                [-124.903603, 50.619991],
                [-125.104202, 50.496941],
                [-125.1147, 50.436939],
                [-125.170303, 50.412491],
                [-125.246101, 50.462212],
                [-125.245003, 50.503609],
                [-125.315804, 50.465271],
                [-125.363297, 50.541382],
                [-125.4217, 50.465271],
                [-125.573303, 50.458889],
                [-125.527802, 50.540279],
                [-125.532204, 50.627209],
                [-125.4328, 50.713879],
                [-125.547203, 50.66193],
                [-125.573898, 50.544159],
                [-125.623901, 50.50639],
                [-125.613899, 50.465271],
                [-125.700302, 50.428051],
                [-125.795502, 50.496101],
                [-125.738602, 50.516109],
                [-125.815804, 50.534161],
                [-125.952202, 50.46888],
                [-126.275002, 50.5075],
                [-126.232803, 50.53611],
                [-126.175797, 50.519161],
                [-126.170303, 50.573879],
                [-125.9403, 50.629162],
                [-126.207802, 50.588879],
                [-126.273903, 50.632141],
                [-125.704201, 50.678329],
                [-125.6772, 50.73444],
                [-125.611099, 50.764721],
                [-125.641998, 50.853321],
                [-125.546402, 50.861382],
                [-125.5056, 50.933331],
                [-125.551697, 51.042221],
                [-125.633904, 51.096939],
                [-125.5811, 50.95388],
                [-125.542801, 50.932499],
                [-125.6744, 50.86694],
                [-125.680298, 50.81554],
                [-125.643097, 50.783878],
                [-125.703598, 50.764999],
                [-125.746902, 50.70805],
                [-125.936897, 50.681381],
                [-125.9953, 50.70805],
                [-126.210297, 50.665272],
                [-126.2089, 50.707771],
                [-126.024696, 50.793049],
                [-126.181396, 50.778332],
                [-126.1772, 50.86805],
                [-126.493301, 50.81638],
                [-126.552803, 50.834721],
                [-126.557503, 50.876659],
                [-126.494499, 50.918049],
                [-126.2258, 50.898609],
                [-126.170303, 50.936649],
                [-126.4636, 50.94804],
                [-126.516403, 51.070549],
                [-126.549202, 51.014439],
                [-126.524399, 50.937489],
                [-126.6614, 50.86805],
                [-126.854401, 50.93943],
                [-126.917198, 50.899158],
                [-127.176399, 50.929161],
                [-127.062798, 50.885269],
                [-126.917503, 50.876659],
                [-127.022499, 50.817501],
                [-127.2547, 50.913879],
                [-127.398598, 50.92638],
                [-127.441101, 50.947769],
                [-127.420799, 50.978321],
                [-127.535301, 51.000549],
                [-127.5028, 51.097488],
                [-127.381104, 51.059052],
                [-127.330803, 51.047779],
                [-127.236099, 51.041111],
                [-126.848602, 51.069721],
                [-126.765602, 51.056381],
                [-126.7117, 51.000832],
                [-126.704498, 51.039719],
                [-126.743103, 51.0825],
                [-126.652802, 51.14999],
                [-126.658302, 51.19276],
                [-126.806999, 51.089161],
                [-127.206703, 51.056381],
                [-127.388397, 51.067989],
                [-127.500603, 51.11805],
                [-127.649399, 51.09222],
                [-127.790001, 51.165539],
                [-127.761902, 51.249439],
                [-127.565598, 51.293049],
                [-127.306702, 51.280499],
                [-127.143303, 51.318329],
                [-127.115799, 51.391659],
                [-127.188004, 51.323608],
                [-127.280602, 51.301102],
                [-127.509697, 51.310268],
                [-127.465302, 51.358051],
                [-127.57, 51.328609],
                [-127.784203, 51.33305],
                [-127.778297, 51.361111],
                [-127.663902, 51.385551],
                [-127.551697, 51.468319],
                [-127.500603, 51.613609],
                [-127.233299, 51.662491],
                [-126.6633, 51.64888],
                [-126.605301, 51.713051],
                [-126.6633, 51.797218],
                [-126.639999, 51.709991],
                [-126.693001, 51.664711],
                [-127.053902, 51.697769],
                [-127.2742, 51.683331],
                [-127.3367, 51.712769],
                [-127.425797, 51.66666],
                [-127.433098, 51.75],
                [-127.3619, 51.77166],
                [-127.351097, 51.863609],
                [-127.392799, 51.77721],
                [-127.453102, 51.772491],
                [-127.474403, 51.70499],
                [-127.644203, 51.69804],
                [-127.462196, 51.6675],
                [-127.540298, 51.638889],
                [-127.5858, 51.570549],
                [-127.561096, 51.538601],
                [-127.640297, 51.458599],
                [-127.7547, 51.479988],
                [-127.656403, 51.508888],
                [-127.795502, 51.5261],
                [-127.787201, 51.560268],
                [-127.840599, 51.641731],
                [-127.878098, 51.673882],
                [-127.866096, 51.89999],
                [-127.789703, 51.941101],
                [-127.667801, 51.949162],
                [-127.651398, 52.051659],
                [-127.580299, 52.129162],
                [-127.480598, 52.1511],
                [-127.462502, 52.112499],
                [-127.584396, 52.093609],
                [-127.6203, 52.027771],
                [-127.547203, 52.07972],
                [-127.4114, 52.090549],
                [-127.453102, 52.179989],
                [-127.2453, 52.248878],
                [-127.1717, 52.312489],
                [-127.004997, 52.3036],
                [-126.938004, 52.246941],
                [-126.937202, 52.196098],
                [-126.751999, 52.078609],
                [-126.669403, 51.983601],
                [-126.669701, 52.041939],
                [-126.751701, 52.125271],
                [-126.900597, 52.205269],
                [-126.940804, 52.303879],
                [-126.867798, 52.35416],
                [-126.736702, 52.37804],
                [-126.792801, 52.39555],
                [-126.9814, 52.33527],
                [-127.082497, 52.334991],
                [-127.186401, 52.380821],
                [-127.2342, 52.517208],
                [-127.074203, 52.61639],
                [-126.989403, 52.631939],
                [-126.922203, 52.71888],
                [-126.966904, 52.828609],
                [-127.071098, 52.883881],
                [-126.980797, 52.72443],
                [-127.075699, 52.639721],
                [-127.257202, 52.54583],
                [-127.266701, 52.464161],
                [-127.452499, 52.40443],
                [-127.611702, 52.29472],
                [-127.746101, 52.276661],
                [-127.714203, 52.353611],
                [-127.736702, 52.349998],
                [-127.858299, 52.206661],
                [-127.928596, 52.30471],
                [-127.905296, 52.3461],
                [-127.841698, 52.365002],
                [-127.916397, 52.44582],
                [-127.869698, 52.5061],
                [-127.900002, 52.509991],
                [-127.930603, 52.434429],
                [-127.889999, 52.384991],
                [-127.963898, 52.321659],
                [-128.057205, 52.394711],
                [-128.068298, 52.447769],
                [-128.009506, 52.50861],
                [-127.9375, 52.499722],
                [-127.8797, 52.57972],
                [-128.099701, 52.503052],
                [-128.151093, 52.409988],
                [-128.304993, 52.26944],
                [-128.393906, 52.291382],
                [-128.224396, 52.459721],
                [-128.239197, 52.56694],
                [-128.113007, 52.748329],
                [-128.139206, 52.837212],
                [-128.128098, 52.88055],
                [-128.025299, 52.912209],
                [-128.139206, 52.924438],
                [-128.235306, 52.80582],
                [-128.441101, 52.822769],
                [-128.495499, 52.887501],
                [-128.537796, 53.073051],
                [-128.496597, 53.09721],
                [-128.554199, 53.118881],
                [-128.4814, 53.141941],
                [-128.571899, 53.142769],
                [-128.663605, 53.239429],
                [-128.685303, 53.18943],
                [-128.858902, 53.283611],
                [-128.878296, 53.338039],
                [-128.8461, 53.36832],
                [-128.883606, 53.369991],
                [-128.889206, 53.430271],
                [-128.829407, 53.477772],
                [-128.923294, 53.45388],
                [-128.972794, 53.553051],
                [-128.866592, 53.535],
                [-128.794998, 53.5686],
                [-128.754196, 53.493881],
                [-128.670807, 53.45055],
                [-128.692505, 53.485271],
                [-128.643906, 53.474991],
                [-128.4953, 53.388599],
                [-128.481903, 53.33971],
                [-128.474396, 53.389721],
                [-128.504196, 53.407768],
                [-128.373596, 53.442211],
                [-128.352493, 53.475819],
                [-128.131897, 53.448879],
                [-128.071106, 53.396389],
                [-128.099396, 53.33638],
                [-128.032196, 53.367489],
                [-127.952003, 53.326099],
                [-127.946098, 53.25222],
                [-127.867798, 53.239719],
                [-127.93, 53.284721],
                [-127.925003, 53.329159],
                [-128.050293, 53.395],
                [-128.114395, 53.488041],
                [-128.380295, 53.499439],
                [-128.389206, 53.462212],
                [-128.517502, 53.431381],
                [-128.756104, 53.560268],
                [-128.816696, 53.623322],
                [-128.764694, 53.71944],
                [-128.7939, 53.764999],
                [-128.770599, 53.79583],
                [-128.689697, 53.81221],
                [-128.643097, 53.863049],
                [-128.471405, 53.8325],
                [-128.688599, 53.889992],
                [-128.609207, 54.03138],
                [-128.685303, 54.000832],
                [-128.720001, 53.938881],
                [-128.9319, 53.774712],
                [-128.904694, 53.856659],
                [-128.943893, 53.80999],
                [-128.950806, 53.878601],
                [-129.003906, 53.89333],
                [-128.966904, 53.863049],
                [-128.964203, 53.782211],
                [-129.009201, 53.762211],
                [-129.0569, 53.811939],
                [-129.028595, 53.7486],
                [-129.232498, 53.62582],
                [-129.261993, 53.517769],
                [-129.2314, 53.500832],
                [-129.237503, 53.433601],
                [-129.310699, 53.379501],
                [-129.606094, 53.567768],
                [-129.734695, 53.67527],
                [-129.699707, 53.671101],
                [-129.713593, 53.699429],
                [-129.989395, 53.845829],
                [-129.962204, 53.88583],
                [-130.031693, 53.876381],
                [-130.101395, 53.946659],
                [-130.090805, 54.071659],
                [-130.046707, 54.15332],
                [-129.950302, 54.158039],
                [-129.839996, 54.027771],
                [-129.718903, 53.951389],
                [-129.737198, 54.001659],
                [-129.834198, 54.05471],
                [-129.929703, 54.183048],
                [-129.831894, 54.21944],
                [-129.614197, 54.178921],
                [-129.470001, 54.237209],
                [-129.616898, 54.194149],
                [-129.854996, 54.238041],
                [-130.122803, 54.15443],
                [-130.278595, 54.208889],
                [-130.279999, 54.24361],
                [-130.195602, 54.259991],
                [-130.2603, 54.33749],
                [-130.250305, 54.400829],
                [-130.275803, 54.34972],
                [-130.348907, 54.326939],
                [-130.481094, 54.364719],
                [-130.473602, 54.435822],
                [-130.381104, 54.45805],
                [-130.433594, 54.496658],
                [-130.429993, 54.562489],
                [-130.375793, 54.571659],
                [-130.439697, 54.612209],
                [-130.416702, 54.630268],
                [-130.063004, 54.339989],
                [-129.982803, 54.3111],
                [-129.959702, 54.326939],
                [-130.038605, 54.34193],
                [-130.089706, 54.402771],
                [-130.059204, 54.47916],
                [-129.978897, 54.516941],
                [-130.0672, 54.49416],
                [-130.082794, 54.434719],
                [-130.123306, 54.42749],
                [-130.351395, 54.61805],
                [-130.368607, 54.66777],
                [-130.171906, 54.703609],
                [-130.000595, 54.614719],
                [-129.880798, 54.614719],
                [-130.004501, 54.632771],
                [-130.079193, 54.691929],
                [-130.201706, 54.72694],
                [-130.155594, 54.815269],
                [-130.050293, 54.88694],
                [-130.173904, 54.846661],
                [-130.031998, 54.965271],
                [-129.954193, 54.95916],
                [-129.974396, 54.914989],
                [-129.824402, 54.989159],
                [-129.622498, 54.997768],
                [-129.998306, 55.018051],
                [-129.961395, 55.093319],
                [-129.860504, 55.170551],
                [-129.815598, 55.255001],
                [-129.749405, 55.28833],
                [-129.643295, 55.434158],
                [-129.523895, 55.439991],
                [-129.4711, 55.472759],
                [-129.636993, 55.456661],
                [-129.708298, 55.409988],
                [-129.811996, 55.629162],
                [-129.772797, 55.473881],
                [-129.839401, 55.387501],
                [-129.824402, 55.361938],
                [-129.790604, 55.398048],
                [-129.808594, 55.367771],
                [-129.782501, 55.353611],
                [-129.830795, 55.323051],
                [-129.813904, 55.289719],
                [-129.920807, 55.151932],
                [-130.068298, 54.996941],
                [-130.113602, 54.99638],
                [-130.160004, 55.086109],
                [-129.943893, 55.282211],
                [-130.007202, 55.355],
                [-130.0242, 55.46471],
                [-130.1017, 55.556381],
                [-130.074707, 55.672489],
                [-130.174698, 55.75444],
                [-130.015106, 55.90918],
                [-130.088593, 56.11805],
                [-130.4142, 56.12888],
                [-130.461899, 56.235271],
                [-130.627197, 56.25861],
                [-130.774399, 56.3661],
                [-131.054993, 56.398048],
                [-131.5578, 56.602219],
                [-131.824203, 56.596939],
                [-131.861404, 56.799721],
                [-132.103104, 56.866661],
                [-132.027496, 57.036381],
                [-132.336899, 57.088329],
                [-132.2267, 57.204708],
                [-133.136993, 58.13583],
                [-133.361099, 58.280548],
                [-133.429993, 58.45916],
                [-133.826904, 58.726101],
                [-134.245499, 58.856941],
                [-134.461395, 59.126659],
                [-134.566406, 59.13055],
                [-134.688004, 59.24332],
                [-134.951904, 59.279991],
                [-134.989197, 59.389992],
                [-135.091705, 59.426941],
                [-135.021103, 59.4711],
                [-135.014496, 59.567501],
                [-135.473602, 59.801929],
                [-136.343903, 59.602779],
                [-136.233902, 59.525829],
                [-136.463593, 59.469711],
                [-136.462799, 59.28944],
                [-136.583893, 59.163319],
                [-136.808899, 59.165272],
                [-137.4655, 58.906101],
                [-137.590805, 59.238602],
                [-138.615799, 59.774158],
                [-138.690308, 59.90694],
                [-138.938599, 59.997551],
                [-134.906601, 60],
                [-134.639297, 60.000198],
                [-134.151703, 59.999722],
                [-133.791199, 60],
                [-125.000801, 60.003868],
                [-123.816902, 59.994709],
                [-119.982903, 59.988522],
                [-120.000801, 54.005871],
                [-120.000801, 53.808041],
                [-119.904701, 53.778599],
                [-119.921097, 53.718319],
                [-119.810303, 53.707771],
                [-119.735298, 53.634991],
                [-119.7686, 53.603321],
                [-119.932198, 53.60722],
                [-119.882797, 53.560268],
                [-119.902496, 53.520271],
                [-119.842499, 53.518051],
                [-119.778099, 53.446941],
                [-119.738098, 53.395],
                [-119.606102, 53.37804],
                [-119.385803, 53.36166],
                [-119.348099, 53.275269],
                [-119.265602, 53.196659],
                [-119.045502, 53.137772],
                [-119.013603, 53.144711],
                [-119.012199, 53.223049],
                [-118.9786, 53.237209],
                [-118.759697, 53.12471],
                [-118.781097, 53.05582],
                [-118.669998, 53.034439],
                [-118.679703, 52.970539],
                [-118.625, 52.884159],
                [-118.491402, 52.89777],
                [-118.415604, 52.83638],
                [-118.428902, 52.78611],
                [-118.3461, 52.74332],
                [-118.305, 52.674709],
                [-118.349998, 52.61805],
                [-118.2817, 52.565269],
                [-118.286903, 52.527771],
                [-118.208298, 52.48082],
                [-118.259697, 52.442211],
                [-118.223099, 52.365551],
                [-118.145798, 52.39888],
                [-118.047501, 52.404991],
                [-118.0056, 52.48777],
                [-117.902802, 52.424999],
                [-117.7547, 52.403599],
                [-117.7314, 52.344151],
                [-117.829697, 52.27277],
                [-117.760002, 52.200829],
                [-117.6875, 52.196098],
                [-117.600304, 52.13055],
                [-117.363098, 52.138599],
                [-117.318901, 52.181938],
                [-117.269997, 52.044998],
                [-117.0392, 51.908039],
                [-116.919197, 51.70916],
                [-116.812798, 51.715271],
                [-116.815804, 51.750549],
                [-116.746101, 51.79916],
                [-116.658897, 51.796661],
                [-116.597504, 51.716381],
                [-116.595001, 51.65749],
                [-116.488098, 51.613049],
                [-116.466103, 51.56638],
                [-116.399696, 51.54472],
                [-116.3797, 51.488602],
                [-116.305, 51.461658],
                [-116.306099, 51.388329],
                [-116.262199, 51.316669],
                [-116.060501, 51.24638],
                [-116.0261, 51.21693],
                [-116.016098, 51.138329],
                [-115.957802, 51.103882],
                [-115.776703, 51.073608],
                [-115.759697, 51.035831],
                [-115.618599, 50.969151],
                [-115.571999, 50.90332],
                [-115.647202, 50.85471],
                [-115.527802, 50.791939],
                [-115.419197, 50.754169],
                [-115.395798, 50.71693],
                [-115.346703, 50.732208],
                [-115.311699, 50.706661],
                [-115.283096, 50.658878],
                [-115.320602, 50.638599],
                [-115.218903, 50.54694],
                [-115.1017, 50.588329],
                [-115.016998, 50.575001],
                [-114.8367, 50.39666],
                [-114.770302, 50.35833],
                [-114.793602, 50.334721],
                [-114.754997, 50.288891],
                [-114.724197, 50.125549],
                [-114.658897, 50.065269],
                [-114.643303, 49.977772],
                [-114.684998, 49.9575],
                [-114.6875, 49.913601],
                [-114.626099, 49.797218],
                [-114.636101, 49.727772],
                [-114.679703, 49.63221],
                [-114.738297, 49.608601],
                [-114.6894, 49.547218],
                [-114.576401, 49.556099],
                [-114.599998, 49.442211],
                [-114.576401, 49.389992],
                [-114.476097, 49.341381],
                [-114.429398, 49.265549],
                [-114.376701, 49.25639],
                [-114.372803, 49.204159],
                [-114.225304, 49.188599],
                [-114.154198, 49.148048],
                [-114.1567, 49.103611],
                [-114.035301, 49.031109],
                [-114.060303, 49],
                [-114.0625, 49],
                [-114.728302, 49],
                [-116.0625, 49],
                [-117.030998, 49]
              ]
            ]
          ]
        },
        "properties": {
          "name": "British Columbia",
          "density": "4.80",
          "cartodb_id": 6,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-109.978409, 78.671058],
                [-109.405296, 78.556931],
                [-109.326401, 78.518883],
                [-109.3339, 78.493874],
                [-109.254997, 78.478592],
                [-109.354401, 78.388893],
                [-109.3181, 78.358032],
                [-109.404999, 78.306374],
                [-109.824402, 78.293869],
                [-109.983902, 78.325546],
                [-109.978409, 78.671058]
              ]
            ],
            [
              [
                [-109.791702, 78.099152],
                [-109.580597, 78.058319],
                [-109.705597, 77.959991],
                [-109.987239, 77.924049],
                [-109.985789, 78.101103],
                [-109.791702, 78.099152]
              ]
            ],
            [
              [
                [-108.482697, 76.456818],
                [-108.607498, 76.410538],
                [-108.318604, 76.39415],
                [-108.077499, 76.279709],
                [-108.218903, 76.258881],
                [-108.093102, 76.214157],
                [-108.380302, 76.165268],
                [-108.4086, 76.147774],
                [-108.394203, 76.124977],
                [-108.456703, 76.099152],
                [-108.384201, 76.086647],
                [-108.496696, 76.039978],
                [-107.838303, 76.061371],
                [-107.6325, 75.991089],
                [-107.785301, 75.928864],
                [-107.750298, 75.905548],
                [-107.930298, 75.885536],
                [-108.031097, 75.822487],
                [-108.020302, 75.780823],
                [-107.873596, 75.798027],
                [-107.739403, 75.87915],
                [-107.369499, 75.911652],
                [-107.064201, 75.892761],
                [-107.043098, 75.883331],
                [-107.0858, 75.872482],
                [-107.098099, 75.823036],
                [-106.958603, 75.791931],
                [-107.0317, 75.771103],
                [-106.951103, 75.747482],
                [-106.963097, 75.702766],
                [-106.874702, 75.64415],
                [-106.9058, 75.686096],
                [-106.861397, 75.75499],
                [-106.880302, 75.765823],
                [-106.615799, 75.797211],
                [-106.888901, 75.834717],
                [-106.863602, 75.886932],
                [-106.896103, 75.941093],
                [-106.869698, 75.964157],
                [-106.606102, 76.057747],
                [-105.9702, 76.012421],
                [-105.668297, 75.955551],
                [-105.478897, 75.863037],
                [-105.445801, 75.830551],
                [-105.463898, 75.77916],
                [-105.419701, 75.743591],
                [-105.459999, 75.72998],
                [-105.388298, 75.656372],
                [-105.496399, 75.555252],
                [-105.751404, 75.4897],
                [-105.592796, 75.467484],
                [-105.714203, 75.416382],
                [-105.6464, 75.365257],
                [-105.866898, 75.275818],
                [-105.937798, 75.214432],
                [-105.8442, 75.183319],
                [-105.8853, 75.140266],
                [-106.070297, 75.106644],
                [-105.993301, 75.055817],
                [-106.725601, 75.002213],
                [-106.930603, 74.933594],
                [-107.197197, 74.910812],
                [-107.662498, 74.96666],
                [-107.7239, 75.016388],
                [-107.681396, 75.04248],
                [-107.695, 75.075272],
                [-107.7733, 75.0961],
                [-107.743896, 75.037201],
                [-108, 75.001938],
                [-107.948898, 74.958038],
                [-108.016403, 74.943588],
                [-107.941902, 74.930817],
                [-108.373001, 74.910538],
                [-108.807198, 74.983597],
                [-108.511398, 74.976379],
                [-108.651398, 75.053864],
                [-108.832497, 75.069992],
                [-108.949699, 75.040268],
                [-108.967796, 75.018883],
                [-108.937202, 75.003326],
                [-109.034698, 75.003052],
                [-108.996399, 74.984711],
                [-109.345802, 74.944702],
                [-109.398598, 74.896103],
                [-109.627296, 74.856888],
                [-109.872498, 74.869141],
                [-110.008101, 74.833102],
                [-110.00402, 75.532769],
                [-109.664703, 75.531097],
                [-108.899399, 75.476379],
                [-108.932503, 75.528587],
                [-109.030602, 75.552757],
                [-108.853897, 75.560257],
                [-108.895798, 75.586929],
                [-108.836403, 75.615807],
                [-108.936096, 75.674698],
                [-108.827003, 75.686653],
                [-109.391998, 75.769707],
                [-109.464401, 75.786652],
                [-109.413902, 75.799713],
                [-109.691399, 75.806931],
                [-109.626404, 75.832489],
                [-109.702499, 75.841927],
                [-109.663597, 75.870819],
                [-109.907799, 75.849991],
                [-110.001897, 75.875843],
                [-110.001757, 75.898089],
                [-109.672203, 75.943863],
                [-109.397499, 76.011383],
                [-109.433098, 76.029984],
                [-109.405602, 76.040817],
                [-109.290604, 76.034988],
                [-109.387199, 76.066093],
                [-109.313599, 76.109154],
                [-109.521698, 76.181091],
                [-109.720001, 76.194977],
                [-109.699997, 76.218872],
                [-109.882797, 76.233871],
                [-109.896698, 76.193588],
                [-109.999649, 76.224774],
                [-109.997979, 76.474992],
                [-109.746399, 76.505547],
                [-109.711403, 76.529984],
                [-109.847198, 76.532494],
                [-109.759201, 76.568047],
                [-109.776398, 76.593597],
                [-109.664398, 76.583878],
                [-109.654999, 76.61499],
                [-109.555298, 76.626373],
                [-109.562202, 76.658333],
                [-109.508301, 76.717758],
                [-109.350304, 76.74942],
                [-109.302803, 76.796944],
                [-108.9189, 76.809418],
                [-108.773598, 76.857758],
                [-108.6511, 76.813599],
                [-108.688301, 76.766098],
                [-108.527199, 76.760269],
                [-108.446404, 76.723602],
                [-108.441902, 76.696373],
                [-108.544701, 76.646378],
                [-108.727203, 76.638046],
                [-108.690598, 76.60054],
                [-108.5289, 76.553864],
                [-108.628304, 76.517761],
                [-108.530602, 76.515549],
                [-108.590302, 76.481934],
                [-108.482697, 76.456818]
              ]
            ],
            [
              [
                [-110.019917, 72.442051],
                [-109.952499, 72.459991],
                [-109.782799, 72.429428],
                [-110.019692, 72.493611],
                [-110.019536, 72.529154],
                [-109.888603, 72.484711],
                [-109.795303, 72.494141],
                [-109.9589, 72.612762],
                [-110.01912, 72.623398],
                [-110.018727, 72.7117],
                [-109.832802, 72.663307],
                [-109.816704, 72.676086],
                [-109.856903, 72.702766],
                [-109.770302, 72.722214],
                [-110.018556, 72.749766],
                [-110.017534, 72.974877],
                [-109.902199, 72.965271],
                [-109.853897, 72.91748],
                [-109.634499, 72.918053],
                [-109.7547, 72.878593],
                [-109.3797, 72.770538],
                [-109.400803, 72.750267],
                [-109.165802, 72.758614],
                [-109.111702, 72.739967],
                [-109.154701, 72.717209],
                [-108.937202, 72.667213],
                [-109.029999, 72.647217],
                [-109.043297, 72.56749],
                [-108.851097, 72.56749],
                [-108.888901, 72.597763],
                [-108.866898, 72.60582],
                [-108.621399, 72.549988],
                [-108.589401, 72.494431],
                [-108.637199, 72.444427],
                [-108.662804, 72.346367],
                [-108.5242, 72.199707],
                [-108.5364, 72.142212],
                [-108.404404, 72.146942],
                [-108.433296, 72.12886],
                [-108.398903, 72.113602],
                [-108.412201, 71.989967],
                [-108.2939, 71.976379],
                [-108.332802, 71.925812],
                [-108.186699, 71.955833],
                [-108.294998, 71.876083],
                [-108.241699, 71.835541],
                [-108.2808, 71.786926],
                [-108.165298, 71.754707],
                [-108.238098, 71.713882],
                [-108.066101, 71.717758],
                [-107.976097, 71.666382],
                [-107.982803, 71.633331],
                [-107.845299, 71.603867],
                [-107.724197, 71.623871],
                [-107.836403, 71.681664],
                [-107.804398, 71.726646],
                [-107.663902, 71.702477],
                [-107.535599, 71.779709],
                [-107.251999, 71.804428],
                [-107.470802, 71.870819],
                [-107.296997, 71.874153],
                [-107.257004, 71.900269],
                [-107.4133, 71.928307],
                [-107.422203, 71.974991],
                [-107.613899, 72.012497],
                [-107.653297, 72.066383],
                [-107.610001, 72.088593],
                [-107.660004, 72.113602],
                [-107.6353, 72.121918],
                [-107.7789, 72.136658],
                [-107.792198, 72.255257],
                [-107.864197, 72.298317],
                [-107.7136, 72.300812],
                [-107.843903, 72.354156],
                [-107.801903, 72.381927],
                [-107.877502, 72.424423],
                [-107.781403, 72.456383],
                [-107.980598, 72.511108],
                [-107.8769, 72.524429],
                [-107.876701, 72.567207],
                [-108.011902, 72.61998],
                [-107.954498, 72.63916],
                [-108.025299, 72.666092],
                [-108.005798, 72.702477],
                [-108.0439, 72.726379],
                [-108.079697, 72.748032],
                [-108.051102, 72.781372],
                [-108.145798, 72.87886],
                [-108.101402, 72.901093],
                [-108.169403, 72.956383],
                [-108.1353, 72.982483],
                [-108.232803, 73.033051],
                [-108.156097, 73.048599],
                [-108.300003, 73.135536],
                [-108.166397, 73.203873],
                [-107.864197, 73.188583],
                [-108.116402, 73.264427],
                [-108.1436, 73.30748],
                [-108.0131, 73.309143],
                [-108.071404, 73.353317],
                [-107.614403, 73.317207],
                [-107.069702, 73.173866],
                [-107.010803, 73.19136],
                [-107.123596, 73.240257],
                [-107.0839, 73.283333],
                [-106.861397, 73.310806],
                [-106.750801, 73.289146],
                [-106.6511, 73.222488],
                [-106.486702, 73.224991],
                [-106.463303, 73.179703],
                [-106.240303, 73.085823],
                [-106.047501, 73.044708],
                [-105.855598, 73.056931],
                [-105.824699, 73.045258],
                [-105.822197, 73.002487],
                [-105.608299, 72.944977],
                [-105.636703, 72.929153],
                [-105.525002, 72.875259],
                [-105.555, 72.86026],
                [-105.452797, 72.842758],
                [-105.363899, 72.785263],
                [-105.399399, 72.767212],
                [-105.321999, 72.741653],
                [-105.3778, 72.729431],
                [-105.510002, 72.776932],
                [-105.431702, 72.729156],
                [-105.457199, 72.702766],
                [-105.290802, 72.651657],
                [-105.3433, 72.617203],
                [-105.310799, 72.595261],
                [-105.318298, 72.55748],
                [-105.238098, 72.54776],
                [-105.2239, 72.512207],
                [-105.252197, 72.489967],
                [-105.195801, 72.482483],
                [-105.217796, 72.444977],
                [-105.301903, 72.455261],
                [-104.957497, 72.181374],
                [-105.037498, 72.118317],
                [-105.0186, 72.06694],
                [-104.954697, 72.057213],
                [-104.871902, 71.989967],
                [-104.790001, 71.84137],
                [-104.673302, 71.823318],
                [-104.355301, 71.574432],
                [-104.401901, 71.498322],
                [-104.319199, 71.462486],
                [-104.3442, 71.410812],
                [-104.277802, 71.351089],
                [-104.471397, 71.351089],
                [-104.496902, 71.322769],
                [-104.438904, 71.257767],
                [-104.448898, 71.218872],
                [-104.648598, 71.119141],
                [-104.414398, 70.999153],
                [-104.122803, 70.914703],
                [-103.994202, 70.752777],
                [-103.838898, 70.743317],
                [-103.556396, 70.600807],
                [-103.309196, 70.595261],
                [-103.121696, 70.501938],
                [-102.930603, 70.49054],
                [-102.932503, 70.5186],
                [-103.126099, 70.573883],
                [-103.1539, 70.665817],
                [-103.066704, 70.669983],
                [-102.848297, 70.593872],
                [-102.853897, 70.508331],
                [-102.377197, 70.412491],
                [-102.195, 70.324158],
                [-102.130798, 70.342758],
                [-101.893303, 70.25444],
                [-101.714699, 70.308868],
                [-101.5886, 70.271927],
                [-101.650597, 70.210823],
                [-101.551903, 70.109421],
                [-101.4133, 70.12442],
                [-101.375801, 70.177757],
                [-101.222198, 70.131927],
                [-101.135002, 70.159714],
                [-101.1119, 70.197197],
                [-100.973297, 70.155548],
                [-101.002502, 70.088882],
                [-100.926102, 70.021103],
                [-100.921898, 69.965271],
                [-100.961403, 69.928589],
                [-100.9039, 69.910538],
                [-100.870003, 69.814423],
                [-100.878098, 69.771652],
                [-100.948898, 69.733597],
                [-100.920303, 69.701103],
                [-100.957199, 69.662491],
                [-101.0644, 69.648613],
                [-101.155296, 69.688026],
                [-101.329697, 69.669983],
                [-101.473297, 69.832764],
                [-101.4161, 69.886932],
                [-101.445297, 69.909149],
                [-101.672501, 69.646103],
                [-101.766098, 69.721916],
                [-101.948601, 69.741364],
                [-102.029701, 69.801086],
                [-102.018303, 69.829163],
                [-102.050797, 69.85582],
                [-102.149696, 69.82666],
                [-102.168602, 69.887207],
                [-102.230301, 69.917213],
                [-102.257797, 69.889977],
                [-102.237198, 69.850807],
                [-102.340797, 69.835823],
                [-102.299202, 69.794434],
                [-102.349701, 69.756378],
                [-102.380302, 69.761383],
                [-102.364197, 69.795258],
                [-102.387802, 69.81192],
                [-102.402199, 69.77388],
                [-102.486702, 69.785812],
                [-102.576401, 69.737488],
                [-102.680603, 69.759987],
                [-102.592201, 69.693863],
                [-102.483597, 69.6922],
                [-102.507797, 69.564148],
                [-102.813004, 69.529709],
                [-103.186699, 69.629433],
                [-103.297501, 69.69693],
                [-103.486702, 69.684708],
                [-103.504204, 69.613037],
                [-103.216698, 69.551086],
                [-103.127502, 69.432213],
                [-103.095001, 69.452209],
                [-103.089203, 69.521927],
                [-103.0233, 69.493874],
                [-102.990799, 69.419434],
                [-103.033897, 69.359993],
                [-103.008301, 69.332207],
                [-103.023102, 69.271652],
                [-103.204498, 69.200821],
                [-103.208603, 69.122757],
                [-103.160599, 69.117752],
                [-102.840797, 69.383331],
                [-102.519203, 69.412201],
                [-102.460297, 69.482208],
                [-102.057503, 69.483597],
                [-101.931099, 69.407494],
                [-102.035797, 69.349991],
                [-102.160599, 69.354713],
                [-102.169998, 69.31749],
                [-102.1017, 69.334991],
                [-102.037201, 69.276382],
                [-102.0886, 69.231934],
                [-102.212799, 69.276382],
                [-102.217201, 69.225273],
                [-102.093597, 69.178589],
                [-102.036697, 69.231659],
                [-101.921898, 69.260269],
                [-101.751701, 69.160538],
                [-101.8675, 69.04248],
                [-101.808601, 68.998032],
                [-101.962799, 68.96666],
                [-102.062202, 68.996933],
                [-102.144997, 68.947662],
                [-102.392197, 68.953049],
                [-102.398399, 68.908867],
                [-102.535797, 68.864433],
                [-102.753098, 68.89415],
                [-102.699997, 68.839981],
                [-102.894699, 68.799988],
                [-103.005798, 68.795258],
                [-103.1642, 68.843048],
                [-103.320801, 68.829712],
                [-103.374199, 68.777481],
                [-103.5494, 68.82666],
                [-104.110298, 68.859421],
                [-104.240501, 68.896652],
                [-104.264999, 68.9422],
                [-104.303596, 68.930267],
                [-104.270798, 68.900543],
                [-104.457497, 68.936653],
                [-104.5028, 68.877762],
                [-104.583603, 68.859711],
                [-105.129204, 68.896378],
                [-105.2742, 68.948868],
                [-105.173302, 68.991928],
                [-105.0392, 68.990257],
                [-105.041397, 69.018883],
                [-104.936401, 69.030548],
                [-104.922997, 69.074707],
                [-105.083298, 69.106369],
                [-105.122803, 69.091087],
                [-105.035797, 69.05748],
                [-105.450798, 69.094994],
                [-105.616898, 69.160812],
                [-106.177803, 69.14415],
                [-106.406097, 69.180542],
                [-106.394402, 69.236923],
                [-106.267799, 69.292213],
                [-106.312202, 69.338318],
                [-106.310303, 69.40387],
                [-106.602203, 69.498871],
                [-106.733101, 69.44165],
                [-106.753304, 69.37915],
                [-106.985497, 69.3461],
                [-106.929199, 69.21666],
                [-107.037804, 69.185806],
                [-107.025803, 69.151382],
                [-107.1381, 69.150543],
                [-107.304199, 69.032494],
                [-107.955803, 68.931053],
                [-108.534401, 68.944702],
                [-108.563301, 68.933594],
                [-108.529701, 68.901382],
                [-108.551399, 68.879433],
                [-108.9375, 68.741089],
                [-109.400002, 68.693314],
                [-109.656403, 68.632751],
                [-110.220001, 68.62915],
                [-110.3778, 68.574158],
                [-110.4272, 68.608322],
                [-110.576698, 68.616653],
                [-110.950302, 68.551933],
                [-111.035004, 68.563873],
                [-110.860298, 68.587769],
                [-110.863602, 68.608597],
                [-111.379204, 68.584152],
                [-111.405296, 68.568047],
                [-111.209198, 68.549149],
                [-111.238098, 68.514427],
                [-111.600601, 68.543587],
                [-112.124397, 68.527771],
                [-112.612198, 68.502213],
                [-112.670303, 68.469147],
                [-113.251404, 68.452209],
                [-113.309402, 68.467209],
                [-113.291397, 68.489151],
                [-113.043602, 68.504173],
                [-113.357803, 68.59137],
                [-113.448303, 68.645264],
                [-113.4533, 68.700821],
                [-113.676903, 68.811096],
                [-113.617203, 68.845261],
                [-113.6772, 68.900818],
                [-113.569504, 68.957207],
                [-113.570801, 69.023323],
                [-113.639999, 69.032494],
                [-113.544998, 69.048027],
                [-113.657799, 69.091087],
                [-113.675598, 69.121643],
                [-113.638298, 69.138603],
                [-113.696098, 69.154709],
                [-113.6922, 69.189423],
                [-113.521103, 69.178589],
                [-114.311401, 69.284988],
                [-114.764198, 69.252213],
                [-114.991898, 69.280273],
                [-115.190804, 69.236923],
                [-115.499702, 69.256104],
                [-115.955803, 69.292213],
                [-116.525803, 69.407494],
                [-116.6297, 69.458878],
                [-116.561401, 69.488586],
                [-116.6092, 69.524429],
                [-116.581398, 69.560532],
                [-116.75, 69.574997],
                [-116.724701, 69.541656],
                [-116.910301, 69.590271],
                [-116.859908, 69.637114],
                [-116.513568, 69.634805],
                [-117.118097, 69.891887],
                [-117.085008, 69.999148],
                [-112.887108, 69.991098],
                [-112.862425, 69.840022],
                [-112.657705, 69.834517],
                [-112.614279, 69.896203],
                [-112.477477, 69.892389],
                [-112.48507, 69.993691],
                [-110.029392, 69.995491],
                [-110.019917, 72.442051]
              ]
            ],
            [
              [
                [-92.743797, 69.671951],
                [-92.920273, 69.669708],
                [-92.661667, 69.652771],
                [-92.349731, 69.701103],
                [-92.376389, 69.66748],
                [-92.357498, 69.658867],
                [-92.088333, 69.623032],
                [-92.300552, 69.641663],
                [-92.12471, 69.554977],
                [-91.803329, 69.483322],
                [-91.79834, 69.513893],
                [-91.712784, 69.535812],
                [-91.736938, 69.548317],
                [-91.590561, 69.578598],
                [-91.595001, 69.611099],
                [-91.475281, 69.664429],
                [-91.091949, 69.631653],
                [-91.219727, 69.617752],
                [-91.334442, 69.552757],
                [-91.553879, 69.505547],
                [-91.340843, 69.516663],
                [-91.192207, 69.562759],
                [-91.102783, 69.508881],
                [-90.88945, 69.534149],
                [-90.876938, 69.482483],
                [-90.758621, 69.482758],
                [-90.71611, 69.539429],
                [-90.493332, 69.504173],
                [-90.319458, 69.440536],
                [-90.555557, 69.422493],
                [-90.703613, 69.453598],
                [-90.58223, 69.416931],
                [-90.797234, 69.361366],
                [-90.809433, 69.253326],
                [-90.931381, 69.247482],
                [-90.896118, 69.2686],
                [-90.970001, 69.267212],
                [-90.920547, 69.299713],
                [-91.068893, 69.266098],
                [-91.100281, 69.281097],
                [-90.951683, 69.354713],
                [-91.194107, 69.288017],
                [-91.446663, 69.349716],
                [-90.664719, 69.083328],
                [-90.709167, 69.003883],
                [-90.604721, 68.978317],
                [-90.583893, 68.928864],
                [-90.629173, 68.915817],
                [-90.436394, 68.87442],
                [-90.481949, 68.856087],
                [-90.436111, 68.833054],
                [-90.593887, 68.805542],
                [-90.438889, 68.823318],
                [-90.473053, 68.799988],
                [-90.449158, 68.776093],
                [-90.507233, 68.779709],
                [-90.526672, 68.744431],
                [-90.479172, 68.705833],
                [-90.496948, 68.663879],
                [-90.563606, 68.624977],
                [-90.49527, 68.596649],
                [-90.538597, 68.565262],
                [-90.474442, 68.530823],
                [-90.606659, 68.446091],
                [-90.315002, 68.36998],
                [-90.477493, 68.33194],
                [-90.326401, 68.350273],
                [-90.280838, 68.243874],
                [-90.20723, 68.231087],
                [-90.118057, 68.262207],
                [-90.167503, 68.312477],
                [-90, 68.377037],
                [-89.904167, 68.451103],
                [-89.912216, 68.473312],
                [-89.800568, 68.539978],
                [-89.92778, 68.563599],
                [-89.949722, 68.607758],
                [-89.894173, 68.660812],
                [-89.80249, 68.712196],
                [-89.746948, 68.669708],
                [-89.766403, 68.621918],
                [-89.714172, 68.649147],
                [-89.729172, 68.699142],
                [-89.684723, 68.810257],
                [-89.737213, 68.831383],
                [-89.713058, 68.848602],
                [-89.756958, 68.939972],
                [-89.71666, 69.006104],
                [-89.48999, 69.110527],
                [-89.389999, 69.219147],
                [-89.114441, 69.275269],
                [-88.94249, 69.22998],
                [-88.87027, 69.148613],
                [-88.772507, 69.098877],
                [-88.208618, 68.911652],
                [-87.964447, 68.760536],
                [-87.916656, 68.656372],
                [-87.947769, 68.605263],
                [-87.789719, 68.391937],
                [-87.808884, 68.356934],
                [-87.787781, 68.327477],
                [-87.837509, 68.267212],
                [-87.935822, 68.195824],
                [-88.004997, 68.231369],
                [-88.16333, 68.231369],
                [-88.194153, 68.37442],
                [-88.392227, 68.287491],
                [-88.277496, 68.111649],
                [-88.381104, 68.025269],
                [-88.35556, 67.998871],
                [-88.370827, 67.959152],
                [-88.269173, 67.793587],
                [-88.162216, 67.736649],
                [-88.13945, 67.664429],
                [-87.955841, 67.607483],
                [-87.483887, 67.362488],
                [-87.448883, 67.261383],
                [-87.357773, 67.262207],
                [-87.505569, 67.185257],
                [-87.510277, 67.112198],
                [-87.42749, 67.170822],
                [-87.34111, 67.176376],
                [-87.304443, 67.099991],
                [-87.272507, 67.0961],
                [-87.229446, 67.22554],
                [-87.154167, 67.239967],
                [-87.106659, 67.179153],
                [-87.075844, 67.253601],
                [-86.963058, 67.250549],
                [-87.006958, 67.277206],
                [-86.994164, 67.302467],
                [-87.089996, 67.350807],
                [-86.974442, 67.316673],
                [-86.961403, 67.360527],
                [-86.874443, 67.404984],
                [-86.779167, 67.422211],
                [-86.647232, 67.358322],
                [-86.610817, 67.402481],
                [-86.592499, 67.345261],
                [-86.531952, 67.346649],
                [-86.503067, 67.377762],
                [-86.531952, 67.439972],
                [-86.473892, 67.468597],
                [-86.496948, 67.501663],
                [-86.451401, 67.597488],
                [-86.525284, 67.681374],
                [-86.364723, 67.822769],
                [-86.12944, 67.949997],
                [-86.127213, 68.013611],
                [-86.084732, 67.976929],
                [-85.892502, 68.051376],
                [-85.914436, 68.104156],
                [-85.858337, 68.137207],
                [-85.888062, 68.189697],
                [-85.784729, 68.216377],
                [-85.842499, 68.23027],
                [-85.805557, 68.294708],
                [-85.842773, 68.317207],
                [-85.712784, 68.397217],
                [-85.745003, 68.434708],
                [-85.66777, 68.448318],
                [-85.742493, 68.4711],
                [-85.7164, 68.501663],
                [-85.747498, 68.622482],
                [-85.645844, 68.737488],
                [-85.481949, 68.739433],
                [-85.464722, 68.75972],
                [-85.562767, 68.776657],
                [-85.514183, 68.783867],
                [-85.228882, 68.710274],
                [-85.262512, 68.751663],
                [-85.224167, 68.758331],
                [-84.775284, 68.737198],
                [-84.754181, 68.772217],
                [-84.834732, 68.820541],
                [-85.031677, 68.810806],
                [-85.192757, 68.862198],
                [-85.005569, 68.877472],
                [-85.069458, 68.906097],
                [-84.997498, 68.925812],
                [-85.116943, 68.933319],
                [-85.140556, 68.94664],
                [-85.115013, 68.958603],
                [-84.796387, 68.933594],
                [-84.986923, 68.999512],
                [-84.834732, 69.008041],
                [-84.818336, 69.032211],
                [-84.591667, 68.994431],
                [-84.528343, 69.017487],
                [-84.736107, 69.035263],
                [-84.769447, 69.083054],
                [-84.946663, 69.081383],
                [-84.963898, 69.1147],
                [-85.02417, 69.11499],
                [-84.968613, 69.136932],
                [-84.998611, 69.14444],
                [-85.123047, 69.11554],
                [-85.138901, 69.129433],
                [-85.101097, 69.154709],
                [-84.99472, 69.173027],
                [-85.172501, 69.169983],
                [-85.223618, 69.12886],
                [-85.315552, 69.148041],
                [-85.203339, 69.180542],
                [-85.383057, 69.205551],
                [-85.503067, 69.314423],
                [-85.345284, 69.306931],
                [-85.508057, 69.395264],
                [-85.383331, 69.415268],
                [-85.410278, 69.443314],
                [-85.339172, 69.438873],
                [-85.422783, 69.462486],
                [-85.491379, 69.436653],
                [-85.542503, 69.476379],
                [-85.479446, 69.562477],
                [-85.38028, 69.56694],
                [-85.544159, 69.637772],
                [-85.526672, 69.67276],
                [-85.438599, 69.701103],
                [-85.441383, 69.739151],
                [-85.514717, 69.768051],
                [-85.409157, 69.788589],
                [-85.389183, 69.780548],
                [-85.410828, 69.755257],
                [-85.379173, 69.753601],
                [-85.333069, 69.774147],
                [-85.344452, 69.813026],
                [-85.586403, 69.826927],
                [-85.561943, 69.859421],
                [-85.377213, 69.851089],
                [-85.064438, 69.769989],
                [-85.049438, 69.799713],
                [-84.871658, 69.816093],
                [-84.854172, 69.831383],
                [-84.882492, 69.855553],
                [-84.75, 69.832764],
                [-84.433609, 69.861099],
                [-83.733887, 69.70665],
                [-83.34639, 69.676086],
                [-83.253342, 69.705261],
                [-83.008057, 69.679153],
                [-82.669998, 69.67804],
                [-82.678329, 69.701103],
                [-82.25473, 69.636383],
                [-82.407501, 69.608597],
                [-82.600281, 69.64415],
                [-82.597778, 69.621643],
                [-82.652786, 69.618317],
                [-82.550552, 69.588043],
                [-82.743332, 69.577477],
                [-82.612503, 69.56694],
                [-82.500557, 69.492203],
                [-83.241096, 69.556641],
                [-83.293877, 69.534416],
                [-82.785278, 69.494141],
                [-82.237213, 69.400818],
                [-82.391678, 69.398613],
                [-82.14389, 69.300262],
                [-82.251106, 69.287773],
                [-82.226936, 69.26416],
                [-82.291382, 69.246643],
                [-82.258621, 69.233871],
                [-82.041107, 69.242752],
                [-82.011673, 69.273613],
                [-81.693878, 69.263046],
                [-81.497772, 69.197746],
                [-81.332497, 69.18248],
                [-81.275009, 69.086647],
                [-81.462784, 69.072769],
                [-81.570282, 68.992477],
                [-81.691673, 68.988586],
                [-81.71666, 68.949142],
                [-82.05722, 68.873871],
                [-81.67305, 68.904427],
                [-81.588058, 68.869713],
                [-81.569458, 68.827209],
                [-81.490547, 68.866089],
                [-81.382767, 68.866653],
                [-81.234444, 68.769989],
                [-81.267776, 68.74498],
                [-81.255836, 68.643051],
                [-81.633904, 68.508041],
                [-81.810547, 68.523323],
                [-81.79361, 68.50444],
                [-81.830292, 68.459717],
                [-81.97084, 68.421097],
                [-82.024437, 68.43692],
                [-82.077499, 68.503601],
                [-82.256393, 68.5336],
                [-82.173317, 68.485809],
                [-82.228058, 68.455261],
                [-82.608887, 68.517487],
                [-82.635277, 68.492203],
                [-82.491096, 68.453873],
                [-82.648064, 68.445251],
                [-82.644447, 68.417213],
                [-82.501678, 68.405823],
                [-82.363052, 68.35054],
                [-82.369453, 68.318329],
                [-82.507782, 68.313309],
                [-82.426102, 68.276657],
                [-82.264717, 68.284416],
                [-82.270279, 68.235527],
                [-82.345551, 68.170258],
                [-82.314438, 68.146652],
                [-82.179169, 68.111649],
                [-82.047501, 68.209717],
                [-81.988892, 68.20665],
                [-82.027222, 68.122757],
                [-82.120537, 68.105553],
                [-82.079453, 68.082489],
                [-82.173889, 68.002487],
                [-82.102783, 67.907211],
                [-81.936661, 67.823318],
                [-81.804443, 67.798317],
                [-81.828339, 67.774147],
                [-81.43306, 67.611366],
                [-81.243057, 67.474991],
                [-81.239166, 67.436096],
                [-81.304718, 67.386108],
                [-81.347778, 67.292763],
                [-81.38028, 67.170532],
                [-81.43222, 67.066673],
                [-81.495003, 67.053307],
                [-81.492493, 67.00972],
                [-81.52417, 66.99054],
                [-81.626099, 67.005257],
                [-81.711937, 66.969994],
                [-81.833618, 66.997757],
                [-81.988327, 66.959427],
                [-82.042503, 66.918053],
                [-82.018341, 66.89444],
                [-82.090561, 66.857208],
                [-82.077499, 66.828323],
                [-82.183884, 66.76416],
                [-82.156631, 66.709518],
                [-82.36972, 66.725807],
                [-82.376099, 66.696373],
                [-82.556381, 66.623871],
                [-82.596947, 66.560257],
                [-83.017227, 66.546944],
                [-83.015289, 66.506104],
                [-83.058609, 66.470261],
                [-83.368332, 66.348877],
                [-83.573624, 66.343597],
                [-83.567497, 66.367477],
                [-83.652786, 66.407761],
                [-83.63945, 66.437187],
                [-83.529167, 66.394707],
                [-83.672501, 66.520538],
                [-83.857498, 66.544144],
                [-83.900284, 66.5961],
                [-83.977493, 66.582207],
                [-84.01445, 66.692749],
                [-83.919724, 66.697746],
                [-83.900833, 66.644707],
                [-83.85611, 66.680817],
                [-83.955276, 66.747757],
                [-83.886124, 66.813873],
                [-83.907784, 66.876648],
                [-83.945541, 66.813599],
                [-84.098343, 66.747482],
                [-84.105003, 66.708328],
                [-84.260277, 66.716377],
                [-84.314438, 66.751099],
                [-84.268623, 66.785812],
                [-84.436394, 66.818329],
                [-84.239723, 66.833878],
                [-84.382492, 66.8647],
                [-84.420273, 66.901932],
                [-84.38945, 66.923866],
                [-84.584061, 66.978333],
                [-84.370827, 66.971367],
                [-84.926941, 67.059418],
                [-84.905273, 67.03804],
                [-84.935272, 67.029709],
                [-84.839722, 67.003883],
                [-84.871658, 66.984993],
                [-84.765556, 67.006378],
                [-84.639717, 66.978043],
                [-85.04834, 66.963318],
                [-85.212219, 66.913307],
                [-85.146957, 66.904709],
                [-85.228882, 66.873306],
                [-85.120003, 66.835541],
                [-84.940277, 66.863876],
                [-84.900833, 66.898041],
                [-84.993057, 66.923027],
                [-84.767776, 66.952209],
                [-84.602219, 66.935806],
                [-84.621109, 66.915268],
                [-84.56221, 66.901382],
                [-84.746384, 66.897491],
                [-84.50473, 66.823036],
                [-84.690552, 66.839981],
                [-84.319733, 66.774696],
                [-84.44249, 66.710823],
                [-84.324448, 66.676933],
                [-84.144447, 66.681374],
                [-84.146118, 66.642212],
                [-84.215012, 66.589432],
                [-83.916397, 66.44664],
                [-83.871109, 66.394989],
                [-83.864166, 66.312477],
                [-83.783333, 66.299149],
                [-83.678596, 66.201103],
                [-83.847778, 66.15332],
                [-83.786667, 66.197746],
                [-83.801102, 66.21666],
                [-83.964722, 66.195251],
                [-84.2164, 66.321663],
                [-84.347229, 66.276932],
                [-84.392227, 66.291367],
                [-84.426941, 66.363037],
                [-84.528877, 66.40416],
                [-84.636124, 66.328873],
                [-84.401398, 66.211113],
                [-84.37471, 66.1586],
                [-84.460564, 66.159149],
                [-84.565552, 66.204987],
                [-84.761124, 66.213318],
                [-84.869164, 66.266663],
                [-84.979721, 66.246643],
                [-85.078888, 66.277771],
                [-85.117767, 66.324707],
                [-85.178329, 66.262207],
                [-85.252228, 66.273041],
                [-85.306664, 66.314148],
                [-85.293327, 66.349152],
                [-85.338058, 66.39415],
                [-85.343338, 66.486923],
                [-85.479721, 66.581383],
                [-85.845284, 66.49942],
                [-86.128326, 66.498032],
                [-86.333618, 66.549988],
                [-86.406952, 66.514427],
                [-86.581123, 66.555817],
                [-86.580002, 66.520538],
                [-86.622772, 66.506653],
                [-86.780838, 66.520828],
                [-86.641678, 66.431931],
                [-86.811111, 66.441933],
                [-86.638344, 66.315262],
                [-86.332779, 66.282761],
                [-85.897781, 66.163307],
                [-86.014183, 66.100273],
                [-85.977493, 66.077766],
                [-85.980003, 66.028587],
                [-86.204727, 65.982758],
                [-86.239166, 65.936653],
                [-86.326401, 65.904984],
                [-86.421387, 65.902206],
                [-86.496948, 65.808029],
                [-86.455276, 65.786102],
                [-86.451111, 65.747208],
                [-86.554993, 65.664703],
                [-86.679169, 65.647217],
                [-86.829727, 65.555817],
                [-86.987213, 65.534149],
                [-86.975563, 65.515266],
                [-87.024437, 65.481659],
                [-87.110283, 65.458603],
                [-87.115013, 65.433594],
                [-87.059433, 65.414146],
                [-87.100563, 65.391937],
                [-87.372772, 65.322769],
                [-87.96666, 65.333054],
                [-88.212784, 65.402771],
                [-88.333618, 65.492477],
                [-88.559723, 65.586929],
                [-88.829727, 65.641373],
                [-88.411942, 65.630257],
                [-88.85083, 65.701393],
                [-88.93721, 65.685806],
                [-89.124443, 65.733322],
                [-89.174438, 65.778587],
                [-89.428329, 65.867203],
                [-89.516113, 65.858032],
                [-89.525284, 65.886932],
                [-89.671661, 65.939423],
                [-90, 65.874771],
                [-90.404449, 65.871094],
                [-90.432503, 65.882751],
                [-90.393066, 65.896103],
                [-90.225563, 65.900543],
                [-90.234734, 65.922493],
                [-90.596123, 65.896652],
                [-91.316391, 65.969994],
                [-91.496948, 65.945824],
                [-91.020554, 65.806091],
                [-91.123047, 65.904709],
                [-91.088058, 65.921097],
                [-90.545273, 65.883331],
                [-90, 65.796883],
                [-89.462509, 65.600807],
                [-89.40361, 65.506653],
                [-89.170837, 65.428307],
                [-89.054443, 65.328873],
                [-88.061661, 65.258881],
                [-87.67305, 65.291367],
                [-87.357773, 65.270828],
                [-87.051392, 65.236366],
                [-87.030289, 65.223038],
                [-87.051941, 65.199997],
                [-86.933319, 65.138046],
                [-86.997223, 65.096649],
                [-86.970284, 65.054428],
                [-87.110001, 64.999153],
                [-87.078339, 64.976646],
                [-87.096657, 64.950821],
                [-87.236389, 64.88472],
                [-87.303596, 64.806641],
                [-87.294449, 64.763046],
                [-87.451401, 64.707489],
                [-87.489441, 64.741089],
                [-87.525284, 64.714157],
                [-87.511398, 64.636658],
                [-87.579178, 64.568047],
                [-87.80722, 64.514427],
                [-87.888062, 64.338593],
                [-88.044724, 64.247482],
                [-88.05249, 64.227203],
                [-87.983887, 64.191093],
                [-88.113327, 64.136108],
                [-88.227493, 64.140266],
                [-88.736656, 63.968319],
                [-88.856949, 63.977489],
                [-88.898621, 64.012207],
                [-89.000839, 63.988041],
                [-88.995827, 64.008331],
                [-89.150833, 64.059418],
                [-89.260834, 64.160538],
                [-89.286392, 64.136658],
                [-89.182503, 64.036652],
                [-89.096947, 63.963879],
                [-89.023621, 63.950272],
                [-89.14389, 63.95055],
                [-89.273064, 64.014709],
                [-89.246658, 63.959721],
                [-89.363892, 64.037491],
                [-89.444717, 64.021652],
                [-89.551102, 64.077477],
                [-89.563316, 64.00943],
                [-89.48555, 63.942211],
                [-89.644173, 64.053864],
                [-89.712219, 64.079437],
                [-89.697487, 64.042213],
                [-89.720551, 64.033333],
                [-89.818069, 64.098602],
                [-89.809723, 64.12886],
                [-89.735817, 64.145264],
                [-89.773064, 64.240807],
                [-89.812767, 64.193863],
                [-89.883904, 64.200546],
                [-89.816391, 64.17276],
                [-89.823624, 64.14444],
                [-90.121422, 64.127861],
                [-89.914719, 64.106934],
                [-89.946114, 64.05748],
                [-89.848053, 64.021927],
                [-89.915283, 64.015823],
                [-89.913887, 63.99332],
                [-89.831123, 63.979988],
                [-89.813606, 63.93721],
                [-89.989441, 63.92194],
                [-89.946663, 63.965271],
                [-90, 63.984039],
                [-90.093887, 63.95055],
                [-90.182503, 64.008614],
                [-90.279167, 64.003601],
                [-90.090561, 63.92416],
                [-90.122223, 63.915272],
                [-90.10083, 63.88361],
                [-90, 63.773899],
                [-90.115829, 63.777489],
                [-90.05777, 63.744438],
                [-90.16861, 63.65694],
                [-90.148903, 63.629162],
                [-90.205276, 63.612209],
                [-90.281387, 63.613049],
                [-90.218887, 63.635551],
                [-90.289169, 63.64777],
                [-90.452217, 63.616661],
                [-90.486389, 63.63221],
                [-90.465012, 63.648048],
                [-90.539993, 63.655819],
                [-90.488052, 63.672489],
                [-90.623894, 63.704159],
                [-90.699158, 63.65749],
                [-90.590286, 63.676659],
                [-90.555557, 63.65527],
                [-90.551392, 63.601391],
                [-90.930557, 63.564159],
                [-91.063316, 63.62027],
                [-91.375816, 63.659161],
                [-91.393623, 63.685261],
                [-91.329453, 63.677769],
                [-91.411667, 63.707211],
                [-91.667221, 63.719711],
                [-91.654167, 63.750832],
                [-91.693878, 63.774712],
                [-91.751106, 63.733879],
                [-91.910553, 63.740551],
                [-91.986107, 63.776379],
                [-91.88945, 63.80138],
                [-91.984734, 63.81916],
                [-92.045547, 63.74527],
                [-92.136124, 63.745541],
                [-92.376389, 63.77721],
                [-92.506393, 63.83194],
                [-92.706947, 63.846661],
                [-92.761398, 63.890549],
                [-93.436661, 64.015266],
                [-93.612213, 64.093048],
                [-93.635834, 64.120247],
                [-93.61528, 64.157761],
                [-93.773064, 64.193588],
                [-93.66861, 64.126923],
                [-93.660004, 64.083054],
                [-93.548889, 64.024696],
                [-93.602219, 64.007492],
                [-93.547501, 64.003883],
                [-93.58168, 63.984989],
                [-93.76445, 63.988331],
                [-93.770554, 63.957771],
                [-93.699432, 63.95166],
                [-93.649727, 63.877209],
                [-93.339996, 63.808331],
                [-93.378883, 63.84277],
                [-93.21611, 63.843609],
                [-93.343338, 63.863049],
                [-93.448883, 63.925831],
                [-93.424438, 63.937771],
                [-93.451111, 63.959721],
                [-93.364723, 63.967491],
                [-93.226257, 63.908489],
                [-92.756958, 63.82555],
                [-92.622498, 63.77166],
                [-92.49527, 63.814709],
                [-92.395554, 63.736938],
                [-92.251106, 63.740551],
                [-92.103882, 63.70166],
                [-92.198608, 63.67749],
                [-92.206947, 63.651661],
                [-92.171944, 63.640549],
                [-92.284729, 63.62138],
                [-92.337784, 63.644711],
                [-92.38501, 63.592491],
                [-92.539436, 63.55582],
                [-92.471939, 63.523319],
                [-92.320557, 63.572769],
                [-92.230293, 63.534721],
                [-92.193878, 63.615269],
                [-91.776108, 63.715832],
                [-91.617493, 63.64888],
                [-91.60083, 63.580551],
                [-91.387222, 63.521099],
                [-91.388901, 63.485271],
                [-91.27417, 63.502491],
                [-91.065277, 63.449162],
                [-91.016403, 63.46693],
                [-90.786392, 63.39555],
                [-90.975014, 63.419441],
                [-90.688599, 63.355],
                [-90.681107, 63.331108],
                [-90.741669, 63.324169],
                [-90.740829, 63.29472],
                [-90.698608, 63.2761],
                [-90.723328, 63.249722],
                [-90.684433, 63.21888],
                [-90.730293, 63.178051],
                [-90.672501, 63.157768],
                [-90.690552, 63.11832],
                [-90.651947, 63.087769],
                [-90.689987, 63.076099],
                [-90.627487, 63.059429],
                [-90.703056, 63.035],
                [-90.720284, 63.011669],
                [-90.689713, 63.006939],
                [-90.756958, 62.99332],
                [-90.72084, 62.967209],
                [-90.783073, 62.961109],
                [-90.785004, 62.938042],
                [-91.04805, 62.942211],
                [-91.182503, 62.905819],
                [-91.213333, 62.85416],
                [-91.410828, 62.819721],
                [-91.356377, 62.788601],
                [-92.121933, 62.874161],
                [-92.09639, 62.798882],
                [-92.339996, 62.843609],
                [-92.455276, 62.814709],
                [-92.333618, 62.709991],
                [-92.219162, 62.684429],
                [-92.286942, 62.676941],
                [-91.948883, 62.651932],
                [-91.880547, 62.619438],
                [-91.948036, 62.53138],
                [-92.053329, 62.526661],
                [-92.174713, 62.54472],
                [-92.143341, 62.579159],
                [-92.16333, 62.60083],
                [-92.266113, 62.595268],
                [-92.245537, 62.564709],
                [-92.321671, 62.56916],
                [-92.325562, 62.540829],
                [-92.384743, 62.529991],
                [-92.483063, 62.552769],
                [-92.47583, 62.595268],
                [-92.519173, 62.571659],
                [-92.615829, 62.614159],
                [-92.539169, 62.53249],
                [-92.563889, 62.4986],
                [-92.498047, 62.508331],
                [-92.541382, 62.476379],
                [-92.529716, 62.44305],
                [-92.710007, 62.465832],
                [-92.730827, 62.4286],
                [-92.681953, 62.374439],
                [-92.822777, 62.349159],
                [-92.598053, 62.310822],
                [-92.601669, 62.265549],
                [-92.633904, 62.25388],
                [-92.470016, 62.14661],
                [-92.603882, 62.15638],
                [-92.624443, 62.210548],
                [-92.747498, 62.289989],
                [-93.144974, 62.337261],
                [-92.780289, 62.236938],
                [-92.790283, 62.17749],
                [-93.001404, 62.200272],
                [-92.981667, 62.171101],
                [-93.110001, 62.156651],
                [-93.126938, 62.132488],
                [-93.08139, 62.104439],
                [-93.019173, 62.126099],
                [-92.931381, 62.10416],
                [-92.991096, 62.067768],
                [-93.067497, 62.076939],
                [-93.078613, 62.035831],
                [-93.156113, 62.049721],
                [-93.162216, 61.97887],
                [-93.30278, 62.049438],
                [-93.329453, 62.047779],
                [-93.294724, 62.018879],
                [-93.411392, 62.03138],
                [-93.218338, 61.952492],
                [-93.388344, 61.94582],
                [-93.305267, 61.920551],
                [-93.279716, 61.894711],
                [-93.299728, 61.88583],
                [-93.619453, 61.948601],
                [-93.491669, 61.884159],
                [-93.600281, 61.879162],
                [-93.613617, 61.852489],
                [-93.362289, 61.821121],
                [-93.434998, 61.803879],
                [-93.248611, 61.784721],
                [-93.255569, 61.742489],
                [-93.468887, 61.764999],
                [-93.412781, 61.72332],
                [-93.313316, 61.725269],
                [-93.647781, 61.634159],
                [-93.780838, 61.551929],
                [-93.830841, 61.571659],
                [-93.874161, 61.513889],
                [-93.984734, 61.4561],
                [-93.938599, 61.408878],
                [-93.968613, 61.39666],
                [-93.868057, 61.38916],
                [-93.819168, 61.34721],
                [-93.940552, 61.294998],
                [-94.110817, 61.322769],
                [-93.998611, 61.227772],
                [-94.098892, 61.162209],
                [-94.039436, 61.15694],
                [-94.064438, 61.110279],
                [-93.980293, 61.0961],
                [-94.128601, 61.06554],
                [-94.233887, 60.904709],
                [-94.349167, 60.858601],
                [-94.415283, 60.762211],
                [-94.404999, 60.709721],
                [-94.484444, 60.64941],
                [-94.509453, 60.544159],
                [-94.575844, 60.520271],
                [-94.732773, 60.53944],
                [-94.652496, 60.492489],
                [-94.796951, 60.497219],
                [-94.68277, 60.47443],
                [-94.613892, 60.38055],
                [-94.706673, 60.260551],
                [-94.673607, 60.191101],
                [-94.720551, 60.138329],
                [-94.707497, 60.083599],
                [-94.766403, 60.061378],
                [-94.758621, 60.017769],
                [-94.800491, 59.999569],
                [-99.626953, 59.999729],
                [-100.039001, 59.999722],
                [-102.001701, 59.99992],
                [-101.984001, 64.21341],
                [-109.387397, 64.826919],
                [-110.651709, 65.499518],
                [-112.498556, 65.506191],
                [-120.658461, 68.001268],
                [-120.664292, 69.542782],
                [-120.66524, 69.551327],
                [-120.393303, 69.439697],
                [-119.982201, 69.344711],
                [-118.840599, 69.250549],
                [-118.644699, 69.212486],
                [-118.457802, 69.117477],
                [-117.870499, 68.985527],
                [-117.381897, 68.951927],
                [-117.1539, 68.885536],
                [-116.9664, 68.902206],
                [-117.0103, 68.936371],
                [-116.950798, 68.939972],
                [-116.847198, 68.883331],
                [-116.744499, 68.880539],
                [-116.500298, 68.857208],
                [-116.381401, 68.882477],
                [-116.2136, 68.830551],
                [-115.959999, 68.804703],
                [-116.116898, 68.861923],
                [-116.082497, 68.882751],
                [-116.325302, 68.961929],
                [-116.205002, 68.984993],
                [-115.8675, 68.92276],
                [-115.766998, 68.939148],
                [-115.935303, 69.006943],
                [-115.593102, 68.971649],
                [-115.094498, 68.85054],
                [-114.989998, 68.862762],
                [-114.791901, 68.799423],
                [-114.729401, 68.744431],
                [-114.448303, 68.689697],
                [-114.465302, 68.664429],
                [-114.404701, 68.61499],
                [-114.187798, 68.565536],
                [-114.170799, 68.528587],
                [-114.088898, 68.496368],
                [-114.057503, 68.433868],
                [-114.123901, 68.430267],
                [-114.095001, 68.402481],
                [-114.006699, 68.393883],
                [-113.974701, 68.435532],
                [-113.922798, 68.408867],
                [-113.912201, 68.38916],
                [-114.0467, 68.311653],
                [-114.0233, 68.241364],
                [-114.207199, 68.253883],
                [-114.32, 68.229156],
                [-114.441704, 68.263611],
                [-114.702499, 68.250267],
                [-114.959999, 68.285812],
                [-114.764702, 68.186096],
                [-114.8964, 68.146942],
                [-115.237198, 68.182213],
                [-115.172798, 68.128593],
                [-115.2267, 68.089706],
                [-115.240501, 68.036377],
                [-115.110497, 68.012207],
                [-115.542503, 67.921654],
                [-115.507202, 67.889427],
                [-115.253098, 67.883041],
                [-115.2761, 67.861649],
                [-115.113602, 67.798599],
                [-114.718903, 67.813309],
                [-114.608597, 67.771378],
                [-114.516701, 67.789429],
                [-114.297798, 67.718597],
                [-114.148399, 67.736923],
                [-113.845802, 67.69136],
                [-113.240799, 67.704437],
                [-113.050003, 67.666092],
                [-112.709396, 67.667763],
                [-112.370003, 67.681931],
                [-112.333603, 67.696373],
                [-112.368599, 67.728317],
                [-112.345299, 67.7397],
                [-112.011101, 67.742477],
                [-111.985497, 67.693039],
                [-111.8936, 67.679428],
                [-111.871101, 67.6922],
                [-111.929199, 67.718323],
                [-111.912804, 67.754173],
                [-111.538902, 67.712486],
                [-111.5728, 67.744431],
                [-111.5317, 67.765549],
                [-111.458603, 67.763046],
                [-111.474197, 67.735527],
                [-111.441101, 67.731934],
                [-111.322502, 67.806931],
                [-111.175797, 67.837486],
                [-111.143898, 67.828873],
                [-111.205597, 67.794144],
                [-111.1689, 67.784416],
                [-111.2006, 67.755547],
                [-111.085297, 67.786652],
                [-111.005798, 67.764999],
                [-110.840599, 67.800262],
                [-110.732498, 67.864433],
                [-110.329201, 67.945251],
                [-110.339996, 67.965553],
                [-110.139702, 67.916656],
                [-110.201698, 67.966087],
                [-110.116898, 68.008881],
                [-109.970802, 67.958038],
                [-110.003601, 67.866379],
                [-109.982498, 67.837196],
                [-109.912498, 67.845833],
                [-109.953102, 67.881927],
                [-109.890297, 67.879967],
                [-109.695801, 67.804153],
                [-109.744202, 67.775543],
                [-109.623001, 67.74054],
                [-109.735802, 67.742752],
                [-109.726097, 67.716927],
                [-109.522499, 67.687477],
                [-109.485497, 67.709991],
                [-109.506104, 67.733322],
                [-109.395302, 67.767487],
                [-109.3703, 67.729156],
                [-109.065804, 67.714157],
                [-108.919197, 67.535812],
                [-109.025299, 67.483322],
                [-109.002197, 67.443588],
                [-108.928596, 67.432747],
                [-108.929703, 67.405823],
                [-108.858902, 67.408867],
                [-108.812798, 67.348877],
                [-108.744499, 67.445534],
                [-108.736397, 67.603043],
                [-108.620499, 67.624687],
                [-108.578903, 67.605553],
                [-108.587196, 67.577209],
                [-108.511398, 67.487488],
                [-108.522797, 67.440811],
                [-108.4842, 67.349426],
                [-108.424698, 67.364151],
                [-108.440002, 67.427467],
                [-108.366898, 67.444977],
                [-108.015297, 67.293053],
                [-107.875, 67.140823],
                [-107.914398, 67.132477],
                [-107.880501, 67.048317],
                [-108.148598, 67.07666],
                [-108.232803, 67.01915],
                [-108.622803, 67.149986],
                [-108.516098, 67.034416],
                [-108.4561, 67.070541],
                [-108.330597, 66.986923],
                [-108.196999, 66.972214],
                [-108.1147, 66.928864],
                [-108.118103, 66.862198],
                [-108.232498, 66.845543],
                [-107.991096, 66.832764],
                [-107.939697, 66.766663],
                [-107.949402, 66.720543],
                [-107.882797, 66.663307],
                [-107.851402, 66.73027],
                [-107.888298, 66.755547],
                [-107.853302, 66.756378],
                [-107.691101, 66.642212],
                [-107.724197, 66.6297],
                [-107.647499, 66.574707],
                [-107.569504, 66.530548],
                [-107.5103, 66.542213],
                [-107.446404, 66.461647],
                [-107.222198, 66.349152],
                [-107.195801, 66.366379],
                [-107.235497, 66.407494],
                [-107.550797, 66.582764],
                [-107.649399, 66.693863],
                [-107.797203, 66.756104],
                [-107.6903, 66.767761],
                [-107.769402, 66.945534],
                [-107.764198, 67.001663],
                [-107.613297, 66.964996],
                [-107.650803, 67.076103],
                [-107.523903, 67.020264],
                [-107.484703, 66.918587],
                [-107.693604, 66.94664],
                [-107.629402, 66.888046],
                [-107.532501, 66.874153],
                [-107.5644, 66.833603],
                [-107.408897, 66.811096],
                [-107.486397, 66.877197],
                [-107.393898, 66.885269],
                [-107.435303, 66.972214],
                [-107.083603, 66.820541],
                [-107.2267, 66.961647],
                [-107.4039, 67.041656],
                [-107.250801, 67.07193],
                [-107.199699, 67.123871],
                [-107.4814, 67.180817],
                [-107.721901, 67.410812],
                [-107.635597, 67.402206],
                [-107.582802, 67.496368],
                [-108.007004, 67.707489],
                [-108.013901, 67.756104],
                [-107.941902, 67.846939],
                [-107.714699, 67.892487],
                [-107.6511, 67.940536],
                [-107.910301, 67.988586],
                [-107.890602, 68.081673],
                [-107.8517, 68.106087],
                [-107.693604, 68.071663],
                [-107.809402, 68.051651],
                [-107.739998, 68.03804],
                [-107.833298, 68.013046],
                [-107.778099, 67.986649],
                [-107.68, 68.045822],
                [-107.349403, 68.048599],
                [-107.154404, 68.126923],
                [-107.110298, 68.126923],
                [-107.118896, 68.084717],
                [-106.958603, 68.089432],
                [-106.952797, 68.1147],
                [-106.756401, 68.10498],
                [-106.753098, 68.157761],
                [-106.809402, 68.207207],
                [-106.779404, 68.223602],
                [-106.6847, 68.223038],
                [-106.703903, 68.194427],
                [-106.663597, 68.169434],
                [-106.625504, 68.184418],
                [-106.641098, 68.23082],
                [-106.614403, 68.248032],
                [-106.468597, 68.190536],
                [-106.444702, 68.151657],
                [-106.344704, 68.183319],
                [-106.495796, 68.22554],
                [-106.4244, 68.299988],
                [-106.466904, 68.324707],
                [-106.447502, 68.344711],
                [-106.2183, 68.392487],
                [-106.124199, 68.362198],
                [-106.055801, 68.398613],
                [-105.981697, 68.393883],
                [-105.966103, 68.421654],
                [-105.845802, 68.415268],
                [-105.821999, 68.449997],
                [-105.782204, 68.413879],
                [-105.730301, 68.419144],
                [-105.698303, 68.491928],
                [-105.7742, 68.548317],
                [-105.666397, 68.638603],
                [-105.790604, 68.609993],
                [-105.886703, 68.63472],
                [-106.029404, 68.619713],
                [-106.551102, 68.514999],
                [-106.419403, 68.510269],
                [-106.556702, 68.493317],
                [-106.505302, 68.471367],
                [-106.528603, 68.443039],
                [-106.631699, 68.461929],
                [-106.480598, 68.395538],
                [-106.5644, 68.342758],
                [-106.5261, 68.306374],
                [-106.543297, 68.291367],
                [-106.595001, 68.296654],
                [-106.644402, 68.347488],
                [-106.724403, 68.349716],
                [-106.713097, 68.371368],
                [-106.802803, 68.414429],
                [-106.796097, 68.370529],
                [-107.013603, 68.369431],
                [-107.131897, 68.272491],
                [-107.258904, 68.259987],
                [-107.338097, 68.315811],
                [-107.478897, 68.31694],
                [-107.5467, 68.347488],
                [-107.813301, 68.342484],
                [-107.8797, 68.258881],
                [-107.603104, 68.165268],
                [-107.800301, 68.183319],
                [-107.967796, 68.145538],
                [-108.1614, 68.17276],
                [-108.3703, 68.112762],
                [-108.436096, 68.141373],
                [-108.397499, 68.163597],
                [-108.362801, 68.156372],
                [-108.378304, 68.13443],
                [-108.3386, 68.145828],
                [-108.347504, 68.226929],
                [-108.392502, 68.218872],
                [-108.391998, 68.180267],
                [-108.455002, 68.190536],
                [-108.402199, 68.294144],
                [-108.484398, 68.305252],
                [-108.715599, 68.231369],
                [-108.819199, 68.266663],
                [-108.741699, 68.294983],
                [-108.741096, 68.342758],
                [-108.5811, 68.406372],
                [-108.345299, 68.601929],
                [-107.305603, 68.697197],
                [-106.820801, 68.811371],
                [-106.655296, 68.811371],
                [-106.214699, 68.94136],
                [-105.797997, 68.879433],
                [-105.475304, 68.720543],
                [-105.5439, 68.656647],
                [-105.407501, 68.51915],
                [-105.326103, 68.510818],
                [-105.321404, 68.474152],
                [-105.433899, 68.489151],
                [-105.538101, 68.445534],
                [-105.533096, 68.4086],
                [-105.338303, 68.412201],
                [-105.350304, 68.372482],
                [-105.294998, 68.339157],
                [-105.055603, 68.258041],
                [-104.998596, 68.274696],
                [-105.082497, 68.308029],
                [-104.939201, 68.309708],
                [-104.893898, 68.348877],
                [-104.839401, 68.288589],
                [-104.952003, 68.247482],
                [-104.900299, 68.225273],
                [-104.822197, 68.261658],
                [-104.609703, 68.241364],
                [-104.597198, 68.222214],
                [-104.670303, 68.138603],
                [-104.600601, 68.135818],
                [-104.592201, 68.083603],
                [-104.495499, 68.062477],
                [-104.5, 68.031937],
                [-104.303001, 68.051651],
                [-104.166702, 68.017487],
                [-103.555298, 68.057213],
                [-103.532799, 68.071663],
                [-103.553001, 68.109711],
                [-103.404198, 68.164146],
                [-103.3414, 68.116089],
                [-103.379204, 68.083328],
                [-103.366096, 68.005829],
                [-103.136902, 67.958038],
                [-103.093102, 67.923027],
                [-102.959396, 67.926933],
                [-102.800301, 67.820831],
                [-102.470802, 67.786926],
                [-102.311699, 67.717484],
                [-102.251099, 67.725273],
                [-102.227501, 67.68692],
                [-102.198601, 67.695824],
                [-102.224403, 67.733871],
                [-102.151398, 67.765549],
                [-102.039398, 67.742203],
                [-101.9786, 67.787773],
                [-101.800003, 67.704987],
                [-101.741898, 67.727768],
                [-101.671898, 67.69165],
                [-101.409698, 67.687759],
                [-101.446701, 67.732483],
                [-101.257797, 67.718597],
                [-101.233101, 67.731934],
                [-101.250298, 67.771103],
                [-101.166397, 67.754707],
                [-101.089401, 67.786102],
                [-101.0989, 67.737762],
                [-100.9039, 67.756943],
                [-100.915604, 67.817757],
                [-100.821999, 67.792213],
                [-100.699997, 67.846939],
                [-100.586403, 67.838593],
                [-100.587502, 67.79332],
                [-100.395599, 67.847488],
                [-99.820007, 67.795822],
                [-99.776672, 67.823883],
                [-99.632492, 67.789429],
                [-99.500557, 67.799713],
                [-99.478333, 67.771927],
                [-99.412216, 67.788307],
                [-99.210564, 67.70694],
                [-99.118881, 67.727768],
                [-98.930557, 67.696091],
                [-98.896118, 67.748322],
                [-98.761124, 67.743874],
                [-98.693604, 67.807747],
                [-98.597778, 67.77832],
                [-98.354446, 67.796097],
                [-98.449432, 67.865807],
                [-98.540283, 67.872208],
                [-98.724167, 67.953598],
                [-98.732773, 68.070267],
                [-98.585281, 68.091927],
                [-98.233322, 67.901932],
                [-98.088608, 67.756653],
                [-97.998611, 67.753052],
                [-97.92749, 67.695534],
                [-97.799438, 67.685532],
                [-97.738892, 67.642212],
                [-97.547501, 67.596367],
                [-97.283333, 67.662491],
                [-97.201401, 67.623871],
                [-97.179169, 67.674149],
                [-97.059158, 67.674988],
                [-97.144997, 67.715271],
                [-97.223618, 67.712486],
                [-97.115829, 67.782761],
                [-97.24028, 67.926086],
                [-97.291107, 67.884987],
                [-97.392227, 67.876373],
                [-97.68306, 68.0186],
                [-97.710831, 67.9711],
                [-97.833618, 67.976929],
                [-97.950287, 67.920258],
                [-98.029999, 67.941933],
                [-98.057503, 67.912491],
                [-98.051941, 67.830833],
                [-98.095284, 67.833328],
                [-98.581123, 68.139977],
                [-98.459732, 68.183594],
                [-98.424438, 68.152206],
                [-98.439438, 68.104156],
                [-98.367767, 68.080551],
                [-98.326111, 68.171371],
                [-98.501106, 68.208603],
                [-98.542221, 68.234154],
                [-98.490829, 68.246933],
                [-98.715286, 68.361649],
                [-98.637222, 68.37915],
                [-98.511398, 68.329712],
                [-98.537216, 68.362198],
                [-98.461403, 68.358871],
                [-98.539436, 68.400818],
                [-98.525284, 68.421097],
                [-98.199722, 68.300262],
                [-98.154449, 68.331673],
                [-98.072777, 68.321663],
                [-98.107498, 68.364433],
                [-97.909729, 68.340553],
                [-97.920273, 68.373032],
                [-97.894119, 68.386459],
                [-97.743057, 68.370529],
                [-97.781952, 68.406647],
                [-97.899986, 68.419708],
                [-97.903061, 68.449997],
                [-98.009743, 68.498032],
                [-97.994164, 68.541092],
                [-97.852493, 68.54248],
                [-97.692757, 68.516663],
                [-97.646118, 68.450821],
                [-97.537781, 68.41832],
                [-97.509171, 68.444427],
                [-97.619164, 68.458328],
                [-97.56778, 68.496368],
                [-97.282784, 68.474152],
                [-97.10556, 68.388321],
                [-97.161392, 68.374977],
                [-97.001953, 68.345261],
                [-96.99472, 68.289978],
                [-97.070007, 68.300262],
                [-97.080292, 68.259163],
                [-96.925003, 68.239151],
                [-96.680557, 68.280823],
                [-96.625267, 68.251663],
                [-96.694153, 68.216927],
                [-96.630829, 68.178864],
                [-96.609734, 68.243317],
                [-96.410553, 68.315536],
                [-96.474716, 68.271652],
                [-96.508904, 68.196373],
                [-96.638062, 68.13916],
                [-96.623047, 68.11554],
                [-96.808884, 68.049423],
                [-96.789169, 68.017212],
                [-96.708344, 68.008614],
                [-96.622223, 68.062187],
                [-96.540283, 68.030273],
                [-96.453056, 68.039429],
                [-96.480003, 68.090271],
                [-96.54834, 68.084152],
                [-96.463058, 68.14415],
                [-95.892227, 68.294144],
                [-96.078613, 68.149986],
                [-96.020279, 68.106087],
                [-96.215286, 67.823608],
                [-96.188889, 67.73027],
                [-96.220001, 67.693039],
                [-96.166656, 67.690262],
                [-96.185272, 67.626373],
                [-96.257782, 67.626923],
                [-96.335564, 67.69664],
                [-96.329727, 67.61026],
                [-96.460007, 67.548866],
                [-96.428879, 67.537201],
                [-96.464722, 67.478043],
                [-96.396118, 67.494431],
                [-96.33168, 67.431931],
                [-96.210007, 67.419144],
                [-96.097229, 67.464706],
                [-96.070282, 67.428307],
                [-96.226936, 67.316673],
                [-96.252228, 67.247482],
                [-96.136124, 67.256378],
                [-96.114441, 67.213318],
                [-95.899437, 67.284149],
                [-95.73999, 67.376648],
                [-95.675552, 67.328323],
                [-95.612778, 67.37915],
                [-95.565826, 67.376648],
                [-95.525009, 67.351089],
                [-95.553329, 67.313309],
                [-95.833328, 67.162773],
                [-95.650284, 67.225807],
                [-95.651398, 67.198868],
                [-95.487778, 67.203873],
                [-95.474167, 67.135536],
                [-95.436943, 67.154984],
                [-95.435547, 67.193863],
                [-95.37944, 67.154709],
                [-95.373894, 67.109993],
                [-95.421661, 67.063873],
                [-95.326111, 67.027206],
                [-95.350281, 66.963882],
                [-95.422501, 66.946373],
                [-95.623322, 66.951927],
                [-95.599442, 66.981087],
                [-95.622223, 66.987488],
                [-95.928047, 66.952477],
                [-95.944443, 66.996368],
                [-95.993881, 67.00972],
                [-95.946953, 67.07222],
                [-96.051666, 67.010818],
                [-96.025009, 66.973312],
                [-96.11055, 66.950821],
                [-96.155563, 66.961647],
                [-96.133904, 66.9897],
                [-96.279167, 66.999153],
                [-96.26889, 67.060532],
                [-96.377213, 67.084717],
                [-96.460564, 67.062187],
                [-96.397232, 66.993874],
                [-96.146393, 66.894707],
                [-96.100563, 66.828598],
                [-95.848053, 66.752777],
                [-95.777496, 66.654984],
                [-95.819733, 66.583878],
                [-95.629707, 66.675537],
                [-95.652222, 66.728592],
                [-95.681107, 66.729431],
                [-95.691383, 66.679428],
                [-95.766403, 66.683594],
                [-95.784729, 66.737198],
                [-95.993057, 66.842758],
                [-96.085564, 66.926933],
                [-96.026108, 66.950272],
                [-95.814438, 66.94136],
                [-95.724716, 66.900818],
                [-95.422234, 66.930542],
                [-95.323624, 66.889427],
                [-95.254547, 66.940369],
                [-95.219452, 66.973602],
                [-95.295547, 66.998871],
                [-95.291672, 67.034714],
                [-95.347504, 67.073883],
                [-95.322777, 67.106087],
                [-95.352219, 67.148331],
                [-95.266113, 67.212769],
                [-95.248894, 67.265266],
                [-95.16333, 67.287201],
                [-95.314713, 67.330551],
                [-95.375816, 67.439972],
                [-95.419159, 67.455261],
                [-95.33168, 67.505829],
                [-95.343613, 67.554977],
                [-95.4664, 67.637207],
                [-95.693047, 67.704437],
                [-95.704453, 67.738586],
                [-95.534439, 67.820831],
                [-95.527222, 67.872208],
                [-95.580292, 67.898613],
                [-95.412781, 68.021652],
                [-95.472778, 68.060532],
                [-95.233887, 68.082489],
                [-95.256958, 68.034988],
                [-95.101669, 68.086929],
                [-95.008904, 68.044434],
                [-94.722229, 68.054977],
                [-94.582497, 68.156097],
                [-94.199158, 68.267761],
                [-94.210831, 68.361366],
                [-94.123047, 68.416931],
                [-93.661392, 68.520828],
                [-93.544998, 68.557213],
                [-93.536392, 68.598602],
                [-93.375549, 68.633331],
                [-93.751953, 68.613602],
                [-93.673607, 68.720261],
                [-93.695267, 68.751938],
                [-93.639717, 68.764709],
                [-93.641113, 68.805817],
                [-93.566101, 68.849426],
                [-93.64389, 68.895538],
                [-93.642227, 68.963882],
                [-93.843887, 68.958878],
                [-93.871109, 68.9711],
                [-93.836937, 69.006378],
                [-93.909157, 68.998032],
                [-93.803879, 69.069153],
                [-93.93222, 69.02887],
                [-93.898064, 69.025818],
                [-93.947487, 68.993042],
                [-93.925552, 68.974701],
                [-94.055832, 68.901932],
                [-94.068069, 68.864151],
                [-94.040833, 68.859711],
                [-94.079727, 68.847488],
                [-94.034157, 68.833328],
                [-93.928596, 68.882477],
                [-93.933884, 68.855263],
                [-93.809998, 68.890549],
                [-94.095284, 68.758041],
                [-94.41333, 68.71582],
                [-94.632217, 68.75],
                [-94.545273, 68.88472],
                [-94.589996, 68.908333],
                [-94.562767, 68.924698],
                [-94.599167, 68.965553],
                [-94.501953, 68.968597],
                [-94.444992, 68.938026],
                [-94.348053, 69.024696],
                [-94.224167, 69.027771],
                [-94.151398, 69.057213],
                [-94.191673, 69.083603],
                [-94.16806, 69.103867],
                [-94.005836, 69.154427],
                [-94.235001, 69.119431],
                [-94.323059, 69.149986],
                [-94.281387, 69.226646],
                [-94.299156, 69.246094],
                [-94.259171, 69.271378],
                [-94.303329, 69.304977],
                [-94.025009, 69.359711],
                [-93.808609, 69.350807],
                [-93.736656, 69.399986],
                [-93.526398, 69.438309],
                [-93.883057, 69.25],
                [-93.811943, 69.264999],
                [-93.877487, 69.224152],
                [-93.8125, 69.214996],
                [-93.85611, 69.176933],
                [-93.837219, 69.164429],
                [-93.37944, 69.376373],
                [-93.498047, 69.349152],
                [-93.585831, 69.364151],
                [-93.439438, 69.475273],
                [-93.540833, 69.523323],
                [-93.839447, 69.4897],
                [-93.916656, 69.432747],
                [-94.279167, 69.440262],
                [-94.451683, 69.5186],
                [-94.577789, 69.649986],
                [-94.626938, 69.636383],
                [-94.628601, 69.683868],
                [-94.754997, 69.661652],
                [-94.730827, 69.602768],
                [-94.862778, 69.56694],
                [-95.009453, 69.621643],
                [-95.11528, 69.612488],
                [-95.40834, 69.681931],
                [-95.649986, 69.786377],
                [-95.738892, 69.786102],
                [-95.786942, 69.741364],
                [-95.828888, 69.765266],
                [-95.799438, 69.796097],
                [-95.960831, 69.778053],
                [-96.074448, 69.841927],
                [-96.198334, 69.859711],
                [-96.08223, 69.873596],
                [-96.119164, 69.895538],
                [-96.085007, 69.911377],
                [-96.116096, 69.921371],
                [-96.097778, 69.94664],
                [-96.246948, 69.958878],
                [-96.50029, 70.101379],
                [-96.568619, 70.2686],
                [-96.519173, 70.358322],
                [-96.434723, 70.409988],
                [-96.340286, 70.415268],
                [-96.324722, 70.49054],
                [-96.243057, 70.569717],
                [-96.188316, 70.529427],
                [-96.069733, 70.587769],
                [-95.797234, 70.529427],
                [-96.061394, 70.617203],
                [-96.04834, 70.646942],
                [-95.81778, 70.710274],
                [-96.093887, 70.659988],
                [-96.202789, 70.621643],
                [-96.383621, 70.675537],
                [-96.434723, 70.737198],
                [-96.611938, 70.794434],
                [-96.591667, 70.866928],
                [-96.513062, 70.949707],
                [-96.495827, 71.040268],
                [-96.370003, 71.089981],
                [-96.409439, 71.119431],
                [-96.41362, 71.084152],
                [-96.461403, 71.080551],
                [-96.560547, 71.126373],
                [-96.450562, 71.168053],
                [-96.376663, 71.149147],
                [-96.459442, 71.197197],
                [-96.429718, 71.258331],
                [-96.503891, 71.277206],
                [-96.30722, 71.295822],
                [-96.193329, 71.389977],
                [-96.046387, 71.418053],
                [-95.89389, 71.390823],
                [-95.67305, 71.287491],
                [-95.535278, 71.290817],
                [-95.519997, 71.323883],
                [-95.588608, 71.354431],
                [-95.433319, 71.370819],
                [-95.548607, 71.428307],
                [-95.376663, 71.50943],
                [-95.490829, 71.482208],
                [-95.799728, 71.504707],
                [-95.936661, 71.546654],
                [-95.876389, 71.582764],
                [-95.90834, 71.60054],
                [-95.724716, 71.625809],
                [-95.507782, 71.719711],
                [-95.263062, 71.723877],
                [-95.288063, 71.761932],
                [-95.226936, 71.82666],
                [-94.932037, 71.840569],
                [-94.734444, 71.823318],
                [-94.607773, 71.866379],
                [-94.756958, 71.837486],
                [-94.850273, 71.848213],
                [-94.933632, 71.850113],
                [-95.213333, 71.843048],
                [-95.256668, 71.866928],
                [-95.217499, 71.944977],
                [-94.563606, 71.996933],
                [-94.388611, 71.924149],
                [-94.5, 71.898041],
                [-94.449722, 71.88443],
                [-94.460564, 71.849426],
                [-94.5625, 71.849991],
                [-94.648354, 71.812477],
                [-94.594162, 71.743317],
                [-94.538597, 71.758331],
                [-94.488327, 71.824158],
                [-94.450562, 71.78804],
                [-94.401947, 71.814148],
                [-94.356659, 71.796371],
                [-94.390839, 71.774429],
                [-94.423889, 71.661377],
                [-94.194443, 71.791931],
                [-94.142776, 71.756104],
                [-94.054443, 71.787201],
                [-93.972504, 71.745819],
                [-93.71167, 71.761383],
                [-93.696663, 71.710823],
                [-93.811394, 71.645538],
                [-93.230827, 71.473602],
                [-93.142502, 71.374977],
                [-92.973892, 71.34082],
                [-92.995003, 71.315536],
                [-92.94194, 71.288879],
                [-92.930832, 71.214432],
                [-92.855827, 71.157494],
                [-92.889999, 71.065811],
                [-92.853058, 71.039429],
                [-92.908051, 70.903587],
                [-92.851936, 70.856644],
                [-92.944992, 70.835823],
                [-93.000557, 70.872757],
                [-93.04277, 70.863876],
                [-92.958618, 70.81749],
                [-92.676666, 70.771652],
                [-92.69194, 70.745819],
                [-92.657784, 70.735809],
                [-92.718887, 70.724152],
                [-92.641113, 70.715271],
                [-92.676102, 70.674698],
                [-92.487877, 70.668053],
                [-92.297501, 70.6297],
                [-92.341667, 70.59166],
                [-92.311394, 70.569992],
                [-92.276108, 70.572487],
                [-92.257233, 70.61998],
                [-92.159157, 70.584152],
                [-92.265007, 70.551933],
                [-92.18721, 70.504707],
                [-92.285553, 70.487762],
                [-92.116653, 70.470833],
                [-92.132492, 70.434143],
                [-91.996948, 70.390823],
                [-91.99527, 70.316673],
                [-92.085564, 70.298317],
                [-91.996658, 70.29776],
                [-91.952217, 70.255257],
                [-91.920273, 70.296371],
                [-91.855003, 70.303307],
                [-91.900284, 70.330833],
                [-91.890289, 70.354431],
                [-91.729721, 70.356934],
                [-91.687767, 70.315536],
                [-91.726387, 70.298317],
                [-91.693878, 70.197479],
                [-91.636124, 70.231659],
                [-91.511398, 70.152771],
                [-92.003891, 70.121368],
                [-92.226936, 70.185257],
                [-92.282501, 70.250816],
                [-92.362503, 70.240257],
                [-92.268341, 70.208878],
                [-92.276672, 70.184418],
                [-92.480827, 70.176651],
                [-92.388611, 70.144989],
                [-92.479446, 70.09137],
                [-92.58667, 70.076927],
                [-92.467499, 70.042763],
                [-92.43222, 70.075546],
                [-92.12999, 70.084991],
                [-91.939713, 70.020264],
                [-92.081123, 69.986366],
                [-92.267227, 69.901932],
                [-92.254997, 69.877762],
                [-92.554718, 69.808319],
                [-92.565277, 69.762207],
                [-92.643341, 69.791656],
                [-92.688599, 69.74498],
                [-92.867767, 69.702209],
                [-92.535004, 69.709427],
                [-92.743797, 69.671951]
              ]
            ],
            [
              [
                [-61.28278, 66.616928],
                [-61.447781, 66.538307],
                [-61.59861, 66.550262],
                [-61.72583, 66.643051],
                [-62.004719, 66.69664],
                [-62.016659, 66.658043],
                [-62.186661, 66.627197],
                [-62.09, 66.606644],
                [-61.98, 66.648041],
                [-61.788891, 66.608597],
                [-61.576389, 66.477203],
                [-61.98695, 66.410263],
                [-61.55727, 66.413681],
                [-61.462502, 66.369141],
                [-61.554722, 66.336113],
                [-61.783058, 66.334152],
                [-61.915001, 66.281372],
                [-62.080002, 66.329437],
                [-62.2075, 66.316673],
                [-62.23, 66.40416],
                [-62.60722, 66.442749],
                [-62.628052, 66.430542],
                [-62.60397, 66.40358],
                [-62.715549, 66.407578],
                [-62.604721, 66.387573],
                [-62.494999, 66.405548],
                [-62.453892, 66.353867],
                [-62.316109, 66.304977],
                [-62.66695, 66.214432],
                [-62.757229, 66.245247],
                [-62.779442, 66.307213],
                [-62.889999, 66.333328],
                [-62.824451, 66.289429],
                [-62.809441, 66.240807],
                [-62.715, 66.20166],
                [-62.513062, 66.219437],
                [-62.34333, 66.162491],
                [-62.108341, 66.143051],
                [-62.16444, 66.125526],
                [-62.153049, 66.100807],
                [-62.03751, 66.100807],
                [-62.079731, 66.082764],
                [-62.012779, 66.071381],
                [-62.108891, 66.049988],
                [-61.98167, 66.043053],
                [-61.960281, 66.011932],
                [-62.188889, 66.012207],
                [-62.307781, 65.973877],
                [-62.52528, 66.034149],
                [-62.778881, 66.033333],
                [-62.930561, 66.146942],
                [-63.061939, 66.120819],
                [-62.93639, 66.122757],
                [-62.875, 66.04776],
                [-62.793621, 66.010818],
                [-62.586109, 66.00943],
                [-62.418331, 65.970543],
                [-62.38583, 65.931374],
                [-62.453609, 65.921921],
                [-62.36195, 65.904984],
                [-62.376389, 65.868042],
                [-62.288059, 65.813309],
                [-62.619999, 65.80304],
                [-62.727219, 65.828598],
                [-62.86417, 65.911102],
                [-62.874722, 65.883041],
                [-62.812771, 65.857483],
                [-62.82362, 65.839706],
                [-62.56945, 65.757492],
                [-62.599442, 65.718872],
                [-62.848049, 65.763893],
                [-62.799171, 65.711929],
                [-62.589722, 65.668587],
                [-62.645279, 65.587196],
                [-62.67194, 65.597214],
                [-62.66, 65.638046],
                [-62.727779, 65.620819],
                [-62.730831, 65.588882],
                [-62.784729, 65.59137],
                [-62.85973, 65.63472],
                [-62.88612, 65.734421],
                [-62.959438, 65.753883],
                [-62.91666, 65.722214],
                [-62.89167, 65.638321],
                [-62.972221, 65.580833],
                [-63.0275, 65.635818],
                [-63.211941, 65.640549],
                [-63.43639, 65.845261],
                [-63.488049, 65.936096],
                [-63.529171, 65.876923],
                [-63.36945, 65.730553],
                [-63.384171, 65.711647],
                [-63.347778, 65.666656],
                [-63.728611, 65.675812],
                [-63.671669, 65.648331],
                [-63.375, 65.632202],
                [-63.32, 65.593323],
                [-63.35973, 65.536102],
                [-63.48222, 65.525269],
                [-63.583611, 65.5961],
                [-63.608891, 65.589981],
                [-63.56945, 65.549149],
                [-63.618328, 65.541931],
                [-63.536949, 65.517487],
                [-63.526951, 65.478867],
                [-63.446949, 65.488312],
                [-63.292782, 65.428864],
                [-63.65556, 65.470833],
                [-63.389439, 65.393051],
                [-63.523331, 65.38443],
                [-63.33556, 65.300537],
                [-63.448608, 65.292213],
                [-63.34277, 65.253326],
                [-63.45805, 65.239433],
                [-63.325001, 65.220833],
                [-63.317219, 65.191093],
                [-63.513889, 65.191093],
                [-63.43972, 65.171654],
                [-63.477779, 65.157494],
                [-63.34222, 65.140549],
                [-63.433331, 65.101929],
                [-63.39999, 65.078873],
                [-63.367771, 65.063026],
                [-63.465279, 65.05748],
                [-63.38805, 65.042763],
                [-63.46944, 65.032211],
                [-63.456669, 65.004173],
                [-63.527222, 64.971916],
                [-63.504169, 64.953049],
                [-63.546951, 64.887207],
                [-63.653881, 64.911652],
                [-63.62833, 64.929703],
                [-63.656658, 64.943588],
                [-63.601391, 64.961113],
                [-63.614719, 64.99942],
                [-63.732498, 64.962196],
                [-63.824169, 64.984711],
                [-63.825562, 65.012772],
                [-63.65527, 65.03804],
                [-63.870548, 65.040817],
                [-63.881672, 65.096939],
                [-63.798889, 65.18248],
                [-63.85944, 65.179153],
                [-63.965, 65.096367],
                [-64.074722, 65.120819],
                [-64.077789, 65.068047],
                [-64.121109, 65.043869],
                [-64.205276, 65.106934],
                [-64.267227, 65.094147],
                [-64.208893, 65.155258],
                [-64.081123, 65.199417],
                [-64.231377, 65.19693],
                [-64.323334, 65.148041],
                [-64.380829, 65.181091],
                [-64.391113, 65.248871],
                [-64.337784, 65.289146],
                [-64.39917, 65.270538],
                [-64.402786, 65.291092],
                [-64.255569, 65.386383],
                [-64.237213, 65.429977],
                [-64.291107, 65.422493],
                [-64.352219, 65.3461],
                [-64.46167, 65.294983],
                [-64.455566, 65.207207],
                [-64.535004, 65.097214],
                [-64.580002, 65.12886],
                [-64.637512, 65.119713],
                [-64.611389, 65.141937],
                [-64.643623, 65.15387],
                [-64.620827, 65.192467],
                [-64.730293, 65.158333],
                [-64.68277, 65.203049],
                [-64.718338, 65.220261],
                [-64.696663, 65.234154],
                [-64.647507, 65.247757],
                [-64.73111, 65.271103],
                [-64.801102, 65.23082],
                [-64.910828, 65.303589],
                [-64.684433, 65.341087],
                [-64.910553, 65.339981],
                [-64.59111, 65.429703],
                [-64.448608, 65.41832],
                [-64.412781, 65.478592],
                [-64.468887, 65.4422],
                [-64.648064, 65.464706],
                [-64.695267, 65.427757],
                [-64.863892, 65.424698],
                [-64.98111, 65.401932],
                [-64.989723, 65.368317],
                [-65.058884, 65.376648],
                [-65.144173, 65.427757],
                [-65.135834, 65.461647],
                [-65.16806, 65.483871],
                [-64.929718, 65.524696],
                [-64.863892, 65.579987],
                [-64.788887, 65.584152],
                [-64.836937, 65.60582],
                [-64.821404, 65.641663],
                [-64.710007, 65.652206],
                [-64.853333, 65.654709],
                [-64.993607, 65.548027],
                [-65.212219, 65.552467],
                [-65.196663, 65.599152],
                [-65.318336, 65.550537],
                [-65.338608, 65.575546],
                [-65.300827, 65.590271],
                [-65.299988, 65.630814],
                [-65.125816, 65.633331],
                [-65.099731, 65.671921],
                [-64.994453, 65.701393],
                [-64.791382, 65.723312],
                [-65.024719, 65.716927],
                [-65.168327, 65.656097],
                [-65.431381, 65.669144],
                [-65.45639, 65.685532],
                [-65.372772, 65.777206],
                [-65.497772, 65.737488],
                [-65.456123, 65.826927],
                [-65.343887, 65.916656],
                [-64.963898, 65.998596],
                [-64.735001, 65.969437],
                [-64.851387, 66.032494],
                [-64.779449, 66.092209],
                [-64.721657, 66.217484],
                [-64.356377, 66.349716],
                [-64.718887, 66.273323],
                [-64.839996, 66.193314],
                [-64.858887, 66.106087],
                [-65.226936, 66.01915],
                [-65.286392, 66.038307],
                [-65.293877, 66.010818],
                [-65.398064, 65.974701],
                [-65.916107, 65.951103],
                [-65.963058, 66.034416],
                [-65.911667, 66.09166],
                [-65.634171, 66.172493],
                [-65.47139, 66.312477],
                [-65.536392, 66.318047],
                [-65.471123, 66.342484],
                [-65.47583, 66.387772],
                [-65.553879, 66.327766],
                [-65.571671, 66.268333],
                [-65.696953, 66.180542],
                [-65.95195, 66.108871],
                [-66.139183, 66.131363],
                [-66.254318, 66.220047],
                [-66.150284, 66.284149],
                [-66.262512, 66.238586],
                [-66.419724, 66.275818],
                [-66.44194, 66.266098],
                [-66.37471, 66.207207],
                [-66.504997, 66.208878],
                [-66.545547, 66.27887],
                [-66.474442, 66.275269],
                [-66.515289, 66.318604],
                [-66.471123, 66.347763],
                [-66.576111, 66.359154],
                [-66.43721, 66.413307],
                [-66.617493, 66.368591],
                [-66.767776, 66.380539],
                [-66.715012, 66.385269],
                [-66.679169, 66.454987],
                [-66.731949, 66.428864],
                [-66.813606, 66.452209],
                [-66.786942, 66.505257],
                [-66.857773, 66.536652],
                [-66.724716, 66.584717],
                [-66.851936, 66.583328],
                [-67.023064, 66.643333],
                [-67.058884, 66.642761],
                [-67.043327, 66.625809],
                [-66.884171, 66.566093],
                [-67.044724, 66.521652],
                [-67.149437, 66.531937],
                [-67.079453, 66.511932],
                [-67.10556, 66.485809],
                [-67.198608, 66.494141],
                [-67.190277, 66.52916],
                [-67.329453, 66.565262],
                [-67.338333, 66.597763],
                [-67.450287, 66.572769],
                [-67.536392, 66.622757],
                [-67.554993, 66.610527],
                [-67.521667, 66.565811],
                [-67.639999, 66.596649],
                [-67.638611, 66.576103],
                [-67.740829, 66.571114],
                [-67.296387, 66.526093],
                [-67.260559, 66.475273],
                [-67.148621, 66.443863],
                [-67.138062, 66.382202],
                [-67.185272, 66.363602],
                [-67.236389, 66.400818],
                [-67.406387, 66.429703],
                [-67.293877, 66.371643],
                [-67.188599, 66.352203],
                [-67.126099, 66.305542],
                [-67.254181, 66.302757],
                [-67.282227, 66.275269],
                [-67.371658, 66.313599],
                [-67.39917, 66.29248],
                [-67.493881, 66.352768],
                [-67.492767, 66.382202],
                [-67.638611, 66.445534],
                [-67.8125, 66.463043],
                [-67.856949, 66.5186],
                [-67.883904, 66.4897],
                [-67.923607, 66.516098],
                [-67.992767, 66.503883],
                [-67.87027, 66.454712],
                [-67.858047, 66.411926],
                [-67.760559, 66.358032],
                [-67.701401, 66.284714],
                [-67.741096, 66.243042],
                [-67.29361, 66.11026],
                [-67.252792, 66.049149],
                [-67.162514, 66.035263],
                [-67.311943, 65.996933],
                [-67.171661, 65.926376],
                [-67.194443, 65.909714],
                [-67.349167, 65.913307],
                [-67.411118, 65.943863],
                [-67.453888, 65.897491],
                [-67.60611, 65.902206],
                [-67.742767, 65.960541],
                [-67.704178, 65.891663],
                [-67.914436, 65.899147],
                [-68.030563, 65.998032],
                [-68.025833, 66.065811],
                [-67.97084, 66.087486],
                [-68.230293, 66.146378],
                [-68.276947, 66.224426],
                [-68.346947, 66.212486],
                [-68.336121, 66.174988],
                [-68.389999, 66.183594],
                [-68.456123, 66.271378],
                [-68.516403, 66.298866],
                [-68.48056, 66.215553],
                [-68.835281, 66.184982],
                [-68.493057, 66.188873],
                [-68.244164, 66.071114],
                [-68.218887, 66.081383],
                [-68.259171, 66.109993],
                [-68.220001, 66.128593],
                [-68.027222, 66.074997],
                [-68.060272, 66.054428],
                [-68.064438, 65.984421],
                [-68.121658, 66.021927],
                [-68.200287, 66.020538],
                [-68.123894, 65.963043],
                [-68.346947, 66.037773],
                [-68.192207, 65.950272],
                [-68.315552, 65.959717],
                [-68.321671, 65.911926],
                [-68.169159, 65.904984],
                [-68.142776, 65.929153],
                [-68.148354, 65.873596],
                [-68.185822, 65.854156],
                [-68.080841, 65.834427],
                [-68.203613, 65.792763],
                [-68.023903, 65.775269],
                [-67.810822, 65.815536],
                [-67.821121, 65.768051],
                [-67.943329, 65.746933],
                [-67.976669, 65.705833],
                [-67.876099, 65.694138],
                [-67.867493, 65.634987],
                [-67.90889, 65.611099],
                [-67.984161, 65.633614],
                [-67.917503, 65.599991],
                [-68.058884, 65.568047],
                [-67.951683, 65.568329],
                [-68.025833, 65.481087],
                [-67.730293, 65.636383],
                [-67.485283, 65.643051],
                [-67.462593, 65.674271],
                [-67.328056, 65.662491],
                [-67.251678, 65.606934],
                [-67.339172, 65.576103],
                [-67.260834, 65.529709],
                [-67.362213, 65.546944],
                [-67.414436, 65.525818],
                [-67.332497, 65.515549],
                [-67.335564, 65.494141],
                [-67.451401, 65.493591],
                [-67.334442, 65.443863],
                [-67.291107, 65.470261],
                [-67.058037, 65.451393],
                [-67.112122, 65.435303],
                [-67.058327, 65.426651],
                [-67.118057, 65.361923],
                [-67.309158, 65.36998],
                [-67.409439, 65.333328],
                [-67.326683, 65.31694],
                [-67.269173, 65.352203],
                [-67.120003, 65.309982],
                [-67.16806, 65.264999],
                [-67.077789, 65.250267],
                [-67.168327, 65.224152],
                [-67.128601, 65.228317],
                [-67.111107, 65.19693],
                [-66.928879, 65.229713],
                [-66.959167, 65.106644],
                [-67.055557, 65.105263],
                [-67.107773, 65.058868],
                [-66.980827, 65.087769],
                [-66.951683, 65.0811],
                [-66.961403, 65.057213],
                [-66.900284, 65.063873],
                [-66.826401, 65.149147],
                [-66.726097, 65.180267],
                [-66.753342, 65.113312],
                [-66.827217, 65.094711],
                [-66.79361, 65.083878],
                [-66.806664, 65.055542],
                [-66.915283, 65.050812],
                [-66.840286, 65.033867],
                [-66.760834, 65.064697],
                [-66.780563, 65.003883],
                [-66.736389, 64.99971],
                [-66.743057, 64.963043],
                [-66.830292, 64.940811],
                [-66.726936, 64.913879],
                [-66.767227, 64.868591],
                [-66.698334, 64.763893],
                [-66.632492, 64.781372],
                [-66.689713, 64.822487],
                [-66.644447, 64.94693],
                [-66.68866, 65.038773],
                [-66.535278, 65.010818],
                [-66.539993, 64.953323],
                [-66.480293, 64.983871],
                [-66.478607, 64.929153],
                [-66.379173, 64.912201],
                [-66.341667, 64.938873],
                [-66.309433, 64.86499],
                [-66.267502, 64.901382],
                [-66.148354, 64.868874],
                [-66.19194, 64.77887],
                [-66.339722, 64.763611],
                [-66.20723, 64.752487],
                [-66.212219, 64.685532],
                [-66.151947, 64.689148],
                [-66.116943, 64.781372],
                [-66.020844, 64.849716],
                [-66.036667, 64.708038],
                [-66.006393, 64.701103],
                [-66.011948, 64.748596],
                [-65.956673, 64.761932],
                [-65.912514, 64.681931],
                [-65.803329, 64.633881],
                [-65.89917, 64.732758],
                [-65.855827, 64.755547],
                [-65.938316, 64.799988],
                [-65.885277, 64.827209],
                [-65.958618, 64.877762],
                [-65.862503, 64.859154],
                [-65.838608, 64.882477],
                [-65.772781, 64.853317],
                [-65.786942, 64.801651],
                [-65.733612, 64.844437],
                [-65.664169, 64.808319],
                [-65.742493, 64.741089],
                [-65.704727, 64.687477],
                [-65.708618, 64.736099],
                [-65.665283, 64.771378],
                [-65.555832, 64.723312],
                [-65.586403, 64.640266],
                [-65.746948, 64.59082],
                [-65.714172, 64.570541],
                [-65.725563, 64.498032],
                [-65.518066, 64.49971],
                [-65.505569, 64.468872],
                [-65.348343, 64.525543],
                [-65.404999, 64.44136],
                [-65.334167, 64.48027],
                [-65.276398, 64.460823],
                [-65.268341, 64.511932],
                [-65.217499, 64.535538],
                [-65.238052, 64.483322],
                [-65.11528, 64.510536],
                [-65.177223, 64.47554],
                [-65.077789, 64.475807],
                [-65.078888, 64.421097],
                [-65.260559, 64.384163],
                [-65.184998, 64.356934],
                [-65.194992, 64.310257],
                [-65.65834, 64.302757],
                [-65.434158, 64.299149],
                [-65.465286, 64.291656],
                [-65.439713, 64.2686],
                [-65.558609, 64.24498],
                [-65.339447, 64.246643],
                [-65.362213, 64.224701],
                [-65.250557, 64.208328],
                [-65.391678, 64.148613],
                [-65.265007, 64.178864],
                [-65.115829, 64.106369],
                [-65.193878, 64.072769],
                [-65.049438, 64.07222],
                [-65.221939, 64.028587],
                [-64.949158, 64.014999],
                [-64.919449, 64.003883],
                [-64.954453, 63.971371],
                [-64.82251, 64.024147],
                [-64.756119, 64.001938],
                [-64.66806, 64.033051],
                [-64.632767, 63.972759],
                [-64.888901, 63.895],
                [-64.889717, 63.865269],
                [-64.974716, 63.85194],
                [-64.984161, 63.813881],
                [-64.948883, 63.774441],
                [-64.755768, 63.744389],
                [-64.689438, 63.75444],
                [-64.508347, 63.650829],
                [-64.521942, 63.611938],
                [-64.589172, 63.608051],
                [-64.536667, 63.581108],
                [-64.558327, 63.55888],
                [-64.51973, 63.494999],
                [-64.547783, 63.468048],
                [-64.501106, 63.423611],
                [-64.553329, 63.393608],
                [-64.634743, 63.410271],
                [-64.51001, 63.374989],
                [-64.495537, 63.32777],
                [-64.526138, 63.303829],
                [-64.620003, 63.31332],
                [-64.481949, 63.282211],
                [-64.648621, 63.23777],
                [-64.767502, 63.323879],
                [-64.757507, 63.379711],
                [-64.797501, 63.395828],
                [-64.834442, 63.4986],
                [-64.935822, 63.572769],
                [-64.94249, 63.63221],
                [-65.070847, 63.67194],
                [-65.106377, 63.727211],
                [-65.208054, 63.775829],
                [-65.159439, 63.781109],
                [-65.298607, 63.812489],
                [-64.986938, 63.601662],
                [-65.091949, 63.559719],
                [-65.099442, 63.526379],
                [-65.04277, 63.459721],
                [-65.117027, 63.426929],
                [-65.045273, 63.43166],
                [-65.123322, 63.400269],
                [-64.965012, 63.369438],
                [-64.909157, 63.280548],
                [-64.921387, 63.235821],
                [-65.055557, 63.314442],
                [-65.015289, 63.251942],
                [-65.148621, 63.28611],
                [-65.087784, 63.23082],
                [-64.968613, 63.217209],
                [-65.079453, 63.21249],
                [-65.05777, 63.174709],
                [-64.920273, 63.184158],
                [-64.857498, 63.16444],
                [-64.898354, 63.140549],
                [-64.78389, 63.122219],
                [-64.760834, 63.090549],
                [-64.864441, 63.088879],
                [-64.766953, 63.06221],
                [-64.815277, 63.039162],
                [-64.771393, 62.98333],
                [-64.846657, 62.967209],
                [-64.730827, 62.921379],
                [-64.696114, 62.952221],
                [-64.626938, 62.89999],
                [-64.881943, 62.867489],
                [-65.080566, 62.938599],
                [-65.11972, 62.992222],
                [-65.154167, 62.972759],
                [-65.125, 62.92083],
                [-65.236107, 62.957771],
                [-65.27417, 63.001389],
                [-65.279449, 62.968601],
                [-65.186661, 62.924999],
                [-65.202499, 62.887772],
                [-64.984734, 62.714161],
                [-64.928329, 62.63221],
                [-64.983887, 62.642769],
                [-64.981667, 62.619438],
                [-65.144997, 62.56583],
                [-65.22084, 62.578331],
                [-65.294998, 62.669441],
                [-65.266953, 62.694439],
                [-65.327499, 62.691662],
                [-65.328056, 62.6661],
                [-65.353882, 62.684429],
                [-65.318336, 62.74332],
                [-65.244164, 62.77916],
                [-65.361938, 62.774712],
                [-65.363617, 62.81694],
                [-65.331947, 62.82222],
                [-65.253616, 62.8325],
                [-65.339722, 62.83749],
                [-65.331947, 62.925549],
                [-65.402786, 62.821659],
                [-65.454178, 62.84193],
                [-65.609444, 62.820831],
                [-65.664436, 62.904991],
                [-65.704727, 62.89027],
                [-65.750557, 62.922489],
                [-65.688049, 62.979988],
                [-65.728882, 63.052769],
                [-65.743881, 62.965832],
                [-65.825562, 62.927769],
                [-65.79361, 62.87027],
                [-65.878883, 62.924709],
                [-65.964172, 62.931381],
                [-65.833328, 63.03138],
                [-65.950562, 62.975819],
                [-66.01445, 62.97887],
                [-66.074722, 63.021381],
                [-66.055267, 63.04805],
                [-66.137222, 63.03722],
                [-66.162781, 63.089989],
                [-66.275833, 63.133881],
                [-66.291382, 63.1161],
                [-66.212219, 63.081379],
                [-66.196663, 63.031658],
                [-66.091377, 62.983601],
                [-66.097778, 62.952492],
                [-66.159157, 62.936649],
                [-66.293877, 62.992771],
                [-66.392776, 62.994999],
                [-66.518341, 63.065269],
                [-66.493607, 63.087769],
                [-66.569733, 63.15749],
                [-66.545273, 63.195541],
                [-66.689713, 63.281109],
                [-66.638344, 63.340271],
                [-66.650833, 63.374989],
                [-66.74028, 63.283611],
                [-66.602493, 63.205269],
                [-66.618607, 63.162491],
                [-66.563316, 63.073608],
                [-66.68277, 63.096661],
                [-66.540283, 62.99416],
                [-66.68222, 63.026379],
                [-66.768066, 63.087769],
                [-66.758621, 63.178051],
                [-66.807503, 63.273319],
                [-66.849167, 63.245541],
                [-66.806664, 63.160549],
                [-66.897713, 63.171459],
                [-67.023903, 63.24305],
                [-66.947769, 63.284161],
                [-67.009453, 63.326939],
                [-66.977783, 63.399719],
                [-67.017227, 63.397221],
                [-67.040833, 63.335548],
                [-67.017227, 63.281109],
                [-67.092499, 63.276661],
                [-67.171661, 63.31638],
                [-67.146118, 63.27943],
                [-67.179718, 63.275551],
                [-67.346657, 63.374161],
                [-67.403061, 63.462769],
                [-67.414436, 63.410271],
                [-67.494164, 63.4361],
                [-67.55278, 63.53611],
                [-67.621658, 63.548882],
                [-67.683319, 63.619438],
                [-67.670273, 63.65332],
                [-67.778061, 63.63694],
                [-67.794998, 63.688881],
                [-67.834732, 63.689159],
                [-67.833328, 63.725552],
                [-67.914719, 63.759441],
                [-67.799728, 63.632488],
                [-67.819458, 63.585819],
                [-67.786942, 63.528599],
                [-67.671944, 63.412491],
                [-67.678329, 63.373051],
                [-67.71666, 63.36388],
                [-67.820557, 63.400269],
                [-67.858047, 63.4575],
                [-67.950844, 63.50666],
                [-68.083618, 63.547218],
                [-68.126938, 63.603321],
                [-68.137222, 63.576389],
                [-68.247498, 63.597488],
                [-68.216949, 63.627209],
                [-68.272781, 63.691101],
                [-68.312767, 63.690269],
                [-68.271118, 63.63805],
                [-68.30278, 63.633049],
                [-68.528877, 63.74638],
                [-68.556381, 63.727489],
                [-68.747223, 63.77499],
                [-68.723618, 63.72971],
                [-68.99472, 63.75555],
                [-68.813049, 63.635551],
                [-68.85083, 63.599998],
                [-68.543877, 63.446098],
                [-68.446663, 63.439991],
                [-68.495827, 63.421379],
                [-68.270554, 63.285],
                [-68.284729, 63.26194],
                [-68.20639, 63.227211],
                [-68.234734, 63.197769],
                [-68.139183, 63.148609],
                [-67.917503, 63.137211],
                [-67.976387, 63.064159],
                [-67.919724, 63.07333],
                [-67.894173, 63.119991],
                [-67.785828, 63.087212],
                [-67.610283, 63.094151],
                [-67.615013, 63.06361],
                [-67.791382, 63.020828],
                [-67.735283, 62.98138],
                [-67.76973, 62.958328],
                [-67.713898, 62.961109],
                [-67.736938, 62.995541],
                [-67.686943, 63.026661],
                [-67.553047, 63.048611],
                [-67.499733, 63.007771],
                [-67.651398, 62.967491],
                [-67.665833, 62.91888],
                [-67.462784, 62.985271],
                [-67.395279, 62.963879],
                [-67.419724, 62.931381],
                [-67.362778, 62.943878],
                [-67.246384, 62.88361],
                [-67.370003, 62.85416],
                [-67.200844, 62.873051],
                [-67.179443, 62.83749],
                [-67.241943, 62.81221],
                [-67.114723, 62.820549],
                [-67.08667, 62.79361],
                [-67.118607, 62.75639],
                [-67.04834, 62.771381],
                [-67.012222, 62.73444],
                [-67.058327, 62.691929],
                [-66.912781, 62.694988],
                [-67.001106, 62.653881],
                [-66.804718, 62.678879],
                [-66.726936, 62.668598],
                [-66.773064, 62.632488],
                [-66.670273, 62.641941],
                [-66.712784, 62.621658],
                [-66.615013, 62.612209],
                [-66.61055, 62.582771],
                [-66.487778, 62.511379],
                [-66.509453, 62.421661],
                [-66.351669, 62.444988],
                [-66.394173, 62.419159],
                [-66.330566, 62.379162],
                [-66.476936, 62.335819],
                [-66.318619, 62.264721],
                [-66.35556, 62.33194],
                [-66.243607, 62.31916],
                [-66.21666, 62.35194],
                [-66.15834, 62.305271],
                [-66.146957, 62.252781],
                [-66.257233, 62.25972],
                [-66.058609, 62.224159],
                [-66.015007, 62.251389],
                [-65.93306, 62.204159],
                [-66.055557, 62.141941],
                [-66.038063, 62.064159],
                [-66.13945, 62.093048],
                [-66.153877, 62.125549],
                [-66.200562, 62.113049],
                [-66.098892, 62.035831],
                [-66.138344, 62.0075],
                [-66.068069, 61.995541],
                [-66.035828, 61.95277],
                [-65.992218, 61.962769],
                [-65.955002, 61.89027],
                [-66.394997, 61.870831],
                [-66.632767, 61.91888],
                [-66.665283, 61.94138],
                [-66.618057, 61.966099],
                [-66.707779, 61.967491],
                [-66.755569, 62.011108],
                [-66.937767, 62.044441],
                [-66.920273, 62.018879],
                [-66.960564, 62.014439],
                [-67.019997, 62.051659],
                [-67.104721, 62.032211],
                [-67.232773, 62.0961],
                [-67.32695, 62.07333],
                [-67.328339, 62.1311],
                [-67.389999, 62.1511],
                [-67.731659, 62.15836],
                [-67.828339, 62.172489],
                [-67.806664, 62.198879],
                [-67.838898, 62.20916],
                [-67.866386, 62.188881],
                [-68.54805, 62.2486],
                [-68.722229, 62.302219],
                [-68.711937, 62.325829],
                [-68.819458, 62.338329],
                [-68.853882, 62.381378],
                [-69.022507, 62.37471],
                [-69.298889, 62.498329],
                [-69.282227, 62.622219],
                [-69.376099, 62.533051],
                [-69.411392, 62.607498],
                [-69.457497, 62.622761],
                [-69.442207, 62.547489],
                [-69.597229, 62.662491],
                [-69.475563, 62.673882],
                [-69.478333, 62.696659],
                [-69.428047, 62.705551],
                [-69.565552, 62.718048],
                [-69.394997, 62.763329],
                [-69.570847, 62.761379],
                [-69.621109, 62.787769],
                [-69.682503, 62.739719],
                [-69.679443, 62.774712],
                [-69.83168, 62.821941],
                [-69.762512, 62.726101],
                [-69.896957, 62.75555],
                [-69.939987, 62.794159],
                [-70.041672, 62.750271],
                [-70.254997, 62.75639],
                [-70.499443, 62.864441],
                [-70.885559, 62.907211],
                [-70.768066, 62.925831],
                [-70.847778, 62.947208],
                [-71.042221, 62.95583],
                [-70.940552, 62.970268],
                [-70.9664, 62.999161],
                [-70.940277, 63.026939],
                [-70.997223, 63.023048],
                [-71.013062, 62.98999],
                [-71.152222, 62.985271],
                [-71.136673, 63.028599],
                [-71.027222, 63.02499],
                [-70.939163, 63.095268],
                [-70.823898, 63.128601],
                [-70.92749, 63.115002],
                [-70.949158, 63.139431],
                [-70.90889, 63.17083],
                [-70.99028, 63.148048],
                [-70.98999, 63.102489],
                [-71.029716, 63.071941],
                [-71.138611, 63.07333],
                [-71.155563, 63.098598],
                [-71.198608, 63.019989],
                [-71.243881, 63.00111],
                [-71.275833, 63.012211],
                [-71.248611, 63.02943],
                [-71.268623, 63.040279],
                [-71.40834, 63.055271],
                [-71.398064, 63.122761],
                [-71.465012, 63.103321],
                [-71.713898, 63.179161],
                [-71.65889, 63.19249],
                [-71.756393, 63.237209],
                [-71.646393, 63.259159],
                [-71.769997, 63.25639],
                [-71.738892, 63.267769],
                [-71.775284, 63.288891],
                [-71.752792, 63.312771],
                [-71.821091, 63.34341],
                [-71.730003, 63.34499],
                [-71.775009, 63.412209],
                [-71.805832, 63.382771],
                [-71.924438, 63.38916],
                [-71.993057, 63.426659],
                [-72.009171, 63.391109],
                [-72.145554, 63.446098],
                [-71.634743, 63.41972],
                [-71.502274, 63.47747],
                [-71.304169, 63.535271],
                [-71.288887, 63.588329],
                [-71.234734, 63.608051],
                [-71.407501, 63.567768],
                [-71.362503, 63.613331],
                [-71.407784, 63.635551],
                [-71.407784, 63.610279],
                [-71.460564, 63.600269],
                [-71.446953, 63.58083],
                [-71.57695, 63.581669],
                [-71.562767, 63.622219],
                [-71.485817, 63.629429],
                [-71.581123, 63.645271],
                [-71.534157, 63.71027],
                [-71.638062, 63.7211],
                [-71.667221, 63.697208],
                [-71.608337, 63.65443],
                [-71.640289, 63.646099],
                [-71.72139, 63.691929],
                [-71.684158, 63.746658],
                [-71.759743, 63.691662],
                [-71.773064, 63.74416],
                [-71.904449, 63.80999],
                [-71.970551, 63.79583],
                [-72.004181, 63.752781],
                [-71.843887, 63.75555],
                [-71.92305, 63.645271],
                [-71.971939, 63.656101],
                [-71.929169, 63.679722],
                [-71.947487, 63.722488],
                [-72.039169, 63.66444],
                [-72.070557, 63.73999],
                [-72.107498, 63.715832],
                [-72.204727, 63.774441],
                [-72.213898, 63.677769],
                [-72.317497, 63.67416],
                [-72.315552, 63.733051],
                [-72.357773, 63.761379],
                [-72.252792, 63.760281],
                [-72.271942, 63.797779],
                [-72.222778, 63.861382],
                [-72.092499, 63.904709],
                [-72.218338, 63.895],
                [-72.246384, 63.950272],
                [-72.276398, 63.90749],
                [-72.246658, 63.883331],
                [-72.3125, 63.896389],
                [-72.405563, 63.826389],
                [-72.372772, 63.778599],
                [-72.526398, 63.787769],
                [-72.456947, 63.81583],
                [-72.596947, 63.86832],
                [-72.660553, 63.850269],
                [-72.641113, 63.90443],
                [-72.665558, 63.922218],
                [-72.604172, 63.949429],
                [-72.592773, 64.022217],
                [-72.676102, 64.020264],
                [-72.664719, 64.080551],
                [-72.705841, 64.009163],
                [-72.658623, 63.98777],
                [-72.733322, 63.962769],
                [-72.758347, 64.004173],
                [-72.940277, 64.058594],
                [-72.870827, 64.093872],
                [-72.897232, 64.156937],
                [-73.08139, 64.183594],
                [-73.05249, 64.224991],
                [-73.130829, 64.266937],
                [-73.107224, 64.290817],
                [-73.210564, 64.291931],
                [-73.236107, 64.330551],
                [-73.310547, 64.310806],
                [-73.279167, 64.258614],
                [-73.384171, 64.272491],
                [-73.391388, 64.338593],
                [-73.333328, 64.378311],
                [-73.260559, 64.363312],
                [-73.351936, 64.401657],
                [-73.41777, 64.371094],
                [-73.471939, 64.414993],
                [-73.316391, 64.45665],
                [-73.419724, 64.477203],
                [-73.278061, 64.519989],
                [-73.16777, 64.57666],
                [-73.165558, 64.607483],
                [-73.275284, 64.595261],
                [-73.309433, 64.6586],
                [-73.347504, 64.634987],
                [-73.302223, 64.583878],
                [-73.307503, 64.535812],
                [-73.463333, 64.502487],
                [-73.486816, 64.571342],
                [-73.402222, 64.549423],
                [-73.457779, 64.58638],
                [-73.430557, 64.603317],
                [-73.571121, 64.585274],
                [-73.656662, 64.663597],
                [-73.689438, 64.583603],
                [-73.644173, 64.566093],
                [-73.753891, 64.535263],
                [-73.929443, 64.602203],
                [-73.786392, 64.476646],
                [-73.815277, 64.459152],
                [-73.882767, 64.494141],
                [-73.86972, 64.468323],
                [-73.906387, 64.455551],
                [-73.961121, 64.477203],
                [-73.972778, 64.430267],
                [-73.940552, 64.404427],
                [-73.985001, 64.387497],
                [-73.993332, 64.3311],
                [-74.089996, 64.329163],
                [-74.175003, 64.36026],
                [-74.102219, 64.367477],
                [-74.107498, 64.403053],
                [-74.077499, 64.411377],
                [-74.170273, 64.461647],
                [-74.04361, 64.443039],
                [-74.127487, 64.534416],
                [-73.953613, 64.516098],
                [-74.164436, 64.570541],
                [-73.999443, 64.58194],
                [-74.083618, 64.631088],
                [-74.060547, 64.733322],
                [-74.120537, 64.741928],
                [-74.119453, 64.676933],
                [-74.22139, 64.671097],
                [-74.208893, 64.614151],
                [-74.24028, 64.580276],
                [-74.298607, 64.625526],
                [-74.264183, 64.654984],
                [-74.316391, 64.681931],
                [-74.338333, 64.627197],
                [-74.417503, 64.659988],
                [-74.347229, 64.591927],
                [-74.381943, 64.569992],
                [-74.431107, 64.596367],
                [-74.415558, 64.623032],
                [-74.535278, 64.622208],
                [-74.57251, 64.664703],
                [-74.704178, 64.735527],
                [-74.565002, 64.845833],
                [-74.478882, 64.835823],
                [-74.577789, 64.86998],
                [-74.545837, 64.892212],
                [-74.639999, 64.903587],
                [-74.660828, 64.893883],
                [-74.63945, 64.861366],
                [-74.732773, 64.854713],
                [-74.706673, 64.800537],
                [-74.726387, 64.770828],
                [-74.811111, 64.804153],
                [-74.837219, 64.778587],
                [-74.985283, 64.795822],
                [-74.470001, 64.55748],
                [-74.638901, 64.538307],
                [-74.537216, 64.510536],
                [-74.616653, 64.498032],
                [-74.501106, 64.455833],
                [-74.667221, 64.40387],
                [-74.680557, 64.372208],
                [-74.974716, 64.416092],
                [-75.174713, 64.492752],
                [-75.18721, 64.473602],
                [-75.144173, 64.453049],
                [-75.215561, 64.428864],
                [-75.331833, 64.490387],
                [-75.536667, 64.536652],
                [-75.568336, 64.569443],
                [-75.843063, 64.607758],
                [-75.781387, 64.571381],
                [-75.837219, 64.561371],
                [-75.818893, 64.530823],
                [-75.631668, 64.453598],
                [-75.73555, 64.470261],
                [-75.708344, 64.437759],
                [-75.922501, 64.481087],
                [-75.801392, 64.436096],
                [-75.821953, 64.425537],
                [-75.726936, 64.367203],
                [-75.944443, 64.376083],
                [-75.982773, 64.404427],
                [-76.071671, 64.359993],
                [-76.121384, 64.387497],
                [-76.169998, 64.373596],
                [-76.140556, 64.364433],
                [-76.166656, 64.349991],
                [-76.375, 64.356934],
                [-76.323898, 64.315811],
                [-76.188316, 64.306931],
                [-76.300552, 64.27887],
                [-76.438049, 64.31749],
                [-76.483887, 64.306091],
                [-76.378052, 64.279427],
                [-76.484734, 64.266663],
                [-76.541382, 64.304703],
                [-76.658051, 64.276657],
                [-76.705841, 64.300812],
                [-76.738892, 64.276382],
                [-76.721657, 64.238312],
                [-76.660278, 64.218323],
                [-76.670547, 64.184143],
                [-76.841667, 64.202209],
                [-76.827499, 64.226929],
                [-77.122772, 64.295532],
                [-77.37999, 64.245247],
                [-77.37027, 64.280548],
                [-77.451111, 64.294434],
                [-77.386673, 64.306091],
                [-77.400284, 64.321663],
                [-77.542503, 64.311371],
                [-77.489441, 64.348328],
                [-77.652222, 64.388046],
                [-77.643341, 64.334717],
                [-77.672501, 64.318047],
                [-77.785004, 64.369431],
                [-77.877777, 64.373871],
                [-77.905563, 64.400543],
                [-77.842773, 64.425537],
                [-78.008347, 64.424423],
                [-77.973053, 64.463608],
                [-78.032227, 64.485809],
                [-78.06778, 64.498032],
                [-78.068619, 64.540817],
                [-78.183319, 64.572487],
                [-78.128601, 64.589157],
                [-78.194992, 64.648613],
                [-78.159157, 64.697197],
                [-78.299156, 64.704712],
                [-78.184433, 64.723602],
                [-78.187767, 64.753883],
                [-78.073624, 64.813599],
                [-78.066391, 64.85582],
                [-78.117218, 64.876083],
                [-78.149727, 64.952209],
                [-77.963898, 65.047493],
                [-77.628052, 65.143051],
                [-77.488327, 65.139427],
                [-77.328613, 65.178864],
                [-77.315552, 65.199417],
                [-77.513062, 65.318878],
                [-77.461121, 65.37915],
                [-77.287514, 65.367752],
                [-77.421661, 65.46138],
                [-77.238052, 65.469437],
                [-77.267227, 65.457489],
                [-77.08667, 65.407211],
                [-76.920837, 65.429428],
                [-76.66362, 65.410263],
                [-75.785553, 65.224426],
                [-75.733322, 65.152206],
                [-75.524628, 65.103271],
                [-75.428047, 65.048317],
                [-75.415558, 64.977478],
                [-75.507233, 64.938309],
                [-75.667503, 64.940811],
                [-75.605827, 64.913307],
                [-75.587509, 64.856644],
                [-75.454727, 64.838882],
                [-75.530838, 64.775543],
                [-75.443329, 64.765266],
                [-75.396667, 64.717209],
                [-75.298187, 64.725769],
                [-75.373894, 64.833054],
                [-75.343887, 64.919708],
                [-75.469727, 64.869431],
                [-75.567497, 64.883614],
                [-75.482773, 64.896378],
                [-75.473892, 64.935806],
                [-75.384743, 64.981934],
                [-75.269173, 64.950546],
                [-75.29306, 64.989151],
                [-75.243057, 65.007767],
                [-75.186943, 65.101646],
                [-75.259171, 65.102203],
                [-75.279999, 65.035263],
                [-75.360001, 65.003883],
                [-75.597504, 65.184143],
                [-75.714722, 65.186371],
                [-75.761948, 65.237762],
                [-75.903343, 65.25943],
                [-75.950287, 65.318329],
                [-75.696114, 65.303864],
                [-75.625549, 65.345543],
                [-75.566391, 65.274986],
                [-75.186661, 65.251938],
                [-75.083618, 65.286102],
                [-75.070847, 65.330276],
                [-75.108047, 65.393051],
                [-75, 65.379707],
                [-74.773064, 65.386108],
                [-74.714447, 65.431664],
                [-74.66861, 65.424149],
                [-74.704178, 65.40332],
                [-74.659439, 65.388321],
                [-74.690552, 65.374977],
                [-74.669449, 65.349716],
                [-74.524437, 65.333328],
                [-74.357773, 65.398613],
                [-74.299988, 65.472214],
                [-74.15889, 65.485809],
                [-74.18277, 65.525269],
                [-74.105827, 65.534988],
                [-73.994453, 65.503052],
                [-73.845284, 65.532211],
                [-73.736938, 65.514709],
                [-73.740829, 65.490807],
                [-73.663887, 65.45694],
                [-73.479721, 65.450821],
                [-73.569458, 65.577209],
                [-73.631378, 65.607483],
                [-73.611656, 65.646103],
                [-73.70195, 65.685806],
                [-73.652496, 65.718597],
                [-73.709732, 65.762497],
                [-74.058037, 65.875526],
                [-74.444992, 66.096939],
                [-74.46611, 66.151932],
                [-74.342216, 66.225273],
                [-73.906952, 66.353043],
                [-73.444153, 66.551086],
                [-73.351936, 66.649986],
                [-73.108612, 66.723312],
                [-72.878326, 66.925537],
                [-72.824173, 67.029427],
                [-72.684998, 67.076103],
                [-72.368607, 67.112488],
                [-72.276108, 67.166931],
                [-72.258347, 67.248032],
                [-72.192757, 67.273041],
                [-72.374443, 67.325821],
                [-72.35611, 67.357758],
                [-72.439713, 67.476646],
                [-72.506119, 67.496933],
                [-72.422234, 67.529984],
                [-72.473892, 67.566383],
                [-72.485817, 67.623032],
                [-72.595001, 67.633881],
                [-72.676392, 67.693863],
                [-72.583328, 67.750267],
                [-72.612503, 67.790268],
                [-72.763062, 67.876373],
                [-72.843887, 67.850807],
                [-72.90834, 67.903053],
                [-72.881378, 67.934418],
                [-72.942207, 67.925262],
                [-72.896118, 68.01416],
                [-72.98111, 68.13916],
                [-72.992218, 68.234421],
                [-73.035553, 68.235527],
                [-73.05249, 68.199142],
                [-73.221657, 68.22998],
                [-73.189438, 68.254707],
                [-73.215561, 68.272774],
                [-73.338898, 68.27916],
                [-73.319733, 68.25],
                [-73.361107, 68.243042],
                [-73.496109, 68.275543],
                [-73.30777, 68.320267],
                [-73.289436, 68.345833],
                [-73.31723, 68.362488],
                [-73.21167, 68.376923],
                [-73.253067, 68.390823],
                [-73.357498, 68.371368],
                [-73.349442, 68.3311],
                [-73.471687, 68.330109],
                [-73.632217, 68.246643],
                [-73.684998, 68.271378],
                [-73.591377, 68.302467],
                [-73.835564, 68.337196],
                [-73.948608, 68.411926],
                [-73.867218, 68.493042],
                [-73.72583, 68.525818],
                [-73.815277, 68.570541],
                [-73.719727, 68.614433],
                [-73.823898, 68.617203],
                [-73.706947, 68.653587],
                [-73.795273, 68.664429],
                [-73.753891, 68.683594],
                [-74.178879, 68.724152],
                [-73.888062, 68.561653],
                [-73.90361, 68.527771],
                [-73.99028, 68.492752],
                [-74.031387, 68.508614],
                [-74.006958, 68.532494],
                [-74.190826, 68.508881],
                [-74.366096, 68.539146],
                [-74.514183, 68.599991],
                [-74.541672, 68.666092],
                [-74.726097, 68.73027],
                [-74.719452, 68.770538],
                [-74.57695, 68.793053],
                [-74.551392, 68.830551],
                [-74.678329, 68.842758],
                [-74.749161, 68.816383],
                [-74.63501, 68.79332],
                [-74.778343, 68.765823],
                [-74.984444, 68.809418],
                [-74.691673, 68.8647],
                [-74.679443, 68.927757],
                [-75.041847, 68.902992],
                [-74.840843, 68.934708],
                [-75, 68.93074],
                [-74.880547, 68.967758],
                [-75.04277, 68.928307],
                [-74.930283, 68.997208],
                [-74.642776, 69.011658],
                [-74.660828, 69.044434],
                [-74.785828, 69.076393],
                [-75.043327, 69.013321],
                [-75.071404, 68.921097],
                [-75.139717, 68.88472],
                [-75.478882, 69.019707],
                [-75.578613, 68.984154],
                [-75.495003, 68.930267],
                [-75.603882, 68.8797],
                [-76.436394, 68.6772],
                [-76.660828, 68.699417],
                [-76.688049, 68.746933],
                [-76.521667, 68.870247],
                [-76.606659, 68.883041],
                [-76.655563, 68.924698],
                [-76.625549, 69.018333],
                [-76.348892, 69.054977],
                [-75.996933, 69.003036],
                [-75.775009, 69.088593],
                [-75.605827, 69.085274],
                [-75.569458, 69.158333],
                [-75.591667, 69.221649],
                [-75.95639, 69.366089],
                [-76.202499, 69.413879],
                [-76.389183, 69.395538],
                [-76.424713, 69.454437],
                [-76.637787, 69.546654],
                [-76.620003, 69.58638],
                [-76.477783, 69.652206],
                [-76.210831, 69.6147],
                [-76.227783, 69.641373],
                [-76.187767, 69.665268],
                [-76.376389, 69.671371],
                [-76.407227, 69.700546],
                [-76.634171, 69.683594],
                [-76.522781, 69.652481],
                [-76.561661, 69.620247],
                [-76.642502, 69.620247],
                [-76.707497, 69.559418],
                [-76.876099, 69.583054],
                [-76.821121, 69.626083],
                [-76.967499, 69.591927],
                [-77.191673, 69.639709],
                [-77.155563, 69.680542],
                [-76.820282, 69.699417],
                [-76.781387, 69.748596],
                [-76.887222, 69.751099],
                [-76.909157, 69.791656],
                [-76.757507, 69.815536],
                [-76.797501, 69.836113],
                [-77.058327, 69.813309],
                [-77.00473, 69.856369],
                [-77.150284, 69.816093],
                [-77.308884, 69.830833],
                [-77.243057, 69.881363],
                [-76.974716, 69.935806],
                [-77.188316, 69.906097],
                [-77.630547, 69.823036],
                [-77.508347, 69.82666],
                [-77.444443, 69.789429],
                [-77.509743, 69.796944],
                [-77.551392, 69.747757],
                [-77.615829, 69.741653],
                [-77.680557, 69.819153],
                [-77.672501, 70.180542],
                [-77.795273, 70.25499],
                [-78.054169, 70.218048],
                [-78.195831, 70.25499],
                [-78.131943, 70.218323],
                [-78.170273, 70.19136],
                [-78.254997, 70.228043],
                [-78.333328, 70.198318],
                [-78.401672, 70.212486],
                [-78.430832, 70.262207],
                [-78.539993, 70.306374],
                [-78.396118, 70.328049],
                [-78.425827, 70.347763],
                [-78.557503, 70.351379],
                [-78.578888, 70.309708],
                [-78.662514, 70.35054],
                [-78.635559, 70.373032],
                [-78.704727, 70.374687],
                [-78.743881, 70.449142],
                [-78.820007, 70.438873],
                [-78.889183, 70.479156],
                [-78.90834, 70.447479],
                [-78.873047, 70.407494],
                [-79.070557, 70.469711],
                [-79.046112, 70.483047],
                [-79.100281, 70.498322],
                [-79.037514, 70.516663],
                [-79.08168, 70.529984],
                [-78.87999, 70.546371],
                [-78.995827, 70.556374],
                [-78.87999, 70.594711],
                [-78.718887, 70.54776],
                [-78.840561, 70.634987],
                [-78.976936, 70.635818],
                [-79.009453, 70.679703],
                [-79.158051, 70.622208],
                [-79.001953, 70.613312],
                [-79.171944, 70.513893],
                [-79.142227, 70.501938],
                [-79.174156, 70.42804],
                [-79.335007, 70.438873],
                [-79.291107, 70.44693],
                [-79.297783, 70.489967],
                [-79.412781, 70.491653],
                [-79.395279, 70.437187],
                [-79.500557, 70.452209],
                [-79.588898, 70.399429],
                [-79.443329, 70.349426],
                [-79.357773, 70.369713],
                [-79.209442, 70.313309],
                [-79.108887, 70.304977],
                [-79.038887, 70.340553],
                [-78.921661, 70.300812],
                [-78.763062, 70.183594],
                [-78.743332, 70.073036],
                [-78.688599, 70.054977],
                [-78.664169, 70.004173],
                [-78.676392, 69.945251],
                [-78.795837, 69.876648],
                [-79.377777, 69.886108],
                [-79.682503, 69.848877],
                [-79.901947, 69.976379],
                [-80.009453, 69.941093],
                [-80.053047, 69.997208],
                [-80.232498, 70.007767],
                [-80.326111, 69.98027],
                [-80.577499, 70.048866],
                [-80.983612, 70.056931],
                [-81.069733, 70.097488],
                [-81.429993, 70.093597],
                [-81.728333, 70.132202],
                [-81.761948, 70.117477],
                [-81.709732, 70.074432],
                [-81.539719, 70.053307],
                [-81.441101, 69.999153],
                [-81.338333, 70.0336],
                [-81.188599, 69.991089],
                [-81.126389, 69.927757],
                [-80.791382, 69.790543],
                [-80.775558, 69.752213],
                [-80.967773, 69.714432],
                [-80.952217, 69.732758],
                [-81.103058, 69.768333],
                [-81.166946, 69.821381],
                [-81.599731, 69.943039],
                [-81.577499, 69.968872],
                [-81.76973, 69.97554],
                [-82.232773, 70.146378],
                [-82.558327, 70.184982],
                [-82.992767, 70.303589],
                [-83.04805, 70.306931],
                [-82.813316, 70.20665],
                [-82.103058, 70.065262],
                [-81.906387, 69.970833],
                [-81.710831, 69.934143],
                [-81.768066, 69.901382],
                [-81.744453, 69.871643],
                [-81.850281, 69.884987],
                [-81.854172, 69.855553],
                [-81.960281, 69.844147],
                [-82.019997, 69.873871],
                [-82.144997, 69.823883],
                [-82.118607, 69.810532],
                [-82.143341, 69.781372],
                [-82.29805, 69.801376],
                [-82.262787, 69.823883],
                [-82.321953, 69.830276],
                [-82.206673, 69.828598],
                [-82.303047, 69.856644],
                [-82.463623, 69.841087],
                [-83.066673, 70.010818],
                [-83.265839, 70.013893],
                [-83.345284, 69.977203],
                [-83.715286, 69.947746],
                [-84.728607, 70.010269],
                [-84.823334, 70.02832],
                [-84.870827, 70.071663],
                [-84.796951, 70.107758],
                [-84.982773, 70.064148],
                [-85.666397, 70.104713],
                [-85.869453, 70.085541],
                [-85.851669, 70.038307],
                [-85.618057, 70.070831],
                [-85.236107, 69.989151],
                [-85.726936, 69.99054],
                [-86.254997, 70.105553],
                [-86.551941, 70.234993],
                [-86.548889, 70.296654],
                [-86.574722, 70.311371],
                [-86.55249, 70.346939],
                [-86.591667, 70.358322],
                [-86.512787, 70.416382],
                [-86.294998, 70.472763],
                [-86.390839, 70.522217],
                [-86.339447, 70.486366],
                [-86.400558, 70.463318],
                [-86.678047, 70.373032],
                [-86.647232, 70.319443],
                [-86.876099, 70.326103],
                [-86.88945, 70.357758],
                [-86.739441, 70.382477],
                [-86.930557, 70.391937],
                [-86.870537, 70.413879],
                [-86.978058, 70.420822],
                [-86.921387, 70.45166],
                [-86.953339, 70.467209],
                [-87.132767, 70.439148],
                [-87.144173, 70.404427],
                [-87.246948, 70.383041],
                [-87.010559, 70.371643],
                [-86.972778, 70.351929],
                [-86.985817, 70.281662],
                [-87.756958, 70.332489],
                [-87.785553, 70.30304],
                [-87.613327, 70.281937],
                [-87.866386, 70.238876],
                [-88.01445, 70.295532],
                [-88.250557, 70.321381],
                [-88.206673, 70.352478],
                [-87.999443, 70.303864],
                [-87.889999, 70.32193],
                [-88.402222, 70.448318],
                [-88.693047, 70.455261],
                [-88.88945, 70.52887],
                [-89.003891, 70.624977],
                [-89.00528, 70.656937],
                [-89.338058, 70.795258],
                [-89.448334, 70.902481],
                [-89.222778, 70.935532],
                [-89.195267, 70.968323],
                [-89.559433, 71.090271],
                [-89.228333, 71.072769],
                [-89.203056, 71.03804],
                [-89.134453, 71.026932],
                [-88.634171, 71.052467],
                [-88.38028, 71.011932],
                [-88.318619, 70.953873],
                [-88.025284, 70.930542],
                [-87.353607, 70.945251],
                [-87.308609, 70.995529],
                [-87.246948, 71.009163],
                [-87.002792, 70.994141],
                [-87.385834, 71.041931],
                [-87.712784, 71.126083],
                [-87.848892, 71.184982],
                [-87.829453, 71.261932],
                [-87.911667, 71.266937],
                [-88.084442, 71.20665],
                [-89.662514, 71.307747],
                [-89.899727, 71.351379],
                [-90, 71.455101],
                [-89.951401, 71.520477],
                [-90.040428, 71.597748],
                [-89.795837, 71.75972],
                [-89.914436, 71.791656],
                [-89.883621, 71.813309],
                [-89.954453, 71.820541],
                [-89.955276, 71.851089],
                [-90, 71.864891],
                [-90.119453, 71.926376],
                [-90, 72.057114],
                [-89.738892, 72.124977],
                [-89.664719, 72.113312],
                [-89.574722, 72.163597],
                [-89.780563, 72.157494],
                [-89.897232, 72.188583],
                [-89.960281, 72.251663],
                [-89.928596, 72.265823],
                [-89.956673, 72.321663],
                [-89.827499, 72.396942],
                [-89.907501, 72.432213],
                [-89.781387, 72.426933],
                [-89.897957, 72.43544],
                [-89.79361, 72.462769],
                [-89.753891, 72.605553],
                [-89.572777, 72.616928],
                [-89.545273, 72.632477],
                [-89.556953, 72.663879],
                [-89.470551, 72.666092],
                [-89.574722, 72.698868],
                [-89.547501, 72.76944],
                [-89.574722, 72.785263],
                [-89.30278, 72.75],
                [-89.245537, 72.785263],
                [-89.414436, 72.825272],
                [-89.295837, 72.919434],
                [-89.361389, 72.991653],
                [-89.205276, 73.013611],
                [-89.308884, 73.048317],
                [-89.235817, 73.120819],
                [-89.125816, 73.146103],
                [-89.180557, 73.156372],
                [-89.087784, 73.207207],
                [-88.888062, 73.212769],
                [-89.046661, 73.246643],
                [-88.95639, 73.293053],
                [-88.618881, 73.280273],
                [-88.856949, 73.336113],
                [-88.074722, 73.627762],
                [-87.456673, 73.760269],
                [-86.54834, 73.85054],
                [-85.239441, 73.821663],
                [-84.837784, 73.741653],
                [-84.956123, 73.665268],
                [-85.573334, 73.508041],
                [-85.915283, 73.366089],
                [-86.11972, 73.246094],
                [-86.32251, 73.046371],
                [-86.65361, 72.868874],
                [-86.740547, 72.727768],
                [-86.68222, 72.683594],
                [-86.705276, 72.665543],
                [-86.686943, 72.644707],
                [-86.397232, 72.534988],
                [-86.240829, 72.420258],
                [-86.435822, 72.270264],
                [-86.459167, 72.201393],
                [-86.429443, 72.168587],
                [-86.420547, 72.012772],
                [-86.330841, 71.98027],
                [-86.332497, 71.947197],
                [-86.11055, 71.783051],
                [-85.747498, 71.64444],
                [-85.500839, 71.511108],
                [-84.934723, 71.41832],
                [-84.915009, 71.388321],
                [-84.951111, 71.34082],
                [-84.872223, 71.333328],
                [-84.832779, 71.27887],
                [-85.254181, 71.287491],
                [-85.273331, 71.277481],
                [-85.246948, 71.262497],
                [-85.172501, 71.272491],
                [-85.234444, 71.247208],
                [-85.472229, 71.231369],
                [-85.393066, 71.222214],
                [-85.399986, 71.193863],
                [-85.463898, 71.211113],
                [-85.514717, 71.176086],
                [-85.729172, 71.2061],
                [-85.93277, 71.178864],
                [-86.248894, 71.058594],
                [-86.820007, 70.988586],
                [-86.601936, 70.971649],
                [-86.270279, 71.002777],
                [-85.802223, 71.135818],
                [-85.411667, 71.174423],
                [-85.16333, 71.136658],
                [-85.088898, 71.151932],
                [-85.116943, 71.179153],
                [-84.875267, 71.17276],
                [-84.84584, 71.147774],
                [-84.875816, 71.069992],
                [-84.96611, 71.100273],
                [-85.127434, 71.087479],
                [-84.960281, 71.07222],
                [-85.005569, 71.030548],
                [-84.929718, 71.00444],
                [-84.975281, 70.945251],
                [-84.958618, 70.919434],
                [-84.79361, 70.926651],
                [-84.748047, 70.988037],
                [-84.829727, 71.073318],
                [-84.801392, 71.183319],
                [-84.665558, 71.217209],
                [-84.79306, 71.278053],
                [-84.740829, 71.377197],
                [-84.762512, 71.406647],
                [-84.526108, 71.468872],
                [-84.561394, 71.549988],
                [-84.65834, 71.583878],
                [-84.606659, 71.649429],
                [-84.782784, 71.678864],
                [-84.867218, 71.668053],
                [-84.840561, 71.641937],
                [-84.876099, 71.627762],
                [-85.279716, 71.668587],
                [-85.579727, 71.784988],
                [-85.43277, 71.806374],
                [-85.559723, 71.900543],
                [-86.022781, 71.980553],
                [-86.050827, 72.011108],
                [-85.9664, 71.97998],
                [-85.98111, 72.028587],
                [-85.72139, 72.004707],
                [-85.776398, 72.023613],
                [-85.764717, 72.043053],
                [-85.509453, 72.068047],
                [-85.495003, 72.089157],
                [-85.540558, 72.124153],
                [-85.396667, 72.137497],
                [-85.509171, 72.178864],
                [-85.496109, 72.210274],
                [-85.538063, 72.23526],
                [-85.41777, 72.220261],
                [-85.5, 72.248322],
                [-85.47084, 72.262497],
                [-84.919998, 72.232758],
                [-84.273903, 72.022491],
                [-84.184723, 71.930542],
                [-84.158623, 71.977203],
                [-84.189438, 72.008614],
                [-84.164719, 72.021103],
                [-84.346657, 72.069717],
                [-84.413887, 72.119713],
                [-84.378326, 72.127762],
                [-84.613327, 72.163597],
                [-84.743057, 72.223602],
                [-84.669724, 72.235809],
                [-84.933319, 72.284416],
                [-84.816101, 72.352768],
                [-84.521118, 72.35054],
                [-84.442207, 72.383041],
                [-84.864166, 72.366928],
                [-84.870827, 72.400818],
                [-84.786667, 72.408867],
                [-84.776947, 72.458328],
                [-85.127213, 72.351929],
                [-85.115829, 72.383041],
                [-85.33902, 72.406418],
                [-85.535278, 72.469711],
                [-85.617218, 72.545532],
                [-85.475563, 72.575272],
                [-85.623894, 72.586929],
                [-85.705276, 72.637772],
                [-85.68222, 72.680817],
                [-85.708344, 72.743042],
                [-85.650284, 72.864151],
                [-85.688049, 72.8936],
                [-85.570557, 72.966927],
                [-85.283073, 72.964432],
                [-83.955276, 72.748322],
                [-85.091667, 72.983047],
                [-85.059998, 72.996643],
                [-85.129173, 73.031372],
                [-85.178329, 73.006653],
                [-85.535278, 73.021927],
                [-85.406952, 73.136383],
                [-85.277222, 73.068878],
                [-85.142227, 73.047493],
                [-85.229172, 73.123032],
                [-85.148354, 73.141663],
                [-84.829727, 73.085541],
                [-83.867767, 73.029709],
                [-83.634453, 72.982483],
                [-83.784157, 73.040543],
                [-84.584442, 73.115807],
                [-85.170547, 73.210823],
                [-85.188599, 73.228867],
                [-85.134171, 73.305542],
                [-85.017227, 73.348328],
                [-84.786942, 73.388046],
                [-84.654999, 73.305542],
                [-84.35527, 73.223038],
                [-84.413063, 73.272217],
                [-84.586937, 73.323036],
                [-84.598343, 73.362488],
                [-84.656113, 73.390549],
                [-84.171387, 73.475273],
                [-83.751923, 73.42749],
                [-83.689438, 73.323608],
                [-83.600563, 73.297493],
                [-83.594162, 73.325272],
                [-83.650833, 73.367477],
                [-83.625, 73.415268],
                [-83.652496, 73.445251],
                [-84.006958, 73.50972],
                [-83.201111, 73.660812],
                [-83.045837, 73.645828],
                [-82.840843, 73.732758],
                [-81.57251, 73.719711],
                [-81.45723, 73.691093],
                [-81.449997, 73.65332],
                [-81.263062, 73.564148],
                [-81.180283, 73.415268],
                [-81.204727, 73.266663],
                [-80.59639, 73.148041],
                [-80.549438, 73.08194],
                [-80.59584, 73.045822],
                [-80.584732, 73.013893],
                [-80.647507, 72.99054],
                [-80.638344, 72.92276],
                [-80.487778, 72.828598],
                [-80.319458, 72.799149],
                [-80.247498, 72.730553],
                [-80.541382, 72.62886],
                [-80.531952, 72.573608],
                [-80.94249, 72.455261],
                [-81.099731, 72.353592],
                [-81.176102, 72.338593],
                [-81.199722, 72.289146],
                [-81.37944, 72.241653],
                [-81.229721, 72.258614],
                [-80.880547, 72.438309],
                [-80.516403, 72.503883],
                [-80.492767, 72.453049],
                [-80.514183, 72.3797],
                [-80.794159, 72.284714],
                [-80.864166, 72.225273],
                [-80.838898, 72.199707],
                [-80.969162, 72.199997],
                [-80.56723, 72.072769],
                [-80.646118, 72.063309],
                [-80.771393, 72.104156],
                [-81.025558, 72.091927],
                [-80.996109, 72.066673],
                [-81.08667, 72.046654],
                [-80.847229, 72.056641],
                [-80.79277, 72.027771],
                [-80.827789, 72.001663],
                [-80.821404, 71.956383],
                [-80.975014, 71.895828],
                [-80.950287, 71.881088],
                [-80.756119, 71.934143],
                [-80.746948, 71.982483],
                [-80.38501, 72.048317],
                [-80.347229, 72.088318],
                [-80.486656, 72.189423],
                [-80.241096, 72.197746],
                [-80.306381, 72.255257],
                [-80.260834, 72.294983],
                [-80.155838, 72.273613],
                [-79.991669, 72.176651],
                [-79.674438, 72.126648],
                [-79.979973, 72.217178],
                [-80.164436, 72.327477],
                [-80.064713, 72.383614],
                [-79.874443, 72.426933],
                [-79.851936, 72.462769],
                [-79.870827, 72.483047],
                [-79.836937, 72.498596],
                [-79.692207, 72.466927],
                [-79.769173, 72.412201],
                [-79.595551, 72.334717],
                [-79.638901, 72.289146],
                [-79.768623, 72.245247],
                [-79.75, 72.215553],
                [-79.565826, 72.275269],
                [-79.450562, 72.194977],
                [-79.467216, 72.24942],
                [-79.54361, 72.285812],
                [-79.329727, 72.397217],
                [-79.027786, 72.277481],
                [-78.948608, 72.273041],
                [-78.985817, 72.234154],
                [-78.943604, 72.193039],
                [-79.005569, 72.143883],
                [-79.036392, 72.069443],
                [-79.233063, 71.976379],
                [-79.026672, 71.970543],
                [-78.551392, 71.861099],
                [-78.508347, 71.876373],
                [-78.592499, 71.903053],
                [-78.607498, 71.938583],
                [-78.923607, 72.014999],
                [-78.876099, 72.06192],
                [-78.854446, 72.173027],
                [-78.476097, 72.087196],
                [-78.381943, 71.92804],
                [-78.184998, 71.81749],
                [-77.904449, 71.768051],
                [-78.139999, 71.830551],
                [-78.308884, 71.921097],
                [-78.305267, 71.94693],
                [-78.156952, 71.968323],
                [-77.96611, 71.837196],
                [-77.797234, 71.792763],
                [-77.759453, 71.75],
                [-77.698036, 71.81192],
                [-77.907227, 71.848038],
                [-78.103607, 71.944702],
                [-78.116386, 71.976929],
                [-78.32251, 71.985809],
                [-78.375549, 72.085823],
                [-78.436943, 72.117477],
                [-78.854721, 72.214432],
                [-78.86055, 72.23526],
                [-78.749161, 72.280273],
                [-78.819733, 72.314697],
                [-78.79277, 72.337769],
                [-78.604172, 72.359421],
                [-78.515839, 72.330551],
                [-78.529167, 72.235527],
                [-78.40834, 72.166382],
                [-78.384171, 72.175537],
                [-78.411118, 72.21666],
                [-78.472778, 72.242477],
                [-78.445831, 72.281097],
                [-78.468338, 72.314987],
                [-78.40834, 72.325821],
                [-77.665283, 72.204712],
                [-77.644447, 72.186653],
                [-77.665009, 72.16304],
                [-77.620537, 72.136108],
                [-77.657227, 72.094147],
                [-77.589722, 72.127472],
                [-77.622223, 72.164429],
                [-77.588898, 72.181374],
                [-77.289436, 72.183319],
                [-76.997223, 72.128036],
                [-77.278061, 72.19693],
                [-77.549988, 72.198029],
                [-78.389183, 72.373032],
                [-78.559158, 72.438026],
                [-78.556664, 72.50444],
                [-78.430557, 72.586647],
                [-77.613892, 72.751663],
                [-77.063049, 72.753326],
                [-76.753067, 72.720543],
                [-76.65361, 72.670822],
                [-76.681671, 72.642487],
                [-76.166946, 72.58638],
                [-76.162514, 72.526093],
                [-76.107773, 72.473038],
                [-76.036942, 72.489433],
                [-76.064713, 72.549988],
                [-75.931953, 72.583603],
                [-75.231667, 72.500549],
                [-75, 72.29837],
                [-74.947487, 72.24971],
                [-75.238327, 72.118317],
                [-75.607498, 72.143333],
                [-76.015007, 72.086647],
                [-76.410553, 71.859154],
                [-76.374443, 71.842484],
                [-76.238602, 71.931091],
                [-76.089722, 71.978867],
                [-76.01973, 72.046097],
                [-75.796661, 72.103592],
                [-75.528343, 72.120819],
                [-75.233322, 72.084152],
                [-75.221123, 72.064697],
                [-75.281677, 72.038589],
                [-75.606377, 71.989433],
                [-75.692207, 71.833328],
                [-75.80249, 71.750549],
                [-76.096123, 71.697479],
                [-75.88028, 71.702477],
                [-75.794998, 71.725807],
                [-75.654999, 71.826103],
                [-75.570282, 71.91748],
                [-75.558327, 71.976646],
                [-75.197769, 72.023323],
                [-75.035828, 72.125809],
                [-74.244164, 72.073036],
                [-74.117767, 71.969994],
                [-74.212219, 71.912773],
                [-74.166107, 71.874687],
                [-74.230003, 71.822769],
                [-74.439987, 71.801933],
                [-74.539436, 71.828873],
                [-74.575012, 71.818878],
                [-74.551392, 71.794434],
                [-74.713623, 71.73526],
                [-75.389999, 71.681091],
                [-75.04361, 71.699707],
                [-74.933609, 71.663879],
                [-75.406387, 71.512207],
                [-74.672783, 71.692467],
                [-74.631943, 71.646378],
                [-74.713058, 71.583878],
                [-75, 71.535583],
                [-75.152222, 71.466087],
                [-75.033333, 71.513046],
                [-74.838333, 71.521927],
                [-74.816101, 71.493317],
                [-74.925552, 71.454163],
                [-74.736938, 71.429703],
                [-74.699432, 71.390823],
                [-75.087219, 71.200272],
                [-75.065002, 71.180817],
                [-74.874161, 71.247757],
                [-74.637512, 71.380539],
                [-74.638062, 71.426651],
                [-74.73555, 71.472488],
                [-74.736107, 71.530548],
                [-74.620003, 71.558029],
                [-74.522781, 71.662201],
                [-74.381943, 71.6772],
                [-74.288597, 71.727478],
                [-74.109161, 71.735809],
                [-74.128883, 71.671654],
                [-74.254463, 71.606644],
                [-74.243057, 71.569717],
                [-74.156387, 71.532211],
                [-74.2164, 71.612762],
                [-74.09639, 71.652771],
                [-73.977783, 71.75972],
                [-73.704453, 71.774986],
                [-73.686111, 71.718872],
                [-73.604172, 71.772217],
                [-73.620003, 71.716087],
                [-73.791382, 71.661102],
                [-73.797783, 71.626648],
                [-73.890839, 71.609421],
                [-73.888901, 71.582489],
                [-73.98555, 71.534149],
                [-74.012222, 71.483597],
                [-73.988892, 71.46138],
                [-74.319168, 71.409416],
                [-74.02861, 71.437759],
                [-74.066963, 71.395538],
                [-74.063889, 71.336929],
                [-74.179169, 71.306374],
                [-74.091667, 71.285538],
                [-74.235001, 71.198318],
                [-73.996658, 71.324158],
                [-74.006958, 71.367203],
                [-73.968613, 71.419434],
                [-73.897781, 71.445534],
                [-73.867767, 71.525818],
                [-73.746948, 71.585274],
                [-73.621933, 71.583328],
                [-73.565002, 71.551933],
                [-73.619453, 71.515823],
                [-73.649437, 71.44136],
                [-73.583328, 71.388321],
                [-73.63501, 71.359421],
                [-73.51889, 71.37915],
                [-73.503067, 71.424698],
                [-73.446953, 71.440262],
                [-73.38028, 71.385269],
                [-73.464188, 71.372208],
                [-73.681953, 71.246094],
                [-73.735817, 71.093323],
                [-73.898354, 71.05748],
                [-73.674438, 71.088318],
                [-73.673607, 71.16304],
                [-73.623611, 71.22554],
                [-73.433884, 71.308594],
                [-73.436111, 71.340553],
                [-73.349731, 71.345261],
                [-73.049438, 71.268333],
                [-73.271942, 71.220543],
                [-73.235001, 71.162201],
                [-73.294449, 71.092484],
                [-73.450844, 71.035263],
                [-73.377487, 70.980553],
                [-73.399437, 71.041931],
                [-73.267059, 71.081779],
                [-73.196953, 71.13472],
                [-73.16861, 71.170532],
                [-73.180283, 71.211647],
                [-72.995003, 71.25],
                [-72.951401, 71.316093],
                [-72.993332, 71.401932],
                [-72.759171, 71.426933],
                [-72.838058, 71.506378],
                [-72.649727, 71.536926],
                [-72.557503, 71.660263],
                [-71.554169, 71.503883],
                [-71.444443, 71.468597],
                [-71.428329, 71.428589],
                [-71.301941, 71.388603],
                [-71.123322, 71.271652],
                [-71.340561, 71.170532],
                [-71.464447, 71.062477],
                [-71.910828, 71.107758],
                [-72.099731, 71.063873],
                [-72.101936, 71.016098],
                [-72.179718, 70.962196],
                [-72.297234, 70.947197],
                [-72.334167, 70.878593],
                [-72.771667, 70.815811],
                [-72.30278, 70.867203],
                [-72.16362, 70.83638],
                [-72.333069, 70.750816],
                [-72.316391, 70.723312],
                [-72.381943, 70.6772],
                [-72.623322, 70.628036],
                [-72.542503, 70.609993],
                [-72.326111, 70.667213],
                [-72.185547, 70.766388],
                [-71.626099, 70.863312],
                [-71.326111, 70.885536],
                [-71.185547, 70.851929],
                [-71.289169, 70.908867],
                [-71.208344, 71.00499],
                [-70.836121, 71.114433],
                [-70.613892, 71.062187],
                [-70.51445, 70.921097],
                [-70.726669, 70.829987],
                [-70.746948, 70.745529],
                [-71.100563, 70.654984],
                [-71.175278, 70.583603],
                [-71.592773, 70.606934],
                [-71.583618, 70.546371],
                [-71.737213, 70.483322],
                [-71.748894, 70.444427],
                [-71.960564, 70.420822],
                [-71.727219, 70.41748],
                [-71.755569, 70.329163],
                [-71.836937, 70.289978],
                [-71.733612, 70.313873],
                [-71.674713, 70.369713],
                [-71.655273, 70.472488],
                [-71.525284, 70.524696],
                [-71.506958, 70.576393],
                [-71.16777, 70.531937],
                [-71.273903, 70.336113],
                [-71.32251, 70.312759],
                [-71.285278, 70.251389],
                [-71.535828, 70.019707],
                [-71.33168, 70.128593],
                [-71.29834, 70.19664],
                [-71.210564, 70.262207],
                [-71.231949, 70.294983],
                [-71.060822, 70.520264],
                [-70.98999, 70.524429],
                [-71.053047, 70.55304],
                [-70.997223, 70.625809],
                [-70.844162, 70.641937],
                [-70.611938, 70.723602],
                [-69.892776, 70.883331],
                [-69.771118, 70.857483],
                [-69.87944, 70.768051],
                [-70.084732, 70.683594],
                [-70.245003, 70.638321],
                [-70.454727, 70.627762],
                [-70.478882, 70.606644],
                [-70.397507, 70.524147],
                [-70.477783, 70.47554],
                [-70.308037, 70.498871],
                [-70.39389, 70.611923],
                [-70.049438, 70.632751],
                [-70.027222, 70.589706],
                [-69.978607, 70.65332],
                [-69.775833, 70.682213],
                [-69.808609, 70.70166],
                [-69.771118, 70.712769],
                [-69.665558, 70.682213],
                [-69.620003, 70.758041],
                [-69.451401, 70.791931],
                [-69.226936, 70.779984],
                [-69.111656, 70.721916],
                [-68.284157, 70.540543],
                [-68.289719, 70.5],
                [-68.451401, 70.409416],
                [-68.458618, 70.369431],
                [-68.575844, 70.396103],
                [-68.54805, 70.447746],
                [-68.582497, 70.464432],
                [-68.661667, 70.431091],
                [-68.651108, 70.345833],
                [-68.682503, 70.329437],
                [-69.414436, 70.253601],
                [-69.66806, 70.198593],
                [-69.829453, 70.15416],
                [-69.91777, 70.085541],
                [-70.185547, 70.028053],
                [-69.901108, 70.048317],
                [-69.815552, 69.984711],
                [-70.148621, 69.962196],
                [-70.291382, 69.889427],
                [-70.450562, 69.857208],
                [-70.384171, 69.837196],
                [-70.076683, 69.958038],
                [-69.777222, 69.963608],
                [-69.735283, 69.99054],
                [-69.804718, 70.038879],
                [-69.79277, 70.080833],
                [-69.675827, 70.139709],
                [-69.219452, 70.188309],
                [-69.039436, 70.16748],
                [-69.014717, 70.183037],
                [-69.12944, 70.214706],
                [-68.68277, 70.203598],
                [-68.642502, 70.149429],
                [-68.753067, 70.105263],
                [-68.734161, 70.073036],
                [-68.783073, 70.044434],
                [-69.106377, 69.974426],
                [-69.426941, 69.819717],
                [-69.683884, 69.839981],
                [-69.805832, 69.819992],
                [-69.998047, 69.669983],
                [-69.982773, 69.61554],
                [-69.741943, 69.803589],
                [-69.395844, 69.777771],
                [-69.076683, 69.923866],
                [-68.933884, 69.951103],
                [-68.639717, 69.939972],
                [-68.669449, 69.981659],
                [-68.541672, 70.021378],
                [-68.565277, 70.049423],
                [-68.338333, 70.064148],
                [-68.20723, 70.109421],
                [-68.229446, 70.135269],
                [-68.333618, 70.13916],
                [-68.291107, 70.163307],
                [-68.345551, 70.163307],
                [-68.345284, 70.188026],
                [-68.298889, 70.182213],
                [-68.318619, 70.218597],
                [-68.160004, 70.282761],
                [-68.151108, 70.313873],
                [-68.055832, 70.322769],
                [-67.909439, 70.275543],
                [-67.794724, 70.258881],
                [-67.575562, 70.158333],
                [-67.242493, 69.958328],
                [-67.117767, 69.726379],
                [-67.406952, 69.714706],
                [-67.761124, 69.778587],
                [-67.996109, 69.774429],
                [-68.208054, 69.715553],
                [-68.329453, 69.627762],
                [-68.699997, 69.645828],
                [-69.220001, 69.535812],
                [-69.688889, 69.569443],
                [-70.035278, 69.537201],
                [-69.885834, 69.507217],
                [-69.66333, 69.548317],
                [-69.299988, 69.506104],
                [-68.668327, 69.59082],
                [-67.982224, 69.457489],
                [-67.792221, 69.484154],
                [-67.258347, 69.467484],
                [-66.795547, 69.341087],
                [-66.694992, 69.281662],
                [-66.646667, 69.203598],
                [-66.758621, 69.128036],
                [-66.996948, 69.177757],
                [-67.164719, 69.179428],
                [-67.207779, 69.143051],
                [-67.343063, 69.183319],
                [-67.676392, 69.169434],
                [-68.184479, 69.308006],
                [-68.375549, 69.294708],
                [-68.984444, 69.356934],
                [-69.257782, 69.270264],
                [-68.941101, 69.332489],
                [-68.445267, 69.270264],
                [-68.159439, 69.266098],
                [-68.077499, 69.217484],
                [-68.129707, 69.197746],
                [-68.967773, 69.2211],
                [-68.502502, 69.198593],
                [-68.70723, 69.138321],
                [-69.075562, 69.10582],
                [-68.926941, 69.098328],
                [-68.958893, 69.003052],
                [-69.028877, 68.971367],
                [-68.930557, 68.992752],
                [-68.872498, 69.084991],
                [-68.506393, 69.161652],
                [-68.178329, 69.146652],
                [-67.705841, 69.016388],
                [-68.029449, 68.971367],
                [-68.314163, 69.010536],
                [-68.556107, 68.964157],
                [-68.303047, 68.985809],
                [-68.04834, 68.943863],
                [-68.051941, 68.910263],
                [-68.089722, 68.901382],
                [-67.977493, 68.855553],
                [-68.485817, 68.890266],
                [-67.861389, 68.805542],
                [-67.771393, 68.781372],
                [-67.872772, 68.771652],
                [-68.565552, 68.847214],
                [-68.611656, 68.835823],
                [-68.48999, 68.791931],
                [-68.605827, 68.781097],
                [-69.280838, 68.875809],
                [-69.394447, 68.857758],
                [-69.152222, 68.828049],
                [-69.383904, 68.816383],
                [-68.957497, 68.790543],
                [-68.959732, 68.756653],
                [-68.997772, 68.751663],
                [-68.97084, 68.737762],
                [-68.868057, 68.766098],
                [-68.529167, 68.746643],
                [-68.049438, 68.681664],
                [-68.168327, 68.668869],
                [-68.087219, 68.654984],
                [-68.094452, 68.627762],
                [-68.209732, 68.655823],
                [-68.359444, 68.627197],
                [-68.657784, 68.656372],
                [-68.902496, 68.603592],
                [-68.768066, 68.594147],
                [-68.628601, 68.635536],
                [-68.483322, 68.596939],
                [-68.739723, 68.563873],
                [-68.460007, 68.562187],
                [-68.337784, 68.593323],
                [-68.136673, 68.57222],
                [-68.126938, 68.530823],
                [-67.922783, 68.550812],
                [-67.939438, 68.522774],
                [-67.904167, 68.455833],
                [-67.958618, 68.396103],
                [-67.898621, 68.421654],
                [-67.864441, 68.549423],
                [-67.780838, 68.529984],
                [-67.669861, 68.561256],
                [-67.492218, 68.527206],
                [-67.628052, 68.496368],
                [-67.612503, 68.379433],
                [-67.551102, 68.414429],
                [-67.553329, 68.488876],
                [-67.378883, 68.469994],
                [-67.316391, 68.496094],
                [-67.272232, 68.45166],
                [-67.232498, 68.480553],
                [-67.191673, 68.426376],
                [-67.324448, 68.407761],
                [-67.105003, 68.414146],
                [-67.112778, 68.478867],
                [-66.974716, 68.436096],
                [-66.803047, 68.467209],
                [-66.66362, 68.43248],
                [-66.801102, 68.424149],
                [-66.808609, 68.399986],
                [-66.904999, 68.416382],
                [-66.936394, 68.37442],
                [-67.011124, 68.353592],
                [-67.40834, 68.393333],
                [-67.781387, 68.337196],
                [-67.865829, 68.299713],
                [-67.86972, 68.259987],
                [-67.752502, 68.318878],
                [-67.536118, 68.34166],
                [-67.586937, 68.301651],
                [-67.576683, 68.2686],
                [-67.695267, 68.242477],
                [-67.570847, 68.253052],
                [-67.533073, 68.271378],
                [-67.540283, 68.299713],
                [-67.39473, 68.354156],
                [-67.325562, 68.344711],
                [-67.323334, 68.319443],
                [-67.243881, 68.358322],
                [-67.016113, 68.318604],
                [-67.235817, 68.294434],
                [-67.327789, 68.243591],
                [-67.327789, 68.181091],
                [-67.387787, 68.146378],
                [-67.598892, 68.164993],
                [-67.396957, 68.124687],
                [-67.304169, 68.1586],
                [-67.274719, 68.244141],
                [-67.011398, 68.294983],
                [-66.930573, 68.262482],
                [-66.765839, 68.238586],
                [-66.842216, 68.163307],
                [-66.823624, 68.126373],
                [-66.965561, 68.063873],
                [-66.946663, 68.013611],
                [-66.836121, 68.095543],
                [-66.694443, 68.143051],
                [-66.670837, 68.12886],
                [-66.678047, 68.04332],
                [-66.735001, 67.982208],
                [-66.647232, 68.015549],
                [-66.594727, 68.143051],
                [-66.309433, 68.127472],
                [-66.366386, 68.083328],
                [-66.460564, 68.070541],
                [-66.24028, 68.073318],
                [-66.184433, 68.013321],
                [-66.320847, 67.978867],
                [-66.405838, 67.898041],
                [-66.398903, 67.868591],
                [-66.746948, 67.875259],
                [-66.406952, 67.829163],
                [-66.386398, 67.772217],
                [-66.338608, 67.900269],
                [-66.276672, 67.954437],
                [-65.947769, 68.040817],
                [-65.960564, 68.14444],
                [-65.911942, 68.158867],
                [-65.857224, 68.106087],
                [-65.902786, 68.080551],
                [-65.853607, 68.073318],
                [-66.032227, 67.952477],
                [-65.941673, 67.880257],
                [-66.009743, 67.803307],
                [-66.008621, 67.625526],
                [-65.936943, 67.765549],
                [-65.953339, 67.821381],
                [-65.804169, 67.863602],
                [-65.763634, 67.909988],
                [-65.81778, 67.968048],
                [-65.514267, 67.992371],
                [-65.443604, 67.992203],
                [-65.464447, 67.920822],
                [-65.613892, 67.780823],
                [-65.426392, 67.696373],
                [-65.356659, 67.594711],
                [-65.318336, 67.601089],
                [-65.369164, 67.702766],
                [-65.554443, 67.78804],
                [-65.486389, 67.820831],
                [-65.406952, 67.922493],
                [-65.201401, 67.954437],
                [-65.141388, 67.990257],
                [-65.169159, 68.027206],
                [-65.110001, 68.015823],
                [-65.001106, 68.055542],
                [-64.713623, 67.981659],
                [-64.847229, 67.934982],
                [-65.066963, 67.931091],
                [-64.94249, 67.912491],
                [-65.048607, 67.844147],
                [-65.113052, 67.857758],
                [-65.139183, 67.790543],
                [-65.04805, 67.825821],
                [-65.008347, 67.785263],
                [-65.15834, 67.729713],
                [-65.200287, 67.643883],
                [-65.162781, 67.638893],
                [-65.129707, 67.71582],
                [-64.919724, 67.790817],
                [-64.810547, 67.777206],
                [-64.816101, 67.710541],
                [-64.862778, 67.69165],
                [-64.820557, 67.688309],
                [-64.768623, 67.709427],
                [-64.75, 67.822769],
                [-64.568069, 67.819992],
                [-64.459167, 67.789703],
                [-64.458618, 67.75972],
                [-64.363892, 67.75444],
                [-64.401398, 67.707764],
                [-64.578339, 67.69693],
                [-64.639183, 67.660538],
                [-64.379913, 67.698097],
                [-64.305557, 67.733597],
                [-64.204178, 67.664703],
                [-64.219452, 67.631653],
                [-64.285828, 67.618874],
                [-64.089996, 67.620819],
                [-64.068069, 67.599716],
                [-64.10556, 67.549423],
                [-64.039719, 67.5336],
                [-64.238602, 67.467484],
                [-64.440552, 67.474991],
                [-63.993328, 67.449142],
                [-63.939991, 67.364433],
                [-64.00029, 67.32666],
                [-63.908051, 67.310532],
                [-63.923889, 67.29332],
                [-64.797501, 67.356644],
                [-64.231949, 67.29332],
                [-64.35611, 67.250267],
                [-64.550278, 67.256104],
                [-64.779167, 67.218872],
                [-64.816101, 67.20694],
                [-64.783333, 67.190262],
                [-64.581947, 67.234154],
                [-63.96278, 67.272491],
                [-64.021393, 67.208878],
                [-64.162216, 67.214432],
                [-64.611938, 67.132477],
                [-64.689987, 67.083603],
                [-64.696953, 67.003326],
                [-64.628326, 67.012207],
                [-64.604172, 67.088318],
                [-64.506393, 67.129433],
                [-63.971378, 67.182213],
                [-63.889999, 67.234993],
                [-63.788342, 67.2397],
                [-63.751671, 67.212486],
                [-63.561939, 67.236923],
                [-63.45055, 67.222488],
                [-63.453892, 67.169434],
                [-63.529442, 67.104431],
                [-63.74445, 67.041092],
                [-63.806389, 66.995819],
                [-63.772499, 66.958878],
                [-63.750561, 67.017487],
                [-63.535278, 67.070831],
                [-63.402779, 67.14444],
                [-63.420841, 67.200272],
                [-63.3325, 67.281937],
                [-63.215279, 67.300262],
                [-63.293621, 67.246368],
                [-63.255001, 67.246643],
                [-63.172501, 67.27832],
                [-63.205002, 67.319153],
                [-63.110279, 67.329987],
                [-62.99778, 67.281372],
                [-62.970829, 67.221367],
                [-63.04472, 67.167213],
                [-63.284451, 67.105263],
                [-63.22028, 67.016937],
                [-63.240841, 66.961647],
                [-63.526138, 66.909271],
                [-63.565552, 66.892487],
                [-63.571949, 66.837486],
                [-63.821671, 66.815811],
                [-63.53944, 66.814697],
                [-63.478882, 66.835823],
                [-63.496948, 66.880814],
                [-63.441669, 66.908333],
                [-63.407219, 66.814423],
                [-63.45306, 66.711647],
                [-63.42028, 66.698868],
                [-63.320278, 66.814148],
                [-63.348339, 66.890823],
                [-62.846951, 66.961929],
                [-62.82056, 66.813599],
                [-62.899731, 66.636658],
                [-62.83527, 66.666382],
                [-62.831669, 66.713882],
                [-62.734169, 66.790817],
                [-62.77417, 66.840553],
                [-62.743061, 66.941933],
                [-62.56889, 66.944138],
                [-62.509731, 66.901932],
                [-62.500561, 66.842484],
                [-62.413059, 66.818604],
                [-62.313332, 66.726929],
                [-62.292782, 66.761658],
                [-62.424721, 66.847488],
                [-62.427219, 66.921097],
                [-62.26112, 66.948029],
                [-62.294449, 67.021378],
                [-62.262501, 67.045258],
                [-62.05444, 67.049149],
                [-62.005562, 67.035263],
                [-62.10223, 66.91304],
                [-62.01889, 66.901657],
                [-61.93861, 66.969437],
                [-61.838051, 66.968597],
                [-61.851391, 66.933037],
                [-61.749729, 66.948029],
                [-61.730831, 66.923866],
                [-61.77084, 66.897491],
                [-61.965549, 66.897491],
                [-62.00806, 66.867752],
                [-61.612782, 66.870819],
                [-61.652779, 66.829987],
                [-61.726662, 66.823608],
                [-61.4575, 66.776093],
                [-61.46278, 66.751663],
                [-61.28944, 66.669983],
                [-61.262501, 66.629433],
                [-61.28278, 66.616928]
              ]
            ],
            [
              [
                [-67.544159, 82.962196],
                [-67.196663, 82.936096],
                [-67.041107, 82.959717],
                [-66.939987, 82.950546],
                [-66.978058, 82.933037],
                [-66.95723, 82.926933],
                [-66.779449, 82.951393],
                [-66.330002, 82.933868],
                [-66.391388, 82.916092],
                [-66.34111, 82.905823],
                [-66.369164, 82.888321],
                [-66.876663, 82.807213],
                [-68.356659, 82.688026],
                [-68.642502, 82.628593],
                [-67.47084, 82.652206],
                [-66.900284, 82.719437],
                [-66.662514, 82.709717],
                [-66.636673, 82.720261],
                [-66.67749, 82.733322],
                [-66.638344, 82.748871],
                [-65.767776, 82.843048],
                [-65.467773, 82.833328],
                [-65.511124, 82.786652],
                [-65.164436, 82.763046],
                [-65.353058, 82.797211],
                [-65.102783, 82.848038],
                [-65.307503, 82.86554],
                [-64.835564, 82.906937],
                [-64.664169, 82.890266],
                [-64.923607, 82.8647],
                [-64.723053, 82.856369],
                [-64.737778, 82.82222],
                [-64.445267, 82.761932],
                [-64.103058, 82.831673],
                [-63.389172, 82.804977],
                [-63.382221, 82.767761],
                [-63.510281, 82.732483],
                [-63.850281, 82.71582],
                [-63.651661, 82.714996],
                [-63.540001, 82.694427],
                [-63.55722, 82.673866],
                [-63.254452, 82.650269],
                [-63.226101, 82.640266],
                [-63.25584, 82.627197],
                [-63.376389, 82.61998],
                [-63.3475, 82.60498],
                [-62.926109, 82.576103],
                [-63.053329, 82.54332],
                [-63.076672, 82.486099],
                [-63.116661, 82.474991],
                [-63.089439, 82.466377],
                [-63.369999, 82.438873],
                [-63.015839, 82.459717],
                [-62.902222, 82.510269],
                [-62.774441, 82.483871],
                [-62.727779, 82.488037],
                [-62.756672, 82.513893],
                [-62.506672, 82.526657],
                [-62.171669, 82.525543],
                [-62.322781, 82.511108],
                [-62.352779, 82.481087],
                [-62.228611, 82.506943],
                [-61.608101, 82.480377],
                [-61.13139, 82.377472],
                [-61.078609, 82.301086],
                [-61.193329, 82.223602],
                [-61.463619, 82.172493],
                [-62.023891, 82.135818],
                [-61.869999, 82.106644],
                [-62.254169, 82.019989],
                [-62.499439, 82.016937],
                [-62.507229, 81.983597],
                [-63.611671, 81.855263],
                [-63.76112, 81.811653],
                [-64.053047, 81.789978],
                [-64.325287, 81.824707],
                [-64.118057, 81.764427],
                [-64.372772, 81.720833],
                [-65.355827, 81.761108],
                [-65.349991, 81.733597],
                [-65.631378, 81.702477],
                [-66.038597, 81.692467],
                [-65.352493, 81.691933],
                [-65.789436, 81.632202],
                [-66.024719, 81.653053],
                [-66.172501, 81.618042],
                [-67.559723, 81.599152],
                [-68.156662, 81.561096],
                [-69.291382, 81.718872],
                [-68.370827, 81.553589],
                [-68.856949, 81.54776],
                [-68.579453, 81.514427],
                [-67.107773, 81.564987],
                [-66.608612, 81.512772],
                [-68.618057, 81.290543],
                [-69.02861, 81.258614],
                [-69.426941, 81.269989],
                [-69.463058, 81.253326],
                [-69.31221, 81.24054],
                [-69.541672, 81.212486],
                [-70.20639, 81.179703],
                [-69.638062, 81.177467],
                [-69.99527, 81.099426],
                [-69.430557, 81.187187],
                [-65.724716, 81.493874],
                [-64.566391, 81.545532],
                [-64.450562, 81.496933],
                [-64.436394, 81.479431],
                [-64.508621, 81.441933],
                [-64.735283, 81.374153],
                [-65.441383, 81.256378],
                [-66.010277, 81.220261],
                [-66.685822, 81.035812],
                [-67.208618, 80.941933],
                [-67.591377, 80.933037],
                [-67.530838, 80.897491],
                [-67.634453, 80.860809],
                [-68.247223, 80.755547],
                [-68.958618, 80.617477],
                [-68.893066, 80.590553],
                [-69.146667, 80.529709],
                [-69.317497, 80.412491],
                [-69.59639, 80.361099],
                [-70.284439, 80.351089],
                [-70.310822, 80.363037],
                [-70.220001, 80.416931],
                [-70.314163, 80.464432],
                [-70.8125, 80.562759],
                [-70.371109, 80.409149],
                [-70.458893, 80.381363],
                [-70.448334, 80.340271],
                [-69.960564, 80.256378],
                [-70.178596, 80.189148],
                [-70.74472, 80.205833],
                [-71.694443, 80.110809],
                [-71.953339, 80.180542],
                [-72.400284, 80.218597],
                [-71.895554, 80.114151],
                [-72.391678, 80.085541],
                [-72.32251, 80.077209],
                [-72.340561, 80.059143],
                [-71.489166, 80.068878],
                [-70.762512, 80.133331],
                [-70.507782, 80.099716],
                [-70.495003, 80.050812],
                [-70.646667, 80.031937],
                [-70.719452, 79.986366],
                [-71.416397, 79.930267],
                [-71.460564, 79.901382],
                [-71.415833, 79.886658],
                [-71.109734, 79.915268],
                [-70.910278, 79.885818],
                [-71.005836, 79.819717],
                [-71.136124, 79.784988],
                [-72.267227, 79.659149],
                [-72.93277, 79.706383],
                [-72.948608, 79.743591],
                [-73.062767, 79.799423],
                [-72.902496, 79.815262],
                [-72.991814, 79.819847],
                [-73.853333, 79.829163],
                [-73.746948, 79.854156],
                [-74.117493, 79.888893],
                [-74.84639, 79.847214],
                [-74.683884, 79.789978],
                [-74.309433, 79.803307],
                [-73.348892, 79.766098],
                [-73.384743, 79.748871],
                [-73.366653, 79.718048],
                [-73.174438, 79.651657],
                [-73.159729, 79.612198],
                [-73.184433, 79.591927],
                [-73.125816, 79.558319],
                [-73.353882, 79.505829],
                [-73.758057, 79.500267],
                [-73.965012, 79.554703],
                [-74.001106, 79.545822],
                [-73.949997, 79.493874],
                [-73.983322, 79.455551],
                [-74.198334, 79.436096],
                [-74.673607, 79.444138],
                [-74.964447, 79.513046],
                [-75.059433, 79.483871],
                [-74.883057, 79.408333],
                [-75.058327, 79.373871],
                [-75.531387, 79.392487],
                [-75.658623, 79.429153],
                [-75.70639, 79.406647],
                [-75.803329, 79.419434],
                [-75.817497, 79.448318],
                [-75.957497, 79.426086],
                [-76.261124, 79.497757],
                [-76.905838, 79.50972],
                [-77.142502, 79.547493],
                [-77.191383, 79.511108],
                [-77.134453, 79.490257],
                [-76.179169, 79.459717],
                [-76.138901, 79.441093],
                [-76.208054, 79.429703],
                [-76.157501, 79.391663],
                [-75.889999, 79.348038],
                [-76.86972, 79.349426],
                [-77.076401, 79.398331],
                [-77.139999, 79.464706],
                [-77.397507, 79.447197],
                [-77.163887, 79.324707],
                [-77.922501, 79.366379],
                [-78.051392, 79.354713],
                [-78.021393, 79.339981],
                [-77.322243, 79.267487],
                [-77.488052, 79.24498],
                [-76.754463, 79.279984],
                [-76.136124, 79.2686],
                [-75.938889, 79.23027],
                [-74.496948, 79.224991],
                [-74.82695, 79.174149],
                [-74.617493, 79.151382],
                [-74.664436, 79.134163],
                [-74.436661, 79.057747],
                [-74.725014, 79.022774],
                [-75.655563, 79.068329],
                [-75.885277, 79.097488],
                [-75.857773, 79.152206],
                [-76.098053, 79.199142],
                [-78.15889, 79.189972],
                [-78.228607, 79.160538],
                [-77.912216, 79.179428],
                [-76.706123, 79.153053],
                [-76.081947, 79.099716],
                [-76.170547, 79.075821],
                [-77.073334, 79.070831],
                [-77.495003, 79.017761],
                [-77.799988, 79.066673],
                [-78.351097, 79.08638],
                [-78.891388, 79.063309],
                [-77.829453, 79.048027],
                [-77.708344, 79.013046],
                [-77.954178, 78.958878],
                [-78.289993, 78.799149],
                [-78.248047, 78.770264],
                [-77.711937, 78.966087],
                [-76.754181, 79.027771],
                [-75.720284, 78.965553],
                [-75.825562, 78.926086],
                [-76.315552, 78.896103],
                [-76.446114, 78.863876],
                [-76.440277, 78.839432],
                [-76.204727, 78.881088],
                [-75.316101, 78.892212],
                [-74.911118, 78.850807],
                [-74.876938, 78.817757],
                [-74.760559, 78.823608],
                [-74.907227, 78.784149],
                [-74.761673, 78.773613],
                [-74.83168, 78.743317],
                [-74.719727, 78.707489],
                [-74.843338, 78.693039],
                [-74.869453, 78.668587],
                [-74.832779, 78.651932],
                [-74.871109, 78.638321],
                [-74.633331, 78.587769],
                [-74.789993, 78.59137],
                [-75.04805, 78.528053],
                [-75.479446, 78.509987],
                [-75.888344, 78.506104],
                [-76.43721, 78.548599],
                [-76.684433, 78.522217],
                [-76.261948, 78.513611],
                [-76.057503, 78.461929],
                [-75.443604, 78.430542],
                [-75.089996, 78.368874],
                [-75.031387, 78.331383],
                [-75.358612, 78.301651],
                [-75.479172, 78.222214],
                [-75.613617, 78.198029],
                [-76.157227, 78.24054],
                [-76.85527, 78.227478],
                [-76.90834, 78.195534],
                [-76.664436, 78.166092],
                [-75.58139, 78.115807],
                [-75.599167, 78.083328],
                [-75.922783, 77.95665],
                [-76.214722, 78.015266],
                [-76.49028, 77.967209],
                [-76.931107, 77.901382],
                [-77.210831, 77.949142],
                [-77.900558, 77.940811],
                [-78.260834, 77.995247],
                [-78.271118, 77.97554],
                [-78.183319, 77.963318],
                [-78.419724, 77.89888],
                [-77.958618, 77.8022],
                [-77.940552, 77.759987],
                [-78.051666, 77.729713],
                [-77.98111, 77.701393],
                [-78.08139, 77.673309],
                [-77.920273, 77.669983],
                [-77.723053, 77.599152],
                [-77.98056, 77.554703],
                [-77.948608, 77.501938],
                [-78.278343, 77.429153],
                [-78.23111, 77.397774],
                [-78.256668, 77.381927],
                [-78.777222, 77.307213],
                [-78.839722, 77.310257],
                [-78.726936, 77.367477],
                [-78.76445, 77.380814],
                [-79.20723, 77.288307],
                [-79.712219, 77.318329],
                [-80.042503, 77.272774],
                [-80.753342, 77.330551],
                [-81.277496, 77.424423],
                [-81.370537, 77.47554],
                [-81.573624, 77.512772],
                [-81.575012, 77.545258],
                [-81.672783, 77.570541],
                [-81.620003, 77.586113],
                [-81.839722, 77.625809],
                [-81.847504, 77.665817],
                [-81.928329, 77.685806],
                [-81.949432, 77.644989],
                [-81.911392, 77.609421],
                [-81.670547, 77.531662],
                [-81.670837, 77.496094],
                [-81.748047, 77.448029],
                [-81.728882, 77.429977],
                [-81.484734, 77.372208],
                [-81.203888, 77.370529],
                [-81.165833, 77.337196],
                [-81.828339, 77.285812],
                [-82.091949, 77.316383],
                [-82.194443, 77.299713],
                [-81.904449, 77.248596],
                [-81.882492, 77.214157],
                [-81.902222, 77.187187],
                [-81.786942, 77.157494],
                [-81.076401, 77.283051],
                [-80.116096, 77.201393],
                [-80.401672, 77.086647],
                [-80.373047, 77.071381],
                [-80.013344, 77.190536],
                [-79.72583, 77.239967],
                [-79.254997, 77.218597],
                [-79.041946, 77.161102],
                [-79.063049, 77.131088],
                [-79.004997, 77.096939],
                [-79.365547, 76.963318],
                [-79.386948, 76.927467],
                [-78.886124, 76.926933],
                [-78.964722, 76.84166],
                [-78.721939, 76.821381],
                [-78.698036, 76.839432],
                [-78.720001, 76.878593],
                [-78.56723, 76.887207],
                [-78.553879, 76.938583],
                [-78.398354, 76.963043],
                [-78.41362, 76.984993],
                [-78.384453, 76.99971],
                [-78.070282, 77.014427],
                [-77.886948, 76.947746],
                [-77.90834, 76.913597],
                [-77.835007, 76.886932],
                [-77.881104, 76.857758],
                [-77.715012, 76.821114],
                [-77.789436, 76.781662],
                [-77.763901, 76.729156],
                [-77.813049, 76.681931],
                [-77.769447, 76.658333],
                [-78.089722, 76.609421],
                [-78.186111, 76.559708],
                [-78.138901, 76.525818],
                [-78.346947, 76.500816],
                [-78.377487, 76.458038],
                [-78.614723, 76.489967],
                [-78.588333, 76.513046],
                [-78.613327, 76.54776],
                [-78.565552, 76.563873],
                [-78.790558, 76.571663],
                [-78.835251, 76.542572],
                [-78.994164, 76.424423],
                [-79.189713, 76.405823],
                [-79.178329, 76.380257],
                [-79.261124, 76.352203],
                [-79.272781, 76.304153],
                [-79.573624, 76.311653],
                [-80.087219, 76.223602],
                [-80.293327, 76.23526],
                [-80.672501, 76.158333],
                [-80.865829, 76.169144],
                [-81.075844, 76.12915],
                [-81.07695, 76.158043],
                [-80.953613, 76.191093],
                [-81.095551, 76.212196],
                [-80.783623, 76.374977],
                [-80.771667, 76.419144],
                [-80.991669, 76.483047],
                [-81.218613, 76.498596],
                [-81.178047, 76.530823],
                [-81.208054, 76.553589],
                [-81.492493, 76.469437],
                [-82.039719, 76.50943],
                [-82.083618, 76.52388],
                [-81.981949, 76.584717],
                [-82.056664, 76.609154],
                [-81.785828, 76.685532],
                [-82.081123, 76.631088],
                [-82.292503, 76.635536],
                [-82.44249, 76.684982],
                [-82.580292, 76.776382],
                [-82.749733, 76.818604],
                [-82.556107, 76.723038],
                [-82.5625, 76.688873],
                [-82.460564, 76.636108],
                [-82.081123, 76.561096],
                [-82.222229, 76.532761],
                [-82.127213, 76.44165],
                [-82.179718, 76.416931],
                [-82.799553, 76.387192],
                [-83.099731, 76.463882],
                [-83.061943, 76.497208],
                [-83.110001, 76.579987],
                [-83.388062, 76.671097],
                [-83.404999, 76.719994],
                [-83.355827, 76.752213],
                [-83.37999, 76.758881],
                [-83.523331, 76.700821],
                [-83.246384, 76.572769],
                [-83.239166, 76.517487],
                [-83.183884, 76.496643],
                [-83.239723, 76.463318],
                [-83.184433, 76.424988],
                [-83.223618, 76.410538],
                [-83.710564, 76.433037],
                [-83.756668, 76.468597],
                [-84.033623, 76.534714],
                [-84.08667, 76.624153],
                [-84.284439, 76.657761],
                [-84.330841, 76.647491],
                [-84.193047, 76.609993],
                [-84.249733, 76.536926],
                [-84.180832, 76.484993],
                [-84.236938, 76.443588],
                [-84.636948, 76.434418],
                [-84.78389, 76.469994],
                [-84.795273, 76.503883],
                [-84.950562, 76.577766],
                [-85.028343, 76.574997],
                [-85.051392, 76.51416],
                [-84.960281, 76.420532],
                [-84.397507, 76.330551],
                [-84.381943, 76.31192],
                [-84.429169, 76.30304],
                [-85.194717, 76.281097],
                [-85.262512, 76.310806],
                [-85.434433, 76.304977],
                [-85.73555, 76.355553],
                [-86.372223, 76.386383],
                [-86.412216, 76.407761],
                [-86.41861, 76.469147],
                [-86.211937, 76.535263],
                [-86.630341, 76.635132],
                [-86.301392, 76.524696],
                [-86.655563, 76.467209],
                [-86.632767, 76.441933],
                [-86.71666, 76.3461],
                [-87.130829, 76.384163],
                [-87.154999, 76.394989],
                [-87.118332, 76.420822],
                [-87.140556, 76.437187],
                [-87.42305, 76.45694],
                [-87.394173, 76.503052],
                [-87.50029, 76.535263],
                [-87.462784, 76.586929],
                [-87.536942, 76.617477],
                [-87.580002, 76.611366],
                [-87.566391, 76.575272],
                [-87.598618, 76.540817],
                [-87.493057, 76.503883],
                [-87.553596, 76.451103],
                [-87.429993, 76.417763],
                [-87.498894, 76.382202],
                [-87.416397, 76.348038],
                [-87.547234, 76.370819],
                [-87.591377, 76.341087],
                [-87.742767, 76.346367],
                [-87.816101, 76.411102],
                [-87.948334, 76.357758],
                [-88.351097, 76.384987],
                [-88.434433, 76.402206],
                [-88.36055, 76.431091],
                [-88.438316, 76.464157],
                [-88.355827, 76.481087],
                [-88.349442, 76.514427],
                [-88.52417, 76.62442],
                [-88.478333, 76.656097],
                [-88.559158, 76.727768],
                [-88.476669, 76.783333],
                [-88.51889, 76.816093],
                [-88.704453, 76.710274],
                [-88.584442, 76.635818],
                [-88.660553, 76.599991],
                [-88.556664, 76.589432],
                [-88.495537, 76.5522],
                [-88.515839, 76.52388],
                [-88.489441, 76.503326],
                [-88.611938, 76.452209],
                [-88.608047, 76.399986],
                [-88.677223, 76.401932],
                [-88.693329, 76.420822],
                [-88.658051, 76.475807],
                [-88.610283, 76.482758],
                [-88.68277, 76.525818],
                [-88.644173, 76.565262],
                [-88.733612, 76.593872],
                [-88.796387, 76.550262],
                [-88.775284, 76.524429],
                [-88.832779, 76.498871],
                [-88.781387, 76.466377],
                [-88.827133, 76.440308],
                [-88.921112, 76.405258],
                [-89.033333, 76.41832],
                [-89.045547, 76.447479],
                [-89.213333, 76.429703],
                [-89.430557, 76.497757],
                [-89.403061, 76.524696],
                [-89.441673, 76.565536],
                [-89.541382, 76.541656],
                [-89.676666, 76.567207],
                [-89.603607, 76.621918],
                [-89.411667, 76.680267],
                [-89.456947, 76.713318],
                [-89.388901, 76.729713],
                [-89.468063, 76.753601],
                [-89.533333, 76.853592],
                [-88.986938, 76.954437],
                [-88.995537, 76.984711],
                [-88.74028, 77.002777],
                [-88.464447, 77.065536],
                [-88.393623, 77.0961],
                [-88.545837, 77.100273],
                [-88.426392, 77.120819],
                [-87.690552, 77.135269],
                [-87.617493, 77.094994],
                [-87.349167, 77.106087],
                [-87.460564, 77.125526],
                [-87.356377, 77.175537],
                [-87.069168, 77.182747],
                [-86.804443, 77.127197],
                [-86.889999, 77.164429],
                [-86.614166, 77.180542],
                [-87.210564, 77.205261],
                [-87.166946, 77.233871],
                [-86.910553, 77.260269],
                [-87.012222, 77.274986],
                [-87.183884, 77.273613],
                [-87.248894, 77.303307],
                [-86.851936, 77.360527],
                [-87.391113, 77.330551],
                [-87.711937, 77.359993],
                [-87.698334, 77.390823],
                [-87.784729, 77.429703],
                [-87.642502, 77.48027],
                [-87.694443, 77.537201],
                [-88.214722, 77.650543],
                [-88.150284, 77.72554],
                [-88.162781, 77.758331],
                [-88.232773, 77.768883],
                [-88.103882, 77.813599],
                [-88.223053, 77.855553],
                [-88.01001, 77.819992],
                [-87.231377, 77.89888],
                [-86.422234, 77.830833],
                [-85.975281, 77.705833],
                [-85.833618, 77.579437],
                [-85.808037, 77.506943],
                [-85.718887, 77.472214],
                [-85.794449, 77.419708],
                [-85.503891, 77.474701],
                [-85.481667, 77.459717],
                [-85.535278, 77.44136],
                [-85.399727, 77.395828],
                [-84.973328, 77.377197],
                [-84.529167, 77.295822],
                [-84.463333, 77.300262],
                [-84.615013, 77.383041],
                [-84.550278, 77.401382],
                [-84.005836, 77.397491],
                [-83.464722, 77.348328],
                [-83.494164, 77.376648],
                [-83.448883, 77.38916],
                [-83.71167, 77.404709],
                [-83.835564, 77.455261],
                [-83.426102, 77.49971],
                [-83.180283, 77.592209],
                [-82.817497, 77.759987],
                [-82.78389, 77.782494],
                [-82.796661, 77.805542],
                [-82.674438, 77.836929],
                [-82.637787, 77.8936],
                [-82.547501, 77.915817],
                [-82.577789, 77.943314],
                [-82.513062, 77.956383],
                [-82.591667, 78.017487],
                [-82.377487, 78.035812],
                [-82.318619, 78.070831],
                [-82.549438, 78.07193],
                [-82.780289, 78.014999],
                [-82.785278, 77.969994],
                [-82.736107, 77.924149],
                [-82.949158, 77.874687],
                [-82.989723, 77.824707],
                [-83.111389, 77.789703],
                [-83.19249, 77.710541],
                [-83.427223, 77.600807],
                [-83.873047, 77.493317],
                [-84.858887, 77.542763],
                [-84.866386, 77.574158],
                [-84.44194, 77.7061],
                [-84.431107, 77.726379],
                [-84.489723, 77.746368],
                [-84.486389, 77.71138],
                [-84.53389, 77.684982],
                [-84.952499, 77.601379],
                [-85.298889, 77.660538],
                [-85.270279, 77.708328],
                [-85.348892, 77.728867],
                [-85.205002, 77.757217],
                [-85.18222, 77.795532],
                [-85.029167, 77.799988],
                [-84.955276, 77.835823],
                [-85.297783, 77.797211],
                [-85.400284, 77.813309],
                [-85.389717, 77.841927],
                [-85.207779, 77.883881],
                [-84.848892, 77.891937],
                [-84.89473, 77.833878],
                [-84.664436, 77.902206],
                [-84.313606, 77.891937],
                [-84.634453, 77.926933],
                [-85.474716, 77.868591],
                [-85.515289, 77.883614],
                [-85.486656, 77.909988],
                [-85.678879, 77.929428],
                [-85.065552, 78.056374],
                [-84.761673, 78.023613],
                [-84.547501, 78.071114],
                [-84.431686, 78.070763],
                [-84.288063, 78.075546],
                [-84.511253, 78.0858],
                [-84.587563, 78.077744],
                [-84.881943, 78.059143],
                [-85.094452, 78.097488],
                [-85.008621, 78.137497],
                [-84.995003, 78.16304],
                [-85.033623, 78.171654],
                [-84.631104, 78.199997],
                [-84.201111, 78.156937],
                [-84.026108, 78.176376],
                [-84.506668, 78.217758],
                [-84.951401, 78.195534],
                [-84.973892, 78.208603],
                [-84.956123, 78.232208],
                [-84.834442, 78.314987],
                [-84.572777, 78.35054],
                [-84.772232, 78.342209],
                [-84.866943, 78.369141],
                [-84.845551, 78.382202],
                [-84.765556, 78.477478],
                [-84.782501, 78.505829],
                [-84.619453, 78.588318],
                [-84.675278, 78.588318],
                [-84.978333, 78.414993],
                [-84.952789, 78.396942],
                [-84.961937, 78.343597],
                [-85.045837, 78.309143],
                [-85.024719, 78.284416],
                [-85.508057, 78.099152],
                [-86.148354, 78.054703],
                [-86.28833, 78.076393],
                [-86.230003, 78.136383],
                [-86.251404, 78.156647],
                [-86.113052, 78.170532],
                [-85.931107, 78.236649],
                [-85.878326, 78.288307],
                [-85.899727, 78.303589],
                [-85.835281, 78.332207],
                [-85.833069, 78.379967],
                [-86.05278, 78.297493],
                [-86.073624, 78.248596],
                [-86.285278, 78.193314],
                [-86.497772, 78.215553],
                [-86.736938, 78.118042],
                [-87.109444, 78.103043],
                [-87.529167, 78.132202],
                [-87.407501, 78.183037],
                [-87.087219, 78.2061],
                [-87.513344, 78.224991],
                [-87.491943, 78.285812],
                [-87.517227, 78.426376],
                [-87.140556, 78.550262],
                [-86.858337, 78.54776],
                [-86.877777, 78.573036],
                [-87.122498, 78.587196],
                [-86.976936, 78.648331],
                [-86.934433, 78.709991],
                [-86.638901, 78.799423],
                [-85.646667, 78.848602],
                [-85.064163, 78.919144],
                [-84.712784, 78.867752],
                [-83.746948, 78.836929],
                [-83.165833, 78.729103],
                [-82.82251, 78.695251],
                [-82.61055, 78.611366],
                [-82.308327, 78.568878],
                [-82.23555, 78.595833],
                [-82.594452, 78.703049],
                [-82.231949, 78.736366],
                [-82.781387, 78.731087],
                [-83.210564, 78.798866],
                [-83.25473, 78.834991],
                [-83.056953, 78.856087],
                [-82.429443, 78.833328],
                [-81.947487, 78.865807],
                [-81.712509, 78.839706],
                [-81.656952, 78.888321],
                [-81.755836, 78.918053],
                [-81.698608, 78.973877],
                [-81.486656, 79.052467],
                [-81.910278, 79.00499],
                [-82.110283, 78.913597],
                [-82.503067, 78.882751],
                [-82.924438, 78.934982],
                [-84.259171, 78.959427],
                [-84.766953, 79.037201],
                [-84.78389, 79.074432],
                [-84.52861, 79.143333],
                [-84.135559, 79.121918],
                [-83.896393, 79.03804],
                [-83.474716, 79.024147],
                [-83.358612, 79.050812],
                [-83.546112, 79.04248],
                [-83.995003, 79.133041],
                [-84.029167, 79.156937],
                [-83.939987, 79.185532],
                [-83.956673, 79.221916],
                [-84.158508, 79.183296],
                [-84.32695, 79.188583],
                [-84.335564, 79.252213],
                [-84.428879, 79.290268],
                [-84.484734, 79.406372],
                [-84.882492, 79.486099],
                [-84.969452, 79.537491],
                [-84.969452, 79.566383],
                [-85.047234, 79.587769],
                [-85.022232, 79.611099],
                [-85.050278, 79.621643],
                [-85.493332, 79.700546],
                [-85.900284, 79.700272],
                [-86.486389, 79.763611],
                [-86.48555, 79.816093],
                [-86.37999, 79.821381],
                [-86.454453, 79.847763],
                [-86.471939, 79.890549],
                [-86.436394, 79.942467],
                [-86.367218, 79.962769],
                [-85.585007, 79.938026],
                [-85.365829, 79.896378],
                [-85.254997, 79.920822],
                [-86.482773, 80.008614],
                [-86.579453, 80.043053],
                [-86.659439, 80.128036],
                [-86.548607, 80.205261],
                [-86.514717, 80.299149],
                [-86.468338, 80.308594],
                [-85.897507, 80.333054],
                [-85.290833, 80.2686],
                [-83.781952, 80.245819],
                [-82.153061, 79.858871],
                [-81.969727, 79.752777],
                [-81.978882, 79.718323],
                [-81.671112, 79.687477],
                [-81.687767, 79.645828],
                [-81.618607, 79.618317],
                [-81.785278, 79.61499],
                [-81.706673, 79.586647],
                [-81.424713, 79.636658],
                [-80.598053, 79.566093],
                [-80.641953, 79.588043],
                [-80.598053, 79.601379],
                [-79.904716, 79.646942],
                [-79.751404, 79.701393],
                [-80.43222, 79.677757],
                [-80.827499, 79.648331],
                [-81.227783, 79.709427],
                [-81.525009, 79.714432],
                [-81.509743, 79.750816],
                [-81.62999, 79.764427],
                [-81.56778, 79.814697],
                [-81.663063, 79.903053],
                [-81.400284, 79.937759],
                [-82.168327, 80.013611],
                [-83.203888, 80.318047],
                [-82.031387, 80.398331],
                [-80.367767, 80.462196],
                [-80.294159, 80.486649],
                [-80.357224, 80.501389],
                [-80.153343, 80.526382],
                [-78.063889, 80.564697],
                [-78.030838, 80.5961],
                [-78.416656, 80.608032],
                [-79.960281, 80.6147],
                [-78.585281, 80.772217],
                [-77.639183, 80.830276],
                [-76.511673, 80.854431],
                [-76.490829, 80.874977],
                [-76.621109, 80.900818],
                [-77.168877, 80.886932],
                [-77.548889, 80.913307],
                [-78.869453, 80.852203],
                [-78.934998, 80.875526],
                [-78.858337, 80.924149],
                [-78.928596, 80.99054],
                [-78.501106, 81.09166],
                [-78.393066, 81.142761],
                [-78.463623, 81.160812],
                [-78.159439, 81.193863],
                [-77.612503, 81.318878],
                [-76.955276, 81.393883],
                [-76.777786, 81.444427],
                [-78.228333, 81.291656],
                [-78.675278, 81.191933],
                [-78.754181, 81.135818],
                [-78.690826, 81.119431],
                [-78.817497, 81.106087],
                [-78.941383, 81.101089],
                [-79.501678, 81.193588],
                [-79.088837, 81.094757],
                [-79.25528, 81.058868],
                [-79.343887, 80.998322],
                [-79.165283, 80.96666],
                [-79.265289, 80.924149],
                [-79.41861, 80.916931],
                [-79.435822, 80.881088],
                [-79.618057, 80.819443],
                [-80.919449, 80.655548],
                [-82.353882, 80.556374],
                [-83.096657, 80.541656],
                [-83.165009, 80.551933],
                [-83.16806, 80.57666],
                [-83.078056, 80.644989],
                [-82.2164, 80.719147],
                [-81.758904, 80.813026],
                [-81.996109, 80.830276],
                [-82.56723, 80.740807],
                [-83.356949, 80.685532],
                [-83.541107, 80.704163],
                [-83.530563, 80.724701],
                [-83.569458, 80.739151],
                [-83.133057, 80.818878],
                [-83.256958, 80.838593],
                [-83.66777, 80.75],
                [-83.863892, 80.757492],
                [-83.775284, 80.739433],
                [-83.836403, 80.719711],
                [-83.720001, 80.636658],
                [-83.840561, 80.545532],
                [-85.066963, 80.505257],
                [-85.864166, 80.535538],
                [-85.564713, 80.619431],
                [-86.080841, 80.52832],
                [-86.739166, 80.597488],
                [-86.514717, 80.729431],
                [-85.77417, 80.948868],
                [-84.991379, 81.040268],
                [-84.769447, 81.027771],
                [-82.826401, 81.123032],
                [-82.364441, 81.179428],
                [-85.608887, 81.054153],
                [-86.309723, 80.940811],
                [-87.076401, 80.722763],
                [-87.215012, 80.638321],
                [-87.458054, 80.627762],
                [-88.406662, 80.716927],
                [-89.334732, 80.857483],
                [-89.462219, 80.919144],
                [-88.590286, 80.996368],
                [-87.446381, 80.976929],
                [-86.671944, 81.005257],
                [-86.061111, 81.082764],
                [-85.838898, 81.075272],
                [-85.914169, 81.100807],
                [-85.887512, 81.118874],
                [-84.745827, 81.289429],
                [-85.279167, 81.289978],
                [-86.077499, 81.207489],
                [-86.438049, 81.126083],
                [-87.37944, 81.070267],
                [-88.339996, 81.069717],
                [-89.629173, 81.009163],
                [-90, 81.032143],
                [-90.197769, 81.069717],
                [-90.351936, 81.16748],
                [-90, 81.242157],
                [-89.491096, 81.206383],
                [-88.944153, 81.244141],
                [-89.335281, 81.242752],
                [-89.952499, 81.329437],
                [-89.026108, 81.459427],
                [-88.823059, 81.453598],
                [-88.928329, 81.485527],
                [-88.715843, 81.513046],
                [-87.984161, 81.535812],
                [-87.914169, 81.509163],
                [-87.24472, 81.490257],
                [-88.306381, 81.581383],
                [-88.998047, 81.540543],
                [-90, 81.417747],
                [-90.443047, 81.366653],
                [-90.553329, 81.384987],
                [-90.487778, 81.398613],
                [-90.856659, 81.444138],
                [-89.586166, 81.621147],
                [-90.091667, 81.623306],
                [-90.033073, 81.646942],
                [-90.296951, 81.698593],
                [-90.360817, 81.685257],
                [-90.29277, 81.641373],
                [-90.718887, 81.666656],
                [-90.991096, 81.616089],
                [-91.006958, 81.598877],
                [-90.964722, 81.562477],
                [-91.071671, 81.537201],
                [-91.144173, 81.555542],
                [-91.446381, 81.524429],
                [-91.406387, 81.54776],
                [-91.444153, 81.583603],
                [-91.900284, 81.616928],
                [-91.950287, 81.637207],
                [-91.863892, 81.642487],
                [-91.956673, 81.6586],
                [-91.770844, 81.663307],
                [-91.809723, 81.674988],
                [-91.737213, 81.68692],
                [-91.726936, 81.726929],
                [-91.48555, 81.769989],
                [-91.034439, 81.763893],
                [-91.152496, 81.798027],
                [-91.051666, 81.828873],
                [-90.338058, 81.893051],
                [-90, 81.8992],
                [-89.700844, 81.915543],
                [-89.741096, 81.894989],
                [-89.62999, 81.856369],
                [-89.332962, 81.805687],
                [-89.349991, 81.823036],
                [-89.146667, 81.890549],
                [-89.397507, 81.909416],
                [-89.419159, 81.915543],
                [-89.397781, 81.930817],
                [-89.007233, 81.915543],
                [-88.986389, 81.944977],
                [-89.054443, 81.987488],
                [-89.021118, 81.998032],
                [-88.075562, 82.10498],
                [-87.352783, 82.067207],
                [-87.174438, 82.014709],
                [-87.309433, 81.967484],
                [-86.768341, 81.890266],
                [-86.734161, 81.902771],
                [-86.834732, 81.927757],
                [-87.13028, 81.968323],
                [-86.842773, 81.987198],
                [-87.009743, 82.030548],
                [-86.791946, 82.058029],
                [-86.169159, 82.041656],
                [-85.422501, 81.857483],
                [-85.371658, 81.859711],
                [-85.398064, 81.881088],
                [-85.731949, 81.983322],
                [-85.559433, 82.001663],
                [-85.188316, 81.992752],
                [-85.01889, 81.919434],
                [-84.793877, 81.881363],
                [-85.066101, 81.987488],
                [-84.889183, 81.990257],
                [-84.816673, 81.970833],
                [-84.811943, 81.927467],
                [-84.635277, 81.886108],
                [-84.605003, 81.889977],
                [-84.693604, 81.921097],
                [-84.729721, 81.977203],
                [-84.932503, 82.01944],
                [-86.637512, 82.12442],
                [-86.876099, 82.202209],
                [-85.662216, 82.2397],
                [-85.370827, 82.279984],
                [-85.48999, 82.319717],
                [-85.420273, 82.344994],
                [-85.537216, 82.345261],
                [-85.515289, 82.40332],
                [-85.911942, 82.435806],
                [-85.046951, 82.481934],
                [-84.641678, 82.465553],
                [-84.631378, 82.440262],
                [-84.943878, 82.425812],
                [-84.449997, 82.386108],
                [-84.344452, 82.352768],
                [-83.961403, 82.368591],
                [-83.516403, 82.31694],
                [-83.368607, 82.276382],
                [-83.344452, 82.227203],
                [-82.976669, 82.138321],
                [-82.968338, 82.098038],
                [-83.111938, 82.065262],
                [-82.284157, 82.066383],
                [-81.878052, 82.036377],
                [-82.020844, 82.082207],
                [-82.676941, 82.107758],
                [-82.731377, 82.149986],
                [-83.011398, 82.221649],
                [-83.028877, 82.276657],
                [-82.654449, 82.282211],
                [-81.91806, 82.154984],
                [-80.640289, 82.018333],
                [-80.571953, 81.989151],
                [-80.085007, 81.973602],
                [-79.564163, 81.825272],
                [-79.229172, 81.816093],
                [-79.670837, 81.927467],
                [-79.710281, 81.96138],
                [-79.921387, 81.984993],
                [-79.853333, 82.018883],
                [-80.791107, 82.079437],
                [-80.975563, 82.123596],
                [-80.868057, 82.154984],
                [-81.624443, 82.199997],
                [-82.679993, 82.370819],
                [-82.728882, 82.408333],
                [-82.498337, 82.506378],
                [-81.47583, 82.498596],
                [-81.9664, 82.52887],
                [-82.343887, 82.595261],
                [-82.39473, 82.617477],
                [-82.371933, 82.639709],
                [-81.972229, 82.666382],
                [-81.359734, 82.620819],
                [-80.891953, 82.532761],
                [-80.58168, 82.543053],
                [-81.138344, 82.703049],
                [-81.508621, 82.764709],
                [-81.585007, 82.800812],
                [-81.411392, 82.827766],
                [-80.381104, 82.788879],
                [-80.138901, 82.719994],
                [-80.182503, 82.694702],
                [-80.160278, 82.681374],
                [-79.861656, 82.64415],
                [-79.82251, 82.657761],
                [-79.983322, 82.689148],
                [-79.885834, 82.708603],
                [-79.203888, 82.666931],
                [-78.521118, 82.676933],
                [-79.623047, 82.727768],
                [-79.886948, 82.75943],
                [-79.996948, 82.803307],
                [-79.673889, 82.824707],
                [-80.15834, 82.835541],
                [-80.393066, 82.875526],
                [-80.429718, 82.89415],
                [-80.145279, 82.933868],
                [-79.458344, 82.974152],
                [-79.17749, 82.951927],
                [-79.218887, 82.933594],
                [-79.04277, 82.872482],
                [-78.671112, 82.945534],
                [-78.503342, 82.913307],
                [-78.55777, 82.860527],
                [-78.534439, 82.847763],
                [-78.406113, 82.873596],
                [-78.144173, 82.823318],
                [-78.106659, 82.83194],
                [-78.238892, 82.865257],
                [-78.016953, 82.857483],
                [-78.108337, 82.893333],
                [-77.813049, 82.924423],
                [-77.128326, 82.863312],
                [-76.927673, 82.790741],
                [-76.959167, 82.774147],
                [-76.766663, 82.750816],
                [-76.570557, 82.666656],
                [-75.892227, 82.591927],
                [-76.207497, 82.506378],
                [-76.261002, 82.466553],
                [-76.230827, 82.444702],
                [-75.671387, 82.586929],
                [-75.396118, 82.6147],
                [-76.235817, 82.712196],
                [-76.309158, 82.752777],
                [-75.976097, 82.784714],
                [-76.447487, 82.797493],
                [-76.752792, 82.894989],
                [-77.344727, 82.972488],
                [-77.381378, 82.994431],
                [-77.183884, 83.033867],
                [-77.135559, 83.011383],
                [-76.604172, 83.008614],
                [-76.02861, 83.054428],
                [-75.251404, 83.015823],
                [-74.95639, 83.045532],
                [-74.435822, 83.027206],
                [-74.018066, 82.95694],
                [-73.81778, 82.852768],
                [-72.633904, 82.694427],
                [-72.502502, 82.724426],
                [-73.148621, 82.794434],
                [-73.375549, 82.844437],
                [-73.354721, 82.856087],
                [-73.430557, 82.8936],
                [-73.650558, 82.925812],
                [-73.189713, 83.023323],
                [-72.902786, 83.048027],
                [-73.001106, 83.059418],
                [-72.650558, 83.096367],
                [-71.611656, 83.0961],
                [-71.775009, 83.032211],
                [-71.778343, 83.001663],
                [-71.56723, 82.941093],
                [-70.871384, 82.881088],
                [-71.497498, 83.007217],
                [-71.097229, 83.069992],
                [-71.120003, 83.092484],
                [-70.86055, 83.081383],
                [-70.887222, 83.098038],
                [-70.804443, 83.10498],
                [-69.665009, 83.108322],
                [-69.730827, 83.090271],
                [-69.664169, 83.070831],
                [-69.775284, 83.04776],
                [-69.451111, 83.035812],
                [-69.647507, 82.992203],
                [-69.015556, 83.040817],
                [-68.975281, 83.02832],
                [-68.980827, 82.982483],
                [-68.46666, 83.008041],
                [-68.154167, 82.991089],
                [-68.188599, 82.946091],
                [-68.145554, 82.934982],
                [-67.544159, 82.962196]
              ]
            ],
            [
              [
                [-96.742027, 76.982208],
                [-96.471939, 76.954987],
                [-96.369164, 76.990807],
                [-96.253342, 76.987198],
                [-96.387787, 77.030548],
                [-95.734734, 77.068604],
                [-95.41777, 77.056931],
                [-94.944443, 76.97554],
                [-94.526398, 76.969437],
                [-94.447487, 76.916656],
                [-94.238892, 76.889427],
                [-94.08168, 76.890266],
                [-93.943604, 76.933868],
                [-93.65834, 76.909988],
                [-93.584167, 76.856087],
                [-93.295837, 76.781097],
                [-93.263634, 76.744141],
                [-93.179718, 76.741089],
                [-93.174438, 76.674988],
                [-93.260277, 76.630257],
                [-93.306664, 76.546654],
                [-93.651947, 76.44165],
                [-93.54834, 76.386108],
                [-93.459732, 76.4086],
                [-93.576401, 76.426651],
                [-93.357224, 76.470543],
                [-93.123611, 76.573036],
                [-93.095284, 76.590553],
                [-93.108887, 76.616379],
                [-92.368607, 76.594437],
                [-91.938599, 76.66832],
                [-91.410553, 76.689148],
                [-90.986107, 76.649147],
                [-90.81778, 76.593597],
                [-90.582497, 76.565262],
                [-90.503067, 76.531372],
                [-90.528061, 76.49971],
                [-90.468063, 76.473038],
                [-90.779167, 76.461113],
                [-90.986938, 76.511383],
                [-91.090561, 76.478043],
                [-91.441101, 76.512772],
                [-91.566673, 76.498871],
                [-91.416397, 76.460274],
                [-90, 76.360207],
                [-89.305557, 76.299149],
                [-89.192207, 76.242203],
                [-89.349731, 76.183594],
                [-90, 76.173332],
                [-90.453613, 76.170532],
                [-90.110817, 76.124153],
                [-91.149727, 76.192467],
                [-91.613617, 76.262207],
                [-91.257782, 76.204437],
                [-91.385559, 76.142487],
                [-91.220284, 76.161652],
                [-90.679718, 76.117203],
                [-90.848892, 76.060806],
                [-90.54834, 76.09137],
                [-90.190552, 76.061096],
                [-90.301666, 76.032494],
                [-91.160828, 76.018051],
                [-90.937767, 76.000267],
                [-91.074448, 75.986649],
                [-90.938599, 75.951393],
                [-91.12944, 75.9086],
                [-91.12999, 75.839157],
                [-91.079453, 75.848038],
                [-91.058884, 75.896652],
                [-90.895844, 75.9272],
                [-90.793877, 75.994713],
                [-90.681107, 75.981369],
                [-90.718063, 75.955551],
                [-90.433319, 75.970261],
                [-90.526108, 75.930542],
                [-90.529999, 75.898331],
                [-90.50473, 75.895264],
                [-90.339447, 75.968323],
                [-90.118607, 75.931931],
                [-90.060272, 76.004707],
                [-90, 76.00959],
                [-89.6875, 75.899986],
                [-89.778877, 75.831673],
                [-89.775009, 75.787491],
                [-89.555557, 75.839432],
                [-89.620003, 75.853592],
                [-89.587509, 75.859154],
                [-89.164169, 75.774696],
                [-89.253891, 75.631088],
                [-89.658051, 75.61998],
                [-89.765289, 75.575546],
                [-89.542503, 75.570541],
                [-89.700844, 75.55304],
                [-89.217216, 75.584991],
                [-89.099731, 75.484154],
                [-88.980003, 75.471367],
                [-88.963898, 75.431931],
                [-88.779716, 75.43248],
                [-88.75, 75.474991],
                [-88.902222, 75.604713],
                [-88.771942, 75.62886],
                [-88.738892, 75.679428],
                [-88.203613, 75.531097],
                [-88.305832, 75.492203],
                [-88.263062, 75.476089],
                [-87.997498, 75.528053],
                [-87.748894, 75.578323],
                [-87.661392, 75.567207],
                [-87.624161, 75.516388],
                [-87.495537, 75.485809],
                [-87.606377, 75.449707],
                [-87.533623, 75.446091],
                [-87.416397, 75.48526],
                [-87.513344, 75.559418],
                [-87.251106, 75.621094],
                [-86.807503, 75.479156],
                [-86.583893, 75.474701],
                [-86.375549, 75.427467],
                [-86.596657, 75.361649],
                [-86.169998, 75.418587],
                [-85.527222, 75.401093],
                [-85.822777, 75.430817],
                [-86.143066, 75.508041],
                [-85.539719, 75.599426],
                [-85.56778, 75.576927],
                [-85.537781, 75.564423],
                [-85.232224, 75.558594],
                [-85.321671, 75.574432],
                [-85.292221, 75.5961],
                [-85.040558, 75.631653],
                [-85.011673, 75.644989],
                [-85.074173, 75.651932],
                [-85.039169, 75.657761],
                [-84.497498, 75.631653],
                [-84.651108, 75.6436],
                [-84.634743, 75.66304],
                [-84.66362, 75.686096],
                [-84.299156, 75.702766],
                [-84.194443, 75.762497],
                [-84.022232, 75.767212],
                [-83.920837, 75.803589],
                [-84.002502, 75.820831],
                [-83.740547, 75.824432],
                [-83.698036, 75.814423],
                [-83.736938, 75.795258],
                [-83.515289, 75.789703],
                [-83.433609, 75.750267],
                [-83.099442, 75.732208],
                [-82.664719, 75.811371],
                [-82.327217, 75.836929],
                [-81.118057, 75.775543],
                [-81.101097, 75.752487],
                [-81.22084, 75.704712],
                [-81.271118, 75.651382],
                [-80.316963, 75.630539],
                [-80.068336, 75.578873],
                [-80.082779, 75.54248],
                [-79.953613, 75.540268],
                [-80.132874, 75.502579],
                [-80.35527, 75.473877],
                [-80.373047, 75.463043],
                [-80.338608, 75.456383],
                [-79.929169, 75.479713],
                [-79.586403, 75.454712],
                [-79.682503, 75.430817],
                [-79.488602, 75.362488],
                [-79.611938, 75.298317],
                [-79.44249, 75.277206],
                [-79.62944, 75.174988],
                [-79.83168, 75.170532],
                [-79.944153, 75.136383],
                [-79.974167, 75.089981],
                [-80.440552, 75.03804],
                [-80.173889, 74.982483],
                [-80.266113, 74.94664],
                [-80.035553, 74.957764],
                [-79.975281, 74.99942],
                [-79.795273, 75.027481],
                [-79.613892, 75.019989],
                [-79.504997, 74.998322],
                [-79.551941, 74.981659],
                [-79.335831, 74.88916],
                [-79.930557, 74.813309],
                [-80.171387, 74.86026],
                [-80.142502, 74.898041],
                [-80.18222, 74.937187],
                [-80.210831, 74.931091],
                [-80.199997, 74.886108],
                [-80.253067, 74.870819],
                [-80.321671, 74.937759],
                [-80.416107, 74.888893],
                [-80.097778, 74.820267],
                [-80.108337, 74.7836],
                [-80.246948, 74.757492],
                [-80.13028, 74.715553],
                [-80.212219, 74.690536],
                [-80.17305, 74.655823],
                [-80.196663, 74.641373],
                [-80.146667, 74.626923],
                [-80.248047, 74.576103],
                [-81.219162, 74.571381],
                [-81.810822, 74.45694],
                [-82.456673, 74.517212],
                [-82.382217, 74.541092],
                [-82.435547, 74.570267],
                [-82.479446, 74.536102],
                [-82.61528, 74.511108],
                [-82.914169, 74.549149],
                [-83.079727, 74.630257],
                [-83.123894, 74.684982],
                [-83.107773, 74.748032],
                [-83.023064, 74.780548],
                [-83.081123, 74.78804],
                [-83.08168, 74.818047],
                [-83.248894, 74.823608],
                [-83.528061, 74.901657],
                [-83.560547, 74.887207],
                [-83.518623, 74.839432],
                [-83.319458, 74.767487],
                [-83.441383, 74.660812],
                [-83.454727, 74.591087],
                [-83.611656, 74.540817],
                [-84.899437, 74.503326],
                [-84.983063, 74.570541],
                [-84.99527, 74.643333],
                [-84.950844, 74.67276],
                [-84.990547, 74.698029],
                [-85.07251, 74.641098],
                [-85.041946, 74.617752],
                [-85.04361, 74.523323],
                [-85.259453, 74.49054],
                [-85.363617, 74.501938],
                [-85.37027, 74.552467],
                [-85.527222, 74.688873],
                [-85.551941, 74.660263],
                [-85.480293, 74.537201],
                [-85.580841, 74.498322],
                [-86.120827, 74.482208],
                [-86.082779, 74.555252],
                [-86.197487, 74.615257],
                [-86.240547, 74.596939],
                [-86.24472, 74.52388],
                [-86.423317, 74.478867],
                [-86.691101, 74.544144],
                [-86.785278, 74.616928],
                [-86.794449, 74.539978],
                [-86.604172, 74.468048],
                [-86.943604, 74.46138],
                [-87.008904, 74.508331],
                [-87.037781, 74.474152],
                [-87.111938, 74.468048],
                [-87.320847, 74.476646],
                [-87.373894, 74.51915],
                [-87.309433, 74.545822],
                [-87.574722, 74.461929],
                [-87.732224, 74.466377],
                [-87.768623, 74.514999],
                [-87.90361, 74.472214],
                [-88.093063, 74.48027],
                [-88.133331, 74.507767],
                [-88.21666, 74.482758],
                [-88.535278, 74.503601],
                [-88.571121, 74.549988],
                [-88.560822, 74.593048],
                [-88.434433, 74.696091],
                [-88.405838, 74.748322],
                [-88.431671, 74.762497],
                [-88.293327, 74.772491],
                [-88.487213, 74.791367],
                [-88.443604, 74.838593],
                [-88.547783, 74.907761],
                [-88.579453, 74.889977],
                [-88.547501, 74.843048],
                [-88.615829, 74.815536],
                [-88.618057, 74.846367],
                [-88.664719, 74.844994],
                [-88.749733, 74.777771],
                [-88.753342, 74.714157],
                [-88.835564, 74.661377],
                [-88.889717, 74.670532],
                [-88.909729, 74.777771],
                [-89.088608, 74.837196],
                [-89.011124, 74.780823],
                [-89.047501, 74.722488],
                [-89.271118, 74.754707],
                [-89.09584, 74.688026],
                [-89.125, 74.616928],
                [-89.185272, 74.587486],
                [-89.928596, 74.530823],
                [-90, 74.586166],
                [-90.106659, 74.549423],
                [-90.363052, 74.594711],
                [-90.317497, 74.626373],
                [-90.589722, 74.613312],
                [-90.731667, 74.664146],
                [-90.761398, 74.714157],
                [-90.721123, 74.732208],
                [-90.845001, 74.721916],
                [-90.895607, 74.681137],
                [-91.024719, 74.702766],
                [-90.758057, 74.8311],
                [-90.740829, 74.847488],
                [-90.772232, 74.884987],
                [-90.853882, 74.875259],
                [-91.101936, 74.751099],
                [-91.22583, 74.733597],
                [-91.106659, 74.625809],
                [-91.281387, 74.633614],
                [-91.301392, 74.656372],
                [-91.277222, 74.688026],
                [-91.314713, 74.716087],
                [-91.366653, 74.704163],
                [-91.33139, 74.672493],
                [-91.381943, 74.641098],
                [-91.676102, 74.671921],
                [-91.62027, 74.704987],
                [-91.708618, 74.727478],
                [-91.851097, 74.698868],
                [-91.875267, 74.711929],
                [-91.81221, 74.734421],
                [-92.045547, 74.789703],
                [-92.066391, 74.813309],
                [-92.013062, 74.851929],
                [-92.015289, 74.913597],
                [-92.075562, 74.933037],
                [-92.04805, 74.958328],
                [-92.223328, 75.029427],
                [-92.198036, 75.052757],
                [-92.228882, 75.071114],
                [-92.029999, 75.08638],
                [-92.01001, 75.101089],
                [-92.112778, 75.12886],
                [-92.051392, 75.146942],
                [-92.271393, 75.132751],
                [-92.503616, 75.218048],
                [-92.458618, 75.250816],
                [-92.448334, 75.318329],
                [-92.328613, 75.369431],
                [-92.458618, 75.368874],
                [-92.403061, 75.436096],
                [-92.210281, 75.551376],
                [-92.004997, 75.594994],
                [-92.080841, 75.617477],
                [-92.008347, 75.661377],
                [-92.174438, 75.744431],
                [-92.104721, 75.805252],
                [-92.108612, 75.858871],
                [-92.629173, 76.008881],
                [-92.637222, 76.115807],
                [-92.767227, 76.166092],
                [-92.793877, 76.207489],
                [-93.066963, 76.299149],
                [-93.061478, 76.344788],
                [-93.315277, 76.36026],
                [-93.617493, 76.291092],
                [-93.666397, 76.298599],
                [-93.624443, 76.305817],
                [-93.636124, 76.32666],
                [-93.678879, 76.32222],
                [-93.763901, 76.286377],
                [-93.637512, 76.258614],
                [-93.689987, 76.248871],
                [-94.625816, 76.275269],
                [-94.720551, 76.315536],
                [-95.033333, 76.220833],
                [-95.386948, 76.235809],
                [-95.366943, 76.301376],
                [-95.279167, 76.281372],
                [-94.983887, 76.332489],
                [-94.843613, 76.303864],
                [-94.814163, 76.329163],
                [-95.274719, 76.372208],
                [-95.388901, 76.351929],
                [-95.450562, 76.375809],
                [-95.378883, 76.395538],
                [-95.83139, 76.394989],
                [-95.995827, 76.43692],
                [-96.106949, 76.494431],
                [-96.065552, 76.521378],
                [-95.77861, 76.518883],
                [-95.587784, 76.603592],
                [-95.780563, 76.548866],
                [-96.016953, 76.549149],
                [-96.001953, 76.584152],
                [-96.158623, 76.583328],
                [-96.238052, 76.635269],
                [-96.403343, 76.639709],
                [-96.470284, 76.685532],
                [-96.946663, 76.723602],
                [-96.84111, 76.766388],
                [-96.904449, 76.790543],
                [-96.855003, 76.813026],
                [-96.684723, 76.774696],
                [-96.673317, 76.748322],
                [-96.305557, 76.753883],
                [-96.583328, 76.771927],
                [-96.511398, 76.804153],
                [-96.320557, 76.806641],
                [-96.813606, 76.868042],
                [-96.865829, 76.898041],
                [-96.857773, 76.924988],
                [-96.659157, 76.949142],
                [-96.827789, 76.968872],
                [-96.742027, 76.982208]
              ]
            ],
            [
              [
                [-86.410278, 64.582764],
                [-86.238892, 64.804153],
                [-86.171661, 64.823036],
                [-86.210564, 64.884163],
                [-86.153343, 64.927757],
                [-86.225014, 65.003601],
                [-86.138344, 65.094437],
                [-86.171661, 65.27887],
                [-86.093887, 65.361649],
                [-86.153343, 65.38472],
                [-86.097778, 65.52916],
                [-86.019997, 65.621918],
                [-86.013901, 65.709152],
                [-85.791382, 65.853317],
                [-85.542503, 65.933319],
                [-85.468887, 65.913879],
                [-85.520554, 65.842758],
                [-85.493332, 65.795258],
                [-85.370003, 65.834991],
                [-85.156387, 65.776657],
                [-85.148064, 65.733322],
                [-85.18721, 65.721367],
                [-85.051666, 65.606934],
                [-85.311943, 65.537773],
                [-85.015007, 65.41832],
                [-85.012512, 65.322769],
                [-84.930557, 65.258614],
                [-84.924713, 65.209717],
                [-84.792503, 65.226929],
                [-84.745827, 65.351379],
                [-84.561943, 65.481087],
                [-84.43222, 65.453323],
                [-84.444717, 65.413597],
                [-84.384171, 65.388321],
                [-84.151108, 65.338593],
                [-84.230827, 65.26944],
                [-84.141388, 65.219994],
                [-83.889999, 65.190262],
                [-83.913887, 65.174698],
                [-83.876099, 65.162773],
                [-83.540283, 65.164146],
                [-83.408623, 65.135536],
                [-83.334732, 65.069992],
                [-83.330841, 65.021378],
                [-83.208054, 64.945534],
                [-83.022232, 64.913879],
                [-82.865829, 64.873596],
                [-82.800278, 64.808868],
                [-82.709167, 64.776382],
                [-82.542503, 64.739433],
                [-82.382492, 64.767761],
                [-82.202217, 64.713608],
                [-82.215012, 64.684982],
                [-82.092499, 64.684982],
                [-81.763062, 64.501099],
                [-81.753067, 64.35582],
                [-81.777222, 64.311653],
                [-81.600281, 64.202766],
                [-81.602493, 64.129967],
                [-81.955841, 64.06192],
                [-81.986938, 63.99416],
                [-81.461937, 64.034988],
                [-81.43222, 64.043587],
                [-81.442757, 64.086929],
                [-81.388901, 64.097214],
                [-81.398621, 64.072769],
                [-81.287781, 64.080276],
                [-80.931671, 63.99194],
                [-80.909157, 64.023323],
                [-80.975563, 64.057747],
                [-80.934723, 64.129967],
                [-80.777496, 64.079437],
                [-80.698608, 64.011383],
                [-80.522507, 63.9786],
                [-80.551666, 63.925549],
                [-80.492218, 63.905548],
                [-80.699997, 63.911659],
                [-80.66362, 63.883331],
                [-80.206123, 63.808331],
                [-80.171661, 63.771099],
                [-80.454178, 63.727772],
                [-80.587784, 63.63583],
                [-80.922501, 63.521381],
                [-80.958054, 63.458328],
                [-81.076401, 63.451389],
                [-81.535278, 63.58028],
                [-81.653877, 63.571941],
                [-81.76889, 63.63805],
                [-81.909729, 63.631939],
                [-82.05249, 63.689159],
                [-82.224716, 63.686378],
                [-82.257233, 63.659161],
                [-82.285553, 63.678051],
                [-82.328888, 63.650269],
                [-82.472229, 63.680271],
                [-82.546951, 63.764721],
                [-82.376389, 63.817501],
                [-82.361389, 63.90527],
                [-82.548889, 63.969151],
                [-82.873322, 63.982769],
                [-83.086937, 63.95499],
                [-83.145279, 64.001099],
                [-82.96167, 64.145538],
                [-83.01973, 64.188873],
                [-83.14711, 64.166634],
                [-83.191101, 64.118317],
                [-83.328888, 64.136932],
                [-83.394997, 64.104156],
                [-83.46611, 64.123596],
                [-83.54834, 64.102478],
                [-83.68222, 64.007217],
                [-83.604172, 63.928051],
                [-83.646957, 63.86721],
                [-83.59584, 63.825829],
                [-83.637222, 63.766392],
                [-83.750839, 63.776661],
                [-84.099731, 63.60527],
                [-84.282784, 63.628601],
                [-84.400558, 63.548611],
                [-84.477493, 63.38361],
                [-84.586121, 63.308041],
                [-85.224167, 63.120831],
                [-85.493332, 63.119991],
                [-85.589172, 63.174709],
                [-85.638062, 63.244999],
                [-85.653877, 63.408878],
                [-85.607224, 63.494438],
                [-85.628052, 63.55666],
                [-85.59111, 63.617489],
                [-85.613327, 63.66972],
                [-85.714722, 63.706661],
                [-85.710831, 63.76749],
                [-85.813049, 63.703609],
                [-86.245003, 63.639431],
                [-86.596123, 63.668598],
                [-86.879173, 63.55555],
                [-87.118607, 63.55582],
                [-87.217216, 63.622219],
                [-87.186661, 63.72221],
                [-86.934433, 63.906101],
                [-86.189438, 64.101646],
                [-86.212219, 64.178589],
                [-86.308884, 64.226379],
                [-86.291672, 64.256653],
                [-86.354721, 64.289978],
                [-86.384171, 64.364433],
                [-86.344452, 64.401093],
                [-86.400284, 64.431931],
                [-86.376389, 64.550537],
                [-86.410278, 64.582764]
              ]
            ],
            [
              [
                [-94.341667, 81.109711],
                [-93.516663, 81.065262],
                [-93.123322, 81.115257],
                [-93.179169, 81.135536],
                [-93.09584, 81.165268],
                [-93.243057, 81.19165],
                [-93.222778, 81.210823],
                [-93.928879, 81.203873],
                [-94.381668, 81.250816],
                [-94.37027, 81.284714],
                [-94.245537, 81.319443],
                [-94.27861, 81.341927],
                [-94.153877, 81.359711],
                [-93.754997, 81.344711],
                [-93.568336, 81.302757],
                [-93.483063, 81.319717],
                [-93.560547, 81.367752],
                [-93.517502, 81.384987],
                [-92.148064, 81.236366],
                [-91.800552, 81.15332],
                [-91.842216, 81.123871],
                [-91.78389, 81.083603],
                [-91.908623, 81.070541],
                [-91.538887, 80.981659],
                [-91.517776, 80.932747],
                [-91.144173, 80.814987],
                [-91.154999, 80.780548],
                [-91.121658, 80.754707],
                [-90.664719, 80.684708],
                [-90.593063, 80.640823],
                [-90.766403, 80.565536],
                [-90, 80.534592],
                [-89.751106, 80.464432],
                [-89.825844, 80.5186],
                [-89.783623, 80.532211],
                [-89.546112, 80.547493],
                [-89.245003, 80.517212],
                [-89.059158, 80.46138],
                [-89.257233, 80.396942],
                [-89.075844, 80.393051],
                [-89.131943, 80.359421],
                [-89.114166, 80.333328],
                [-89.256668, 80.298599],
                [-89.251404, 80.27832],
                [-89.072777, 80.195534],
                [-88.534729, 80.098877],
                [-88.414719, 80.10498],
                [-88.483322, 80.135818],
                [-88.421944, 80.142487],
                [-88.145554, 80.093872],
                [-88.425003, 80.186096],
                [-88.27417, 80.171921],
                [-88.273064, 80.195534],
                [-88.506958, 80.218323],
                [-88.704178, 80.284149],
                [-88.644997, 80.321663],
                [-88.685272, 80.371643],
                [-88.648903, 80.3936],
                [-88.383904, 80.443588],
                [-87.683884, 80.410263],
                [-87.607498, 80.324158],
                [-87.618332, 80.272774],
                [-87.563889, 80.233322],
                [-87.562767, 80.179153],
                [-88.065552, 80.120819],
                [-87.891388, 80.055542],
                [-87.275558, 80.06694],
                [-87.036942, 79.962486],
                [-87.007233, 79.946091],
                [-87.031952, 79.937477],
                [-87.489441, 79.84137],
                [-87.338608, 79.84082],
                [-87.024719, 79.916092],
                [-86.957779, 79.903587],
                [-87.058327, 79.804703],
                [-87.055557, 79.731934],
                [-87.134171, 79.645264],
                [-87.441673, 79.573883],
                [-87.462509, 79.534714],
                [-87.44194, 79.526382],
                [-87.309998, 79.502213],
                [-87.161118, 79.571663],
                [-86.96666, 79.601646],
                [-86.823624, 79.587769],
                [-86.84639, 79.549988],
                [-86.789993, 79.538879],
                [-86.694992, 79.56749],
                [-86.813049, 79.611923],
                [-86.762222, 79.631653],
                [-86.334167, 79.645538],
                [-86.046112, 79.568878],
                [-86.039719, 79.545532],
                [-86.137512, 79.51915],
                [-86.028061, 79.474701],
                [-86.167503, 79.457764],
                [-86.070847, 79.434143],
                [-85.901108, 79.493591],
                [-85.893341, 79.554703],
                [-85.828339, 79.607483],
                [-85.681953, 79.613312],
                [-85.531113, 79.559418],
                [-85.54361, 79.538589],
                [-85.390839, 79.449142],
                [-85.039719, 79.350807],
                [-84.904167, 79.267761],
                [-85.297234, 79.187187],
                [-86.422501, 79.075546],
                [-86.550827, 79.048866],
                [-86.587784, 78.983597],
                [-86.702217, 78.955261],
                [-86.785004, 78.957207],
                [-86.983322, 79.056641],
                [-87.004463, 78.987198],
                [-86.952499, 78.906647],
                [-87.328056, 78.794708],
                [-87.615829, 78.645264],
                [-87.872772, 78.694977],
                [-87.953613, 78.74971],
                [-88.003342, 78.807213],
                [-87.946663, 78.909149],
                [-87.970001, 78.965553],
                [-87.794449, 79.006943],
                [-87.833618, 79.026093],
                [-87.741669, 79.059982],
                [-87.746109, 79.086113],
                [-87.899986, 79.011108],
                [-88.202499, 78.976379],
                [-88.188049, 78.885818],
                [-88.229721, 78.8022],
                [-88.13028, 78.684708],
                [-88.351936, 78.65332],
                [-88.02417, 78.656647],
                [-87.89473, 78.584717],
                [-87.90834, 78.548599],
                [-88.011948, 78.481369],
                [-88.234161, 78.453598],
                [-88.559433, 78.604156],
                [-88.804169, 78.609711],
                [-88.755569, 78.589981],
                [-88.773331, 78.573608],
                [-88.746109, 78.535812],
                [-88.535553, 78.41304],
                [-88.672501, 78.316093],
                [-88.751404, 78.19693],
                [-88.81778, 78.154427],
                [-89.115829, 78.200821],
                [-89.353882, 78.339706],
                [-89.658051, 78.431091],
                [-90, 78.609543],
                [-90.088333, 78.595833],
                [-90.10083, 78.549713],
                [-90, 78.466957],
                [-89.985001, 78.436096],
                [-89.748047, 78.380257],
                [-89.452499, 78.162491],
                [-89.618607, 78.16304],
                [-89.673889, 78.217209],
                [-89.911118, 78.218872],
                [-90, 78.284409],
                [-90.186111, 78.333328],
                [-90.744453, 78.323036],
                [-90.325287, 78.271652],
                [-90.375549, 78.240807],
                [-90.272781, 78.176933],
                [-90.465012, 78.135269],
                [-91.489723, 78.176933],
                [-91.857498, 78.2397],
                [-92.083328, 78.212486],
                [-92.190277, 78.278053],
                [-92.323898, 78.287201],
                [-92.181953, 78.319717],
                [-92.537216, 78.310532],
                [-92.609444, 78.330833],
                [-92.613617, 78.357208],
                [-92.919724, 78.418869],
                [-92.982498, 78.454437],
                [-92.978882, 78.483322],
                [-91.848343, 78.546097],
                [-91.660828, 78.526932],
                [-91.632492, 78.539429],
                [-91.657501, 78.563873],
                [-92.585564, 78.5961],
                [-92.806107, 78.633614],
                [-93.271118, 78.584152],
                [-93.4664, 78.645828],
                [-93.35611, 78.679428],
                [-93.555557, 78.680817],
                [-93.813606, 78.765823],
                [-93.589722, 78.7836],
                [-93.16333, 78.735527],
                [-93.054993, 78.739967],
                [-93.037781, 78.765823],
                [-93.416397, 78.824997],
                [-93.87471, 78.833603],
                [-94.282227, 78.968872],
                [-93.910278, 79.041931],
                [-93.608337, 79.051651],
                [-93.472778, 79.108871],
                [-93.460564, 79.142761],
                [-93.329727, 79.164429],
                [-92.86972, 79.139709],
                [-92.746948, 79.164146],
                [-92.309433, 79.145264],
                [-91.628098, 79.178452],
                [-90.564163, 79.215553],
                [-90.363274, 79.246811],
                [-92.180832, 79.203049],
                [-92.446663, 79.223038],
                [-92.694717, 79.257217],
                [-92.603882, 79.300812],
                [-91.932503, 79.297211],
                [-91.146667, 79.356369],
                [-91.129173, 79.390823],
                [-92.181107, 79.345833],
                [-92.571671, 79.370529],
                [-92.243881, 79.426651],
                [-92.259171, 79.44693],
                [-92.580841, 79.452209],
                [-92.876099, 79.407761],
                [-93.090561, 79.482208],
                [-93.144447, 79.463882],
                [-93.021393, 79.382751],
                [-93.374161, 79.336929],
                [-93.229721, 79.425537],
                [-93.338058, 79.447197],
                [-93.485283, 79.354156],
                [-93.665833, 79.360527],
                [-93.683884, 79.339432],
                [-93.628601, 79.31694],
                [-93.870003, 79.263893],
                [-94.212219, 79.276657],
                [-94.161942, 79.286377],
                [-94.167221, 79.31694],
                [-94.11972, 79.344711],
                [-93.838608, 79.392761],
                [-94.027222, 79.377472],
                [-94.386124, 79.421371],
                [-94.509171, 79.416382],
                [-94.5, 79.3797],
                [-94.389999, 79.368874],
                [-94.506668, 79.337196],
                [-95.087547, 79.270752],
                [-95.235283, 79.286102],
                [-95.191383, 79.309143],
                [-95.318619, 79.332207],
                [-95.163887, 79.344711],
                [-95.285004, 79.353043],
                [-95.308884, 79.390823],
                [-95.281952, 79.399719],
                [-95.444153, 79.372757],
                [-95.771667, 79.409714],
                [-95.748611, 79.424698],
                [-95.777222, 79.430267],
                [-95.671661, 79.439423],
                [-95.778343, 79.474701],
                [-95.70639, 79.501099],
                [-95.736389, 79.537491],
                [-94.699432, 79.612198],
                [-94.329453, 79.688309],
                [-94.357773, 79.726929],
                [-94.282784, 79.757492],
                [-94.318336, 79.778587],
                [-94.814438, 79.670258],
                [-95.853333, 79.646103],
                [-96.149437, 79.739151],
                [-96.238052, 79.736923],
                [-96.253067, 79.783333],
                [-96.335564, 79.815536],
                [-96.490547, 79.836113],
                [-96.391953, 79.863037],
                [-96.589447, 79.852478],
                [-96.609444, 79.888603],
                [-96.458618, 79.914429],
                [-96.391388, 79.913879],
                [-96.381668, 79.881653],
                [-96.138062, 79.906372],
                [-96.606659, 79.962196],
                [-96.679443, 80.014427],
                [-96.391678, 80.045822],
                [-96.676102, 80.041931],
                [-96.80278, 80.09082],
                [-96.711121, 80.144989],
                [-96.410004, 80.138893],
                [-95.847778, 80.053307],
                [-94.852783, 80.044144],
                [-94.416946, 79.978867],
                [-94.711533, 80.070663],
                [-94.803879, 80.092758],
                [-94.611938, 80.135536],
                [-94.120003, 80.183037],
                [-94.596947, 80.224152],
                [-94.816963, 80.159714],
                [-95.367767, 80.118317],
                [-95.695831, 80.178307],
                [-95.295837, 80.212769],
                [-95.243332, 80.243591],
                [-95.496948, 80.219437],
                [-95.645844, 80.2397],
                [-95.933609, 80.194427],
                [-96.566391, 80.272217],
                [-96.47084, 80.32222],
                [-96.681671, 80.342209],
                [-96.592216, 80.362762],
                [-96.232224, 80.334717],
                [-96.268066, 80.367477],
                [-96.047783, 80.389977],
                [-95.440826, 80.338318],
                [-95.468063, 80.382202],
                [-96.098343, 80.488037],
                [-95.933884, 80.508881],
                [-95.904716, 80.540543],
                [-96.027222, 80.574158],
                [-95.980003, 80.584717],
                [-94.995003, 80.603043],
                [-94.752792, 80.559982],
                [-93.990547, 80.554703],
                [-93.958344, 80.526093],
                [-93.786392, 80.525543],
                [-94.004997, 80.585274],
                [-94.543327, 80.599716],
                [-94.660278, 80.651382],
                [-94.662216, 80.675262],
                [-94.596947, 80.690536],
                [-94.199722, 80.693039],
                [-94.088333, 80.70166],
                [-94.108337, 80.718872],
                [-94.895554, 80.747757],
                [-95.037514, 80.771378],
                [-94.857773, 80.801376],
                [-94.888611, 80.814423],
                [-95.282501, 80.786102],
                [-95.534157, 80.818878],
                [-95.150833, 80.881088],
                [-95.484734, 80.899147],
                [-95.283623, 80.949997],
                [-95.241096, 81.006104],
                [-94.904999, 81.05748],
                [-94.572777, 81.038879],
                [-94.457779, 81.012207],
                [-94.508057, 80.979431],
                [-94.434158, 80.965553],
                [-94.298607, 81.008331],
                [-93.936394, 81.017761],
                [-94.070557, 81.041656],
                [-93.906387, 81.040543],
                [-94.14917, 81.056641],
                [-94.341667, 81.109711]
              ]
            ],
            [
              [
                [-101.086098, 73.325699],
                [-101.305, 73.361649],
                [-101.3172, 73.401657],
                [-101.580803, 73.450272],
                [-101.546097, 73.459991],
                [-101.621399, 73.490257],
                [-101.523903, 73.489433],
                [-101.427498, 73.5522],
                [-101.2808, 73.552467],
                [-101.217201, 73.604156],
                [-100.890297, 73.5961],
                [-100.672798, 73.464432],
                [-100.499702, 73.412491],
                [-100.415001, 73.418053],
                [-100.606102, 73.497208],
                [-100.5439, 73.556374],
                [-100.551697, 73.598877],
                [-100.9114, 73.622757],
                [-100.858002, 73.667213],
                [-101.046997, 73.673027],
                [-101.057198, 73.69664],
                [-101.014999, 73.712769],
                [-101.118301, 73.723312],
                [-100.981903, 73.763611],
                [-101.027199, 73.775269],
                [-101.013901, 73.797211],
                [-100.886398, 73.827477],
                [-100.754997, 73.812477],
                [-100.648399, 73.848328],
                [-100.417801, 73.845543],
                [-100.136902, 73.795532],
                [-100.026703, 73.729713],
                [-100.027199, 73.763046],
                [-99.955566, 73.813873],
                [-99.857773, 73.842758],
                [-99.843063, 73.878311],
                [-99.871384, 73.898331],
                [-99.892776, 73.891373],
                [-99.873611, 73.866928],
                [-99.948608, 73.87886],
                [-100.0494, 73.832764],
                [-100.249397, 73.833878],
                [-100.292198, 73.872208],
                [-100.142502, 73.931091],
                [-99.85611, 73.940811],
                [-99.800827, 73.931664],
                [-99.836121, 73.901093],
                [-99.785278, 73.867203],
                [-99.398064, 73.798866],
                [-99.206673, 73.725807],
                [-99.247772, 73.696091],
                [-99.189163, 73.685257],
                [-99.115013, 73.748596],
                [-98.789719, 73.734993],
                [-98.688316, 73.772018],
                [-98.134171, 73.809708],
                [-97.960281, 73.846939],
                [-97.900284, 73.896103],
                [-97.734444, 73.905548],
                [-97.825012, 73.858871],
                [-97.602493, 73.894989],
                [-97.47139, 73.857758],
                [-97.223618, 73.856369],
                [-96.962219, 73.738586],
                [-96.934723, 73.68692],
                [-96.968613, 73.633331],
                [-97.202499, 73.557213],
                [-97.433884, 73.581383],
                [-97.459732, 73.573883],
                [-97.432503, 73.531097],
                [-97.459732, 73.515823],
                [-97.638062, 73.538589],
                [-97.668327, 73.483322],
                [-97.623322, 73.456383],
                [-97.417221, 73.493317],
                [-97.18306, 73.464996],
                [-97.17305, 73.443588],
                [-97.248894, 73.429153],
                [-97.167503, 73.410812],
                [-97.171944, 73.352768],
                [-97.267227, 73.36499],
                [-97.841377, 73.273323],
                [-97.862503, 73.233871],
                [-98.240829, 73.075272],
                [-98.510277, 73.00943],
                [-98.44249, 72.981659],
                [-98.473618, 72.95665],
                [-98.42749, 72.943588],
                [-98.445831, 72.865807],
                [-98.227219, 72.987488],
                [-97.846947, 73.048599],
                [-97.283333, 72.963882],
                [-97.225014, 72.939972],
                [-97.253891, 72.931664],
                [-97.258057, 72.883614],
                [-97.415283, 72.86026],
                [-97.236389, 72.842484],
                [-97.161118, 72.780548],
                [-97.053047, 72.757492],
                [-97.029716, 72.71666],
                [-97.170547, 72.673599],
                [-97.196663, 72.604431],
                [-97.081009, 72.605927],
                [-96.915558, 72.678589],
                [-96.536667, 72.750267],
                [-96.517502, 72.714706],
                [-96.585007, 72.693863],
                [-96.455276, 72.640549],
                [-96.459732, 72.607758],
                [-96.405273, 72.559418],
                [-96.438599, 72.534714],
                [-96.37471, 72.534416],
                [-96.325562, 72.488312],
                [-96.29834, 72.415817],
                [-96.39917, 72.407211],
                [-96.446114, 72.369141],
                [-96.668327, 72.309708],
                [-96.871933, 72.321114],
                [-96.561111, 72.275543],
                [-96.614723, 72.245819],
                [-96.533333, 72.236923],
                [-96.485001, 72.129967],
                [-96.498337, 72.090271],
                [-96.866943, 72.041092],
                [-96.672501, 72.012772],
                [-96.488602, 72.034988],
                [-96.522507, 71.959152],
                [-96.76445, 71.914993],
                [-96.491669, 71.926086],
                [-96.570847, 71.819443],
                [-96.74472, 71.792213],
                [-96.738052, 71.824997],
                [-96.67778, 71.837486],
                [-96.775284, 71.843597],
                [-96.978058, 71.795258],
                [-97.084167, 71.700272],
                [-97.504997, 71.611649],
                [-97.988052, 71.661926],
                [-98.207497, 71.646103],
                [-98.359734, 71.728043],
                [-98.321404, 71.803307],
                [-98.209167, 71.88916],
                [-98.267227, 71.90416],
                [-98.291382, 71.894707],
                [-98.262787, 71.866653],
                [-98.338608, 71.85498],
                [-98.462509, 71.783867],
                [-98.493881, 71.713882],
                [-98.391113, 71.707489],
                [-98.410278, 71.688873],
                [-98.381378, 71.653587],
                [-98.18277, 71.583878],
                [-98.148621, 71.538879],
                [-98.037514, 71.526657],
                [-98.198036, 71.414703],
                [-98.46611, 71.313309],
                [-98.729721, 71.270538],
                [-98.829453, 71.293869],
                [-98.960281, 71.379967],
                [-99.238052, 71.344994],
                [-99.313606, 71.470543],
                [-99.410278, 71.53804],
                [-99.369164, 71.575546],
                [-99.574173, 71.619713],
                [-99.678329, 71.760536],
                [-99.788597, 71.786102],
                [-99.872498, 71.858032],
                [-100.101898, 71.88472],
                [-100.443604, 72.057213],
                [-100.423302, 72.096367],
                [-100.481697, 72.086113],
                [-100.513298, 72.108032],
                [-100.490799, 72.126923],
                [-100.634499, 72.185532],
                [-100.8853, 72.207764],
                [-100.951401, 72.171097],
                [-101.0214, 72.202209],
                [-101.055603, 72.236649],
                [-101.019203, 72.254707],
                [-101.083298, 72.258614],
                [-101.037498, 72.276382],
                [-101.220596, 72.332207],
                [-101.395599, 72.286926],
                [-101.402496, 72.244141],
                [-101.495003, 72.244431],
                [-101.469498, 72.265549],
                [-101.585602, 72.301376],
                [-101.776901, 72.299713],
                [-101.890297, 72.362488],
                [-101.879997, 72.399147],
                [-101.775803, 72.389427],
                [-101.916397, 72.406097],
                [-101.963303, 72.463318],
                [-101.911697, 72.472214],
                [-102.597801, 72.648331],
                [-102.741699, 72.724152],
                [-102.714996, 72.745819],
                [-102.7631, 72.790817],
                [-102.646698, 72.864433],
                [-102.576698, 72.979713],
                [-102.501099, 73.030548],
                [-102.2761, 73.082489],
                [-101.962196, 73.089157],
                [-101.9683, 73.049988],
                [-101.768898, 73.021103],
                [-101.7911, 72.98082],
                [-101.732803, 72.967209],
                [-101.817802, 72.96666],
                [-101.741096, 72.924149],
                [-101.618103, 72.886383],
                [-101.618896, 72.909988],
                [-101.5411, 72.921921],
                [-101.595299, 72.902206],
                [-101.509697, 72.871643],
                [-101.4506, 72.816383],
                [-101.466698, 72.800262],
                [-101.294403, 72.789978],
                [-101.296402, 72.74498],
                [-101.4133, 72.748322],
                [-101.366699, 72.725273],
                [-100.882202, 72.689697],
                [-100.812202, 72.715553],
                [-100.870796, 72.735809],
                [-100.828102, 72.758614],
                [-100.447998, 72.735527],
                [-100.331703, 72.780548],
                [-100.324402, 72.838882],
                [-100.416702, 72.869141],
                [-100.387199, 72.897217],
                [-100.442497, 72.921921],
                [-100.3881, 72.944977],
                [-100.498901, 72.950546],
                [-100.421898, 73.034988],
                [-100.309402, 73.028053],
                [-100.407799, 73.011383],
                [-100.381104, 73],
                [-100.419998, 72.974152],
                [-100.381401, 72.949142],
                [-100.292801, 72.931931],
                [-100.296997, 72.958603],
                [-100.2556, 72.953873],
                [-100.261101, 72.921097],
                [-100.328598, 72.904984],
                [-100.326103, 72.878036],
                [-100.077499, 72.886932],
                [-100.059402, 72.896942],
                [-100.086899, 72.920258],
                [-100.031403, 72.934982],
                [-100.176399, 72.987762],
                [-100.1064, 73.000816],
                [-100.140602, 73.048027],
                [-100.220596, 73.04248],
                [-100.1772, 73.083878],
                [-100.323898, 73.093597],
                [-100.232201, 73.13443],
                [-100.523903, 73.089706],
                [-100.518303, 73.120247],
                [-100.607803, 73.146378],
                [-100.472801, 73.193314],
                [-100.544701, 73.228317],
                [-100.361099, 73.290268],
                [-100.196701, 73.233322],
                [-100.107803, 73.241364],
                [-100.134697, 73.2211],
                [-100.037498, 73.183868],
                [-99.771667, 73.208038],
                [-100.077499, 73.251389],
                [-100.373901, 73.395828],
                [-100.4058, 73.361366],
                [-100.336098, 73.324997],
                [-100.583603, 73.2836],
                [-100.840599, 73.25972],
                [-101.086098, 73.325699]
              ]
            ],
            [
              [
                [-95.60054, 73.283913],
                [-95.590843, 73.304428],
                [-95.650833, 73.325272],
                [-95.60083, 73.381653],
                [-95.683884, 73.450272],
                [-95.626099, 73.500549],
                [-95.652496, 73.511383],
                [-95.632767, 73.527771],
                [-95.700287, 73.553864],
                [-95.631668, 73.588318],
                [-95.541107, 73.576103],
                [-95.723328, 73.639977],
                [-95.676102, 73.664993],
                [-95.716949, 73.689423],
                [-95.656387, 73.699417],
                [-95.681953, 73.711929],
                [-95.65889, 73.732483],
                [-95.299728, 73.771103],
                [-94.956673, 73.659149],
                [-94.618057, 73.651382],
                [-94.866096, 73.687187],
                [-95.075562, 73.773323],
                [-95.116096, 73.806641],
                [-94.982498, 73.845543],
                [-95.154167, 73.823608],
                [-95.304169, 73.880814],
                [-95.324722, 73.952477],
                [-95.24527, 74.010269],
                [-94.732224, 74.095261],
                [-94.17778, 74.133614],
                [-93.914169, 74.136108],
                [-93.765007, 74.075821],
                [-93.721939, 74.083054],
                [-93.758347, 74.096939],
                [-93.730827, 74.15416],
                [-93.515007, 74.173027],
                [-92.796387, 74.124977],
                [-92.748894, 74.113312],
                [-92.761124, 74.080551],
                [-92.711937, 74.080551],
                [-92.638062, 74.103043],
                [-92.334442, 74.031372],
                [-92.273621, 73.99054],
                [-92.327789, 73.951393],
                [-92.309433, 73.940811],
                [-92.132492, 73.946373],
                [-92.094162, 73.989151],
                [-91.925552, 74.012772],
                [-91.570847, 74.025818],
                [-91.065002, 74.006104],
                [-90.406662, 73.914703],
                [-90.31778, 73.925262],
                [-90.204178, 73.888321],
                [-90.275284, 73.838593],
                [-90.316963, 73.871368],
                [-90.376663, 73.85582],
                [-90.372498, 73.789703],
                [-90.765007, 73.562187],
                [-90.989723, 73.553307],
                [-90.930283, 73.483871],
                [-91.180557, 73.345833],
                [-91.262222, 73.263893],
                [-91.634453, 73.228867],
                [-91.367767, 73.200821],
                [-91.642776, 73.021103],
                [-91.646118, 72.97998],
                [-91.818893, 72.862198],
                [-92.066101, 72.752487],
                [-92.393341, 72.707207],
                [-93.222778, 72.780823],
                [-93.311394, 72.814697],
                [-93.603058, 72.774986],
                [-94.29805, 72.770264],
                [-94.334442, 72.738037],
                [-94.3125, 72.715553],
                [-94.142227, 72.734993],
                [-93.902496, 72.705833],
                [-93.775558, 72.734993],
                [-93.81723, 72.642212],
                [-93.568619, 72.570831],
                [-93.463333, 72.462196],
                [-93.628052, 72.341927],
                [-93.819733, 72.307213],
                [-94.014183, 72.163879],
                [-94.080841, 72.06694],
                [-94.228333, 72.039978],
                [-94.153061, 72.018883],
                [-94.022232, 72.059418],
                [-94.069733, 72.024147],
                [-94.029716, 71.99942],
                [-94.063049, 71.978317],
                [-94.449722, 72.023323],
                [-95.175827, 71.966927],
                [-95.10527, 71.99498],
                [-95.207779, 71.988876],
                [-95.196381, 72.052467],
                [-95.083618, 72.097763],
                [-95.196663, 72.106644],
                [-94.752228, 72.15332],
                [-95.171112, 72.13916],
                [-95.161667, 72.162773],
                [-95.214722, 72.205261],
                [-95.159729, 72.350807],
                [-95.236107, 72.428589],
                [-95.145279, 72.431374],
                [-95.196663, 72.450821],
                [-95.133331, 72.460274],
                [-95.200287, 72.524429],
                [-95.316101, 72.539703],
                [-95.346657, 72.587486],
                [-95.313316, 72.601089],
                [-95.364441, 72.643333],
                [-95.602219, 72.702209],
                [-95.599731, 72.73526],
                [-95.513062, 72.736923],
                [-95.570007, 72.765823],
                [-95.55722, 72.785538],
                [-95.666107, 72.801376],
                [-95.656387, 72.901093],
                [-95.622772, 72.927467],
                [-95.718613, 72.999153],
                [-95.655563, 73.019989],
                [-95.642227, 73.053307],
                [-95.688316, 73.080551],
                [-95.657501, 73.112198],
                [-95.5, 73.128593],
                [-95.579453, 73.159416],
                [-95.596947, 73.219437],
                [-95.557503, 73.23082],
                [-95.60054, 73.283913]
              ]
            ],
            [
              [
                [-99.45462, 75.974808],
                [-99.863327, 75.935532],
                [-99.905563, 75.9561],
                [-99.87471, 75.983871],
                [-100.035301, 76.006943],
                [-99.995003, 76.034149],
                [-100.077499, 76.038879],
                [-100.1119, 76.101379],
                [-100.220596, 76.132202],
                [-100.031097, 76.155548],
                [-99.714172, 76.114433],
                [-99.414436, 76.158333],
                [-99.716949, 76.139709],
                [-100.001099, 76.201927],
                [-100.438904, 76.212486],
                [-100.512802, 76.249153],
                [-100.464996, 76.274986],
                [-100.074699, 76.271927],
                [-100.203598, 76.298866],
                [-100.186401, 76.313873],
                [-100.029198, 76.27916],
                [-99.848618, 76.283867],
                [-100.0233, 76.295822],
                [-99.92305, 76.317757],
                [-100.153099, 76.349152],
                [-100.179398, 76.360809],
                [-100.147202, 76.380257],
                [-100.694397, 76.374977],
                [-100.982498, 76.50499],
                [-100.7117, 76.532211],
                [-100.723297, 76.556091],
                [-100.653099, 76.576393],
                [-100.318901, 76.635536],
                [-99.837784, 76.608597],
                [-99.684433, 76.633331],
                [-99.551392, 76.609154],
                [-99.676941, 76.59166],
                [-99.522781, 76.588318],
                [-99.291672, 76.500267],
                [-99.143623, 76.501663],
                [-99.25528, 76.453598],
                [-99.079727, 76.397217],
                [-99.122498, 76.461113],
                [-98.980827, 76.471649],
                [-98.925827, 76.436096],
                [-98.846123, 76.436096],
                [-98.889183, 76.446373],
                [-98.86528, 76.469147],
                [-99.050552, 76.536102],
                [-99.027222, 76.601089],
                [-98.814713, 76.613602],
                [-98.743057, 76.579987],
                [-98.725037, 76.611542],
                [-98.538887, 76.616379],
                [-98.509453, 76.630539],
                [-98.537216, 76.641937],
                [-98.483063, 76.649147],
                [-98.744453, 76.643883],
                [-98.852493, 76.671654],
                [-98.480293, 76.679153],
                [-98.403877, 76.661377],
                [-98.419724, 76.622482],
                [-98.151672, 76.585823],
                [-98.395554, 76.594437],
                [-98.357773, 76.565536],
                [-97.662781, 76.481087],
                [-97.695541, 76.460823],
                [-97.662514, 76.419434],
                [-97.727219, 76.395538],
                [-97.783623, 76.319717],
                [-97.509171, 76.188873],
                [-97.494453, 76.138893],
                [-97.588333, 76.080833],
                [-97.58139, 76.042213],
                [-97.656387, 75.972763],
                [-97.558037, 75.864433],
                [-97.664169, 75.79332],
                [-97.938599, 75.741364],
                [-97.386673, 75.682747],
                [-97.369164, 75.65332],
                [-97.411942, 75.61554],
                [-97.422501, 75.506378],
                [-97.378326, 75.434143],
                [-97.280838, 75.396942],
                [-97.469162, 75.42276],
                [-97.496109, 75.4422],
                [-97.487503, 75.496094],
                [-97.711937, 75.566673],
                [-97.762512, 75.568047],
                [-97.752792, 75.53804],
                [-97.841949, 75.550812],
                [-97.856949, 75.535538],
                [-97.789993, 75.518883],
                [-97.74527, 75.46666],
                [-97.835831, 75.460823],
                [-97.847504, 75.502213],
                [-97.932503, 75.512497],
                [-98.043327, 75.481369],
                [-97.949722, 75.461113],
                [-98.055557, 75.452477],
                [-97.774719, 75.423027],
                [-98.036942, 75.412201],
                [-97.876663, 75.36499],
                [-98.069168, 75.349716],
                [-98.107773, 75.371918],
                [-98.164719, 75.329163],
                [-98.080566, 75.329987],
                [-98.129173, 75.301933],
                [-98.04805, 75.284714],
                [-98.006119, 75.319717],
                [-97.894173, 75.329437],
                [-97.995827, 75.27887],
                [-97.913063, 75.26416],
                [-97.922501, 75.295258],
                [-97.887512, 75.300537],
                [-97.873894, 75.270264],
                [-97.743607, 75.224991],
                [-97.736938, 75.204437],
                [-97.800278, 75.193863],
                [-97.69249, 75.193863],
                [-97.654167, 75.179703],
                [-97.676941, 75.164146],
                [-97.567497, 75.147491],
                [-97.619453, 75.118591],
                [-97.862503, 75.111099],
                [-98.024719, 75.162491],
                [-98.025833, 75.210823],
                [-98.098618, 75.222763],
                [-98.07251, 75.199142],
                [-98.146667, 75.163879],
                [-98.090652, 75.137299],
                [-97.966949, 75.104156],
                [-97.938599, 75.064987],
                [-97.949997, 75.021103],
                [-97.982498, 75.015266],
                [-98.748337, 74.989967],
                [-98.84584, 75.00972],
                [-98.859161, 75.061653],
                [-98.960281, 75.051933],
                [-98.928047, 75.012207],
                [-98.956123, 75.003601],
                [-99.376663, 74.985527],
                [-99.406113, 74.993317],
                [-99.37471, 75.007767],
                [-99.387512, 75.037773],
                [-99.281387, 75.122482],
                [-99.496658, 75.026382],
                [-99.426392, 74.991364],
                [-99.538597, 74.974152],
                [-100.347801, 75.016937],
                [-100.393898, 75.037201],
                [-100.378098, 75.113312],
                [-100.430801, 75.147217],
                [-100.398102, 75.1586],
                [-100.538597, 75.204712],
                [-99.987778, 75.236099],
                [-100.393898, 75.260269],
                [-100.257202, 75.313309],
                [-100.523102, 75.293053],
                [-100.7789, 75.35054],
                [-100.4328, 75.375259],
                [-100.6978, 75.436371],
                [-100.011902, 75.46138],
                [-100.306999, 75.471649],
                [-99.951111, 75.492477],
                [-100.283096, 75.514999],
                [-100.155403, 75.52359],
                [-99.845001, 75.540817],
                [-100.039398, 75.554153],
                [-99.712509, 75.589157],
                [-99.66777, 75.611099],
                [-99.863052, 75.614433],
                [-99.809433, 75.635536],
                [-99.817497, 75.655258],
                [-99.033073, 75.6772],
                [-98.889999, 75.695251],
                [-98.971939, 75.710274],
                [-100.559196, 75.647217],
                [-101.365501, 75.574158],
                [-101.377197, 75.598877],
                [-101.495499, 75.601929],
                [-102.668297, 75.497208],
                [-102.671898, 75.52832],
                [-102.7789, 75.544434],
                [-102.728897, 75.572487],
                [-102.8778, 75.624687],
                [-102.632202, 75.6147],
                [-102.701401, 75.62886],
                [-102.666397, 75.648613],
                [-102.689201, 75.670532],
                [-102.531403, 75.691093],
                [-102.583099, 75.709991],
                [-102.538902, 75.721367],
                [-102.009201, 75.703049],
                [-102.236702, 75.744141],
                [-102.093597, 75.791092],
                [-102.366096, 75.789978],
                [-102.290298, 75.857208],
                [-101.864403, 75.902206],
                [-101.459702, 75.810806],
                [-101.459702, 75.761108],
                [-101.254997, 75.744713],
                [-100.911102, 75.811653],
                [-101.347801, 75.774986],
                [-101.323601, 75.80748],
                [-101.363899, 75.847763],
                [-101.537201, 75.861366],
                [-101.597801, 75.919708],
                [-101.302803, 76.013046],
                [-101.678299, 75.979713],
                [-101.8489, 76.024696],
                [-101.907204, 76.078598],
                [-101.888603, 76.119141],
                [-101.761101, 76.174149],
                [-101.385002, 76.248871],
                [-102.133102, 76.219994],
                [-102.165802, 76.238312],
                [-102.063904, 76.255257],
                [-102.059402, 76.27916],
                [-102.194397, 76.289429],
                [-102.041397, 76.318604],
                [-102.097198, 76.34166],
                [-102.064697, 76.358322],
                [-101.957497, 76.357483],
                [-102.050797, 76.398041],
                [-101.862198, 76.450272],
                [-101.315804, 76.414429],
                [-101.058296, 76.326927],
                [-101.140602, 76.240257],
                [-100.751099, 76.182213],
                [-100.625504, 76.127472],
                [-100.727203, 76.090553],
                [-100.417503, 76.116379],
                [-100.621101, 76.079712],
                [-100.315804, 76.051376],
                [-100.171898, 75.959717],
                [-100.071098, 75.945534],
                [-100.145302, 75.908043],
                [-99.983612, 75.919434],
                [-99.981949, 75.88472],
                [-99.930283, 75.873596],
                [-99.45462, 75.974808]
              ]
            ],
            [
              [
                [-99.57988, 69.04393],
                [-99.455002, 69.137772],
                [-99.341667, 69.123596],
                [-99.311394, 69.158867],
                [-99.006958, 69.136383],
                [-98.936394, 69.172493],
                [-98.720284, 69.193863],
                [-98.706947, 69.261383],
                [-98.653877, 69.294983],
                [-98.555557, 69.275269],
                [-98.393341, 69.308029],
                [-98.397232, 69.338318],
                [-98.611938, 69.444138],
                [-98.577217, 69.479431],
                [-98.419449, 69.471367],
                [-98.601669, 69.520828],
                [-98.556664, 69.580833],
                [-98.357773, 69.559143],
                [-98.219162, 69.473312],
                [-98.103333, 69.459717],
                [-98.092499, 69.424988],
                [-98.003891, 69.435806],
                [-98.263062, 69.578873],
                [-98.367767, 69.601089],
                [-98.281952, 69.751663],
                [-97.974167, 69.892212],
                [-97.458054, 69.76944],
                [-97.34111, 69.706383],
                [-97.489441, 69.673866],
                [-97.391388, 69.593323],
                [-97.303879, 69.698318],
                [-97.273903, 69.694702],
                [-96.879173, 69.491364],
                [-96.789719, 69.495247],
                [-96.51889, 69.418053],
                [-96.218338, 69.311096],
                [-96.176392, 69.269989],
                [-96.251404, 69.15387],
                [-96.210007, 69.123032],
                [-96.229721, 69.054703],
                [-96.118881, 69.025543],
                [-96.15834, 69.086929],
                [-96.134743, 69.125259],
                [-96.155838, 69.168587],
                [-96.047783, 69.228317],
                [-95.924438, 69.089432],
                [-95.978882, 69.043869],
                [-95.857773, 68.972214],
                [-95.833618, 68.872757],
                [-95.756958, 68.891373],
                [-95.626099, 68.82666],
                [-95.351387, 68.899429],
                [-95.206947, 68.850273],
                [-95.535278, 68.671921],
                [-95.565552, 68.674698],
                [-95.538887, 68.723312],
                [-95.593887, 68.752777],
                [-95.790558, 68.737198],
                [-95.798607, 68.693588],
                [-95.859734, 68.65332],
                [-95.852493, 68.608597],
                [-96.000839, 68.619431],
                [-96.291382, 68.473038],
                [-96.503616, 68.446091],
                [-96.928329, 68.521378],
                [-97.099167, 68.587769],
                [-97.143623, 68.584427],
                [-97.098892, 68.528587],
                [-97.181107, 68.511383],
                [-97.506119, 68.541931],
                [-97.761398, 68.653587],
                [-98.035553, 68.694702],
                [-98.120003, 68.670822],
                [-98.290558, 68.755547],
                [-98.244164, 68.82222],
                [-98.383331, 68.859993],
                [-98.425003, 68.84137],
                [-98.400284, 68.770828],
                [-98.519447, 68.747482],
                [-98.575012, 68.786652],
                [-98.859444, 68.829163],
                [-98.870003, 68.874153],
                [-98.811394, 68.915817],
                [-98.849442, 68.933594],
                [-98.98111, 68.950546],
                [-99.006958, 68.907761],
                [-99.118057, 68.921097],
                [-99.044159, 68.859711],
                [-99.188599, 68.824158],
                [-99.437767, 68.891098],
                [-99.460564, 68.950821],
                [-99.594162, 69.015823],
                [-99.57988, 69.04393]
              ]
            ],
            [
              [
                [-104.042198, 78.620682],
                [-103.826103, 78.671921],
                [-103.483597, 78.669144],
                [-103.518097, 78.710823],
                [-103.318901, 78.740257],
                [-103.468102, 78.787491],
                [-103.581398, 78.743591],
                [-103.616402, 78.767487],
                [-103.796097, 78.735809],
                [-103.695297, 78.793869],
                [-103.726898, 78.8022],
                [-103.886101, 78.804428],
                [-103.905296, 78.768883],
                [-103.991096, 78.758881],
                [-104.198898, 78.770264],
                [-104.215302, 78.793587],
                [-104.167198, 78.816383],
                [-103.821701, 78.898331],
                [-104.203598, 78.991653],
                [-104.265602, 78.988586],
                [-104.293098, 78.954987],
                [-104.455002, 78.9561],
                [-104.564201, 78.8647],
                [-104.785797, 78.806641],
                [-105.011902, 78.803589],
                [-105.012199, 78.844711],
                [-104.870796, 78.88916],
                [-104.681099, 79.016663],
                [-104.937202, 79.05304],
                [-105.395302, 79.011658],
                [-105.590797, 79.034416],
                [-105.628601, 79.161377],
                [-105.541702, 79.239967],
                [-105.420502, 79.284416],
                [-105.482803, 79.306374],
                [-105.440002, 79.329163],
                [-105.127197, 79.297493],
                [-104.007202, 79.367752],
                [-103.722504, 79.356934],
                [-102.8769, 79.233047],
                [-102.926697, 79.211113],
                [-102.891701, 79.166656],
                [-102.611702, 79.093048],
                [-102.665604, 78.982758],
                [-102.773598, 78.944138],
                [-102.583603, 78.948593],
                [-102.573097, 78.929703],
                [-102.626099, 78.900269],
                [-102.579498, 78.873032],
                [-102.391998, 78.931664],
                [-102.380501, 78.962769],
                [-102.417198, 79.009163],
                [-102.268097, 79.029709],
                [-101.9422, 79.084717],
                [-101.648903, 79.075821],
                [-101.231697, 78.959427],
                [-101.1408, 78.974152],
                [-100.986702, 78.937187],
                [-101.2006, 78.820831],
                [-101.186401, 78.802757],
                [-100.925598, 78.763321],
                [-100.351097, 78.828323],
                [-100.323303, 78.778053],
                [-99.896393, 78.695824],
                [-100.0625, 78.638893],
                [-100.016701, 78.616653],
                [-99.853333, 78.633041],
                [-99.748337, 78.597214],
                [-99.550278, 78.590271],
                [-99.529716, 78.578049],
                [-99.551941, 78.56192],
                [-99.651398, 78.544983],
                [-99.597778, 78.530548],
                [-99.670273, 78.479713],
                [-99.867767, 78.437477],
                [-99.748337, 78.383614],
                [-99.793877, 78.297211],
                [-99.529449, 78.282494],
                [-99.413887, 78.205261],
                [-98.945831, 78.061653],
                [-98.971657, 77.997757],
                [-99.099167, 77.960823],
                [-99.013344, 77.888321],
                [-99.179993, 77.84082],
                [-99.822243, 77.814148],
                [-99.906952, 77.778587],
                [-100.607498, 77.859993],
                [-100.604202, 77.890823],
                [-100.838303, 78.022774],
                [-100.747803, 78.077209],
                [-100.998596, 78.131653],
                [-101.035599, 78.196091],
                [-101.289398, 78.18248],
                [-101.474197, 78.234711],
                [-102.133102, 78.282761],
                [-102.618599, 78.241364],
                [-102.809402, 78.288307],
                [-102.807999, 78.31694],
                [-102.665802, 78.358597],
                [-102.777802, 78.376373],
                [-103.68, 78.31192],
                [-103.9058, 78.272774],
                [-103.898903, 78.245819],
                [-103.963097, 78.233597],
                [-104.467499, 78.265266],
                [-104.820602, 78.35582],
                [-104.991898, 78.437759],
                [-105.043098, 78.505829],
                [-104.831398, 78.569992],
                [-104.666702, 78.579712],
                [-103.523598, 78.496094],
                [-103.448898, 78.534714],
                [-103.494499, 78.566673],
                [-103.378098, 78.586113],
                [-103.431999, 78.598602],
                [-103.400002, 78.61554],
                [-104.042198, 78.620682]
              ]
            ],
            [
              [
                [-80.771667, 73.74971],
                [-80.373322, 73.761658],
                [-80.142227, 73.69664],
                [-80.023064, 73.727203],
                [-79.45195, 73.630539],
                [-78.166397, 73.668053],
                [-77.424438, 73.554703],
                [-77.19194, 73.501389],
                [-77.029449, 73.398041],
                [-77.048889, 73.361923],
                [-76.893341, 73.321114],
                [-76.708054, 73.317757],
                [-76.738602, 73.279984],
                [-76.579727, 73.219711],
                [-76.631668, 73.167213],
                [-76.495537, 73.116928],
                [-76.241096, 73.092209],
                [-76.318069, 73.062759],
                [-76.279449, 73.045822],
                [-76.323624, 73.020828],
                [-76.260277, 72.997208],
                [-76.333069, 72.963608],
                [-76.118607, 72.940262],
                [-76.059433, 72.900818],
                [-76.149986, 72.842209],
                [-76.5625, 72.812477],
                [-77.704178, 72.897217],
                [-78.236359, 72.893013],
                [-79.209167, 72.74498],
                [-79.594162, 72.753326],
                [-79.998611, 72.863037],
                [-80.181381, 73.043869],
                [-80.122772, 73.114433],
                [-80.178329, 73.158333],
                [-80.110001, 73.179703],
                [-80.151947, 73.222488],
                [-80.797501, 73.276932],
                [-80.876099, 73.327766],
                [-80.844162, 73.352478],
                [-80.892502, 73.374687],
                [-80.861938, 73.396378],
                [-80.884453, 73.439697],
                [-80.85556, 73.47554],
                [-80.695267, 73.477768],
                [-80.867218, 73.544708],
                [-80.902222, 73.602768],
                [-80.797501, 73.659988],
                [-80.80249, 73.714432],
                [-80.861717, 73.745178],
                [-80.771667, 73.74971]
              ]
            ],
            [
              [
                [-75, 68.11969],
                [-75.05249, 68.036652],
                [-75.164436, 67.954163],
                [-75.104446, 67.847488],
                [-75.044449, 67.812477],
                [-75.066391, 67.695534],
                [-75.025284, 67.619431],
                [-75.071953, 67.538879],
                [-75.388062, 67.354713],
                [-75.517227, 67.351646],
                [-75.59111, 67.306641],
                [-75.908051, 67.248596],
                [-76.242218, 67.262207],
                [-76.66362, 67.219994],
                [-77.044724, 67.260536],
                [-77.175003, 67.421097],
                [-77.246948, 67.451927],
                [-77.256393, 67.516388],
                [-77.22583, 67.543869],
                [-77.32251, 67.698029],
                [-77.220551, 67.861923],
                [-76.865547, 68.157761],
                [-76.606949, 68.279427],
                [-76.281952, 68.332764],
                [-76.235817, 68.303307],
                [-76.083328, 68.295258],
                [-75.917503, 68.338318],
                [-75.726097, 68.330276],
                [-75.134743, 68.234711],
                [-75, 68.11969]
              ]
            ],
            [
              [
                [-95.959473, 74.856369],
                [-96.127777, 74.915543],
                [-96.033333, 74.949417],
                [-96.050827, 74.972763],
                [-96.141678, 74.957207],
                [-96.056381, 75.010269],
                [-96.074173, 75.023613],
                [-96.142227, 75.017761],
                [-96.209732, 74.915543],
                [-96.268341, 74.90387],
                [-96.403061, 74.925812],
                [-96.37944, 74.943863],
                [-96.394173, 74.978317],
                [-96.328056, 74.974701],
                [-96.331123, 75.004707],
                [-96.614166, 74.984993],
                [-96.587509, 75.031662],
                [-96.604721, 75.063309],
                [-96.463058, 75.193314],
                [-96.380829, 75.175262],
                [-96.366386, 75.193314],
                [-96.399727, 75.204987],
                [-96.378601, 75.21666],
                [-96.04805, 75.238312],
                [-95.987778, 75.25],
                [-96.08139, 75.25],
                [-96.077789, 75.272491],
                [-95.903343, 75.289978],
                [-96.172501, 75.285538],
                [-95.944443, 75.378036],
                [-95.890556, 75.372208],
                [-95.919998, 75.346939],
                [-95.776947, 75.371918],
                [-96.033333, 75.401093],
                [-96.151108, 75.374977],
                [-96.178879, 75.38443],
                [-96.09584, 75.417763],
                [-95.958344, 75.436371],
                [-95.760834, 75.390823],
                [-95.684723, 75.428307],
                [-95.838058, 75.459152],
                [-95.749733, 75.513321],
                [-95.456673, 75.568329],
                [-95.371109, 75.552467],
                [-95.275284, 75.60582],
                [-95.178596, 75.584427],
                [-94.88266, 75.635788],
                [-94.363892, 75.59082],
                [-93.989723, 75.434982],
                [-93.676941, 75.356644],
                [-93.649727, 75.320831],
                [-93.801102, 75.303589],
                [-93.499161, 75.264709],
                [-93.578339, 75.197746],
                [-93.456673, 75.116379],
                [-93.509171, 75.091087],
                [-93.488892, 75.072487],
                [-93.642776, 75.038589],
                [-93.515289, 75.038307],
                [-93.42778, 74.955261],
                [-93.406387, 74.883614],
                [-93.438889, 74.757767],
                [-93.563606, 74.743042],
                [-93.463058, 74.729431],
                [-93.484734, 74.687759],
                [-93.741379, 74.635536],
                [-94.278061, 74.650818],
                [-94.643341, 74.623596],
                [-94.869453, 74.684982],
                [-94.91362, 74.662201],
                [-95.080002, 74.680817],
                [-95.06778, 74.736366],
                [-95.283333, 74.798027],
                [-95.443878, 74.769707],
                [-95.38028, 74.799713],
                [-95.457497, 74.798317],
                [-95.482773, 74.779427],
                [-95.450562, 74.741653],
                [-95.519173, 74.74054],
                [-95.547501, 74.756378],
                [-95.493057, 74.798866],
                [-95.518066, 74.811653],
                [-95.625816, 74.80748],
                [-95.725014, 74.843048],
                [-95.735001, 74.81749],
                [-95.907784, 74.819443],
                [-95.868881, 74.848038],
                [-95.959473, 74.856369]
              ]
            ],
            [
              [
                [-82.185822, 62.979988],
                [-81.911667, 62.952221],
                [-81.865547, 62.923321],
                [-81.90361, 62.852779],
                [-81.949722, 62.834991],
                [-81.938599, 62.709991],
                [-82.13945, 62.60638],
                [-82.369164, 62.547489],
                [-82.442207, 62.458599],
                [-83.001953, 62.204441],
                [-83.136398, 62.17305],
                [-83.198608, 62.22221],
                [-83.337784, 62.25222],
                [-83.703888, 62.141659],
                [-83.731667, 62.3036],
                [-83.945267, 62.427219],
                [-83.90834, 62.482769],
                [-83.704178, 62.569439],
                [-83.570007, 62.67527],
                [-83.527496, 62.82111],
                [-83.374161, 62.90694],
                [-83.21167, 62.913601],
                [-83.142502, 62.854439],
                [-83.041107, 62.837212],
                [-82.694443, 62.93943],
                [-82.420837, 62.924999],
                [-82.29277, 62.98333],
                [-82.185822, 62.979988]
              ]
            ],
            [
              [
                [-97.949432, 78.816383],
                [-97.488602, 78.796654],
                [-97.025284, 78.741928],
                [-96.886398, 78.69664],
                [-96.533623, 78.676933],
                [-96.315826, 78.618042],
                [-96.184433, 78.628593],
                [-96.152496, 78.611366],
                [-96.275009, 78.533333],
                [-95.964447, 78.483597],
                [-95.601936, 78.519707],
                [-94.894997, 78.395828],
                [-94.837509, 78.347214],
                [-95.39917, 78.231087],
                [-95.130829, 78.194138],
                [-95.089722, 78.154984],
                [-94.889999, 78.108871],
                [-94.929718, 78.08194],
                [-94.888062, 78.058868],
                [-95.112503, 77.951393],
                [-95.37999, 77.966377],
                [-96.285553, 77.859421],
                [-96.541107, 77.897217],
                [-96.735001, 77.866928],
                [-96.491669, 77.870247],
                [-96.570557, 77.851929],
                [-96.515556, 77.845543],
                [-96.71167, 77.839706],
                [-96.837784, 77.805252],
                [-96.817497, 77.791656],
                [-97.096947, 77.803307],
                [-97.081123, 77.843597],
                [-97.120003, 77.870247],
                [-96.995003, 77.921097],
                [-97.775558, 78.034988],
                [-97.651398, 78.051933],
                [-97.684433, 78.073883],
                [-97.647781, 78.09082],
                [-96.910278, 78.079163],
                [-96.85556, 78.104156],
                [-96.904449, 78.113037],
                [-96.870537, 78.133331],
                [-97.321121, 78.207489],
                [-97.926666, 78.217758],
                [-97.91362, 78.244141],
                [-97.763344, 78.244431],
                [-98.054718, 78.301651],
                [-98.010277, 78.333328],
                [-98.087509, 78.375809],
                [-98.036942, 78.397217],
                [-98.347778, 78.443039],
                [-98.410553, 78.490257],
                [-98.319458, 78.51915],
                [-98.345284, 78.534714],
                [-98.019173, 78.542763],
                [-98.32695, 78.650543],
                [-98.311394, 78.699707],
                [-98.371658, 78.719994],
                [-98.31778, 78.746643],
                [-98.364723, 78.768051],
                [-98.144447, 78.816673],
                [-97.949432, 78.816383]
              ]
            ],
            [
              [
                [-105.2258, 72.933037],
                [-105.356102, 72.947746],
                [-105.292503, 72.924698],
                [-105.324203, 72.906097],
                [-105.204201, 72.864151],
                [-105.383102, 72.866653],
                [-105.462502, 72.94136],
                [-105.903603, 73.130814],
                [-105.895798, 73.161102],
                [-106.101402, 73.189697],
                [-106.044403, 73.219437],
                [-106.193604, 73.284988],
                [-106.126099, 73.291367],
                [-106.466103, 73.401382],
                [-107.034203, 73.486366],
                [-106.645798, 73.699142],
                [-106.205597, 73.734154],
                [-105.148598, 73.754173],
                [-104.824203, 73.637207],
                [-104.580803, 73.600273],
                [-104.483101, 73.534416],
                [-104.5756, 73.453873],
                [-104.548599, 73.414703],
                [-104.586899, 73.37442],
                [-104.573303, 73.323036],
                [-104.760002, 73.203873],
                [-104.739403, 73.179977],
                [-104.975601, 73.085274],
                [-104.984703, 73.020538],
                [-105.2258, 72.933037]
              ]
            ],
            [
              [
                [-79.541321, 61.799789],
                [-79.60527, 61.76527],
                [-79.656952, 61.64249],
                [-79.761398, 61.576939],
                [-79.846123, 61.569988],
                [-79.959442, 61.68721],
                [-80.275284, 61.80666],
                [-80.292221, 61.873051],
                [-80.257507, 61.883881],
                [-80.295273, 61.983601],
                [-80.268623, 62.10722],
                [-80.180283, 62.217491],
                [-79.94722, 62.386108],
                [-79.714447, 62.39777],
                [-79.700562, 62.369991],
                [-79.561943, 62.41721],
                [-79.442757, 62.37999],
                [-79.359161, 62.296101],
                [-79.260834, 62.253609],
                [-79.261398, 62.163601],
                [-79.333618, 62.05471],
                [-79.315826, 61.98888],
                [-79.381104, 62.002781],
                [-79.541321, 61.799789]
              ]
            ],
            [
              [
                [-81.087784, 53.17944],
                [-80.763344, 52.931381],
                [-80.66777, 52.771931],
                [-80.712509, 52.689159],
                [-81.024437, 52.750549],
                [-81.251953, 52.8325],
                [-81.916656, 52.961658],
                [-82.063316, 53.026661],
                [-81.965286, 53.122219],
                [-81.848053, 53.18166],
                [-81.375, 53.224991],
                [-81.087784, 53.17944]
              ]
            ],
            [
              [
                [-83.921387, 66.00972],
                [-83.683319, 65.910538],
                [-83.699722, 65.851379],
                [-83.796951, 65.811371],
                [-83.525009, 65.737762],
                [-83.529716, 65.708328],
                [-83.360001, 65.727478],
                [-83.210831, 65.705833],
                [-83.263634, 65.688309],
                [-83.247498, 65.659714],
                [-83.288887, 65.632751],
                [-83.379173, 65.61554],
                [-83.468613, 65.654984],
                [-83.623611, 65.661377],
                [-83.58667, 65.703873],
                [-83.662514, 65.676376],
                [-83.62471, 65.646652],
                [-83.760559, 65.670258],
                [-83.842773, 65.649147],
                [-83.785828, 65.724701],
                [-83.684158, 65.744431],
                [-83.827499, 65.748322],
                [-83.785278, 65.788879],
                [-83.960007, 65.737762],
                [-84.143623, 65.76416],
                [-84.101097, 65.819717],
                [-84.130829, 65.909714],
                [-84.193329, 65.929153],
                [-84.208618, 65.977203],
                [-84.382767, 66.000549],
                [-84.468063, 66.064987],
                [-84.470284, 66.133331],
                [-84.096657, 66.056931],
                [-84.008347, 66.077209],
                [-84.000557, 66.02887],
                [-83.921387, 66.00972]
              ]
            ],
            [
              [
                [-93.174713, 77.704163],
                [-93.101936, 77.662491],
                [-93.37944, 77.630814],
                [-93.405563, 77.609154],
                [-93.37999, 77.583878],
                [-93.486389, 77.545532],
                [-93.502502, 77.503052],
                [-93.475281, 77.471367],
                [-93.570557, 77.437759],
                [-94.878326, 77.484993],
                [-95.864166, 77.462196],
                [-96.083328, 77.497757],
                [-96.328888, 77.60498],
                [-96.269173, 77.614151],
                [-96.289169, 77.650543],
                [-96.245003, 77.662491],
                [-96.241096, 77.694977],
                [-95.465286, 77.808029],
                [-95.363892, 77.737198],
                [-95.30278, 77.763893],
                [-95.243057, 77.742752],
                [-94.752228, 77.788589],
                [-94.343887, 77.753883],
                [-94.042503, 77.766098],
                [-93.931381, 77.732483],
                [-93.656952, 77.776657],
                [-93.174713, 77.704163]
              ]
            ],
            [
              [
                [-73.655472, 68.007713],
                [-73.411667, 67.974991],
                [-73.430283, 67.914146],
                [-73.405563, 67.856934],
                [-73.348618, 67.828049],
                [-73.449432, 67.762497],
                [-74.48111, 67.789429],
                [-74.684433, 67.881927],
                [-74.772232, 67.963318],
                [-74.778061, 68.006104],
                [-74.731949, 68.070831],
                [-74.456123, 68.067207],
                [-74.339447, 68.1772],
                [-74.212509, 68.130539],
                [-74.266403, 68.059418],
                [-73.974716, 68.041092],
                [-73.794159, 67.988586],
                [-73.655472, 68.007713]
              ]
            ],
            [
              [
                [-105.648903, 77.748596],
                [-105.027802, 77.546371],
                [-104.945, 77.484993],
                [-105.0103, 77.458603],
                [-105.0103, 77.408043],
                [-104.741096, 77.414429],
                [-104.706703, 77.377762],
                [-104.425003, 77.309708],
                [-104.472504, 77.284149],
                [-104.395599, 77.276382],
                [-104.3675, 77.224426],
                [-104.472504, 77.137497],
                [-104.740303, 77.108597],
                [-105.044403, 77.171371],
                [-105.226402, 77.161102],
                [-105.261101, 77.171654],
                [-105.248596, 77.205551],
                [-105.396103, 77.213043],
                [-105.323898, 77.23027],
                [-105.550301, 77.311653],
                [-105.573097, 77.329437],
                [-105.544701, 77.367477],
                [-105.657501, 77.380257],
                [-105.631897, 77.406937],
                [-105.689697, 77.41748],
                [-105.655602, 77.441933],
                [-105.764999, 77.463882],
                [-105.687202, 77.515549],
                [-105.856903, 77.54332],
                [-105.8181, 77.55748],
                [-105.834396, 77.61026],
                [-106.091698, 77.715271],
                [-105.913902, 77.762497],
                [-105.648903, 77.748596]
              ]
            ],
            [
              [
                [-78.212784, 63.496101],
                [-77.845001, 63.47221],
                [-77.81778, 63.454441],
                [-77.844452, 63.437771],
                [-77.80722, 63.425831],
                [-77.680557, 63.434429],
                [-77.636673, 63.402771],
                [-77.635559, 63.358891],
                [-77.495003, 63.265831],
                [-77.570282, 63.206661],
                [-77.560272, 63.18277],
                [-77.958344, 63.093048],
                [-78.5625, 63.395828],
                [-78.572777, 63.440269],
                [-78.212784, 63.496101]
              ]
            ],
            [
              [
                [-78.458618, 69.389977],
                [-78.279716, 69.370247],
                [-78.214447, 69.288307],
                [-78.356377, 69.196091],
                [-78.467216, 69.219711],
                [-78.476097, 69.161377],
                [-78.556381, 69.137497],
                [-78.556664, 69.083603],
                [-78.715012, 69.00972],
                [-78.725281, 68.968872],
                [-78.867493, 68.900543],
                [-79.216949, 68.829987],
                [-79.400284, 68.871918],
                [-79.388062, 68.906647],
                [-79.419159, 68.920258],
                [-79.309433, 68.973602],
                [-79.227219, 69.076393],
                [-78.960281, 69.102478],
                [-78.864166, 69.141098],
                [-78.858887, 69.189972],
                [-78.749161, 69.261108],
                [-78.619453, 69.261658],
                [-78.721939, 69.310532],
                [-78.71611, 69.340271],
                [-78.458618, 69.389977]
              ]
            ],
            [
              [
                [-79.768066, 69.752777],
                [-79.68721, 69.814697],
                [-79.476936, 69.803589],
                [-79.329453, 69.701393],
                [-79.631668, 69.608871],
                [-79.91806, 69.603592],
                [-80.059433, 69.643883],
                [-80.078888, 69.622208],
                [-79.935547, 69.527206],
                [-80.011948, 69.491653],
                [-80.229446, 69.531097],
                [-80.219162, 69.625259],
                [-80.325012, 69.554977],
                [-80.353607, 69.6147],
                [-80.492767, 69.664993],
                [-80.809433, 69.683037],
                [-80.725014, 69.749153],
                [-80.649727, 69.748596],
                [-80.462784, 69.712769],
                [-80.400833, 69.666092],
                [-80.419159, 69.699142],
                [-80.375267, 69.709717],
                [-80.448608, 69.723877],
                [-80.504463, 69.774986],
                [-80.388901, 69.799988],
                [-80.329727, 69.774147],
                [-80.18277, 69.792763],
                [-80.120537, 69.758614],
                [-80.146118, 69.720833],
                [-80.085007, 69.749153],
                [-79.964172, 69.720833],
                [-79.768066, 69.752777]
              ]
            ],
            [
              [
                [-90, 77.547783],
                [-89.719162, 77.458328],
                [-89.711121, 77.365257],
                [-89.636124, 77.339157],
                [-90, 77.213814],
                [-90.39473, 77.199142],
                [-90.419159, 77.208038],
                [-90.387512, 77.227203],
                [-91.142227, 77.360527],
                [-91.106377, 77.374687],
                [-91.208893, 77.414993],
                [-91.170273, 77.449707],
                [-91.205566, 77.518883],
                [-91.184433, 77.608597],
                [-90.906387, 77.653053],
                [-90.339172, 77.623871],
                [-90, 77.547783]
              ]
            ],
            [
              [
                [-97.65361, 74.099991],
                [-97.649437, 74.052467],
                [-97.763634, 73.988312],
                [-98.010559, 73.937477],
                [-98.144997, 73.873596],
                [-98.392776, 73.845261],
                [-98.652222, 73.834427],
                [-98.715286, 73.861366],
                [-98.754181, 73.837486],
                [-98.72084, 73.82222],
                [-98.74028, 73.813309],
                [-98.96167, 73.805252],
                [-99.166397, 73.832489],
                [-98.823624, 73.824432],
                [-98.847778, 73.849152],
                [-99.25, 73.852768],
                [-99.4375, 73.896942],
                [-99.281952, 73.938026],
                [-98.972778, 73.951103],
                [-99.013901, 73.984154],
                [-98.765289, 74.031097],
                [-98.491943, 74.034149],
                [-97.994453, 74.109421],
                [-97.65361, 74.099991]
              ]
            ],
            [
              [
                [-104.151802, 75.434563],
                [-103.803299, 75.36026],
                [-103.814201, 75.330833],
                [-103.733902, 75.274147],
                [-103.749702, 75.234711],
                [-103.583099, 75.164703],
                [-103.730301, 75.099991],
                [-104.261101, 75.018333],
                [-104.728104, 75.070267],
                [-104.930801, 75.132202],
                [-104.688004, 75.25972],
                [-104.770302, 75.282494],
                [-104.673599, 75.34166],
                [-104.428001, 75.420822],
                [-104.151802, 75.434563]
              ]
            ],
            [
              [
                [-86.434998, 68.162491],
                [-86.426392, 68.069153],
                [-86.368607, 67.954712],
                [-86.396118, 67.859711],
                [-86.571953, 67.728867],
                [-86.690552, 67.733871],
                [-86.858337, 67.796944],
                [-86.945831, 67.909416],
                [-86.940552, 67.934418],
                [-86.843338, 67.958603],
                [-86.836937, 68.001099],
                [-86.988327, 68.081673],
                [-86.897781, 68.136108],
                [-86.906387, 68.180267],
                [-86.711937, 68.299149],
                [-86.458618, 68.235527],
                [-86.402786, 68.194427],
                [-86.434998, 68.162491]
              ]
            ],
            [
              [
                [-70.587784, 62.774158],
                [-70.396957, 62.723049],
                [-70.422783, 62.709431],
                [-70.371933, 62.687771],
                [-70.413063, 62.650269],
                [-70.3125, 62.65416],
                [-70.211937, 62.57777],
                [-70.393066, 62.53027],
                [-70.723892, 62.55027],
                [-70.774719, 62.569721],
                [-70.74028, 62.592491],
                [-70.819733, 62.60471],
                [-70.854446, 62.713612],
                [-70.920273, 62.740551],
                [-70.771118, 62.76749],
                [-71.019173, 62.811939],
                [-71.15834, 62.797218],
                [-71.166397, 62.81805],
                [-71.032784, 62.822769],
                [-71.241379, 62.881378],
                [-70.788063, 62.836109],
                [-70.587784, 62.774158]
              ]
            ],
            [
              [
                [-104.134201, 76.669434],
                [-103.922501, 76.633614],
                [-104.053902, 76.563026],
                [-103.878899, 76.573608],
                [-103.859398, 76.607208],
                [-103.787498, 76.620529],
                [-103.559402, 76.563873],
                [-103.587799, 76.524986],
                [-103.248596, 76.499153],
                [-103.245003, 76.474991],
                [-103.004501, 76.429977],
                [-103.204697, 76.354713],
                [-103.507004, 76.310806],
                [-104.378899, 76.323318],
                [-104.404404, 76.334427],
                [-104.329697, 76.358032],
                [-104.351097, 76.383614],
                [-104.4608, 76.390266],
                [-104.391998, 76.421654],
                [-104.391098, 76.461113],
                [-104.448898, 76.491089],
                [-104.563904, 76.482208],
                [-104.589699, 76.508881],
                [-104.560799, 76.523041],
                [-104.658897, 76.545822],
                [-104.638603, 76.574707],
                [-104.6828, 76.597488],
                [-104.134201, 76.669434]
              ]
            ],
            [
              [
                [-84.910278, 66],
                [-84.860283, 65.968323],
                [-84.883331, 65.940811],
                [-84.752502, 65.878036],
                [-84.638062, 65.707207],
                [-84.58667, 65.6922],
                [-84.604446, 65.671921],
                [-84.576111, 65.62915],
                [-84.66777, 65.560532],
                [-84.784729, 65.556641],
                [-84.840843, 65.588043],
                [-84.878601, 65.669144],
                [-85.118057, 65.764709],
                [-85.143066, 65.913307],
                [-85.181953, 65.945534],
                [-85.17305, 65.994713],
                [-85.08168, 66.026657],
                [-84.910278, 66]
              ]
            ],
            [
              [
                [-64.659729, 61.588039],
                [-64.726097, 61.542221],
                [-64.683319, 61.531109],
                [-64.71666, 61.511669],
                [-64.675278, 61.50861],
                [-64.686943, 61.465832],
                [-64.801392, 61.412209],
                [-64.802223, 61.372761],
                [-64.875267, 61.322491],
                [-64.91333, 61.355],
                [-64.977493, 61.347771],
                [-64.980827, 61.376099],
                [-64.932503, 61.403599],
                [-65.060272, 61.407211],
                [-65.119453, 61.43277],
                [-65.090843, 61.454441],
                [-65.151672, 61.453049],
                [-65.195267, 61.499161],
                [-65.372498, 61.53722],
                [-65.487778, 61.59943],
                [-65.435822, 61.658039],
                [-65.036392, 61.6936],
                [-64.719162, 61.658039],
                [-64.791382, 61.617489],
                [-64.725563, 61.6311],
                [-64.734161, 61.610821],
                [-64.659157, 61.609989],
                [-64.659729, 61.588039]
              ]
            ],
            [
              [
                [-98.830002, 79.664429],
                [-98.936111, 79.719711],
                [-99.31723, 79.758614],
                [-99.30278, 79.787491],
                [-99.333618, 79.80304],
                [-99.296387, 79.839157],
                [-99.368881, 79.857758],
                [-99.614166, 79.893333],
                [-100.121101, 79.886658],
                [-100.177498, 79.909988],
                [-100.127502, 79.961929],
                [-100.193298, 80.033867],
                [-100.065598, 80.089981],
                [-99.759171, 80.149719],
                [-99.436661, 80.107208],
                [-99.136673, 80.133041],
                [-98.868881, 80.077766],
                [-98.871933, 80.044434],
                [-98.705841, 79.96582],
                [-98.779167, 79.902771],
                [-98.644173, 79.800262],
                [-98.830002, 79.664429]
              ]
            ],
            [
              [
                [-102.652199, 76.287773],
                [-102.5308, 76.223312],
                [-102.5839, 76.183594],
                [-102.529198, 76.153587],
                [-102.621399, 76.152481],
                [-102.650597, 76.11998],
                [-102.806396, 76.085541],
                [-103.342201, 76.036652],
                [-103.986099, 76.034988],
                [-103.888603, 76.049713],
                [-104.391098, 76.078323],
                [-104.482803, 76.142212],
                [-104.297997, 76.212486],
                [-103.876404, 76.248596],
                [-103.059998, 76.306374],
                [-102.652199, 76.287773]
              ]
            ],
            [
              [
                [-76.68161, 63.48135],
                [-76.541946, 63.46249],
                [-76.650284, 63.431381],
                [-76.631668, 63.412491],
                [-76.692207, 63.367771],
                [-76.973892, 63.403599],
                [-77.243332, 63.54361],
                [-77.42778, 63.589161],
                [-77.400833, 63.598331],
                [-77.439713, 63.618599],
                [-77.333893, 63.626942],
                [-77.45723, 63.64333],
                [-77.343338, 63.696098],
                [-77.116943, 63.65416],
                [-77.121933, 63.679722],
                [-77.061394, 63.672771],
                [-76.616096, 63.523319],
                [-76.68161, 63.48135]
              ]
            ],
            [
              [
                [-79.057503, 54.91777],
                [-79.776947, 54.778049],
                [-79.681953, 54.810268],
                [-79.728058, 54.812771],
                [-79.686943, 54.838329],
                [-79.467216, 54.888329],
                [-79.053879, 54.946659],
                [-79.015007, 54.932209],
                [-79.057503, 54.91777]
              ]
            ],
            [
              [
                [-64.325562, 63.637501],
                [-64.275284, 63.496101],
                [-64.306664, 63.483051],
                [-64.252502, 63.44054],
                [-64.286942, 63.430271],
                [-64.087509, 63.32972],
                [-64.061111, 63.27055],
                [-64.166656, 63.32333],
                [-64.181953, 63.29694],
                [-64.266953, 63.326389],
                [-64.421944, 63.471661],
                [-64.389183, 63.48444],
                [-64.490547, 63.620541],
                [-64.377213, 63.67749],
                [-64.325562, 63.637501]
              ]
            ],
            [
              [
                [-75, 68.672241],
                [-74.938316, 68.571663],
                [-74.768341, 68.473312],
                [-74.906662, 68.423027],
                [-74.776398, 68.410538],
                [-74.863892, 68.3797],
                [-74.816101, 68.369713],
                [-74.81221, 68.338043],
                [-74.855827, 68.34137],
                [-74.811394, 68.320541],
                [-75.002228, 68.333603],
                [-75.037514, 68.394707],
                [-75.241379, 68.436371],
                [-75.416397, 68.518051],
                [-75.398354, 68.610527],
                [-75.280563, 68.709717],
                [-75, 68.672241]
              ]
            ],
            [
              [
                [-78.141953, 69.742477],
                [-77.982773, 69.694702],
                [-77.946663, 69.646652],
                [-78.087784, 69.576927],
                [-78.518623, 69.480553],
                [-78.646118, 69.509987],
                [-78.817497, 69.452766],
                [-78.88028, 69.476929],
                [-78.575844, 69.636383],
                [-78.400284, 69.643333],
                [-78.37999, 69.698593],
                [-78.306953, 69.686653],
                [-78.315552, 69.659714],
                [-78.24527, 69.663597],
                [-78.228058, 69.677467],
                [-78.268623, 69.732208],
                [-78.141953, 69.742477]
              ]
            ],
            [
              [
                [-90, 76.837822],
                [-89.825844, 76.806091],
                [-89.673889, 76.737488],
                [-89.671661, 76.698593],
                [-89.837219, 76.66304],
                [-89.862778, 76.603867],
                [-89.794449, 76.533333],
                [-89.671387, 76.504173],
                [-89.808609, 76.482483],
                [-89.943329, 76.506378],
                [-89.934433, 76.476646],
                [-90, 76.470306],
                [-90.184433, 76.515266],
                [-90.600281, 76.734993],
                [-90.474716, 76.799713],
                [-90, 76.837822]
              ]
            ],
            [
              [
                [-77.113617, 69.44165],
                [-76.923607, 69.3936],
                [-76.7164, 69.422211],
                [-76.64473, 69.381363],
                [-76.646667, 69.336929],
                [-76.939987, 69.209152],
                [-76.944443, 69.185257],
                [-76.858612, 69.14888],
                [-76.922501, 69.119713],
                [-76.897232, 69.103867],
                [-76.958893, 69.142487],
                [-77.213623, 69.125809],
                [-77.301392, 69.164146],
                [-77.381943, 69.247482],
                [-77.335007, 69.333878],
                [-77.359734, 69.392761],
                [-77.113617, 69.44165]
              ]
            ],
            [
              [
                [-96.025436, 75.602837],
                [-95.911118, 75.554153],
                [-96.048889, 75.525269],
                [-96.170837, 75.458038],
                [-96.417221, 75.523323],
                [-96.360001, 75.568878],
                [-96.410278, 75.580276],
                [-96.549156, 75.519707],
                [-96.511948, 75.455833],
                [-96.703613, 75.423027],
                [-96.631104, 75.393051],
                [-96.851097, 75.350273],
                [-96.880547, 75.379967],
                [-96.979446, 75.386383],
                [-96.941383, 75.407761],
                [-97.053047, 75.492203],
                [-96.419159, 75.588593],
                [-96.424438, 75.635536],
                [-96.396393, 75.649986],
                [-96.025436, 75.602837]
              ]
            ],
            [
              [
                [-101.988602, 75.934418],
                [-102.394203, 75.880814],
                [-102.496902, 75.795532],
                [-102.611702, 75.767212],
                [-103.137802, 75.742752],
                [-103.382797, 75.765549],
                [-103.246902, 75.797211],
                [-103.297798, 75.809982],
                [-103.051399, 75.83194],
                [-103.146698, 75.854431],
                [-103.059402, 75.896378],
                [-102.696098, 75.94664],
                [-102.164703, 75.99054],
                [-101.988602, 75.934418]
              ]
            ],
            [
              [
                [-67.948608, 60.561378],
                [-67.798607, 60.4575],
                [-67.853333, 60.375271],
                [-68.177223, 60.24305],
                [-68.387222, 60.240829],
                [-68.377777, 60.310268],
                [-68.12944, 60.570549],
                [-67.948608, 60.561378]
              ]
            ],
            [
              [
                [-78.893066, 76.11554],
                [-78.79805, 76.08638],
                [-78.833893, 76.052467],
                [-79.176941, 75.946373],
                [-78.87944, 75.844147],
                [-79.319458, 75.873596],
                [-79.420273, 75.848038],
                [-79.341377, 75.824707],
                [-79.443878, 75.791931],
                [-79.592773, 75.817207],
                [-79.566963, 75.845833],
                [-79.598053, 75.861366],
                [-79.752228, 75.878593],
                [-79.596947, 75.902481],
                [-79.580002, 75.945251],
                [-79.307503, 76.013321],
                [-79.091377, 76.114433],
                [-78.893066, 76.11554]
              ]
            ],
            [
              [
                [-101.671898, 77.893333],
                [-101.191399, 77.830833],
                [-100.960602, 77.759163],
                [-100.926399, 77.743317],
                [-100.940598, 77.726929],
                [-101.118301, 77.714157],
                [-101.507004, 77.724991],
                [-101.798897, 77.676376],
                [-102.067802, 77.682213],
                [-102.444397, 77.731934],
                [-102.413597, 77.753326],
                [-102.504501, 77.779984],
                [-102.493896, 77.81192],
                [-102.529701, 77.834152],
                [-102.387802, 77.884163],
                [-101.671898, 77.893333]
              ]
            ],
            [
              [
                [-95.488892, 69.565536],
                [-95.359734, 69.509163],
                [-95.402496, 69.383331],
                [-95.515839, 69.330833],
                [-95.736938, 69.324432],
                [-95.713058, 69.344711],
                [-95.727783, 69.378593],
                [-95.648354, 69.40332],
                [-95.689987, 69.464706],
                [-95.669159, 69.507492],
                [-95.708054, 69.548866],
                [-95.815826, 69.562759],
                [-95.822777, 69.514427],
                [-95.781113, 69.463043],
                [-95.866943, 69.383041],
                [-95.862213, 69.348038],
                [-95.990829, 69.353317],
                [-95.993057, 69.388603],
                [-95.945831, 69.413879],
                [-96.009743, 69.483047],
                [-95.952499, 69.510536],
                [-95.919998, 69.595261],
                [-95.789436, 69.634163],
                [-95.612213, 69.614433],
                [-95.488892, 69.565536]
              ]
            ],
            [
              [
                [-71.794724, 71.05304],
                [-71.339722, 70.991364],
                [-71.415558, 70.907761],
                [-71.593613, 70.90387],
                [-71.914436, 70.848877],
                [-71.955566, 70.818329],
                [-72.112213, 70.811371],
                [-72.118057, 70.85054],
                [-72.19194, 70.877762],
                [-72.226387, 70.930542],
                [-72.05777, 70.933037],
                [-72.008621, 71.049713],
                [-71.851669, 71.07222],
                [-71.794724, 71.05304]
              ]
            ],
            [
              [
                [-102.353302, 76.073883],
                [-102.316399, 76.036926],
                [-102.365799, 76.005829],
                [-103.339401, 75.908043],
                [-103.699699, 75.887497],
                [-103.901901, 75.898613],
                [-103.971703, 75.938309],
                [-102.868301, 76.06694],
                [-102.353302, 76.073883]
              ]
            ],
            [
              [
                [-101.693901, 68.768051],
                [-101.682503, 68.661652],
                [-101.701698, 68.637772],
                [-101.824699, 68.649719],
                [-101.795303, 68.623596],
                [-101.8311, 68.56694],
                [-102.349403, 68.683037],
                [-102.092499, 68.748871],
                [-101.998299, 68.823036],
                [-101.693901, 68.768051]
              ]
            ],
            [
              [
                [-100.323601, 68.996094],
                [-100.238899, 68.924149],
                [-100.126404, 68.905548],
                [-100.205002, 68.846649],
                [-100.175598, 68.794708],
                [-100.2211, 68.799713],
                [-100.220802, 68.764427],
                [-100.299202, 68.773323],
                [-100.366898, 68.710541],
                [-100.4869, 68.753052],
                [-100.446701, 68.774429],
                [-100.468903, 68.797211],
                [-100.571999, 68.75444],
                [-100.628098, 68.766388],
                [-100.625801, 68.912491],
                [-100.573601, 68.936653],
                [-100.624199, 68.996094],
                [-100.543602, 69.036652],
                [-100.323601, 68.996094]
              ]
            ],
            [
              [
                [-100.297203, 76.721916],
                [-101.273903, 76.560806],
                [-101.688301, 76.58638],
                [-100.7164, 76.756378],
                [-100.297203, 76.721916]
              ]
            ],
            [
              [
                [-87.26561, 70.113564],
                [-87.102783, 70.120819],
                [-87.145279, 70.142761],
                [-87.091377, 70.150269],
                [-86.798889, 70.087196],
                [-86.639717, 70.116653],
                [-86.456673, 70.007492],
                [-86.662216, 69.967484],
                [-87.002228, 70.014999],
                [-87.104172, 69.987762],
                [-87.376938, 70.093323],
                [-87.26561, 70.113564]
              ]
            ],
            [
              [
                [-96.136398, 69.546097],
                [-96.096123, 69.468048],
                [-96.148064, 69.421921],
                [-96.125816, 69.393883],
                [-96.146667, 69.345833],
                [-96.269997, 69.370819],
                [-96.401108, 69.453049],
                [-96.548889, 69.474991],
                [-96.736656, 69.57666],
                [-96.373611, 69.560806],
                [-96.316391, 69.526382],
                [-96.184433, 69.567207],
                [-96.136398, 69.546097]
              ]
            ],
            [
              [
                [-79.297234, 52.09193],
                [-79.252792, 52.071381],
                [-79.322243, 52.031658],
                [-79.319733, 51.96999],
                [-79.376663, 51.9361],
                [-79.611656, 51.91444],
                [-79.652786, 51.981098],
                [-79.623322, 52.025269],
                [-79.53833, 52.023319],
                [-79.338333, 52.110279],
                [-79.297234, 52.09193]
              ]
            ],
            [
              [
                [-64.382767, 62.525829],
                [-64.363892, 62.495831],
                [-64.481667, 62.481659],
                [-64.394173, 62.46138],
                [-64.504997, 62.443321],
                [-64.477219, 62.408039],
                [-64.590561, 62.36721],
                [-64.926666, 62.418331],
                [-64.965843, 62.465832],
                [-64.79306, 62.491379],
                [-64.862213, 62.520828],
                [-64.815552, 62.559719],
                [-64.682503, 62.53249],
                [-64.549728, 62.536381],
                [-64.549728, 62.56221],
                [-64.466949, 62.544159],
                [-64.463333, 62.51416],
                [-64.382767, 62.525829]
              ]
            ],
            [
              [
                [-64.549438, 63.895271],
                [-64.604172, 63.838879],
                [-64.506119, 63.838039],
                [-64.608612, 63.783051],
                [-64.498337, 63.7411],
                [-64.426102, 63.777771],
                [-64.386124, 63.70166],
                [-64.436394, 63.673321],
                [-64.694717, 63.784439],
                [-64.802223, 63.764439],
                [-64.916397, 63.806381],
                [-64.910278, 63.837212],
                [-64.811661, 63.877209],
                [-64.748047, 63.828049],
                [-64.777222, 63.86832],
                [-64.732224, 63.86805],
                [-64.710564, 63.908878],
                [-64.636948, 63.914711],
                [-64.628601, 63.881378],
                [-64.549438, 63.895271]
              ]
            ],
            [
              [
                [-73.060547, 71.294708],
                [-73.092773, 71.308868],
                [-73.065552, 71.324997],
                [-73.272507, 71.361923],
                [-73.25473, 71.415268],
                [-73.374161, 71.485809],
                [-73.37999, 71.519707],
                [-73.189987, 71.565536],
                [-73.081123, 71.542213],
                [-73.179718, 71.450546],
                [-73.15834, 71.41832],
                [-72.985283, 71.521378],
                [-72.827499, 71.454987],
                [-73.031113, 71.406647],
                [-73.017227, 71.342758],
                [-72.971939, 71.31749],
                [-73.060547, 71.294708]
              ]
            ],
            [
              [
                [-85.924438, 79.053864],
                [-85.321404, 79.053864],
                [-85.167221, 79.020828],
                [-85.301392, 78.975273],
                [-86.388062, 78.883041],
                [-86.484444, 78.892761],
                [-86.346123, 78.939697],
                [-86.29277, 79.012207],
                [-85.924438, 79.053864]
              ]
            ],
            [
              [
                [-104.682503, 68.573883],
                [-104.440598, 68.476089],
                [-104.441399, 68.417213],
                [-104.545303, 68.396103],
                [-104.919197, 68.459991],
                [-105.081703, 68.546371],
                [-104.938599, 68.583328],
                [-104.682503, 68.573883]
              ]
            ],
            [
              [
                [-96.771942, 73.181664],
                [-96.72583, 73.159149],
                [-96.771118, 73.113602],
                [-96.650284, 73.139977],
                [-96.568619, 73.062187],
                [-96.65834, 72.954163],
                [-96.955276, 72.920532],
                [-97.089722, 72.981659],
                [-97.139717, 73.051933],
                [-97.141113, 73.085541],
                [-97.045547, 73.147491],
                [-96.771942, 73.181664]
              ]
            ],
            [
              [
                [-65.068069, 61.923882],
                [-65.016403, 61.851662],
                [-64.964722, 61.889992],
                [-64.953888, 61.820549],
                [-64.893341, 61.82999],
                [-64.828888, 61.75222],
                [-64.95195, 61.722488],
                [-65.210564, 61.81694],
                [-65.251953, 61.869709],
                [-65.24527, 61.914711],
                [-65.170273, 61.947769],
                [-65.068069, 61.923882]
              ]
            ],
            [
              [
                [-65.266403, 64.693314],
                [-65.308884, 64.660263],
                [-65.25, 64.663597],
                [-65.213898, 64.626083],
                [-65.428596, 64.591087],
                [-65.363052, 64.579987],
                [-65.397232, 64.556374],
                [-65.539436, 64.546944],
                [-65.460564, 64.521103],
                [-65.689713, 64.519989],
                [-65.660278, 64.573883],
                [-65.449158, 64.678864],
                [-65.414169, 64.733597],
                [-65.380547, 64.733871],
                [-65.401672, 64.646103],
                [-65.296951, 64.734154],
                [-65.266403, 64.693314]
              ]
            ],
            [
              [
                [-100.461098, 70.659988],
                [-100.1922, 70.577209],
                [-100.3881, 70.578873],
                [-100.296997, 70.540817],
                [-100.224403, 70.45665],
                [-100.676399, 70.563309],
                [-100.661697, 70.633041],
                [-100.702202, 70.682747],
                [-100.461098, 70.659988]
              ]
            ],
            [
              [
                [-78.795273, 68.438583],
                [-78.97084, 68.471367],
                [-78.943329, 68.511932],
                [-78.723892, 68.547493],
                [-78.66861, 68.583328],
                [-78.898064, 68.64888],
                [-78.720001, 68.657211],
                [-78.460564, 68.617203],
                [-78.482224, 68.553307],
                [-78.668877, 68.554153],
                [-78.724716, 68.521927],
                [-78.610817, 68.498032],
                [-78.795273, 68.438583]
              ]
            ],
            [
              [
                [-94.481949, 75.974426],
                [-94.420273, 75.868591],
                [-94.287781, 75.783867],
                [-94.353882, 75.753883],
                [-94.777496, 75.7686],
                [-94.825844, 75.800812],
                [-94.829453, 75.882751],
                [-94.906113, 75.930817],
                [-94.523903, 75.992477],
                [-94.481949, 75.974426]
              ]
            ],
            [
              [
                [-78.226669, 60.80888],
                [-78.397232, 60.743881],
                [-78.656387, 60.70277],
                [-78.698334, 60.721661],
                [-78.573624, 60.784161],
                [-78.223892, 60.83083],
                [-78.226669, 60.80888]
              ]
            ],
            [
              [
                [-73.669449, 64.4272],
                [-73.577217, 64.4422],
                [-73.611938, 64.475273],
                [-73.577499, 64.489433],
                [-73.681953, 64.507492],
                [-73.67778, 64.532211],
                [-73.509171, 64.552467],
                [-73.529167, 64.498596],
                [-73.488327, 64.443863],
                [-73.549988, 64.403053],
                [-73.547234, 64.367477],
                [-73.515839, 64.365257],
                [-73.579727, 64.335823],
                [-73.557503, 64.312759],
                [-73.655563, 64.320267],
                [-73.669449, 64.4272]
              ]
            ],
            [
              [
                [-101.053001, 69.50444],
                [-100.970299, 69.455551],
                [-101.063004, 69.4561],
                [-101.099197, 69.401932],
                [-101.194397, 69.401932],
                [-101.230301, 69.368591],
                [-101.279198, 69.382477],
                [-101.231697, 69.400818],
                [-101.245003, 69.424988],
                [-101.300301, 69.4422],
                [-101.228897, 69.434708],
                [-101.186401, 69.475273],
                [-101.387199, 69.537773],
                [-101.349701, 69.572487],
                [-101.261703, 69.581673],
                [-101.243103, 69.54248],
                [-101.1744, 69.565811],
                [-101.053001, 69.50444]
              ]
            ],
            [
              [
                [-74.480827, 62.740269],
                [-74.374161, 62.681381],
                [-74.015839, 62.664989],
                [-73.958054, 62.612499],
                [-74.169159, 62.602219],
                [-74.541382, 62.668331],
                [-74.651108, 62.71777],
                [-74.480827, 62.740269]
              ]
            ],
            [
              [
                [-74.340843, 68.462486],
                [-74.26973, 68.454712],
                [-74.074722, 68.330833],
                [-74.149727, 68.25],
                [-74.228882, 68.250816],
                [-74.278343, 68.294983],
                [-74.213333, 68.319443],
                [-74.388611, 68.398331],
                [-74.393066, 68.445251],
                [-74.340843, 68.462486]
              ]
            ],
            [
              [
                [-79.099442, 68.348602],
                [-78.933319, 68.35054],
                [-78.802223, 68.272491],
                [-79.020554, 68.169144],
                [-79.089722, 68.170258],
                [-79.176941, 68.209427],
                [-79.190552, 68.27832],
                [-79.191101, 68.319443],
                [-79.099442, 68.348602]
              ]
            ],
            [
              [
                [-67.86972, 69.700821],
                [-67.72139, 69.644707],
                [-67.83168, 69.601929],
                [-67.920273, 69.521927],
                [-68.309998, 69.59082],
                [-68.063889, 69.669983],
                [-67.827217, 69.655548],
                [-67.971123, 69.689697],
                [-67.86972, 69.700821]
              ]
            ],
            [
              [
                [-90.195267, 69.416931],
                [-90.154716, 69.350807],
                [-90.329453, 69.235809],
                [-90.51001, 69.334717],
                [-90.501106, 69.372482],
                [-90.351097, 69.424698],
                [-90.294159, 69.433594],
                [-90.241943, 69.389427],
                [-90.218887, 69.401932],
                [-90.24527, 69.4272],
                [-90.205276, 69.445824],
                [-90.195267, 69.416931]
              ]
            ],
            [
              [
                [-62.8946, 67.059113],
                [-62.81028, 67.056931],
                [-62.795559, 67.099991],
                [-62.61972, 67.135269],
                [-62.652222, 67.166092],
                [-62.631939, 67.176933],
                [-62.41806, 67.190811],
                [-62.375271, 67.165817],
                [-62.580002, 67.102203],
                [-62.651661, 67.046944],
                [-62.733608, 67.04332],
                [-62.752499, 67.010536],
                [-62.8946, 67.059113]
              ]
            ],
            [
              [
                [-97.368057, 74.622757],
                [-97.256958, 74.590553],
                [-97.387512, 74.506378],
                [-97.652786, 74.455833],
                [-97.792503, 74.485809],
                [-97.631378, 74.538307],
                [-97.616943, 74.574997],
                [-97.532227, 74.606369],
                [-97.368057, 74.622757]
              ]
            ],
            [
              [
                [-95.628601, 74.640823],
                [-95.40361, 74.603317],
                [-95.246384, 74.521652],
                [-95.48056, 74.5],
                [-95.845001, 74.563873],
                [-95.628601, 74.640823]
              ]
            ],
            [
              [
                [-66.32695, 68.147491],
                [-66.628601, 68.182213],
                [-66.662216, 68.208878],
                [-66.551941, 68.201927],
                [-66.5, 68.2397],
                [-66.348892, 68.228592],
                [-66.283333, 68.268333],
                [-66.198608, 68.267487],
                [-66.269173, 68.210541],
                [-66.256668, 68.163597],
                [-66.32695, 68.147491]
              ]
            ],
            [
              [
                [-90.575562, 69.198593],
                [-90.613327, 69.207764],
                [-90.620003, 69.251938],
                [-90.690826, 69.227768],
                [-90.646393, 69.259163],
                [-90.695831, 69.277771],
                [-90.655273, 69.304703],
                [-90.680557, 69.311371],
                [-90.651947, 69.350807],
                [-90.777222, 69.272491],
                [-90.775833, 69.329987],
                [-90.673317, 69.373871],
                [-90.559433, 69.347214],
                [-90.471939, 69.281097],
                [-90.495003, 69.241928],
                [-90.454453, 69.226379],
                [-90.575562, 69.198593]
              ]
            ],
            [
              [
                [-82.935547, 66.251389],
                [-82.996384, 66.198593],
                [-83.080292, 66.19664],
                [-83.287216, 66.256104],
                [-83.272232, 66.305252],
                [-83.29834, 66.313873],
                [-83.252228, 66.344994],
                [-83.142227, 66.309143],
                [-83.16806, 66.283867],
                [-83.054993, 66.25499],
                [-82.91333, 66.276093],
                [-82.935547, 66.251389]
              ]
            ],
            [
              [
                [-64.954727, 63.553879],
                [-64.881378, 63.486382],
                [-64.847778, 63.387501],
                [-64.942207, 63.43082],
                [-64.971657, 63.494709],
                [-65.053047, 63.548328],
                [-64.986938, 63.51527],
                [-65.007507, 63.556381],
                [-64.954727, 63.553879]
              ]
            ],
            [
              [
                [-100.095596, 69.117477],
                [-100.033302, 69.094711],
                [-99.978882, 69.013893],
                [-100.007004, 68.939423],
                [-100.161697, 68.96138],
                [-100.256699, 69.026657],
                [-100.231903, 69.087196],
                [-100.144203, 69.140823],
                [-100.095596, 69.117477]
              ]
            ],
            [
              [
                [-85.259743, 77.586647],
                [-85.011124, 77.573883],
                [-84.813889, 77.497208],
                [-85.168327, 77.45694],
                [-85.152786, 77.507767],
                [-85.53833, 77.539978],
                [-85.259743, 77.586647]
              ]
            ],
            [
              [
                [-77.625, 63.997768],
                [-77.753342, 63.925549],
                [-77.982498, 63.975552],
                [-77.943604, 64.011108],
                [-77.774437, 64.031662],
                [-77.55722, 64.028053],
                [-77.625, 63.997768]
              ]
            ],
            [
              [
                [-107.882797, 67.462486],
                [-107.892799, 67.383614],
                [-107.933899, 67.361923],
                [-107.910797, 67.310532],
                [-108.150803, 67.406097],
                [-108.125298, 67.422493],
                [-108.040802, 67.386932],
                [-108.032501, 67.407494],
                [-108.075302, 67.419708],
                [-108.061096, 67.4422],
                [-107.917801, 67.489433],
                [-107.882797, 67.462486]
              ]
            ],
            [
              [
                [-62.25861, 65.728592],
                [-62.297501, 65.708603],
                [-62.18639, 65.711929],
                [-62.167782, 65.702766],
                [-62.19944, 65.685532],
                [-62.12833, 65.674149],
                [-62.215, 65.609993],
                [-62.272221, 65.663307],
                [-62.331951, 65.611923],
                [-62.313061, 65.660538],
                [-62.46611, 65.663879],
                [-62.441669, 65.689148],
                [-62.48444, 65.726929],
                [-62.444439, 65.74498],
                [-62.37389, 65.720261],
                [-62.32, 65.74942],
                [-62.25861, 65.728592]
              ]
            ],
            [
              [
                [-79.929993, 59.8736],
                [-79.878883, 59.85471],
                [-79.928047, 59.811661],
                [-80.089722, 59.751942],
                [-80.09111, 59.808331],
                [-80.184158, 59.747768],
                [-80.103058, 59.84499],
                [-80.015007, 59.884991],
                [-79.929993, 59.8736]
              ]
            ],
            [
              [
                [-63.926392, 67.633331],
                [-63.76083, 67.515266],
                [-64.025284, 67.510536],
                [-64.034439, 67.558594],
                [-63.97694, 67.649429],
                [-63.926392, 67.633331]
              ]
            ],
            [
              [
                [-96.688316, 72.883331],
                [-96.721657, 72.829163],
                [-96.641388, 72.782211],
                [-96.741379, 72.72554],
                [-96.955276, 72.734154],
                [-97.011124, 72.775818],
                [-96.921387, 72.795258],
                [-96.921387, 72.835823],
                [-96.798607, 72.881363],
                [-96.688316, 72.883331]
              ]
            ],
            [
              [
                [-95.065277, 80.680542],
                [-94.970551, 80.635269],
                [-95.190277, 80.608871],
                [-96.14917, 80.664703],
                [-95.491379, 80.699997],
                [-95.065277, 80.680542]
              ]
            ],
            [
              [
                [-79.784157, 56.94083],
                [-79.717499, 56.81361],
                [-79.757507, 56.78194],
                [-79.79306, 56.789989],
                [-79.811111, 56.89222],
                [-79.897507, 56.884991],
                [-79.858612, 56.938599],
                [-79.784157, 56.94083]
              ]
            ],
            [
              [
                [-90.027458, 68.753304],
                [-90.003014, 68.807091],
                [-89.914436, 68.853043],
                [-89.896118, 68.829712],
                [-89.925552, 68.804153],
                [-89.85611, 68.822487],
                [-89.861656, 68.79776],
                [-89.781677, 68.766663],
                [-89.944443, 68.662201],
                [-89.959167, 68.6772],
                [-89.916397, 68.704987],
                [-89.967216, 68.694702],
                [-89.956947, 68.724991],
                [-90.027458, 68.753304]
              ]
            ],
            [
              [
                [-107.983299, 67.644707],
                [-107.922203, 67.540817],
                [-108.067497, 67.539703],
                [-108.029999, 67.498871],
                [-108.109398, 67.471367],
                [-108.142799, 67.541656],
                [-108.074203, 67.557747],
                [-108.120003, 67.575821],
                [-108.117798, 67.669983],
                [-107.983299, 67.644707]
              ]
            ],
            [
              [
                [-100.479698, 75.545822],
                [-100.319702, 75.59082],
                [-100.885597, 75.545822],
                [-101.039398, 75.567207],
                [-100.235001, 75.623032],
                [-100.157799, 75.584991],
                [-100.479698, 75.545822]
              ]
            ],
            [
              [
                [-63.456108, 67.264427],
                [-63.829731, 67.284149],
                [-63.585831, 67.353317],
                [-63.58889, 67.305252],
                [-63.485001, 67.341087],
                [-63.36916, 67.302467],
                [-63.456108, 67.264427]
              ]
            ],
            [
              [
                [-74.771942, 68.673027],
                [-74.648354, 68.652206],
                [-74.654167, 68.607758],
                [-74.518341, 68.558594],
                [-74.833893, 68.569717],
                [-74.890289, 68.624977],
                [-74.771942, 68.673027]
              ]
            ],
            [
              [
                [-91.535278, 69.726929],
                [-91.73555, 69.789146],
                [-91.523621, 69.824707],
                [-91.483322, 69.871918],
                [-91.419449, 69.879967],
                [-91.502228, 69.813873],
                [-91.454178, 69.784988],
                [-91.463333, 69.763611],
                [-91.535278, 69.726929]
              ]
            ],
            [
              [
                [-102.897499, 78.26915],
                [-102.798599, 78.250267],
                [-102.781403, 78.204987],
                [-103.041702, 78.122208],
                [-103.212799, 78.120529],
                [-103.282204, 78.157761],
                [-103.062798, 78.258041],
                [-102.897499, 78.26915]
              ]
            ],
            [
              [
                [-79.706673, 57.58083],
                [-79.705002, 57.50861],
                [-79.771118, 57.51416],
                [-79.805557, 57.418049],
                [-79.85556, 57.479988],
                [-79.810547, 57.48193],
                [-79.776672, 57.530819],
                [-79.82695, 57.538052],
                [-79.734161, 57.618881],
                [-79.706673, 57.58083]
              ]
            ],
            [
              [
                [-91.072243, 77.253326],
                [-90.815002, 77.240257],
                [-90.713623, 77.200821],
                [-90.868332, 77.138321],
                [-91.184723, 77.163597],
                [-91.299156, 77.217758],
                [-91.072243, 77.253326]
              ]
            ],
            [
              [
                [-64.171944, 63.63361],
                [-64.078339, 63.550541],
                [-64.10556, 63.532211],
                [-64.092499, 63.481659],
                [-64.209167, 63.575001],
                [-64.212784, 63.6236],
                [-64.171944, 63.63361]
              ]
            ],
            [
              [
                [-64.998894, 64.354431],
                [-64.989166, 64.317757],
                [-65.037514, 64.290268],
                [-64.884743, 64.287773],
                [-64.989166, 64.209152],
                [-65.065552, 64.227478],
                [-65.114723, 64.329437],
                [-64.998894, 64.354431]
              ]
            ],
            [
              [
                [-99.528877, 76.72554],
                [-99.433884, 76.694702],
                [-99.58168, 76.670258],
                [-99.65834, 76.680542],
                [-99.551941, 76.709717],
                [-100.128601, 76.721916],
                [-100.037804, 76.751389],
                [-99.528877, 76.72554]
              ]
            ],
            [
              [
                [-108.951103, 67.973312],
                [-108.884499, 67.948593],
                [-108.910599, 67.924149],
                [-108.866096, 67.900269],
                [-108.900597, 67.869713],
                [-109.198898, 67.972488],
                [-109.058601, 67.951927],
                [-109.074203, 67.974426],
                [-108.951103, 67.973312]
              ]
            ],
            [
              [
                [-72.924713, 71.649429],
                [-72.709167, 71.655258],
                [-72.660553, 71.598038],
                [-72.742493, 71.534149],
                [-72.949997, 71.547211],
                [-73.039993, 71.579987],
                [-72.924713, 71.649429]
              ]
            ],
            [
              [
                [-67.440552, 69.588318],
                [-67.310547, 69.549149],
                [-67.545273, 69.525818],
                [-67.492493, 69.495529],
                [-67.749733, 69.521103],
                [-67.550278, 69.565536],
                [-67.518341, 69.600273],
                [-67.440552, 69.588318]
              ]
            ],
            [
              [
                [-82.796112, 69.805252],
                [-82.460281, 69.761658],
                [-82.49472, 69.752487],
                [-82.453056, 69.720543],
                [-82.54277, 69.704163],
                [-82.720001, 69.733322],
                [-82.877777, 69.774986],
                [-82.796112, 69.805252]
              ]
            ],
            [
              [
                [-65.713623, 61.824169],
                [-65.678596, 61.746658],
                [-65.948036, 61.790279],
                [-65.809433, 61.863331],
                [-65.752228, 61.850552],
                [-65.794724, 61.84277],
                [-65.780563, 61.825829],
                [-65.713623, 61.824169]
              ]
            ],
            [
              [
                [-107.581703, 67.196373],
                [-107.319504, 67.098877],
                [-107.527802, 67.078049],
                [-107.629204, 67.183319],
                [-107.581703, 67.196373]
              ]
            ],
            [
              [
                [-66.820847, 66.388046],
                [-66.573624, 66.310806],
                [-66.641953, 66.279427],
                [-66.811661, 66.316673],
                [-66.847778, 66.342484],
                [-66.783073, 66.339157],
                [-66.958618, 66.406372],
                [-66.820847, 66.388046]
              ]
            ],
            [
              [
                [-93.08667, 61.829441],
                [-93.135834, 61.87249],
                [-93.218887, 61.879429],
                [-93.223618, 61.913052],
                [-93.06221, 61.93055],
                [-92.969452, 61.888329],
                [-93.001678, 61.84721],
                [-93.08667, 61.829441]
              ]
            ],
            [
              [
                [-79.95195, 53.348331],
                [-79.957497, 53.31221],
                [-79.911942, 53.29055],
                [-79.942757, 53.266941],
                [-80.018066, 53.265831],
                [-80.085281, 53.326939],
                [-80.061394, 53.355549],
                [-79.944717, 53.36805],
                [-79.95195, 53.348331]
              ]
            ],
            [
              [
                [-88.05278, 78.445534],
                [-88.05722, 78.407494],
                [-88.154449, 78.36554],
                [-88.166397, 78.308029],
                [-88.254997, 78.247208],
                [-88.360001, 78.237762],
                [-88.407501, 78.264427],
                [-88.404449, 78.298027],
                [-88.327217, 78.336647],
                [-88.199722, 78.354431],
                [-88.248047, 78.373306],
                [-88.235283, 78.426933],
                [-88.05278, 78.445534]
              ]
            ],
            [
              [
                [-107.645302, 73.570267],
                [-107.930603, 73.539429],
                [-108.084999, 73.585823],
                [-108.0075, 73.618591],
                [-107.681099, 73.621368],
                [-107.584702, 73.603043],
                [-107.645302, 73.570267]
              ]
            ],
            [
              [
                [-68.4189, 68.810257],
                [-67.661392, 68.701927],
                [-67.86972, 68.698593],
                [-68.451401, 68.783867],
                [-68.4189, 68.810257]
              ]
            ],
            [
              [
                [-64.334091, 63.852081],
                [-64.179993, 63.865551],
                [-64.179169, 63.8111],
                [-64.236938, 63.79583],
                [-64.180832, 63.785271],
                [-64.256958, 63.774158],
                [-64.398354, 63.845539],
                [-64.334091, 63.852081]
              ]
            ],
            [
              [
                [-78.939987, 72.435806],
                [-78.731667, 72.369431],
                [-78.950287, 72.334991],
                [-79.053047, 72.360809],
                [-79.066673, 72.420258],
                [-78.939987, 72.435806]
              ]
            ],
            [
              [
                [-83.576683, 69.780823],
                [-83.708618, 69.75943],
                [-83.655838, 69.732208],
                [-83.688599, 69.719437],
                [-83.917221, 69.778587],
                [-83.886948, 69.818604],
                [-83.832497, 69.825272],
                [-83.576683, 69.780823]
              ]
            ],
            [
              [
                [-64.884743, 62.594151],
                [-64.839447, 62.57777],
                [-64.860817, 62.561378],
                [-64.972778, 62.52972],
                [-65.141678, 62.54694],
                [-65.003067, 62.598881],
                [-64.884743, 62.594151]
              ]
            ],
            [
              [
                [-110.728302, 68.484421],
                [-111.097504, 68.477203],
                [-110.821701, 68.548027],
                [-110.745796, 68.526382],
                [-110.696098, 68.486366],
                [-110.728302, 68.484421]
              ]
            ],
            [
              [
                [-97.327499, 69.931664],
                [-97.347229, 69.896652],
                [-97.23056, 69.883331],
                [-97.269173, 69.852478],
                [-97.492767, 69.917763],
                [-97.453613, 69.963043],
                [-97.327499, 69.931664]
              ]
            ],
            [
              [
                [-73.730827, 64.386383],
                [-73.697769, 64.269989],
                [-73.780838, 64.283051],
                [-73.833618, 64.331673],
                [-73.781387, 64.405548],
                [-73.730827, 64.386383]
              ]
            ],
            [
              [
                [-83.347778, 65.832489],
                [-83.485283, 65.800812],
                [-83.586121, 65.854156],
                [-83.370003, 65.866653],
                [-83.285278, 65.838882],
                [-83.347778, 65.832489]
              ]
            ],
            [
              [
                [-109.447197, 68.092209],
                [-109.3153, 68.035812],
                [-109.314201, 67.985809],
                [-109.538902, 68.047493],
                [-109.470001, 68.045258],
                [-109.5047, 68.088882],
                [-109.447197, 68.092209]
              ]
            ],
            [
              [
                [-82.01001, 68.332764],
                [-82.07251, 68.30304],
                [-82.345551, 68.367752],
                [-82.234734, 68.354431],
                [-82.243057, 68.383331],
                [-82.216949, 68.384163],
                [-82.01001, 68.332764]
              ]
            ],
            [
              [
                [-79.55249, 72.451103],
                [-79.436394, 72.41832],
                [-79.440552, 72.370529],
                [-79.53389, 72.3461],
                [-79.609444, 72.366928],
                [-79.683319, 72.430542],
                [-79.55249, 72.451103]
              ]
            ],
            [
              [
                [-101.520302, 69.197479],
                [-101.495003, 69.160263],
                [-101.563599, 69.099991],
                [-101.698303, 69.095261],
                [-101.601402, 69.151657],
                [-101.710297, 69.169144],
                [-101.695297, 69.20694],
                [-101.520302, 69.197479]
              ]
            ],
            [
              [
                [-74.555557, 78.776093],
                [-74.16362, 78.716087],
                [-74.334167, 78.675262],
                [-74.614166, 78.702766],
                [-74.706673, 78.737488],
                [-74.632217, 78.777206],
                [-74.555557, 78.776093]
              ]
            ],
            [
              [
                [-94.515007, 78.278053],
                [-94.309158, 78.179153],
                [-94.506119, 78.17276],
                [-94.671112, 78.240807],
                [-94.694153, 78.258614],
                [-94.660828, 78.27916],
                [-94.515007, 78.278053]
              ]
            ],
            [
              [
                [-97.337784, 67.7211],
                [-97.327217, 67.681664],
                [-97.370537, 67.657761],
                [-97.502792, 67.62442],
                [-97.551392, 67.642761],
                [-97.529716, 67.676376],
                [-97.560547, 67.692749],
                [-97.337784, 67.7211]
              ]
            ],
            [
              [
                [-108.286102, 67.533051],
                [-108.301697, 67.491089],
                [-108.368301, 67.467209],
                [-108.496399, 67.524429],
                [-108.467499, 67.540543],
                [-108.491402, 67.563026],
                [-108.457802, 67.568047],
                [-108.370003, 67.543053],
                [-108.3358, 67.565811],
                [-108.286102, 67.533051]
              ]
            ],
            [
              [
                [-111.528603, 68.290543],
                [-111.605301, 68.281097],
                [-111.6717, 68.224701],
                [-111.7789, 68.241089],
                [-111.703903, 68.299423],
                [-111.513603, 68.311096],
                [-111.528603, 68.290543]
              ]
            ],
            [
              [
                [-79.843887, 56.85833],
                [-79.813606, 56.81694],
                [-79.876663, 56.747768],
                [-79.941101, 56.763611],
                [-79.945831, 56.833599],
                [-79.843887, 56.85833]
              ]
            ],
            [
              [
                [-73.213333, 71.698593],
                [-73.149986, 71.674423],
                [-73.282784, 71.637772],
                [-73.352493, 71.55748],
                [-73.450562, 71.584152],
                [-73.348343, 71.658333],
                [-73.213333, 71.698593]
              ]
            ],
            [
              [
                [-108.048599, 67.949142],
                [-108.0914, 67.880814],
                [-108.254204, 67.881927],
                [-108.197998, 67.950821],
                [-108.100601, 67.974991],
                [-108.048599, 67.949142]
              ]
            ],
            [
              [
                [-90, 73.986359],
                [-90.285004, 74.029709],
                [-90, 74.065613],
                [-89.901947, 74.037773],
                [-90, 73.986359]
              ]
            ],
            [
              [
                [-62.23138, 66.26944],
                [-62.14806, 66.207764],
                [-62.43, 66.229156],
                [-62.23138, 66.26944]
              ]
            ],
            [
              [
                [-104.071404, 77.161377],
                [-103.998901, 77.123871],
                [-104.196701, 77.077477],
                [-104.431702, 77.098877],
                [-104.300797, 77.155258],
                [-104.071404, 77.161377]
              ]
            ],
            [
              [
                [-77.676102, 77.8647],
                [-77.568619, 77.849716],
                [-77.579178, 77.818604],
                [-77.680557, 77.792763],
                [-77.875267, 77.774429],
                [-77.955002, 77.830276],
                [-77.676102, 77.8647]
              ]
            ],
            [
              [
                [-65.648354, 68.168587],
                [-65.502792, 68.151093],
                [-65.495537, 68.128311],
                [-65.686943, 68.095833],
                [-65.716949, 68.175812],
                [-65.648354, 68.168587]
              ]
            ],
            [
              [
                [-90.143578, 68.967819],
                [-89.914169, 69.006943],
                [-89.933609, 68.950546],
                [-89.906662, 68.922493],
                [-90.143578, 68.967819]
              ]
            ],
            [
              [
                [-95.328613, 71.842209],
                [-95.259743, 71.827477],
                [-95.310547, 71.737198],
                [-95.488327, 71.740807],
                [-95.361107, 71.790268],
                [-95.438889, 71.790268],
                [-95.450287, 71.818878],
                [-95.328613, 71.842209]
              ]
            ],
            [
              [
                [-95.372223, 77.238037],
                [-95.2164, 77.20166],
                [-95.206947, 77.177757],
                [-95.24527, 77.164146],
                [-95.639999, 77.237762],
                [-95.372223, 77.238037]
              ]
            ],
            [
              [
                [-94.854446, 76.136658],
                [-94.816101, 76.101089],
                [-94.928596, 76.051086],
                [-95.048889, 76.050812],
                [-95.147232, 76.116928],
                [-95.013062, 76.10582],
                [-94.854446, 76.136658]
              ]
            ],
            [
              [
                [-82.636398, 69.871094],
                [-82.517227, 69.854156],
                [-82.426102, 69.786926],
                [-82.564438, 69.800812],
                [-82.688599, 69.850807],
                [-82.636398, 69.871094]
              ]
            ],
            [
              [
                [-86.06723, 72.293869],
                [-85.863892, 72.297211],
                [-85.837219, 72.262772],
                [-85.90834, 72.217758],
                [-86.09639, 72.276382],
                [-86.06723, 72.293869]
              ]
            ],
            [
              [
                [-103.331902, 75.871918],
                [-103.590797, 75.8311],
                [-103.864403, 75.871368],
                [-103.331902, 75.871918]
              ]
            ],
            [
              [
                [-97.243057, 77.037491],
                [-97.093063, 77.00499],
                [-97.256393, 76.967484],
                [-97.473053, 76.980553],
                [-97.243057, 77.037491]
              ]
            ],
            [
              [
                [-96.455841, 75.817757],
                [-96.553596, 75.738586],
                [-96.717216, 75.7397],
                [-96.660828, 75.789429],
                [-96.455841, 75.817757]
              ]
            ],
            [
              [
                [-74.707779, 72.207207],
                [-74.796661, 72.168053],
                [-74.946953, 72.173309],
                [-74.85083, 72.217758],
                [-74.673889, 72.23027],
                [-74.707779, 72.207207]
              ]
            ],
            [
              [
                [-101.681702, 78.227478],
                [-101.6036, 78.187477],
                [-101.625801, 78.148041],
                [-101.881897, 78.162201],
                [-101.785301, 78.18692],
                [-101.753304, 78.227203],
                [-101.681702, 78.227478]
              ]
            ],
            [
              [
                [-84.097504, 76.506653],
                [-83.908051, 76.464996],
                [-83.986107, 76.423309],
                [-84.109444, 76.444427],
                [-84.103607, 76.484154],
                [-84.13945, 76.507217],
                [-84.097504, 76.506653]
              ]
            ],
            [
              [
                [-62.961109, 67.054703],
                [-62.91222, 67.014709],
                [-62.977779, 67.006653],
                [-63.138889, 67.059418],
                [-63.110561, 67.080276],
                [-62.961109, 67.054703]
              ]
            ],
            [
              [
                [-66.644447, 81.562187],
                [-66.896667, 81.587486],
                [-66.296661, 81.584717],
                [-66.644447, 81.562187]
              ]
            ],
            [
              [
                [-71.044159, 71.142761],
                [-71.112503, 71.089432],
                [-71.25473, 71.073883],
                [-71.200562, 71.125809],
                [-71.044159, 71.142761]
              ]
            ],
            [
              [
                [-78.419449, 82.899147],
                [-78.414719, 82.941933],
                [-78.361656, 82.958603],
                [-78.116943, 82.9422],
                [-78.365829, 82.883614],
                [-78.419449, 82.899147]
              ]
            ],
            [
              [
                [-91.761673, 81.548027],
                [-91.960564, 81.594994],
                [-91.58223, 81.578049],
                [-91.761673, 81.548027]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Nunavut",
          "density": "0.02158",
          "cartodb_id": 12,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-110.394402, 78.752213],
                [-109.978409, 78.671058],
                [-109.983902, 78.325546],
                [-110.411903, 78.277206],
                [-110.715599, 78.29248],
                [-111.141403, 78.386108],
                [-111.306396, 78.371094],
                [-111.2397, 78.323608],
                [-111.433601, 78.323036],
                [-111.401901, 78.283333],
                [-111.462502, 78.267487],
                [-111.82, 78.273613],
                [-111.918602, 78.332764],
                [-112.1586, 78.36998],
                [-113.142197, 78.268333],
                [-113.334198, 78.332764],
                [-113.226097, 78.356644],
                [-113.218903, 78.40387],
                [-112.6614, 78.494141],
                [-112.238098, 78.547211],
                [-111.752502, 78.550537],
                [-111.455597, 78.592758],
                [-111.378601, 78.617752],
                [-111.4347, 78.643333],
                [-111.1894, 78.688583],
                [-110.738098, 78.75444],
                [-110.394402, 78.752213]
              ]
            ],
            [
              [
                [-109.987239, 77.924049],
                [-110.2211, 77.893883],
                [-110.895798, 77.856087],
                [-110.886398, 77.820831],
                [-110.658897, 77.75972],
                [-110.105003, 77.774986],
                [-110.079498, 77.757492],
                [-110.113899, 77.692749],
                [-110.040604, 77.637497],
                [-110.162804, 77.604713],
                [-110.080299, 77.563599],
                [-110.224998, 77.505257],
                [-110.877197, 77.411377],
                [-111.276703, 77.424698],
                [-112.057198, 77.323318],
                [-112.413101, 77.356087],
                [-112.507797, 77.381363],
                [-112.601097, 77.455261],
                [-112.790802, 77.441093],
                [-112.957802, 77.469437],
                [-113.011703, 77.512772],
                [-113.197998, 77.52388],
                [-113.240303, 77.587196],
                [-113.089203, 77.619141],
                [-113.252197, 77.664703],
                [-113.206902, 77.688873],
                [-113.229401, 77.723602],
                [-113.1875, 77.739433],
                [-113.319702, 77.795532],
                [-113.209702, 77.908867],
                [-112.996101, 77.890549],
                [-112.914703, 77.927467],
                [-112.4142, 78.003052],
                [-111.932503, 78.018333],
                [-111.807999, 78.049423],
                [-111.730598, 78.024147],
                [-111.0933, 78.092484],
                [-110.861099, 78.061653],
                [-110.550003, 78.106087],
                [-109.985789, 78.101103],
                [-109.987239, 77.924049]
              ]
            ],
            [
              [
                [-110.008101, 74.833102],
                [-110.065598, 74.817757],
                [-110.345596, 74.846939],
                [-110.410797, 74.822487],
                [-110.318901, 74.798027],
                [-110.665298, 74.78804],
                [-110.596397, 74.707764],
                [-111.6772, 74.493317],
                [-112.541901, 74.409416],
                [-113.471703, 74.418907],
                [-113.696404, 74.446091],
                [-114.298302, 74.602768],
                [-114.443604, 74.664146],
                [-114.394402, 74.708328],
                [-113.709999, 74.829712],
                [-113.207199, 74.845261],
                [-113.322502, 74.879967],
                [-112.8675, 74.97554],
                [-112.014999, 75.002487],
                [-111.753304, 74.981659],
                [-111.551399, 75.011383],
                [-111.4814, 75.045822],
                [-111.5672, 75.060257],
                [-111.306396, 75.075272],
                [-111.290604, 75.091087],
                [-111.351402, 75.111649],
                [-111.266403, 75.103043],
                [-111.029198, 75.171097],
                [-111.068298, 75.188583],
                [-111.001999, 75.221367],
                [-110.920502, 75.223602],
                [-111.0681, 75.271927],
                [-111.230797, 75.26416],
                [-111.391701, 75.181091],
                [-111.608597, 75.165543],
                [-111.577202, 75.1436],
                [-111.695297, 75.145828],
                [-111.760803, 75.184708],
                [-111.900597, 75.131927],
                [-112.409698, 75.123596],
                [-112.470802, 75.146378],
                [-112.412003, 75.159012],
                [-112.294701, 75.198029],
                [-112.387199, 75.209427],
                [-112.339401, 75.223602],
                [-112.408897, 75.240807],
                [-112.461899, 75.218872],
                [-112.432503, 75.18692],
                [-112.592499, 75.181664],
                [-112.637199, 75.204987],
                [-112.594704, 75.25],
                [-112.679199, 75.277771],
                [-112.660599, 75.266388],
                [-112.713097, 75.256104],
                [-112.735802, 75.203323],
                [-112.688599, 75.171921],
                [-112.699997, 75.138321],
                [-112.806999, 75.115807],
                [-113.038902, 75.093597],
                [-113.134201, 75.14415],
                [-113.178001, 75.09166],
                [-113.254501, 75.076103],
                [-113.941399, 75.056931],
                [-113.973099, 75.096367],
                [-113.932503, 75.141373],
                [-113.647499, 75.184143],
                [-113.929398, 75.173599],
                [-113.805801, 75.32666],
                [-113.318901, 75.417763],
                [-113.470596, 75.431091],
                [-113.902199, 75.37442],
                [-113.992203, 75.448318],
                [-114.065002, 75.466087],
                [-114.093903, 75.410812],
                [-114.040604, 75.362198],
                [-114.170799, 75.226929],
                [-114.284698, 75.234993],
                [-114.512802, 75.314697],
                [-114.615303, 75.274986],
                [-114.443001, 75.257217],
                [-114.297203, 75.179153],
                [-114.394997, 75.090553],
                [-114.949203, 74.969994],
                [-115.195297, 74.989967],
                [-115.225304, 75.064148],
                [-115.172798, 75.107758],
                [-115.257004, 75.179977],
                [-115.279198, 75.140823],
                [-115.404198, 75.098877],
                [-115.674202, 75.138321],
                [-115.595299, 75.117203],
                [-115.611397, 75.098877],
                [-115.537498, 75.027206],
                [-115.690598, 74.964157],
                [-116.253899, 75.058319],
                [-116.041702, 75.122757],
                [-116.321098, 75.103317],
                [-116.241096, 75.168869],
                [-116.239197, 75.201393],
                [-116.301399, 75.203049],
                [-116.560501, 75.179153],
                [-116.694199, 75.116653],
                [-117.457802, 75.188873],
                [-117.683899, 75.253052],
                [-117.661903, 75.298027],
                [-117.555603, 75.310806],
                [-117.575302, 75.33638],
                [-117.455299, 75.400269],
                [-117.218903, 75.476379],
                [-115.991402, 75.485809],
                [-115.619698, 75.578873],
                [-115.2686, 75.595833],
                [-115.2883, 75.620529],
                [-115.267799, 75.635818],
                [-114.998001, 75.695824],
                [-115.714401, 75.642487],
                [-116.119202, 75.572769],
                [-116.863098, 75.564148],
                [-117.25, 75.586113],
                [-117.016701, 75.737198],
                [-116.816399, 75.796371],
                [-116.037201, 75.809708],
                [-115.749397, 75.85498],
                [-115.594498, 75.833328],
                [-114.980797, 75.853043],
                [-114.798599, 75.892212],
                [-116.106697, 75.864151],
                [-116.649696, 75.884987],
                [-116.724403, 75.906372],
                [-116.7267, 75.951393],
                [-116.461899, 75.974991],
                [-116.525803, 76.002213],
                [-116.499397, 76.026657],
                [-116.705597, 76.039146],
                [-116.641403, 76.113312],
                [-116.516098, 76.157761],
                [-116.163597, 76.197197],
                [-114.790802, 76.151077],
                [-114.680801, 76.164993],
                [-115.282204, 76.233871],
                [-115.827202, 76.239433],
                [-115.9217, 76.281097],
                [-115.850304, 76.324158],
                [-115.909401, 76.345543],
                [-115.499702, 76.454712],
                [-115.0214, 76.474701],
                [-114.899696, 76.516937],
                [-114.702003, 76.515266],
                [-114.751999, 76.487762],
                [-114.4617, 76.501663],
                [-114.118301, 76.435257],
                [-114.106102, 76.355553],
                [-114.177803, 76.344711],
                [-113.983299, 76.261383],
                [-114.0597, 76.217758],
                [-113.983299, 76.190262],
                [-113.323303, 76.262772],
                [-112.999199, 76.267487],
                [-112.892197, 76.253883],
                [-112.838898, 76.214996],
                [-112.425003, 76.167763],
                [-112.528099, 76.103867],
                [-112.4133, 76.04248],
                [-112.043297, 75.998871],
                [-111.727798, 75.921654],
                [-112.074997, 75.873871],
                [-112.225601, 75.811096],
                [-111.452003, 75.836647],
                [-111.474197, 75.810806],
                [-111.592201, 75.80748],
                [-111.439201, 75.776382],
                [-111.451698, 75.742203],
                [-111.355301, 75.724426],
                [-111.4039, 75.684708],
                [-111.3722, 75.645264],
                [-111.407204, 75.61499],
                [-111.3536, 75.572487],
                [-111.292801, 75.57193],
                [-111.318604, 75.545258],
                [-111.2714, 75.522491],
                [-110.495499, 75.569153],
                [-110.389503, 75.534149],
                [-110.00402, 75.532769],
                [-110.008101, 74.833102]
              ]
            ],
            [
              [
                [-110.001897, 75.875843],
                [-110.055603, 75.890549],
                [-110.001757, 75.898089],
                [-110.001897, 75.875843]
              ]
            ],
            [
              [
                [-109.999649, 76.224774],
                [-110.201698, 76.285538],
                [-110.372803, 76.294434],
                [-110.329498, 76.336647],
                [-110.377502, 76.355553],
                [-110.383904, 76.427467],
                [-109.997979, 76.474992],
                [-109.999649, 76.224774]
              ]
            ],
            [
              [
                [-117.431396, 69.99498],
                [-117.251404, 70.072769],
                [-117.010803, 70.116928],
                [-115.908302, 70.228867],
                [-114.543098, 70.313309],
                [-114.2183, 70.316093],
                [-113.683899, 70.263046],
                [-113.333298, 70.277206],
                [-112.564697, 70.198318],
                [-112.517197, 70.207489],
                [-112.577797, 70.216087],
                [-112.543297, 70.237488],
                [-112.391403, 70.225273],
                [-112.3414, 70.234993],
                [-112.378899, 70.256653],
                [-112.138298, 70.271378],
                [-112.283302, 70.299988],
                [-111.926399, 70.252213],
                [-111.442802, 70.290268],
                [-111.583603, 70.301086],
                [-111.489197, 70.324997],
                [-111.536903, 70.349426],
                [-111.980598, 70.370819],
                [-112.169197, 70.497208],
                [-112.408302, 70.507492],
                [-112.445297, 70.534149],
                [-112.528099, 70.51416],
                [-112.709702, 70.56749],
                [-112.848602, 70.567757],
                [-112.882202, 70.538589],
                [-113.091904, 70.608871],
                [-113.185799, 70.603592],
                [-113.206703, 70.64415],
                [-113.511101, 70.677757],
                [-113.591698, 70.646103],
                [-113.938301, 70.715271],
                [-114.171097, 70.664703],
                [-114.408302, 70.673599],
                [-114.641098, 70.622482],
                [-115.399399, 70.60498],
                [-115.5811, 70.573036],
                [-115.699699, 70.598602],
                [-116.055603, 70.57222],
                [-116.211899, 70.602768],
                [-116.171097, 70.626923],
                [-116.363899, 70.63916],
                [-116.9011, 70.597214],
                [-117.338097, 70.598877],
                [-117.376701, 70.625526],
                [-117.543602, 70.595261],
                [-117.724701, 70.641373],
                [-117.741699, 70.661377],
                [-117.709396, 70.693863],
                [-117.735802, 70.711647],
                [-118.112701, 70.811462],
                [-118.3097, 70.897217],
                [-118.415001, 70.998032],
                [-117.849701, 71.156937],
                [-116.842499, 71.247208],
                [-116.800598, 71.286102],
                [-116.2089, 71.364151],
                [-116.077797, 71.365807],
                [-116.148903, 71.341087],
                [-115.760803, 71.365807],
                [-115.7267, 71.381363],
                [-115.790604, 71.396378],
                [-116.220001, 71.424423],
                [-115.740799, 71.503601],
                [-115.750298, 71.481087],
                [-115.620796, 71.498596],
                [-115.378304, 71.449707],
                [-115.057503, 71.523041],
                [-115.442802, 71.488037],
                [-115.558601, 71.508331],
                [-115.455803, 71.532494],
                [-115.706398, 71.555817],
                [-117.377197, 71.382751],
                [-117.416702, 71.391373],
                [-117.378304, 71.454163],
                [-117.471397, 71.454712],
                [-117.4478, 71.473877],
                [-117.562798, 71.49971],
                [-117.633301, 71.461929],
                [-117.483299, 71.43248],
                [-117.539398, 71.372757],
                [-117.720299, 71.400818],
                [-117.776901, 71.368591],
                [-118.112198, 71.373596],
                [-118.2836, 71.404709],
                [-118.3153, 71.439697],
                [-118.290001, 71.481659],
                [-118.054398, 71.543053],
                [-117.766403, 71.522774],
                [-117.8358, 71.554703],
                [-117.6558, 71.57193],
                [-117.887199, 71.614151],
                [-117.957199, 71.598602],
                [-117.967499, 71.626373],
                [-117.700798, 71.671371],
                [-118.027802, 71.671654],
                [-118.172798, 71.628036],
                [-118.176697, 71.596367],
                [-118.411697, 71.57666],
                [-118.386101, 71.619713],
                [-118.566399, 71.662773],
                [-118.855598, 71.662491],
                [-118.9039, 71.6147],
                [-118.880501, 71.577209],
                [-119.050301, 71.626648],
                [-119.134499, 71.765266],
                [-119.087502, 71.908333],
                [-118.929199, 72.000549],
                [-118.724998, 72.06192],
                [-118.717499, 72.116928],
                [-118.5886, 72.176086],
                [-118.112801, 72.231934],
                [-118.145302, 72.326927],
                [-118.491096, 72.353043],
                [-118.5886, 72.416656],
                [-118.536903, 72.493874],
                [-118.191299, 72.628662],
                [-117.868896, 72.699997],
                [-117.3536, 72.916382],
                [-116.941399, 72.956383],
                [-116.574203, 73.054153],
                [-114.561699, 73.375526],
                [-114.226097, 73.318329],
                [-114.016701, 73.206383],
                [-113.953903, 73.125526],
                [-114.051399, 72.959991],
                [-114.017197, 72.925812],
                [-114.052803, 72.872208],
                [-113.969498, 72.813026],
                [-114.224197, 72.794983],
                [-114.351402, 72.747482],
                [-114.327797, 72.688873],
                [-114.248596, 72.688583],
                [-114.604698, 72.601646],
                [-114.5522, 72.585541],
                [-114.558296, 72.560806],
                [-114.351402, 72.55748],
                [-114.319199, 72.589706],
                [-114.062798, 72.640266],
                [-113.893097, 72.660263],
                [-113.806702, 72.63916],
                [-113.516098, 72.701927],
                [-113.729202, 72.61554],
                [-113.699699, 72.604156],
                [-113.442802, 72.672211],
                [-113.4133, 72.734711],
                [-113.597504, 72.754173],
                [-113.5989, 72.782761],
                [-113.302803, 72.948868],
                [-113.007004, 73.008881],
                [-112.514999, 72.951103],
                [-112.3619, 72.904709],
                [-112.061699, 72.889427],
                [-111.2258, 72.722763],
                [-111.202499, 72.670532],
                [-111.276901, 72.56749],
                [-111.489197, 72.50499],
                [-111.436699, 72.488586],
                [-111.560501, 72.429153],
                [-111.905602, 72.349716],
                [-111.812798, 72.323036],
                [-111.643097, 72.348038],
                [-111.651703, 72.319153],
                [-111.726097, 72.300537],
                [-111.713898, 72.274429],
                [-111.786102, 72.222763],
                [-111.763298, 72.198593],
                [-111.653297, 72.29332],
                [-111.4244, 72.337196],
                [-111.578102, 72.339157],
                [-111.609398, 72.367203],
                [-111.350304, 72.453323],
                [-111.2286, 72.465553],
                [-111.202499, 72.460274],
                [-111.243896, 72.434563],
                [-111.371696, 72.403587],
                [-111.2808, 72.397774],
                [-111.364403, 72.342484],
                [-111.241898, 72.365257],
                [-111.033096, 72.281937],
                [-111.0914, 72.401932],
                [-110.987801, 72.434418],
                [-110.993103, 72.476379],
                [-110.796997, 72.472763],
                [-110.827797, 72.525818],
                [-110.701401, 72.575546],
                [-110.478897, 72.5522],
                [-110.601898, 72.493317],
                [-110.350601, 72.42804],
                [-110.311897, 72.434708],
                [-110.530602, 72.502487],
                [-110.384201, 72.555542],
                [-110.050598, 72.433868],
                [-110.019917, 72.442051],
                [-110.029392, 69.995491],
                [-112.48507, 69.993691],
                [-112.477477, 69.892389],
                [-112.614279, 69.896203],
                [-112.657705, 69.834517],
                [-112.862425, 69.840022],
                [-112.887108, 69.991098],
                [-117.085008, 69.999148],
                [-117.118097, 69.891887],
                [-116.513568, 69.634805],
                [-116.859908, 69.637114],
                [-116.847504, 69.648613],
                [-117.247498, 69.756943],
                [-117.431396, 69.99498]
              ]
            ],
            [
              [
                [-110.019692, 72.493611],
                [-110.255798, 72.556931],
                [-110.104202, 72.557747],
                [-110.019536, 72.529154],
                [-110.019692, 72.493611]
              ]
            ],
            [
              [
                [-110.01912, 72.623398],
                [-110.2911, 72.671097],
                [-110.178299, 72.680817],
                [-110.212799, 72.711647],
                [-110.188301, 72.726089],
                [-110.078102, 72.727074],
                [-110.018727, 72.7117],
                [-110.01912, 72.623398]
              ]
            ],
            [
              [
                [-110.018556, 72.749766],
                [-110.073601, 72.755829],
                [-110.266098, 72.734993],
                [-110.171402, 72.774986],
                [-110.2117, 72.818329],
                [-110.535599, 72.847214],
                [-110.756699, 72.971367],
                [-110.708298, 73.002487],
                [-110.511398, 73.015266],
                [-110.017534, 72.974877],
                [-110.018556, 72.749766]
              ]
            ],
            [
              [
                [-102.001701, 59.99992],
                [-104.270699, 59.9995],
                [-104.7099, 59.999771],
                [-105.5411, 60],
                [-105.859001, 59.99968],
                [-106.044403, 59.999592],
                [-106.255997, 59.999771],
                [-107.113602, 59.994709],
                [-110.0009, 59.995281],
                [-119.982903, 59.988522],
                [-123.816902, 59.994709],
                [-123.8769, 60.04166],
                [-124.029198, 60.023048],
                [-123.974197, 60.098049],
                [-124.195297, 60.32888],
                [-124.206398, 60.457211],
                [-124.418297, 60.47916],
                [-124.459396, 60.569721],
                [-124.614197, 60.668598],
                [-124.619202, 60.709721],
                [-124.476898, 60.79055],
                [-124.578903, 60.953049],
                [-124.813004, 60.972488],
                [-124.8778, 60.91721],
                [-124.871696, 60.858051],
                [-125, 60.860519],
                [-125.184196, 60.848881],
                [-125.370003, 60.782558],
                [-125.711998, 60.827259],
                [-125.887199, 60.896938],
                [-125.957001, 60.877239],
                [-126.009201, 60.80341],
                [-126.107201, 60.817009],
                [-126.113602, 60.862808],
                [-126.202202, 60.863651],
                [-126.244698, 60.850868],
                [-126.217499, 60.819771],
                [-126.253601, 60.78867],
                [-126.394501, 60.77668],
                [-126.520302, 60.814411],
                [-126.672203, 60.743469],
                [-126.753304, 60.782211],
                [-126.826103, 60.755001],
                [-126.886902, 60.78249],
                [-126.8797, 60.82999],
                [-126.925301, 60.862209],
                [-126.898903, 60.941662],
                [-126.9394, 61.002491],
                [-126.935303, 61.059158],
                [-127.055801, 61.039989],
                [-127.101898, 61.070831],
                [-127.0131, 61.12804],
                [-127.031403, 61.150539],
                [-127.007004, 61.18499],
                [-127.059402, 61.363049],
                [-127.125, 61.4011],
                [-127.141701, 61.46666],
                [-127.2883, 61.519161],
                [-127.550301, 61.511379],
                [-127.972801, 61.688599],
                [-128.006104, 61.732491],
                [-128.080597, 61.849159],
                [-128.196701, 61.844151],
                [-128.279205, 61.948879],
                [-128.388107, 61.993881],
                [-128.375305, 62.02721],
                [-128.546997, 62.122219],
                [-128.677505, 62.125271],
                [-128.765305, 62.058331],
                [-128.984207, 62.136108],
                [-129.122803, 62.116661],
                [-129.2939, 62.156101],
                [-129.199203, 62.21693],
                [-129.282196, 62.267208],
                [-129.310501, 62.326389],
                [-129.215805, 62.377491],
                [-129.308304, 62.421379],
                [-129.172195, 62.481659],
                [-129.417801, 62.584991],
                [-129.567795, 62.57444],
                [-129.487793, 62.618881],
                [-129.523895, 62.64333],
                [-129.516998, 62.672489],
                [-129.625305, 62.70499],
                [-129.630798, 62.76083],
                [-129.773895, 62.874439],
                [-129.702499, 62.89222],
                [-129.726105, 62.91415],
                [-129.705795, 62.952221],
                [-129.598907, 63.051929],
                [-129.637207, 63.08028],
                [-129.847198, 63.086941],
                [-129.835297, 63.114719],
                [-129.900299, 63.197208],
                [-129.978607, 63.203609],
                [-130.044495, 63.269989],
                [-130.151093, 63.265831],
                [-130.138306, 63.310268],
                [-130.073593, 63.33696],
                [-129.919205, 63.372761],
                [-129.897202, 63.452221],
                [-129.819504, 63.48333],
                [-129.898102, 63.57222],
                [-129.957993, 63.58527],
                [-129.948593, 63.613609],
                [-130.093002, 63.63055],
                [-130.110794, 63.66444],
                [-130.082504, 63.68832],
                [-130.109695, 63.703609],
                [-130.251999, 63.66666],
                [-130.321106, 63.69582],
                [-130.282501, 63.73777],
                [-130.103897, 63.76416],
                [-130.119705, 63.808041],
                [-130.3703, 63.84388],
                [-130.522202, 63.93499],
                [-130.5811, 63.92416],
                [-130.7556, 63.986938],
                [-130.778595, 64.009163],
                [-130.751404, 64.046097],
                [-130.863907, 64.043053],
                [-130.853607, 64.072487],
                [-130.9478, 64.137207],
                [-130.867203, 64.187759],
                [-130.937195, 64.209991],
                [-130.979706, 64.265549],
                [-131.060501, 64.279427],
                [-131.001999, 64.330276],
                [-131.141693, 64.424423],
                [-131.388596, 64.467209],
                [-131.427505, 64.416382],
                [-131.593002, 64.368591],
                [-131.8181, 64.378311],
                [-131.803894, 64.438026],
                [-131.687805, 64.498596],
                [-131.702805, 64.535538],
                [-131.915802, 64.582489],
                [-132.046402, 64.695534],
                [-132.163605, 64.70166],
                [-132.368896, 64.77832],
                [-132.570602, 64.772491],
                [-132.622498, 64.826927],
                [-132.487503, 64.870819],
                [-132.507507, 64.964432],
                [-132.333298, 65.034714],
                [-132.381699, 65.084991],
                [-132.539993, 65.093872],
                [-132.556107, 65.118042],
                [-132.527496, 65.162773],
                [-132.576401, 65.190536],
                [-132.701904, 65.15387],
                [-132.787796, 65.187759],
                [-132.714203, 65.199417],
                [-132.780594, 65.229713],
                [-132.555298, 65.2836],
                [-132.521393, 65.363037],
                [-132.327805, 65.434708],
                [-132.273605, 65.546944],
                [-132.174194, 65.59137],
                [-132.286102, 65.71582],
                [-132.573303, 65.848602],
                [-132.525803, 65.897774],
                [-132.334396, 65.94664],
                [-132.342804, 65.981934],
                [-132.5103, 65.978867],
                [-132.542496, 66.021378],
                [-132.605804, 66.031662],
                [-132.869003, 65.910477],
                [-132.993896, 65.923027],
                [-132.908401, 65.996933],
                [-132.939697, 66.02887],
                [-133.147507, 66.024696],
                [-133.407501, 65.943863],
                [-133.629395, 65.974426],
                [-133.603302, 66.051651],
                [-133.704697, 66.07193],
                [-133.673096, 66.116928],
                [-133.563904, 66.155823],
                [-133.570007, 66.27832],
                [-133.824707, 66.316673],
                [-133.768097, 66.373032],
                [-133.750305, 66.447746],
                [-133.629395, 66.445251],
                [-133.690308, 66.531937],
                [-133.5867, 66.564697],
                [-133.771896, 66.64415],
                [-133.757202, 66.685257],
                [-133.840302, 66.727768],
                [-133.763901, 66.806931],
                [-133.908096, 66.908867],
                [-134.092499, 66.955261],
                [-134.046097, 66.984421],
                [-133.8311, 67.003326],
                [-136.164505, 67.003601],
                [-136.222198, 67.064148],
                [-136.198898, 67.143333],
                [-136.236404, 67.173866],
                [-136.134506, 67.226379],
                [-136.0914, 67.302467],
                [-136.142807, 67.359421],
                [-136.116394, 67.382751],
                [-136.205307, 67.406937],
                [-136.157806, 67.507767],
                [-136.241104, 67.620529],
                [-136.421097, 67.655258],
                [-136.445007, 67.715553],
                [-136.446106, 68.873032],
                [-136.419998, 68.90052],
                [-136.027802, 68.873032],
                [-135.799194, 68.822487],
                [-135.725296, 68.772491],
                [-135.519699, 68.745819],
                [-135.399399, 68.670258],
                [-135.286407, 68.684143],
                [-135.160004, 68.657211],
                [-135.506104, 68.832489],
                [-135.337997, 68.834991],
                [-135.623596, 68.886108],
                [-135.241699, 68.926933],
                [-135.172501, 68.886383],
                [-134.977493, 68.878311],
                [-134.845596, 68.929428],
                [-134.669495, 68.873306],
                [-134.626099, 68.808319],
                [-134.5, 68.756943],
                [-134.446396, 68.700272],
                [-134.219498, 68.692513],
                [-134.287506, 68.753601],
                [-134.406403, 68.801086],
                [-134.629196, 69.008881],
                [-134.537506, 69.093872],
                [-134.338898, 69.106369],
                [-134.1539, 69.258881],
                [-133.905594, 69.287491],
                [-133.859695, 69.337486],
                [-133.688904, 69.336929],
                [-133.661102, 69.348602],
                [-133.691101, 69.359154],
                [-133.670303, 69.386658],
                [-133.550598, 69.405823],
                [-133.217499, 69.396378],
                [-133.046707, 69.433319],
                [-133.052505, 69.466087],
                [-132.966095, 69.511658],
                [-132.951904, 69.569153],
                [-132.988602, 69.595261],
                [-132.893906, 69.65387],
                [-132.417496, 69.635536],
                [-132.333603, 69.68248],
                [-132.449402, 69.654427],
                [-132.423294, 69.683319],
                [-132.441101, 69.702477],
                [-132.622498, 69.679703],
                [-132.540604, 69.740807],
                [-132.259201, 69.772491],
                [-132.480804, 69.732208],
                [-132.303101, 69.733597],
                [-132.310303, 69.710541],
                [-132.133896, 69.686653],
                [-132.107193, 69.723602],
                [-131.845306, 69.765266],
                [-131.645004, 69.86499],
                [-131.418594, 69.901932],
                [-131.4478, 69.918587],
                [-131.410294, 69.957207],
                [-131.237198, 69.9272],
                [-131.201401, 69.883881],
                [-131.220596, 69.843048],
                [-131.184998, 69.824158],
                [-131.152496, 69.854156],
                [-131.185501, 69.863312],
                [-131.170303, 69.881927],
                [-131.080597, 69.884987],
                [-131.0047, 70.003883],
                [-130.930603, 70.021378],
                [-131.019501, 70.027771],
                [-130.892197, 70.099152],
                [-130.897507, 70.065811],
                [-130.713593, 70.125526],
                [-130.748306, 70.08194],
                [-130.720596, 70.076103],
                [-130.657501, 70.096939],
                [-130.656998, 70.134163],
                [-130.472198, 70.173866],
                [-130.522202, 70.158867],
                [-130.535599, 70.107483],
                [-130.477203, 70.110527],
                [-130.504196, 70.13443],
                [-130.411407, 70.131927],
                [-130.386703, 70.165543],
                [-130.324997, 70.134163],
                [-130.331696, 70.105263],
                [-130.285294, 70.101646],
                [-130.336105, 70.083054],
                [-130.279495, 70.082657],
                [-130.170807, 70.105263],
                [-130.195297, 70.062759],
                [-130.168594, 70.053307],
                [-130.104996, 70.109711],
                [-130.074707, 70.106369],
                [-130.082794, 70.073883],
                [-129.951096, 70.099426],
                [-129.990295, 70.068878],
                [-129.8936, 70.077766],
                [-129.854996, 70.10054],
                [-129.853302, 70.149147],
                [-129.7883, 70.177757],
                [-129.832504, 70.195534],
                [-129.730804, 70.218597],
                [-129.740005, 70.248032],
                [-129.673096, 70.266937],
                [-129.591904, 70.188583],
                [-129.629395, 70.160812],
                [-129.459198, 70.147491],
                [-129.502808, 70.115257],
                [-129.473297, 70.092758],
                [-129.404694, 70.123032],
                [-129.459396, 70.053307],
                [-129.553604, 70.042763],
                [-129.497498, 70.020538],
                [-130.521896, 69.782494],
                [-130.578293, 69.708038],
                [-130.780304, 69.676933],
                [-130.844101, 69.599716],
                [-130.928604, 69.563309],
                [-131.039703, 69.601646],
                [-131.039993, 69.640823],
                [-131.331696, 69.603317],
                [-131.332794, 69.574707],
                [-131.645599, 69.577209],
                [-131.667801, 69.571663],
                [-131.657196, 69.531662],
                [-131.755905, 69.56694],
                [-132.006393, 69.527206],
                [-132.080002, 69.48082],
                [-132.042496, 69.448868],
                [-132.098907, 69.451103],
                [-132.094101, 69.418053],
                [-132.141693, 69.412773],
                [-132.097504, 69.396057],
                [-132.094696, 69.362762],
                [-132.339401, 69.311096],
                [-132.315796, 69.247757],
                [-132.381393, 69.305252],
                [-132.435806, 69.298599],
                [-132.434204, 69.250267],
                [-132.483902, 69.236366],
                [-132.495499, 69.159416],
                [-132.513306, 69.263046],
                [-132.545593, 69.283333],
                [-132.717804, 69.266388],
                [-132.837997, 69.217209],
                [-132.8228, 69.1922],
                [-132.907196, 69.12442],
                [-132.829697, 69.106934],
                [-132.901901, 69.071663],
                [-132.888306, 68.979713],
                [-132.922806, 68.985809],
                [-132.946106, 69.037491],
                [-132.919205, 69.074997],
                [-133.016693, 69.044983],
                [-133.098602, 69.06694],
                [-133.200806, 69.032494],
                [-133.198898, 68.977203],
                [-133.251099, 68.963608],
                [-133.210495, 68.944138],
                [-133.233597, 68.9086],
                [-133.334702, 68.866089],
                [-133.399399, 68.881363],
                [-133.3694, 68.910538],
                [-133.466095, 68.888321],
                [-133.425598, 68.857483],
                [-133.484695, 68.850273],
                [-133.463898, 68.79332],
                [-133.054199, 68.69136],
                [-132.918304, 68.690262],
                [-132.933594, 68.709717],
                [-133.152496, 68.720833],
                [-133.266098, 68.77887],
                [-133.139694, 68.766937],
                [-133.251999, 68.797981],
                [-133.335495, 68.789703],
                [-133.380295, 68.844711],
                [-133.237793, 68.861099],
                [-133.121902, 68.805817],
                [-132.938004, 68.795822],
                [-132.962204, 68.8461],
                [-132.936707, 68.85498],
                [-132.707199, 68.786652],
                [-132.733307, 68.830833],
                [-132.491898, 68.801086],
                [-132.422699, 68.835831],
                [-132.397003, 68.8647],
                [-132.553299, 68.916092],
                [-132.492493, 68.877197],
                [-132.598602, 68.89415],
                [-132.665298, 68.841927],
                [-132.787201, 68.847488],
                [-132.705002, 68.880814],
                [-132.833298, 68.923599],
                [-132.675797, 68.926651],
                [-132.855804, 68.984421],
                [-132.867996, 69.026932],
                [-132.827194, 69.040268],
                [-132.865494, 69.068329],
                [-132.496094, 69.132202],
                [-132.425003, 69.233597],
                [-132.404205, 69.216377],
                [-132.423904, 69.150269],
                [-132.467499, 69.107208],
                [-132.384995, 69.13916],
                [-132.320602, 69.233871],
                [-132.291702, 69.203873],
                [-132.342194, 69.188873],
                [-132.289993, 69.1772],
                [-132.197006, 69.212196],
                [-132.237198, 69.161102],
                [-132.222504, 69.141663],
                [-132.163605, 69.24054],
                [-132.076096, 69.226089],
                [-131.953293, 69.260269],
                [-131.9095, 69.309708],
                [-131.876907, 69.310806],
                [-131.913895, 69.280823],
                [-131.865005, 69.283051],
                [-131.714401, 69.397774],
                [-131.849701, 69.369141],
                [-131.887497, 69.405258],
                [-131.970001, 69.406372],
                [-131.846603, 69.426086],
                [-131.645798, 69.421654],
                [-131.679993, 69.451927],
                [-131.652206, 69.471916],
                [-131.416107, 69.435532],
                [-131.486099, 69.402206],
                [-131.480804, 69.358597],
                [-131.525803, 69.329437],
                [-131.412796, 69.366653],
                [-131.405594, 69.41832],
                [-131.358307, 69.419983],
                [-131.370804, 69.454437],
                [-131.330795, 69.442467],
                [-131.321899, 69.493317],
                [-131.241898, 69.406372],
                [-131.274704, 69.49498],
                [-131.245804, 69.498322],
                [-131.212997, 69.387497],
                [-131.414505, 69.296944],
                [-131.160599, 69.388603],
                [-131.1642, 69.495529],
                [-131.247803, 69.583054],
                [-131.113602, 69.513321],
                [-131.128296, 69.361923],
                [-131.062195, 69.510269],
                [-131.196106, 69.589981],
                [-131.093597, 69.609421],
                [-131.104401, 69.571663],
                [-131.027802, 69.463882],
                [-131.116394, 69.322487],
                [-131.030899, 69.371918],
                [-130.962997, 69.472214],
                [-130.990295, 69.539429],
                [-130.946396, 69.539146],
                [-130.932495, 69.419144],
                [-131.047806, 69.336113],
                [-131.016998, 69.30748],
                [-130.935501, 69.388321],
                [-130.897797, 69.385818],
                [-130.906403, 69.328873],
                [-130.9991, 69.268333],
                [-130.989197, 69.236649],
                [-131.024704, 69.209717],
                [-131.014206, 69.136932],
                [-130.922806, 69.135269],
                [-130.956696, 69.161926],
                [-130.941696, 69.270538],
                [-130.794495, 69.346367],
                [-130.813004, 69.3797],
                [-130.660294, 69.429428],
                [-130.652802, 69.457764],
                [-130.748306, 69.453598],
                [-130.443893, 69.582489],
                [-130.452194, 69.613312],
                [-130.333893, 69.681374],
                [-130.365295, 69.694427],
                [-129.602203, 69.818878],
                [-129.099396, 69.858871],
                [-128.9319, 69.971649],
                [-128.900299, 69.971878],
                [-128.854706, 69.954712],
                [-128.898895, 69.962769],
                [-128.8983, 69.914429],
                [-128.9561, 69.889709],
                [-128.934402, 69.844147],
                [-129.169495, 69.824707],
                [-129.1297, 69.74942],
                [-129.149994, 69.695534],
                [-128.977493, 69.674698],
                [-128.921402, 69.687187],
                [-128.970795, 69.711113],
                [-128.961395, 69.732758],
                [-128.795807, 69.756943],
                [-128.587997, 69.872208],
                [-128.324203, 69.948318],
                [-128.308594, 70.008041],
                [-128.369705, 70.095833],
                [-128.343002, 70.116928],
                [-128.109207, 70.182213],
                [-127.514999, 70.221649],
                [-128.028397, 70.286377],
                [-128.112198, 70.359711],
                [-127.959999, 70.347488],
                [-127.915604, 70.396652],
                [-128.192795, 70.386932],
                [-128.135803, 70.523041],
                [-127.996696, 70.590553],
                [-127.463097, 70.409149],
                [-127.173599, 70.272217],
                [-126.893303, 70.008881],
                [-126.7742, 69.974426],
                [-126.795303, 69.943863],
                [-126.765602, 69.928307],
                [-126.811897, 69.905258],
                [-126.705803, 69.766098],
                [-126.255798, 69.526657],
                [-125.910599, 69.405548],
                [-125.6689, 69.389969],
                [-125.426399, 69.31218],
                [-125.365501, 69.342468],
                [-125.457802, 69.384407],
                [-125.456902, 69.410248],
                [-125.165298, 69.381638],
                [-125.1119, 69.415817],
                [-125.135803, 69.439133],
                [-125.089401, 69.449707],
                [-125.266403, 69.442177],
                [-125.295303, 69.47081],
                [-125.622498, 69.418587],
                [-125.578102, 69.471649],
                [-125.498596, 69.474693],
                [-125.511101, 69.491928],
                [-125.464699, 69.512207],
                [-125.313301, 69.497742],
                [-125.324203, 69.523857],
                [-125.118103, 69.485786],
                [-125.292801, 69.549133],
                [-125.225304, 69.586639],
                [-125.437202, 69.608871],
                [-125.372803, 69.6147],
                [-125.413597, 69.638321],
                [-125.365501, 69.690262],
                [-125.241096, 69.686371],
                [-125.016998, 69.740517],
                [-124.967499, 69.726929],
                [-124.925003, 69.644707],
                [-124.861702, 69.688583],
                [-124.871101, 69.713882],
                [-124.821999, 69.714996],
                [-124.897499, 69.750549],
                [-125.241402, 69.760246],
                [-125.276398, 69.808243],
                [-125.184402, 69.855797],
                [-125.165001, 69.795502],
                [-125.058601, 69.789688],
                [-125.0214, 69.864967],
                [-124.893303, 69.940262],
                [-124.629997, 69.979431],
                [-124.684196, 70.01915],
                [-124.764503, 69.970833],
                [-124.852501, 69.986099],
                [-124.795303, 70.008881],
                [-124.976402, 70.007217],
                [-125.121101, 69.967506],
                [-125.111397, 69.94194],
                [-125.206703, 69.933228],
                [-125.188301, 70.009033],
                [-125, 70.079987],
                [-124.986702, 70.041656],
                [-125.047997, 70.023041],
                [-125, 70.018646],
                [-124.863297, 70.027206],
                [-124.956902, 70.032211],
                [-124.834198, 70.068047],
                [-124.645302, 70.07193],
                [-124.584702, 70.011932],
                [-124.5336, 70.01944],
                [-124.556702, 70.036377],
                [-124.526398, 70.053589],
                [-124.436096, 70.036377],
                [-124.418098, 70.046371],
                [-124.449402, 70.080276],
                [-124.577797, 70.0811],
                [-124.519997, 70.103867],
                [-124.735802, 70.089432],
                [-124.6847, 70.108322],
                [-124.752502, 70.121643],
                [-124.680298, 70.149719],
                [-124.385803, 70.144707],
                [-124.446098, 70.108871],
                [-124.361397, 70.073608],
                [-124.376701, 70.023323],
                [-124.488899, 70.016098],
                [-124.417801, 69.989433],
                [-124.537804, 69.963882],
                [-124.438599, 69.952766],
                [-124.441902, 69.920258],
                [-124.5056, 69.903053],
                [-124.403297, 69.857758],
                [-124.501701, 69.784416],
                [-124.479401, 69.755547],
                [-124.500603, 69.725807],
                [-124.294998, 69.695251],
                [-124.201401, 69.729431],
                [-124.069702, 69.723602],
                [-124.035301, 69.678864],
                [-124.203598, 69.619713],
                [-124.240501, 69.550262],
                [-124.419998, 69.470261],
                [-124.414398, 69.412773],
                [-124.518898, 69.40416],
                [-124.446701, 69.367203],
                [-124.263603, 69.348602],
                [-124.095299, 69.35498],
                [-124.053001, 69.386932],
                [-124.006104, 69.344711],
                [-124.016403, 69.37915],
                [-123.984703, 69.404984],
                [-123.889503, 69.365257],
                [-123.817001, 69.388893],
                [-123.68, 69.353317],
                [-123.477798, 69.381088],
                [-123.380798, 69.410538],
                [-123.450798, 69.44693],
                [-123.400002, 69.490257],
                [-123.168602, 69.495247],
                [-123.126701, 69.571114],
                [-123.162201, 69.618591],
                [-123.096397, 69.670532],
                [-123.108902, 69.747757],
                [-123.152199, 69.768051],
                [-122.959198, 69.833603],
                [-122.807999, 69.793587],
                [-122.754997, 69.799149],
                [-122.741898, 69.830276],
                [-122.202797, 69.794983],
                [-121.930603, 69.814423],
                [-121.443001, 69.765549],
                [-120.881104, 69.638893],
                [-120.66524, 69.551327],
                [-120.664292, 69.542782],
                [-120.658461, 68.001268],
                [-112.498556, 65.506191],
                [-110.651709, 65.499518],
                [-109.387397, 64.826919],
                [-101.984001, 64.21341],
                [-102.001701, 59.99992]
              ]
            ],
            [
              [
                [-119.744797, 74.025513],
                [-119.750603, 74.09137],
                [-119.611397, 74.128593],
                [-119.465302, 74.2211],
                [-119.121101, 74.199707],
                [-119.090797, 74.150818],
                [-119.131104, 74.126083],
                [-119.0653, 74.103317],
                [-119.147202, 74.062187],
                [-119.187202, 73.994141],
                [-119.167503, 73.987198],
                [-119.073898, 74.015266],
                [-118.973099, 74.000267],
                [-118.790298, 74.107758],
                [-118.881104, 74.17804],
                [-118.673897, 74.219994],
                [-118.101898, 74.276382],
                [-117.438599, 74.229431],
                [-116.785004, 74.059708],
                [-115.894402, 73.718597],
                [-115.614197, 73.668053],
                [-115.366898, 73.545822],
                [-115.323097, 73.474426],
                [-115.834702, 73.339981],
                [-116.4561, 73.257492],
                [-117.244202, 73.054703],
                [-117.466103, 73.036377],
                [-118.366096, 72.824432],
                [-118.496101, 72.763611],
                [-118.753098, 72.736923],
                [-119.137497, 72.632477],
                [-119.183601, 72.595543],
                [-119.170799, 72.542763],
                [-119.309402, 72.438873],
                [-119.332497, 72.387207],
                [-119.311096, 72.352203],
                [-119.801399, 72.221367],
                [-120.029198, 72.219147],
                [-120.1847, 72.232758],
                [-120.139999, 72.267761],
                [-120.251099, 72.258614],
                [-120.258598, 72.234154],
                [-120.180298, 72.203873],
                [-120.144997, 72.1436],
                [-120.194199, 72.078323],
                [-120.449203, 71.94693],
                [-120.379204, 71.883881],
                [-120.426399, 71.744431],
                [-120.3769, 71.688026],
                [-120.543297, 71.516663],
                [-120.877197, 71.44136],
                [-121.429199, 71.378311],
                [-121.603104, 71.399429],
                [-121.531998, 71.417763],
                [-121.569702, 71.45166],
                [-121.743896, 71.453323],
                [-122.121696, 71.267212],
                [-122.605003, 71.174988],
                [-122.781097, 71.086113],
                [-123.095001, 71.079987],
                [-123.223297, 71.114151],
                [-123.403297, 71.211929],
                [-123.665802, 71.496368],
                [-123.801697, 71.535538],
                [-123.948898, 71.658333],
                [-125.236397, 71.94191],
                [-124.953903, 71.938026],
                [-124.939697, 71.95694],
                [-125, 71.970657],
                [-125.761398, 71.950821],
                [-125.997803, 71.973602],
                [-125.7789, 71.986366],
                [-125.715302, 72.090271],
                [-125.7883, 72.118317],
                [-125.566399, 72.178864],
                [-125.660004, 72.19136],
                [-125.6567, 72.251083],
                [-125.501701, 72.266373],
                [-125.478302, 72.299133],
                [-125.532204, 72.30191],
                [-125.391998, 72.396637],
                [-125.436699, 72.409126],
                [-125.343597, 72.414978],
                [-125.358597, 72.447189],
                [-125.300598, 72.483307],
                [-125, 72.560463],
                [-124.947998, 72.570541],
                [-124.968903, 72.60498],
                [-125.098099, 72.630814],
                [-124.940598, 72.702477],
                [-125.0186, 72.702759],
                [-124.971901, 72.755829],
                [-125.0261, 72.821091],
                [-125.114403, 72.861649],
                [-124.943001, 72.854156],
                [-124.4758, 72.9272],
                [-124.522499, 72.952766],
                [-124.4972, 72.977203],
                [-124.624702, 73.020538],
                [-124.726097, 73.006653],
                [-124.868896, 73.080551],
                [-124.785004, 73.138893],
                [-124.644699, 73.156372],
                [-124.668098, 73.192749],
                [-124.482498, 73.346649],
                [-124.4039, 73.371918],
                [-124.433098, 73.421371],
                [-124.068604, 73.535812],
                [-124.038902, 73.586929],
                [-124.078598, 73.622757],
                [-124.067802, 73.654709],
                [-123.8442, 73.696091],
                [-123.838303, 73.743874],
                [-123.774696, 73.764427],
                [-123.847801, 73.825272],
                [-124.217499, 73.872757],
                [-124.195801, 73.916382],
                [-124.352501, 73.976379],
                [-124.381104, 74.037773],
                [-124.462502, 74.069717],
                [-124.469704, 74.101089],
                [-124.426697, 74.109711],
                [-124.535301, 74.20665],
                [-124.516403, 74.25],
                [-124.693001, 74.26944],
                [-124.604202, 74.312477],
                [-124.762497, 74.315536],
                [-124.770798, 74.340271],
                [-123.253899, 74.444138],
                [-122.337502, 74.4711],
                [-121.5186, 74.548866],
                [-121.150803, 74.509987],
                [-121.002197, 74.467758],
                [-120.971703, 74.418053],
                [-120.217201, 74.282494],
                [-119.525002, 74.229713],
                [-119.6633, 74.223877],
                [-119.651398, 74.181664],
                [-119.824203, 74.094711],
                [-119.827202, 74.059143],
                [-119.744797, 74.025513]
              ]
            ],
            [
              [
                [-116.144997, 77.023323],
                [-115.7314, 76.949707],
                [-115.903297, 76.893883],
                [-116.365303, 76.926376],
                [-116.183601, 76.845833],
                [-116.000298, 76.811371],
                [-115.996696, 76.770828],
                [-115.896698, 76.69165],
                [-116.011101, 76.676376],
                [-116.093102, 76.619141],
                [-116.322502, 76.5811],
                [-117.040001, 76.537491],
                [-117.076401, 76.508881],
                [-116.983597, 76.454987],
                [-117.002502, 76.431931],
                [-116.940804, 76.386932],
                [-116.938599, 76.346367],
                [-117.319702, 76.257767],
                [-117.603104, 76.27388],
                [-117.657799, 76.29332],
                [-117.655296, 76.31749],
                [-117.813599, 76.318329],
                [-118.059998, 76.409149],
                [-118.0289, 76.424149],
                [-118.044998, 76.447197],
                [-117.990799, 76.462486],
                [-118.020599, 76.492752],
                [-117.904198, 76.52832],
                [-117.953903, 76.562759],
                [-117.908096, 76.578598],
                [-117.973297, 76.596367],
                [-117.900002, 76.636658],
                [-117.919197, 76.688026],
                [-117.734398, 76.77832],
                [-117.798897, 76.817757],
                [-117.8881, 76.818878],
                [-118.029701, 76.758614],
                [-118.337502, 76.768333],
                [-118.300301, 76.73526],
                [-118.312202, 76.705261],
                [-118.495003, 76.712196],
                [-118.458603, 76.6922],
                [-118.474701, 76.679703],
                [-118.3414, 76.6436],
                [-118.304199, 76.552467],
                [-118.4347, 76.548866],
                [-118.570801, 76.499153],
                [-118.841698, 76.554977],
                [-118.773598, 76.527206],
                [-118.9758, 76.498871],
                [-118.681999, 76.445251],
                [-118.567497, 76.336647],
                [-118.655602, 76.284149],
                [-118.9114, 76.265549],
                [-118.9422, 76.210541],
                [-118.887497, 76.173866],
                [-118.964203, 76.160812],
                [-118.964996, 76.126648],
                [-119.071404, 76.117477],
                [-119.075798, 76.083328],
                [-119.282501, 76.127472],
                [-119.301697, 76.19136],
                [-119.4561, 76.255547],
                [-119.499397, 76.361099],
                [-119.562798, 76.320831],
                [-119.713898, 76.331673],
                [-119.652802, 76.29776],
                [-119.658302, 76.268883],
                [-119.705002, 76.262207],
                [-119.565804, 76.175262],
                [-119.701103, 76.150818],
                [-119.552803, 76.117752],
                [-119.805, 76.108871],
                [-119.491402, 76.035538],
                [-119.481102, 75.970833],
                [-119.636902, 75.992203],
                [-119.7033, 75.942749],
                [-119.606903, 75.911102],
                [-119.710602, 75.921654],
                [-119.870003, 75.857483],
                [-120.069504, 75.838882],
                [-120.1492, 75.896378],
                [-120.288902, 75.816093],
                [-120.454498, 75.815811],
                [-120.4897, 75.849991],
                [-120.463097, 75.916382],
                [-120.384499, 75.967758],
                [-120.445297, 75.976646],
                [-120.4589, 76.011658],
                [-120.578102, 76.002777],
                [-120.563301, 75.985527],
                [-120.593903, 75.978043],
                [-120.696701, 76.013893],
                [-120.731102, 76.057747],
                [-120.648598, 76.099716],
                [-120.751099, 76.10582],
                [-120.659203, 76.142487],
                [-120.883598, 76.198318],
                [-121.012497, 76.13916],
                [-120.995003, 76.103592],
                [-121.022797, 76.059143],
                [-120.931999, 75.959991],
                [-120.980797, 75.94165],
                [-121.017197, 75.948029],
                [-120.999199, 75.994431],
                [-121.113899, 75.991653],
                [-121.259499, 75.964432],
                [-121.294998, 75.907494],
                [-121.423302, 75.933868],
                [-121.4953, 75.984421],
                [-121.864998, 76.042213],
                [-122.134201, 76.036377],
                [-122.132797, 75.974152],
                [-122.1978, 75.977478],
                [-122.416397, 75.928589],
                [-122.696404, 75.955551],
                [-122.728897, 75.973038],
                [-122.560501, 76.020538],
                [-122.698898, 76.018333],
                [-122.5439, 76.046944],
                [-122.4711, 76.11026],
                [-122.699997, 76.112198],
                [-122.489998, 76.141098],
                [-122.6203, 76.174423],
                [-122.842201, 76.148613],
                [-122.901901, 76.098038],
                [-123.037804, 76.084717],
                [-122.905602, 76.155258],
                [-122.9422, 76.182213],
                [-122.848602, 76.184708],
                [-122.828903, 76.233871],
                [-122.707497, 76.226089],
                [-122.636101, 76.264709],
                [-122.617798, 76.342209],
                [-121.983902, 76.444138],
                [-121.781998, 76.420258],
                [-121.533096, 76.437187],
                [-121.413902, 76.500549],
                [-121.411903, 76.553864],
                [-121.216904, 76.618591],
                [-121.1903, 76.680542],
                [-120.900299, 76.693314],
                [-120.812202, 76.737198],
                [-120.5811, 76.74942],
                [-120.401703, 76.797211],
                [-120.365303, 76.836113],
                [-120.418098, 76.875259],
                [-120.176697, 76.919708],
                [-120.188599, 76.942749],
                [-120.091103, 76.963608],
                [-120.0914, 77.003052],
                [-119.949997, 77.012497],
                [-119.8125, 77.096649],
                [-119.389198, 77.184418],
                [-119.295799, 77.276657],
                [-119.153297, 77.325821],
                [-117.867203, 77.388603],
                [-117.934998, 77.358597],
                [-117.801903, 77.367203],
                [-117.253601, 77.285538],
                [-117.023102, 77.290817],
                [-117.060799, 77.32666],
                [-117.181999, 77.340271],
                [-117.158897, 77.358871],
                [-116.795799, 77.31749],
                [-116.810799, 77.340553],
                [-116.651703, 77.388046],
                [-116.994698, 77.39444],
                [-117.150002, 77.457207],
                [-116.785301, 77.499153],
                [-116.769402, 77.516388],
                [-116.900299, 77.532211],
                [-116.416901, 77.556931],
                [-116.090599, 77.491089],
                [-116.065804, 77.47998],
                [-116.108299, 77.461113],
                [-115.753304, 77.419708],
                [-115.389198, 77.312187],
                [-115.8778, 77.215271],
                [-116.269203, 77.190262],
                [-116.290604, 77.14888],
                [-116.400002, 77.133881],
                [-116.295303, 77.113602],
                [-116.269203, 77.055817],
                [-116.144997, 77.023323]
              ]
            ],
            [
              [
                [-135.850098, 69.007401],
                [-135.648895, 68.991928],
                [-135.517807, 69.02388],
                [-135.690598, 69.012497],
                [-135.758606, 69.060257],
                [-135.934692, 69.095543],
                [-135.967194, 69.214706],
                [-135.914993, 69.257492],
                [-135.656693, 69.1436],
                [-135.411407, 69.097488],
                [-135.6306, 69.151382],
                [-135.810806, 69.242752],
                [-135.855804, 69.284714],
                [-135.803894, 69.316673],
                [-135.6595, 69.316673],
                [-135.585297, 69.223312],
                [-135.548904, 69.23526],
                [-135.610504, 69.319717],
                [-135.571899, 69.338593],
                [-135.170593, 69.257767],
                [-135.158401, 69.274429],
                [-135.240799, 69.308319],
                [-135.287201, 69.419144],
                [-135.150803, 69.476929],
                [-135.107803, 69.457764],
                [-134.790604, 69.498322],
                [-134.468597, 69.444427],
                [-134.407806, 69.474152],
                [-134.417801, 69.500549],
                [-134.507004, 69.515549],
                [-134.548599, 69.472763],
                [-134.596405, 69.514427],
                [-134.576401, 69.54776],
                [-134.468903, 69.542763],
                [-134.492798, 69.560532],
                [-134.401703, 69.638321],
                [-134.491104, 69.722214],
                [-134.312195, 69.7211],
                [-134.351395, 69.668869],
                [-134.203903, 69.668869],
                [-134.147003, 69.6297],
                [-134.242203, 69.568047],
                [-134.191101, 69.59082],
                [-134.127106, 69.542862],
                [-134.020004, 69.559418],
                [-134.001404, 69.546371],
                [-134.048096, 69.526932],
                [-133.918304, 69.508331],
                [-133.805603, 69.539429],
                [-133.827194, 69.559982],
                [-133.778397, 69.576393],
                [-133.748306, 69.542213],
                [-133.789703, 69.531097],
                [-133.778107, 69.487762],
                [-134.029694, 69.382477],
                [-134.097504, 69.328598],
                [-134.099197, 69.293869],
                [-134.211105, 69.276093],
                [-134.275604, 69.226089],
                [-134.280304, 69.180267],
                [-134.383606, 69.118317],
                [-134.5681, 69.106644],
                [-134.675797, 69.011383],
                [-134.666702, 68.9561],
                [-134.488602, 68.870247],
                [-134.417206, 68.784416],
                [-134.287506, 68.750816],
                [-134.227295, 68.696426],
                [-134.457504, 68.719147],
                [-134.467804, 68.762497],
                [-134.559402, 68.798866],
                [-134.606903, 68.862762],
                [-134.834198, 68.987488],
                [-134.914703, 68.96666],
                [-134.920303, 68.914703],
                [-134.961105, 68.892487],
                [-135.141998, 68.901382],
                [-135.201706, 68.932747],
                [-135.421402, 68.928864],
                [-135.4608, 68.942749],
                [-135.455307, 68.977768],
                [-135.534698, 68.944702],
                [-135.522202, 68.908043],
                [-135.807999, 68.895264],
                [-136.005295, 68.951927],
                [-135.9189, 68.958603],
                [-135.987503, 69.011932],
                [-135.968597, 69.044434],
                [-135.850098, 69.007401]
              ]
            ],
            [
              [
                [-117.623001, 76.114433],
                [-117.471397, 76.088882],
                [-117.489197, 76.046944],
                [-117.681099, 75.921097],
                [-117.838303, 75.859993],
                [-117.823898, 75.83194],
                [-117.952202, 75.771927],
                [-117.927498, 75.734421],
                [-118.263603, 75.616928],
                [-118.203903, 75.598877],
                [-118.604698, 75.496368],
                [-118.927498, 75.529427],
                [-118.873596, 75.547493],
                [-118.928001, 75.562759],
                [-119.196999, 75.562477],
                [-119.408096, 75.60582],
                [-118.619202, 75.915543],
                [-118.367798, 75.966377],
                [-118.152199, 75.971367],
                [-118.071404, 76.034149],
                [-117.933601, 76.047493],
                [-117.7789, 76.108597],
                [-117.623001, 76.114433]
              ]
            ],
            [
              [
                [-114.193001, 77.698029],
                [-114.634697, 77.757767],
                [-114.521698, 77.771103],
                [-115.116096, 77.958328],
                [-114.952202, 77.949417],
                [-114.6875, 78.024696],
                [-114.286903, 78.066093],
                [-114.350601, 78.032494],
                [-114.270599, 78.012772],
                [-114.311096, 78.004707],
                [-114.089203, 77.986366],
                [-113.958298, 77.914993],
                [-113.706398, 77.891663],
                [-113.677498, 77.846367],
                [-113.576103, 77.814148],
                [-113.7883, 77.745247],
                [-114.193001, 77.698029]
              ]
            ],
            [
              [
                [-114.054703, 76.703598],
                [-114.875801, 76.770828],
                [-114.6064, 76.865807],
                [-113.807503, 76.889427],
                [-113.498001, 76.833328],
                [-113.453903, 76.772774],
                [-113.652496, 76.704437],
                [-114.054703, 76.703598]
              ]
            ],
            [
              [
                [-120.867798, 75.913307],
                [-120.898399, 75.825821],
                [-121.032799, 75.737762],
                [-121.2883, 75.752777],
                [-121.029404, 75.811371],
                [-120.998299, 75.867752],
                [-121.047501, 75.899986],
                [-120.997803, 75.926376],
                [-120.8778, 75.936096],
                [-120.867798, 75.913307]
              ]
            ],
            [
              [
                [-112.237198, 70.362488],
                [-111.6717, 70.306641],
                [-112.090302, 70.295822],
                [-112.317802, 70.345543],
                [-112.237198, 70.362488]
              ]
            ],
            [
              [
                [-109.882797, 68.114151],
                [-109.932198, 68.079163],
                [-110.258598, 68.041931],
                [-109.9217, 68.134163],
                [-109.882797, 68.114151]
              ]
            ],
            [
              [
                [-128.120193, 70.597214],
                [-128.144196, 70.568047],
                [-128.2314, 70.598328],
                [-128.242203, 70.570267],
                [-128.341705, 70.542213],
                [-128.234207, 70.656097],
                [-128.094696, 70.620529],
                [-128.120193, 70.597214]
              ]
            ],
            [
              [
                [-117.183296, 70.537491],
                [-117.301697, 70.56192],
                [-117.199402, 70.59166],
                [-116.879402, 70.547493],
                [-117.183296, 70.537491]
              ]
            ],
            [
              [
                [-135.297501, 69.304977],
                [-135.565598, 69.390549],
                [-135.337799, 69.388603],
                [-135.339401, 69.351089],
                [-135.272202, 69.358322],
                [-135.297501, 69.304977]
              ]
            ],
            [
              [
                [-122.633301, 75.919708],
                [-122.324203, 75.899719],
                [-122.340797, 75.862762],
                [-122.398598, 75.859421],
                [-122.691704, 75.900269],
                [-122.633301, 75.919708]
              ]
            ],
            [
              [
                [-124.307503, 73.632202],
                [-124.113899, 73.564148],
                [-124.343597, 73.559982],
                [-124.358597, 73.630257],
                [-124.307503, 73.632202]
              ]
            ],
            [
              [
                [-113.778603, 77.104156],
                [-113.931396, 77.1297],
                [-113.699203, 77.144707],
                [-113.6642, 77.123032],
                [-113.778603, 77.104156]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Northwest Territories",
          "density": "0.03987",
          "cartodb_id": 13,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-64.482773, 47.91777],
                [-64.514717, 47.8325],
                [-64.605003, 47.748329],
                [-64.646667, 47.72887],
                [-64.690552, 47.753052],
                [-64.646118, 47.788601],
                [-64.702789, 47.823608],
                [-64.667503, 47.86694],
                [-64.609161, 47.88694],
                [-64.533333, 47.864159],
                [-64.482773, 47.91777]
              ]
            ],
            [
              [
                [-69.039711, 47.29715],
                [-68.576111, 47.424709],
                [-68.38028, 47.558601],
                [-68.378052, 47.65416],
                [-68.372772, 47.924709],
                [-68.12027, 47.926659],
                [-68.116096, 48.001942],
                [-67.612213, 47.99749],
                [-67.604172, 47.9361],
                [-67.364166, 47.841381],
                [-67.322777, 47.896389],
                [-67.188599, 47.883881],
                [-67.047234, 47.93082],
                [-66.946953, 47.899158],
                [-66.943604, 47.963051],
                [-66.843697, 47.996651],
                [-66.75, 47.979988],
                [-66.356659, 48.07333],
                [-66.339172, 48.013611],
                [-65.79361, 47.890831],
                [-65.628601, 47.66972],
                [-65.671387, 47.651661],
                [-65.634743, 47.620831],
                [-65.606659, 47.659988],
                [-65.476387, 47.68082],
                [-65.240829, 47.80666],
                [-65.044724, 47.84444],
                [-64.976936, 47.838039],
                [-65.071953, 47.776939],
                [-64.823624, 47.811378],
                [-64.786118, 47.772221],
                [-64.846947, 47.744438],
                [-64.791382, 47.742222],
                [-64.770279, 47.706661],
                [-64.733322, 47.76194],
                [-64.676666, 47.73555],
                [-64.8125, 47.674999],
                [-64.818619, 47.608051],
                [-64.89389, 47.556938],
                [-64.901108, 47.513329],
                [-64.87027, 47.51083],
                [-64.928329, 47.450829],
                [-64.866943, 47.484718],
                [-64.880829, 47.432499],
                [-64.910004, 47.35305],
                [-64.904167, 47.393051],
                [-64.941101, 47.373878],
                [-64.96611, 47.28944],
                [-65.370003, 47.086651],
                [-65.204727, 47.087769],
                [-65.212509, 47.044998],
                [-65.025284, 47.09193],
                [-64.979446, 47.05555],
                [-64.93277, 47.08416],
                [-64.798607, 47.07999],
                [-64.825844, 47.049999],
                [-64.803329, 46.987499],
                [-64.88028, 46.93055],
                [-64.904999, 46.851101],
                [-64.873047, 46.782211],
                [-64.788597, 46.724159],
                [-64.853333, 46.70499],
                [-64.854446, 46.672218],
                [-64.817497, 46.696381],
                [-64.752792, 46.663601],
                [-64.748611, 46.70277],
                [-64.711121, 46.679989],
                [-64.723618, 46.580551],
                [-64.673317, 46.500832],
                [-64.711403, 46.479988],
                [-64.611938, 46.40971],
                [-64.631943, 46.338039],
                [-64.701683, 46.32666],
                [-64.579178, 46.328049],
                [-64.551666, 46.363331],
                [-64.517227, 46.320831],
                [-64.568619, 46.287498],
                [-64.559433, 46.21999],
                [-64.518341, 46.242771],
                [-64.253891, 46.23444],
                [-64.154167, 46.19305],
                [-64.146118, 46.157211],
                [-64.116943, 46.181938],
                [-64.081947, 46.151379],
                [-64.057503, 46.180271],
                [-63.970829, 46.18055],
                [-63.77639, 46.121101],
                [-63.919449, 46.053051],
                [-64.068069, 46.059429],
                [-64.093887, 46.02166],
                [-64.042793, 45.991901],
                [-64.154999, 45.96888],
                [-64.272926, 45.835751],
                [-64.361389, 45.879162],
                [-64.339722, 45.859718],
                [-64.481949, 45.72332],
                [-64.538887, 45.723049],
                [-64.488052, 45.801102],
                [-64.534157, 45.898048],
                [-64.549728, 45.86805],
                [-64.576401, 45.885551],
                [-64.681381, 45.994709],
                [-64.690552, 46.050831],
                [-64.754463, 46.089161],
                [-64.699432, 46.035],
                [-64.70639, 45.994709],
                [-64.573624, 45.863331],
                [-64.672501, 45.750271],
                [-64.642227, 45.717209],
                [-64.693047, 45.716099],
                [-64.778343, 45.60722],
                [-64.904167, 45.627769],
                [-65.015007, 45.548882],
                [-65.326683, 45.4575],
                [-65.739723, 45.24527],
                [-65.799156, 45.25666],
                [-65.918877, 45.2061],
                [-65.999161, 45.2286],
                [-66.039169, 45.28833],
                [-66.126389, 45.304161],
                [-66.015289, 45.377769],
                [-66.002502, 45.461658],
                [-66.088333, 45.35527],
                [-66.193329, 45.333599],
                [-66.145279, 45.27916],
                [-66.05777, 45.2575],
                [-66.21666, 45.159161],
                [-66.245537, 45.208889],
                [-66.321953, 45.19804],
                [-66.253342, 45.191662],
                [-66.25, 45.161381],
                [-66.34584, 45.14444],
                [-66.440552, 45.07333],
                [-66.496384, 45.149719],
                [-66.648064, 45.083599],
                [-66.722229, 45.089161],
                [-66.75473, 45.05555],
                [-66.796951, 45.05888],
                [-66.790558, 45.102779],
                [-66.87999, 45.057209],
                [-66.907784, 45.111382],
                [-66.84584, 45.132488],
                [-66.949432, 45.187489],
                [-66.987213, 45.153881],
                [-67.027222, 45.168049],
                [-67.033333, 45.0886],
                [-67.122223, 45.1586],
                [-67.127213, 45.220268],
                [-67.182503, 45.244709],
                [-67.15123, 45.12191],
                [-67.166473, 45.156189],
                [-67.274658, 45.182709],
                [-67.346169, 45.122181],
                [-67.439987, 45.18951],
                [-67.478508, 45.280209],
                [-67.419113, 45.375778],
                [-67.504662, 45.485748],
                [-67.416641, 45.50349],
                [-67.439857, 45.592491],
                [-67.615692, 45.605129],
                [-67.718582, 45.68124],
                [-67.753502, 45.659229],
                [-67.803978, 45.678051],
                [-67.803596, 45.794449],
                [-67.759911, 45.82774],
                [-67.795113, 45.878422],
                [-67.756157, 45.916519],
                [-67.78083, 45.94701],
                [-67.79155, 47.060959],
                [-68.231323, 47.352112],
                [-68.335327, 47.35733],
                [-68.391769, 47.285061],
                [-68.515182, 47.296921],
                [-68.895393, 47.18222],
                [-69.037231, 47.257332],
                [-69.039711, 47.29715]
              ]
            ]
          ]
        },
        "properties": {
          "name": "New Brunswick",
          "density": "11.38",
          "cartodb_id": 7,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-60.910278, 45.546101],
                [-61.000278, 45.45583],
                [-61.021111, 45.46888],
                [-60.99889, 45.49361],
                [-61.072231, 45.502491],
                [-61.108341, 45.540829],
                [-61.01722, 45.575272],
                [-60.90028, 45.57555],
                [-60.878609, 45.55582],
                [-60.910278, 45.546101]
              ]
            ],
            [
              [
                [-66.764717, 44.801102],
                [-66.734734, 44.72916],
                [-66.761124, 44.676102],
                [-66.900284, 44.613331],
                [-66.858612, 44.74305],
                [-66.764717, 44.801102]
              ]
            ],
            [
              [
                [-60.59639, 46.196098],
                [-60.46806, 46.316669],
                [-60.416389, 46.328049],
                [-60.419998, 46.279991],
                [-60.64584, 46.106098],
                [-60.870281, 46.051659],
                [-60.84972, 46.07888],
                [-60.880001, 46.079159],
                [-60.97472, 45.988331],
                [-61.11306, 45.955269],
                [-61.125561, 45.923611],
                [-60.993328, 45.94138],
                [-60.875561, 46.036942],
                [-60.734169, 46.047489],
                [-60.80389, 45.9786],
                [-60.807499, 45.93111],
                [-61.066391, 45.893879],
                [-61.043892, 45.88472],
                [-61.08778, 45.847488],
                [-60.96806, 45.883331],
                [-60.917782, 45.847488],
                [-60.97028, 45.856659],
                [-60.940552, 45.821659],
                [-61.01083, 45.820271],
                [-61.118061, 45.763611],
                [-61.142502, 45.696381],
                [-61.070839, 45.689991],
                [-60.91444, 45.753609],
                [-60.797779, 45.707211],
                [-60.844158, 45.64138],
                [-60.76112, 45.69054],
                [-60.733608, 45.67416],
                [-60.74889, 45.74194],
                [-60.719719, 45.78833],
                [-60.39584, 45.996101],
                [-60.686111, 45.88694],
                [-60.785278, 45.946381],
                [-60.305, 46.210548],
                [-60.29528, 46.232208],
                [-60.372768, 46.22332],
                [-60.547501, 46.10416],
                [-60.656109, 46.067768],
                [-60.347778, 46.310551],
                [-60.285278, 46.321381],
                [-60.203331, 46.235271],
                [-60.28944, 46.154709],
                [-60.24028, 46.173611],
                [-60.199169, 46.143608],
                [-60.194439, 46.20277],
                [-60.131939, 46.2486],
                [-60.041672, 46.227772],
                [-60.076389, 46.199162],
                [-59.95055, 46.201389],
                [-59.94138, 46.151661],
                [-59.810551, 46.162769],
                [-59.88805, 46.088879],
                [-59.809719, 46.10638],
                [-59.959999, 46.02055],
                [-59.82362, 45.99749],
                [-59.818611, 45.929161],
                [-59.973331, 45.901379],
                [-59.99416, 45.8661],
                [-60.13028, 45.867771],
                [-60.160549, 45.835548],
                [-60.07362, 45.796391],
                [-60.17445, 45.763889],
                [-60.233891, 45.701099],
                [-60.673061, 45.564159],
                [-60.853889, 45.603882],
                [-60.871498, 45.639721],
                [-61.168331, 45.55138],
                [-61.14584, 45.579441],
                [-61.21722, 45.606941],
                [-61.285, 45.5425],
                [-61.45472, 45.705551],
                [-61.547501, 46.025551],
                [-61.482769, 46.066669],
                [-61.438889, 46.159431],
                [-61.283329, 46.23888],
                [-61.089439, 46.45805],
                [-61.033058, 46.561661],
                [-61.058048, 46.5961],
                [-61.034451, 46.628601],
                [-61.013889, 46.59166],
                [-60.892231, 46.77388],
                [-60.704441, 46.89138],
                [-60.640282, 47],
                [-60.591942, 47.033329],
                [-60.488609, 46.99416],
                [-60.39806, 47.023319],
                [-60.384998, 46.996101],
                [-60.498051, 46.89666],
                [-60.45084, 46.89777],
                [-60.445271, 46.86166],
                [-60.326111, 46.86832],
                [-60.302219, 46.83749],
                [-60.346951, 46.685551],
                [-60.396111, 46.665821],
                [-60.35445, 46.648609],
                [-60.384731, 46.635551],
                [-60.345001, 46.60638],
                [-60.589439, 46.255001],
                [-60.59639, 46.196098]
              ]
            ],
            [
              [
                [-64.042793, 45.991901],
                [-63.913059, 45.979988],
                [-63.80389, 45.88583],
                [-63.725769, 45.869961],
                [-63.749168, 45.835819],
                [-63.682499, 45.848598],
                [-63.664162, 45.815269],
                [-63.66222, 45.870541],
                [-63.482498, 45.877209],
                [-63.403881, 45.854439],
                [-63.482769, 45.858051],
                [-63.428612, 45.823608],
                [-63.51556, 45.807209],
                [-63.235001, 45.808601],
                [-63.380001, 45.766109],
                [-63.294449, 45.721931],
                [-63.186939, 45.772221],
                [-63.18972, 45.73444],
                [-63.065552, 45.75555],
                [-63.10778, 45.80888],
                [-62.672779, 45.767769],
                [-62.753891, 45.758049],
                [-62.63612, 45.714439],
                [-62.775002, 45.671101],
                [-62.791111, 45.638329],
                [-62.743061, 45.654991],
                [-62.743889, 45.611111],
                [-62.703892, 45.652489],
                [-62.650829, 45.62804],
                [-62.675282, 45.671379],
                [-62.60944, 45.684158],
                [-62.47361, 45.655548],
                [-62.509731, 45.6161],
                [-62.486389, 45.603611],
                [-62.356949, 45.67194],
                [-62.424721, 45.673321],
                [-62.250278, 45.708328],
                [-61.9175, 45.885551],
                [-61.88139, 45.686649],
                [-61.724998, 45.620831],
                [-61.6325, 45.637772],
                [-61.65556, 45.624161],
                [-61.618061, 45.61055],
                [-61.564999, 45.673882],
                [-61.471378, 45.682499],
                [-61.332779, 45.540829],
                [-61.22916, 45.501942],
                [-61.249729, 45.447491],
                [-61.48167, 45.367771],
                [-61.457218, 45.343609],
                [-61.257778, 45.33416],
                [-60.970551, 45.321659],
                [-60.97028, 45.269711],
                [-61.036388, 45.293049],
                [-61.02306, 45.25639],
                [-61.077782, 45.21944],
                [-61.139721, 45.210819],
                [-61.121941, 45.27166],
                [-61.171391, 45.255268],
                [-61.1675, 45.20388],
                [-61.204441, 45.246101],
                [-61.268059, 45.254711],
                [-61.360001, 45.209991],
                [-61.349442, 45.174438],
                [-61.458611, 45.144711],
                [-61.595829, 45.143051],
                [-61.69611, 45.177769],
                [-61.63805, 45.12027],
                [-61.672501, 45.114159],
                [-61.642231, 45.08416],
                [-61.819729, 45.104439],
                [-61.81361, 45.073879],
                [-61.85944, 45.075001],
                [-61.834999, 45.051102],
                [-61.88306, 45.024441],
                [-61.932499, 45.046391],
                [-62.051109, 45.00639],
                [-61.98138, 44.983051],
                [-62.240002, 44.95805],
                [-62.337219, 44.921101],
                [-62.338612, 44.888599],
                [-62.381111, 44.913601],
                [-62.457218, 44.82972],
                [-62.476391, 44.89555],
                [-62.515011, 44.85194],
                [-62.551392, 44.861382],
                [-62.535561, 44.798611],
                [-62.61195, 44.839161],
                [-62.65361, 44.791382],
                [-62.801109, 44.778599],
                [-62.825562, 44.744438],
                [-62.796108, 44.723881],
                [-62.840279, 44.711109],
                [-62.928612, 44.733879],
                [-62.99778, 44.703609],
                [-63.003059, 44.765831],
                [-63.055561, 44.77277],
                [-63.011951, 44.70805],
                [-63.054722, 44.673321],
                [-63.118889, 44.786659],
                [-63.14278, 44.688599],
                [-63.163891, 44.725552],
                [-63.212219, 44.673611],
                [-63.245831, 44.732491],
                [-63.240002, 44.676941],
                [-63.283058, 44.627209],
                [-63.40361, 44.641659],
                [-63.406391, 44.683331],
                [-63.451389, 44.672218],
                [-63.41222, 44.63221],
                [-63.439991, 44.590832],
                [-63.658329, 44.715],
                [-63.64056, 44.67305],
                [-63.562222, 44.63221],
                [-63.520279, 44.507771],
                [-63.570839, 44.461941],
                [-63.59972, 44.483601],
                [-63.631111, 44.435822],
                [-63.633888, 44.473881],
                [-63.713058, 44.454159],
                [-63.763618, 44.49749],
                [-63.79834, 44.46944],
                [-63.783329, 44.527489],
                [-63.8675, 44.489429],
                [-63.932499, 44.513329],
                [-63.937771, 44.621658],
                [-63.887218, 44.69054],
                [-64.059433, 44.638329],
                [-64.063889, 44.590549],
                [-64.009171, 44.513329],
                [-64.046951, 44.518879],
                [-64.083069, 44.46666],
                [-64.121658, 44.485271],
                [-64.121658, 44.544159],
                [-64.170273, 44.586109],
                [-64.224442, 44.533878],
                [-64.301941, 44.561378],
                [-64.324448, 44.47332],
                [-64.297501, 44.463879],
                [-64.355827, 44.44276],
                [-64.330841, 44.408878],
                [-64.256958, 44.408039],
                [-64.305267, 44.376099],
                [-64.211403, 44.361938],
                [-64.346123, 44.35722],
                [-64.335564, 44.32666],
                [-64.260559, 44.324169],
                [-64.289993, 44.301929],
                [-64.239441, 44.294159],
                [-64.258057, 44.269989],
                [-64.319458, 44.264721],
                [-64.430557, 44.338039],
                [-64.358612, 44.28833],
                [-64.363617, 44.25666],
                [-64.428047, 44.228321],
                [-64.494164, 44.14027],
                [-64.604172, 44.156651],
                [-64.616386, 44.133049],
                [-64.543327, 44.11639],
                [-64.556381, 44.077221],
                [-64.715561, 44.052219],
                [-64.663063, 44.02388],
                [-64.666397, 43.990269],
                [-64.845284, 43.92194],
                [-64.785553, 43.867771],
                [-64.820847, 43.864719],
                [-64.835007, 43.825001],
                [-64.907784, 43.87833],
                [-64.880547, 43.833881],
                [-64.906387, 43.800541],
                [-64.96611, 43.872761],
                [-64.928596, 43.802219],
                [-64.965286, 43.751659],
                [-65.040283, 43.82666],
                [-65.008347, 43.746941],
                [-65.025558, 43.70805],
                [-65.074448, 43.698879],
                [-65.083618, 43.752491],
                [-65.122498, 43.69305],
                [-65.12471, 43.670551],
                [-65.152786, 43.68721],
                [-65.138901, 43.74416],
                [-65.180557, 43.706661],
                [-65.237213, 43.798611],
                [-65.256668, 43.770828],
                [-65.223618, 43.7075],
                [-65.285004, 43.66972],
                [-65.32695, 43.697491],
                [-65.332497, 43.7686],
                [-65.337784, 43.73555],
                [-65.368332, 43.734989],
                [-65.325844, 43.674999],
                [-65.360001, 43.60527],
                [-65.338608, 43.549438],
                [-65.450287, 43.59972],
                [-65.404449, 43.502781],
                [-65.449158, 43.559719],
                [-65.486938, 43.5186],
                [-65.481377, 43.464439],
                [-65.568619, 43.570271],
                [-65.657501, 43.490269],
                [-65.72583, 43.502491],
                [-65.739166, 43.573051],
                [-65.755569, 43.54916],
                [-65.783333, 43.57111],
                [-65.775558, 43.68832],
                [-65.793327, 43.61166],
                [-65.818336, 43.630268],
                [-65.860817, 43.80444],
                [-65.87471, 43.763611],
                [-65.93277, 43.827221],
                [-65.962784, 43.774712],
                [-65.982224, 43.843319],
                [-66.004997, 43.840549],
                [-65.965843, 43.73082],
                [-66.020554, 43.691101],
                [-66.033623, 43.740269],
                [-66.062767, 43.716381],
                [-66.080841, 43.768051],
                [-66.123047, 43.740551],
                [-66.136948, 43.799999],
                [-66.108337, 43.841099],
                [-66.147232, 43.824169],
                [-66.16806, 43.863049],
                [-66.149727, 44.011108],
                [-66.21167, 44.095268],
                [-66.134453, 44.22971],
                [-66.118607, 44.338039],
                [-65.841377, 44.574169],
                [-65.943047, 44.57777],
                [-66.190552, 44.383331],
                [-66.191101, 44.423321],
                [-66.103058, 44.5],
                [-65.785004, 44.69054],
                [-65.753067, 44.605831],
                [-65.684998, 44.61694],
                [-65.428329, 44.776661],
                [-65.721657, 44.66777],
                [-65.75473, 44.67749],
                [-65.741096, 44.707211],
                [-64.861938, 45.139721],
                [-64.434433, 45.2575],
                [-64.393066, 45.303322],
                [-64.489166, 45.33527],
                [-64.327499, 45.303322],
                [-64.368881, 45.197491],
                [-64.353882, 45.165272],
                [-64.385834, 45.14555],
                [-64.359734, 45.097488],
                [-64.324387, 45.139339],
                [-64.244164, 45.123878],
                [-64.157227, 45.056938],
                [-64.156387, 44.978321],
                [-64.103882, 44.992222],
                [-64.098892, 45.057499],
                [-64.198608, 45.11721],
                [-64.152786, 45.19276],
                [-63.854721, 45.269161],
                [-63.78973, 45.311661],
                [-63.539169, 45.333328],
                [-63.470829, 45.29055],
                [-63.46666, 45.326389],
                [-63.360828, 45.360821],
                [-63.755562, 45.398048],
                [-63.883888, 45.350552],
                [-63.923611, 45.388329],
                [-64.069733, 45.410271],
                [-64.385559, 45.3661],
                [-64.563316, 45.411659],
                [-64.701401, 45.368599],
                [-64.698608, 45.326099],
                [-64.76001, 45.28944],
                [-64.763062, 45.326099],
                [-64.815826, 45.348598],
                [-64.93721, 45.326939],
                [-64.909157, 45.418049],
                [-64.458054, 45.674438],
                [-64.436661, 45.73999],
                [-64.375549, 45.7925],
                [-64.296661, 45.8186],
                [-64.335564, 45.740829],
                [-64.266663, 45.761108],
                [-64.30249, 45.772491],
                [-64.272926, 45.835751],
                [-64.154999, 45.96888],
                [-64.042793, 45.991901]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Nova Scotia",
          "density": "19.26",
          "cartodb_id": 2,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-102.001701, 59.99992],
                [-102.0009, 59.918839],
                [-102.001503, 58.12854],
                [-102.001297, 58.034302],
                [-102.001297, 57.868439],
                [-102.0009, 57.49725],
                [-102.001297, 57.25964],
                [-102.001099, 57.013351],
                [-102.000999, 56.366421],
                [-102.001404, 56.221241],
                [-102.0009, 55.830151],
                [-101.967598, 55.76067],
                [-101.967201, 55.67194],
                [-101.967201, 55.467468],
                [-101.925797, 55.466381],
                [-101.925598, 55.117489],
                [-101.883598, 55.116661],
                [-101.883904, 54.76749],
                [-101.851402, 54.766392],
                [-101.851799, 54.53513],
                [-101.851898, 54.41721],
                [-101.811401, 54.41666],
                [-101.810799, 54.066101],
                [-101.7714, 54.06638],
                [-101.772102, 54.00256],
                [-101.742798, 53.369438],
                [-101.700798, 53.368881],
                [-101.672203, 52.67083],
                [-101.639999, 52.671101],
                [-101.639503, 52.32222],
                [-101.612503, 52.32222],
                [-101.613297, 51.96999],
                [-101.567001, 51.970829],
                [-101.544998, 50.950001],
                [-101.514702, 50.949429],
                [-101.514999, 50.595539],
                [-101.484703, 50.589989],
                [-101.483597, 50.24192],
                [-101.4533, 50.241661],
                [-101.453598, 49.888599],
                [-101.426102, 49.888599],
                [-101.425598, 49.5341],
                [-101.400299, 49.533051],
                [-101.400299, 49.177761],
                [-101.3675, 49.17749],
                [-101.367203, 49],
                [-101.5, 49],
                [-102.021797, 49],
                [-102.9375, 49],
                [-104.0625, 49],
                [-105.0625, 49],
                [-106.125, 49],
                [-107.1875, 49],
                [-108.25, 49],
                [-109.5, 49],
                [-110.001404, 49],
                [-110.000504, 54.00573],
                [-110.000702, 54.44952],
                [-110.000603, 54.624401],
                [-110.000801, 54.769001],
                [-110.000603, 56.276039],
                [-110.0009, 56.36282],
                [-110.000801, 58.968639],
                [-110.000504, 59.309391],
                [-110.0009, 59.995281],
                [-107.113602, 59.994709],
                [-106.255997, 59.999771],
                [-106.044403, 59.999592],
                [-105.859001, 59.99968],
                [-105.5411, 60],
                [-104.7099, 59.999771],
                [-104.270699, 59.9995],
                [-102.001701, 59.99992]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Saskatchewan",
          "density": "2",
          "cartodb_id": 3,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-114.060303, 49],
                [-114.035301, 49.031109],
                [-114.1567, 49.103611],
                [-114.154198, 49.148048],
                [-114.225304, 49.188599],
                [-114.372803, 49.204159],
                [-114.376701, 49.25639],
                [-114.429398, 49.265549],
                [-114.476097, 49.341381],
                [-114.576401, 49.389992],
                [-114.599998, 49.442211],
                [-114.576401, 49.556099],
                [-114.6894, 49.547218],
                [-114.738297, 49.608601],
                [-114.679703, 49.63221],
                [-114.636101, 49.727772],
                [-114.626099, 49.797218],
                [-114.6875, 49.913601],
                [-114.684998, 49.9575],
                [-114.643303, 49.977772],
                [-114.658897, 50.065269],
                [-114.724197, 50.125549],
                [-114.754997, 50.288891],
                [-114.793602, 50.334721],
                [-114.770302, 50.35833],
                [-114.8367, 50.39666],
                [-115.016998, 50.575001],
                [-115.1017, 50.588329],
                [-115.218903, 50.54694],
                [-115.320602, 50.638599],
                [-115.283096, 50.658878],
                [-115.311699, 50.706661],
                [-115.346703, 50.732208],
                [-115.395798, 50.71693],
                [-115.419197, 50.754169],
                [-115.527802, 50.791939],
                [-115.647202, 50.85471],
                [-115.571999, 50.90332],
                [-115.618599, 50.969151],
                [-115.759697, 51.035831],
                [-115.776703, 51.073608],
                [-115.957802, 51.103882],
                [-116.016098, 51.138329],
                [-116.0261, 51.21693],
                [-116.060501, 51.24638],
                [-116.262199, 51.316669],
                [-116.306099, 51.388329],
                [-116.305, 51.461658],
                [-116.3797, 51.488602],
                [-116.399696, 51.54472],
                [-116.466103, 51.56638],
                [-116.488098, 51.613049],
                [-116.595001, 51.65749],
                [-116.597504, 51.716381],
                [-116.658897, 51.796661],
                [-116.746101, 51.79916],
                [-116.815804, 51.750549],
                [-116.812798, 51.715271],
                [-116.919197, 51.70916],
                [-117.0392, 51.908039],
                [-117.269997, 52.044998],
                [-117.318901, 52.181938],
                [-117.363098, 52.138599],
                [-117.600304, 52.13055],
                [-117.6875, 52.196098],
                [-117.760002, 52.200829],
                [-117.829697, 52.27277],
                [-117.7314, 52.344151],
                [-117.7547, 52.403599],
                [-117.902802, 52.424999],
                [-118.0056, 52.48777],
                [-118.047501, 52.404991],
                [-118.145798, 52.39888],
                [-118.223099, 52.365551],
                [-118.259697, 52.442211],
                [-118.208298, 52.48082],
                [-118.286903, 52.527771],
                [-118.2817, 52.565269],
                [-118.349998, 52.61805],
                [-118.305, 52.674709],
                [-118.3461, 52.74332],
                [-118.428902, 52.78611],
                [-118.415604, 52.83638],
                [-118.491402, 52.89777],
                [-118.625, 52.884159],
                [-118.679703, 52.970539],
                [-118.669998, 53.034439],
                [-118.781097, 53.05582],
                [-118.759697, 53.12471],
                [-118.9786, 53.237209],
                [-119.012199, 53.223049],
                [-119.013603, 53.144711],
                [-119.045502, 53.137772],
                [-119.265602, 53.196659],
                [-119.348099, 53.275269],
                [-119.385803, 53.36166],
                [-119.606102, 53.37804],
                [-119.738098, 53.395],
                [-119.778099, 53.446941],
                [-119.842499, 53.518051],
                [-119.902496, 53.520271],
                [-119.882797, 53.560268],
                [-119.932198, 53.60722],
                [-119.7686, 53.603321],
                [-119.735298, 53.634991],
                [-119.810303, 53.707771],
                [-119.921097, 53.718319],
                [-119.904701, 53.778599],
                [-120.000801, 53.808041],
                [-120.000801, 54.005871],
                [-119.982903, 59.988522],
                [-110.0009, 59.995281],
                [-110.000504, 59.309391],
                [-110.000801, 58.968639],
                [-110.0009, 56.36282],
                [-110.000603, 56.276039],
                [-110.000801, 54.769001],
                [-110.000603, 54.624401],
                [-110.000702, 54.44952],
                [-110.000504, 54.00573],
                [-110.001404, 49],
                [-110.75, 49],
                [-111.281898, 49],
                [-112.1875, 49],
                [-114.060303, 49]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Alberta",
          "density": "6",
          "cartodb_id": 4,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-63.98917, 46.393608],
                [-64.13501, 46.416382],
                [-64.105827, 46.445541],
                [-64.113327, 46.534721],
                [-64.058037, 46.569439],
                [-64.071404, 46.63583],
                [-64.133331, 46.591381],
                [-64.223618, 46.629711],
                [-64.387512, 46.620831],
                [-64.416656, 46.68111],
                [-64.226936, 46.906101],
                [-63.99889, 47.061661],
                [-63.967499, 46.891659],
                [-64.091667, 46.778599],
                [-64.067497, 46.749161],
                [-63.97472, 46.742489],
                [-63.980831, 46.714161],
                [-63.882221, 46.64555],
                [-63.904171, 46.603882],
                [-63.8325, 46.61721],
                [-63.833328, 46.577492],
                [-63.9375, 46.47665],
                [-63.831669, 46.516392],
                [-63.811668, 46.49527],
                [-63.840839, 46.464439],
                [-63.709438, 46.437489],
                [-63.700279, 46.466381],
                [-63.740002, 46.496101],
                [-63.70055, 46.515549],
                [-63.723331, 46.54361],
                [-63.647221, 46.567211],
                [-63.490841, 46.524712],
                [-63.496948, 46.488331],
                [-63.45472, 46.461658],
                [-63.422501, 46.495831],
                [-63.4575, 46.509991],
                [-63.320278, 46.492771],
                [-63.238892, 46.399719],
                [-63.210831, 46.429161],
                [-62.587502, 46.42527],
                [-62.692501, 46.4561],
                [-62.477219, 46.477772],
                [-62.166389, 46.486111],
                [-61.970551, 46.45694],
                [-62.173328, 46.34943],
                [-62.285839, 46.377491],
                [-62.272221, 46.338329],
                [-62.35778, 46.355831],
                [-62.334721, 46.311939],
                [-62.400551, 46.306938],
                [-62.35973, 46.282768],
                [-62.37389, 46.262211],
                [-62.494999, 46.271381],
                [-62.437222, 46.251659],
                [-62.424721, 46.215549],
                [-62.58527, 46.232208],
                [-62.498051, 46.182499],
                [-62.589439, 46.206379],
                [-62.56834, 46.186649],
                [-62.603889, 46.177219],
                [-62.534168, 46.16777],
                [-62.542229, 46.12249],
                [-62.478329, 46.120831],
                [-62.513889, 46.14222],
                [-62.473049, 46.150269],
                [-62.446949, 46.090549],
                [-62.573891, 46.034721],
                [-62.459171, 46.00639],
                [-62.74667, 45.94833],
                [-62.882771, 45.995541],
                [-62.935001, 46.045551],
                [-62.896111, 46.064991],
                [-63.03083, 46.060822],
                [-62.922501, 46.092491],
                [-62.868061, 46.13472],
                [-62.885281, 46.155819],
                [-62.959171, 46.128601],
                [-62.94416, 46.189991],
                [-62.985001, 46.19582],
                [-62.983891, 46.173882],
                [-63.122219, 46.211658],
                [-62.96278, 46.319988],
                [-63.122768, 46.237209],
                [-63.195, 46.269989],
                [-63.172501, 46.224159],
                [-63.27306, 46.195271],
                [-63.219158, 46.181938],
                [-63.173061, 46.211109],
                [-63.14056, 46.186378],
                [-63.261669, 46.13805],
                [-63.48138, 46.21777],
                [-63.591942, 46.211941],
                [-63.698608, 46.249161],
                [-63.812771, 46.332211],
                [-63.785561, 46.339989],
                [-63.800831, 46.372761],
                [-63.714722, 46.35416],
                [-63.76556, 46.380268],
                [-63.744999, 46.394711],
                [-63.889999, 46.397491],
                [-63.929729, 46.424999],
                [-63.98917, 46.393608]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Prince Edward Island",
          "density": "27.27",
          "cartodb_id": 8,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-139.121094, 69.52916],
                [-139.252197, 69.579712],
                [-139.332504, 69.566093],
                [-139.133102, 69.647774],
                [-138.867203, 69.588318],
                [-139.023102, 69.580833],
                [-139.121094, 69.52916]
              ]
            ],
            [
              [
                [-123.816902, 59.994709],
                [-125.000801, 60.003868],
                [-133.791199, 60],
                [-134.151703, 59.999722],
                [-134.639297, 60.000198],
                [-134.906601, 60],
                [-138.938599, 59.997551],
                [-139.188904, 60.088879],
                [-139.0681, 60.352219],
                [-139.676697, 60.340549],
                [-139.979401, 60.187771],
                [-140.450806, 60.309719],
                [-140.521393, 60.22221],
                [-140.995499, 60.307209],
                [-140.996857, 61.894802],
                [-141.000046, 65.840279],
                [-141.00206, 68.428207],
                [-141.003006, 69.642357],
                [-140.396103, 69.5961],
                [-139.888306, 69.616653],
                [-139.272797, 69.508614],
                [-139.143097, 69.510818],
                [-138.957504, 69.407494],
                [-138.751404, 69.346367],
                [-138.600601, 69.236649],
                [-138.5186, 69.25444],
                [-138.449997, 69.229156],
                [-138.395599, 69.293053],
                [-138.446106, 69.288589],
                [-138.378296, 69.304703],
                [-138.329407, 69.234711],
                [-138.1772, 69.159988],
                [-137.378296, 69.001389],
                [-137.419205, 68.988876],
                [-137.255005, 68.948318],
                [-137.0103, 68.947479],
                [-136.745499, 68.875259],
                [-136.419998, 68.90052],
                [-136.446106, 68.873032],
                [-136.445007, 67.715553],
                [-136.421097, 67.655258],
                [-136.241104, 67.620529],
                [-136.157806, 67.507767],
                [-136.205307, 67.406937],
                [-136.116394, 67.382751],
                [-136.142807, 67.359421],
                [-136.0914, 67.302467],
                [-136.134506, 67.226379],
                [-136.236404, 67.173866],
                [-136.198898, 67.143333],
                [-136.222198, 67.064148],
                [-136.164505, 67.003601],
                [-133.8311, 67.003326],
                [-134.046097, 66.984421],
                [-134.092499, 66.955261],
                [-133.908096, 66.908867],
                [-133.763901, 66.806931],
                [-133.840302, 66.727768],
                [-133.757202, 66.685257],
                [-133.771896, 66.64415],
                [-133.5867, 66.564697],
                [-133.690308, 66.531937],
                [-133.629395, 66.445251],
                [-133.750305, 66.447746],
                [-133.768097, 66.373032],
                [-133.824707, 66.316673],
                [-133.570007, 66.27832],
                [-133.563904, 66.155823],
                [-133.673096, 66.116928],
                [-133.704697, 66.07193],
                [-133.603302, 66.051651],
                [-133.629395, 65.974426],
                [-133.407501, 65.943863],
                [-133.147507, 66.024696],
                [-132.939697, 66.02887],
                [-132.908401, 65.996933],
                [-132.993896, 65.923027],
                [-132.869003, 65.910477],
                [-132.605804, 66.031662],
                [-132.542496, 66.021378],
                [-132.5103, 65.978867],
                [-132.342804, 65.981934],
                [-132.334396, 65.94664],
                [-132.525803, 65.897774],
                [-132.573303, 65.848602],
                [-132.286102, 65.71582],
                [-132.174194, 65.59137],
                [-132.273605, 65.546944],
                [-132.327805, 65.434708],
                [-132.521393, 65.363037],
                [-132.555298, 65.2836],
                [-132.780594, 65.229713],
                [-132.714203, 65.199417],
                [-132.787796, 65.187759],
                [-132.701904, 65.15387],
                [-132.576401, 65.190536],
                [-132.527496, 65.162773],
                [-132.556107, 65.118042],
                [-132.539993, 65.093872],
                [-132.381699, 65.084991],
                [-132.333298, 65.034714],
                [-132.507507, 64.964432],
                [-132.487503, 64.870819],
                [-132.622498, 64.826927],
                [-132.570602, 64.772491],
                [-132.368896, 64.77832],
                [-132.163605, 64.70166],
                [-132.046402, 64.695534],
                [-131.915802, 64.582489],
                [-131.702805, 64.535538],
                [-131.687805, 64.498596],
                [-131.803894, 64.438026],
                [-131.8181, 64.378311],
                [-131.593002, 64.368591],
                [-131.427505, 64.416382],
                [-131.388596, 64.467209],
                [-131.141693, 64.424423],
                [-131.001999, 64.330276],
                [-131.060501, 64.279427],
                [-130.979706, 64.265549],
                [-130.937195, 64.209991],
                [-130.867203, 64.187759],
                [-130.9478, 64.137207],
                [-130.853607, 64.072487],
                [-130.863907, 64.043053],
                [-130.751404, 64.046097],
                [-130.778595, 64.009163],
                [-130.7556, 63.986938],
                [-130.5811, 63.92416],
                [-130.522202, 63.93499],
                [-130.3703, 63.84388],
                [-130.119705, 63.808041],
                [-130.103897, 63.76416],
                [-130.282501, 63.73777],
                [-130.321106, 63.69582],
                [-130.251999, 63.66666],
                [-130.109695, 63.703609],
                [-130.082504, 63.68832],
                [-130.110794, 63.66444],
                [-130.093002, 63.63055],
                [-129.948593, 63.613609],
                [-129.957993, 63.58527],
                [-129.898102, 63.57222],
                [-129.819504, 63.48333],
                [-129.897202, 63.452221],
                [-129.919205, 63.372761],
                [-130.073593, 63.33696],
                [-130.138306, 63.310268],
                [-130.151093, 63.265831],
                [-130.044495, 63.269989],
                [-129.978607, 63.203609],
                [-129.900299, 63.197208],
                [-129.835297, 63.114719],
                [-129.847198, 63.086941],
                [-129.637207, 63.08028],
                [-129.598907, 63.051929],
                [-129.705795, 62.952221],
                [-129.726105, 62.91415],
                [-129.702499, 62.89222],
                [-129.773895, 62.874439],
                [-129.630798, 62.76083],
                [-129.625305, 62.70499],
                [-129.516998, 62.672489],
                [-129.523895, 62.64333],
                [-129.487793, 62.618881],
                [-129.567795, 62.57444],
                [-129.417801, 62.584991],
                [-129.172195, 62.481659],
                [-129.308304, 62.421379],
                [-129.215805, 62.377491],
                [-129.310501, 62.326389],
                [-129.282196, 62.267208],
                [-129.199203, 62.21693],
                [-129.2939, 62.156101],
                [-129.122803, 62.116661],
                [-128.984207, 62.136108],
                [-128.765305, 62.058331],
                [-128.677505, 62.125271],
                [-128.546997, 62.122219],
                [-128.375305, 62.02721],
                [-128.388107, 61.993881],
                [-128.279205, 61.948879],
                [-128.196701, 61.844151],
                [-128.080597, 61.849159],
                [-128.006104, 61.732491],
                [-127.972801, 61.688599],
                [-127.550301, 61.511379],
                [-127.2883, 61.519161],
                [-127.141701, 61.46666],
                [-127.125, 61.4011],
                [-127.059402, 61.363049],
                [-127.007004, 61.18499],
                [-127.031403, 61.150539],
                [-127.0131, 61.12804],
                [-127.101898, 61.070831],
                [-127.055801, 61.039989],
                [-126.935303, 61.059158],
                [-126.9394, 61.002491],
                [-126.898903, 60.941662],
                [-126.925301, 60.862209],
                [-126.8797, 60.82999],
                [-126.886902, 60.78249],
                [-126.826103, 60.755001],
                [-126.753304, 60.782211],
                [-126.672203, 60.743469],
                [-126.520302, 60.814411],
                [-126.394501, 60.77668],
                [-126.253601, 60.78867],
                [-126.217499, 60.819771],
                [-126.244698, 60.850868],
                [-126.202202, 60.863651],
                [-126.113602, 60.862808],
                [-126.107201, 60.817009],
                [-126.009201, 60.80341],
                [-125.957001, 60.877239],
                [-125.887199, 60.896938],
                [-125.711998, 60.827259],
                [-125.370003, 60.782558],
                [-125.184196, 60.848881],
                [-125, 60.860519],
                [-124.871696, 60.858051],
                [-124.8778, 60.91721],
                [-124.813004, 60.972488],
                [-124.578903, 60.953049],
                [-124.476898, 60.79055],
                [-124.619202, 60.709721],
                [-124.614197, 60.668598],
                [-124.459396, 60.569721],
                [-124.418297, 60.47916],
                [-124.206398, 60.457211],
                [-124.195297, 60.32888],
                [-123.974197, 60.098049],
                [-124.029198, 60.023048],
                [-123.8769, 60.04166],
                [-123.816902, 59.994709]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Yukon Territory",
          "density": "0.09224",
          "cartodb_id": 9,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-102.001701, 59.99992],
                [-100.039001, 59.999722],
                [-99.626953, 59.999729],
                [-94.800491, 59.999569],
                [-94.82251, 59.954159],
                [-94.768341, 59.78249],
                [-94.819168, 59.636379],
                [-94.761673, 59.570271],
                [-94.793877, 59.541939],
                [-94.727783, 59.446659],
                [-94.710831, 59.37138],
                [-94.736656, 59.34222],
                [-94.658623, 59.350552],
                [-94.775009, 59.293331],
                [-94.794724, 59.087212],
                [-94.934433, 59.084991],
                [-94.996109, 59.053879],
                [-94.804443, 59.061378],
                [-94.804718, 59.014721],
                [-94.679993, 58.971371],
                [-94.74527, 58.83305],
                [-94.675827, 58.87804],
                [-94.586403, 58.874989],
                [-94.482224, 58.8111],
                [-94.421661, 58.716381],
                [-94.32695, 58.721661],
                [-94.276947, 58.780548],
                [-94.203056, 58.804161],
                [-94.239723, 58.738602],
                [-94.202499, 58.67944],
                [-94.271667, 58.633881],
                [-94.306381, 58.563881],
                [-94.287216, 58.438042],
                [-94.328613, 58.39333],
                [-94.363327, 58.21888],
                [-94.228607, 58.396099],
                [-94.246109, 58.586651],
                [-94.140556, 58.73333],
                [-94.177223, 58.771381],
                [-93.501953, 58.771099],
                [-93.46666, 58.719711],
                [-93.400284, 58.700272],
                [-93.319168, 58.75861],
                [-93.152222, 58.73777],
                [-93.118057, 58.508888],
                [-92.931107, 58.211658],
                [-92.852493, 58.16415],
                [-92.868881, 58.143051],
                [-92.803329, 58.057209],
                [-92.749161, 57.860279],
                [-92.781113, 57.834721],
                [-92.728333, 57.820549],
                [-92.600281, 57.648609],
                [-92.554169, 57.539719],
                [-92.450844, 57.44249],
                [-92.418877, 57.33749],
                [-92.441101, 57.230549],
                [-92.576401, 57.056938],
                [-92.715843, 56.947491],
                [-92.876099, 56.90749],
                [-92.691101, 56.933601],
                [-92.443047, 57.04472],
                [-92.212219, 57.058041],
                [-92.4664, 56.932499],
                [-92.180283, 57.030819],
                [-91.001678, 57.261379],
                [-90.451111, 57.193878],
                [-90, 57.01683],
                [-89.179642, 56.86895],
                [-89.305557, 56.627209],
                [-90.843887, 55.66777],
                [-92.388641, 54.62793],
                [-92.476593, 54.56694],
                [-92.60231, 54.478489],
                [-92.698143, 54.410931],
                [-93.272087, 53.998859],
                [-93.652222, 53.72221],
                [-95.150284, 52.826389],
                [-95.154327, 49.576729],
                [-95.153877, 49.452019],
                [-95.151627, 49.371731],
                [-95.157516, 49],
                [-95.276421, 49],
                [-96.406616, 49],
                [-97.229103, 49],
                [-97.9375, 49],
                [-99, 49],
                [-99.53315, 49],
                [-100.1875, 49],
                [-101.367203, 49],
                [-101.3675, 49.17749],
                [-101.400299, 49.177761],
                [-101.400299, 49.533051],
                [-101.425598, 49.5341],
                [-101.426102, 49.888599],
                [-101.453598, 49.888599],
                [-101.4533, 50.241661],
                [-101.483597, 50.24192],
                [-101.484703, 50.589989],
                [-101.514999, 50.595539],
                [-101.514702, 50.949429],
                [-101.544998, 50.950001],
                [-101.567001, 51.970829],
                [-101.613297, 51.96999],
                [-101.612503, 52.32222],
                [-101.639503, 52.32222],
                [-101.639999, 52.671101],
                [-101.672203, 52.67083],
                [-101.700798, 53.368881],
                [-101.742798, 53.369438],
                [-101.772102, 54.00256],
                [-101.7714, 54.06638],
                [-101.810799, 54.066101],
                [-101.811401, 54.41666],
                [-101.851898, 54.41721],
                [-101.851799, 54.53513],
                [-101.851402, 54.766392],
                [-101.883904, 54.76749],
                [-101.883598, 55.116661],
                [-101.925598, 55.117489],
                [-101.925797, 55.466381],
                [-101.967201, 55.467468],
                [-101.967201, 55.67194],
                [-101.967598, 55.76067],
                [-102.0009, 55.830151],
                [-102.001404, 56.221241],
                [-102.000999, 56.366421],
                [-102.001099, 57.013351],
                [-102.001297, 57.25964],
                [-102.0009, 57.49725],
                [-102.001297, 57.868439],
                [-102.001297, 58.034302],
                [-102.001503, 58.12854],
                [-102.0009, 59.918839],
                [-102.001701, 59.99992]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Manitoba",
          "density": "2",
          "cartodb_id": 10,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-79.547096, 51.460129],
                [-79.539436, 51.191662],
                [-79.537804, 50.982368],
                [-79.624969, 51.0355],
                [-79.678047, 51.05471],
                [-79.752228, 51.184429],
                [-79.680557, 51.29805],
                [-79.698036, 51.401379],
                [-79.547096, 51.460129]
              ]
            ],
            [
              [
                [-79.537613, 50.958599],
                [-79.520683, 48.764679],
                [-79.519012, 48.605289],
                [-79.511322, 47.565571],
                [-79.576401, 47.441929],
                [-79.439438, 47.26527],
                [-79.443878, 47.11055],
                [-79.305557, 46.94054],
                [-79.155563, 46.812489],
                [-78.950562, 46.511669],
                [-78.75528, 46.400539],
                [-78.703613, 46.337212],
                [-77.680557, 46.183601],
                [-77.554993, 46.151932],
                [-77.321953, 46.026661],
                [-77.278877, 45.937771],
                [-77.206123, 45.877209],
                [-77.002792, 45.790279],
                [-76.920837, 45.806381],
                [-76.910553, 45.889992],
                [-76.804718, 45.864719],
                [-76.783333, 45.76194],
                [-76.710564, 45.712769],
                [-76.666397, 45.590549],
                [-76.607773, 45.55249],
                [-76.337784, 45.45583],
                [-76.184433, 45.521099],
                [-76.076683, 45.513889],
                [-75.847229, 45.37249],
                [-75.453339, 45.523609],
                [-74.875839, 45.64159],
                [-74.646118, 45.635551],
                [-74.396667, 45.564159],
                [-74.479172, 45.316669],
                [-74.324722, 45.201099],
                [-74.433609, 45.149441],
                [-74.506393, 45.071659],
                [-74.736481, 44.99287],
                [-74.968819, 44.948578],
                [-75.329201, 44.81057],
                [-75.758972, 44.517475],
                [-75.759117, 44.517273],
                [-75.778252, 44.519852],
                [-75.976936, 44.38361],
                [-76.503067, 44.227489],
                [-76.688316, 44.21693],
                [-76.984161, 44.071381],
                [-77.070007, 44.09222],
                [-76.886673, 44.17749],
                [-77.046661, 44.129429],
                [-77.011398, 44.211109],
                [-77.55722, 44.12138],
                [-77.575562, 44.07888],
                [-77.314438, 44.15221],
                [-77.31778, 44.08971],
                [-77.076683, 44.17944],
                [-77.127777, 44.033611],
                [-77.015793, 44.047249],
                [-76.941109, 44.071049],
                [-76.846657, 44.11832],
                [-77.042221, 43.94833],
                [-76.848053, 43.943321],
                [-77.134453, 43.855549],
                [-77.232773, 43.87999],
                [-77.170273, 43.954159],
                [-77.275284, 43.910549],
                [-77.327789, 43.953609],
                [-77.25473, 43.96888],
                [-77.48555, 43.942211],
                [-77.580841, 44.002781],
                [-77.538887, 44.01944],
                [-77.703613, 44.041111],
                [-77.736938, 44.023609],
                [-77.678329, 44.000832],
                [-77.804718, 44.016941],
                [-79.092499, 43.826389],
                [-79.362503, 43.64333],
                [-79.467773, 43.646938],
                [-79.587784, 43.564709],
                [-79.775833, 43.319988],
                [-79.703613, 43.244438],
                [-79.345284, 43.188042],
                [-79.073982, 43.267471],
                [-79.065811, 43.101341],
                [-79.078613, 43.081669],
                [-79.031113, 43.05666],
                [-79.040833, 43.007771],
                [-78.92495, 42.91011],
                [-79.098892, 42.840271],
                [-79.454727, 42.877491],
                [-80.204178, 42.790279],
                [-80.450562, 42.612209],
                [-80.114441, 42.541382],
                [-80.521942, 42.573051],
                [-80.925003, 42.665539],
                [-81.333069, 42.651379],
                [-81.785278, 42.39777],
                [-81.856377, 42.25639],
                [-81.894173, 42.257221],
                [-81.857498, 42.320831],
                [-81.921112, 42.260281],
                [-82.071953, 42.253052],
                [-82.388062, 42.1236],
                [-82.479721, 42.03249],
                [-82.505836, 41.913319],
                [-82.627777, 42.035271],
                [-82.890556, 41.982208],
                [-83.123894, 42.069721],
                [-83.030457, 42.313221],
                [-82.493057, 42.312771],
                [-82.427223, 42.353882],
                [-82.418877, 42.498329],
                [-82.595284, 42.509991],
                [-82.638443, 42.54705],
                [-82.586403, 42.558601],
                [-82.518883, 42.633781],
                [-82.473328, 42.762859],
                [-82.472038, 42.898651],
                [-82.419701, 42.972679],
                [-82.175552, 43.071659],
                [-81.745537, 43.345829],
                [-81.697769, 43.48777],
                [-81.745003, 44.083881],
                [-81.615547, 44.219151],
                [-81.580566, 44.346661],
                [-81.405273, 44.437771],
                [-81.268066, 44.616661],
                [-81.349731, 44.973049],
                [-81.433884, 44.982491],
                [-81.591377, 45.15221],
                [-81.576111, 45.19276],
                [-81.720001, 45.215832],
                [-81.695267, 45.264721],
                [-81.283623, 45.249161],
                [-81.325562, 45.15749],
                [-81.265556, 45.10416],
                [-81.253616, 45.001659],
                [-81.110283, 44.96027],
                [-81.150284, 44.911381],
                [-80.948608, 44.963612],
                [-81.132767, 44.758049],
                [-80.997772, 44.809158],
                [-80.892776, 44.791111],
                [-80.942207, 44.595268],
                [-80.805557, 44.702492],
                [-80.649986, 44.726101],
                [-80.584442, 44.612209],
                [-80.089722, 44.468048],
                [-79.997498, 44.556938],
                [-79.987503, 44.68277],
                [-80.113327, 44.752491],
                [-80.103882, 44.819988],
                [-79.945831, 44.865551],
                [-79.877487, 44.76833],
                [-79.659729, 44.751659],
                [-79.721657, 44.78722],
                [-79.68306, 44.879162],
                [-79.736938, 44.865551],
                [-79.737778, 44.8036],
                [-79.783623, 44.817501],
                [-79.828613, 44.9361],
                [-79.914169, 44.94471],
                [-79.934998, 45.013329],
                [-80.099991, 45.123322],
                [-79.988602, 45.148331],
                [-80.12944, 45.237499],
                [-80.037514, 45.314159],
                [-80.059433, 45.382771],
                [-80.127777, 45.411659],
                [-80.19249, 45.375549],
                [-80.164719, 45.33971],
                [-80.233322, 45.341099],
                [-80.335831, 45.435551],
                [-80.349991, 45.382771],
                [-80.400284, 45.606659],
                [-80.501404, 45.569439],
                [-80.748337, 45.921379],
                [-81.043327, 45.9561],
                [-81.155273, 45.925831],
                [-81.168877, 46.017769],
                [-81.250557, 45.9575],
                [-81.497498, 45.974991],
                [-81.497772, 46.020271],
                [-81.670273, 45.946381],
                [-81.576111, 45.996941],
                [-81.64473, 46.014999],
                [-81.503891, 46.04166],
                [-81.703888, 46.0186],
                [-81.543327, 46.05999],
                [-81.621384, 46.123051],
                [-81.743332, 46.06805],
                [-81.761124, 46.01305],
                [-81.777222, 46.06332],
                [-81.714447, 46.10416],
                [-82.13501, 46.11694],
                [-82.369453, 46.165821],
                [-82.322243, 46.190269],
                [-82.674156, 46.16444],
                [-82.63028, 46.21777],
                [-82.986938, 46.172218],
                [-83.865547, 46.29583],
                [-83.883331, 46.334991],
                [-84.049728, 46.326099],
                [-84.119453, 46.375271],
                [-84.075012, 46.503609],
                [-84.127129, 46.529591],
                [-84.136177, 46.534111],
                [-84.17305, 46.55249],
                [-84.412132, 46.50367],
                [-84.433167, 46.499371],
                [-84.517227, 46.482208],
                [-84.590286, 46.56583],
                [-84.421944, 46.696098],
                [-84.465843, 46.758331],
                [-84.54834, 46.699429],
                [-84.544159, 46.825001],
                [-84.393066, 46.8325],
                [-84.351097, 46.882771],
                [-84.446663, 46.938881],
                [-84.576401, 46.943878],
                [-84.603607, 46.901932],
                [-84.777786, 46.981659],
                [-84.653877, 47.225819],
                [-84.578888, 47.288891],
                [-84.62471, 47.361111],
                [-85.027786, 47.611382],
                [-84.967499, 47.68082],
                [-84.991096, 47.71693],
                [-84.892502, 47.789719],
                [-84.931671, 47.85527],
                [-84.844452, 47.949162],
                [-85.557503, 47.925549],
                [-85.860283, 47.985271],
                [-86.131378, 48.241379],
                [-86.262787, 48.584431],
                [-86.434723, 48.77721],
                [-86.55249, 48.732208],
                [-86.703056, 48.810268],
                [-86.85556, 48.750832],
                [-86.970551, 48.809719],
                [-87.003067, 48.77499],
                [-87.289719, 48.76749],
                [-87.443604, 48.84388],
                [-87.547234, 48.833599],
                [-87.575844, 48.88583],
                [-87.649986, 48.863049],
                [-87.955841, 48.95055],
                [-88.035553, 49.022221],
                [-88.183319, 48.965832],
                [-88.261093, 48.99295],
                [-88.253891, 48.872219],
                [-88.10527, 48.787498],
                [-88.103333, 48.711658],
                [-88.204453, 48.606659],
                [-88.252792, 48.59499],
                [-88.229446, 48.636662],
                [-88.283623, 48.603882],
                [-88.332497, 48.657211],
                [-88.3125, 48.581669],
                [-88.391113, 48.540829],
                [-88.430283, 48.573608],
                [-88.482773, 48.470539],
                [-88.559723, 48.430271],
                [-88.590561, 48.490269],
                [-88.556381, 48.546101],
                [-88.350281, 48.678051],
                [-88.313889, 48.760551],
                [-88.360817, 48.83638],
                [-88.480293, 48.854439],
                [-88.547501, 48.764721],
                [-88.509453, 48.716381],
                [-88.551666, 48.698601],
                [-88.539993, 48.645271],
                [-88.624443, 48.62471],
                [-88.632767, 48.522491],
                [-88.693047, 48.507771],
                [-88.74028, 48.369709],
                [-88.938599, 48.314442],
                [-88.759453, 48.580551],
                [-89.138611, 48.48444],
                [-89.243057, 48.388329],
                [-89.198883, 48.32444],
                [-89.337784, 48.1161],
                [-89.530586, 48.00177],
                [-89.749222, 48.0266],
                [-89.900299, 47.992619],
                [-89.986931, 48.02367],
                [-90.026611, 48.086189],
                [-90.14518, 48.112881],
                [-90.556732, 48.092861],
                [-90.567352, 48.121811],
                [-90.743263, 48.088551],
                [-90.864388, 48.254311],
                [-91.027039, 48.19545],
                [-91.239326, 48.081409],
                [-91.571442, 48.043678],
                [-91.568657, 48.104561],
                [-91.703613, 48.114941],
                [-91.711807, 48.19688],
                [-91.788689, 48.206249],
                [-91.979401, 48.2505],
                [-92.035049, 48.35561],
                [-92.125816, 48.366859],
                [-92.275993, 48.352421],
                [-92.300133, 48.298409],
                [-92.276772, 48.244438],
                [-92.369957, 48.220871],
                [-92.473167, 48.35759],
                [-92.456192, 48.40226],
                [-92.497383, 48.44017],
                [-92.70649, 48.460461],
                [-92.698669, 48.49482],
                [-92.626228, 48.502918],
                [-92.64167, 48.54044],
                [-92.728851, 48.54031],
                [-92.946777, 48.628441],
                [-93.091301, 48.626659],
                [-93.304092, 48.63723],
                [-93.457619, 48.59277],
                [-93.465187, 48.54958],
                [-93.513977, 48.534328],
                [-93.780937, 48.511639],
                [-93.812523, 48.525459],
                [-93.843727, 48.624779],
                [-94.230637, 48.65202],
                [-94.292137, 48.707741],
                [-94.430443, 48.710819],
                [-94.570107, 48.713699],
                [-94.694221, 48.777641],
                [-94.681038, 48.877178],
                [-94.83181, 49.330811],
                [-95.151627, 49.371731],
                [-95.153877, 49.452019],
                [-95.154327, 49.576729],
                [-95.150284, 52.826389],
                [-93.652222, 53.72221],
                [-93.272087, 53.998859],
                [-92.698143, 54.410931],
                [-92.60231, 54.478489],
                [-92.476593, 54.56694],
                [-92.388641, 54.62793],
                [-90.843887, 55.66777],
                [-89.305557, 56.627209],
                [-89.179642, 56.86895],
                [-89.037781, 56.836109],
                [-88.87944, 56.858051],
                [-88.654716, 56.696381],
                [-88.48999, 56.64222],
                [-88.353607, 56.553322],
                [-87.975563, 56.437489],
                [-87.943047, 56.377491],
                [-87.723892, 56.20388],
                [-87.708054, 56.156101],
                [-87.583618, 56.076099],
                [-87.615829, 56.002781],
                [-87.548607, 56.049999],
                [-87.351936, 55.992771],
                [-87.362503, 55.946659],
                [-86.979446, 55.93166],
                [-86.958893, 55.905819],
                [-86.477783, 55.80888],
                [-86.277222, 55.72887],
                [-85.944443, 55.697769],
                [-85.577789, 55.562771],
                [-85.393341, 55.408878],
                [-85.128883, 55.346378],
                [-85.121933, 55.303322],
                [-85.214172, 55.273319],
                [-85.398064, 55.10083],
                [-85.383057, 55.063049],
                [-85.423889, 54.990551],
                [-85.194153, 55.24416],
                [-85.001953, 55.296661],
                [-84.599167, 55.241661],
                [-84.322777, 55.289989],
                [-84.092216, 55.27166],
                [-83.920273, 55.31916],
                [-83.730003, 55.259441],
                [-83.736938, 55.215],
                [-83.686943, 55.24416],
                [-83.601387, 55.224159],
                [-83.561943, 55.130821],
                [-83.583618, 55.22916],
                [-83.698608, 55.283051],
                [-83.179718, 55.197208],
                [-82.964722, 55.233601],
                [-82.895279, 55.171661],
                [-82.946663, 55.10638],
                [-82.884171, 55.15332],
                [-82.650284, 55.16972],
                [-82.555557, 55.139721],
                [-82.536667, 55.16444],
                [-82.319458, 55.064159],
                [-82.338608, 55.09943],
                [-82.308037, 55.121929],
                [-82.340561, 55.164711],
                [-82.245827, 55.102779],
                [-82.287216, 55.036659],
                [-82.254997, 54.975269],
                [-82.270844, 54.931381],
                [-82.195267, 54.844711],
                [-82.358887, 54.500271],
                [-82.441101, 54.36166],
                [-82.434158, 54.209431],
                [-82.362778, 54.143608],
                [-82.381378, 54.118881],
                [-82.321953, 54.123878],
                [-82.243881, 54.06805],
                [-82.12999, 53.79916],
                [-82.21666, 53.603882],
                [-82.114723, 53.273609],
                [-82.250557, 53.211941],
                [-82.301666, 52.961109],
                [-82.101669, 52.87999],
                [-81.973618, 52.780548],
                [-81.988892, 52.762211],
                [-81.921387, 52.682209],
                [-81.558037, 52.4561],
                [-81.553047, 52.352489],
                [-81.478058, 52.302219],
                [-81.561394, 52.31638],
                [-81.747498, 52.251389],
                [-81.82251, 52.25444],
                [-81.883621, 52.187489],
                [-81.758347, 52.239429],
                [-81.501678, 52.23333],
                [-81.352783, 52.101101],
                [-80.988327, 52.008049],
                [-80.899986, 51.895271],
                [-80.815277, 51.87999],
                [-80.599167, 51.713329],
                [-80.515007, 51.524441],
                [-80.436394, 51.464161],
                [-80.424438, 51.363609],
                [-80.487503, 51.336651],
                [-80.652496, 51.278332],
                [-80.75029, 51.17749],
                [-80.844727, 51.150269],
                [-81.015007, 51.028332],
                [-80.93277, 51.049721],
                [-80.943878, 51.0075],
                [-80.862213, 51.1161],
                [-80.694443, 51.156101],
                [-80.530563, 51.283611],
                [-80.392227, 51.3386],
                [-79.996384, 51.254711],
                [-79.741096, 51.1236],
                [-79.684998, 51.045551],
                [-79.537613, 50.958599]
              ]
            ],
            [
              [
                [-82.878601, 45.968601],
                [-82.809998, 45.992489],
                [-82.842216, 45.920269],
                [-82.757507, 45.844151],
                [-82.592216, 45.849998],
                [-82.539169, 45.785549],
                [-82.509171, 45.848598],
                [-82.586937, 45.902489],
                [-82.299438, 45.99194],
                [-82.167503, 45.837769],
                [-82.048607, 45.96471],
                [-81.92778, 45.978039],
                [-81.888344, 45.949429],
                [-81.90834, 45.891941],
                [-81.820282, 45.880268],
                [-81.847229, 45.785271],
                [-81.799988, 45.732208],
                [-81.683609, 45.904991],
                [-81.655838, 45.861111],
                [-81.70723, 45.798328],
                [-81.587219, 45.799999],
                [-81.719452, 45.606098],
                [-81.863327, 45.52055],
                [-81.99472, 45.559719],
                [-81.898064, 45.577492],
                [-81.761124, 45.701099],
                [-82.065826, 45.55888],
                [-82.689163, 45.793049],
                [-83.213333, 45.873878],
                [-83.186943, 45.96471],
                [-83.091377, 45.919441],
                [-83.054993, 45.960548],
                [-82.983887, 45.930271],
                [-82.878601, 45.968601]
              ]
            ]
          ]
        },
        "properties": {
          "name": "Ontario",
          "density": '14',
          "cartodb_id": 11,
          "created_at": "2014-10-16T13:22:00Z",
          "updated_at": "2014-10-16T13:22:00Z"
        }
      }
    ]
  }