import { Modal, ModalHeader, ModalBody, FormGroup, Label, Button, InputGroup } from 'reactstrap';
import { validateUserLoginForm } from '../../utils/validateUserLoginForm';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import UserSignupForm from './UserSignupForm';
import { useDispatch } from 'react-redux';
import { userLogin } from './userSlice';
import { useState } from 'react';

const UserLoginForm = ({ isOpen, toggle }) => {
    
    const [signupModalOpen, setSignupModalOpen] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const dispatch = useDispatch();

    const handleLogin = (values) => {
        const userLoggingIn = {
            username: values.username,
            password: values.password
        };
        dispatch(userLogin(userLoggingIn));
        toggle();
    };

    const toggleSignupModal = () => {
        setSignupModalOpen(!signupModalOpen);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return(
        <>            
            <Modal 
                isOpen={isOpen} 
                toggle={toggle}
                id='userLoginModal'>
                <ModalHeader toggle={toggle}>
                    Login
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{username: '', password: ''}}
                        onSubmit={handleLogin}
                        validate={validateUserLoginForm}
                    >
                        <Form>
                            <FormGroup>
                                <Label htmlFor='username'>
                                    Username
                                </Label>
                                <Field 
                                    id='username'
                                    name='username'
                                    placeholder='Username'
                                    className='form-control'
                                />
                                <ErrorMessage name='username'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor='password'>
                                    Password
                                </Label>
                                <InputGroup>
                                    <Field 
                                        id='password'
                                        name='password'
                                        placeholder='Password'
                                        type={passwordVisible ? 'text' : 'password'}
                                        className='form-control'
                                    />
                                    <Button onClick={togglePasswordVisibility} color='primary'>
                                        {passwordVisible ? <i className='fa fa-regular fa-eye-slash' /> : <i className="fa fa-regular fa-eye" />}
                                    </Button>    
                                </InputGroup>
                                <ErrorMessage name='password'>
                                    {msg => <span className='text-danger'>{msg}</span>}
                                </ErrorMessage>
                            </FormGroup>
                            <Button type='submit'  color='primary'>
                                Login
                            </Button>{' '}
                            <Button onClick={toggleSignupModal} color='info'>
                                Sign-Up
                            </Button>{' '}
                            <Button onClick={toggle} color='danger'>
                                Cancel
                            </Button>
                        </Form>
                    </Formik>
                </ModalBody>
            </Modal>
            <UserSignupForm 
                isSignupOpen={signupModalOpen} 
                toggleSignupModal={toggleSignupModal} 
                toggle={toggle}
            />
        </>
    );
};

export default UserLoginForm;
