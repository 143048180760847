import { CampArray } from '../app/shared/CampArray';
import Masonry from '@mui/lab/Masonry';
import { motion } from 'framer-motion';
import { Row, Col } from 'reactstrap';
import { Box } from '@mui/material';
import BoxSpread from './BoxSpread';

const CampTextBoxOne = () => {

    return(
        <Row id='memory' className='campCSS'>
            <Col>
                <motion.div
                    className="campTextBox" 
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.5, duration: 2, type: 'tween' }}
                    viewport={{ once: true }}>
                    <h1 style={{ color: '#FFFFFF', marginRight: '1rem' }}>Memorias</h1>
                    <a href="#welcome" style={{ color: '#FFFFFF', alignContent:'center'}}>
                        <i class="fa fa-arrow-circle-up fa-2x" />
                    </a>
                </motion.div>
                <div style={{display:'flex', justifyContent:'space-around', marginTop:'3rem'}}>
                    <div>
                        <h4>Test1</h4>
                        <p>
                            Elit veniam culpa nostrud nulla aute consectetur commodo consequat
                            sit et. Occaecat ex reprehenderit ullamco excepteur exercitation 
                            enim magna aliquip cillum ut et enim. Exercitation id ad sunt 
                            exercitation laborum occaecat dolore non magna ipsum.
                        </p>
                        <p>
                            Elit veniam culpa nostrud nulla aute consectetur commodo consequat
                            sit et. Occaecat ex reprehenderit ullamco excepteur exercitation 
                            enim magna aliquip cillum ut et enim. Exercitation id ad sunt 
                            exercitation laborum occaecat dolore non magna ipsum.
                        </p>
                    </div>
                    <div>
                        <Box 
                            sx={{ width: 500, minHeight:629 }}  
                            alignContent= 'center'
                            display='flex'
                            justifyContent='center'
                        >
                            <Masonry columns={1} spacing={5}>
                                {
                                    CampArray.map((item, index) => (
                                        <motion.div 
                                        key={index}
                                        initial={{ opacity: 0, x: -300 }}
                                        whileInView={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.10 * index, duration: 1, type: 'tween' }}
                                        viewport={{ once: true }}>
                                                <BoxSpread item={item} />
                                        </motion.div>
                                    ))
                                }
                            </Masonry>
                        </Box>
                    </div>
                    <div>
                        <h4>Test2</h4>
                        <p>
                            Elit veniam culpa nostrud nulla aute consectetur commodo consequat
                            sit et. Occaecat ex reprehenderit ullamco excepteur exercitation 
                            enim magna aliquip cillum ut et enim. Exercitation id ad sunt 
                            exercitation laborum occaecat dolore non magna ipsum.
                        </p>
                        <p>
                            Elit veniam culpa nostrud nulla aute consectetur commodo consequat
                            sit et. Occaecat ex reprehenderit ullamco excepteur exercitation 
                            enim magna aliquip cillum ut et enim. Exercitation id ad sunt 
                            exercitation laborum occaecat dolore non magna ipsum.
                        </p>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default CampTextBoxOne;